import React from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import CheckboxField from './CheckboxField';

const TelegramField = ({ control, errors, field, index, register, setValue, disabled }) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  return (
    <Grid xs={12} item style={{ margin: '5px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="Telegram"
      />
      <CheckboxField
        name={enabledFieldName}
        defaultValue={field.enabled}
        control={control}
        disabled={disabled}>
        <img alt="telegram" src="/images/telegram.svg" height={22} />
        <Box margin="0 10px" minWidth="10vw">
          <Typography>Send a notification to the</Typography>
        </Box>
        <Controller
          name={`notificationSetup[${index}].config.telegramId`}
          control={control}
          defaultValue={field.config.telegramId}
          render={props => {
            const { onChange, ...otherProps } = props;

            return (
              <TextField
                label="Telegram id"
                disabled={disabled}
                variant="outlined"
                margin="dense"
                errors={errors}
                style={{ minWidth: '15vw' }}
                error={Boolean(error?.config?.telegramId)}
                helperText={error?.config?.telegramId?.message}
                onChange={event => {
                  const value = event.target.value;

                  setValue(enabledFieldName, Boolean(value));

                  onChange(event.target.value);
                }}
                {...otherProps}
              />
            );
          }}
        />
      </CheckboxField>
    </Grid>
  );
};

export default TelegramField;
