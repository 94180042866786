import * as z from 'zod';

export default z
  .object({
    environmentId: z.string().nonempty({ message: 'Required' }),
    check_interval: z.string().refine(
      check_interval => {
        try {
          if (
            Number(check_interval) >=
            Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
          ) {
            return true;
          }
        } catch {
          return false;
        }
      },
      {
        message: `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
      }
    ),
    timeout: z.string().refine(
      timeout => {
        try {
          if (Number(timeout) >= 1) {
            return true;
          }
        } catch {
          return false;
        }
      },
      {
        message: `The timeout number must be greater than zero.`
      }
    ),
    failuresToIncident: z.string().refine(
      failuresToIncident => {
        try {
          if (Number(failuresToIncident) >= 1) {
            return true;
          }
        } catch {
          return false;
        }
      },
      { message: 'Fails number should be a greater than zero' }
    ),
    openIncidentWithSeverity: z.string().optional(),
    productId: z.string().nonempty({ message: 'Required' }),
    validationString: z.string().optional(),

    pre_name: z.string().nonempty({ message: 'Pre Request Name is required' }),
    pre_healthcheckUrl: z.string().url({ message: 'Invalid url. E.g.: http://host' }),
    pre_method: z.string().nonempty({ message: 'Required' }),
    pre_headers: z.any(),
    pre_post_body_default: z.any(),
    pre_post_body_urlencoded: z.any(),
    pre_post_body_raw: z.any(),
    pre_post_body_type: z.any(),
    pre_post_body_raw_type: z.any(),
    pre_validationString: z.union([z.string().nonempty({ message: 'Required' }), z.any()]),
    pre_skip_ssl_validation: z.boolean().optional(),

    pos_name: z.string().nonempty({ message: 'Application Name is required' }),
    pos_healthcheckUrl: z.string().url({ message: 'Invalid url. E.g.: http://host' }),
    pos_method: z.string().nonempty({ message: 'Required' }),
    pos_headers: z.any(),
    pos_post_body_default: z.any(),
    pos_post_body_urlencoded: z.any(),
    pos_post_body_raw: z.any(),
    pos_post_body_type: z.any(),
    pos_post_body_raw_type: z.any(),
    pos_skip_ssl_validation: z.boolean().optional(),
    pos_teamId: z
      .any()
      .nullable()
      .optional()
  })
  .refine(
    values => {
      if (Number(values.timeout) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Timeout number can only be up to six digits',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.check_interval) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval number can only be up to six digits',
      path: ['check_interval']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The timeout must not be greater than the interval',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The interval must not be lesser than the timeout',
      path: ['check_interval']
    }
  );
