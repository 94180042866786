type Primitive = string | number | boolean | null | undefined;
type Value = Primitive | Value[] | { [key: string]: Value };

export const isEqual = (newValue: Value, defaultValue: Value): boolean => {
  if (Array.isArray(newValue) && Array.isArray(defaultValue)) {
    return (
      newValue.length === defaultValue.length &&
      newValue.every((value, index) => isEqual(value, defaultValue[index]))
    );
  }

  if (isObject(newValue) && isObject(defaultValue)) {
    const newValueKeys = Object.keys(newValue);
    const defaultValueKeys = Object.keys(defaultValue);

    return (
      newValueKeys.length === defaultValueKeys.length &&
      newValueKeys.every(key => isEqual(newValue[key], defaultValue[key]))
    );
  }

  return newValue === defaultValue;
};

const isObject = (value: Value): value is Record<string, Value> => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};
