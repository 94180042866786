import uuid from 'uuid';
import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 500
    }
  },
  environmentIds: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      minimum: 1
    }
  }
};

const schemaEdit = {
  name: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 500
    }
  },
  environmentId: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  check_interval: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    },
    presence: {
      allowEmpty: false
    }
  },
  timeout: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    },
    presence: {
      allowEmpty: false
    }
  }
};

const schemaSettings = {
  url: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  host: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  port: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  dockerImage: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  healthcheckUrl: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  method: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  }
};

const applications1 = (state, action) => {
  if (actions.PRODUCT_APPLICATIONS_RESET === action.type) {
    return {
      ...state,
      product_applications_new: {
        isValid: false,
        values: {
          environmentIds: [],
          name: '',
          productId: '',
          healthcheckUrl: '',
          method: 'GET',
          timeout: null,
          check_interval: null,
          envs: [
            {
              uid: uuid(),
              key: '',
              value: ''
            }
          ],
          runtimeId: null
        },
        touched: {},
        errors: {}
      },
      product_applications_edit: {
        isValid: false,
        values: {
          environmentIds: [],
          name: '',
          productId: '',
          healthcheckUrl: '',
          method: 'GET',
          timeout: null,
          check_interval: null,
          envs: [
            {
              uid: uuid(),
              key: '',
              value: ''
            }
          ],
          runtimeId: null
        },
        touched: {},
        errors: {}
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_SETTINGS_RESET === action.type) {
    return {
      ...state,
      product_applications_settings: {
        isValid: false,
        values: {
          url: '',
          host: '',
          port: '',
          dockerImage: '',
          healthcheckUrl: '',
          method: 'GET',
          timeout: null,
          check_interval: null,
          envs: [
            {
              uid: uuid(),
              key: '',
              value: ''
            }
          ]
        },
        touched: {},
        errors: {}
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_EDIT_POPULATE_ENTITY === action.type) {
    const product_applications_edit = state.product_applications_edit;
    const values = {
      ...product_applications_edit.values,
      ...action.payload
    };

    const errors = validate(values, schemaEdit);

    return {
      ...state,
      product_applications_edit: {
        ...product_applications_edit,
        values,
        errors,
        isValid: !errors
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_POPULATE_ENTITY === action.type) {
    const product_applications_new = state.product_applications_new;

    const values = {
      ...product_applications_new.values,
      ...action.payload
    };

    const errors = validate(values, schema);

    return {
      ...state,
      product_applications_new: {
        ...product_applications_new,
        values,
        errors,
        isValid: !errors
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_NEW_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      environments: {
        ...state.environments,
        ...action.payload.environments
      },
      runtimes: {
        ...state.runtimes,
        ...action.payload.runtimes
      },
      products_view: {
        ...state.products_view,
        ...action.payload.product
      },
      product_applications_new: {
        ...state.product_applications_new,
        values: {
          ...state.product_applications_new.values,
          ...action.payload.product_applications_new
        }
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_EDIT_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      environments: {
        ...state.environments,
        ...action.payload.environments
      },
      runtimes: {
        ...state.runtimes,
        ...action.payload.runtimes
      },
      products_view: {
        ...state.products_view,
        ...action.payload.product
      },
      product_applications_edit: {
        ...state.product_applications_edit,
        values: {
          ...state.product_applications_edit.values,
          ...action.payload.product_applications_edit.values
        }
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_STATUS_PAGE_VIEW_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      product_applications_status_page_view: {
        ...state.product_applications_status_page_view,
        values: {
          ...state.product_applications_status_page_view.values,
          ...action.payload.product_applications_status_page_view.values
        }
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_VIEW_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      application_services_view: {
        ...state.application_services_view,
        ...action.payload.application_services_view
      },
      product_applications_view: {
        ...state.product_applications_view,
        values: {
          ...state.product_applications_view.values,
          ...action.payload.product_applications_view.values,
          org: {
            ...action.payload.org
          }
        }
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_POPULATE_SETTINGS_ENTITY === action.type) {
    const product_applications_settings = state.product_applications_settings;
    const { uid } = action.meta;

    const envs = product_applications_settings.values.envs.map(item => {
      const selected = { values: {} };
      if (item.uid === uid) {
        selected.values = action.payload;
      }
      const result = {
        ...item,
        ...selected.values
      };
      return result;
    });

    product_applications_settings.values.envs = envs;

    const values = {
      ...product_applications_settings.values,
      ...action.payload
    };

    const errors = validate(values, schemaSettings);

    return {
      ...state,
      product_applications_settings: {
        ...product_applications_settings,
        values,
        errors,
        isValid: !errors
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_NEW_ENVS_ADD === action.type) {
    const product_applications_settings = state.product_applications_settings;
    const envs = product_applications_settings.values.envs;

    envs.push({
      uid: uuid(),
      key: '',
      value: ''
    });

    product_applications_settings.values.envs = envs;
    const errors = validate(product_applications_settings.values, schemaSettings);

    return {
      ...state,
      product_applications_settings: {
        ...product_applications_settings,
        isValid: !errors
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_NEW_ENVS_REMOVE === action.type) {
    const product_applications_settings = state.product_applications_settings;
    const envs = product_applications_settings.values.envs;

    if (product_applications_settings.values.envs.length > 1) {
      product_applications_settings.values.envs.pop();
    }

    product_applications_settings.values.envs = envs;

    const errors = validate(product_applications_settings.values, schemaSettings);

    return {
      ...state,
      product_applications_settings: {
        ...product_applications_settings,
        isValid: !errors
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH_SUCCESSFUL === action.type) {
    const application = action.payload.applications.data;

    if (application.applicationsSettings) {
      state.product_applications_settings.values = application.applicationsSettings;
    }

    return {
      ...state,
      products_view: {
        ...state.products_view,
        ...action.payload.product
      },
      product_applications_edit: {
        ...state.product_applications_edit,
        values: {
          ...state.product_applications_edit.values,
          ...action.payload.applications.data
        }
      },
      product_applications_settings: {
        ...state.product_applications_settings,
        values: {
          ...state.product_applications_settings.values,
          ...action.payload.product_applications_settings
        }
      }
    };
  }

  return state;
};

// FIXME: identifier "applications" has already been declared or isn't helpful, rename this please!
export default applications1;
