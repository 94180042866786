import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { MenuSidebar, MenuSidebarCollection } from 'types/directus';

import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';
import { directusV2 } from 'services/directus';

function mapMenuSidebar(data: MenuSidebarCollection[]): MenuSidebar[] {
  return data.reduce((menuItems, menuItem) => {
    const isParentItem = data.find(item => menuItem.id === item.parent);
    const isChildren = Boolean(menuItem.parent);

    if (isChildren) return menuItems;

    if (isParentItem) {
      menuItems.push({
        id: menuItem.id,
        href: menuItem.href,
        icon: menuItem.icon,
        title: menuItem.title,
        featureFlag: menuItem.feature_flag,
        isStatic: menuItem.is_static,
        planFeature: menuItem.plan_feature,
        children: data
          .filter(item => menuItem.id === item.parent)
          .map(item => ({
            id: item.id,
            href: item.href!,
            icon: item.icon,
            title: item.title,
            featureFlag: item.feature_flag,
            planFeature: item.plan_feature
          })),
        allowedRoles: menuItem.allowed_roles
      });

      return menuItems;
    }

    menuItems.push({
      id: menuItem.id,
      href: menuItem.href,
      icon: menuItem.icon,
      title: menuItem.title,
      featureFlag: menuItem.feature_flag,
      isStatic: menuItem.is_static,
      children: [],
      allowedRoles: menuItem.allowed_roles,
      planFeature: menuItem.plan_feature
    });

    return menuItems;
  }, [] as MenuSidebar[]);
}

export function useMenuItems() {
  const { data: user } = useUser();
  const dispatch = useDispatch();

  const { data: menuItemsFromCMS, isLoading } = useQuery(
    'menu_sidebar',
    async () => {
      const response = await directusV2.items('menu_sidebar').readByQuery({});

      return mapMenuSidebar(response.data ?? []);
    },
    {
      enabled: Boolean(user?.logged),
      onError: () => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: new Error("Couldn't load menu items, please contact support"),
          ga: { category: 'ERROR', action: null }
        });
      }
    }
  );

  return { menuItems: menuItemsFromCMS ?? [], isLoading };
}
