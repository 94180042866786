import React from 'react';

import {
  DialogContentText,
  Link,
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DialogContent } from 'oldComponents/LegacyDialog';

import useCopy from 'hooks/useCopy';

import { instructions } from './CommandTexts';

const useStyles = makeStyles(theme => ({
  text: { paddingBottom: theme.spacing(1), color: '#546e7a' }
}));

const OsTabs = ({ environment, operationalSystem, setOperationalSystem }) => {
  const classes = useStyles();

  const { copiedSuccessfullyMessage, handleCopy } = useCopy();

  const handleChange = event => {
    setOperationalSystem(event.target.value);
  };

  return (
    <DialogContent>
      <DialogContentText id={'customAgent-dialog-osText'}>
        <Typography className={classes.text}>
          Agent Custom is only available in the following operating systems:{' '}
          <strong>Ubuntu, Amazon Linux 2</strong>,<strong> CentOS 7</strong> and{' '}
          <strong>CentOS 8</strong>
        </Typography>

        <Typography className={classes.text}>
          It won’t be able to operate in other systems. And, you also must have in your operation
          system in the cloud GitHub Installed.{' '}
        </Typography>
        <Typography className={classes.text}>
          Install the Agent on your cloud using the scripts below to be able to monitor applications
          and dependencies. We will automatically update your Agent Status when you install.
        </Typography>
      </DialogContentText>

      <Typography className={classes.text}>Select your Operational System:</Typography>
      <FormControl fullWidth size="small" variant="outlined" margin="dense">
        <Select id="typeOS" onChange={handleChange} value={operationalSystem}>
          <MenuItem value={'amazonLinux'}>{'Amazon Linux'}</MenuItem>
          <MenuItem value={'centOS7And8'}>{'CentOS 7 and 8'}</MenuItem>
          <MenuItem value={'ubuntu'}>{'Ubuntu'}</MenuItem>
        </Select>
      </FormControl>

      {operationalSystem && (
        <>
          <Box paddingTop={2}>
            <DialogContentText>
              <Link
                id={'customAgent-copy-kubernetes-commands'}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  handleCopy(
                    instructions(environment.id, environment.agentToken, operationalSystem)
                  );
                }}>
                Copy to clipboard
              </Link>{' '}
              {copiedSuccessfullyMessage}
            </DialogContentText>
          </Box>

          <Box maxHeight={'240px'}>
            <pre
              id={'customAgent-codeBox-os'}
              style={{
                overflowX: 'auto',
                padding: 8,
                border: '1px solid rgba(29,28,29,.04)',
                borderRadius: 4,
                background: 'rgba(29, 28, 29, 0.04)'
              }}
              onClick={event => {
                const range = document.createRange();
                range.selectNodeContents(event.target);
                const sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
              }}>
              {instructions(environment.id, environment.agentToken, operationalSystem)}
            </pre>
          </Box>
        </>
      )}
    </DialogContent>
  );
};

export default OsTabs;
