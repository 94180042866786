import validate from 'validate.js';

validate.validators.presenceIfProvider = (value, options, key, attributes) => {
  const { provider } = attributes;

  if (provider === options.provider) {
    return validate.validators.presence(value, { allowEmpty: false }, key, attributes);
  }
};

const schema = {
  provider: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },

  name: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },

  accessKeyId: {
    presenceIfProvider: { provider: 'aws' }
  },

  secretAccessKey: {
    presenceIfProvider: { provider: 'aws' }
  },

  clientId: {
    presenceIfProvider: { provider: 'azure' }
  },

  clientSecret: {
    presenceIfProvider: { provider: 'azure' }
  },

  tenantId: {
    presenceIfProvider: { provider: 'azure' }
  },

  subscriptionId: {
    presenceIfProvider: { provider: 'azure' }
  }
};

export default schema;
