import { useMutation } from 'urql';

const CloseIssueMutation = `#graphql
  mutation($data: CloseIssueInput!) {
    closeIssue(data: $data) {
      message
      success
    }
  }`;

type CloseIssueResponse = {
  closeIssue: { message: string; success: boolean };
};

type CloseIssueVariables = {
  data: {
    incidentId: number;
    integrationUid: string;
  };
};

export const useCloseIssue = () => {
  const [{ data, fetching }, closeIssue] = useMutation<CloseIssueResponse, CloseIssueVariables>(
    CloseIssueMutation
  );

  const success = data?.closeIssue.success;

  return { closeIssue, fetching, success };
};
