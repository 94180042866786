import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareSaveEntity(axios, action) {
  try {
    const { account_security_edit } = yield select(state => state);
    yield axios.request({
      method: 'put',
      url: `/auth/change/password`,
      data: account_security_edit.values
    });
    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'updated successfully'
    });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchUserSecurity(axios) {
  yield takeLatest(actions.SAVE_SECURITY, prepareSaveEntity, axios);
}
