export const CreateStepGroupsMutation = `mutation($createStepGroupInput: CreateStepGroupInput!) {
    createStepGroupsUsingTeams(createStepGroupInput: $createStepGroupInput) {
      id
      team
      teamIdFromTeams
      name
      steps {
        teamMemberToContact
        memberFromTeams
        contactMethod
        intervalInMinutes
        timeoutInMinutes
        order
      }
    }
  }
`;
