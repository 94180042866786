import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentDynatraceGuide = ({
  orgUid,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Dynatrace',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/dynatrace/${integrationUid}?token=${token}`,
    snippets: [
      {
        key: 'Header',
        value: `{'User-Agent: 1PcustomAuth/1.0'}
            `
      },
      {
        key: 'Payload',
        value: `
            {
                "title": "{ProblemTitle}",
                "description": "{ProblemDetailsText}",
                "external_aggregate_key": "{Pid}",
                "action": "{State}",
                "severity": ""
              }
            `
      }
    ],
    instructions: {
      isNumeric: false,
      steps: [
        `The action field must be filled in with the value "alarmed" or "resolved" depending on the requested action. The external_agreegate_key field must be filled in with the incident identifier, which is mandatory for resolved and alarmed. The severity field must be filled in with the following values:`,
        '- critical',
        '- high',
        '- moderate',
        '- low',
        '- informational',
        'If you do not want to classify the incident created, just do not send the field.'
      ]
    }
  };
};
