import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { ApplicationEdit } from 'views/Applications/Edit';
import { ApplicationsList } from 'views/Applications/List';
import { ApplicationView } from 'views/Applications/View';

export const applicationsRoutes = [
  <AuthorizedRoute
    component={ApplicationsList}
    exact
    layout={MainLayoutV5}
    path="/applications"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ApplicationEdit}
    exact
    layout={MainLayoutV5}
    path="/applications/:applicationUid/edit"
    planFeature="Monitoring"
    featureTarget="ApplicationController-put-/applications"
  />,
  <AuthorizedRoute
    component={ApplicationView}
    exact
    layout={MainLayoutV5}
    path="/applications/:uid"
    planFeature="Monitoring"
  />
];
