import { Product } from 'constants/Product';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import V1 from './V1';
import V2 from './V2';

const StatusLine = (
  props: Product & {
    productId: number;
  }
) => {
  const { resourcesMonitoringAndHealthStatus, productId } = props;

  const isOldApplication = useFeatureFlagUnleash('oldApplications');

  if (!isOldApplication) {
    return <V2 productId={productId} />;
  }

  return (
    <V1
      monitoredApplications={props.monitoredApplications}
      resourcesMonitoringAndHealthStatus={resourcesMonitoringAndHealthStatus}
      productId={productId}
    />
  );
};

export default StatusLine;
