import { IconButton } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SecretsField from 'oldComponents/SecretsField';

import InputDefault from 'componentsV3/InputDefault';
import withThemeV3Hoc from 'theme/v3/withTheme';

const InputWithSecrets = ({ iconColor, ...props }) => {
  return (
    <SecretsField
      InputComponent={InputDefault}
      IconButton={
        <IconButton>
          <VpnKeyIcon style={{ color: iconColor }} />
        </IconButton>
      }
      {...props}
    />
  );
};

export default withThemeV3Hoc(InputWithSecrets);
