import { Link, List, ListItem } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

import { useStyles } from '../styles';

type MarkdownViewProps = {
  text: string;
};

export function Markdown({ text }: MarkdownViewProps) {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={classes.content}
      components={{
        a: ({ node, href, children }) => (
          <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        ),
        ul: ({ node, children, ...props }) => (
          <List {...props} className={classes.unorderedList}>
            {children}
          </List>
        ),
        ol: ({ node, children, ...props }) => (
          <List {...props} className={classes.orderedList}>
            {children}
          </List>
        ),
        li: ({ node, children, ...props }) => (
          <ListItem {...props} style={{ display: 'list-item' }}>
            {children}
          </ListItem>
        )
      }}>
      {text}
    </ReactMarkdown>
  );
}
