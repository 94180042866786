import { useEffect } from 'react';

import { green, grey, orange, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import DataTable from 'react-data-table-component';
import { useQuery } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

const GetUptimesByServiceIdV2 = `#graphql
  query($serviceId: Int!) {
    uptimeV2(serviceId: $serviceId) {
      online1Hour,
      online6Hours,
      online12Hours,
      online24Hours,
      online7Days,
      online15Days,
      online30Days,
      online90Days,
      online180Days,
      online365Days,
    }
  }
`;

const statusColor = (hour: string) => {
  const defaultStyle = {
    fontSize: 16,
    color: '#ffffff'
  };

  return [
    {
      when: (row: any = {}) => !row?.uptimeV2,
      style: {
        backgroundColor: grey[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any = {}) => row?.uptimeV2[hour] >= 99.5,
      style: {
        backgroundColor: green[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any = {}) => row?.uptimeV2[hour] < 99.5 && row?.uptimeV2[hour] >= 98,
      style: {
        backgroundColor: orange[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any) => row.uptimeV2[hour] < 98,
      style: {
        backgroundColor: red[300],
        ...defaultStyle
      }
    }
  ];
};

const TooltipHeader = ({
  textEnding,
  children,
  resourceType
}: {
  textEnding: string;
  children: string;
  resourceType: string;
}) => (
  <Tooltip
    title={`Shows if the ${resourceType} uptime is > 99.5, between 98 and 99.5, or < 98, in the last ${textEnding}`}>
    <span>{children}</span>
  </Tooltip>
);

const UptimeTable = ({
  sterasId,
  resourceType,
  otherProps,
  hasLoading = false,
  monitoringActive
}: {
  sterasId?: number;
  resourceType: string;
  otherProps?: any;
  hasLoading: boolean;
  monitoringActive?: boolean;
}) => {
  const canFetchDataFromTheLast7Days = useFeaturePlan('DataRetention.7Days');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');
  const canFetchDataFromTheLast365Days = useFeaturePlan('DataRetention.365Days');

  const [resultUptime, reexecuteQuery] = useQuery({
    query: GetUptimesByServiceIdV2,
    pause: !sterasId,
    variables: { serviceId: sterasId }
  });

  const { fetching } = resultUptime;

  const dataArray = resultUptime.data && monitoringActive ? [resultUptime.data] : [];

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 10 /* ms = 30 seconds */);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  const columns = [
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="1 hour">
          01 hour
        </TooltipHeader>
      ),
      center: true,
      conditionalCellStyles: statusColor('online1Hour'),
      cell: (row: any) =>
        row?.uptime || row?.uptimeV2 ? (
          <div>{row?.uptime?.online1Hour || row?.uptimeV2?.online1Hour || 0}%</div>
        ) : (
          <div>No data</div>
        )
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="6 hours">
          06 hours
        </TooltipHeader>
      ),

      center: true,
      conditionalCellStyles: statusColor('online6Hours'),
      cell: (row: any) =>
        row?.uptime || row?.uptimeV2 ? (
          <div>{row?.uptime?.online6Hours || row?.uptimeV2?.online6Hours || 0}%</div>
        ) : (
          <div>No data</div>
        )
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="12 hours">
          12 hours
        </TooltipHeader>
      ),
      center: true,
      conditionalCellStyles: statusColor('online12Hours'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online12Hours || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="24 hours">
          24 hours
        </TooltipHeader>
      ),
      center: true,
      conditionalCellStyles: statusColor('online24Hours'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online24Hours || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="7 days">
          07 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast7Days,
      center: true,
      conditionalCellStyles: statusColor('online7Days'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online7Days || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="15 days">
          15 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast30Days,
      center: true,
      conditionalCellStyles: statusColor('online15Days'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online15Days || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="30 days">
          30 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast30Days,
      center: true,
      conditionalCellStyles: statusColor('online30Days'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online30Days || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="90 days">
          90 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast365Days,
      center: true,
      conditionalCellStyles: statusColor('online90Days'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online90Days || 0}%</div> : <div>No data</div>
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="180 days">
          180 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast365Days,
      center: true,
      conditionalCellStyles: statusColor('online180Days'),
      cell: (row: any) =>
        row?.uptime || row?.uptimeV2 ? (
          <div>{row?.uptimeV2?.online180Days || 0}%</div>
        ) : (
          <div>No data</div>
        )
    },
    {
      name: (
        <TooltipHeader resourceType={resourceType} textEnding="365 days">
          365 days
        </TooltipHeader>
      ),
      omit: !canFetchDataFromTheLast365Days,
      center: true,
      conditionalCellStyles: statusColor('online365Days'),
      cell: (row: any) =>
        row?.uptimeV2 ? <div>{row?.uptimeV2?.online365Days || 0}%</div> : <div>No data</div>
    }
  ];

  return (
    <>
      <DataTable
        noHeader
        columns={columns}
        data={dataArray}
        noDataComponent={fetching ? `` : `${resourceType} has no monitoring data`}
        {...otherProps}
      />
      {hasLoading && fetching && <LoadingOverlay size={45} />}
    </>
  );
};

export default UptimeTable;
