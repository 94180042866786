import { Grid } from '@material-ui/core';
import { Control } from 'react-hook-form';

import InputDefault from 'componentsV3/InputDefault';
import SelectSimple from 'componentsV3/Select';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

function ApplicationAlertParams({
  errors,
  register,
  control
}: {
  errors: Object;
  register: (name: string) => void;
  control: Control<any>;
}) {
  const useSeverityOnApplication = useFeatureFlagUnleash('chooseSeverityOnApplication', {
    queryString: true
  });

  return (
    <Grid item container spacing={4}>
      <Grid item xs={12}>
        <InputDefault
          errors={errors}
          inputRef={register}
          label="Validation String"
          placeholder="Ex: my_validation_string"
          tooltip="Will check if the healthcheck response body contains this string. Leave empty to check status code only."
          name="validationString"
        />
      </Grid>

      <Grid item xs={2}>
        <InputDefault
          errors={errors}
          inputRef={register}
          label="Interval (sec)"
          name="check_interval"
          type="number"
          defaultValue={60}
        />
      </Grid>
      <Grid item xs={2}>
        <InputDefault
          errors={errors}
          inputRef={register}
          label="Timeout (sec)"
          name="timeout"
          type="number"
          defaultValue={60}
        />
      </Grid>
      <Grid item xs={4}>
        <InputDefault
          errors={errors}
          inputRef={register}
          label="Fails to open automatic incident"
          name="failuresToIncident"
          type="number"
          defaultValue={2}
        />
      </Grid>
      {useSeverityOnApplication && (
        <Grid item xs={4}>
          <SelectSimple
            errors={errors}
            name="openIncidentWithSeverity"
            label="Severity"
            defaultValue="not-classified"
            control={control}
            items={[
              { value: 'sev-1-critical', label: 'SEV-1 - Critical' },
              { value: 'sev-2-high', label: 'SEV-2 - High' },
              { value: 'sev-3-moderate', label: 'SEV-3 - Moderate' },
              { value: 'sev-4-low', label: 'SEV-4 - Low' },
              { value: 'sev-5-informational', label: 'SEV-5 - Informational' },
              { value: 'not-classified', label: 'Not Classified' }
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default ApplicationAlertParams;
