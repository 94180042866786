/**
 * @param {Function} execute - function to execute on timeout
 * @param {number} wait - time in miliseconds, default is 1000 ms
 * time param is a closure variable, so it can be changed inside the function
 */
const useDebounce = (execute, wait = 1000, time = 0) => event => {
  clearTimeout(time);

  event.persist();

  time = setTimeout(() => execute(event), wait);
};

export default useDebounce;
