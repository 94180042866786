import React from 'react';

import { Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Link as RouterLink } from 'react-router-dom';

const members = [
  {
    grow: 0,
    center: true,
    compact: true,
    cell: row => <Avatar src={row.user.photoURL} />
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    cell: row => row.user.email
  },
  {
    name: 'Name',
    selector: 'displayName',
    sortable: true,
    cell: row =>
      row.user?.profile?.displayName ? row.user?.profile?.displayName : row.user?.displayName
  },
  {
    name: 'Role',
    sortable: true,
    cell: row => row.role.name
  }
];

const products = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    cell: row => {
      return (
        <Link component={RouterLink} to={`/products/${row.id}`}>
          {row.name}
        </Link>
      );
    }
  }
];

const columns = {
  members,
  products
};

export default columns;
