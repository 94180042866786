import { ChangeEvent } from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import { useStyles } from './styles';

type Options = {
  value: string;
  label: string;
};

export const Filter = ({
  name,
  label,
  value,
  onChange,
  options
}: {
  name: string;
  label: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  options: Options[];
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={label}
      variant="outlined"
      onChange={onChange}
      value={value}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        shrink: true,
        color: 'secondary'
      }}
      select
      fullWidth>
      {options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
