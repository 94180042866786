import { Dispatch, SetStateAction } from 'react';

import { Skeleton } from '@material-ui/lab-old';
import { TableColumn } from 'react-data-table-component';
import { ExternalIntegrationFromIndexer } from 'types/external/ExternalService';

import { MenuActions } from './Menu/MenuActions';
import { StatusChip } from './StatusChip';

export function getColumns({
  showDeleteButton,
  externalServices,
  setExternalServices,
  setDeletedItem
}: {
  showDeleteButton: boolean;
  externalServices: ExternalIntegrationFromIndexer[];
  setExternalServices: Dispatch<SetStateAction<ExternalIntegrationFromIndexer[]>>;
  setDeletedItem: Dispatch<SetStateAction<string>>;
}) {
  const COLUMNS: TableColumn<ExternalIntegrationFromIndexer>[] = [
    {
      name: 'Status',
      selector: row => row?.status || '',
      cell: row =>
        row?.status ? (
          <StatusChip status={row?.status} />
        ) : (
          <Skeleton height={40} animation="wave" width={100} />
        ),
      width: '190px',
      grow: 1.5
    },
    {
      name: 'Monitoring name',
      selector: row => row.name || '',
      cell: row => {
        return row.name ? row.name : <Skeleton height={40} animation="wave" width={100} />;
      },
      grow: 2
    },
    {
      name: 'External player',
      cell: row => {
        return row.origin ? row.origin : <Skeleton height={40} animation="wave" width={100} />;
      }
    },
    {
      name: 'Actions',
      omit: !showDeleteButton,
      right: true,
      cell: row => (
        <MenuActions
          externalServiceUid={String(row.uid)}
          externalServiceTitle={String(row.name)}
          externalServices={externalServices}
          externalService={row}
          setExternalServices={setExternalServices}
          setDeletedItem={setDeletedItem}
        />
      )
    }
  ];

  return COLUMNS;
}
