export const UpdatePostMortem = `#graphql
  mutation(
    $id: Int!,
    $incidentId: Int!,
    $postMortem: String!,
    $serviceId: Int
    $title: String
  ) {
    updatePostMortemV2(
      data: {
        id:$id
        incidentId: $incidentId
        postMortem: $postMortem
        serviceId: $serviceId
        title: $title
      }
    ) {
      id
      postMortem
      updatedAt
      uid
    }
  }
`;

export type UpdatedPostMortemData = {
  updatePostMortem: {
    id: number;
    postMortem: string;
  };
};

export type UpdatePostMortemVariables = {
  id: number;
  incidentId: number;
  postMortem: string;
  serviceId?: number;
  title?: string;
};
