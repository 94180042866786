import React from 'react';

import {
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Box
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    justifyContent: 'flex-end'
  },
  checked: {
    color: palette.primary.main
  },
  radioGrup: {
    display: 'flex',
    flexDirection: 'row'
  },
  radio: {
    color: palette.inputBorder.defaul,
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  imageBox: {
    minWidth: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(1),
    borderRadius: 8,
    border: '1px solid',
    borderColor: props => (props.checked ? palette.primary.main : palette.inputBorder.default)
  },
  image: {
    width: 50,
    borderRadius: 8
  }
}));

function Image({ checked, provider }) {
  const classes = useStyles({ checked });

  const icons = {
    aws: '/images/aws.png',
    azure: '/images/azure.png',
    custom: '/images/custom.png'
  };

  return (
    <Box className={classes.imageBox}>
      <img className={classes.image} src={icons[provider]} alt={`${provider} provider`} />
    </Box>
  );
}

const ProviderInput = ({ control, name }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <Controller
        name={name}
        control={control}
        as={
          <RadioGroup className={classes.radioGrup}>
            <FormControlLabel
              key={'aws'}
              value={'aws'}
              control={
                <Radio
                  classes={{
                    root: classes.radio
                  }}
                  icon={<Image provider="aws" />}
                  className={classes.radio}
                  checkedIcon={<Image provider="aws" checked />}
                  disableRipple
                  focusRipple={false}
                />
              }
            />

            <FormControlLabel
              key={'azure'}
              value={'azure'}
              control={
                <Radio
                  classes={{
                    root: classes.radio
                  }}
                  icon={<Image provider="azure" />}
                  className={classes.radio}
                  checkedIcon={<Image provider="azure" checked />}
                  disableRipple
                  focusRipple={false}
                />
              }
            />

            <FormControlLabel
              key={'custom'}
              value={'custom'}
              control={
                <Radio
                  classes={{
                    root: classes.radio
                  }}
                  icon={<Image provider="custom" />}
                  className={classes.radio}
                  checkedIcon={<Image provider="custom" checked />}
                  disableRipple
                  focusRipple={false}
                />
              }
            />
          </RadioGroup>
        }
      />
    </FormControl>
  );
};

export default withThemeV3Hoc(ProviderInput);
