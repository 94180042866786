import React from 'react';

export const useSetEvents = (callRotationSchedules, setEvents) => {
  React.useEffect(() => {
    if (callRotationSchedules) {
      const data = callRotationSchedules?.map(item => ({
        id: item.id,
        color: item.color,
        title: item.group.name,
        start: item.startDate
      }));
      setEvents(data);
    }
  }, [callRotationSchedules, setEvents]);
};
