export const lambdaStartValues = ({ method, domain_settings }) => {
  if (method !== 'lambdaawsv2') {
    return;
  }

  let domainSettingsObj = domain_settings;
  if (typeof domain_settings === 'string') {
    domainSettingsObj = JSON.parse(domain_settings);
  }

  return {
    flag_search_period: domainSettingsObj?.flag_search_period
  };
};
