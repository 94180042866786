import { useSetEvents } from '../../CallRotation/Calendar';
import Calendar from '../components/Calendar';

const CallRotation = ({
  setCreateEvent,
  setEditEvent,
  dataSelected,
  setDataSelected,
  events,
  setEvents,
  setDataInitSelected,
  setDataEndSelected,
  recurrence,
  endOfRecurrence,
  schedulesList,
  setCurrentDates,
  teamId
}) => {
  useSetEvents(schedulesList, setEvents);

  const handleOpenDialogCreateEvent = () => {
    setCreateEvent(true);
  };

  const handleOpenDialogEditEvent = data => {
    setEditEvent(data.event.id);
  };

  return (
    <>
      <Calendar
        events={events}
        dataSelected={dataSelected}
        setDataSelected={setDataSelected}
        handleOpenDialogCreateEvent={handleOpenDialogCreateEvent}
        handleOpenDialogEditEvent={handleOpenDialogEditEvent}
        setDataInitSelected={setDataInitSelected}
        setDataEndSelected={setDataEndSelected}
        recurrence={recurrence}
        endOfRecurrence={endOfRecurrence}
        setCurrentDates={setCurrentDates}
        teamId={teamId}
      />
    </>
  );
};

export default CallRotation;
