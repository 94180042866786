import { useQuery } from 'react-query';

import axios from 'redux/axios';

const useGetVnets = ({ cloudId, resourceGroup }) => {
  return useQuery(
    ['/vnets', cloudId, resourceGroup],
    () =>
      axios
        .get(`/vnets?cloudId=${cloudId}&resourceGroup=${resourceGroup}`)
        .then(response => response.data.data),
    { refetchOnWindowFocus: false, enabled: Boolean(resourceGroup) }
  );
};

export default useGetVnets;
