import { Box, Card, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import LinkButton from 'componentsV3/LinkButton';
import QueryStats from 'icons/QueryStats';
import { Theme } from 'theme/v3';
import withThemeV3 from 'theme/v3/withTheme';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    }
  };
});
const CardProdct = (props: { title: string; linkTo: string; showMetricButton: boolean }) => {
  const { title, linkTo, showMetricButton } = props;
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <Card className={classes.root}>
      <Box display="flex" width="100%">
        <Box width="100%" display="flex" alignItems="center" position="relative">
          <Box display="flex" flexDirection="column" flex={1}>
            <Tooltip title="View product" placement="top-start">
              <Link to={linkTo} style={{ color: theme.palette.text.secondary }}>
                <Typography variant="h5" gutterBottom>
                  {title}
                </Typography>
              </Link>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <Box mt={3} mb={2}>
        <Divider /*style={{ background: theme.palette.inputBorder.default }} */ />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent={'flex-end'} alignItems="center">
        <Box display="flex" alignItems="center">
          {showMetricButton ? (
            <Link to="/metrics?tab=monitoring-performance">
              <LinkButton
                startIcon={
                  <QueryStats
                    style={{ fontSize: 14, marginLeft: theme.spacing(0.5), color: '#5FBC91' }}
                  />
                }>
                View metrics
              </LinkButton>
            </Link>
          ) : (
            <Link to={linkTo}>
              <LinkButton
                endIcon={
                  <ArrowForwardIos
                    style={{ fontSize: 14, marginLeft: theme.spacing(0.5), color: '#5FBC91' }}
                  />
                }>
                View product
              </LinkButton>
            </Link>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default withThemeV3(CardProdct);
