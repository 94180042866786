import React from 'react';
import { useState } from 'react';

import { Typography, Box, Tab, Tabs } from '@material-ui/core';

import LastDeploymentsGraph from '../Deployments/index';
import LatencyGraph from '../Latencies/index';
import PercentilesGraph from '../Percentiles/index';

import useStyles from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface LatencyTabsProps {
  service: number;
  application: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LatencyTabs(props: LatencyTabsProps) {
  const serviceId = props.service;
  const applicationId = props.application;

  const classes = useStyles();

  const [dashboardIndex, setDashboardIndex] = useState(1);

  const tabs = [
    {
      label: 'Latencies Percentiles'
    },
    {
      label: 'Realtime Latencies 🔥'
    },
    {
      label: 'Last Deployments'
    }
  ];

  const onChangeDashboard = function(value: number) {
    setDashboardIndex(value);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Tabs
          className={classes.tabs}
          classes={{
            flexContainer: classes.tabsFlexContainer
          }}
          indicatorColor="primary"
          textColor="primary"
          value={dashboardIndex}
          onChange={(_, value) => onChangeDashboard(value)}>
          {tabs.map((tab, index) => (
            <Tab className={classes.tab} label={tab.label} value={index} />
          ))}
        </Tabs>
      </Box>

      <CustomTabPanel value={dashboardIndex} index={0}>
        <PercentilesGraph service={serviceId} />
      </CustomTabPanel>
      <CustomTabPanel value={dashboardIndex} index={1}>
        <LatencyGraph service={serviceId} />
      </CustomTabPanel>
      <CustomTabPanel value={dashboardIndex} index={2}>
        <LastDeploymentsGraph application={applicationId} />
      </CustomTabPanel>
    </Box>
  );
}

export default LatencyTabs;
