import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { ElasticFormData } from 'views/ServicesHub/forms/monitoring/searchEngine/Elastic';

import { monitoringAdapter } from '../monitoring';

export function elasticAdapter(data: MonitoringBaseForm & ElasticFormData) {
  const domain = prepareDomain(data);
  // ex1: http://HOST:PORT/_cluster/health/staging_entities-orgid
  // ex2: http://USER:PASSWORD@HOST:PORT/_cluster/health/staging_entities-orgid
  // ex3: http://APIKEY@HOST:PORT/_cluster/health/staging_entities-orgid

  return {
    ...monitoringAdapter({
      ...data,
      method: 'elastic'
    }),
    domain,
    domainSettings: {
      ssl: data.protocol === 'https' ? true : false,
      monitoringType: data.monitoringType
    }
  };
}

const prepareDomain = (data: ElasticFormData) => {
  const protocol = data.protocol === 'https' ? 'https://' : 'http://';
  const index = data.index ? `/${data.index}` : '';

  if (data.monitoringType === 'userAndPass') {
    return `${protocol}${data.user}:${data.password}@${data.host}${
      data.port ? `:${data.port}` : ''
    }/_cluster/health${index}`;
  }

  if (data.monitoringType === 'apiKey') {
    return `${protocol}${data.apiKey}@${data.host}${
      data.port ? `:${data.port}` : ''
    }/_cluster/health${index}`;
  }

  return `${protocol}${data.host}${data.port ? `:${data.port}` : ''}/_cluster/health${index}`;
};
