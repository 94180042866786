import actions from '../../actions';

const products = (state, action) => {
  if (actions.MATRIX_FETCH_SUCCESSFUL === action.type) {
    const values = state.matrix_view;
    return {
      ...state,
      matrix_view: { ...action.payload, tab: values.tab }
    };
  }

  if (actions.MATRIX_RESET === action.type) {
    const values = state.matrix_view;
    return {
      ...state,
      matrix_view: {
        tab: values.tab
      }
    };
  }

  if (actions.MATRIX_CHANGE_TAB === action.type) {
    return {
      ...state,
      matrix_view: {
        ...state.matrix_view,
        tab: action.payload
      }
    };
  }

  if (actions.PRODUCT_RESET === action.type) {
    const values = state.products_view;
    return {
      ...state,
      products_view: {
        tab: values.tab
      }
    };
  }

  if (actions.PRODUCT_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_view: action.payload
    };
  }

  if (actions.PRODUCT_EDIT_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_edit: {
        ...state.products_edit,
        values: action.payload
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_applications: {
        ...state.products_applications,
        data: action.payload
      }
    };
  }

  if (actions.PRODUCT_STATUS_APPLICATIONS_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_status_applications: {
        ...state.products_status_applications,
        data: action.payload
      }
    };
  }

  if (actions.PRODUCT_RUNTIMES_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_runtimes: {
        ...state.products_runtimes,
        data: action.payload
      }
    };
  }

  if (actions.PRODUCT_SERVICES_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_services: {
        ...state.products_services,
        data: action.payload
      }
    };
  }

  if (actions.PRODUCT_TEAMS_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_teams: {
        ...state.products_teams,
        data: action.payload
      }
    };
  }

  return state;
};

export default products;
