export const queryGetTeamsByOrgUid = `#graphql
  query(
    $page: Float!
    $per_page: Float!
    $sort: String!
    $query: String!
  ) {
    getTeamsByOrgUid(
    page: $page, 
    per_page: $per_page, 
    sort: $sort
    query: $query
  ){
    data{
      orgUid
      name,
      id,
      members{
        id
      }
    },
    total
    }
  }
  `;
