import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type CassandraFormData = {
  host: string;
  port: string;
  user: string | undefined;
  password: string | undefined;
};

export function CassandraForm({ form }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'Host',
            name: 'host'
          }}
          InputComponent={Input}
        />

        <Input
          label="Port"
          name="port"
          inputRef={register}
          type="number"
          inputProps={{
            min: 1,
            max: 65535
          }}
          errors={errors}
        />
      </Box>

      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'User',
            name: 'user',
            required: true
          }}
          InputComponent={Input}
        />
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'password',
            label: 'Password',
            name: 'password',
            required: true
          }}
          InputComponent={Input}
        />
      </Box>
    </Box>
  );
}
