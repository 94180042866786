import { DialogContentText } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function TelegramForm({ form }: FormProps) {
  const classes = useStyles();
  const { register, errors } = form;

  return (
    <>
      <DialogContentText>Send a notification to the</DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.telegramId"
          label="Telegram"
          errors={errors}
          required
        />
      </DialogContentText>
    </>
  );
}
