import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

interface StyleProps {
  isDown: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  paperTextBottomTime: {
    marginRight: '2px',
    color: props => (props.isDown ? theme.palette.green[500] : theme.palette.red[500]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium
  },
  paperTextBottomPeriod: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.gray[900]
  },
  icon: {
    color: props => (props.isDown ? theme.palette.green[500] : theme.palette.red[500]),
    width: 16,
    height: 16
  }
}));
