import { ChangeEvent, useState, useEffect, useRef } from 'react';

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Divider,
  Typography,
  TextField,
  useTheme
} from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import { Title } from 'components/Title';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { useApplications } from 'hooks/queriesGraphQL/useApplications';
import { useFullApplications } from 'hooks/queriesGraphQL/useFullApplications';
import useDebounce from 'hooks/useDebounce';
import { usePermission } from 'hooks/usePermission';

import { COLUMNS } from './columns';
import { Filters, PageFilters } from './components/Filters';

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

export function ApplicationsList() {
  const globalClasses = useGlobalStyles();
  const theme = useTheme();

  const history = useHistory();

  const [applications, setApplications] = useState<any>([]);
  const [fullApplications, setFullApplications] = useState<any>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | ResourceStatusEnum | MultiFlowStatusEnum>('all');
  const [productId, setProductId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const [{ fetching, data, error }] = useApplications({
    page,
    perPage,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'name',
          value: search,
          isRegex: true
        },
        {
          key: 'status',
          value: status === 'all' ? '' : status,
          isRegex: false
        },
        {
          key: 'product_id',
          value: productId === 'all' ? '' : productId,
          isRegex: false
        }
      ]
    }),
    ignoreDate: 'true',
    functions: [
      { filter: { term: { status: 'unavailable' } }, weight: 10 },
      { filter: { term: { status: 'error' } }, weight: 10 },
      { filter: { term: { status: 'maintenance' } }, weight: 8 },
      { filter: { term: { status: 'pending' } }, weight: 6 },
      { filter: { term: { status: 'available' } }, weight: 4 },
      { filter: { term: { status: 'success' } }, weight: 4 },
      { filter: { term: { status: 'off' } }, weight: 2 }
    ],
    sortKey: 'updated_at',
    maxBoost: 10,
    scoreMode: 'sum',
    boostMode: 'replace',
    minScore: 1
  });

  const hasPermissionCreateApplication = usePermission('ApplicationController-post-/applications');

  const [{ fetching: fullApplicationsFetching, data: fullApplicationsData }] = useFullApplications({
    fullApplications: applications
  });

  useEffect(() => {
    if (data?.applicationsV2?.data) {
      setApplications(data?.applicationsV2.data);
    }
  }, [data?.applicationsV2.data]);

  useEffect(() => {
    if (fullApplicationsData?.fullApplications) {
      setFullApplications(fullApplicationsData?.fullApplications);
    }
  }, [fullApplicationsData?.fullApplications]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const handleApplyFilters = (filters: PageFilters) => {
    setStatus(filters.status);
    setProductId(filters.productId);
  };

  const handleClearFilters = () => {
    setStatus('all');
    setProductId('all');
  };

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const dataToDysplay = () => {
    if (!applications.length && !fetching) return [];

    if (!fullApplications.length || fullApplicationsFetching) return applications;

    return fullApplications;
  };

  const ApplicationsResult = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    return (
      <>
        {dataToDysplay()?.length ? <Divider /> : null}

        <DataTable
          columns={COLUMNS}
          data={dataToDysplay()}
          noDataComponent="No results found"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeSize}
          paginationComponentOptions={{
            rowsPerPageText: 'Items per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false
          }}
          paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
          paginationPerPage={perPage}
          paginationTotalRows={data?.applicationsV2?.total}
          paginationServer
          pagination
          progressComponent={<CircularProgress color="primary" size="3rem" />}
          progressPending={fetching}
          highlightOnHover
          striped
          pointerOnHover={true}
          onRowClicked={(application, e) => {
            if (e.ctrlKey) {
              return window.open(`applications/${application.uid}`, '_blank');
            }
            history.push(`applications/${application.uid}`);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'Monitoring Aggregator' },
          { link: `/external-services`, label: 'Application Center' }
        ]}
      />

      <Title title="Application Center">
        <Box>
          <Button
            component={Link}
            to="/services-hub/new?type=application"
            title="Create new application"
            disabled={!hasPermissionCreateApplication}>
            Application
          </Button>
        </Box>
      </Title>

      <Box
        display="flex"
        justifyContent="space-between"
        mb={7.5}
        gridGap={theme.spacing(1.5)}
        {...{
          ref: searchContainerRef
        }}>
        <TextField
          name="search"
          label="Search"
          variant="outlined"
          onChange={handleChangeSearch}
          placeholder="Search by name"
          InputProps={{
            endAdornment: <Search />,
            className: globalClasses.input
          }}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
        />

        <MuiButton
          className={globalClasses.filterButton}
          variant="outlined"
          color="primary"
          onClick={handleToggleFilters}>
          <FilterList />
        </MuiButton>
      </Box>

      {isFiltersOpen && (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      )}

      {ApplicationsResult()}
    </>
  );
}
