import { ServiceNow, UpdateServiceNowPayload } from 'types/external/itsmIntegration/serviceNow';

import { ServiceNowData } from 'views/ServicesHub/layouts/ItsmServiceNow';

export function serviceNowStartValuesAdapter(integration: ServiceNow): ServiceNowData {
  const {
    configuration: { ...configurationProps },
    ...props
  } = integration;

  return {
    ...props,
    ...configurationProps
  };
}

export function updateServiceNowAdapter(integration: ServiceNowData): UpdateServiceNowPayload {
  const { subdomain, clientId, clientSecret, username, password, uid, ...props } = integration;

  return {
    ...props,
    origin: 'service-now',
    configuration: {
      subdomain,
      clientId,
      clientSecret,
      username,
      password
    }
  };
}
