import { CSSProperties, ReactChild } from 'react';

import EventType from 'constants/EventType';

import { Box } from '@material-ui/core';
import { green, red, blue, orange, yellow } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {
  Build,
  CompareArrows,
  Description,
  DoneAll,
  Restore,
  TrendingUp
} from '@material-ui/icons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Link as RouterLink } from 'react-router-dom';

import useComposeIncidentLink from 'hooks/useComposeIncidentLink';
import Deployed from 'icons/Deployed';
import IncidentAlarmed from 'icons/IncidentAlarmed';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const Item = ({
  icon,
  description,
  createdAt,
  textStylePrimary,
  textStyleSecondary,
  inlineDate,
  applicationName,
  productId,
  applicationId
}: {
  icon: ReactChild;
  description: ReactChild;
  createdAt: Date;
  textStylePrimary?: CSSProperties;
  textStyleSecondary?: CSSProperties;
  applicationName?: string;
  productId: number | undefined;
  applicationId: number | undefined;
  inlineDate: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box>
        {createdAt && inlineDate && (
          <Box display="flex" alignItems="center">
            <Box mr={1}>{icon}</Box>
            <Typography variant="body2" color="textPrimary" style={textStylePrimary}>
              {description}
              <span title={dayjs(new Date(createdAt)).format('MMMM D, YYYY HH:mm (zzz)')}>
                {' '}
                ({dayjs(new Date(createdAt)).format('MM/DD, HH:mm z')})
              </span>
            </Typography>
          </Box>
        )}

        {createdAt && !inlineDate && (
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>{icon}</Box>
              <Typography variant="body2" color="textPrimary" style={textStylePrimary}>
                {description}
              </Typography>
            </Box>

            <Typography variant="body2" color="textSecondary" style={textStyleSecondary}>
              <span title={dayjs(new Date(createdAt)).format('MMMM D, YYYY HH:mm (zzz)')}>
                {' '}
                {dayjs(new Date(createdAt)).format('MM/DD, HH:mm z')}
              </span>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const LastUpdate = ({
  type,
  prefix = '',
  incidentId,
  applicationId,
  productId,
  ...props
}: {
  type?: EventType;
  prefix?: string;
  incidentId?: number;
  applicationId?: number;
  applicationName?: string;
  productId?: number;
  createdAt: Date;
  textStylePrimary?: CSSProperties;
  textStyleSecondary?: CSSProperties;
  inlineDate: boolean;
}) => {
  const incidentLink = useComposeIncidentLink()({
    incidentId: incidentId || ''
  });

  if (type === EventType.Deploy) {
    return (
      <Item
        icon={<Deployed />}
        description={`${prefix}Deployed`}
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.Hotfix) {
    return (
      <Item
        icon={<Build style={{ color: red[300], fontSize: 18 }} />}
        description={`${prefix}Hotfix`}
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.Rollback) {
    return (
      <Item
        icon={<Restore style={{ color: blue[600], fontSize: 18 }} />}
        description={`${prefix}Rollback`}
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.PostMortem) {
    return (
      <Item
        icon={<Description style={{ color: yellow[700], fontSize: 18 }} />}
        description={
          <>
            {prefix}
            {'Post mortem '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
          </>
        }
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.Alarmed) {
    return (
      <Item
        icon={<IncidentAlarmed />}
        description={
          <>
            {prefix}
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' created'}
          </>
        }
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.Acknowledged) {
    return (
      <Item
        icon={<DoneAll style={{ color: blue[600], fontSize: 18 }} />}
        description={
          <>
            {prefix}
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' acknowledged'}
          </>
        }
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.ChangeDescription) {
    return (
      <Item
        icon={<CompareArrows style={{ color: orange[400], fontSize: 18 }} />}
        description={
          <>
            {prefix}
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' changed'}
          </>
        }
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  if (type === EventType.Resolved) {
    return (
      <Item
        icon={<TrendingUp style={{ color: green[600], fontSize: 18 }} />}
        description={
          <>
            {prefix}
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' resolved'}
          </>
        }
        productId={productId}
        applicationId={applicationId}
        {...props}
      />
    );
  }

  return null;
};

export default LastUpdate;
