import * as z from 'zod';

const commonProperties = {
  name: z.string().nonempty({ message: 'Cloud name is required' }),
  newCloud: z.any().optional()
};

const schema = z.union([
  z.object({
    ...commonProperties,
    provider: z.literal('aws'),
    accessKeyId: z.string().nonempty({ message: 'AccessKeyId is required' }),
    secretAccessKey: z.string().nonempty({ message: 'SecretAccessKey is required' })
  }),
  z.object({
    ...commonProperties,
    provider: z.literal('azure'),
    clientId: z.string().nonempty({ message: 'Client Id is required' }),
    clientSecret: z.string().nonempty({ message: 'Client secret is required' }),
    tenantId: z.string().nonempty({ message: 'TenantId is required' }),
    subscriptionId: z.string().nonempty({ message: 'SubscriptionId is required' })
  }),
  z.object({
    ...commonProperties,
    provider: z.literal('custom')
  })
]);

export type FormData = z.infer<typeof schema>;

export default schema;
