import React from 'react';

import { MonitoringStatus } from 'constants/MonitoringStatusV1';

import {
  Tooltip,
  CircularProgress,
  Backdrop,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  Box,
  Typography,
  Divider
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useProductPermission } from 'hooks/useProductPermission';
import axios from 'redux/axios';

import Dialog from '../Deploy/Dialog';
import HitsAndFailures from '../HitsAndFailures';
import LatencyIncidentsModal from '../LatencyIncidentsModal';

import DeleteMaintenanceModal from './DeleteMaintenanceModal';
import DeleteModal from './DeleteModal';
import useAppActions from './useAppActions';
import useWebHookActions from './useWebhookActions';

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  },
  flexVertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    flex: 1,
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.primary,
    fontSize: 12
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  divider: {
    margin: theme.spacing(2, 0, 3)
  },
  hitButtonMargin: {
    marginTop: theme.spacing(2)
  }
});

const Buttons = ({
  classes,
  application,
  monitoringActive,
  isDeleteModalOpen,
  handleCloseDeleteModal,
  reexecuteQueryUniqueResourceStatus,
  maintenance,
  submitDeleteMaintenance,
  monitoringStatus,
  addons
}) => {
  const shouldShowResponseTimeDegradation = useFeatureFlagUnleash('showResponseTimeDegradation', {
    queryString: true
  });

  const [loading, setLoading] = React.useState(true);
  const [version, setVersion] = React.useState(`${dayjs().format('YYYYMMDDHHmmss')}`);

  const {
    dependencies,
    isLoading,
    handleToggleMonitoring,
    product,
    productId,
    applicationId,
    handleDeleteApplicationGraphQL
  } = useAppActions({
    isDeleteModalOpen,
    monitoringActive,
    setLoading,
    applicationType: application?.applicationsSettings?.type,
    reexecuteQueryUniqueResourceStatus,
    maintenance,
    submitDeleteMaintenance
  });

  const { deployTimeline, isWebhook, isAbleToMonitor } = useWebHookActions({
    application,
    productId,
    applicationId,
    version
  });

  const [deployType, setDeployType] = React.useState('deploy');

  React.useEffect(() => {
    setLoading(false);
  }, [application]);

  const permissions = {
    edit: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-put-/products/:productId/applications/:id'
    }),
    remove: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-delete-/products/:productId/applications/:id'
    }),
    activateMonitoring: useProductPermission({
      productId: String(product?.id),
      target: 'ApplicationController-post-/products/:productId/applications/:id/monitoring/activate'
    }),
    deactivateMonitoring: useProductPermission({
      productId: String(product?.id),
      target:
        'ApplicationController-delete-/products/:productId/applications/:id/monitoring/deactivate'
    }),
    deploy: useProductPermission({
      productId: String(product?.id),
      target: 'TimelineController-post-/products/:productId/applications/:applicationId/timeline'
    }),
    latencyToIncidents: useProductPermission({
      productId: String(product?.id),
      target:
        'ApplicationController-put-/products/:productId/applications/:id/latencyIncidentsConfig'
    })
  };

  const [open, setOpen] = React.useState(false);

  const [isDeleteMaintenanceModalOpen, setIsDeleteMaintenanceModalOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (deployDate, descriptionValue) => {
    deployTimeline(descriptionValue, deployDate, deployType);
    handleClose();
  };

  const [isLatencyModalOpen, setIsLatencyModalOpen] = React.useState(false);

  const setHandleToggleMonitoring = () => {
    if (!monitoringActive) {
      handleToggleMonitoring();
    } else {
      if (maintenance?.id > 0) {
        setIsDeleteMaintenanceModalOpen(true);
      } else {
        handleToggleMonitoring();
      }
    }
  };

  const handleDeleteMaintenance = () => {
    setIsDeleteMaintenanceModalOpen(false);
    handleToggleMonitoring();
    submitDeleteMaintenance(maintenance);
  };

  const isInMaintenance = (() => {
    if (!monitoringStatus) {
      return maintenance?.status === 'Initialized';
    }

    return monitoringStatus === MonitoringStatus.Maintenance;
  })();

  const MonitoringToggle = () => (
    <FormControlLabel
      title={
        isInMaintenance
          ? 'Enable monitoring disabled while maintenance in progress'
          : isAbleToMonitor
          ? 'Activate Monitoring'
          : null
      }
      disabled={
        isInMaintenance || !permissions.activateMonitoring || !isAbleToMonitor || loading || !addons
      }
      control={
        <Switch color="primary" checked={monitoringActive} onChange={setHandleToggleMonitoring} />
      }
    />
  );

  const AddHitsAndFailuresButton = props => (
    <Button
      id={`sendHitsAndFailuresButton`}
      disabled={!monitoringActive}
      className={classes.hitButtonMargin}
      variant="contained"
      color="primary"
      startIcon={<Icon>input</Icon>}
      fullWidth
      {...props}>
      Hits and failures API
    </Button>
  );

  const hitsAndFailures = props =>
    !monitoringActive ? (
      <Tooltip title="Active monitoring to send hits and failures">
        <span style={{ width: '100%' }}>
          <AddHitsAndFailuresButton {...props} />
        </span>
      </Tooltip>
    ) : (
      <AddHitsAndFailuresButton {...props} />
    );

  const queryKey = ['latencyConfig', productId, applicationId];
  const {
    data: latencyConfig,
    isLoading: loadingLatencyConfig,
    isFetching: fetchingLatencyConfig
  } = useQuery(
    queryKey,
    async () => {
      const {
        data: { data }
      } = await axios.get(
        `/products/${productId}/applications/${applicationId}/latencyIncidentsConfig`
      );

      const { p50xp90_anomaly_percent, p50xp95_anomaly_percent } = data.applicationsSettings;

      return {
        p50xp90_anomaly_percent,
        p50xp95_anomaly_percent
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: isLatencyModalOpen
    }
  );

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" size={90} thickness={5} />
      </Backdrop>
      <Box className={classes.flexVertical}>
        <Box display="flex" alignItems="center">
          {isAbleToMonitor ? (
            <MonitoringToggle />
          ) : (
            <Tooltip
              id={`activateMonitoringToggle`}
              title="Can't activate monitoring because the agent is not installed">
              <span>
                <MonitoringToggle />
              </span>
            </Tooltip>
          )}
          <Typography>{`Monitoring is ${monitoringActive ? 'active' : 'inactive'}`}</Typography>
        </Box>

        <Divider className={classes.divider} />

        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Box display="flex" width="100%" gridGap={16}>
            <IconButton
              id={`registerDeployIcon`}
              title="Register Deploy"
              color="primary"
              variant="contained"
              disabled={!permissions.deploy}
              onClick={handleOpen}
              classes={{
                root: classes.button,
                label: classes.label
              }}>
              <Icon>cloud_upload</Icon>
              Register Deploy
            </IconButton>

            {shouldShowResponseTimeDegradation && (
              <IconButton
                id={`configureIncidentsToLatencyIcon`}
                title="Configure incidents to latency"
                color="primary"
                variant="contained"
                disabled={!permissions.latencyToIncidents}
                onClick={() => setIsLatencyModalOpen(true)}
                classes={{
                  root: classes.button,
                  label: classes.label
                }}>
                <Icon>stacked_line_chart</Icon>
                Response Time Degradation
              </IconButton>
            )}

            <Dialog
              handleClose={handleClose}
              handleSave={handleSave}
              open={open}
              application={application}
              version={version}
              deployType={deployType}
              setDeployType={setDeployType}
              setVersion={setVersion}
            />

            <LatencyIncidentsModal
              open={isLatencyModalOpen}
              handleClose={() => setIsLatencyModalOpen(false)}
              initialConfig={latencyConfig}
              loadingLatencyConfig={loadingLatencyConfig}
              fetchingLatencyConfig={fetchingLatencyConfig}
              productId={productId}
              applicationId={applicationId}
              queryKey={queryKey}
            />
          </Box>

          {isWebhook && (
            <HitsAndFailures
              openerComponent={hitsAndFailures}
              application={application}
              productId={productId}
              sterasId={application.sterasId}
              orgUid={application.org.uid}
            />
          )}
        </Box>
      </Box>

      <DeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDeleteApplication={handleDeleteApplicationGraphQL}
        isLoading={isLoading}
        permissions={permissions}
        dependencies={{
          ...dependencies,
          applicationDependencies: {
            servicesAddons: dependencies?.applicationDependencies?.servicesAddons?.filter(
              addon => addon?.multiFlow === false
            ),
            applicationAddons: dependencies?.applicationDependencies?.applicationAddons
          }
        }}
      />

      <DeleteMaintenanceModal
        isDeleteModalOpen={isDeleteMaintenanceModalOpen}
        handleCloseDeleteModal={setIsDeleteMaintenanceModalOpen}
        handleDeleteMaintenance={handleDeleteMaintenance}
        maintenance={maintenance}
      />
    </>
  );
};

export default withStyles(styles)(Buttons);
