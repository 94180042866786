import { ClientOptions } from 'urql';

type FetchOptions = ClientOptions['fetchOptions'];

export function getUrqlFetchOptions(additionalOptions: Partial<FetchOptions> = {}): FetchOptions {
  return {
    credentials: 'include',
    ...additionalOptions
  };
}
