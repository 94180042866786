import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacing(2, 0)
  },
  inputLabel: {
    marginRight: spacing(2)
  },
  input: {
    maxWidth: 100,
    maxHeight: 45
  },
  inputHelperText: {
    color: 'red',
    width: '80%',
    textAlign: 'left'
  },
  budget: {
    padding: spacing(0, 0, 1, 0)
  },
  hourLabel: {
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    padding: spacing(0, 2, 0, 0)
  },
  margin: {
    color: 'white',
    textAlign: 'center',
    alignItems: 'center',
    height: 35,
    maxWidth: 800,
    borderRadius: '10px 0px 0px 0px',
    padding: spacing(3, 0, 0, 0)
  },
  outMargin: {
    height: spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: spacing(1, 2),
    backgroundColor: '#F9A990',
    borderRadius: '5px 0px 0px 5px'
  },
  outMarginWithRadius: {
    height: spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: spacing(1, 2),
    backgroundColor: '#F9A990',
    borderRadius: '5px 5px 5px 5px'
  },
  insideMargin: {
    height: spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: spacing(1, 2),
    backgroundColor: '#2D9CDB',
    borderRadius: '0px 5px 5px 0px'
  },
  insideMarginWithRadius: {
    height: spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: spacing(1, 2),
    backgroundColor: '#2D9CDB',
    borderRadius: '5px 5px 5px 5px'
  },
  point: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    maxWidth: 500,
    padding: spacing(3, 0, 0, 0)
  },
  outMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#F9A990',
    borderRadius: 20
  },
  insideMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#2D9CDB',
    borderRadius: 20
  },
  legend: {
    fontSize: 14,
    padding: spacing(0, 0, 0, 1)
  },
  button: {
    width: 30,
    marginLeft: 5,
    padding: spacing(1, 2),
    borderRadius: 8,
    border: '1px solid'
  }
}));
