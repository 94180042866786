import React from 'react';

import { Typography, CircularProgress, Box } from '@material-ui/core';
import { default as axiosDefault } from 'axios';
import { parseUrl } from 'query-string';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router';

const SlackConfirmation = () => {
  const { search } = useLocation();
  const {
    query: { code }
  } = parseUrl(search);

  const [validateCode, { isLoading, isSuccess, data, error }] = useMutation(
    async () => {
      return await axiosDefault({
        url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
        method: 'post',
        withCredentials: true,
        data: {
          query: `
              mutation {
                saveSlackToken (data: {slackRedirectUri: "${
                  window.location.href.split('?')[0]
                }", slackTemporaryCode: "${code}" } ) {
                  ok
                  appId
                  authedUser {
                    id
                  }
                  scope
                  tokenType
                  accessToken
                  botUserId
                  team {
                    id
                    name
                  }
                  enterprise {
                    id
                    name
                  }
                  isEnterpriseInstall
                  incomingWebhook {
                    channel
                    channelId
                    configurationUrl
                    url
                  }
                }
              }
            `
        }
      }).then(({ data: { data } }) => {
        const payload = {
          incoming_webhook: data.saveSlackToken.incomingWebhook,
          ...data.saveSlackToken
        };

        delete payload.incomingWebhook;

        return {
          data: payload
        };
      });
    },
    {
      onSuccess: ({ data }) => {
        if (!data.error) {
          window.opener.sessionStorage.setItem('slackIntegration', JSON.stringify(data));
        }
      }
    }
  );

  React.useEffect(() => {
    validateCode();
  }, [validateCode]);

  return (
    <>
      {isLoading && (
        <>
          <Typography variant="h4">Integrating slack...</Typography>
          <Box marginTop={2} component={CircularProgress} />
        </>
      )}

      {error && (
        <>
          <Typography variant="h6" gutterBottom>
            Oops! Integration failed
          </Typography>
          <Typography variant="subtitle1">
            server response: {error.response?.data?.message}
          </Typography>
        </>
      )}

      {isSuccess && !data.error && (
        <>
          <Typography variant="h6" gutterBottom>
            Integrated successfully!
          </Typography>
          <Typography variant="subtitle1">You can close this tab now</Typography>
          {window.close()}
        </>
      )}
    </>
  );
};

export default SlackConfirmation;
