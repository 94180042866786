export const UpdateExternalServiceMutation = `#graphql
  mutation ($uid:String!, $data: UpdateExternalIntegrationInput!) {
    updateExternalIntegration(uid: $uid, data: $data) {
      uid
      responders {
        type
        uid
        id
      }
    }
  }
`;
