import { CassandraFormData } from 'views/ServicesHub/forms/monitoring/database/Cassandra';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function cassandraAdapter(data: MonitoringCommomData & CassandraFormData) {
  const auth = data.user && data.password ? `@${data.user}/${data.password}` : '';
  const domain = `${data.host}:${data.port}${auth}`;
  // ex: HOST:PORT@USER/PASSWORD

  return {
    ...monitoringAdapter({ ...data, method: 'cassandra' }),
    domain
  };
}
