import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useQuery } from 'urql';

import { Columns } from './Columns';
import { useStyles } from './styles';

const GetUptimesByServiceId = `#graphql
  query(
    $serviceId: Int!
  ) {
    uptimeV2(serviceId: $serviceId) {
        online1Hour,
        online6Hours,
        online12Hours,
        online24Hours,
        online7Days,
        online15Days,
        online30Days,
        online90Days,
        online180Days,
        online365Days
    }
  }
`;

export function Uptime({ serviceId, isMonitoring }: { serviceId: number; isMonitoring: boolean }) {
  const classes = useStyles();

  const [resultUptime, reexecuteQuery] = useQuery({
    query: GetUptimesByServiceId,
    pause: !serviceId,
    variables: {
      serviceId
    }
  });

  const data = resultUptime?.data?.uptimeV2 && isMonitoring ? [resultUptime?.data?.uptimeV2] : [];

  const { fetching } = resultUptime;

  React.useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 10);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  if (fetching) {
    return (
      <Box display="flex" mt={4} alignItems="center" justifyContent="center">
        <CircularProgress color="primary" size={50} thickness={4} />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <DataTable noHeader columns={Columns} data={data} noDataComponent="Error fetching data" />
    </Box>
  );
}
