import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { GRPCFormData } from 'views/ServicesHub/forms/monitoring/others/GRPC';

import { monitoringAdapter } from '../monitoring';

export function grpcAdapter(data: MonitoringBaseForm & GRPCFormData) {
  const domain = `${data.host}:${data.port}`;
  // ex: HOST:PORT

  return {
    ...monitoringAdapter({
      ...data,
      method: 'grpc'
    }),
    domain
  };
}
