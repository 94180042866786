import { Box, Checkbox, FormControlLabel, FormGroup, Icon, Tooltip } from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import useQueryParams from 'helpers/useQueryParams';
import { useStyles } from 'views/Monitoring/View/styles';
import { HostAndPortWithSecrets } from 'views/ServicesHub/components/HostAndPortWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type KafkaFormData = {
  username: string;
  password: string;
  mechanism: string;
  authentication: boolean;
  tls: boolean;
  sasl: boolean;
  brokers: { key: string; value: string }[];
  topic: string;
  consumer: string | undefined;
  lag: number | undefined;
  lagTolerance: string;
};

export function KafkaForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;
  const queryParams = useQueryParams();
  const withLag =
    queryParams?.get('lag-tolerance') === 'true' ||
    watch('consumer') !== undefined ||
    watch('lagTolerance') === 'true';
  const auth = watch('authentication');
  const classes = useStyles();

  const { fields, append: addFields, remove: removeFields } = useFieldArray({
    control,
    name: 'brokers'
  });

  return (
    <>
      <input ref={register} type="hidden" name="lagTolerance" value={String(withLag)} />
      {domainType !== 'url' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <HostAndPortWithSecrets
            form={form}
            name="brokers"
            fields={fields}
            handleAdd={addFields}
            handleRemove={removeFields}
            label="Host"
            valueLabel="Port"
            required={true}
            type={'number'}
            inputProps={{
              min: 1,
              max: 65535
            }}
          />

          <Input label="Topic" name="topic" inputRef={register} errors={errors} />

          {withLag && (
            <Box display="flex" gridGap="1rem">
              <Input label="Consumer Group" name="consumer" inputRef={register} errors={errors} />
              <Input
                errors={errors}
                label="Lag Tolerance"
                name="lag"
                inputRef={register}
                type="number"
                inputProps={{
                  min: 0
                }}
              />
            </Box>
          )}

          <Controller
            control={control}
            name="authentication"
            render={({ value, onChange }) => {
              return (
                <FormGroup>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      style={{ marginRight: 0 }}
                      label="Authentication"
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(_, val) => onChange(val)}
                          color="primary"
                        />
                      }
                    />
                    <Tooltip title="Check it up if you are going to monitor a Kafka queue with Authentication">
                      <Icon className={classes.descriptionIcon}>help</Icon>
                    </Tooltip>
                  </Box>
                </FormGroup>
              );
            }}
          />
          {auth && (
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Box display="flex" gridGap="1rem">
                <InputWithSecrets
                  control={control}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  TextFieldProps={{
                    type: 'text',
                    label: 'Username',
                    name: 'username'
                  }}
                  InputComponent={Input}
                />

                <InputWithSecrets
                  control={control}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  TextFieldProps={{
                    type: 'password',
                    label: 'Password',
                    name: 'password'
                  }}
                  InputComponent={Input}
                />
              </Box>
              <Box display="flex" gridGap="1rem">
                <Select
                  name="mechanism"
                  label="Mechanism"
                  inputRef={register}
                  defaultValue=""
                  control={control}
                  options={[
                    { value: 'PLAIN', label: 'Plain' },
                    { value: 'SCRAM-SHA-512', label: 'Scram' },
                    { value: 'OAUTH', label: 'OAuth' },
                    { value: 'GSSAPI', label: 'Gssapi' }
                  ]}
                />

                <Controller
                  control={control}
                  name="tls"
                  render={({ value, onChange }) => {
                    return (
                      <FormGroup>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            style={{ marginRight: 0 }}
                            label="TLS"
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(_, val) => onChange(val)}
                                color="primary"
                              />
                            }
                          />
                          <Tooltip title="Check it up if you are going to monitor a Kafka TLS Connection">
                            <Icon className={classes.descriptionIcon}>help</Icon>
                          </Tooltip>
                        </Box>
                      </FormGroup>
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="sasl"
                  render={({ value, onChange }) => {
                    return (
                      <FormGroup>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            style={{ marginRight: 0 }}
                            label="SASL"
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(_, val) => onChange(val)}
                                color="primary"
                              />
                            }
                          />
                          <Tooltip title="Check it up if you are going to monitor a Kafka SASL Connection">
                            <Icon className={classes.descriptionIcon}>help</Icon>
                          </Tooltip>
                        </Box>
                      </FormGroup>
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
