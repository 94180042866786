import { useState } from 'react';

import { useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import actions from 'redux/actions';

import { Contact } from '../../ContactMethod';

export function useCreateMethod({
  currentContact,
  onClose
}: {
  currentContact: Contact;
  onClose: () => void;
}) {
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const [{ fetching: fetchingResendVerificationCode }, resendVerificationCode] = useMutation(
    `
      mutation(
        $providerId: Int!
      ) {
        resendVerificationCode(
          providerId: $providerId
        )
      }
    `
  );

  const [{ fetching: fetchingValidatePhoneNumber }, validatePhoneNumber] = useMutation<{
    validatePhoneNumber: boolean;
  }>(
    `
      mutation(
        $phoneNumber: String!
        $code: String!
      ) {
        validatePhoneNumber(
          phoneNumber: $phoneNumber
          code: $code
        )
      }
    `
  );
  const queryCache = useQueryCache();

  async function handleCodeComplete(data: string) {
    const result = await validatePhoneNumber({
      phoneNumber: currentContact.value,
      code: data
    });

    if (result.error) {
      setError(result.error.message);
      return;
    }

    if (!result.data?.validatePhoneNumber) {
      setError('Invalid code');
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Contact method verified successfully'
    });

    queryCache.invalidateQueries('userProviders');

    onClose();
  }

  async function handleResendVerificationCode() {
    const result = await resendVerificationCode({
      providerId: currentContact.id
    });

    if (result.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: result.error.message }
      });
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Verification code resent successfully'
    });
  }

  return {
    isLoading: fetchingValidatePhoneNumber || fetchingResendVerificationCode,
    error,
    handleCodeComplete,
    handleResendVerificationCode
  };
}
