import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';

import { actions } from '../actionCreators';

function* prepareSave(axios, action) {
  try {
    const {
      data: { data: product }
    } = yield axios.post('/products/availability', action.payload);
    yield put(push(`/products/${product.id}/availability/applications/new`));
  } catch (err) {
    yield put({
      type: action.type,
      payload: err
    });
  }
}

export default function* watchProductSave(axios) {
  yield takeLatest(actions.PRODUCT_AVAILABILITY_SAVE, prepareSave, axios);
}
