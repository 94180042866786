import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import useCopy from 'hooks/useCopy';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => ({
  pre: {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbarWidth: 'auto',
    scrollbarColor: '#888888 #595959',

    /* Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
      width: 25,
      minHeight: 20
    },

    '&::-webkit-scrollbar-track': {
      background: '#595959'
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888888',
      borderRadius: theme.spacing(2),
      border: '7px solid #595959'
    },

    '&::-webkit-scrollbar-corner': {
      background: 'none'
    },

    color: 'white',
    border: '1px solid rgba(29,28,29,.04)',
    borderRadius: 4,
    background: '#595959',
    whiteSpace: 'pre-wrap',
    textOverflow: 'clip',
    padding: theme.spacing(1),
    maxHeight: 200,
    overflowY: 'scroll'
  },
  box: {
    backgroundColor: '#F5F5F5	',
    borderRadius: 4,
    border: '1px solid lightGrey',
    padding: theme.spacing(2, 3, 0)
  },
  checked: {
    color: theme.palette.primary.main
  }
}));

function DialogBox({ text, title, link, applicationType = '', firstAccess = false }) {
  const classes = useStyles();

  const { handleCopy, copiedSuccessfully } = useCopy();

  return (
    <Box className={classes.box}>
      <Grid container xs={12} justifyContent="space-between" alignItems="center">
        <Typography>{title}</Typography>

        <IconButton
          disableRipple
          color="primary"
          className={classes.buttonCopy}
          style={{
            padding: 0
          }}
          onClick={() => {
            handleCopy(text);

            const eventsMap = {
              webhook: firstAccess ? 'fa_copy_to_system_hook' : 'copy_to_system_hook',
              internal: firstAccess
                ? 'fa_new_iaea_copy_code_link_button'
                : 'new_iaea_copy_code_link_button',
              externalWithAddons: firstAccess
                ? 'fa_new_eaea_copy_code_link_button'
                : 'new_eaea_copy_code_link_button'
            };

            if (eventsMap[applicationType]) {
              analyticsEvent(eventsMap[applicationType]);
            }
          }}>
          {/* FIXME: <button> cannot appear as a descendant of <button>. */}
          <ButtonSimple
            style={{
              paddingTop: 2,
              paddingBottom: 2
            }}
            text={copiedSuccessfully ? 'Copied!' : 'Copy code'}
            color="primary"
          />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <pre
          className={classes.pre}
          onClick={event => {
            const range = document.createRange();
            range.selectNodeContents(event.target);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }}>
          {text}
        </pre>
      </Grid>
      <Box marginTop={2} marginBottom={3}>
        <Typography>
          Doubts?{' '}
          <a
            target="_blank"
            className={classes.checked}
            href={link}
            rel="noreferrer"
            onClick={() => {
              const eventsMap = {
                webhook: firstAccess ? 'fa_read_article_system_hook' : 'read_article_system_hook',
                internal: firstAccess
                  ? 'fa_new_iaea_read_article_link_button'
                  : 'new_iaea_read_article_link_button',
                externalWithAddons: firstAccess
                  ? 'fa_new_eaea_read_article_link_button'
                  : 'new_eaea_read_article_link_button'
              };

              if (eventsMap[applicationType]) {
                analyticsEvent(eventsMap[applicationType]);
              }
            }}>
            Read this article
          </a>{' '}
          or{' '}
          <a
            target="_blank"
            className={classes.checked}
            href="https://elvenworks.zendesk.com/hc/en-us/requests/new"
            rel="noreferrer"
            onClick={() => {
              const eventsMap = {
                webhook: firstAccess ? 'fa_open_ticket_system_hook' : 'open_ticket_system_hook',
                internal: firstAccess
                  ? 'fa_new_iaea_open_ticket_link_button'
                  : 'new_iaea_open_ticket_link_button',
                externalWithAddons: firstAccess
                  ? 'fa_new_eaea_open_ticket_link_button'
                  : 'new_eaea_open_ticket_link_button'
              };

              if (eventsMap[applicationType]) {
                analyticsEvent(eventsMap[applicationType]);
              }
            }}>
            open a ticket
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
}

export default withThemeV3Hoc(DialogBox);
