import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  subTitle: {
    fontWeight: 'bold',
    fontSize: 24
  },
  infoSectionTitle: {
    fontWeight: 'bold'
  },
  infoSectionDescription: {
    color: theme.palette.gray[500]
  },
  sectionDivider: {
    border: `${theme.palette.grey[300]} 1px solid`
  }
}));
