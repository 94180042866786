import { Box, Divider, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { ButtonDefault } from 'oldComponents/Button';
import { useParams } from 'react-router';

import useStyles from './style';

const MaintenanceForceFinishForm = ({ onClose, startValues, onForceFinish, handleClose }) => {
  const classes = useStyles();

  const { productId } = useParams();

  const isSameDay =
    dayjs(new Date(startValues?.finish)).startOf('day') >
    dayjs(new Date(startValues?.start)).startOf('day')
      ? false
      : true;

  const onSubmit = data => {
    data.preventDefault();

    onForceFinish({
      id: startValues?.id,
      serviceId: startValues?.serviceId,
      start: dayjs(startValues?.start).format('YYYY-MM-DDTHH:mm:ssZ'),
      finish: dayjs(startValues?.finish).format('YYYY-MM-DDTHH:mm:ssZ'),
      productId: Number(productId)
    });

    handleClose();
  };
  const format = isSameDay ? 'HH:mm' : 'MM/DD HH:mm';

  return (
    <form name="deleteMaintenance" onSubmit={onSubmit}>
      <Typography className={classes.paragraphText}>
        Maintenance started at <strong>{dayjs(new Date(startValues?.start)).format(format)}</strong>{' '}
        and ends at <strong>{dayjs(new Date(startValues?.finish)).format(format)}</strong>.
      </Typography>
      <Typography className={classes.paragraphText}>
        Are you sure you want to end maintenance?
      </Typography>
      <Divider className={classes.dividerTop} />

      <Box display="flex" justifyContent="flex-end">
        <ButtonDefault onClick={onClose} color="primary">
          Cancel
        </ButtonDefault>

        <ButtonDefault
          color="primary"
          variant="contained"
          type="submit"
          style={{ marginLeft: '24px' }}>
          End maintenance
        </ButtonDefault>
      </Box>
    </form>
  );
};

export default MaintenanceForceFinishForm;
