import { useQuery } from 'urql';

const EnvironmentDependenciesQuery = `#graphql
  query($environmentId: Float!) {
    environmentDependencies(environmentId: $environmentId) {
      resources {
        id
        name
        uid
      }
      synthetics {
        id
        name
        uid
      }
    }
  }
`;

type EnvironmentDependencies = {
  environmentDependencies: {
    resources: {
      id: number;
      name: string;
      uid: string;
    }[];
    synthetics: {
      id: number;
      name: string;
      uid: string;
    }[];
  };
};

type EnvironmentDependenciesVariables = {
  environmentId: number;
};

export default function useEnvironmentDependencies(
  params: EnvironmentDependenciesVariables,
  dialogOpened: boolean
) {
  const [{ data, fetching }] = useQuery<EnvironmentDependencies, EnvironmentDependenciesVariables>({
    query: EnvironmentDependenciesQuery,
    variables: params,
    pause: !dialogOpened
  });

  return {
    data: data?.environmentDependencies,
    fetching
  };
}
