import { useMemo } from 'react';

import { useParams } from 'react-router';
import { ServiceNow } from 'types/external/itsmIntegration/serviceNow';
import { useQuery } from 'urql';

import { serviceNowStartValuesAdapter } from 'views/ServicesHub/adapters/edit/itsm/serviceNow';

const getItsmIntegration = `#graphql
  query( $integrationUid: String!) {
    getItsmIntegration(
      integrationUid: $integrationUid
    ) {
        uid
        name
        origin
        openAutomatic
        configuration {
          subdomain
          username
          password
          clientId
          clientSecret
        }
    }
  }`;

type GetItsmIntegrationVariables = {
  integrationUid: string;
};

export function useStartValues() {
  const { uid: integrationUid } = useParams<{ uid: string }>();

  const [
    { data: serviceNowIntegrationData, fetching: fetchingServiceNowIntegrationData }
  ] = useQuery<
    {
      getItsmIntegration: ServiceNow;
    },
    GetItsmIntegrationVariables
  >({
    variables: { integrationUid },
    query: getItsmIntegration,
    pause: !integrationUid
  });

  const integration = serviceNowIntegrationData?.getItsmIntegration;

  return useMemo(() => {
    if (!integration) return { startValues: undefined, fetching: false };

    const startValues = serviceNowStartValuesAdapter(integration);

    return { startValues, fetchingIntegration: fetchingServiceNowIntegrationData };
  }, [fetchingServiceNowIntegrationData, integration]);
}
