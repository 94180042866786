import { useState } from 'react';

import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { CreateApplicationMutation } from 'graphqlQueries/createApplication';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import ModalAllReady from 'componentsV3/ModalAllReady';
import withThemeV3Hoc from 'theme/v3/withTheme';

import ApplicationForm from '../../View/Application/ApplicationForm/index';

import mapDataToApi from './ApplicationForm/mapDataToApi';

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    marginTop: spacing(3),
    color: palette.text.primary
  },
  subtitle: {
    margin: spacing(2, 0, 4)
  },
  form: {
    maxWidth: 830
  }
}));

const ApplicationTab = ({ firstAccess, productId, environmentId, applicationType }) => {
  const history = useHistory();
  const classes = useStyles();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [createApplicationResult, createApplication] = useMutation(CreateApplicationMutation);

  const { fetching, error } = createApplicationResult;

  const handleSave = data => {
    const mappedData = mapDataToApi({
      ...data,
      productId,
      applicationType,
      teamId: null
    });

    createApplication({
      applicationInput: { ...mappedData, productId }
    }).then(result => {
      setShowSuccessModal(true);
    });
  };

  return (
    <Box>
      <ModalAllReady
        title="All set"
        description="Go to the Application page to activate monitoring"
        mainActionLabel="Go to Application page"
        mainAction={() => {
          const params = new URLSearchParams();
          const applicationId = createApplicationResult.data?.createApplication?.id;

          if (firstAccess) {
            params.append('firstAccess', 'true');
          }

          if (applicationType) {
            params.append('redirectedBy', applicationType);
          }

          history.push(
            `/products/${productId}/availability/applications/${applicationId}?${params.toString()}`
          );
        }}
        isOpen={showSuccessModal}
        firstAccess={firstAccess}
        applicationType={applicationType}
      />

      <Grid>
        <Typography className={classes.title} variant="h3">
          Configure application
        </Typography>

        <Typography className={classes.subtitle} variant="body2">
          In an external application without dependencies, it will not be necessary to register a
          cloud or environment.
        </Typography>

        <ApplicationForm
          onSubmit={handleSave}
          isLoading={fetching}
          mutationErrors={error?.graphQLErrors}
          productId={productId}
          firstAccess={firstAccess}
          environmentId={environmentId}
          applicationType={applicationType}
        />
      </Grid>
    </Box>
  );
};

export default withThemeV3Hoc(ApplicationTab);
