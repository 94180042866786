import { Box, Card, Typography } from '@material-ui/core';

export const ItemPreview = ({ name }) => {
  return (
    <Card style={{ marginBottom: '1rem' }}>
      <Box padding={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">{name}</Typography>
          <Typography variant="caption">Response time: 21ms</Typography>
        </Box>

        <Typography variant="h5" color="primary">
          Operational
        </Typography>
      </Box>
    </Card>
  );
};
