import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import actions from 'redux/actions';
import axios from 'redux/axios';

const useCreateTeam = () => {
  const router = useHistory();
  const reduxDispatch = useDispatch();

  const [createTeamMutation, requestStatus] = useMutation(
    data => axios.post(`/teams`, data).then(response => response.data),
    {
      onSuccess: data => {
        const id = data.data.id;
        router.push(`/teams/${id}`);
        reduxDispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Team created successfully'
        });
      }
    }
  );

  return { createTeamMutation, requestStatus };
};

export default useCreateTeam;
