import { Resource } from 'types/external/Resource';

import { TCPFormData } from 'views/ServicesHub/forms/monitoring/others/TCP';

import { monitoringAdapter } from '../monitoring';

export function tcpAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & TCPFormData {
  const { domain, host, port } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    host,
    port
  };
}

const prepareDomain = (data: Resource) => {
  let host = '';
  let port = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    // ex: HOST:PORT
    const tcpDomainRegex = /(.+):(.+)/;
    const match = domain.match(tcpDomainRegex)!;

    host = match[1];
    port = match[2];
  }

  return { domain, host, port };
};
