import React, { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';

import { Box, Typography, CircularProgress, Card } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { CallToAction } from 'oldComponents/EventsHistory/components/CallToAction';
import { CreatedAt } from 'oldComponents/EventsHistory/components/CreatedAt';
import { Description } from 'oldComponents/EventsHistory/components/Description';
import { MoreDetails } from 'oldComponents/EventsHistory/components/MoreDetails';
import { PrimaryChip } from 'oldComponents/EventsHistory/components/PrimaryChip';
import { SecondaryChip } from 'oldComponents/EventsHistory/components/SecondaryChip';
import { UserAction } from 'oldComponents/EventsHistory/components/UserAction';
import { EventType } from 'types/external/EventsHistory';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { DeployEnum, EventsFilters, FilterTypeEnum } from 'componentsV4/Filters';
import { PageFilters } from 'componentsV4/Filters/Search';
import useDebounce from 'hooks/useDebounce';

import { useStyles } from './styles';

const TYPING_DEBOUNCE_TIME = 500;

export const eventHistoryCustomElasticQuery = ({
  queryFilters
}: {
  queryFilters: { key: string; value: string; isRegex?: boolean }[];
}) => {
  const filters: string[] = [];

  queryFilters.forEach(query => {
    const { key, value, isRegex } = query;

    if (!query.key) return;

    if (query.value === '' || query.value === 'all') return;

    const matchQuery = `(${key}: ${isRegex ? `/.*${value}.*/` : `${value}`})`;

    filters.push(matchQuery);
  });

  return filters.length ? `.${filters.join(' AND ')}.` : '';
};

export const EventsHistory = ({
  page,
  setPage,
  setSearch,
  setStatus,
  setSeverity,
  setBetweenValues,
  setDeploy,
  setType,
  fetching,
  reexecuteQuery,
  events,
  hideType = false
}: {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<StatusEnum | 'all'>>;
  setSeverity: Dispatch<SetStateAction<'all' | SeverityStatusEnum>>;
  setBetweenValues: Dispatch<SetStateAction<string>>;
  setDeploy?: Dispatch<SetStateAction<DeployEnum | 'all'>>;
  setType?: Dispatch<SetStateAction<FilterTypeEnum>>;
  fetching: boolean;
  reexecuteQuery: any;
  events: any;
  hideType?: boolean;
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (fetching) return;
    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleApplyFilters = (filters: PageFilters) => {
    if ('status' in filters) setStatus(filters.status as StatusEnum);
    if ('severity' in filters) setSeverity(filters.severity as SeverityStatusEnum);
    if ('deploy' in filters) setDeploy?.(filters.deploy as DeployEnum);

    setPage(1);
  };

  const handleClearFilters = () => {
    setStatus('all');
    setSeverity('all');
    setDeploy?.('all');
    setPage(1);
  };

  const handleClearCustomRange = () => {
    setBetweenValues('now-1y,now');
    setPage(1);
  };

  const handleApplyPeriod = ({ period }: { period: string }) => {
    if (period === 'all') {
      setBetweenValues('now-1y,now');
      setPage(1);
      return;
    }

    setBetweenValues(`now-${period},now`);
    setPage(1);
  };

  const handleApplyType = ({ type }: { type: FilterTypeEnum }) => {
    handleClearFilters();
    setType?.(type);
    setPage(1);
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    setBetweenValues(betweenValue);
    setPage(1);
  };

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const eventsHistoryStatusOptions = {
    label: 'Incident Status',
    options: [
      { value: 'all', label: 'All' },
      { value: StatusEnum.Alarmed, label: 'Alarmed' },
      { value: StatusEnum.Resolved, label: 'Resolved' },
      { value: StatusEnum.Acknowledged, label: 'Acknowledged' }
    ]
  };

  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h5" className={classes.subtitle}>
          Events History
        </Typography>
      </Box>

      <EventsFilters
        handleChangeSearch={handleChangeSearch}
        onApplyFilters={handleApplyFilters}
        onApplyPeriod={handleApplyPeriod}
        onApplyCustomRange={handleApplyCustomRange}
        onClearFilters={handleClearFilters}
        onClearCustomRange={handleClearCustomRange}
        onApplyType={handleApplyType}
        hideOrigins={true}
        hideType={hideType}
        statusOptions={eventsHistoryStatusOptions}
      />

      {fetching && (
        <Box display="flex" mt={4} alignItems="center" justifyContent="center" pb={4}>
          <CircularProgress color="primary" size={50} thickness={4} />
        </Box>
      )}

      {!events?.data?.length && !fetching ? (
        <Typography variant="body1" className={classes.noDataText}>
          No results found
        </Typography>
      ) : null}

      {events?.data?.length && !fetching ? (
        <Card className={classes.card}>
          {events.data.map((event: EventType) => (
            <React.Fragment key={event?.id}>
              <Box display="flex" mt={1} alignItems="center">
                <PrimaryChip event={event} style={{ height: '24px', marginRight: '8px' }} />
                <SecondaryChip event={event} style={{ height: '24px', marginRight: '8px' }} />
                <Description event={event} />
                <CallToAction event={event} />
                <UserAction event={event} />
                <CreatedAt event={event} />
                <MoreDetails event={event} />
              </Box>

              {events?.data?.indexOf(event) < events?.data?.length - 1 ? (
                <Box display="flex" ml={0.6} mt={1} mb={1}>
                  <span className={classes.timelineConnector}></span>
                </Box>
              ) : null}
            </React.Fragment>
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              page={page}
              count={Math.ceil(events?.total! / 10)}
              defaultPage={1}
              size="large"
              color="primary"
              onChange={handlePageChange}
            />
          </Box>
        </Card>
      ) : null}
    </Box>
  );
};
