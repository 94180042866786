import React, { useEffect } from 'react';

import { Box, Button } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { Input } from './Input';

type KeyValueFieldProps = {
  form: UseFormMethods;
  name: string;
  fields: UseFieldArrayMethods['fields'];
  handleAdd: UseFieldArrayMethods['append'];
  handleRemove: UseFieldArrayMethods['remove'];
  label: string;
  valueLabel?: string;
  required?: boolean;
  isSecret?: boolean;
  type?: string;
  inputProps?: any;
};

export function KeyValueField({
  form,
  name,
  fields,
  handleAdd,
  handleRemove,
  label,
  required,
  isSecret = true,
  valueLabel = 'Value',
  type,
  inputProps
}: KeyValueFieldProps) {
  const { errors, control, setValue, watch, register } = form;

  useEffect(() => {
    if (fields.length === 0) {
      handleAdd({});
    }
  }, [fields, handleAdd]);

  return (
    <Box display="flex" gridGap="1rem" flexDirection="column">
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <Box width="100%" display="flex" justifyContent="space-between" gridGap="1rem">
            <Box width="100%" display="flex" gridGap="1rem">
              <Input
                label={label}
                fullWidth
                name={`${name}[${index}].key`}
                inputRef={register()}
                required={required}
                defaultValue={field.key || ''}
                inputProps={{ style: { fontSize: '16px' } }}
                InputLabelProps={{ style: { fontSize: '16px' }, shrink: true }}
                errors={errors}
              />
              {isSecret ? (
                <InputWithSecrets
                  iconColor={'#515151'}
                  fullWidth
                  errors={errors}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  TextFieldProps={{
                    name: `${name}[${index}].value`,
                    label: valueLabel,
                    required: required
                  }}
                  InputComponent={Input}
                  required={required}
                  defaultValue={field.value || ''}
                />
              ) : (
                <Input
                  fullWidth
                  label={valueLabel}
                  name={`${name}[${index}].value`}
                  defaultValue={field.value || ''}
                  inputRef={register()}
                  required={required}
                  type={type}
                  inputProps={{ style: { fontSize: '16px' }, ...inputProps }}
                  InputLabelProps={{ style: { fontSize: '16px' }, shrink: true }}
                  errors={errors}
                />
              )}
            </Box>

            <Box display="flex" gridGap="1rem">
              <Button variant="outlined" color="primary" onClick={handleAdd}>
                <Add />
              </Button>
              <Button variant="outlined" color="primary" onClick={() => handleRemove(index)}>
                <Delete />
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}
