import { ServiceNowData } from 'views/ServicesHub/layouts/ItsmServiceNow';

export function serviceNowAdapter(integration: ServiceNowData) {
  const { subdomain, clientId, clientSecret, username, password, uid, ...props } = integration;

  return {
    ...props,
    origin: 'service-now',
    configuration: {
      subdomain,
      clientId,
      clientSecret,
      username,
      password
    }
  };
}
