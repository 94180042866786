import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/applications';

function* prepareFetchEntities(axios, action) {
  try {
    const productId = action.payload;
    const {
      data: { data: product }
    } = yield axios.get(`/products/${productId}`);
    const {
      data: { data }
    } = yield axios.get('/environments');

    yield put({
      type: actions.PRODUCT_APPLICATIONS_NEW_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        environments: { data },
        product,
        product_application_new: { productId }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationFetch(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_NEW_FETCH, prepareFetchEntities, axios);
}
