import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import actions from 'redux/actions';

import mapFormDataToApi from '../mapFormDataToApi';

import MultiHttpForm from './MultiHttpForm';

const UpdateMultiHttpApplicationQuery = `#graphql
  mutation($data: UpdateMultiHttpApplicationInput!) {
    updateMultiHttpApplication(data: $data) {
      id
    }
  }
`;

const MultiHttpFormEdit = ({
  onClose,
  resourceType,
  startValues,
  applicationId,
  onUpdateAddon,
  productId,
  teamId,
  reexecuteQueryUniqueResourceStatus
}) => {
  const dispatch = useDispatch();

  const [{ error, fetching }, submitMutation] = useMutation(UpdateMultiHttpApplicationQuery);

  const onSubmit = formData => {
    const { addonSettings, ...payload } = mapFormDataToApi({
      formData,
      resourceType,
      productId,
      applicationId,
      teamId: null
    });

    return submitMutation({
      data: {
        payload
      }
    }).then(response => {
      onClose();

      dispatch({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId }
      });
      onUpdateAddon();
      reexecuteQueryUniqueResourceStatus();
      return response;
    });
  };

  return (
    <MultiHttpForm
      pos_teamId={teamId}
      submitMutation={onSubmit}
      errorUseMutation={error}
      isLoading={fetching}
      onClose={onClose}
      startValues={startValues}
    />
  );
};

export default MultiHttpFormEdit;
