import { useState, useEffect, useMemo, useCallback } from 'react';

import useMaintenanceWindow from 'hooks/queriesGraphQL/useMaintenanceWindow';

import MaintenanceDeleteForm from '../Form/MaintenanceDeleteForm';
import MaintenanceEditForm from '../Form/MaintenanceEditForm';
import MaintenanceForceFinishForm from '../Form/MaintenanceForceFinishForm';
import MaintenanceViewForm from '../Form/MaintenanceViewForm';

import MaintenanceDialog from './MaintenanceDialog';

const forms = {
  edit: {
    Form: MaintenanceEditForm,
    title: 'Maintenance window',
    id: 'dialogMaintenanceEdit'
  },
  view: {
    Form: MaintenanceViewForm,
    title: 'Maintenance window',
    id: 'dialogMaintenanceView'
  },
  forceFinish: {
    Form: MaintenanceForceFinishForm,
    title: 'End maintenance?',
    id: 'dialogMaintenanceForceFinish'
  },
  delete: {
    Form: MaintenanceDeleteForm,
    title: 'Delete maintenance?',
    id: 'dialogMaintenanceDelete'
  }
};

const getFormByMaintenance = ({ maintenanceId, inMaintenance }) => {
  if (inMaintenance) return 'forceFinish';
  if (maintenanceId) return 'view';

  return 'edit';
};

const MaintenanceEditDialog = ({
  applicationName,
  serviceId,
  applicationId,
  uid,
  entity,
  setActivated,
  openerComponent: OpenerComponent,
  reexecuteEntityQuery,
  ...otherProps
}) => {
  const {
    maintenance,
    onSubmit,
    onDelete,
    inMaintenance,
    fetchingCreate,
    onForceFinish
  } = useMaintenanceWindow({
    serviceId: serviceId,
    applicationId: applicationId,
    uid: uid,
    entity,
    reexecuteEntityQuery
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentForm, setCurrentForm] = useState(
    getFormByMaintenance({ maintenance, inMaintenance })
  );

  const CurrentForm = useMemo(() => forms[currentForm], [currentForm]);

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    setCurrentForm(getFormByMaintenance({ maintenanceId: maintenance?.id, inMaintenance }));
  }, [inMaintenance, maintenance?.id]);

  const setCurrentFormByMaintenance = useCallback(form => {
    setCurrentForm(form);
  }, []);

  const FormComponent = useCallback(
    props =>
      CurrentForm?.Form ? (
        <CurrentForm.Form
          submitMutation={data => {
            onSubmit(data);
            handleClose();
          }}
          submitDelete={onDelete}
          setCurrentForm={setCurrentFormByMaintenance}
          fetchingCreate={fetchingCreate}
          onForceFinish={onForceFinish}
          handleClose={handleClose}
          {...props}
        />
      ) : null,
    [
      CurrentForm?.Form,
      onDelete,
      setCurrentFormByMaintenance,
      fetchingCreate,
      onForceFinish,
      onSubmit,
      handleClose
    ]
  );

  useEffect(() => {
    setCurrentForm(getFormByMaintenance({ maintenanceId: maintenance?.id, inMaintenance }));

    setActivated(maintenance);
  }, [inMaintenance, maintenance?.id, setActivated, maintenance]);

  return (
    <>
      <OpenerComponent onClick={() => setIsModalOpen(true)} />
      <MaintenanceDialog
        isOpen={isModalOpen}
        handleClose={handleClose}
        title={CurrentForm?.title}
        startValues={maintenance}
        applicationName={applicationName}
        uid={uid}
        entity={entity}
        inMaintenance={inMaintenance}
        fetchingCreate={fetchingCreate}
        onForceFinish={onForceFinish}
        formComponent={FormComponent}
        {...otherProps}
      />
    </>
  );
};

export default MaintenanceEditDialog;
