type IframeUrlParams = {
  orgUid: string;
  token: string;
  params?: Record<string, any>;
  hideParams?: string[];
  iframeTitle?: boolean;
  iframeRefresh?: number;
};

const hideParamsDefault = ['org_uid'];

function getMetabaseIframeUrl({
  orgUid,
  token,
  params = {},
  hideParams = hideParamsDefault,
  iframeTitle = true,
  iframeRefresh
}: IframeUrlParams) {
  const IFRAME_BASE_URL = process.env.REACT_APP_DATAELVEN_GCP_IFRAME_BASE_URL!;

  const dashboardBaseUrl = `${IFRAME_BASE_URL}/embed/dashboard`;

  if (!orgUid) return dashboardBaseUrl;

  const urlSearchParams = new URLSearchParams();

  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach((value: string) => {
        urlSearchParams.append(key, value);
      });
      continue;
    }
    urlSearchParams.append(key, params[key]);
  }

  urlSearchParams.append('org_uid', orgUid);

  const queryStringParams = urlSearchParams.toString();
  const hideParamsSet = new Set([...hideParamsDefault, ...hideParams]);
  const queryStringHideParams = Array.from(hideParamsSet).join(',');
  const refresh = iframeRefresh ? `&refresh=${iframeRefresh}` : '';

  return `${dashboardBaseUrl}/${token}?${queryStringParams}#hide_parameters=${queryStringHideParams}&titled=${iframeTitle}${refresh}`;
}

export default getMetabaseIframeUrl;
