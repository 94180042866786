import { Skeleton } from '@material-ui/lab-old';
import { TableColumn } from 'react-data-table-component';
import { ProductV2 } from 'types/external/Product';

export const COLUMNS: TableColumn<Partial<ProductV2>>[] = [
  {
    name: 'Name',
    selector: row => row.name || '',
    cell: row => {
      return row.name ? row.name : <Skeleton height={40} animation="wave" width={100} />;
    },
    grow: 3
  }
];
