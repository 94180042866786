import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { StyledTableCell, StyledTableRow, useStyles } from '../hooks';

export const TableAdvancedNotifySettings = ({
  initialValues,
  onChange,
  providersConfigData,
  hasSchedules,
  canEdit
}) => {
  const classes = useStyles();
  const handleChange = (event, productId, environmentId) => {
    const {
      target: { name, checked }
    } = event;
    onChange(name, checked, productId, environmentId);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell align="center">Environment</StyledTableCell>
              <StyledTableCell align="center">Emails</StyledTableCell>
              <StyledTableCell align="center">Telegram</StyledTableCell>
              <StyledTableCell align="center">Slack</StyledTableCell>
              <StyledTableCell align="center">MS Teams</StyledTableCell>
              <StyledTableCell align="center">WhatsApp</StyledTableCell>
              <StyledTableCell align="center">GChat</StyledTableCell>
              <StyledTableCell align="center">Discord</StyledTableCell>
              <StyledTableCell align="center">Call Rotation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providersConfigData &&
              initialValues.map(product => {
                return product.resourceId ? (
                  <StyledTableRow
                    key={`product_${product.resourceId}_environment_${product.environmentId}`}>
                    <StyledTableCell component="th" scope="row" size="small">
                      {product.resourceName}
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      {product.environmentName}
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="Mail"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config => config.providerName === 'Mail' && config.enabled === true
                          )
                            ? product.Mail
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config => config.providerName === 'Mail' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="Telegram"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config => config.providerName === 'Telegram' && config.enabled === true
                          )
                            ? product.Telegram
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config =>
                                config.providerName === 'Telegram' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="Slack"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config => config.providerName === 'Slack' && config.enabled === true
                          )
                            ? product.Slack
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config => config.providerName === 'Slack' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="Teams"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config => config.providerName === 'Teams' && config.enabled === true
                          )
                            ? product.Teams
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config => config.providerName === 'Teams' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="WhatsApp"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config => config.providerName === 'WhatsApp' && config.enabled === true
                          )
                            ? product.WhatsApp
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config =>
                                config.providerName === 'WhatsApp' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="GoogleChat"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config =>
                              config.providerName === 'GoogleChat' && config.enabled === true
                          )
                            ? product.GoogleChat
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config =>
                                config.providerName === 'GoogleChat' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="DiscordChannel"
                        defaultChecked={
                          providersConfigData.listProviders.some(
                            config =>
                              config.providerName === 'DiscordChannel' && config.enabled === true
                          )
                            ? product.DiscordChannel
                            : false
                        }
                        disabled={
                          !(
                            providersConfigData.listProviders.some(
                              config =>
                                config.providerName === 'DiscordChannel' && config.enabled === true
                            ) && canEdit
                          )
                        }
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      <Checkbox
                        color="primary"
                        name="CallRotation"
                        defaultChecked={hasSchedules ? product.CallRotation : false}
                        disabled={!(hasSchedules && canEdit)}
                        onChange={event =>
                          handleChange(event, product.resourceId, product.environmentId)
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : null;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
