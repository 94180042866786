import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  dividerTop: {
    backgroundColor: 'black',
    opacity: '10%',
    marginBottom: theme.spacing(3)
  },
  dividerBottom: {
    backgroundColor: 'black',
    opacity: '10%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  paragraphText: {
    maxWidth: '42ch',
    paddingBottom: theme.spacing(3)
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  closeButton: {
    marginRight: theme.spacing(2)
  }
}));
