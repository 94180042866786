import {
  Rule,
  RuleAction,
  RuleActionType,
  RuleOriginEntityType,
  RuleType,
  UpdateNewIncidentRulePayload
} from 'types/external/AlertCenter';

import { IncidentFormData } from 'views/ServicesHub/forms/monitoring/commom/Incident';

export function incidentsNewRulesAdapter(
  data: IncidentFormData & { entityUid: string; entity: RuleOriginEntityType }
) {
  const { incident } = data;

  const teams = incident.teams?.map(team => {
    return { teamId: Number(team.value) };
  });

  const responders = incident.responders?.map(responder => {
    return {
      uid: responder.value,
      type: responder.type
    };
  });

  return {
    title: '?',
    description: '?',
    severity: incident.severity,
    type: RuleType.Incident,
    actions: [
      {
        action: RuleAction.Close,
        type: RuleActionType.Hits,
        operation: '>=',
        occurrence: Number(incident.hitsToCloseIncident),
        interval: Number(incident.intervalHits)
      },
      {
        action: RuleAction.Open,
        type: RuleActionType.Failures,
        operation: '>=',
        occurrence: Number(incident.failuresToOpenIncident),
        interval: Number(incident.intervalFailures)
      }
    ],
    teams,
    responders,
    origins: [
      {
        entity: data.entity,
        entityUid: data.entityUid
      }
    ]
  };
}

export function updateNewIncidentRuleAdapter(data: {
  incidentRule: Rule;
  ruleFormData: IncidentFormData['incident'] & {
    entityUid: string;
    entity: RuleOriginEntityType;
  };
}): UpdateNewIncidentRulePayload {
  const { ruleFormData: incident, incidentRule } = data;

  const teamIds = incident.teams?.map(team => {
    return Number(team.value);
  });
  const responders = incident.responders?.map(responder => {
    return {
      uid: responder.value,
      type: responder.type
    };
  });

  return {
    uid: incidentRule.uid,
    teamIds,
    responders,
    severity: incident.severity,
    title: 'Default',
    description: '',
    type: incident.type,
    actions: [
      {
        action: RuleAction.Close,
        type: RuleActionType.Hits,
        operation: '>=',
        occurrence: Number(incident.hitsToCloseIncident),
        interval: Number(incident.intervalHits)
      },
      {
        action: RuleAction.Open,
        type: RuleActionType.Failures,
        operation: '>=',
        occurrence: Number(incident.failuresToOpenIncident),
        interval: Number(incident.intervalFailures)
      }
    ],
    origins: [
      {
        entity: incident.entity,
        entityUid: incident.entityUid
      }
    ]
  };
}
