import { Resource } from 'types/external/Resource';

import { PubSubFormData } from 'views/ServicesHub/forms/monitoring/queue/PubSub';

import { monitoringAdapter } from '../monitoring';

export function pubSubAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & PubSubFormData {
  return {
    ...monitoringAdapter(data),
    topic: data.domain,
    credentials: JSON.stringify(data.domainSettings)
  };
}
