import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Badge, Box, Button, TextField, useTheme } from '@material-ui/core';
import { ArrowDropDown, Search, Tune } from '@material-ui/icons';
import dayjs from 'dayjs';
import { externalIntegrationsOriginsList } from 'types/external/ExternalService';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { CustomRange } from './CustomRange';
import { Period } from './Period';
import { Filters, PageFilters } from './Search';
import { useStyles } from './styles';
import { Type } from './Type';

type AppliedFilters = () => {
  status: StatusEnum | 'all';
  severity: 'all' | SeverityStatusEnum;
  betweenValues: string;
  origins: string[];
  externalIntegrationsOrigins: string[];
};

export type StatusOptions = { label: string; options: { value: string; label: string }[] };
export type OriginsOptions = { label: string; options: { value: string; label: string }[] };

export enum FilterTypeEnum {
  Incident = 'incidents',
  Deploy = 'deploy'
}

export enum DeployEnum {
  Deploy = 'deploy',
  Hotfix = 'hotfix',
  Rollback = 'rollback'
}

type Value = string | string[] | Record<string, any> | undefined;

const isEqual = (newValue: Value, defaultValue: Value): boolean => {
  if (Array.isArray(newValue) && Array.isArray(defaultValue)) {
    if (newValue.length !== defaultValue.length) return false;
    return newValue.every((value, index) => isEqual(value, defaultValue[index]));
  }

  if (
    typeof newValue === 'object' &&
    newValue !== null &&
    typeof defaultValue === 'object' &&
    defaultValue !== null
  ) {
    const newValueKeys = Object.keys(newValue);
    const defaultValuekeys = Object.keys(defaultValue);

    if (newValueKeys.length !== defaultValuekeys.length) return false;
    return newValueKeys.every(key =>
      isEqual((newValue as Record<string, any>)[key], (defaultValue as Record<string, any>)[key])
    );
  }

  return newValue === defaultValue;
};

export const EventsFilters = ({
  handleChangeSearch,
  onApplyFilters = () => {},
  onClearFilters = () => {},
  onApplyPeriod = () => {},
  onApplyCustomRange = () => {},
  onClearCustomRange = () => {},
  onApplyType,
  hideType = true,
  hideOrigins,
  hideStatus,
  hideSeverity,
  statusOptions,
  appliedFilters = () => {},
  originsQuery = '',
  includePeriod = true,
  includeCustomRange = true,
  hideExternalIntegrationsOrigins = true,
  searchPlaceholder = 'Search by name',
  hideFilters = false
}: {
  handleChangeSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onApplyFilters?: (filters: PageFilters) => void;
  onClearFilters?: () => void;
  onClearCustomRange?: () => void;
  onApplyPeriod?: ({ period }: { period: string }) => void;
  onApplyCustomRange?: ({ betweenValue }: { betweenValue: string }) => void;
  onApplyType?: ({ type }: { type: FilterTypeEnum }) => void;
  hideOrigins?: boolean;
  hideStatus?: boolean;
  hideSeverity?: boolean;
  statusOptions?: StatusOptions;
  appliedFilters?: AppliedFilters | (() => void);
  originsQuery?: string;
  includePeriod?: boolean;
  includeCustomRange?: boolean;
  hideExternalIntegrationsOrigins?: boolean;
  hideType?: boolean;
  searchPlaceholder?: string;
  hideFilters?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [status, setStatus] = useState<'all' | StatusEnum | MultiFlowStatusEnum>('all');
  const [severity, setSeverity] = useState<'all' | SeverityStatusEnum>('all');
  const [origins, setOrigins] = useState<{ value: string; label: string; type: string }[]>([]);
  const [externalIntegrationsOrigins, setExternalIntegrationsOrigins] = useState<
    { value: string; label: string }[]
  >([]);
  const [deployment, setDeployment] = useState<'all' | DeployEnum>('all');
  const [type, setType] = useState<FilterTypeEnum>(FilterTypeEnum.Incident);

  const [startDate, setStartDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
  const [endDate, setEndDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isPeriodOpen, setIsPeriodOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  const [periodAnchorEl, setPeriodAnchorEl] = useState<null | HTMLElement>(null);
  const [typeAnchorEl, setTypeAnchorEl] = useState<null | HTMLElement>(null);
  const [customRangeAnchorEl, setCustomRangeAnchorEl] = useState<null | HTMLElement>(null);

  const [badgeContentFilters, setBadgeContentFilters] = useState<string | number>(0);
  const [badgeContentPeriod, setBadgeContentPeriod] = useState<string | number>(0);
  const [badgeContentType] = useState<string | number>(0);
  const [badgeContentCustomRange, setBadgeContentCustomRange] = useState<string | number>(0);

  const defaults = useMemo(
    () => ({
      status: 'all',
      severity: 'all',
      deployment: 'all',
      origins: [],
      externalIntegrationsOrigins: []
    }),
    []
  );

  useEffect(() => {
    const values = {
      status,
      severity,
      deployment,
      origins,
      externalIntegrationsOrigins
    };

    const changedFields = Object.keys(values).filter(key => {
      if (
        (values as Record<string, any>)[key] === undefined ||
        (defaults as Record<string, any>)[key] === undefined
      ) {
        return false;
      }

      return !isEqual((values as Record<string, any>)[key], (defaults as Record<string, any>)[key]);
    });

    const content = Math.min(changedFields.length, 5);

    setBadgeContentFilters(content);
  }, [defaults, status, severity, deployment, origins, externalIntegrationsOrigins]);

  useEffect(() => {
    const filters = appliedFilters();

    if (!filters) return;

    setStatus(filters.status);
    setSeverity(filters.severity);

    if (origins && !origins?.length) {
      const originsWithValue = filters?.origins?.map((origin: string) => {
        return {
          value: origin,
          label: '',
          type: ''
        };
      });

      setOrigins(originsWithValue);
    }

    const periodValues = ['now-1y,now', 'now-1d,now', 'now-7d,now', 'now-30d,now'];

    if (filters?.betweenValues && periodValues.includes(filters?.betweenValues)) {
      setBadgeContentPeriod('1');
      setBadgeContentCustomRange(0);

      return;
    }

    if (filters?.betweenValues && !periodValues.includes(filters?.betweenValues)) {
      const date = filters?.betweenValues.split(',');

      setStartDate(dayjs(new Date(date[0])).format('YYYY-MM-DDTHH:mm'));
      setEndDate(dayjs(new Date(date[1])).format('YYYY-MM-DDTHH:mm'));

      setBadgeContentCustomRange('1');
      setBadgeContentPeriod(0);

      return;
    }

    if (externalIntegrationsOrigins && !externalIntegrationsOrigins?.length) {
      const originsWithValue = filters?.externalIntegrationsOrigins?.map((origin: string) => {
        const findedOrigin = externalIntegrationsOriginsList.find(item => origin === item.value);
        if (findedOrigin) {
          return {
            value: findedOrigin.value,
            label: findedOrigin.label
          };
        }
        return {
          value: '',
          label: ''
        };
      });

      setExternalIntegrationsOrigins(originsWithValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);

  const handleApplyPeriods = ({ period }: { period: string }) => {
    if (period === 'all') {
      onApplyPeriod({ period: 'all' });
      setBadgeContentPeriod(0);
      setBadgeContentCustomRange(0);
      return;
    }

    onApplyPeriod({ period });
    setBadgeContentPeriod('1');
    setBadgeContentCustomRange(0);
  };

  const handleClearFilters = () => {
    setStatus('all');
    setSeverity('all');
    setDeployment('all');
    setExternalIntegrationsOrigins([]);
  };

  const handleApplyType = ({ type }: { type: FilterTypeEnum }) => {
    if (typeof onApplyType === 'function') {
      setType(type);
      onApplyType({ type });
      handleClearFilters();
    }
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    onApplyCustomRange({ betweenValue });
    setBadgeContentPeriod(0);
  };

  const handleTogglePeriods = (event: React.MouseEvent<HTMLElement>) => {
    setPeriodAnchorEl(event.currentTarget);
    setIsPeriodOpen(isPeriodOpen => !isPeriodOpen);
  };

  const handleToggleType = (event: React.MouseEvent<HTMLElement>) => {
    setTypeAnchorEl(event.currentTarget);
    setIsTypeOpen(isTypeOpen => !isTypeOpen);
  };

  const handleToggleCustomRange = (event: React.MouseEvent<HTMLElement>) => {
    setCustomRangeAnchorEl(event.currentTarget);
    setIsCustomRangeOpen(isCustomRangeOpen => !isCustomRangeOpen);
  };

  const handleCloseFilters = () => {
    setIsFiltersOpen(false);
    setIsPeriodOpen(false);
    setIsCustomRangeOpen(false);
    setIsTypeOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      gridGap={theme.spacing(2)}
      {...{
        ref: searchContainerRef
      }}>
      <TextField
        name="search"
        label="Search"
        variant="outlined"
        onChange={handleChangeSearch}
        placeholder={searchPlaceholder}
        InputProps={{
          endAdornment: <Search />,
          className: classes.input
        }}
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
      />

      {!hideType && (
        <>
          <Badge
            classes={{ badge: classes.badgeContent }}
            color="primary"
            badgeContent={badgeContentType}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Button variant="outlined" onClick={handleToggleType} className={classes.buttons}>
              <Box display="flex" alignItems="center" gridGap={8}>
                {type?.toUpperCase() || 'TYPE'}
                <ArrowDropDown />
              </Box>
            </Button>
          </Badge>

          {isTypeOpen && (
            <Type
              anchorEl={typeAnchorEl}
              onApplyType={handleApplyType}
              handleClose={handleCloseFilters}
            />
          )}
        </>
      )}

      {!hideFilters && (
        <Badge
          classes={{ badge: classes.badgeContent }}
          color="primary"
          badgeContent={badgeContentFilters}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Button variant="outlined" onClick={handleToggleFilters} className={classes.buttons}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <Tune />
              Filters
            </Box>
          </Button>
        </Badge>
      )}

      {isFiltersOpen && (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
          handleClose={handleCloseFilters}
          status={status}
          severity={severity}
          origins={origins}
          deployment={deployment}
          type={type}
          externalIntegrationsOrigins={externalIntegrationsOrigins}
          statusOptions={statusOptions}
          originsQuery={originsQuery}
          setStatus={setStatus}
          setSeverity={setSeverity}
          setOrigins={setOrigins}
          setDeployment={setDeployment}
          setExternalIntegrationsOrigins={setExternalIntegrationsOrigins}
          hideStatus={hideStatus}
          hideSeverity={hideSeverity}
          hideOrigins={hideOrigins}
          hideExternalIntegrationsOrigins={hideExternalIntegrationsOrigins}
        />
      )}

      {includePeriod && (
        <Badge
          classes={{ badge: classes.badgeContent }}
          color="primary"
          badgeContent={badgeContentPeriod}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Button variant="outlined" onClick={handleTogglePeriods} className={classes.buttons}>
            <Box display="flex" alignItems="center" gridGap={8}>
              Period
              <ArrowDropDown />
            </Box>
          </Button>
        </Badge>
      )}

      {includePeriod && isPeriodOpen && (
        <Period
          anchorEl={periodAnchorEl}
          onApplyPeriod={handleApplyPeriods}
          handleClose={handleCloseFilters}
        />
      )}

      {includeCustomRange && (
        <Badge
          classes={{ badge: classes.badgeContent }}
          color="primary"
          badgeContent={badgeContentCustomRange}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Button variant="outlined" onClick={handleToggleCustomRange} className={classes.buttons}>
            <Box display="flex" alignItems="center" gridGap={8}>
              Custom Range
              <ArrowDropDown />
            </Box>
          </Button>
        </Badge>
      )}

      {includeCustomRange && isCustomRangeOpen && (
        <CustomRange
          anchorEl={customRangeAnchorEl}
          onApplyCustomRange={handleApplyCustomRange}
          handleClose={handleCloseFilters}
          setBadgeContentCustomRange={setBadgeContentCustomRange}
          onClearCustomRange={onClearCustomRange}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      )}
    </Box>
  );
};
