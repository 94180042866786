import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type MysqlFormData = {
  username: string;
  password: string;
  host: string;
  port: string;
  database: string;
};

export function MysqlForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <>
      {domainType === 'userAndPass' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'User',
                name: 'username',
                required: true
              }}
              InputComponent={Input}
            />

            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'password',
                label: 'Password',
                name: 'password'
              }}
              InputComponent={Input}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Host',
                name: 'host'
              }}
              InputComponent={Input}
            />

            <Input
              label="Port"
              name="port"
              inputRef={register}
              type="number"
              inputProps={{
                min: 1,
                max: 65535
              }}
              errors={errors}
            />
          </Box>

          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'text',
              label: 'Database',
              name: 'database'
            }}
            InputComponent={Input}
          />
        </Box>
      )}
    </>
  );
}
