import { makeStyles } from '@material-ui/core';
import { StepStatusEnum } from 'types/external/MultiFlow';

import { Theme } from 'theme/v4';

import { StepStatusChipProps } from '.';

type StatusChipStyleProps = {
  status: StepStatusChipProps['status'];
};

function getChipColorByStatus(status: StepStatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [StepStatusEnum.Off]: palette.gray[700],
    [StepStatusEnum.Success]: palette.primary.main,
    [StepStatusEnum.Error]: palette.red[500],
    [StepStatusEnum.Skipped]: palette.gray[200]
  };

  return statusColorMap[status];
}

export const useStyles = makeStyles<Theme, StatusChipStyleProps>(theme => ({
  chip: {
    backgroundColor: props => getChipColorByStatus(props.status, theme.palette),
    color: theme.palette.common.white
  }
}));
