import { ChangeEvent } from 'react';

const useDebounce = (execute: Function, wait = 1000, timeoutId?: ReturnType<typeof setTimeout>) => (
  event: ChangeEvent
) => {
  clearTimeout(timeoutId!);

  event.persist();

  timeoutId = setTimeout(() => execute(event), wait);
};

export default useDebounce;
