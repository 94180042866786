import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';

function* ProductApplicationsMonitoring(axios, action) {
  try {
    const { productId, id } = action.payload;
    if (action.meta.action === 'activate') {
      yield axios.request({
        method: 'post',
        url: `/products/${productId}/applications/${id}/monitoring/activate`
      });
      yield put({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId: id }
      });
      yield put({
        type: actions.GLOBAL_SUCCESS,
        payload: 'activate monitoring...'
      });
    } else {
      yield axios.request({
        method: 'delete',
        url: `/products/${productId}/applications/${id}/monitoring/deactivate`
      });
      yield put({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId: id }
      });
      yield put({
        type: actions.GLOBAL_SUCCESS,
        payload: 'deactivate monitoring...'
      });
    }
    yield put({
      type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
      params: { productId, applicationId: id }
    });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationsMonitoring(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_MONITORING, ProductApplicationsMonitoring, axios);
}
