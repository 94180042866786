const moveServiceToAnotherComponent = ({
  resources,
  source,
  destination,
  draggableId
}) => statusPageComponents => {
  const sourceIndex = Number(source.droppableId.split('group-')[1]);
  const destinationIndex = Number(destination.droppableId.split('group-')[1]);
  const serviceId = String(draggableId.split('service-')[1]);

  return statusPageComponents.map((component, componentIndex) => {
    if (componentIndex === sourceIndex) {
      return {
        ...component,
        services: component.services.filter(service => String(service.id) !== String(serviceId))
      };
    }

    if (componentIndex === destinationIndex) {
      const service = resources.find(
        resource => String(resource.monitoringId || resource.sterasId) === String(serviceId)
      );

      return {
        ...component,
        services: [
          ...component.services,
          {
            ...service,
            id: String(service.monitoringId || service.sterasId),
            name: service.name
          }
        ]
      };
    }

    return component;
  });
};

export default moveServiceToAnotherComponent;
