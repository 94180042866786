import { z } from 'zod';

import { makeMonitoringSchema } from '../makeMonitoringSchema';

export const postgresSchema = makeMonitoringSchema({
  host: z.string().optional(),
  port: z.coerce.number().optional(),
  database: z.string().optional(),
  username: z.string().optional(),
  password: z.string().optional(),
  sslMode: z.string().optional()
}).superRefine((data, ctx) => {
  if (data.domainType === 'userAndPass') {
    if (!data.host) {
      ctx.addIssue({
        path: ['host'],
        message: 'Host is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (!data.port) {
      ctx.addIssue({
        path: ['port'],
        message: 'Port is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (!data.database) {
      ctx.addIssue({
        path: ['database'],
        message: 'Database is required',
        code: z.ZodIssueCode.custom
      });
    }
  }

  if (data.username && !data.password) {
    ctx.addIssue({
      path: ['password'],
      message: 'Password is required',
      code: z.ZodIssueCode.custom
    });
  }

  if (!data.username && data.password) {
    ctx.addIssue({
      path: ['username'],
      message: 'Username is required',
      code: z.ZodIssueCode.custom
    });
  }
});
