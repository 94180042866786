import { push } from 'connected-react-router';
import { put, takeLatest, select } from 'redux-saga/effects';

import encrypt from 'helpers/encrypt';

import actions from '../../actions';
import reducer from '../../reducer/signUpValidation';

function* loginUser(axios, action) {
  try {
    const data = yield select(state => state.signUp.values);

    const login = JSON.stringify({ ...data, token: action.payload.token });

    const hash = encrypt(login);

    yield axios.post('/auth/signup', { data: hash });
    yield put(push('/'));
  } catch (err) {
    yield put({
      type: actions.SIGN_UP_FAILED,
      payload: err,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSignup(axios) {
  yield takeLatest(actions.SIGN_UP, loginUser, axios);
}
