import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.gray[900]
  },
  dateTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900]
  },
  date: {
    color: theme.palette.gray[700]
  },
  idTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.gray[900]
  },
  ellipse: {
    width: '5px',
    height: '5px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  firstChild: {
    marginLeft: theme.spacing(1)
  },
  link: {
    color: theme.palette.gray[700]
  },
  divider: {
    '&:last-child': {
      '& hr': {
        display: 'none'
      }
    }
  }
}));
