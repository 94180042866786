import React from 'react';

import DataTable from 'react-data-table-component';

import columns from './columns';

const DataGrid = ({ data }) => {
  const dataGridTheme = {
    title: {
      height: 0
    }
  };

  return (
    <DataTable
      clicked
      columns={columns.runtimes}
      customTheme={dataGridTheme}
      data={data}
      highlightOnHover
      noDataComponent="No data"
      striped
      title=""
    />
  );
};

export default DataGrid;
