import actions from '../../actions';
import { initialState } from '../index';

const availability = (state, action) => {
  if (
    actions.APPLICATION_AVAILABILITY_VIEW_FETCH_SUCCESSFUL === action.type ||
    actions.APPLICATION_AVAILABILITY_STATUS_TIMER_SUCCESSFUL === action.type
  ) {
    return {
      ...state,
      application_availability: {
        ...state.application_availability,
        ...action.payload
      }
    };
  }

  if (actions.APPLICATION_AVAILABILITY_VIEW_RESET) {
    return {
      ...state,
      application_availability: {
        ...initialState.application_availability
      }
    };
  }

  return state;
};

export default availability;
