const propperties = [
  'displayName',
  'photoURL',
  'cep',
  'endereco',
  'numero',
  'bairro',
  'cidade',
  'uf',
  'phone'
];

const calculateProfileCompleteness = (account_edit: Record<string, unknown>) => {
  const complete = propperties.reduce(
    (percent, field) => (account_edit[field] ? percent + 1 : percent),
    0
  );
  const total = propperties.length;
  return Math.round((complete * 100) / total);
};

export default calculateProfileCompleteness;
