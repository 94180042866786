import { MonitoringStatus } from 'constants/MonitoringStatusV1';

import { useQuery } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const GetDependencies = `#graphql
  query($applicationId: Int!) {
    dependenciesWithOrgUid(applicationId: $applicationId) {
      id
      name
      applicationId
      multiFlow
      applicationDependency {
        id
      }
      informations {
        id
        lastCheck
        monitoringStatus
      }
      sterasId   
      webhook
      orgUid   
    }
  }
`;

const FullGetDependencies = `#graphql
  query($applicationId: Int!) {
    dependenciesWithOrgUid(applicationId: $applicationId) {
      id
      name
      applicationId
      multiFlow
      sterasId
      orgUid
      resourceUid
      webhook
      service {
        name
        domain
        headers
        method
        post_body_type
        post_body
        domain_settings
      }
      applicationDependency {
        id
        name
      }
      informations {
        id
        lastCheck
        monitoringStatus
      }
    }
  }
`;

export type Dependencies = {
  id: number;
  name: string;
  applicationId: number;
  applicationDependency?: ApplicationDependency;
  multiFlow: boolean;
  sterasId?: number;
  webhook: boolean;
  orgUid: string;
  resourceUid?: string;
  useResource: boolean;
  informations?: Informations;
};

type ApplicationDependency = {
  id?: number;
  name?: string;
};

type Informations = {
  id: number;
  lastCheck: string;
  monitoringStatus: MonitoringStatus;
};

type Variables = {
  applicationId: number;
};

const useNewAddons = ({ applicationId }: { applicationId: number }) => {
  const useCleanDependenciesQuery = useFeatureFlagUnleash('useCleanDependenciesQuery', {
    queryString: true
  });

  const query = useCleanDependenciesQuery ? GetDependencies : FullGetDependencies;

  const [{ data, fetching }, reexecuteQuery] = useQuery<
    { dependenciesWithOrgUid: Dependencies[] },
    Variables
  >({
    query,
    variables: { applicationId: Number(applicationId) },
    pause: !applicationId
  });

  const result = data?.dependenciesWithOrgUid || [];

  return { result, fetching, reexecuteQuery };
};

export default useNewAddons;
