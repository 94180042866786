import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router';

import initialValues from '../../../components/initialValues';
import UseProvidersFromMsTeam from '../hooks/useProvidersFromMsTeams';

import NotificationsForm from './NotificationsForm';

const NotificationsV2 = ({ canEdit, setShowBackdrop }) => {
  const { id: teamId } = useParams();

  const result = UseProvidersFromMsTeam({ teamId: Number(teamId) });
  if (result.fetching) {
    return (
      <CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
    );
  }

  return (
    <NotificationsForm
      canEdit={canEdit}
      setShowBackdrop={setShowBackdrop}
      refetchProviders={result.reexecuteQuery}
      initialValues={
        result.data?.listProviders
          ? {
              notificationSetup: result?.data?.listProviders
            }
          : initialValues
      }
    />
  );
};

export default NotificationsV2;
