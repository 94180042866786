import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';

import schema from './schema';

const useForm1 = ({ defaultValues }) => {
  const useFormMethods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const { control } = useFormMethods;

  const { fields: headersFields, append: headersAppend, remove: headersRemove } = useFieldArray({
    control,
    name: 'headers'
  });

  const {
    fields: postBodyDefaultFields,
    append: postBodyDefaultAppend,
    remove: postBodyDefaultRemove
  } = useFieldArray({
    control,
    name: 'post_body_default'
  });

  const {
    fields: postBodyXFormUrlEncodedFields,
    append: postBodyXFormUrlEncodedAppend,
    remove: postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'post_body_urlencoded'
  });

  return {
    headersFields,
    headersAppend,
    headersRemove,
    postBodyDefaultFields,
    postBodyDefaultAppend,
    postBodyDefaultRemove,
    postBodyXFormUrlEncodedFields,
    postBodyXFormUrlEncodedAppend,
    postBodyXFormUrlEncodedRemove,
    ...useFormMethods
  };
};

// FIXME: identifier "useForm" has already been declared or isn't helpful, rename this please!
export default useForm1;
