import { NAMESPACE_MUI, default as generalActions } from 'redux/actions';

import reducer from './reducer';

const PRODUCT_NEW_FETCH_TEAMS = `${NAMESPACE_MUI}PRODUCT_NEW_FETCH_TEAMS`;
const PRODUCT_NEW_FETCH_TEAMS_SUCCESSFULL = `${NAMESPACE_MUI}PRODUCT_NEW_FETCH_TEAMS_SUCCESSFULL`;
const PRODUCT_NEW_CLEAR_STATE = `${NAMESPACE_MUI}PRODUCT_NEW_CLEAR_STATE`;
const PRODUCT_NEW_SELECT_TEAM = `${NAMESPACE_MUI}PRODUCT_NEW_SELECT_TEAM`;
const PRODUCT_AVAILABILITY_SAVE = `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_SAVE`;
const PRODUCT_AVAILABILITY_NEW_TEAM = `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_NEW_TEAM`;

export const actions = {
  PRODUCT_NEW_FETCH_TEAMS,
  PRODUCT_NEW_FETCH_TEAMS_SUCCESSFULL,
  PRODUCT_NEW_CLEAR_STATE,
  PRODUCT_NEW_SELECT_TEAM,
  PRODUCT_AVAILABILITY_SAVE,
  PRODUCT_AVAILABILITY_NEW_TEAM,
  ENTITY_ERROR: generalActions.ENTITY_ERROR,
  POPULATE_ENTITY: generalActions.POPULATE_ENTITY
};

const actionCreators = dispatch => {
  return {
    onSelectTeam: payload => {
      dispatch({
        type: PRODUCT_NEW_SELECT_TEAM,
        payload,
        meta: {
          reducer
        }
      });
    },

    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'product_new',
          touched: {
            [name]: true
          },
          reducer
        }
      });
    },

    save: payload =>
      dispatch({
        type: PRODUCT_AVAILABILITY_SAVE,
        payload
      }),

    fetchTeams: () => {
      dispatch({
        type: PRODUCT_NEW_FETCH_TEAMS
      });
    },

    clearForm: () => {
      dispatch({
        type: PRODUCT_NEW_CLEAR_STATE,
        meta: {
          entity: 'product_new',
          reducer
        }
      });
    }
  };
};

export default actionCreators;
