import { Helmet } from 'react-helmet-async';

const environmentPrefix = (() => {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    return '(local) ';
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return '(dev) ';
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return '(st) ';
  }

  return '';
})();

const Title = ({ title }: { title?: string }) => {
  const suffix = 'Elven Platform';

  return (
    <Helmet>
      <title>
        {environmentPrefix}
        {title ? `${title} | ` : ''}
        {suffix}
      </title>
    </Helmet>
  );
};

export default Title;
