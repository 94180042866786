export const encodeData = <
  FormData extends
    | { provider: 'aws'; accessKeyId: string; secretAccessKey: string }
    | {
        provider: 'azure';
        clientId: string;
        clientSecret: string;
        tenantId: string;
        subscriptionId: string;
      }
    | { provider: 'custom' | '' }
>(
  formData: FormData
) => {
  if (formData.provider === 'aws') {
    return {
      ...formData,
      accessKeyId: btoa(formData.accessKeyId || ''),
      secretAccessKey: btoa(formData.secretAccessKey || '')
    };
  }
  if (formData.provider === 'azure') {
    return {
      ...formData,
      clientId: btoa(formData.clientId || ''),
      clientSecret: btoa(formData.clientSecret || ''),
      tenantId: btoa(formData.tenantId || ''),
      subscriptionId: btoa(formData.subscriptionId || '')
    };
  }
  return formData;
};

export const decodeString = (encodedString: string) => {
  return atob(encodedString);
};
