import { useQuery } from 'urql';

const EnvironmentsListQuery = `#graphql
  query($page: Float!, $per_page: Float!, $sort: String!, $provider: String!, $newApplications: Boolean!, $query: String) {
    environmentsList(page: $page, per_page: $per_page, sort: $sort, provider: $provider, newApplications: $newApplications, query: $query) {
      data {
        id
        name
        cloud {
          name
        }
        newApplications
        agentToken
        providerData
        agent {
          object {
            version
          }
          status
          status_code
        }
      }
      total
    }
  }
`;

type EnvironmentsList = {
  environmentsList: {
    data: {
      id: number;
      name: string;
      cloud: {
        name: string;
      };
      providerData: {
        provider: string;
      };
      newApplications: boolean;
    }[];
    total: number;
  };
};

type EnvironmentsListVariables = {
  page: number;
  per_page: number;
  sort: string;
  provider: string;
  newApplications?: boolean;
  query?: string;
};

export default function useEnvironments(params: EnvironmentsListVariables) {
  const [{ data, fetching }, reexecuteQuery] = useQuery<
    EnvironmentsList,
    EnvironmentsListVariables
  >({
    query: EnvironmentsListQuery,
    variables: params
  });

  return {
    data: data?.environmentsList,
    fetching,
    reloadEnvironments: reexecuteQuery
  };
}
