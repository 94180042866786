import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

const MaintenanceDialog = ({
  formComponent: FormComponent,
  title,
  startValues,
  applicationName,
  inMaintenance,
  fetchingCreate,
  onForceFinish,
  isOpen,
  handleClose
}) => {
  return (
    <OldDeleteDialog
      disableEnforceFocus
      open={isOpen}
      onClose={handleClose}
      title={title}
      maxWidth="xs">
      <FormComponent
        onClose={handleClose}
        startValues={startValues}
        applicationName={applicationName}
        inMaintenance={inMaintenance}
        fetchingCreate={fetchingCreate}
        onForceFinish={onForceFinish}
      />
    </OldDeleteDialog>
  );
};

export default MaintenanceDialog;
