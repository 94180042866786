import { Box, Checkbox, FormControlLabel, FormGroup, Icon, Tooltip } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { useStyles } from 'views/Monitoring/View/styles';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type SidekiqFormData = {
  url: string;
  skipSslValidation: boolean;
  queueSize: number;
  buzySize: number;
  scheduleSize: number;
  connectedClients: number;
};

export function SidekiqForm({ form }: FormProps) {
  const classes = useStyles();

  const { control, watch, errors, setValue, register } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'string',
            label: 'Healthcheck URL',
            name: 'url'
          }}
          InputComponent={Input}
        />
        <Controller
          control={control}
          name="skipSslValidation"
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="Skip SSL"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                        required={false}
                      />
                    }
                  />
                  <Tooltip
                    title={
                      '(TLS) By default, every SSL connection connector makes is verified to be secure. This option allows connector to proceed and operate even for server connections otherwise considered insecure.'
                    }>
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Input
          label="Queue Size"
          name="queueSize"
          inputRef={register}
          type="number"
          defaultValue={0}
          inputProps={{
            min: 0
          }}
        />
        <Input
          label="Buzy Size"
          name="buzySize"
          inputRef={register}
          type="number"
          defaultValue={0}
          inputProps={{
            min: 0
          }}
        />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input
          label="Schedule Size"
          name="scheduleSize"
          inputRef={register}
          type="number"
          defaultValue={0}
          inputProps={{
            min: 0
          }}
        />
        <Input
          label="Connected Clients"
          name="connectedClients"
          inputRef={register}
          type="number"
          defaultValue={0}
          inputProps={{
            min: 0
          }}
        />
      </Box>
    </Box>
  );
}
