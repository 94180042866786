import Checkbox from './CheckBox';

function SkipSSLValidation({
  register,
  defaultChecked,
  name = 'skip_ssl_validation',
  disabled = false
}) {
  return (
    <Checkbox
      inputRef={register}
      defaultChecked={defaultChecked}
      name={name}
      disabled={disabled}
      label="Skip SSL Validation"
      tooltipText="(TLS) By default, every SSL connection connector makes is verified to be secure. This option allows
      connector to proceed and operate even for server connections otherwise considered insecure."
    />
  );
}

export default SkipSSLValidation;
