import { Card, Divider, CardContent, CardActions, CardHeader, Box } from '@material-ui/core';

import { ButtonSimple } from 'componentsV4/Button';

import Form from './Form';
import getStartValues from './getStartValues';
import useCloudsUseForm from './useForm/cloudsUseForm';

const defaultValues = {
  name: '',
  provider: ''
};

interface NewCloudsFormProps {
  title: string;
  submitMutation: () => void;
  startValues: any;
}

const NewCloudsForm = ({ title, submitMutation, startValues }: NewCloudsFormProps) => {
  const {
    control,
    register,
    errors,
    setValue,
    watch,
    formState: { isValid },
    handleSubmit
  } = useCloudsUseForm({
    defaultValues: startValues ? getStartValues(startValues) : defaultValues
  });

  const provider = watch('provider');
  return (
    <Card>
      <form onSubmit={handleSubmit(submitMutation)}>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          <Form
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
            isValid={isValid}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Box marginLeft={1}>
            <ButtonSimple
              color="primary"
              type="submit"
              variant="contained"
              disabled={!isValid || !provider}
              text="Save"
            />
          </Box>
        </CardActions>
      </form>
    </Card>
  );
};

export default NewCloudsForm;
