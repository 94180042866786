import actions from '../../actions';

const newProduct = (state, action) => {
  if (actions.PRODUCT_NEW_STEPPER_COMPLETE === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item,
            env: action.payload
          };
          if (newItem.index === 1) {
            newItem.loading = false;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_RUNTIMES_NEW_FETCH === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item
          };
          if (newItem.index === 2) {
            newItem.loading = false;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_APPLICATIONS_NEW_FETCH === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item
          };
          if (newItem.index === 2) {
            newItem.loading = false;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item,
            entity: action.payload
          };
          if (newItem.index === 1) {
            newItem.loading = false;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_NEW_CHANGE_NAME === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item
          };
          if (newItem.index === 0) {
            newItem.name = action.payload;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_NEW_CHANGE_DESCRIPTION === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          const newItem = {
            ...item
          };
          if (newItem.index === 1) {
            newItem.description = action.payload;
          }
          list.push(newItem);
          return list;
        }, [])
      }
    };
  }

  if (actions.PRODUCT_NEW_STEPPER_ACTIVE === action.type) {
    return {
      ...state,
      products_new: {
        ...state.products_new,
        steps: state.products_new.steps.reduce((list, item) => {
          list.push({
            ...item,
            active: item.index === action.payload
          });
          return list;
        }, [])
      }
    };
  }

  return state;
};

export default newProduct;
