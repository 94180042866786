import { Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

const Info = ({ startValues }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Start: <strong>{dayjs(startValues?.start).format('YYYY/MM/DD HH:mm')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            End: <strong>{dayjs(startValues?.finish).format('YYYY/MM/DD HH:mm')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {startValues?.dependencies && <Typography>Includes dependencies (Add-on)</Typography>}
        </Grid>
      </Grid>
    </>
  );
};

export default Info;
