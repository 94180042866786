import { ReactNode } from 'react';

import {
  FormControl,
  FormHelperText,
  InputBaseProps as InputBasePropsMUI,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Error, Info } from '@material-ui/icons';
import { DeepMap, FieldError } from 'react-hook-form';

import SimpleInput from './SimpleInput';
import useStyles from './styles';

interface InputProps extends InputBasePropsMUI {
  defaultLabel?: string;
  placeholder?: string;
  /**
   * @example
   * // With JSX
   * <Input subLabel={
   *    <Typography variant="caption">
   *      A custom label
   *      <span style={{ color: 'green' }}>with green</span>
   *      text
   *    </Typography>
   *   }
   * />
   *
   * @example
   * // Without JSX
   * <Input
   *  defaultLabel="E-mail"
   *  subLabel="We'll never share your email with anyone else."
   * />
   */
  subLabel?: ReactNode;
  /**
   * Uses this props for helper text or errors message
   */
  helperText?: string;
  /**
   * Shows an tooltip when the input is disabled
   * @default false
   */
  tooltipOnDisabled?: boolean;
  /**
   * if `props.tooltipOnDisabled` is true, show this message in tooltip
   */
  tooltipMessage?: string;
  /**
   * React Hook Form errors object
   *
   * This component will find an error like `errors[props.name]` and show this in
   * helper text message
   */
  errors?: DeepMap<any, FieldError>;
}

const Input = ({
  defaultLabel,
  subLabel,
  helperText,
  placeholder,
  style,
  tooltipMessage,
  tooltipOnDisabled,
  errors,
  ...props
}: InputProps) => {
  const classes = useStyles({ disabled: props.disabled });

  const errorMessage = props?.name && errors && errors[props.name]?.message;

  const helperTextMessage = (() => {
    if (errorMessage && !helperText) return errorMessage;
    if (helperText && !errorMessage) return helperText;
    if (errorMessage && helperText)
      return (
        <p>
          {errorMessage} <br /> {helperText}
        </p>
      );
  })();

  const hasError = props.error || Boolean(errorMessage);

  return (
    <FormControl className={classes.root} style={style} disabled={props?.disabled}>
      <Typography
        className={classes.label}
        variant="body1"
        component="label"
        htmlFor={props.id}
        gutterBottom={Boolean(subLabel)}>
        <strong>{defaultLabel}</strong>
      </Typography>
      {Boolean(subLabel) && (
        <Typography
          variant="caption"
          component="label"
          htmlFor={props.id}
          color="textPrimary"
          gutterBottom>
          {subLabel}
        </Typography>
      )}
      <Tooltip
        title={tooltipMessage ?? ''}
        disableFocusListener
        disableHoverListener={!(props.disabled && tooltipOnDisabled)}>
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          {/* The span is needed because: https://v4.mui.com/components/tooltips/#disabled-elements */}
          <SimpleInput placeholder={placeholder} id={props.id} {...props} />
        </span>
      </Tooltip>

      {helperTextMessage && (
        <FormHelperText className={classes.helperText} error={hasError} color="primary">
          {props.error ? <Error fontSize="medium" /> : <Info fontSize="medium" />}
          {helperTextMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Input;
