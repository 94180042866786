import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { StatusOptions } from 'componentsV4/Filters';
import { Origin } from 'componentsV4/Filters/Search';

import { useStyles } from '../../styles';

export const StatusFilter = ({
  status,
  setStatus,
  handleApplyFilters,
  options
}: {
  options: StatusOptions;
  status: 'all' | StatusEnum | MultiFlowStatusEnum;
  setStatus: Dispatch<SetStateAction<StatusEnum | MultiFlowStatusEnum | 'all'>>;
  handleApplyFilters: ({
    newStatus,
    newSeverity,
    newOrigins
  }: {
    newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
    newSeverity?: SeverityStatusEnum | 'all';
    newOrigins?: Origin[] | undefined;
  }) => void;
}) => {
  const classes = useStyles();

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const statusValue = event.target.value as StatusEnum | MultiFlowStatusEnum;

    setStatus(statusValue);
    handleApplyFilters({ newStatus: statusValue });
  };

  return (
    <TextField
      name="statusFilter"
      label={options.label}
      variant="outlined"
      onChange={handleChangeStatus}
      value={status}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        shrink: true
      }}
      select
      fullWidth>
      {options?.options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
