import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/environment';

function* prepareFetchEntities(axios, action) {
  try {
    const tab = yield select(state => state.products_view.tab);
    const {
      data: { data }
    } = yield axios.get(`/environments/${action.payload}`);
    const {
      data: { data: agent }
    } = yield axios.get(`/environments/${action.payload}/agent`);
    yield put({
      type: actions.ENVIRONMENT_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        ...data,
        agent,
        tab
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchEnvironmentFetch(axios) {
  yield takeLatest(actions.ENVIRONMENT_FETCH, prepareFetchEntities, axios);
}
