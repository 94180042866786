import { useQuery } from 'react-query';

import axios from 'redux/axios';

const useGetResourceGroups = ({ cloudId }) => {
  return useQuery(
    ['/clouds/azure/resourceGroups', cloudId],
    () => axios.get(`/clouds/azure/${cloudId}/resourceGroups`).then(response => response.data.data),
    { refetchOnWindowFocus: false, enabled: cloudId }
  );
};

export default useGetResourceGroups;
