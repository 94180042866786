import { Typography } from '@material-ui/core';
import { ButtonDefault } from 'oldComponents/Button/index';
import { Link } from 'react-router-dom';

import useLeadTimeChanges from 'hooks/queriesGraphQL/useLeadTimeChanges';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { useStyles } from 'views/Monitoring/View/styles';

import { MetricCard } from '../MetricCard';

export function LeadTimeCard({
  applicationUid,
  productId
}: {
  applicationUid: string;
  productId: number | null;
}) {
  const classes = useStyles();

  const canUseMetrics = useFeaturePlan('Metrics.DORA');

  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const { getMetricsByAppUid } = useLeadTimeChanges();

  const availableMetricPeriods = [
    {
      type: '1 day',
      enabled: true
    },
    {
      type: '30 days',
      enabled: canFetchDataFromTheLast30Days
    }
  ].filter(period => period.enabled);

  const [{ data }] = getMetricsByAppUid({
    variables: {
      applicationUid: applicationUid,
      periods: availableMetricPeriods.map(period => period.type)
    }
  });

  const isIntegrated = data?.leadTimeMetricsByAppUid?.metric?.applicationUid !== '';

  const metric = data?.leadTimeMetricsByAppUid?.metric?.metrics?.find(
    (metric: { type: string; hoursFormated: string }) => {
      if (canFetchDataFromTheLast30Days) {
        return metric.type === '30 days';
      }

      return metric.type === '1 day';
    }
  );

  const redirectURL = productId
    ? `/integrations/new/github?productId=${productId}&applicationUid=${applicationUid}&redirectTo=${window.location.pathname}`
    : `/integrations/new/github?applicationUid=${applicationUid}&redirectTo=${window.location.pathname}`;

  const Content = (() => {
    if (!canUseMetrics) {
      return (
        <Link to="/plans">
          <Typography variant="h5" color="primary" className={classes.upgradePlanText}>
            Upgrade your plan
          </Typography>
        </Link>
      );
    }

    return isIntegrated ? (
      <>
        <Typography variant="h5" className={classes.paperTextMiddle}>
          {metric?.hoursFormated}
        </Typography>
        <Typography variant="body2" className={classes.paperTextBottom}>
          Last {metric?.type}
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="body2" className={classes.paperTextBottom}>
          To calculate, it is necessary to make an integration
        </Typography>
        <Link to={redirectURL}>
          <ButtonDefault
            className={classes.spacingButton}
            size="small"
            children="Integrate now"
            variant="text"
          />
        </Link>
      </>
    );
  })();

  return <MetricCard name="Lead Time for Changes">{Content}</MetricCard>;
}
