import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  contactValueBox: {
    border: 1,
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuOptionButton: {
    padding: 0,
    margin: 0,
    width: 42,
    minWidth: 42,
    height: 34
  },
  contactLabel: {
    fontWeight: 'bold'
  }
}));
