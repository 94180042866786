import { useEffect } from 'react';

import useChangeOrg from 'helpers/changeOrg';

export function LogOutView() {
  const { handleLogout } = useChangeOrg();

  useEffect(() => {
    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
