import { Resource } from 'types/external/Resource';

import { CassandraFormData } from 'views/ServicesHub/forms/monitoring/database/Cassandra';

import { monitoringAdapter } from '../monitoring';

export function cassandraAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & CassandraFormData {
  const domain = data.domain;
  // ex: HOST:PORT@USER/PASSWORD

  const [hostAndPort, auth] = domain.split('@');

  const [host, port] = hostAndPort.split(':');
  const [user, password] = auth ? auth.split('/') : [undefined, undefined];

  return {
    ...monitoringAdapter(data),
    host,
    port,
    user,
    password
  };
}
