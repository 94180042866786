import { useQuery } from 'urql';

const ItsmHistoryQuery = `#graphql
  query( $incidentId: Float!) {
    itsmHistory(
      incidentId: $incidentId
    ) {
      state
    }
  }`;

type ItsmHistoryData = {
  itsmHistory: { state: string };
};

export const useItsmHistory = ({ incidentId }: { incidentId: number }) => {
  const [{ data, fetching }] = useQuery<ItsmHistoryData>({
    query: ItsmHistoryQuery,
    variables: { incidentId }
  });

  const state = data?.itsmHistory.state;

  return { state, fetching };
};
