import { useMutation } from 'urql';

const slackAuthorizationUrl = `https://slack.com/oauth/v2/authorize?scope=channels:manage,chat:write&user_scope=&redirect_uri=${window.location.protocol}//${window.location.host}/slack-integration-done&client_id=${process.env['REACT_APP_SLACK_CLIENT_ID_1P']}`;

const authorizeSlack = () => window.open(slackAuthorizationUrl, 'popup', 'width=600,height=600');

const SlackCreateChannel = `
  mutation($roomName: String!, $incidentId: Float!) {
    slackCreateChannelWithIncident(roomName: $roomName, incidentId: $incidentId)
    {
      success
      link
      integrated
    }
  }
`;

type SlackCreateChannelResult = {
  slackCreateChannelWithIncident: { success: boolean; link: string; integrated: boolean };
};

type SlackCreateChannelVariables = { roomName: string; incidentId: number };

export const useSlackCreateWarRoomChannel = () => {
  const [result, slackCreateChannelMutation] = useMutation<
    SlackCreateChannelResult,
    SlackCreateChannelVariables
  >(SlackCreateChannel);

  const slackCreateChannel = async (variables: SlackCreateChannelVariables) => {
    try {
      const { data } = await slackCreateChannelMutation(variables);
      return data;
    } catch (error) {
      authorizeSlack();
    }
  };

  return { authorizeSlack, slackCreateChannel, slackCreateChannelResult: result };
};
