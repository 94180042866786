import { useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ButtonSimple from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import ModalCompound from 'componentsV3/ModalCompound';

import { ItemPreview } from './ItemPreview';

const useStyles = makeStyles(() => ({
  input: {
    marginTop: 32,
    marginBottom: 16
  }
}));

export const EditServiceModal = ({ handleClose, data, onUpdate }) => {
  const classes = useStyles();

  const [name, setName] = useState('');

  return (
    <ModalCompound
      className={classes.root}
      title="Edit service display name"
      isOpen={true}
      handleClose={handleClose}>
      <Box>
        <form
          onSubmit={event => {
            event.preventDefault();
            onUpdate({
              ...data.service,
              displayName: name
            });

            handleClose();
          }}>
          <Grid item className={classes.input}>
            <InputDefault
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              label="What name would you like to display on your status page?"
              placeholder="Auth API"
            />
          </Grid>
          <Grid item xs={12} className={classes.button}>
            <Typography variant="h4">Your customer will see it like this</Typography>
            <ItemPreview name={name || data.service.name} />

            <Box display="flex" justifyContent="flex-end">
              {/* <FormControlLabel
                label="Make this service private?"
                control={
                  <Checkbox
                    checked={isPrivate}
                    onChange={event => setPrivate(event.target.checked)}
                  />
                }
              /> */}

              <ButtonSimple type="submit" color="primary" text="Edit" disabled={!Boolean(name)} />
            </Box>
          </Grid>
        </form>
      </Box>
    </ModalCompound>
  );
};
