import React from 'react';

import { Redirect, Switch, useLocation } from 'react-router-dom';

import { useLoggedUserRole } from 'hooks/useLoggedUserRole';
import { accountsRoutesV4WithLayoutV2 } from 'routes/V4/accountsRoutes';
import { alertsRoutes } from 'routes/V4/alertsRoutes';
import { applicationsRoutes } from 'routes/V4/applicationsRoutes';
import { authRoutesWithLayoutV2 } from 'routes/V4/authRoutes';
import { cloudsRoutesWithLayoutV2 } from 'routes/V4/cloudsRoutes';
import { environmentsRoutesWithLayoutV2 } from 'routes/V4/environmentsRoutes';
import { homeRoutes } from 'routes/V4/homeRoutes';
import { incidentsRoutesWithLayoutV2 } from 'routes/V4/incidentsRoutes';
import { integrationsRoutesWithLayoutV2 } from 'routes/V4/integrationsRoutes';
import { metricsResourcesRoutesWithLayoutV2 } from 'routes/V4/metricsResourcesRoutes';
import { metricsRoutesWithLayoutV2 } from 'routes/V4/metricsRoutes';
import { monitoringRoutes } from 'routes/V4/monitoringRoutes';
import { multiFlowRoutes } from 'routes/V4/multiFlowRoutes';
import { orgsRoutesWithLayoutV2 } from 'routes/V4/orgsRoutes';
import { plansRoutesWithLayoutV2 } from 'routes/V4/plansRoutes';
import { postmortemRoutes } from 'routes/V4/postmortemRoutes';
import { productsRoutesWithLayoutV2 } from 'routes/V4/productsRoutes';
import { servicesHubRoutes } from 'routes/V4/servicesHubRoutes';
import { serviceRoutesWithLayoutV2 } from 'routes/V4/servicesRoutes';
import { statusPageRoutesWithLayoutV2 } from 'routes/V4/statusPageRoutes';
import { teamsRoutesWithLayoutV2 } from 'routes/V4/teamsRoutes';
import { usersRoutesWithLayoutV2 } from 'routes/V4/usersRoutes';
import { utilityRoutesWithLayoutV2 } from 'routes/V4/utilityRoutes';

import firebase from '../services/firebase';

const RoutesListener = () => {
  const location = useLocation();

  React.useEffect(() => {
    firebase.analytics().setCurrentScreen(location.pahtname);
  }, [location]);

  return <></>;
};

const Routes = () => {
  // Load the logged user role here, and in other places pass the { fromCache: true }
  // option, so the only actual request is made here
  useLoggedUserRole();

  const routesV4WithLayoutV2 = [
    authRoutesWithLayoutV2,
    productsRoutesWithLayoutV2,
    orgsRoutesWithLayoutV2,
    accountsRoutesV4WithLayoutV2,
    cloudsRoutesWithLayoutV2,
    environmentsRoutesWithLayoutV2,
    serviceRoutesWithLayoutV2,
    statusPageRoutesWithLayoutV2,
    teamsRoutesWithLayoutV2,
    usersRoutesWithLayoutV2,
    plansRoutesWithLayoutV2,
    utilityRoutesWithLayoutV2,
    incidentsRoutesWithLayoutV2,
    metricsResourcesRoutesWithLayoutV2,
    metricsRoutesWithLayoutV2,
    integrationsRoutesWithLayoutV2,
    monitoringRoutes,
    servicesHubRoutes,
    alertsRoutes,
    multiFlowRoutes,
    applicationsRoutes,
    postmortemRoutes,
    homeRoutes
  ];

  return (
    <Switch>
      {routesV4WithLayoutV2.map(router => router)}

      <Redirect to="/not-found" />
      <RoutesListener />
    </Switch>
  );
};

export default Routes;
