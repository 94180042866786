import { useEffect, useState, ChangeEvent } from 'react';

import { useHistory } from 'react-router';

import { FilterToolbar } from 'components/FilterToolbar';
import { Filter } from 'components/FilterToolbar/Filter';
import { isEqual } from 'helpers/compareFilters';
import useQueryParams from 'helpers/useQueryParams';

import { StatusEnum, RoleEnum } from '../index';

export const Filters = ({
  statusFilter,
  setStatusFilter,
  roleFilter,
  setRoleFilter,
  setSearch,
  setPage
}: {
  statusFilter: StatusEnum;
  setStatusFilter: (status: StatusEnum) => void;
  roleFilter: RoleEnum;
  setRoleFilter: (role: RoleEnum) => void;
  setSearch: (search: string) => void;
  setPage: (page: number) => void;
}) => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const [badgeContent, setBadgeContent] = useState<number>(0);

  const statusURL = queryParams.get('status');
  const roleURL = queryParams.get('role');

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  const handleClearFilters = () => {
    removeQueryParams('role');
    removeQueryParams('status');

    setStatusFilter(StatusEnum.All);
    setRoleFilter(RoleEnum.All);

    setPage(1);
  };

  const handleChangeStatusFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(event?.target.value as StatusEnum);

    if (event?.target.value === StatusEnum.All) {
      return removeQueryParams('status');
    }

    addQueryParams('status', event?.target.value as string);
  };

  const handleChangeRoleFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    setRoleFilter(event?.target.value as RoleEnum);

    if (event?.target.value === RoleEnum.All) {
      return removeQueryParams('role');
    }

    addQueryParams('role', event?.target.value as string);
  };

  useEffect(() => {
    statusURL && setStatusFilter(statusURL as StatusEnum);
    roleURL && setRoleFilter(roleURL as RoleEnum);
  }, [statusURL, roleURL, setStatusFilter, setRoleFilter]);

  useEffect(() => {
    const defaultValues = {
      status: StatusEnum.All,
      role: RoleEnum.All
    };

    const values = {
      status: statusFilter,
      role: roleFilter
    };

    const changedFields = Object.keys(values).filter(key => {
      if (
        (values as Record<string, string>)[key] === undefined ||
        (defaultValues as Record<string, string>)[key] === undefined
      ) {
        return false;
      }

      return !isEqual(
        (values as Record<string, string>)[key],
        (defaultValues as Record<string, string>)[key]
      );
    });

    const content = Math.min(changedFields.length, 5);

    setBadgeContent(content);
  }, [statusFilter, roleFilter]);

  return (
    <FilterToolbar
      placeholder="Search by name"
      handleClearFilters={handleClearFilters}
      badgeContent={badgeContent}
      setSearch={setSearch}>
      <Filter
        name="status"
        label="Status"
        value={statusFilter}
        onChange={handleChangeStatusFilter}
        options={[
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' }
        ]}
      />
      <Filter
        name="role"
        label="Role"
        value={roleFilter}
        onChange={handleChangeRoleFilter}
        options={[
          { label: 'All', value: 'all' },
          { label: 'Member', value: 'member' },
          { label: 'Admin', value: 'admin' },
          { label: 'Owner', value: 'owner' }
        ]}
      />
    </FilterToolbar>
  );
};
