import { FormControl, SelectProps as SelectPropsMUI, Typography } from '@material-ui/core';
import { KeyboardArrowDownSharp } from '@material-ui/icons';

import Select from './Select';
import useStyles from './styles';

interface SelectProps extends SelectPropsMUI {
  children: React.ReactNode;
}

const SelectInput = ({ children, ...props }: SelectProps) => {
  const classes = useStyles({ hasLabel: Boolean(props.label) });

  return (
    <FormControl variant="outlined" className={classes.formControl} margin="dense">
      {props.label && (
        <Typography variant="h5" component="label" htmlFor={props.id} className={classes.label}>
          props.label
        </Typography>
      )}
      <Select
        displayEmpty
        disableUnderline
        style={{ margin: 0 }}
        IconComponent={KeyboardArrowDownSharp}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          getContentAnchorEl: null,
          classes: { paper: classes.paper }
        }}
        {...props}>
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
