import * as z from 'zod';

export const kafkaFieldSchemaValidation = () => {
  return {
    brokers: z.any().refine(
      data => {
        if (data?.length === 1) {
          const isValid = z
            .string()
            .nonempty()
            .is(data[0]);

          return isValid;
        }

        return true;
      },
      { message: 'At least one host must be informed' }
    ),
    consumer: z.string().optional(),
    lag: z.string().optional(),
    mechanism: z.string().optional(),
    user: z.string().optional(),
    password: z.string().optional(),
    sasl: z.boolean().optional(),
    tls: z.boolean().optional(),
    topic: z
      .string()
      .nonempty({ message: 'Topic must be informed' })
      .optional()
  };
};
