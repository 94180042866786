import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    marginBottom: theme.typography.pxToRem(8)
  },
  description: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.typography.pxToRem(8)
  },
  divider: {
    border: `${theme.palette.grey[300]} 1px solid`
  },
  ServiceTitle: {
    fontWeight: 700,
    marginTop: theme.typography.pxToRem(40),
    marginBottom: theme.typography.pxToRem(32)
  },
  card: {
    width: theme.typography.pxToRem(252),
    height: theme.typography.pxToRem(180)
  },
  cardTop: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(8),
    alignItems: 'center'
  },
  cardMedia: {
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
    maxWidth: theme.typography.pxToRem(48)
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginLeft: theme.typography.pxToRem(16),
    alignSelf: 'center'
  },
  cardDescription: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  cardLocked: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.typography.pxToRem(20),
    marginLeft: 'auto'
  },
  cardChip: {
    color: theme.palette.grey[900]
  }
}));

export default useStyles;
