import { ReactNode } from 'react';

import { Box, useTheme } from '@material-ui/core';

export function TabPanel(props: {
  value: number;
  index: number;
  children: NonNullable<ReactNode>;
}) {
  const { children, value, index, ...other } = props;

  const theme = useTheme();

  if (value === index) {
    return (
      <Box
        style={{
          marginLeft: 0,
          marginTop: theme.spacing(2),
          overflow: 'hidden',
          padding: theme.spacing()
        }}
        id={`full-width-tabpanel-${index}`}
        role="tabpanel"
        {...other}>
        {children}
      </Box>
    );
  }

  return null;
}
