import React from 'react';

import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import SwipeableViews from 'react-swipeable-views';

import useQueryParams from 'helpers/useQueryParams';
import { useProductPermission } from 'hooks/useProductPermission';

import TabAvailability from '../../Availability/List';
import Teams from '../../Teams';
const styles = theme => ({
  root: {},
  content: {
    marginTop: theme.spacing(1),
    borderTop: '2px solid #f4f4f4'
  }
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      aria-labelledby={`full-width-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

function TabPanels(props) {
  const { classes, product: view, changeTab, fetching } = props;

  const history = useHistory();

  const tabViewQuery = useQueryParams().get('tab');

  const tabsIndexes = {
    availability: 0,
    teams: 1
  };
  const value = tabViewQuery && tabViewQuery in tabsIndexes ? tabsIndexes[tabViewQuery] : view.tab;

  const handleChange = (event, newValue) => {
    if (tabViewQuery) {
      history.replace(`/products/${view.id}`);
    }
    changeTab(newValue);
  };

  const handleChangeIndex = index => {
    changeTab(index);
  };

  const hasTeamPermission = useProductPermission({
    productId: String(view?.id),
    target: 'ProductController-post-/products/:id/teams/:teamId'
  });
  const hasNewAppPermission = useProductPermission({
    productId: String(view?.id),
    target: 'ApplicationController-post-/products/:productId/applications'
  });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Tabs
          onChange={handleChange}
          aria-label="full width tabs example"
          indicatorColor="primary"
          textColor="primary"
          value={value}>
          <Tab label="Availability" {...a11yProps(0)} />
          <Tab disabled={!hasTeamPermission} label="Teams" {...a11yProps(1)} />
        </Tabs>
        <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
          <TabPanel index={0} value={value}>
            <TabAvailability
              product={view}
              productName={view.name}
              productId={view.id}
              fetching={fetching}
              hasNewAppPermission={hasNewAppPermission}
            />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <Teams productId={view.id} />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}

export default withStyles(styles)(TabPanels);
