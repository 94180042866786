import { MenuItem } from '@material-ui/core';

import { Menu } from 'components/Menu';

export type PeriodProps = {
  onApplyPeriod: ({ period }: { period: string }) => void;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

export function Period({ anchorEl, onApplyPeriod, handleClose }: PeriodProps) {
  const handleApplyPeriod = (period: string) => {
    onApplyPeriod({ period });
    handleClose();
  };

  return (
    <Menu anchorEl={anchorEl} onClose={handleClose}>
      <MenuItem onClick={() => handleApplyPeriod('all')}>All</MenuItem>
      <MenuItem onClick={() => handleApplyPeriod('1d')}>Last Day</MenuItem>
      <MenuItem onClick={() => handleApplyPeriod('7d')}>Last Week</MenuItem>
      <MenuItem onClick={() => handleApplyPeriod('30d')}>Last Month</MenuItem>
      <MenuItem onClick={() => handleApplyPeriod('1y')}>Last Year</MenuItem>
    </Menu>
  );
}
