import { Breadcrumbs, Grid, Link as MuiLink, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[400]
  },
  fontSize: {
    fontSize: theme.typography.pxToRem(14)
  },
  breadcrumbBefore: {
    color: theme.palette.grey[400]
  },
  listItem: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const Breadcrumb = ({ items, style }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} style={{ margin: 16, ...style }}>
      <Breadcrumbs
        separator="‹"
        aria-label="breadcrumb"
        className={classes.root}
        classes={{ li: classes.listItem, separator: classes.fontSize }}>
        {items.map((item, index) => {
          const key = `${item.link}_${item.label}`;

          const isLastItem = index === items.length - 1;

          if (isLastItem) {
            return (
              <Typography key={key} color="textSecondary" className={classes.fontSize}>
                {item.label}
              </Typography>
            );
          }

          if (!item.label) {
            return null;
          }

          return (
            <MuiLink
              key={key}
              className={clsx(classes.breadcrumbBefore, classes.fontSize)}
              to={item.link}
              component={Link}>
              {item.label}
            </MuiLink>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
};

export default Breadcrumb;
