import { Typography } from '@material-ui/core';
import Dialog from 'oldComponents/Dialog';

import LoadingOverlay from 'componentsV3/LoadingOverlay';

function DeleteDialogV2({
  open,
  onClose,
  onPrimaryAction,
  isLoading,
  title,
  primaryActionLabel,
  message
}: {
  open: boolean;
  onClose: () => void;
  onPrimaryAction: () => void;
  isLoading: boolean;
  title: string;
  primaryActionLabel: string;
  message: string;
}) {
  return (
    <Dialog
      open={open}
      title={title}
      showSecondaryAction
      onPrimaryAction={onPrimaryAction}
      onClose={onClose}
      primaryActionLabel={primaryActionLabel}>
      <>
        <Typography variant="body2" style={{ fontWeight: 500, fontSize: 16 }}>
          {message}
          <br />
          <br />
          Are you sure you would like to delete?
        </Typography>

        {isLoading && <LoadingOverlay />}
      </>
    </Dialog>
  );
}

export default DeleteDialogV2;
