import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    border: 'none'
  },
  headIcon: {
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  title: {
    fontSize: theme.typography.pxToRem(28)
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    padding: theme.spacing(0, 1.25, 0, 1.25)
  },
  diviserLine: {
    backgroundColor: theme.palette.grey[200]
  },
  titleCell: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500]
  },
  tableCell: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[700]
  },
  borderBody: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.up('lg')]: {
      width: 967
    }
  },
  spaceButton: {
    marginLeft: theme.spacing(2)
  },
  iconColor: {
    color: theme.palette.text.link,
    '&[data-disabled=true]': {
      color: theme.palette.text.disabled
    }
  },
  restApplicationsBadge: {
    backgroundColor: '#ECFDF5;',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.primary.main
  },
  cardContent: {},
  actionsButtons: {
    display: 'flex',
    alignContent: 'flex-end'
  }
}));
