import { useEffect } from 'react';

import { useQuery as useQueryGraphQL } from 'urql';

const latencyQuery = `#graphql 
  query (
    $applicationId: Int!
  ){
    getLatencyGraph(
      applicationId: $applicationId 
    ) {
      id
      uid
      sterasId
      org {
        uid
      }
      addons {
        id
        sterasId
        graphData {
          latencies {
            hour
            latency
            latency_p90
            latency_p95
            timestamp
          }
          deploys {
            id
            type
            version
            description
            deployXaxis
            timestamp
          }
        }
      }
      graphData {
        latencies {
          hour
          latency
          latency_p90
          latency_p95
          timestamp
        }
        deploys {
          id
          type
          version
          description
          deployXaxis
          timestamp
        }
      }
    }
  }
`;

const useLatencies = ({
  applicationId,
  isMonitoringActive
}: {
  applicationId: number;
  isMonitoringActive: boolean;
}) => {
  const [resultLatencies, reexecuteQuery] = useQueryGraphQL({
    query: latencyQuery,
    pause: !applicationId || !isMonitoringActive,
    variables: { applicationId: Number(applicationId) }
  });

  const { fetching: fetchingLatency } = resultLatencies;

  const dataFromGraphQl = resultLatencies.data?.getLatencyGraph;

  useEffect(() => {
    if (fetchingLatency) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 * 10);

    return () => clearInterval(intervalId);
  }, [fetchingLatency, reexecuteQuery]);

  return dataFromGraphQl;
};

export { useLatencies };
