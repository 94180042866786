import validate from 'validate.js';

import actions from '../../actions';

const crud = (state, action) => {
  if (actions.POPULATE_ENTITY === action.type) {
    const oldState = state[action.meta.entity];

    const newState = {
      ...oldState,
      values: {
        ...oldState.values,
        ...action.payload
      },
      touched: {
        ...oldState.touched,
        ...action.meta.touched
      }
    };

    const errors = validate(newState.values, action.meta.schema);

    return {
      ...state,
      [action.meta.entity]: {
        ...newState,
        errors: errors || {},
        isValid: !errors
      }
    };
  }

  if (actions.PAGINATE_ENTITY === action.type) {
    const stateEntity = state[action.meta.entity];
    return {
      ...state,
      [action.meta.entity]: {
        ...stateEntity,
        ...action.payload
      }
    };
  }

  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      [action.meta.entity]: {
        per_page: 10,
        ...state[action.meta.entity],
        ...action.payload
      }
    };
  }
  return state;
};

export default crud;
