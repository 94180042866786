import React from 'react';

import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { Controller } from 'react-hook-form';

import { useEnvironments } from 'hooks/queriesGraphQL/useEnvironments';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const useStyles = makeStyles(theme => ({
  agentStatus: {
    color: grey[500],
    marginLeft: theme.spacing()
  }
}));

const EnvironmentSelect = ({ control, errors, ...otherProps }) => {
  const classes = useStyles();

  const newApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [{ data, fetching: isLoading }] = useEnvironments(null, newApplications);
  const environmentsList = data?.environments?.data;

  return (
    <FormControl fullWidth size="small" variant="outlined" {...otherProps}>
      <InputLabel>
        {isLoading
          ? 'Loading...'
          : environmentsList?.length
          ? 'Environment'
          : 'No registered environments'}
      </InputLabel>
      <Controller
        as={
          environmentsList?.length ? (
            <Select
              label="Environment"
              required
              IconComponent={ExpandMoreIcon}
              error={Boolean(errors?.environmentId?.message)}>
              {environmentsList?.map(environment => (
                <MenuItem key={environment.id} value={environment.id}>
                  {environment.name}
                  <small className={classes.agentStatus}>
                    {environment.agent
                      ? environment.agent.status_code === 16
                        ? ''
                        : 'Agent not installed'
                      : 'Agent not installed'}
                  </small>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Select
              label="NoEnvironment"
              error={!isLoading}
              style={{
                pointerEvents: 'none'
              }}
            />
          )
        }
        name={`environmentId`}
        control={control}
      />
      {errors?.environmentId ? (
        <FormHelperText error variant="outlined">
          {errors.environmentId.message}
        </FormHelperText>
      ) : (
        !isLoading &&
        !environmentsList?.length && (
          <FormHelperText error variant="outlined">
            You need to create environments first
          </FormHelperText>
        )
      )}
    </FormControl>
  );
};

export default EnvironmentSelect;
