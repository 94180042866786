import { ResourceOrigin } from 'types/external/Resource';

import { MonitoringBaseForm } from '../../forms/monitoring/commom/Base';

function getResourceTypeByMethod(method: string) {
  const methods = {
    db: ['postgres', 'mysql', 'sqlserver', 'mongodb', 'oracle', 'cassandra', 'neo4j', 'dynamodb'],
    cache: ['memcached', 'redis', 'redisV2'],
    http: ['GET', 'POST'],
    queue: ['kafka', 'rabbitmq', 'sqs', 'pubsub', 'servicebus', 'sidekiq'],
    lambda: ['lambdaawsv2', 'functions', 'functionsgen2'],
    search: ['elastic', 'solr'],
    others: ['ping', 'tcp', 'grpc', 'webhook'],
    api: ['api']
  };

  return Object.entries(methods).reduce((type, [key, value]) => {
    if (value.includes(method)) {
      type = key;
    }

    return type;
  }, '');
}

export function monitoringAdapter(data: MonitoringBaseForm & { method: string }) {
  const { name, environmentId, interval, timeout, method, domain } = data;
  return {
    name,
    origin: ResourceOrigin.Agent,
    environmentId: Number(environmentId),
    interval: Number(interval),
    timeout: Number(timeout),
    type: getResourceTypeByMethod(method),
    domainSettings: {},
    method,
    domain
  };
}
