import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    minHeight: '296px',
    minWidth: '551px',
    textAlign: 'center',
    '& > *': {
      whiteSpace: 'pre-wrap'
    }
  },
  paper: {
    borderRadius: 15
  },
  icon: {
    fontSize: 55,
    color: theme.palette.secondary.main
  },
  closeButton: {
    fontSize: 28,
    color: theme.palette.primary.main,
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}));
