import { useQuery } from 'urql';

const getTeamNotifySettingsQuery = `#graphql
    query TeamNotifySettings ($teamId: Float!, $type: String!, $context: String!){
        getTeamNotifySettings(teamId: $teamId, type: $type, context: $context) {
            id,
            enabled,
            type,
            context
        }
    }
`;

export type TeamNotifySetting = {
  id: number;
  enabled: boolean;
  type: string;
  context: string;
};

type GetTeamNotifySettingsProps = {
  teamId: number;
  type: string;
  context: string;
};

type GetTeamNotifySettingsResponse = {
  getTeamNotifySettings: TeamNotifySetting;
};

export const useGetTeamNotifySettings = ({ teamId, type, context }: GetTeamNotifySettingsProps) => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<GetTeamNotifySettingsResponse>({
    query: getTeamNotifySettingsQuery,
    variables: { teamId, type, context }
  });

  return { data: data?.getTeamNotifySettings, fetching, reexecuteQuery };
};
