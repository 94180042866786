import { put, takeLatest } from 'redux-saga/effects';

import calculateProfileCompleteness from 'helpers/user/calculateProfileCompleteness';

import actions from '../../actions';
import reducer from '../../reducer/logged';

const entity_new = {
  isValid: false,
  values: {
    displayName: '',
    photoURL: '',
    cep: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    phone: ''
  },
  touched: {},
  errors: {}
};

function* loggedUser(axios, action) {
  try {
    const {
      data: { data }
    } = yield axios.get('/auth/logged');
    const user = data || {};
    const account_edit = user.profile
      ? {
          ...entity_new,
          values: {
            ...user.profile,
            photoURL: user.photoURL
          }
        }
      : {
          ...entity_new,
          values: {
            ...entity_new.values,
            photoURL: user.photoURL
          }
        };

    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL,
      meta: { entity: 'user', reducer },
      payload: {
        user,
        loading: false,
        account_edit,
        profileCompleteness: calculateProfileCompleteness(account_edit.values)
      }
    });
  } catch (err) {
    yield put({
      type: actions.LOGIN_USER_FAILED,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchLogged(axios) {
  yield takeLatest(actions.LOGGED_USER, loggedUser, axios);
}
