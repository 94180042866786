import { Link, Typography } from '@material-ui/core';

export function TelegramInstructions() {
  return (
    <>
      <Typography>
        1. Click{' '}
        <Link
          href="https://t.me/GlorfindelBot"
          target="_blank"
          rel="noopener noreferrer"
          title="Start a conversation with our bot on Telegram">
          here
        </Link>{' '}
        to start a conversation with our bot on Telegram;
      </Typography>
      <Typography>2. Click the "Start bot" button;</Typography>
      <Typography>3. Type the command "/mychatid"</Typography>
      <Typography>4. The bot will reply with your chat_id number;</Typography>
      <Typography>5. Copy the ID and paste it into the field below.</Typography>
      <Typography>
        6. If you have any questions, check out our{' '}
        <Link
          href="https://elven.works/help/como-configurar-notificacoes-via-telegram/"
          target="_blank"
          rel="noopener noreferrer"
          title="View our documentation">
          documentation
        </Link>
        .
      </Typography>
    </>
  );
}
