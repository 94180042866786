import { useQuery } from 'urql';

const GetTeamQuery = `#graphql
  query(
    $teamId: Int!
  ) {
    getTeam(
      teamId: $teamId
    ) {
      id
      name
      orgUid
    }
  }
`;

export const useGetTeam = ({ teamId }: { teamId: number }) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery<{
    getTeam: {
      id: number;
      name: string;
      orgUid: string;
    };
  }>({
    query: GetTeamQuery,
    variables: { teamId }
  });

  return { data: data?.getTeam, fetching, reexecuteQuery };
};

export default useGetTeam;
