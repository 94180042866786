import React from 'react';

import { Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Form = props => {
  const { children, className, title, formState, onSave, onSubmit, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form name="CrudForm" onSubmit={onSubmit}>
        <CardHeader title={title} />
        <Divider />
        <CardContent>{children}</CardContent>
        <Divider />
        <CardActions>
          <ButtonSimple
            color="primary"
            disabled={!formState.isValid}
            onClick={onSave}
            type="submit"
            variant="contained"
            text="Save"
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default Form;
