import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation as useMutationGraphql } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import useFormState from '../../../hooks/crud/useFormState';
import Form from '../components/Form';
import initialValues from '../components/initialValues';
import notificationInitialValues from '../components/notificationInitialValues';
import notificationSchema from '../components/notificationSchema';
import schema from '../components/schema';
import useCreateTeam from '../hooks/useCreateTeam';

const createTeamWithMSTeams = `#graphql
  mutation(
    $name: String!,
    $userId: Float!,
    $providersConfig: [ProvidersConfig!]!
  ){
    createTeamWithMSTeams(
      name: $name, 
      userId: $userId,
      providersConfig: $providersConfig
    ){
      id
      name
      orgUid
    }
  }
`;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const TeamsNew = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const router = useHistory();

  const { formState, onChange } = useFormState({
    schema: { ...schema, ...notificationSchema },
    initialValues: { ...initialValues, ...notificationInitialValues }
  });

  const { requestStatus } = useCreateTeam();

  const [, createTeamMutationV2] = useMutationGraphql(createTeamWithMSTeams);

  const onCreateTeam = async data => {
    const notificationSetup = data.notificationSetup.map(({ notificationSetupId, ...rest }) => ({
      ...rest
    }));

    await createTeamMutationV2({
      name: data.name,
      userId: Number(user.id),
      providersConfig: notificationSetup
    })
      .then(data => {
        const id = data.data.createTeamWithMSTeams.id;
        router.push(`/teams/${id}`);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Team created successfully'
        });
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const user = useSelector(state => state.user);

  const permissions = {
    new: usePermission('TeamController-post-/teams'),
    update: usePermission('TeamController-put-/teams/:id'),
    remove: usePermission('TeamController-delete-/teams/:id')
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={requestStatus.isLoading}>
        <CircularProgress size={90} thickness={5} />
      </Backdrop>
      <Breadcrumb
        items={[{ label: 'People' }, { label: 'Team Center', link: '/teams' }, { label: 'New' }]}
      />
      <Form
        canCreate={permissions.new}
        formState={formState}
        onChange={onChange}
        newTeam={true}
        onSave={onCreateTeam}
        title="New Team"
      />
    </>
  );
};

export default TeamsNew;
