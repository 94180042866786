import { useState } from 'react';

import cloudProviders from 'constants/cloudProviders';

import { Box, FormHelperText, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import ProviderInput from 'componentsV3/ProviderInput';
import RadioGroup from 'componentsV3/RadioGroup';
import { encodeData } from 'helpers/encodeData';
import useQueryParams from 'helpers/useQueryParams';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

import CloudAWS from './cloudFields/CloudAWS';
import CloudAzure from './cloudFields/CloudAzure';
import CloudCustom from './cloudFields/CloudCustom';
import ExistingCloudForm from './ExistingCloudForm';
import { FormData } from './schema';
import useForm from './useForm';

const useStyles = makeStyles<Theme>(theme => ({
  row: {
    marginTop: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(5)
  }
}));

const CreateCloudMutation = `#graphql
    mutation($cloudInput: CloudInput!){
      createCloud (cloudInput: $cloudInput){
        id
        provider
      }
    }
  `;

type CreateCloudMutationType = { id: number; provider: keyof typeof cloudProviders };

enum Mode {
  NewCloud,
  ExistingCloud
}

export type Props = {
  onFinish: (cloudInfo: CreateCloudMutationType) => void;
  applicationType: string;
};

function TabCloud({ onFinish, applicationType }: Props) {
  const classes = useStyles();

  const [mode, setMode] = useState<Mode>(Mode.NewCloud);

  const {
    handleSubmit,
    register,
    errors,
    control,
    watch,
    setValue,
    formState: { isValid }
  } = useForm();

  const [createCloudResult, createCloud] = useMutation<{ createCloud: CreateCloudMutationType }>(
    CreateCloudMutation
  );

  const { error, fetching: fetchingCloudStatus } = createCloudResult;

  const serverErrorMessage = error?.graphQLErrors
    ? error.graphQLErrors[0]?.originalError?.message
    : null;

  const submitMutation = (formData: FormData) => {
    const { name, provider, newCloud, ...providerCredentials } = encodeData(formData);

    createCloud({
      cloudInput: { name, provider, providerCredentials: { provider, ...providerCredentials } }
    }).then(result => {
      if (result.error || !result.data) return;

      onFinish(result.data.createCloud);
    });
  };

  const selectedProvider = watch('provider');

  const ButtonValidateCloud = () => (
    <Grid className={classes.button} item xs={12}>
      <ButtonSimple
        className={classes.button}
        type="submit"
        color="primary"
        disabled={!isValid}
        text="Validate Cloud and configure Environment"
        onClick={() => {
          const eventsMap: { [key: string]: string } = {
            internalMultiHttp: firstAccess ? 'fa_validate_cloud_multi' : 'validate_cloud_multi'
          };

          if (eventsMap[applicationType]) {
            analyticsEvent(eventsMap[applicationType]);
          }
        }}
      />
      {serverErrorMessage && <FormHelperText error>{serverErrorMessage}</FormHelperText>}
    </Grid>
  );

  const firstAccess = useQueryParams().has('firstAccess');

  return (
    <Grid container>
      <Grid item xs={12} className={classes.row}>
        <Typography variant="h3" gutterBottom>
          Configure Cloud
        </Typography>

        <Typography>
          It is important that you have admin permission on your cloud to perform these steps.
        </Typography>

        <Box marginTop={4}>
          <Typography variant="h6">Do you want to set up a new cloud?</Typography>
          <RadioGroup
            options={[
              { label: 'New Cloud', value: Mode.NewCloud },
              { label: 'Existing Cloud', value: Mode.ExistingCloud }
            ]}
            value={mode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
              const eventsMap: { [key: string]: { [key: string]: string } } = {
                internalMultiHttp: {
                  [Mode.NewCloud]: firstAccess ? 'fa_new_cloud_multi' : 'new_cloud_multi',
                  [Mode.ExistingCloud]: firstAccess
                    ? 'fa_existing_cloud_multi '
                    : 'existing_cloud_multi '
                },
                internal: {
                  [Mode.NewCloud]: firstAccess
                    ? 'fa_new_iaea_create_new_cloud'
                    : 'new_iaea_create_new_cloud',
                  [Mode.ExistingCloud]: firstAccess
                    ? 'fa_new_iaea_use_existing_cloud '
                    : 'new_iaea_use_existing_cloud '
                },

                externalWithAddons: {
                  [Mode.NewCloud]: firstAccess
                    ? 'fa_new_eaea_create_new_cloud'
                    : 'new_eaea_create_new_cloud',
                  [Mode.ExistingCloud]: firstAccess
                    ? 'fa_new_eaea_use_existing_cloud'
                    : 'new_eaea_use_existing_cloud'
                }
              };

              if (eventsMap[applicationType] && eventsMap[applicationType][value]) {
                analyticsEvent(eventsMap[applicationType][value]);
              }

              setMode(Number(value));
            }}
            name="newCloud"
          />
        </Box>
      </Grid>

      {mode === Mode.ExistingCloud && (
        <Grid item xs={12} sm={6} className={classes.row}>
          <ExistingCloudForm onFinish={onFinish} applicationType={applicationType} />
        </Grid>
      )}

      {mode === Mode.NewCloud && (
        <Grid item sm={12} className={classes.row}>
          <form onSubmit={handleSubmit(submitMutation)}>
            <Grid container>
              <Grid item sm={6}>
                <InputDefault
                  inputRef={register}
                  errors={errors}
                  name="name"
                  label={'Cloud Name'}
                  placeholder={'Ex: Cloud test'}
                  required
                />
              </Grid>

              <Grid item sm={6} />

              <Grid item sm={6} className={classes.row}>
                <Typography>Select your cloud provider</Typography>

                <ProviderInput name="provider" control={control} />
              </Grid>

              {selectedProvider === 'aws' && (
                <Grid item sm={8} className={classes.row}>
                  <CloudAWS
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                  />
                </Grid>
              )}

              {selectedProvider === 'azure' && (
                <Grid item sm={8} className={classes.row}>
                  <CloudAzure errors={errors} control={control} setValue={setValue} watch={watch} />
                </Grid>
              )}

              {selectedProvider === 'custom' && (
                <Grid item sm={12} className={classes.row}>
                  <CloudCustom />
                </Grid>
              )}
              <ButtonValidateCloud />
            </Grid>
          </form>
          {fetchingCloudStatus && (
            <LoadingOverlay text="Validating cloud credentials..." size={60} isRelative={false} />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default withThemeV3Hoc(TabCloud);
