import dayjs from 'dayjs';

export const maintenanceMessage = ({
  start,
  finish,
  entity
}: {
  start: string;
  finish: string;
  entity: string;
}): string => {
  const isFutureDay =
    dayjs(new Date(start)).startOf('day') > dayjs(new Date()).startOf('day') ? true : false;

  const isSameDay =
    dayjs(new Date(finish)).startOf('day') > dayjs(new Date(start)).startOf('day') ? false : true;

  const format = isFutureDay ? 'MM/DD HH:mm' : isSameDay ? 'HH:mm' : 'MM/DD HH:mm';

  const maintenanceMessage =
    'This ' +
    entity +
    ' is under scheduled maintenance (' +
    dayjs(new Date(start)).format(format) +
    ' - ' +
    dayjs(new Date(finish)).format(format) +
    ')';

  return maintenanceMessage;
};
