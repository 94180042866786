import { FunctionFormData } from 'views/ServicesHub/forms/monitoring/serveless/Function';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

export function functionsCommomData(data: MonitoringCommomData & FunctionFormData) {
  return {
    domain: data.function,
    domainSettings: {
      function: data.function,
      searchPeriod: data.notInvoke,
      credentials: data.credentials,
      locations: data.locations
    }
  };
}
