import { Resource } from 'types/external/Resource';
import { useQuery } from 'urql';

const GetResourceQuery = `#graphql
  query(
    $uid: String!
  ) {
      resource(resourceUid: $uid) {
        name
        origin
        environmentId
        interval
        timeout
        domainSettings
        orgUid
        method
        domain
      }
  }
`;

export function useResource(uid: string) {
  return useQuery<{ resource: Resource }>({
    query: GetResourceQuery,
    variables: {
      uid
    },
    pause: Boolean(!uid)
  });
}
