import { ChangeEvent } from 'react';

import { Box, Button } from '@material-ui/core';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type PubSubFormData = {
  topic: string;
  credentials: string;
};

export function PubSubForm({ form }: FormProps) {
  const { register, setValue } = form;

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    reader.onload = () => {
      const json = JSON.stringify(JSON.parse(String(reader.result))).trim();
      setValue('credentials', json);
    };
    reader.readAsText(file!);
  };

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <Input label="Topic" name="topic" inputRef={register} />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input
          label="Credentials (JSON)"
          name="credentials"
          inputRef={register}
          multiline
          maxRows={10}
          minRows={5}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" title="Upload a .json file" component="label">
          Upload a JSON
          <input
            accept="application/json"
            id="json-file"
            name="fileInput"
            style={{ display: 'none' }}
            type="file"
            onChange={onUploadFile}
          />
        </Button>
      </Box>
    </Box>
  );
}
