import { useEffect } from 'react';

import { useQuery } from 'urql';

const GetUptimesByServiceIdV2 = `#graphql
  query($serviceId: Int!) {
    uptimeV2(
      serviceId: $serviceId
    ) {
      online1Hour
    }
  }
`;

const UptimeLine = ({
  sterasId,
  monitoringActive
}: {
  sterasId?: number | null;
  monitoringActive?: boolean;
}) => {
  const [resultUptime, reexecuteQuery] = useQuery({
    query: GetUptimesByServiceIdV2,
    pause: !sterasId,
    variables: { serviceId: sterasId }
  });

  const { fetching } = resultUptime;

  const uptime =
    resultUptime?.data && monitoringActive ? `${resultUptime.data.uptimeV2.online1Hour}%` : `0%`;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 /* ms = 1 minute */);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  return <>{uptime}</>;
};

export default UptimeLine;
