import { Grid, MenuItem, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab-old/Alert';
import { Select, TextField } from 'oldComponents/Inputs';
import ArrayField from 'oldComponents/Inputs/ArrayField';
import CheckBox from 'oldComponents/ResourceInputs/CheckBox';
import SecretsField from 'oldComponents/SecretsField';

import healthCheckExempleHelper from 'helpers/getHealthCheckHelperText';

export const KafkaForm = ({
  control,
  errors,
  setValue,
  watch,
  getValues,
  disabled = false,
  register,
  startValues,
  kafkaBrokersFields,
  kafkaBrokersAppend,
  kafkaBrokersRemove
}) => {
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ArrayField
            name="brokers"
            label="List of Hosts*"
            register={register}
            fields={kafkaBrokersFields}
            fieldAppend={kafkaBrokersAppend}
            fieldRemove={kafkaBrokersRemove}
            control={control}
            setValue={setValue}
            disabled={disabled}
            watch={watch}
            errors={errors}
            defaultHelperText={healthCheckExempleHelper('kafka_advanced')}
          />
        </Grid>
        <Grid item xs={4}>
          <SecretsField
            TextFieldProps={{
              label: 'Username',
              name: 'user'
            }}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            errors={errors}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <SecretsField
            TextFieldProps={{
              label: 'Password',
              name: 'password',
              type: 'password'
            }}
            control={control}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            errors={errors}
            disabled={disabled}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          alignItems="center"
          spacing={1}
          style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Grid item xs={8}>
            <Select
              control={control}
              label="SASL Mechanism"
              name="mechanism"
              errors={errors}
              disabled={disabled}>
              <MenuItem value="">Not informed</MenuItem>
              <MenuItem value="PLAIN">PLAIN</MenuItem>
              <MenuItem value="SCRAM-SHA-256">SCRAM-SHA-256</MenuItem>
              <MenuItem value="SCRAM-SHA-512">SCRAM-SHA-512</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={3}>
            <CheckBox
              inputRef={register}
              defaultChecked={startValues?.sasl}
              name="sasl"
              tooltipText="Check it up if your cluster use SASL connection"
              label="SASL"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            register={register}
            required
            label="Topic"
            errors={errors}
            name="topic"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            register={register}
            required={false}
            label="Consumer Group"
            errors={errors}
            disabled={disabled}
            name="consumer"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            register={register}
            required={false}
            label="Lag Tolerance"
            errors={errors}
            name="lag"
            disabled={disabled}
            type="number"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <CheckBox
            inputRef={register}
            defaultChecked={startValues?.tls}
            name="tls"
            disabled={disabled}
            tooltipText="Check it up if your cluster use TLS connection"
            label="TLS"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity="info">
            <Typography variant="subtitle1" color="inherit" style={{ fontWeight: 500 }}>
              We have two ways to monitor your Kafka cluster:
            </Typography>
            <p>
              1. If you inform the fields "Lag Tolerance" and "Consumer Group" we're going to
              validate if there's more Messages behind than the "Lag Tolerance"
            </p>

            <p>
              2. If you do NOT inform the "Lag Tolerance" and "Consumer Group" fields, we're going
              to produce a message and consume it. So in the field "Topic" you should inform a NON
              Production topic, something like "1p-monitoring-events".
            </p>
          </Alert>
        </Grid>
      </Grid>
    </Grid>
  );
};
