import { z } from 'zod';

const commomProperties = {
  name: z.string().nonempty({ message: 'Environment name is required' }),
  tag: z.string().nonempty({ message: 'A tag is required' })
};

const schema = z.union([
  z.object({
    ...commomProperties,
    provider: z.literal('aws'),
    region: z.string().nonempty({ message: 'Region is required' }),
    vpc: z.string().nonempty({ message: 'VPC is required' }),
    subnet: z.string().nonempty({ message: 'Subnet is required' }),
    associatePublicIpAddress: z.string().nonempty(),
    instanceType: z.string().optional()
  }),
  z.object({
    ...commomProperties,
    provider: z.literal('azure'),
    vnet: z.string().nonempty({ message: 'Virtual network is required' }),
    location: z.string().nonempty({ message: 'A location is required' }),
    subnetName: z.string().nonempty({ message: 'Subnet is required' }),
    resourceGroup: z.string().nonempty({ message: 'Resource group network is required' })
  }),
  z.object({
    ...commomProperties,
    provider: z.literal('custom')
  })
]);

export default schema;
