import { postBodyTypeValues } from 'constants/postBody';

import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import uuid from 'uuid';

import { initialState as product_new } from 'views/Products/New/Availability/redux/mapStateToProps';

import actions from '../actions';

export const entity_new = {
  isValid: false,
  values: {},
  touched: {},
  errors: {}
};

export const service_entity_new = {
  isValid: false,
  values: {
    name: '',
    check_type: '',
    method: '',
    post_body: '',
    postBodyType: postBodyTypeValues.raw,
    headers: '',
    domain: '',
    serviceSettings: {
      envs: [
        // {
        //   uid: uuid(),
        //   key: '',
        //   value: ''
        // }
      ]
    }
  },
  touched: {},
  errors: {}
};

const entity_new_recovery = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
  submit_success: ''
};

const initialEntityState = {
  data: [],
  count: 0,
  total: 0,
  page: 1,
  pageCount: 0,
  per_page: 10
};

export const initialState = {
  initialUrl: null,
  loading: true,
  upload: {
    status: false
  },
  globalErrors: [],
  signIn: {
    isValid: false,
    values: {
      email: '',
      password: ''
    },
    touched: {},
    errors: {}
  },
  signUp: {
    isValid: false,
    values: {
      email: '',
      password: '',
      policy: false
    },
    touched: {},
    errors: {}
  },
  org: {
    isValid: false,
    values: {
      name: '',
      ein: '',
      email: '',
      companyName: '',
      policy: false
    },
    touched: {},
    errors: {}
  },
  globalSuccess: '',

  user: { email: null },
  guest: { email: null, policy: false },
  account_edit: { ...entity_new },
  account_security_edit: { ...entity_new },
  selectedAccount: 0,
  sent_email: { ...entity_new_recovery },
  submit_success: '',
  reset_password: { ...entity_new },

  guest_signup: { ...entity_new },

  signUpGuestError: [],

  // new
  users_new: {
    teams: [],
    roles: [],
    data: [],
    isValid: false,
    errors: {}
  },
  features_new: {
    isValid: false,
    values: {
      name: '',
      target: '',
      requiresDependent: false,
      description: ''
    },
    touched: {},
    errors: {}
  },
  environments_new: { ...entity_new },
  clouds_new: { ...entity_new },
  teams_new: { ...entity_new },
  services_new: { ...service_entity_new },
  menuItems_new: { ...entity_new },
  roles_new: { ...entity_new },
  permissions_new: { ...entity_new },

  // edit
  services_edit: { ...service_entity_new },
  users_edit: { ...entity_new },
  teams_edit: { ...entity_new },
  clouds_edit: { ...entity_new },
  environments_edit: { ...entity_new },
  menuItems_edit: { ...entity_new },
  roles_edit: { ...entity_new },
  features_edit: { ...entity_new },
  permissions_edit: { ...entity_new },

  // initial
  accounts: { ...initialEntityState },
  users: { ...initialEntityState },
  services: { ...initialEntityState },
  environments: { ...initialEntityState },
  runtimes: { ...initialEntityState },
  clusters: { ...initialEntityState },
  clouds: { ...initialEntityState },
  teams: { ...initialEntityState },
  addons: { ...initialEntityState },
  menuItems: { ...initialEntityState },
  roles: { ...initialEntityState },
  features: { ...initialEntityState },
  permissions: { ...initialEntityState },

  products: { ...initialEntityState, page: '', per_page: '' },
  matrix_view: {
    tab: 0
  },
  products_view: {
    tab: 0
  },
  products_runtimes: { ...initialEntityState },
  products_applications: { ...initialEntityState },
  products_status_applications: { ...initialEntityState },
  products_services: { ...initialEntityState },
  products_teams: { ...initialEntityState },
  environment_view: {
    tab: 0
  },
  product_new,
  products_new: {
    steps: [
      {
        index: 0,
        label: 'New Product',
        active: true,
        name: ''
      },
      {
        index: 1,
        label: 'Brand and Description',
        active: false,
        loading: true,
        description: ''
      },
      {
        index: 2,
        label: 'New Application',
        active: false,
        loading: true
      }
    ]
  },

  products_edit: {
    isValid: false,
    values: {
      name: '',
      description: '',
      cover: ''
    },
    touched: {},
    errors: {}
  },

  product_availability_application_new: {
    isValid: false,
    values: {
      environmentId: null,
      method: 'GET',
      name: '',
      healthcheckUrl: '',
      external: false,
      timeout: 60,
      check_interval: 60,
      envs: [
        {
          uid: uuid(),
          key: '',
          value: ''
        }
      ]
    },
    touched: {},
    errors: {}
  },

  product_availability_application_edit: {
    isValid: false,
    values: {
      id: null,
      environmentId: null,
      name: '',
      healthcheckUrl: '',
      timeout: null,
      check_interval: null,
      validationString: '',
      envs: [
        {
          uid: uuid(),
          key: '',
          value: ''
        }
      ],
      status: []
    },
    touched: {},
    errors: {}
  },

  product_applications_view: {
    isValid: false,
    values: {
      environmentId: '',
      name: '',
      productId: '',
      healthcheckUrl: '',
      method: 'GET',
      timeout: null,
      check_interval: null,
      envs: [
        {
          uid: uuid(),
          key: '',
          value: ''
        }
      ]
    },
    touched: {},
    errors: {}
  },

  product_applications_status_page_view: {
    isValid: false,
    values: {
      environmentId: '',
      name: '',
      productId: '',
      runtimeId: null
    },
    touched: {},
    errors: {}
  },

  product_applications_new: {
    isValid: false,
    values: {
      environmentIds: [],
      name: '',
      productId: '',
      // healthcheckUrl: '',
      // timeout: null,
      // check_interval: null,
      // envs: [
      //   {
      //     uid: uuid(),
      //     key: '',
      //     value: ''
      //   }
      // ],
      runtimeId: null
    },
    touched: {},
    errors: {}
  },

  application_availability: {
    application: {
      addons: [],
      status: []
    },
    view: {
      autoComplete: {
        open: false,
        options: [],
        loading: false,
        searchText: ''
      }
    }
  },

  // product_availability_new: {
  //   isValid: false,
  //   values: {
  //     environmentIds: [],
  //     name: '',
  //     productId: '',
  //     healthcheckUrl: '',
  //     timeout: null,
  //     check_interval: null,
  //     envs: [
  //       {
  //         uid: uuid(),
  //         key: '',
  //         value: ''
  //       }
  //     ],
  //     runtimeId: null
  //   },
  //   touched: {},
  //   errors: {}
  // },

  addon_view: {
    application: {},
    service: {},
    settings: {
      isValid: false,
      values: {
        domain: '',
        check_interval: '',
        timeout: '',
        envs: [
          {
            uid: uuid(),
            key: '',
            value: ''
          }
        ]
      },
      touched: {},
      errors: {}
    }
  },

  product_applications_settings: {
    isValid: false,
    values: {
      url: '',
      host: '',
      port: '',
      dockerImage: '',
      healthcheckUrl: '',
      timeout: null,
      check_interval: null,
      envs: [
        {
          uid: uuid(),
          key: '',
          value: ''
        }
      ]
    },
    touched: {},
    errors: {}
  },

  product_applications_edit: {
    isValid: false,
    values: {
      environmentIds: [],
      name: '',
      productId: '',
      runtimeId: null
    },
    touched: {},
    errors: {}
  },

  product_runtimes_new: {
    isValid: false,
    values: {
      name: '',
      deploy: false,
      environmentId: '',
      productId: ''
    },
    touched: {},
    errors: {}
  },

  product_runtimes_edit: {
    isValid: false,
    values: {
      name: '',
      deploy: false,
      environmentId: '',
      productId: ''
    },
    touched: {},
    errors: {}
  },

  product_services_view: {
    autoComplete: {
      open: false,
      options: [],
      loading: false,
      searchText: ''
    }
  },

  product_teams_view: {
    autoComplete: {
      open: false,
      options: [],
      loading: false,
      searchText: ''
    }
  },

  application_services_view: {
    autoComplete: {
      open: false,
      options: [],
      loading: false,
      searchText: ''
    }
  },

  environment_clusters: { ...initialEntityState },

  members_new: {
    roles: [],
    data: [],
    isValid: false,
    errors: {},
    autoComplete: {
      open: false,
      options: [],
      loading: false,
      searchText: ''
    }
  },
  members_edit: { ...entity_new },

  teams_view: {
    autoComplete: {
      open: false,
      options: [],
      loading: false,
      searchText: ''
    },
    tab: 0,
    teams_members: {
      ...initialEntityState
    },
    teams_products: {
      ...initialEntityState
    },
    products: {
      ...initialEntityState
    }
  },

  incident_view: {
    timeline: [],
    post_mortem: {}
  }
};

const setId = (url, state) => {
  const [entity, id] = url.split('/').slice(1);
  const entityView = state[`${entity}_view`] || {};
  const entityNew = state[`${entity}_new`] || {};
  const entityEdit = state[`${entity}_edit`] || {};
  return {
    ...state,
    [`${entity}_new`]: {
      ...entityNew,
      id
    },
    [`${entity}_edit`]: {
      ...entityEdit,
      id
    },
    [`${entity}_view`]: {
      ...entityView,
      id
    }
  };
};

const createReducer = history => {
  const fn = connectRouter(history);

  return (state = initialState, action) => {
    if (action.type === LOCATION_CHANGE) {
      const { pathname } = action.payload.location;
      const preparedState = setId(pathname, state);
      return {
        ...preparedState,
        initialUrl: !state.initialUrl ? pathname : state.initialUrl,
        router: fn({
          ...action.payload,
          user: state.user
        })
      };
    }

    if (action.meta && action.meta.reducer) {
      return action.meta.reducer(state, action);
    }

    if (action.type === actions.RELOAD) {
      return {
        ...state,
        ...initialState,
        loading: false
      };
    }

    if (action.type === actions.RESET_ENTITY) {
      return {
        ...state,
        [action.meta.entity]: action.payload
      };
    }

    if (action.type === actions.RESET_FORM_ENTITY) {
      return {
        ...state,
        [action.meta.entity]: action.payload
      };
    }

    if (action.type === actions.ENTITY_ERROR) {
      const errors = state.globalErrors;
      const { message, stack } = action.payload;
      const data = action.payload.response ? action.payload.response.data : null;

      errors.push({ message: (data && data.message) || message, stack, data });
      return {
        ...state,
        globalErrors: errors
      };
    }

    if (action.type === actions.CLEAR_ERROR) {
      return {
        ...state,
        globalErrors: [],
        globalSuccess: '',
        globalWarning: ''
      };
    }

    if (action.type === actions.GLOBAL_SUCCESS) {
      return {
        ...state,
        globalSuccess: action.payload
      };
    }

    if (action.type === actions.GLOBAL_WARNING) {
      return {
        ...state,
        globalWarning: action.payload
      };
    }

    return state;
  };
};

export default createReducer;
