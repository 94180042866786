import validate from 'validate.js';

import { actions } from './actionCreators';
import { initialState } from './mapStateToProps';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500
    }
  }
};

function validateComplete(product_new, values, selectedTeams) {
  const errors = validate(values, schema);

  if (selectedTeams) {
    const isValid = product_new.teams.filter(team => {
      const founded = selectedTeams.filter(teamId => teamId === team.id);
      return founded.length && team.members.length;
    }).length;

    if (!isValid) {
      return {
        ...errors,
        teams: ["They're not team"]
      };
    }
  } else {
    const isValid = product_new.teams.filter(team => {
      const founded = product_new.selectedTeams.filter(teamId => teamId === team.id);
      return founded.length && team.members.length;
    }).length;

    if (!isValid) {
      return {
        ...errors,
        teams: ["They're not team"]
      };
    }
  }

  return errors;
}

const reducer = (state, action) => {
  if (action.type === actions.PRODUCT_NEW_CLEAR_STATE) {
    return {
      ...state,
      product_new: initialState
    };
  }

  if (action.type === actions.PRODUCT_NEW_FETCH_TEAMS_SUCCESSFULL) {
    return {
      ...state,
      product_new: {
        ...state.product_new,
        ...action.payload,
        loading: false
      }
    };
  }

  if (actions.PRODUCT_NEW_SELECT_TEAM === action.type) {
    const selectedTeams = action.payload;
    const errors = validateComplete(state.product_new, state.product_new.values, selectedTeams);
    return {
      ...state,
      product_new: {
        ...state.product_new,
        ...action.payload,
        selectedTeams,
        errors: errors || {},
        isValid: !errors
      }
    };
  }

  if (actions.POPULATE_ENTITY === action.type) {
    const oldState = state[action.meta.entity];

    const newState = {
      ...oldState,
      values: {
        ...oldState.values,
        ...action.payload
      },
      touched: {
        ...oldState.touched,
        ...action.meta.touched
      }
    };

    const errors = validateComplete(oldState, newState.values);

    return {
      ...state,
      [action.meta.entity]: {
        ...newState,
        errors: errors || {},
        isValid: !errors
      }
    };
  }

  return state;
};

export default reducer;
