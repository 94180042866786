import { Box, Divider, useTheme, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import { SearchInput } from 'oldComponents';
import { ButtonDefault } from 'oldComponents/Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useDebounce from './useDebounce';

const CrudToolbar = props => {
  const {
    title,
    className,
    buttonNewName,
    searchPlaceholder,
    entity,
    newEntity,
    disableNew,
    noSearch,
    noButton,
    query,
    setQuery,
    urlNew,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const buttonNewHandler = () => {
    if (urlNew) {
      history.push(urlNew);
      return;
    }
    dispatch(push(`/${newEntity || entity}/new`));
  };

  const onChangeHandler = useDebounce(event => {
    setQuery(event.target.value.length >= 3 ? event.target.value : '');
  }, 500);

  const theme = useTheme();

  return (
    <div {...rest} className={className}>
      <Box display="flex" alignItems="center">
        {title && <Typography variant="h4">{title}</Typography>}

        {!noButton && (
          <ButtonDefault
            children="New"
            variant="contained"
            color="primary"
            fullWidth
            onClick={buttonNewHandler}
            style={{ width: 'initial', marginLeft: 'auto' }}
            disabled={disableNew}
          />
        )}
      </Box>

      <Divider
        style={{
          border: `1px solid ${theme.palette.gray[200]}`,
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        }}
      />

      {!noSearch && (
        <Box display="flex">
          <SearchInput onChange={onChangeHandler} placeholder={searchPlaceholder} />
        </Box>
      )}
    </div>
  );
};

CrudToolbar.propTypes = {
  className: PropTypes.string
};

export default CrudToolbar;
