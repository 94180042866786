import React from 'react';

import Form from '../components/Form';

class EnvironmentsEditForm extends React.Component {
  render() {
    const { formState, onChange, clouds } = this.props;

    return (
      <Form
        disableEditCloud={true}
        clouds={clouds}
        formState={formState}
        onChange={onChange}
        title="Edit Environment"
      />
    );
  }
}

export default EnvironmentsEditForm;
