import { Box, Grid } from '@material-ui/core';
import { Skeleton as MuiSkeleton, SkeletonProps } from '@material-ui/lab';

import { useStyles as useGlobalStyles } from 'helpers/globalStyles';

const Skeleton = (props: SkeletonProps) => (
  <MuiSkeleton
    {...props}
    style={{
      transform: 'none'
    }}
  />
);

export function LoadingSkeleton() {
  const globalClasses = useGlobalStyles();

  return (
    <Box display="flex" flexDirection="column" className={globalClasses.pageContainer}>
      <Box display="flex">
        <Skeleton height={60} width="100%" />
      </Box>
      <Box display="flex" mt={4}>
        <Skeleton height={60} width="100%" />
      </Box>

      <Box display="flex" mt={4}>
        <Skeleton height={80} width="100%" />
      </Box>

      <Box display="flex" mb={2} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Skeleton height={130} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton height={130} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton height={130} />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Skeleton height={130} />
          </Grid>
          <Grid item xs={5}>
            <Skeleton height={130} />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" mb={2}>
        <Skeleton height="100%" width="100%" />
      </Box>
    </Box>
  );
}
