import React from 'react';

import Breadcrumb from 'oldComponents/Breadcrumb';
import { connect } from 'react-redux';

import actions from 'redux/actions';
import reducer from 'redux/reducer/environment';

import TabPanels from './TabPanels';
import Toolbar from './Toolbar';

class EnvironmentView extends React.Component {
  componentDidMount() {
    this.props.fetch(this.props.match.params.id);
  }

  render() {
    const { environment_view: environment, changeTab } = this.props;
    environment.id = this.props.match.params.id;

    return (
      <>
        <Breadcrumb
          items={[{ link: '/environments', label: 'Environments' }, { label: environment.name }]}
        />
        <Toolbar entity="environments" environment={environment} />
        <TabPanels changeTab={changeTab} environment={environment} />
      </>
    );
  }
}

export default connect(
  ({ environment_view }) => ({ environment_view }),
  dispatch => ({
    fetch: payload =>
      dispatch({
        type: actions.ENVIRONMENT_FETCH,
        payload
      }),
    changeTab: payload =>
      dispatch({
        type: actions.ENVIRONMENT_CHANGE_TAB,
        payload,
        meta: { reducer }
      })
  })
)(EnvironmentView);
