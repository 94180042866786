import { useQuery } from 'urql';

const GetMetabaseTokens = `#graphql
  query {
    metabaseTokens {
      dashboard
      token
      expires
    }
  }
`;

type MetabaseTokens = {
  dashboard: string;
  token: string;
  expires: number;
}[];

export function useMetabaseTokens(): {
  data: MetabaseTokens;
  fetching: boolean;
  refetch: any;
} {
  const query = GetMetabaseTokens;

  const [{ data, fetching }, refetch] = useQuery({
    query
  });

  return {
    data: data?.metabaseTokens,
    fetching,
    refetch
  };
}
