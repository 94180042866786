import { zodResolver } from '@hookform/resolvers/zod';
import { useForm as useReactHookForm } from 'react-hook-form';
import { z } from 'zod';

import schema from './schema';

const useForm = ({ defaultValues }: { defaultValues: any }) => {
  return useReactHookForm<z.infer<typeof schema>>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });
};

export default useForm;
