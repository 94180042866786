import { useMemo } from 'react';

import { useLocation } from 'react-router';

export type UseQueryParams = URLSearchParams & {
  /** 
    Clear the query params
  */
  clear(): void;
};

function useQueryParams() {
  const location = useLocation();

  return useMemo<UseQueryParams>(() => {
    const queryParams = new URLSearchParams(location.search) as UseQueryParams;

    queryParams.clear = () => {
      window.history.replaceState({}, '', location.pathname);
    };

    return queryParams;
  }, [location.pathname, location.search]);
}

export default useQueryParams;
