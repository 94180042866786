import { useFlagsStatus } from '@unleash/proxy-client-react';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ProductView from './V1';
import { ProductView as ProductViewV2 } from './V2';

const Product = props => {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  return newApplications ? <ProductViewV2 /> : <ProductView {...props} />;
};

export default Product;
