import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import Status from 'views/Products/View/Availability/List/Datagrid/checkAppAvailability/Status';

export const validateDependencyStatus = ({
  app,
  row
}: {
  app: {
    uid: string;
    dependencies: {
      entity: ApplicationManagerEntityType;
      entityUid: string;
      status: string;
      uptime: { online1Hour: number };
    }[];
  };
  row: {
    resourceUid: string;
    apps: {
      uid: string;
      status: string;
    }[];
  };
}) => {
  const response: any = {
    status: '',
    sla: 0
  };

  if (!row.resourceUid) {
    return response;
  }

  response.status = Status['DOES-NOT-DEPEND'];

  if (!row.apps) {
    return response;
  }

  const hasApplication = row.apps.find((application: any) => application.uid === app.uid);

  if (!hasApplication) {
    return response;
  }

  if (
    hasApplication?.status === ResourceStatusEnum.Off ||
    hasApplication?.status === ResourceStatusEnum.Pending
  ) {
    response.status = Status['MONITORING-IS-INACTIVE'];
    return response;
  }

  const dependency = app?.dependencies?.find(
    (dependency: any) => row.resourceUid === dependency.entityUid
  );

  if (!dependency) {
    return response;
  }

  response.status = Status['MONITORING-IS-INACTIVE'];

  const statusAddon = {
    status: dependency.status,
    online1Hour: dependency.uptime.online1Hour
  };

  if (
    statusAddon.status === ResourceStatusEnum.Off ||
    statusAddon.status === ResourceStatusEnum.Pending
  ) {
    return response;
  }

  if (dependency.entity === ApplicationManagerEntityType.Multiflow) {
    response.sla = statusAddon.online1Hour || 'No data';
    response.statusService = statusAddon.status;

    if (response?.statusService === MultiFlowStatusEnum.Error) {
      response.status = Status['UNAVAILABLE'];
      return response;
    }

    if (response?.statusService === MultiFlowStatusEnum.Success) {
      response.status = Status['AVAILABLE'];
      return response;
    }
  }

  response.statusService = statusAddon.status;
  response.sla = statusAddon.online1Hour;

  if (response?.statusService === ResourceStatusEnum.Maintenance) {
    response.status = Status['MAINTENANCE'];
    return response;
  }

  if (statusAddon.online1Hour >= 99.9) {
    response.status = Status['AVAILABLE'];
    return response;
  }

  if (statusAddon.online1Hour < 99.9 && statusAddon.online1Hour >= 98) {
    response.status = Status['DEGRADED'];
    return response;
  }

  response.status = Status['UNAVAILABLE'];
  return response;
};
