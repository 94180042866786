import { useState } from 'react';

import { Box, Switch, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import ConfirmationDialog from '../ConfirmationDialog';

type NotificationSwitchBaseProps = {
  title: string;
  checked: boolean;
  onEnable: () => void;
  onDisable: () => void;
  tooltipTitle: string;
  disabled: boolean;
  useProviderFeaturePlan: boolean | null;
  disableMessage: string;
  activeMessage: string;
};

const NotificationSwitchBase = ({
  title,
  checked,
  onEnable,
  onDisable,
  tooltipTitle,
  disabled,
  useProviderFeaturePlan,
  activeMessage,
  disableMessage
}: NotificationSwitchBaseProps) => {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleToggle = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <Box display="flex" alignItems="center">
        {!useProviderFeaturePlan && <Lock style={{ marginRight: theme.spacing() }} />}
        {useProviderFeaturePlan && (
          <Tooltip title={tooltipTitle}>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <Switch
                size="small"
                color="primary"
                checked={checked}
                onChange={handleToggle}
                disabled={disabled}
              />
            </span>
          </Tooltip>
        )}

        <Typography>{title}</Typography>
      </Box>
      <ConfirmationDialog
        open={openModal}
        checked={checked}
        onClose={handleCloseModal}
        onEnable={() => {
          handleCloseModal();
          onEnable();
        }}
        onDisable={() => {
          handleCloseModal();
          onDisable();
        }}
        activeMessage={activeMessage}
        disableMessage={disableMessage}
      />
    </>
  );
};

export default NotificationSwitchBase;
