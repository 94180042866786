import { apm } from '@elastic/apm-rum';
import { useQuery } from 'react-query';

import useUser from 'hooks/queriesRest/useUser';

import { checkFeature } from './checkFeature';
import { fetchPlanFeatures } from './fetchPlanFeatures';

const PLANS_FEATURES_CACHE_TIME_IN_MINUTES = 15;
const PLANS_FEATURES_STALE_TIME_IN_MINUTES = 10;

export const planFeaturesQueryKey = 'featurePlans';

/**
 * # Enable feature by plan.
 *
 * The `feature` param can be a feature name or a object path to a feature. Like this:
 * `Feature.Subfeature`
 *
 * Or:
 * `Feature`
 *
 * - You can configure the `placeholder` value, it is useful when you want return true or false, while fetching plans features.
 * - If you want know if data is loading, check if returns === null.
 */
export function useFeaturePlan(feature: string, config?: { placeholder: boolean }) {
  const userData = useUser({});

  const planName = userData.data?.logged?.org?.planName;

  const { data, isLoading, isFetched } = useQuery(
    planFeaturesQueryKey,
    fetchPlanFeatures(planName),
    {
      cacheTime: 1000 * 60 * PLANS_FEATURES_CACHE_TIME_IN_MINUTES,
      staleTime: 1000 * 60 * PLANS_FEATURES_STALE_TIME_IN_MINUTES,
      refetchOnWindowFocus: false,
      enabled: Boolean(planName),
      onError: (error: Error) => {
        apm.captureError(error);
      }
    }
  );

  if (isLoading || !isFetched) return config?.placeholder ?? null;

  return checkFeature({ feature, features: data });
}
