import useGetTeamMembers from 'views/Teams/hooks/useGetTeamMembers';

import { getMsTeamMembersQuery } from '../query';
import { extractNumbers } from '../utils';

const UseTeamsFromMsTeam = (teamId: string) => {
  const getTeamMembers = getMsTeamMembersQuery;
  const [resultQuery] = useGetTeamMembers({
    getTeamMembers,
    teamId: Number(teamId),
    pause: false
  });

  const graphQlNumberOptions =
    resultQuery.data && resultQuery.data.getTeamMembersV2
      ? extractNumbers(resultQuery.data.getTeamMembersV2)
      : [];

  return {
    membersNumbers: graphQlNumberOptions
  };
};

export default UseTeamsFromMsTeam;
