import {
  Typography,
  Box,
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '400px',
    maxWidth: '100%'
  }
}));

const EditDependencies = ({ dependencies, title }) => {
  const classes = useStyles();

  if (dependencies && dependencies.length) {
    return (
      <Box style={{ paddingBottom: '0px' }}>
        <List className={classes.root} component="nav" aria-label="addons-being-monitored">
          {dependencies?.some(dependency => dependency?.items.length > 0) ? (
            <Typography variant="h5">{title}</Typography>
          ) : null}

          {dependencies.map(dependency => {
            return (
              <>
                {dependency.items.map(item => {
                  return (
                    <ListItem key={item.id}>
                      <ListItemIcon>
                        <ArrowRightIcon />
                      </ListItemIcon>
                      <ListItemText primary={`${dependency.type}: ${item.name}`} />
                    </ListItem>
                  );
                })}
              </>
            );
          })}
        </List>
      </Box>
    );
  }
};

export default EditDependencies;
