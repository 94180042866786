import { makeStyles } from '@material-ui/core';

import palette from 'theme/palette';
import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  root: {
    '& > div': {
      width: '100% !important',
      height: '40px'
    }
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 400
  },

  containerNoWidth: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  selectItem: {
    height: 48,
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '& p': {
      color: theme.palette.text.primary,
      fontSize: 16
    }
  },
  autocomplete: {
    width: '100%'
  },
  selectItemsInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    paddingRight: 16,

    '& p:nth-child(2)': {
      fontSize: 14,
      color: theme.palette.grey[600]
    }
  },

  input: {
    fontSize: 16,
    borderRadius: 4,
    '& $notchedOutline, &$inputFocused $notchedOutline': {
      transition: 'all 0.3s linear 0s'
    },
    '&:hover $notchedOutline, &$inputFocused $notchedOutline': {
      border: `1px solid ${palette.green[400]}`,
      boxShadow: `0px 0px 4px 0px ${palette.green[400]}`
    }
  },
  paper: {
    width: '100%',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    '& ul': {
      padding: 0,

      overflowY: 'auto',
      scrollbarWidth: 'auto',
      scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.grey[100]}`,
      '&::-webkit-scrollbar': {
        width: theme.spacing(1)
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[100]
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[300],
        borderRadius: theme.spacing(2)
      }
    }
  },
  option: {
    margin: 0,
    padding: 0
  },
  chip: {
    backgroundColor: palette.green[50],
    borderRadius: 4,
    border: 'none',
    color: palette.text.primary
  },

  inputFocused: {},
  notchedOutline: {}
}));
