import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  responderText: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '26px'
  },
  loopButton: {
    minWidth: theme.spacing(5)
  }
}));
