import { Grid, Link } from '@material-ui/core';
import Alert from '@material-ui/lab-old/Alert';
import CheckBox from 'oldComponents/ResourceInputs/CheckBox';

export const FunctionsForm = ({ errors, register, startValues, disabled = false }) => {
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item md={3} sm={6}>
          <CheckBox
            inputRef={register}
            errors={errors}
            disabled={disabled}
            name="search_period"
            tooltipText="If checked, we're going to generate a failure when there isn't any invocations in the configured interval"
            label="Check not invoke on interval"
            defaultChecked={startValues?.search_period}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Alert severity="warning">
            We use GCP Logging for searching by Google Cloud logs
            <Link href="https://cloud.google.com/logging#section-7" target="_blank">
              Click here to read more about it's pricing.
            </Link>
          </Alert>
        </Grid>
      </Grid>
    </Grid>
  );
};
