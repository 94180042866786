import { SeverityStatusEnum } from 'types/external/Severity';

export const severityOptions = [
  {
    value: SeverityStatusEnum.Critical,
    label: 'SEV-1 - Critical'
  },
  {
    value: SeverityStatusEnum.High,
    label: 'SEV-2 - High'
  },
  {
    value: SeverityStatusEnum.Moderate,
    label: 'SEV-3 - Moderate'
  },
  {
    value: SeverityStatusEnum.Low,
    label: 'SEV-4 - Low'
  },
  {
    value: SeverityStatusEnum.Informational,
    label: 'SEV-5 - Informational'
  },
  {
    value: SeverityStatusEnum.NotClassified,
    label: 'Not Classified'
  }
];
