import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

function IncidentsMetricsPerHour() {
  const dashboardName = MetabaseDashboards.IncidentsByOrgPerHour;

  const hideParams = ['source'];

  return (
    <MetabaseDashboard
      title="Organization incidents metrics by hour"
      subtitle="List of resources with their incidents number grouped by hour"
      dashboardName={dashboardName}
      hideParams={hideParams}
    />
  );
}

export default IncidentsMetricsPerHour;
