import { Theme } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';

import { MuiButton } from './MuiButton';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiMenuItem } from './MuiMenuItem';
import { MuiOutlinedInput } from './MuiOutlinedInput';

export type OverrideFn<component extends keyof Overrides> = (theme: Theme) => Overrides[component];

export function getOverrides(theme: Theme): Theme['overrides'] {
  return {
    MuiMenuItem: MuiMenuItem(theme),
    MuiButton: MuiButton(theme),
    MuiInputLabel: MuiInputLabel(theme),
    MuiOutlinedInput: MuiOutlinedInput(theme)
  };
}
