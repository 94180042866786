import cloudProviders from 'constants/cloudProviders';

const provider = [
  {
    value: '',
    label: 'Select Cloud Provider'
  },
  {
    value: cloudProviders.aws,
    label: 'AWS'
  },
  {
    value: cloudProviders.azure,
    label: 'AZURE'
  },
  {
    value: cloudProviders.custom,
    label: 'Custom'
  }
];

export default provider;
