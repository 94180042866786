import { MonitoringStatus, MonitoringStatusDictionary } from 'constants/MonitoringStatusV1';

import MonitoringStatusBase from './MonitoringStatusBase';

const mapMonitoringStatus = (monitoringStatus: MonitoringStatus) => {
  if (monitoringStatus === MonitoringStatus.Maintenance) {
    return MonitoringStatus.Maintenance;
  }

  return MonitoringStatus.Off;
};

const MonitoringStatusV1 = ({
  monitoringStatus,
  iconOnly
}: {
  monitoringStatus: MonitoringStatus;
  iconOnly?: boolean;
}) => {
  // TODO: consider the healthStatus
  // const healthStatusString = monitoringStatusQuery.data?.service.healthStatus;

  const mappedMonitoringStatus = mapMonitoringStatus(monitoringStatus);
  // TODO: consider the healthStatus
  // const healthStatus = HealthStatus[healthStatusString];

  const monitoringStatusProps = MonitoringStatusDictionary[mappedMonitoringStatus];

  // TODO: consider the healthStatus
  // const healthStatusProps = HealthStatusDictionary[healthStatus];

  return (
    <MonitoringStatusBase
      iconOnly={iconOnly}
      {...monitoringStatusProps}
      // TODO: consider the healthStatus
      // {...(monitoringStatus === MonitoringStatus.On ? monitoringStatusProps : healthStatusProps)}
    />
  );
};

export default MonitoringStatusV1;
