import { useForm as useReactHookForm } from 'react-hook-form';

function useForm({ defaultValues }) {
  const useFormMethods = useReactHookForm({
    defaultValues
  });

  return useFormMethods;
}

export default useForm;
