import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { OracleFormData } from 'views/ServicesHub/forms/monitoring/database/Oracle';

import { monitoringAdapter } from '../monitoring';

export function oracleAdapter(data: MonitoringBaseForm & OracleFormData) {
  const role = data.role === 'NORMAL' ? '' : ` as ${data.role}`;

  const domain = `${data.username}/${data.password}@${data.host}:${data.port}/${data.database}${role}`;
  // ex: USER/PASSWORD@HOST:PORT/DATABASE/ as SYSDBA

  return {
    ...monitoringAdapter({
      ...data,
      method: 'oracle'
    }),
    domain
  };
}
