import { ChangeEvent } from 'react';

import { UseFormMethods } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';
import { OperationResult } from 'urql';

import actions from 'redux/actions';

import { GoogleWorkspaceIntegrationForm } from './GoogleWorkspaceIntegrationForm';
import { MicrosoftWorkspaceIntegrationForm } from './MicrosoftWorkspaceIntegrationForm';
import {
  GoogleWorkspaceIntegrationFormType,
  MicrosoftWorkspaceIntegrationFormType
} from './schemas';

export function handleSelectFile(formFieldName: string, setValue: UseFormMethods['setValue']) {
  return (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onload = e => {
      const fileContent = (() => {
        const readResult = String(e.target?.result).trim();

        if (file.type === 'application/x-x509-ca-cert') {
          return readResult
            .replaceAll(/\r\n/g, '\n') // Replace CLRF to LF
            .replaceAll('\n', '\\n');
        }

        return readResult;
      })();

      setValue(formFieldName, fileContent, { shouldValidate: true, shouldDirty: true });
    };
    reader.readAsText(file);
  };
}

type FormType = GoogleWorkspaceIntegrationFormType | MicrosoftWorkspaceIntegrationFormType;

type SSOIntegrationFormProps = {
  onSubmit: () => void;
  onCancel: () => void;
  type: SSOIntegration['type'];
};

export function SSOIntegrationForm({ onCancel, onSubmit, type }: SSOIntegrationFormProps) {
  const dispatch = useDispatch();

  const handleCreateSSOIntegration = (
    createSSOIntegration: (args: any) => Promise<OperationResult<any, object>>
  ) => async (data: FormType) => {
    const response = await createSSOIntegration({
      data: {
        ...data,
        type
      }
    });

    if (response.error?.message) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error(response.error.message.replace('[GraphQL] ', '')),
        ga: { category: 'ERROR' }
      });
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Integration created.'
    });

    onSubmit();
  };

  if (type === 'google')
    return (
      <GoogleWorkspaceIntegrationForm
        onCancel={onCancel}
        handleCreateSSOIntegration={handleCreateSSOIntegration}
      />
    );

  if (type === 'microsoft')
    return (
      <MicrosoftWorkspaceIntegrationForm
        onCancel={onCancel}
        handleCreateSSOIntegration={handleCreateSSOIntegration}
      />
    );

  return null;
}
