import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/services/fetch';

function* prepareFetchEntities(axios, action) {
  try {
    const service = { values: {} };
    if (action.payload) {
      const {
        data: { data }
      } = yield axios.get(`/services/${action.payload}`);
      service.values = data;
    }
    const {
      data: { data: clouds }
    } = yield axios.get('/clouds');

    const { post_body, post_body_type } = service.values;

    yield put({
      type: actions.FETCH_SERVICE_SUCCESSFUL,
      meta: { reducer },
      payload: {
        service: {
          ...service.values,
          postBodyType:
            post_body_type === postBodyRawTypeValues.json ? postBodyTypeValues.raw : post_body_type,
          postBodyRaw: post_body_type === postBodyRawTypeValues.json ? post_body : '',
          postBodyXFormUrlEncoded: post_body_type === postBodyTypeValues.urlencoded ? post_body : ''
        },
        clouds
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchServiceFetch(axios) {
  yield takeLatest(actions.FETCH_SERVICE, prepareFetchEntities, axios);
}
