import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type SqlserverFormData = {
  user: string;
  password: string;
  host: string;
  database: string;
};

export function SqlserverForm({ form, domainType }: FormProps) {
  const { control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {domainType !== 'url' && (
        <>
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'User',
                name: 'user',
                required: true
              }}
              InputComponent={Input}
            />
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'password',
                label: 'Password',
                name: 'password'
              }}
              InputComponent={Input}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Host',
                name: 'host'
              }}
              InputComponent={Input}
            />

            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Database',
                name: 'database'
              }}
              InputComponent={Input}
            />
          </Box>
        </>
      )}
    </Box>
  );
}
