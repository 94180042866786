const instanceTypesAWS = [
  {
    value: 't3a.nano',
    label: '(t3a.nano) CPU: 2, MEM: 0,5'
  },
  {
    value: 't3a.micro',
    label: '(t3a.micro) CPU: 2, MEM: 1'
  },
  {
    value: 't3a.small',
    label: '(t3a.small) CPU: 2, MEM: 2'
  }
];

export default instanceTypesAWS;
