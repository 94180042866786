import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { useStyles } from './styles';

function IntegrationEditTable({
  onDelete,
  data
}: {
  onDelete: (uid: string) => void;
  data: {
    id: number;
    uid: string;
    name: string;
    productName: string;
  }[];
}) {
  const classes = useStyles();

  return (
    <Table>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>Application</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(data => (
          <TableRow>
            <TableCell>{data.productName}</TableCell>
            <TableCell>{data.name}</TableCell>
            <TableCell>
              <IconButton title="Delete association" onClick={() => onDelete(data.uid)}>
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default IntegrationEditTable;
