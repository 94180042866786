import { useEffect } from 'react';

import { useQuery } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

const getFrequenciesMetrics = `#graphql
  query(
    $applicationId: Float!
    $periods: [String!]
  ) {
    frequenciesMetrics(
      applicationId: $applicationId
      periods: $periods
    ) {
      applicationId
      orgUid
      metrics{
        type
        changeFailure
        deployFrequency
      }   
    }
  }
`;

const useFrequenciesMetrics = ({
  applicationId,
  periods
}: {
  applicationId: number;
  periods: string[];
}) => {
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const [{ fetching: fetchingFrequenciesMetrics, data }, reexecuteQuery] = useQuery({
    query: getFrequenciesMetrics,
    pause: !useFrequenciesMetrics || !applicationId,
    variables: { applicationId, periods }
  });

  const metrics = data?.frequenciesMetrics ?? null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (fetchingFrequenciesMetrics) return;

      reexecuteQuery();
    }, 6000 * 10 /* ms = 10 seconds */);

    return () => clearInterval(intervalId);
  }, [fetchingFrequenciesMetrics, reexecuteQuery]);

  const metric = metrics?.metrics?.find((metric: { type: string }) => {
    if (canFetchDataFromTheLast30Days) {
      return metric.type === '30 days';
    }

    return metric.type === '1 day';
  });

  return metric;
};

export default useFrequenciesMetrics;
