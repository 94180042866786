import { Token } from 'types/external/Token';
import { useQuery } from 'urql';

const GetApiTokensQuery = `#graphql
    query(
        $from: Int!,
        $size: Int!,
        $query: String!
    ) {
    tokens(
        from: $from,
        size: $size,
        query: $query
    ) {
        data {
            id
            uid
            name
            type
            userUid
            kongApiKeyId
            consumer {
              id
              kongConsumerId
            }
            createdAt
            updatedAt
        }
        total
      }
    }
`;

type Variables = {
  from?: number;
  size?: number;
  query?: string;
};

function useApiTokens({
  from,
  size,
  query,
  pause
}: {
  from?: number;
  size?: number;
  query?: string;
  pause: boolean;
}) {
  return useQuery<Token, Variables>({
    query: GetApiTokensQuery,
    pause,
    variables: {
      from,
      size,
      query
    }
  });
}

export default useApiTokens;
