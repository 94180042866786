import React from 'react';

import {
  Box,
  Typography,
  Checkbox,
  Tooltip,
  IconButton,
  Fade,
  CircularProgress
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';

import curlText from './curlText';

const useStyles = makeStyles(theme => ({
  copyButton: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0)
  }
}));

const WebHookCurl = ({
  values,
  deployType,
  setDeployType,
  setValues,
  data,
  application,
  token,
  isLoadingApiKey
}) => {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = React.useState(false);
  const handleChangeCheckBox = event => {
    setDeployType(event.target.name);
    curlText(setValues, data, application, event.target.name);
  };

  React.useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 300);
    }
  }, [copySuccess]);

  return (
    <div>
      <>
        <Typography variant="h4" style={{ marginTop: 16, marginBottom: 16 }}>
          Deploy Type
        </Typography>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Checkbox
            color="default"
            name={'deploy'}
            checked={deployType === 'deploy'}
            onChange={handleChangeCheckBox}
          />

          <Typography variant="body1">Deploy</Typography>

          <Checkbox
            color="default"
            name={'rollback'}
            checked={deployType === 'rollback'}
            onChange={handleChangeCheckBox}
          />

          <Typography variant="body1">Rollback</Typography>

          <Checkbox
            color="default"
            name={'hotfix'}
            checked={deployType === 'hotfix'}
            onChange={handleChangeCheckBox}
          />

          <Typography variant="body1">Hot Fix</Typography>

          <Box id={`BoxCopyAppToken`} style={{ marginLeft: 'auto' }}>
            <Fade id={`fadeCopyAppToken`} in={copySuccess} timeout={{ enter: 500, exit: 2000 }}>
              <small style={{ color: 'green' }}>Copied</small>
            </Fade>
            <Tooltip title="Copy to clipboard" aria-label="add">
              <IconButton
                id={`copyButtonAppToken`}
                disabled={!token}
                color="primary"
                className={classes.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(values.curl);
                  setCopySuccess(true);
                }}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </>

      {isLoadingApiKey ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <pre
          id={`preAppToken`}
          style={{
            cols: 100,
            border: '1px solid rgba(29,28,29,.04)',
            borderRadius: 4,
            background: 'rgba(29, 28, 29, 0.04)',
            whiteSpace: 'pre-wrap',
            textOverflow: 'clip',
            padding: 8
          }}
          key={deployType}
          onClick={event => {
            const range = document.createRange();
            range.selectNodeContents(event.target);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          }}>
          {values.curl}
        </pre>
      )}
    </div>
  );
};

export default WebHookCurl;
