import React from 'react';

import applicationType from 'constants/ApplicationType';

import MonitoringFormEdit from '../Form/FullForm/MonitoringFormEdit';
import MultiHttpFormEdit from '../Form/MultiHttpForm/MultiHttpFormEdit';
import WebHookFormEdit from '../Form/WebHookForm/WebHookFormEdit';

import ResourceDialog from './ResourceDialog';

const getFormByApplicationType = type => {
  if (type === applicationType.Internal.type) {
    return MonitoringFormEdit;
  }

  if (type === applicationType.ExternalWithAddons.type) {
    return MonitoringFormEdit;
  }
  if (type === applicationType.ExternalWithoutAddons.type) {
    return MonitoringFormEdit;
  }

  if (type === applicationType.Webhook.type) {
    return WebHookFormEdit;
  }

  if (type === applicationType.InternalMultiHttp.type) {
    return MultiHttpFormEdit;
  }

  return MonitoringFormEdit;
};

const ResourceEditDialog = ({
  openerComponent,
  applicationName,
  applicationType,
  applicationId,
  productId,
  teamId,
  application,
  onUpdateAddon,
  reexecuteQueryUniqueResourceStatus,
  addonHttpFlow,
  ...otherProps
}) => {
  const Form = getFormByApplicationType(applicationType);

  const FormComponent = React.useCallback(
    props => (
      <Form
        resourceType={applicationType}
        applicationId={applicationId}
        productId={productId}
        teamId={teamId}
        {...props}
      />
    ),

    [applicationType, applicationId, productId, teamId]
  );

  return (
    <ResourceDialog
      openerComponent={openerComponent}
      title={`Edit ${applicationName}`}
      onUpdateAddon={onUpdateAddon}
      application={application}
      reexecuteQueryUniqueResourceStatus={reexecuteQueryUniqueResourceStatus}
      formComponent={FormComponent}
      addonHttpFlow={addonHttpFlow}
      {...otherProps}
    />
  );
};

export default ResourceEditDialog;
