import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStylesMaterial = makeStyles((theme: Theme) => ({
  filter: {
    minWidth: 50,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  }
}));
