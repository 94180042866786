import { ComponentProps } from 'react';

import { Backdrop, Box, CircularProgress, Modal, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  backDropContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: 'rgb(255 255 255 / 66%)',
    alignItems: 'center',
    zIndex: 50
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    marginTop: spacing(3)
  }
}));

type LoadingOverlayProps = {
  text?: string;
  size?: number | string;
  thickness?: number;
  color?: ComponentProps<typeof CircularProgress>['color'];
  /**
   * if true, the backdrop will be positioned relative to the parent.
   *
   * if false, this component will be use a portal to render
   *
   * @default true
   */
  isRelative?: boolean;
  /**
   * if true, the loading will be rendered as a block element.
   * @default false
   */
  isBlock?: boolean;
};

function LoadingOverlay({
  text = '',
  color = 'primary',
  size = 90,
  thickness = 3,
  isRelative = true,
  isBlock = false
}: LoadingOverlayProps) {
  const classes = useStyles();

  const content = (
    <>
      <CircularProgress color={color} size={size} thickness={thickness} />
      {text && (
        <Typography variant="h3" className={classes.text}>
          {text}
        </Typography>
      )}
    </>
  );

  if (isBlock) {
    return (
      <Box display="flex" justifyContent="center">
        {content}
      </Box>
    );
  }

  if (isRelative) {
    return (
      <Backdrop open={true} className={classes.backDropContainer}>
        {content}
      </Backdrop>
    );
  }

  return (
    <Modal open={true}>
      <Box className={classes.container}>{content}</Box>
    </Modal>
  );
}

export default LoadingOverlay;
