import { useState, useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import useQueryParams from 'helpers/useQueryParams';
import actions from 'redux/actions';

import { Incident, IncidentNewFields } from './types';

const ResolveIncidentMutation = `#graphql
  mutation(
    $incidentId: Int!,
  ) {
    resolvedIncident(incidentId: $incidentId) {
      ok
      message
    }
  }
`;

type ResolveIncidentMutationVariables = {
  incidentId: number;
};

const useResolveIncident = ({
  incident,
  isResolved,
  onResolve
}: {
  incident: Incident & Partial<IncidentNewFields>;
  isResolved: boolean;
  onResolve: () => void;
}) => {
  const [resolve, setResolve] = useState(useQueryParams().get('resolve') === 'true');

  const [showResolveDialog, setShowResolveDialog] = useState(false);

  const handleOpenResolveDialog = () => {
    setShowResolveDialog(true);
  };

  const handleClose = () => {
    setShowResolveDialog(false);
  };

  const [resolveIncidentMutationResult, resolveIncidentMutation] = useMutation<
    {
      resolvedIncident: { ok: boolean; message: string };
    },
    ResolveIncidentMutationVariables
  >(ResolveIncidentMutation);

  const dispatch = useDispatch();

  const isFetching = resolveIncidentMutationResult.fetching;

  const canResolve = !(isResolved || isFetching);

  const handleResolve = useCallback(() => {
    if (!canResolve) {
      return;
    }

    handleClose();

    resolveIncidentMutation({
      incidentId: incident.incidentId
    })
      .then(result => {
        if (result.data?.resolvedIncident?.ok) {
          onResolve();

          return dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Marked as resolved successfully'
          });
        }

        throw new Error(
          result.data?.resolvedIncident?.message || "Couldn't mark incident as resolved :("
        );
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  }, [canResolve, dispatch, incident.incidentId, onResolve, resolveIncidentMutation]);

  useEffect(() => {
    if (resolve && canResolve) {
      handleOpenResolveDialog();
      setResolve(false);
    }
  }, [canResolve, resolve]);

  return {
    handleResolve,
    isFetching,
    canResolve,
    showResolveDialog,
    handleOpenResolveDialog,
    handleClose
  };
};

export default useResolveIncident;
