import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: theme.spacing(5.5)
  },
  autocomplete: {
    minHeight: theme.spacing(5.5),
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.gray[200],
      borderStyle: 'solid'
    }
  },
  buttons: {
    minWidth: 115
  },
  customRangeButton: {
    minWidth: 170,
    whiteSpace: 'nowrap'
  },
  badgeContent: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 16,
    height: 16
  }
}));
