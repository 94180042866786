import { useState, useRef, KeyboardEvent } from 'react';

import {
  Box,
  Typography,
  Divider,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ServiceNowIcon from './icons/ServiceNow';
import { useStyles } from './style';

const SelectIntegration = () => {
  const [show, setShow] = useState(false);

  const anchorRef = useRef(null);

  const classes = useStyles();

  const dropDown = () => {
    setShow(prev => !prev);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setShow(false);
    }
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    //@ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setShow(false);
  };

  return (
    <div>
      <Box className={classes.container}>
        <Button
          ref={anchorRef}
          aria-controls={show ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          className={classes.openBtn}
          onClick={dropDown}>
          <Typography className={classes.titleButton}>open Ticket</Typography>
          <Divider className={classes.line} orientation="vertical" />
          <ArrowDropDownIcon />
        </Button>

        <Popper
          className={classes.drop}
          open={show}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={show} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <Box className={classes.listItem}>
                      <Typography className={classes.title}>CREATE AN ISSUE ON</Typography>
                    </Box>
                    <MenuItem className={classes.listItem} onClick={handleClose}>
                      <ServiceNowIcon />
                      <Typography className={classes.listItemTypography}>Servicenow</Typography>
                    </MenuItem>
                    <MenuItem className={classes.listItem} onClick={handleClose}>
                      <ServiceNowIcon />
                      <Typography className={classes.listItemTypography}>Jira</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </div>
  );
};

export default SelectIntegration;
