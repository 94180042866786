const time = new Date(new Date().toDateString());

export const defaultValues = [
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 0,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 1,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 2,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 3,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 4,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 5,
    enabled: false,
    start: time,
    finish: time
  },
  {
    id: 0,
    scheduledEventId: 0,
    dayOfWeek: 6,
    enabled: false,
    start: time,
    finish: time
  }
];
