import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import useGetVnets from './useGetVnets';

const SubnetSelect = ({ formState, onChange }) => {
  const { cloudId, resourceGroup, vnetName } = formState.values;

  const { data: vnets = [], isSuccess, isLoading, isError } = useGetVnets({
    cloudId,
    resourceGroup
  });

  const subnets = vnetName ? vnets.find(vnet => vnet.name === vnetName)?.subnets : [];

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const options = () => {
    if (isLoading) {
      const pendingText = 'loading...';
      return (
        <MenuItem title={pendingText} value="1">
          <em>{pendingText}</em>
        </MenuItem>
      );
    }

    if (isSuccess && subnets && subnets.length) {
      return subnets.map(({ name: subnetName }) => {
        return (
          <MenuItem title={subnetName} key={`subnet_${subnetName}`} value={subnetName}>
            {subnetName}
          </MenuItem>
        );
      });
    }

    if (isError) {
      const errorText = "Couldn't load Virtual Networks";
      return (
        <MenuItem title={errorText} value="1">
          <em>{errorText}</em>
        </MenuItem>
      );
    }

    return null;
  };

  return (
    <TextField
      fullWidth
      select
      InputLabelProps={{
        shrink: true
      }}
      label="Subnet"
      margin="dense"
      name="subnetName"
      onChange={handleChange}
      required
      value={!isSuccess ? '1' : formState.values.subnetName || '1'}
      variant="outlined"
      error={hasError('subnetName')}
      helperText={hasError('subnetName') ? formState.errors.subnetName[0] : null}>
      {options()}
    </TextField>
  );
};

export default SubnetSelect;
