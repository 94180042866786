import { DynamodbFormData } from 'views/ServicesHub/forms/monitoring/database/Dynamodb';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function dynamodbAdapter(data: MonitoringCommomData & DynamodbFormData) {
  const primaryKeyAndValueItem =
    data.primarykey && data.valueItem ? `:${data.primarykey}:${data.valueItem}` : '';

  const domain = `${data.accessKey}:${data.secretAccessKey}/${data.awsRegion}@${data.tableName}${primaryKeyAndValueItem}`;
  // ex1: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TABLE_NAME
  // ex2: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TABLE_NAME:PRIMARY_KEY:VALUE_ITEM

  return {
    ...monitoringAdapter({ ...data, method: 'dynamodb' }),
    domain
  };
}
