import React from 'react';

import { colors, Button, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'redux/actions';
import uploadReducer from 'redux/reducer/upload';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  spacer: {
    flexGrow: 1
  },
  avatar: {
    display: 'flex'
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10
  },
  paper: {
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
    boxShadow: 'none',
    fontSize: 'large',
    fontWeight: 'bold'
  },
  excluir: {
    backgroundColor: colors.red[700],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    margin: 10
  },
  progress: {
    margin: 10
  }
}));

const cover_url = product => {
  if (product.cover) {
    return product.cover;
  } else {
    return `${process.env.PUBLIC_URL}/images/not_product_2.png`;
  }
};

const ProductToolbar = props => {
  const { className, product } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const upload = useSelector(state => state.upload);

  const saveHandler = (file, contentType, name) => {
    dispatch({
      type: actions.PRODUCT_UPLOAD_AVATAR,
      meta: { reducer: uploadReducer },
      payload: { file, contentType, name, id: product.id }
    });
  };

  const onFileLoad = (e, file) => {
    saveHandler(e.target.result, file.type, file.name);
  };

  const onInputChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => onFileLoad(e, file);
    reader.readAsDataURL(file);
  };

  const handleUpload = () => {
    const input = document.querySelector('#avatarAccount');
    input.click();
  };

  const progress = upload.status ? <CircularProgress className={classes.progress} /> : null;

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.avatar}>
          <img alt={product.name} className={classes.cover} src={cover_url(product)} />
          <input
            accept="image/*"
            id="avatarAccount"
            onChange={onInputChange}
            style={{ display: 'none' }}
            type="file"
          />
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={upload.status}
            onClick={handleUpload}
            size="small"
            variant="text">
            Upload
          </Button>
          {progress}
        </div>

        <Typography gutterBottom variant="h4">
          {product.name}
        </Typography>
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

ProductToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductToolbar;
