import instanceTypesAWS from 'constants/instanceTypesAWS';
import regionsAws from 'constants/regionsAws';

import { Grid } from '@material-ui/core';

import SelectSimple from 'componentsV3/Select';

import SubnetSelectAWS from './SubnetSelectAWS';
import VpcSelect from './VpcSelect';

const AwsFields = ({
  watch,
  control,
  register,
  selectedCloudId,
  selectedSubnetId,
  region,
  vpcId,
  errors,
  warnings,
  setWarning,
  setValue,
  setError,
  clearWarnings,
  clearErrors
}) => {
  const selectRegion = watch('region');
  const selectVpc = watch('vpc');
  const selectSubnet = watch('subnet');

  return (
    <>
      <Grid item xs={6}>
        <SelectSimple
          errors={errors}
          name="region"
          control={control}
          label="Region"
          defaultValue=""
          items={regionsAws}
          required={true}
        />
      </Grid>

      <Grid item xs={6}>
        <VpcSelect
          disabled={!selectRegion}
          errors={errors}
          control={control}
          region={region}
          selectedCloudId={selectedCloudId}
          required={true}
        />
      </Grid>
      <Grid item xs={6}>
        <SubnetSelectAWS
          disabled={!selectVpc}
          control={control}
          region={region}
          selectedCloudId={selectedCloudId}
          selectedSubnetId={selectedSubnetId}
          vpcId={vpcId}
          errors={errors}
          warnings={warnings}
          setWarning={setWarning}
          setValue={setValue}
          setError={setError}
          clearWarnings={clearWarnings}
          clearErrors={clearErrors}
          required={true}
        />

        <input ref={register} type="hidden" name="associatePublicIpAddress" />
      </Grid>

      <Grid item xs={6}>
        <SelectSimple
          disabled={!selectSubnet}
          errors={errors}
          name="instanceType"
          control={control}
          required={true}
          label="Instance Type"
          defaultValue=""
          items={instanceTypesAWS}
          setValue={setValue}
          selectFirst
        />
      </Grid>
    </>
  );
};

export default AwsFields;
