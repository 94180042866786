import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  IconButton,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import { withThemeV4 } from 'theme/v4';

import useStyles from './styles';

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  bodyComponent: React.ReactNode;
}

interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography>
      <Box mt={5}>
        <CheckCircleOutlineIcon className={classes.icon} />
      </Box>

      <Box mt={3}>
        <Typography variant="h4">{props.children}</Typography>
      </Box>

      <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

const SuccessfulModal = ({ open, setOpen, title, bodyComponent }: ModalProps) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      className={classes.root}
      PaperProps={{ classes: { root: classes.paper } }}>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>

      <Box mb={5}>
        <DialogContent>
          <Typography variant="body2">{bodyComponent}</Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default withThemeV4(SuccessfulModal);
