import useQueryParams, { UseQueryParams } from 'helpers/useQueryParams';
import { IntegrationPlatforms } from 'hooks/queriesGraphQL/useLeadTimeChanges';

function getGithubInstallationType(queryParams: UseQueryParams) {
  const installationId = queryParams.get('installation_id');
  const setupAction = queryParams.get('setup_action');

  if (!installationId && !setupAction) return null;
  if (setupAction !== 'install') return null;

  return {
    installationId: Number(installationId)
  };
}
function getBitbucketInstallationType(queryParams: UseQueryParams) {
  const token = queryParams.get('jwt');

  if (!token) return null;

  return {
    token
  };
}
function getGitlabInstallationType(_queryParams: UseQueryParams) {
  return null;
}

const handlerDictionary = {
  github: getGithubInstallationType,
  bitbucket: getBitbucketInstallationType,
  gitlab: getGitlabInstallationType
};

export function useIntegrationData(type: IntegrationPlatforms) {
  const queryParams = useQueryParams();

  if (!(type in handlerDictionary)) return () => null;

  return () => handlerDictionary[type](queryParams);
}
