import { Box } from '@material-ui/core';
import SecretsField from 'oldComponents/SecretsField';

import healthCheckExempleHelper from 'helpers/getHealthCheckHelperText';

const HealthcheckField = ({
  control,
  register,
  setValue,
  watch,
  getValues,
  errors,
  selectedMethod,
  disabled,
  ...otherProps
}) => {
  const namesMap = {
    pubsub: 'Topic',
    functions: 'Function',
    functionsgen2: 'Function (2nd gen)'
  };

  const name = namesMap[selectedMethod] || 'Healthcheck URL';

  return (
    <Box display="flex" {...otherProps}>
      <SecretsField
        TextFieldProps={{
          label: name,
          name: 'domain'
          // required: true
        }}
        control={control}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
        errors={errors}
        defaultHelperText={healthCheckExempleHelper(selectedMethod)}
        disabled={disabled}
      />
    </Box>
  );
};

export default HealthcheckField;
