import actions from '../actions';

const logged = (state, action) => {
  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      ...action.payload
    };
  }
  return state;
};

export default logged;
