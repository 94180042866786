import { Box, Backdrop, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  }
}));

const RemoveConfirmation = ({ open, isLoading, onConfirm, onCancel, title, children }) => {
  const classes = useStyles();

  return (
    <OldDeleteDialog open={open} onClose={onCancel} title={title} fullWidth>
      <Box paddingBottom={3}>
        <Typography color="textSecondary">{children}</Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <ButtonSimple onClick={onCancel} variant="outlined" text="Cancel" color="primary" />

        <Box ml={2}>
          <ButtonSimple color="primary" variant="contained" onClick={onConfirm} text="Confirm" />
        </Box>
      </Box>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" size={90} thickness={5} />
      </Backdrop>
    </OldDeleteDialog>
  );
};

export default RemoveConfirmation;
