import { Resource } from 'types/external/Resource';

import { LambdaFormData } from 'views/ServicesHub/forms/monitoring/serveless/Lambda';

import { monitoringAdapter } from '../monitoring';

export function lambdaAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & LambdaFormData {
  const domain = data.domain;
  // ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@functionName

  if (data.domainSettings.domainType === 'url') {
    return {
      ...monitoringAdapter(data),
      functionName: '',
      notInvoke: data.domainSettings.searchPeriod,
      accessKey: '',
      secretAccessKey: '',
      awsRegion: '',
      domainType: data.domainSettings.domainType,
      domain: domain
    };
  }

  const lambdaDomainRegex = /(.+):(.+)\/(.+)@(.+)/;

  const match = domain.match(lambdaDomainRegex)!;

  const accessKey = match[1];
  const secretAccessKey = match[2];
  const awsRegion = match[3];
  const functionName = match[4];

  return {
    ...monitoringAdapter(data),
    functionName,
    notInvoke: data.domainSettings.searchPeriod,
    accessKey,
    secretAccessKey,
    awsRegion,
    domainType: data.domainSettings.domainType,
    domain: ''
  };
}
