/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';

import { useQuery } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const getIncident = `#graphql
  query( $incidentId: Float!) {
    incident(
      incidentId: $incidentId
    ) {
      title
      description
      closed
      applicationName
      applicationId
      applicationUid
      addonId
      environmentName
      postMortem {
        id
        uid
        postMortem
      }
      incidentId
      serviceId
      serviceName
      status
      acknowledged
      type
      createdAt
      updatedAt
      mttRecov
      mtta,
      origin
      severity
      externalIntegration{
        name
      },
      data {
        name
        source
        snapshot
      }
      warRoomProvider
      warRoomIntegrationId
    }
  }`;

const newGetIncidentQuery = `#graphql
  query( $incidentId: Float!) {
    incident(
      incidentId: $incidentId
    ) {
      description
      title
      applicationName
      closed
      applicationId
      applicationUid
      addonId
      environmentName
      postMortem {
        id
        uid
        postMortem
      }
      incidentId
      serviceId
      serviceName
      status
      acknowledged
      type
      createdAt
      updatedAt
      failureHappenedAt
      mttRecov
      mtta,
      origin
      severity
      externalIntegration{
        name
      },
      data {
        name
        source
        snapshot
      }
      warRoomProvider
      warRoomIntegrationId
    }
  }`;

function useIncident({ incidentId }: { incidentId: number }) {
  const shouldUseNewIncidentDate = useFeatureFlagUnleash('newIncidentDate');

  const [{ fetching: fetchingIncident, data }, reexecuteQuery] = useQuery({
    query: !shouldUseNewIncidentDate ? getIncident : newGetIncidentQuery,
    pause: !incidentId,
    variables: { incidentId }
  });

  const onReexecuteQuery = useCallback(() => {
    reexecuteQuery();
  }, [reexecuteQuery]);

  return [data, fetchingIncident, onReexecuteQuery];
}

export default useIncident;
