import SvgIcon from '@material-ui/core/SvgIcon';

export default function Deployed({ style = {}, ...otherProps }) {
  return (
    <SvgIcon viewBox="0 0 18 18" style={{ fontSize: 18, ...style }} {...otherProps}>
      <path
        d="M9.00781 4.5C10.9653 4.5 12.6753 5.895 13.0578 7.8225L13.2828 8.9475L14.4228 9.03C15.5928 9.1125 16.5078 10.0875 16.5078 11.25C16.5078 12.4875 15.4953 13.5 14.2578 13.5H4.50781C2.85031 13.5 1.50781 12.1575 1.50781 10.5C1.50781 8.9625 2.65531 7.68 4.17781 7.5225L4.98031 7.44L5.35531 6.7275C6.06031 5.355 7.46281 4.5 9.00781 4.5ZM9.00781 3C6.84031 3 4.95031 4.23 4.01281 6.03C1.76281 6.27 0.0078125 8.1825 0.0078125 10.5C0.0078125 12.9825 2.02531 15 4.50781 15H14.2578C16.3278 15 18.0078 13.32 18.0078 11.25C18.0078 9.27 16.4703 7.665 14.5278 7.53C14.0103 4.9425 11.7378 3 9.00781 3Z"
        fill="#546E7A"
      />
      <path
        d="M5.10858 11.1086L4.96716 11.25L5.10858 11.3914L5.76952 12.0524L5.91081 12.1937L6.05223 12.0525L8.33125 9.77754V14.25V14.45H8.53125H9.46875H9.66875V14.25V9.77877L11.9428 12.0569L12.0842 12.1986L12.2258 12.057L12.8914 11.3914L13.0328 11.25L12.8914 11.1086L9.14142 7.35858L9 7.21716L8.85858 7.35858L5.10858 11.1086Z"
        fill="#546E7A"
        stroke="#546E7A"
        stroke-width="0.4"
      />
    </SvgIcon>
  );
}
