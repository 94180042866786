import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDivider from './MuiDivider';
import MuiMenuItem from './MuiMenuItem';
import MuiPaper from './MuiPaper';
import MuiSelect from './MuiSelect';

const overrides = {
  MuiCssBaseline,
  MuiButton,
  MuiDivider,
  MuiMenuItem,
  MuiSelect,
  MuiPaper
};

export default overrides;
