import { ChangeEvent, useRef, useState } from 'react';

import { Avatar, Badge, Box, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { UseFormMethods } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Button } from 'components/Button';
import Dialog from 'components/Dialog';
import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import useUser from 'hooks/queriesRest/useUser';
import useProfile from 'hooks/useProfile';
import actions from 'redux/actions';
import { Input } from 'views/ServicesHub/components/Input';

import { useStyles } from './styles';

export type FormInputs = {
  timezone: string;
  name: string;
};

type Props = {
  form: UseFormMethods<FormInputs>;
  profile?: {
    displayName?: string;
    photoURL?: string;
  };
};

const FormProfile = ({ form, profile }: Props) => {
  const [isOpenAvatarDialog, setIsOpenAvatarDialog] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [avatarFileImage, setAvatarFileImage] = useState<File | null>(null);
  const [avatarBase64, setAvatarBase64] = useState<string | null>(null);

  const dispatch = useDispatch();

  const { updateUserProfilePhoto, updatingUserProfilePhoto } = useProfile();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { data: user } = useUser();

  const classes = useStyles({
    isDragging
  });

  const onFileLoad = (e: ProgressEvent<FileReader>, file: File) => {
    setAvatarBase64(e.target?.result as string);
    setAvatarFileImage(file);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDragging(false);
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = e => onFileLoad(e, file);
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onDragOver = () => {
    setIsDragging(true);
  };
  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onClose = () => {
    setAvatarBase64(null);
    setIsOpenAvatarDialog(false);
  };

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <Box display="flex" flexDirection="column" gridGap="1rem">
        <Box gridGap={16} mb={2} display="flex" alignItems="center" flexDirection="center">
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              badgeContent={
                <Box className={classes.smallBox}>
                  <EditOutlinedIcon />
                </Box>
              }>
              <Avatar
                onClick={() => setIsOpenAvatarDialog(true)}
                className={classes.avatar}
                alt={profile?.displayName}
                src={avatarBase64 || profile?.photoURL}
              />
            </Badge>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">{profile?.displayName}</Typography>
            <Typography className={classes.locationText} color="textPrimary" variant="body1">
              <strong>E-mail:</strong> {user?.email}
            </Typography>
          </Box>
        </Box>

        <Input
          label="Name"
          name="name"
          inputRef={form.register}
          required={true}
          defaultValue={profile?.displayName}
          // errors={errors}
        />
        {/* <Select
          defaultValue={momentTimezone.tz.guess()}
          options={momentTimezone.tz.names().map(tz => ({
            label: tz,
            value: tz
          }))}
          control={form.control}
          name="timezone"
          label="Timezone"
          required={true}
        /> */}
      </Box>
      <Box display="flex" gridGap="1rem">
        <Button startIcon={null} variant="contained" color="primary" type="submit" disabled={false}>
          SAVE
        </Button>
      </Box>
      <Dialog
        title="Upload photo"
        open={isOpenAvatarDialog}
        onClose={onClose}
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            disabled: updatingUserProfilePhoto,
            action: onClose
          },
          {
            label: 'Save',
            disabled: updatingUserProfilePhoto || avatarBase64 === null,
            action: () => {
              updateUserProfilePhoto({
                file: avatarFileImage
              })
                .then(result => {
                  if (result?.error) {
                    dispatch({
                      type: actions.ENTITY_ERROR,
                      payload: { message: getErrorMessageFromUrql(result?.error) }
                    });

                    return;
                  }

                  if (!result?.data?.updateUserProfilePhoto) {
                    dispatch({
                      type: actions.ENTITY_ERROR,
                      payload: {
                        message: 'Unexpected error when trying to deactivate'
                      }
                    });
                    return;
                  }

                  dispatch({
                    type: actions.GLOBAL_SUCCESS,
                    payload: 'Updated successfully'
                  });
                  setIsOpenAvatarDialog(false);
                })
                .catch(error => {
                  dispatch({
                    type: actions.ENTITY_ERROR,
                    payload: error,
                    ga: { category: 'ERROR' }
                  });
                });
            }
          }
        ]}>
        <input
          accept="image/*"
          id="avatarAccount"
          disabled={updatingUserProfilePhoto}
          onChange={onInputChange}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          ref={inputRef}
          className={classes['input-file']}
          type="file"
        />
        <Box className={classes.dragAndDropBox}>
          {!avatarBase64 ? (
            <>
              <Typography>Drag and drop your image here</Typography>
              <Typography>Or</Typography>
              <Button onClick={handleUpload} startIcon={null} variant="contained">
                Choose a file
              </Button>
            </>
          ) : (
            <img src={avatarBase64} alt="Image_uploaded" />
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default FormProfile;
