import { useQuery } from 'urql';

const useGetNotificationSettingsMatrix = ({
  query,
  teamId,
  pause
}: {
  query: string;
  teamId: number;
  pause: boolean;
}) => {
  return useQuery({
    query: query,
    pause: pause || !teamId,
    variables: { teamId }
  });
};

export default useGetNotificationSettingsMatrix;
