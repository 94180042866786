import React from 'react';

import DataGrid from '../DataGrid';

import Toolbar from './Toolbar';

const List = ({ data, pathname }) => {
  return (
    <div>
      <Toolbar entity="environments" environment={data} pathname={pathname} />
      <DataGrid data={data} />
    </div>
  );
};

export default List;
