import { useEffect } from 'react';

import {
  CreateItsmIntegration,
  CreateItsmIntegrationResponse,
  CreateItsmIntegrationVariables,
  UpdateItsmIntegration,
  UpdateItsmIntegrationResponse,
  UpdateItsmIntegrationVariables
} from 'graphqlQueries/createItsmIntegration';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import actions from 'redux/actions';
import { updateServiceNowAdapter } from 'views/ServicesHub/adapters/edit/itsm/serviceNow';
import { serviceNowAdapter } from 'views/ServicesHub/adapters/new/itsm/serviceNow';
import { LayoutType } from 'views/ServicesHub/forms';

import { BaseLayout } from '../components/BaseLayout';

import { useStartValues } from './useStartValues';

export type ServiceNowData = {
  uid: string;
  name: string;
  origin: string;
  openAutomatic: boolean;
  subdomain: string;
  username: string;
  password: string;
  clientId: string;
  clientSecret: string;
};

export function ItsmServiceNowLayout({ Form, isEdit }: LayoutType) {
  const { startValues, fetching: fetchingStartValues } = useStartValues();

  const form = useForm<ServiceNowData>({
    defaultValues: {
      uid: '',
      name: '',
      origin: '',
      openAutomatic: false,
      subdomain: '',
      username: '',
      password: '',
      clientId: '',
      clientSecret: ''
    }
  });

  const { reset } = form;

  useEffect(() => {
    reset(startValues);
  }, [reset, startValues]);

  const dispatch = useDispatch();

  const history = useHistory();

  const [{ fetching: fetchingCreateItsmIntegration }, createItsmIntegration] = useMutation<
    CreateItsmIntegrationResponse,
    CreateItsmIntegrationVariables
  >(CreateItsmIntegration);

  const [{ fetching: fethcinUpdateItsmIntegration }, updateItsmIntegration] = useMutation<
    UpdateItsmIntegrationResponse,
    UpdateItsmIntegrationVariables
  >(UpdateItsmIntegration);

  const handleSubmit = async (data: ServiceNowData) => {
    if (isEdit && startValues) {
      const payload = updateServiceNowAdapter(data);

      const response = await updateItsmIntegration({
        newData: payload,
        integrationUid: data.uid
      });

      if (response.error || response.data?.updateItsmIntegration.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on update ServiceNow integration' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'ServiceNow integration updated successfully'
      });

      history.push(`/organization?tab=integrations`);
      return;
    }

    if (!isEdit) {
      const payload = serviceNowAdapter(data);

      const response = await createItsmIntegration({ data: payload });

      if (response.error || response.data?.createItsmIntegration.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create ServiceNow integration' }
        });

        // eslint-disable-next-line no-console
        console.error(response.error);
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'ServiceNow integration created successfully'
      });

      history.push(`/organization?tab=integrations`);
      return;
    }
  };

  const fetching =
    fetchingCreateItsmIntegration || fetchingStartValues || fethcinUpdateItsmIntegration;

  return (
    <BaseLayout
      formComponent={<Form form={form} />}
      onSubmit={form.handleSubmit(handleSubmit)}
      isLoading={fetching}
      saveButtonLabel="Save integration"
      infoTitle="Configuring your integration with ServiceNow"
      infoDescription="To integrate successfully, fill the form with the basic necessary info. Next, select the type of credential that you want to use to enable your access. After filling the form, save to conclude, then start opening your tickets from the incident view."
    />
  );
}
