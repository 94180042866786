import { MemcachedFormData } from 'views/ServicesHub/forms/monitoring/cache/Memcached';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function memcachedAdapter(data: MonitoringCommomData & MemcachedFormData) {
  const domain = prepareDomain(data);

  return {
    ...monitoringAdapter({ ...data, method: 'memcached' }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringCommomData & MemcachedFormData) => {
  if (data.domainType === 'userAndPass') {
    // ex: HOST:PORT
    return `${data.host}:${data.port}`;
  }

  return data.domain;
};
