import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

export const MTTR = () => {
  const GetIncidentsMTTR = `#graphql
    query (
      $startDate: String! 
      $endDate: String!      
    ) {
      insights {
        incidents {
          mttr(startDate: $startDate, endDate: $endDate){
            period
            compared
          }
        }      
      }
    }
  `;

  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentsMTTR,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const mttr = data?.insights?.incidents?.mttr;

  return (
    <>
      <Metrics
        name="MTTR"
        value={mttr?.period}
        valueFormat={MetricsValueFormatEnum.Time}
        isFetching={fetching}
        tooltipTitle="Mean Time to Resolve (MTTR) is the average time from the trigger of an incident to its resolution."
        diffFormat={MetricsValueFormatEnum.Time}
        diff={mttr?.compared}
        startDate={startDate.toDate()}
        endDate={endDate.toDate()}
      />
    </>
  );
};
