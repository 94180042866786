import keyValuesToObject from 'helpers/keyValuesToObject';

const mapDataToApi = formData => {
  const applicationType = formData.applicationType;

  const postBodyType =
    formData.post_body_type !== 'raw' ? formData.post_body_type : formData.post_body_raw_type;

  const postBodyXFormUrlEncoded = keyValuesToObject(formData.post_body_urlencoded || []);

  const headers = keyValuesToObject(formData.headers || []);

  return {
    environmentId: Number(formData.environmentId),
    productId: formData.productId,
    name: formData.name,
    teamId: formData.teamId ? Number(formData.teamId) : null,
    applicationsSettings: {
      type: applicationType,
      healthcheckUrl: formData.healthcheckUrl,
      method: formData.method.toUpperCase(),
      headers,
      validationString: formData.validationString,
      timeout: Number(formData.timeout),
      checkInterval: Number(formData.check_interval),
      failuresToIncident: Number(formData.failuresToIncident),
      domain_settings: JSON.stringify({ tls_renegotiation: Number(formData.tls_renegotiation) }),
      postBodyRaw: formData.post_body_raw || null,
      postBodyUrlencoded: postBodyXFormUrlEncoded || null,
      postBodyType: postBodyType || null,
      skipSslValidation: formData.skip_ssl_validation,
      openIncidentWithSeverity: formData?.openIncidentWithSeverity || 'not-classified',
      external: true
    }
  };
};

export default mapDataToApi;
