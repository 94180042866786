import React from 'react';

import { Button, Hidden, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import actions from 'redux/actions/';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *': {
      // marginRight: 2,
      margin: theme.spacing(1)
    }
  },
  spacer: {
    flexGrow: 1
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10
  },
  paper: {
    // padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
    boxShadow: 'none',
    fontSize: 'xx-large',
    fontWeight: 'bold'
  }
}));

const ProductToolbar = props => {
  const { className, environment, title, entity } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const cluster =
    environment.environment.clusters && environment.environment.clusters.length
      ? environment.environment.clusters[0]
      : '';

  const provisionHandler = env =>
    dispatch({
      type: actions.PROVISION_ENTITY,
      payload: env,
      meta: {
        entity
      }
    });

  const deprovisionHandler = env =>
    dispatch({
      type: actions.DEPROVISION_ENTITY,
      payload: env,
      meta: {
        entity
      }
    });

  function provisionButton(cluster) {
    if (cluster) {
      return (
        <Button
          color="primary"
          onClick={event => {
            const env = {
              id: cluster.id,
              environmentId: cluster.environmentId
            };
            deprovisionHandler(env);
          }}
          variant="contained">
          Deprovision
        </Button>
      );
    } else {
      return (
        <Button
          color="primary"
          onClick={event => {
            const env = { environmentId: environment.environment.id };
            provisionHandler(env);
          }}
          variant="contained">
          Provision
        </Button>
      );
    }
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Hidden smDown>
          <Paper className={classes.paper}>{title}</Paper>
        </Hidden>

        <span className={classes.spacer} />
        {provisionButton(cluster)}
      </div>
    </div>
  );
};

ProductToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductToolbar;
