export enum MetricsValueFormatEnum {
  Time = 'time',
  Milliseconds = 'milliseconds',
  Count = 'count',
  Percentile = 'percentile'
}

export const formatMetricsValue = ({
  value,
  valueFormat
}: {
  value: number;
  valueFormat?: MetricsValueFormatEnum;
}) => {
  if (!value) {
    return ' - ';
  }

  if (value < 0) {
    value = value * -1;
  }

  const getTime = (value: number): string => {
    let dayReturn = '';
    const newDate = new Date(2000, 0, 1, 0, 0, 0);
    const parsedDate = new Date(newDate.getTime() + 1000 * value);

    var days = parsedDate.getDate() - 1;
    var hours = parsedDate.getHours();
    var minutes = parsedDate.getMinutes();
    var seconds = parsedDate.getSeconds();

    if (value === 0) {
      return '0s';
    }

    if (value < 60) {
      return seconds + 's';
    }

    if (days >= 1) {
      dayReturn = days + 'd ';
    }

    if (hours >= 1) {
      const hourReturn = hours + 'h ';

      if (minutes > 0) {
        if (seconds > 0) {
          return dayReturn + hourReturn + minutes + 'm ' + seconds + 's';
        }

        return dayReturn + hourReturn + minutes + 'm ';
      }

      return dayReturn + hourReturn;
    }

    if (minutes > 0) {
      if (seconds > 0) {
        return dayReturn + minutes + 'm ' + seconds + 's';
      }

      return dayReturn + minutes + 'm';
    }

    return dayReturn + seconds + 's';
  };

  const getPercentile = (value: number): string => {
    return value + '%';
  };

  const getMilliseconds = (value: number): string => {
    return value + 'ms';
  };

  const getCount = (value: number): string => {
    return String(value);
  };

  switch (valueFormat) {
    case MetricsValueFormatEnum.Time:
      return getTime(value);
    case MetricsValueFormatEnum.Percentile:
      return getPercentile(value);
    case MetricsValueFormatEnum.Milliseconds:
      return getMilliseconds(value);
    case MetricsValueFormatEnum.Count:
      return getCount(value);
    default:
      return value;
  }
};
