import { useQuery } from 'urql';

export const useSlackGetLinkChannelById = (
  warRoomProvider: string,
  warRoomIntegrationId: number
) => {
  return useQuery({
    query: `
      query($warRoomProvider: String!, $warRoomIntegrationId: Float!) {
        getLinkChannelById(
          warRoomProvider: $warRoomProvider
          warRoomIntegrationId: $warRoomIntegrationId
        ) {
          link
        }
      }
    `,
    variables: { warRoomProvider, warRoomIntegrationId }
  });
};
