import { DialogContentText, Link } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function DiscordForm({ form }: FormProps) {
  const classes = useStyles();
  const { register, errors } = form;

  return (
    <>
      <DialogContentText>
        1. Go to
        <Link
          className={classes.link}
          href="https://discord.com/app"
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: '4px' }}>
          Discord
        </Link>
        via browser or application.
      </DialogContentText>
      <DialogContentText>
        Go to
        <Link
          className={classes.link}
          href="https://teams.microsoft.com/l/app/203a1e2c-26cc-47ca-83ae-be98f960b6b2?source=store-copy-link"
          target="_blank"
          rel="noopener noreferrer">
          incoming webhook connector details
        </Link>
        , click on <strong>Add to a team</strong>, then choose a channel to receive Elven Platform
        notifications.
      </DialogContentText>
      <DialogContentText>
        2. Open your Server Settings on Discord, head into the Integrations tab and click in "Create
        Webhook".
      </DialogContentText>
      <DialogContentText>3. Name your Webhook.</DialogContentText>
      <DialogContentText>4 Choose what channel the Webhook posts to.</DialogContentText>
      <DialogContentText>5. Copy the URL, and paste here.</DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.discordChannelWebhookUrl"
          label="Webhook url"
          errors={errors}
          minRows={5}
          multiline
          required
        />
      </DialogContentText>
    </>
  );
}
