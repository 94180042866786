import { useEffect } from 'react';

import { apm } from '@elastic/apm-rum';
import { Typography, Box, InputAdornment, FormHelperText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ErrorBudgetMargin } from 'types/external/ErrorBudget';
import { useQuery, useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { ButtonSimple } from 'componentsV4/Button';
import SimpleInput from 'componentsV4/Input/SimpleInput';
import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';

import { ErrorBudgetFormData } from './schema';
import useForm from './useForm';
import { useStyles } from './useStyles';

export const GetErrorBudgetByServiceId = `#graphql
  query(
    $serviceId: Float!
  ) {
    getMargin(serviceId: $serviceId) {
      budgetPerc
      budgetHour
      marginHour
      marginOutHour
      marginInsideHour
      marginOutPerc
      marginInsidePerc   
    }
  }
`;

export const CreateService = `#graphql
  mutation($serviceInput: ServiceInput!) {
    createService(
      serviceInput: $serviceInput
    ) {
      serviceId
      interval
      budgetPerc
      budgetHour
    }
  }
`;

const errorBudgetMarginDefaultValues: ErrorBudgetMargin = {
  budgetPerc: 0,
  budgetHour: 0,
  marginHour: '0 h',
  marginOutHour: '0 h',
  marginInsideHour: '0 h',
  marginOutPerc: 0,
  marginInsidePerc: 100
};

const startValues = {
  budget_perc: 0,
  budget_hour: 0
};

function getErrorBudgetMarginText(margin: ErrorBudgetMargin) {
  if (margin.budgetPerc > 0) return `${margin.budgetPerc}%`;

  return `${margin.budgetHour} h`;
}

const ErrorBudget = ({
  sterasId,
  interval,
  isMonitoringActive
}: {
  sterasId: number;
  interval: number;
  isMonitoringActive: boolean;
}) => {
  const classes = useStyles();

  const { productId } = useParams<{ productId: string }>();

  const dispatch = useDispatch();

  const hasPostBudgetPermission = useProductPermission({
    target: 'ErrorBudgetController-post-/budget/',
    productId
  });

  const { handleSubmit, register, errors, setValue, formState, reset } = useForm({
    defaultValues: {
      budget_perc: startValues.budget_perc,
      budget_hour: startValues.budget_hour
    }
  });

  const { isDirty } = formState;

  const useErrorBudget = ({
    serviceId,
    isMonitoringActive
  }: {
    serviceId: number;
    isMonitoringActive: boolean;
  }) => {
    return useQuery<{ getMargin: ErrorBudgetMargin }>({
      query: GetErrorBudgetByServiceId,
      pause: !serviceId || !isMonitoringActive,
      variables: { serviceId }
    });
  };

  const [resultErrorBudget, reexecuteQuery] = useErrorBudget({
    serviceId: sterasId,
    isMonitoringActive
  });

  const { fetching: fetchingGet } = resultErrorBudget;

  const margin: ErrorBudgetMargin =
    resultErrorBudget.data?.getMargin || errorBudgetMarginDefaultValues;

  useEffect(() => {
    if (fetchingGet) return;

    setValue('budget_perc', margin.budgetPerc || startValues.budget_perc);

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 60 /* 1 minute */);

    return () => clearInterval(intervalId);
  }, [fetchingGet, margin.budgetPerc, reexecuteQuery, setValue]);

  const [{ fetching: fetchingCreate }, createService] = useMutation(CreateService);

  const onSubmit = (data: ErrorBudgetFormData) => {
    const serviceInput = {
      serviceId: sterasId,
      interval,
      budgetPerc: Number(data.budget_perc),
      budgetHour: 0,
      productId: Number(productId)
    };

    createService({
      serviceInput
    }).then(result => {
      if (result.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to configure error budget' }
        });

        apm.setCustomContext({
          graphqlRequestError: result.error,
          graphqlRequestData: result.data
        });
        apm.captureError(result.error);

        return;
      }

      if (result.data) {
        reexecuteQuery();
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Error budget configured'
        });
      }
    });

    reset({ budget_perc: serviceInput.budgetPerc });
  };

  return (
    <Box className={classes.root}>
      <form id="configureErrorBudget" onSubmit={handleSubmit(onSubmit)}>
        {!hasPostBudgetPermission && (
          <Typography className={classes.inputLabel} variant="h6">
            Your budget: {getErrorBudgetMarginText(margin)}
          </Typography>
        )}

        {hasPostBudgetPermission && (
          <Box display="flex" alignItems="center" className={classes.budget}>
            <Typography className={classes.inputLabel} variant="h6">
              Define your budget:
            </Typography>
            <SimpleInput
              className={classes.input}
              inputRef={register}
              required={true}
              name="budget_perc"
              type="decimal"
              error={Boolean(errors['budget_perc']?.message)}
              endAdornment={
                <InputAdornment style={{ marginRight: 12 }} position="end">
                  %
                </InputAdornment>
              }
            />
            <ButtonSimple
              className={classes.button}
              color="primary"
              type="submit"
              variant="outlined"
              text="Save"
              disabled={!isDirty}
            />
          </Box>
        )}

        <Box display="flex" alignItems="flex-start" flexDirection="column">
          {errors && (
            <FormHelperText className={classes.inputHelperText}>
              {errors['budget_perc']?.message}
            </FormHelperText>
          )}
        </Box>

        <Box display="flex" alignItems="center" className={classes.margin}>
          <Typography className={classes.hourLabel} variant="h4">
            {margin.marginHour}
          </Typography>
          {margin.marginOutPerc > 0 && (
            <Box
              className={
                margin.marginInsidePerc > 0 ? classes.outMargin : classes.outMarginWithRadius
              }
              width={`${margin.marginOutPerc}%`}></Box>
          )}
          {margin.marginInsidePerc > 0 && (
            <Box
              className={
                margin.marginOutPerc > 0 ? classes.insideMargin : classes.insideMarginWithRadius
              }
              width={`${margin.marginInsidePerc}%`}></Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" className={classes.point}>
          <Box display="flex" alignItems="center">
            <Box className={classes.outMarginPoint} />
            <Typography className={classes.legend} variant="caption">
              <strong>{margin.marginOutHour}</strong> out of margin
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={classes.insideMarginPoint} />
            <Typography className={classes.legend} variant="caption">
              <strong>{margin.marginInsideHour}</strong> inside margin
            </Typography>
          </Box>
        </Box>
      </form>
      {(fetchingGet || fetchingCreate) && <LoadingOverlay size={45} />}
    </Box>
  );
};

export default ErrorBudget;
