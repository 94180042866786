import { createContext, ReactNode, useContext, useState } from 'react';

import { TourLocalStorageKeys, TourOrder } from 'constants/tour';

type PageGuideContextData = {
  canPresents: (index: number) => boolean;
  onFinish: (index: number) => void;
};

type PageGuideContextProps = {
  children: ReactNode;
};

const PageGuideContext = createContext({} as PageGuideContextData);

function getInitialTourIndex() {
  const TourOrderKeys = Object.keys(TourOrder).filter(
    x => !(parseInt(x) >= 0)
  ) as (keyof typeof TourOrder)[];

  return TourOrderKeys.reduce((tourIndex, key) => {
    const storageKey = TourLocalStorageKeys[key];
    const isTourCompleted = localStorage.getItem(storageKey) === 'done';

    if (isTourCompleted) tourIndex += 1;

    return tourIndex;
  }, 0);
}

export function PageGuideProvider({ children }: PageGuideContextProps) {
  const [currentTourIndex, setCurrentTourIndex] = useState(getInitialTourIndex);

  function canPresents(index: number) {
    return index === currentTourIndex;
  }

  function onFinish(index: number) {
    setCurrentTourIndex(index + 1);
  }

  return (
    <PageGuideContext.Provider value={{ canPresents, onFinish }}>
      {children}
    </PageGuideContext.Provider>
  );
}

export default function usePageGuideOrder(tourIndex: number) {
  const { canPresents, onFinish } = useContext(PageGuideContext);

  return { onFinish, canPresents: canPresents(tourIndex) };
}
