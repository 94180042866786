import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import useVpc from './useVpc';

const VpcSelect = ({ formState, onChange }) => {
  const cloudId = formState.values.cloudId;
  const region = formState.values.region;

  const { data: rawVpcs = [], isIdle, isPending } = useVpc({
    cloudId,
    region
  });

  const vpcs = rawVpcs.map(option => {
    const { VpcId: vpcId, IsDefault, Tags } = option;
    const Name = Tags.find(tag => tag.Key === 'Name')?.Value;

    const name = `(${Name ? Name : 'no name'})`;
    const vpcDefault = `${IsDefault ? '(default)' : ''}`;
    const vpcLabel = `${vpcId} ${name} ${vpcDefault}`.trim();

    return { vpcId, vpcLabel, vpcDefault };
  });

  const waitingSelectionsText = 'Select a Cloud and Region first';
  const pendingText = 'loading...';
  const selectText = 'select a VPC';

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
    const { vpcDefault, vpcLabel } = vpcs.find(vpc => vpc.vpcId === event.target.value) || {};
    onChange('vpcLabel', vpcLabel);
    onChange('vpcDefault', Boolean(vpcDefault));
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  return (
    <TextField
      fullWidth
      select
      InputLabelProps={{
        shrink: true
      }}
      label="VPC"
      margin="dense"
      name="vpcId"
      onChange={handleChange}
      required
      value={isPending ? '1' : formState.values.vpcId || '1'}
      variant="outlined"
      error={hasError('vpcId')}
      helperText={hasError('vpcId') ? formState.errors.vpcId[0] : null}>
      {isIdle && (
        <MenuItem title={waitingSelectionsText} value="1">
          <em>{waitingSelectionsText}</em>
        </MenuItem>
      )}
      {isPending && (
        <MenuItem title={pendingText} value="1">
          <em>{pendingText}</em>
        </MenuItem>
      )}
      {!isIdle && !isPending && (
        <MenuItem title={selectText} value="1">
          <em>{selectText}</em>
        </MenuItem>
      )}
      {vpcs.map(({ vpcId, vpcLabel }) => {
        return (
          <MenuItem title={vpcLabel} key={`vpc_${vpcId}`} value={vpcId}>
            {vpcLabel}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default VpcSelect;
