import React from 'react';

import {
  Fade,
  Box,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  copyButton: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0)
  },
  actionsButtons: {
    color: 'primary',
    marginRight: theme.spacing(2)
  }
}));

const AppDialog = ({
  title,
  open,
  isError,
  isSuccess,
  handleClose,
  mutationLoading,
  queryLoading,
  handleCreateToken,
  handleReGenerateToken,
  values,
  setValues,
  showApiTokenOption = false,
  permissions = {
    new: false,
    update: false,
    remove: false
  }
}) => {
  const classes = useStyles();
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [errorName, setErrorName] = React.useState(false);

  const handleChange = event => {
    if (event.target.name === 'name') {
      setValues({
        ...values,
        [event.target.name]: event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      });
    }

    if (event.target.name !== 'name') {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  React.useEffect(() => {
    if (copySuccess) {
      setTimeout(() => {
        setCopySuccess(false);
      }, 300);
    }
  }, [copySuccess]);

  return (
    <OldDeleteDialog
      id={'dialogAppToken'}
      open={open}
      aria-labelledby="new-service-dialog"
      onClose={handleClose}
      title={title}
      fullWidth
      maxWidth="md">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label="Type"
              required
              margin="dense"
              name="token_type"
              onChange={handleChange}
              error={errorName}
              helperText={errorName ? 'A token type must be provided' : ''}
              select
              SelectProps={{ native: true }}
              value={values.token_type}
              variant="outlined">
              <option value="" />
              <option value="hits/failures">Hits/Failures</option>
              {showApiTokenOption ? (
                <option value="api_token">Api Token</option>
              ) : (
                <option value="deploy">Deploy</option>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              label="Name"
              margin="dense"
              name="name"
              error={errorName}
              helperText={errorName ? 'A name must be provided' : ''}
              required
              value={values.name}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={11} maxWidth={300}>
            {isError && "Couldn't load tokens :("}
            {queryLoading || mutationLoading ? <CircularProgress /> : null}
            {isSuccess && values.token_type === 'deploy' && values.curl.length > 0
              ? handleClose()
              : null}
            {isSuccess && values.curl.length > 0 && values.token_type === 'hits/failures' ? (
              <pre
                id={`preAppToken`}
                style={{
                  cols: 100,
                  border: '1px solid rgba(29,28,29,.04)',
                  borderRadius: 4,
                  background: 'rgba(29, 28, 29, 0.04)',
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'clip',
                  padding: 8,
                  marginRight: 13
                }}
                onClick={event => {
                  const range = document.createRange();
                  range.selectNodeContents(event.target);
                  const sel = window.getSelection();
                  sel.removeAllRanges();
                  sel.addRange(range);
                }}>
                {values.curl}
              </pre>
            ) : null}
          </Grid>

          <Grid item xs={1}>
            {isSuccess && values.curl.length > 0 && values.token_type === 'hits/failures' ? (
              <Box id={`BoxCopyAppToken`}>
                <Tooltip title="Copy to clipboard" aria-label="add">
                  <IconButton
                    id={`copyButtonAppToken`}
                    disabled={!values.curl}
                    color="primary"
                    className={classes.copyButton}
                    onClick={() => {
                      navigator.clipboard.writeText(values.curl);
                      setCopySuccess(true);
                    }}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
                <Fade id={`fadeCopyAppToken`} in={copySuccess} timeout={{ enter: 500, exit: 2000 }}>
                  <small style={{ color: 'green' }}>Copied</small>
                </Fade>
              </Box>
            ) : null}
          </Grid>
        </Grid>

        <Box id={`appTolkenBoxButtons`} display="flex" justifyContent="flex-end" marginTop={3}>
          {handleCreateToken && (
            <ButtonSimple
              className={classes.actionsButtons}
              variant="contained"
              color="primary"
              disabled={(isSuccess && values.curl) || !permissions.new}
              disableElevation
              onClick={() => {
                if (values.name.length === 0) {
                  setErrorName(true);
                  return;
                } else {
                  setErrorName(false);
                }
                handleCreateToken();
              }}
              text="Generate Integration Token"
            />
          )}
          {handleReGenerateToken && (
            <ButtonSimple
              className={classes.actionsButtons}
              variant="contained"
              color="primary"
              disableElevation
              disabled={!permissions.update}
              onClick={() => {
                if (values.name.length === 0 || values.token_type.length === 0) {
                  setErrorName(true);
                  return;
                } else {
                  setErrorName(false);
                }
                handleReGenerateToken();
              }}
              text="Generate New Token"
            />
          )}
          <ButtonSimple
            id={`appTokenCancel`}
            color="primary"
            variant="outlined"
            onClick={handleClose}
            text="Close"
          />
        </Box>
      </Box>
    </OldDeleteDialog>
  );
};

export default AppDialog;
