import { useMutation } from 'urql';

const DeleteSSOIntegrationQuery = `#graphql
  mutation DeleteSSOIntegration ($ssoUid: String!) {
    deleteSSOIntegration(ssoUid: $ssoUid)
  }
`;

export function useDeleteSSOIntegration() {
  return useMutation(DeleteSSOIntegrationQuery);
}
