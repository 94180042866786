import { Resource } from 'types/external/Resource';

import { ServiceBusFormData } from 'views/ServicesHub/forms/monitoring/queue/ServiceBus';

import { monitoringAdapter } from '../monitoring';

export function serviceBusAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & ServiceBusFormData {
  return {
    ...monitoringAdapter(data),
    connectionString: data.domainSettings.connectionString,
    monitoringType: data.domainSettings.type,
    queueName: data.domainSettings.queue,
    topicName: data.domainSettings.topic,
    subscriptionName: data.domainSettings.subscription
  };
}
