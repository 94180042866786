const regionsAws = [
  {
    value: 'us-east-1',
    label: 'us-east-1 (N. Virginia)'
  },
  {
    value: 'us-east-2',
    label: 'us-east-2 (Ohio)'
  },
  {
    value: 'us-west-2',
    label: 'us-west-2 (Oregon)'
  },
  {
    value: 'ap-south-1',
    label: 'ap-south-1 (Mumbai)'
  },
  {
    value: 'ap-southeast-1',
    label: 'ap-southeast-1 (Singapore)'
  },
  {
    value: 'ap-southeast-2',
    label: 'ap-southeast-2 (Sydney)'
  },
  {
    value: 'ca-central-1',
    label: 'ca-central-1 (Central)'
  },
  {
    value: 'eu-central-1',
    label: 'eu-central-1 (Frankfurt)'
  },
  {
    value: 'eu-west-1',
    label: 'eu-west-1 (Ireland)'
  },
  {
    value: 'eu-west-3',
    label: 'eu-west-3 (Paris)'
  },
  {
    value: 'sa-east-1',
    label: 'sa-east-1 (São Paulo)'
  }
];

export default regionsAws;
