import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme/v5';

export default makeStyles((theme: Theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    marginTop: theme.spacing(1),
    maxHeight: theme.spacing(50)
  },
  label: {
    color: theme.palette.primary.dark
  }
}));
