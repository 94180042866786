import { ApplicationType } from 'constants/ApplicationType';
import MonitoringStatusV0 from 'constants/MonitoringStatusV0';

import { CircularProgress, Typography, Box, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'urql';

import ButtonSimple from 'componentsV3/ButtonSimple';
import CardApplication from 'componentsV3/CardApplication';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

const ApplicationsQuery = `
  query($productId: Float!, $quantity: Float!) {
    applications(page: 1, per_page: $quantity, sort: "name,ASC", productId: $productId) {
      data {
        id
        name
        type
        monitoringStatus
        isMonitoringActive
        lastEvent {
          type
          createdAt
          applicationId
          incidentId
        }
      }
    }
  }
`;

type Application = {
  id: number;
  name: string;
  type: ApplicationType;
  monitoringStatus: MonitoringStatusV0;
  isMonitoringActive: boolean;
  lastEvent: { type: any; createdAt: Date; applicationId: number; incidentId: number };
};

const ApplicationsList = ({
  productId,
  totalApplications,
  quantity = 4,
  enableQuery
}: {
  productId: number;
  totalApplications: number;
  quantity?: number;
  enableQuery: boolean;
}) => {
  const theme = useTheme<Theme>();

  const history = useHistory();

  const [result] = useQuery<{ applications: { data: Application[] } }>({
    query: ApplicationsQuery,
    variables: { productId, quantity },
    pause: !enableQuery
  });

  const applications = result.data?.applications?.data;

  if (result.fetching) {
    const itemHeight = 115;
    const showMoreButtonHeight = 56;
    const itemsActualQuantity = totalApplications > quantity ? quantity : totalApplications;
    const willShowViewMoreButton = totalApplications - quantity > 0;

    return (
      <Box
        padding={3}
        minHeight={
          itemsActualQuantity * itemHeight + (willShowViewMoreButton ? showMoreButtonHeight : 0)
        }
        display="flex"
        alignItems="center"
        justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!applications?.length) {
    return (
      <Box padding={3}>
        <Typography>No applications to show</Typography>
      </Box>
    );
  }

  return (
    <>
      {applications.map((applicationData, index) => (
        <Box key={applicationData.id}>
          {index > 0 && (
            <Box ml={3} mr={3}>
              <Divider style={{ background: theme.palette.inputBorder.default }} />
            </Box>
          )}
          <Box padding={3}>
            <CardApplication
              key={applicationData.id}
              id={applicationData.id}
              name={applicationData.name}
              type={applicationData.type}
              status={applicationData.monitoringStatus}
              productId={productId}
              event={
                applicationData.lastEvent ? { ...applicationData.lastEvent, productId } : undefined
              }
            />
          </Box>
        </Box>
      ))}
      {totalApplications !== applications.length && (
        <Box paddingX={2} paddingBottom={2} textAlign={'center'}>
          <ButtonSimple
            color={'primary'}
            text={'See all applications from this product'}
            onClick={() => history.push(`/products/${productId}`)}></ButtonSimple>
        </Box>
      )}
    </>
  );
};

export default withThemeV3Hoc(ApplicationsList);
