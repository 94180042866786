import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { Box } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab-old/Autocomplete';
import { useQuery } from 'urql';

import { usePermission } from 'hooks/usePermission';

const useStyles = makeStyles(theme => ({
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  noOptions: {
    textAlign: 'center'
  },
  popperDisablePortal: {
    position: 'relative'
  },
  listbox: {
    marginTop: '5px'
  }
}));

const SecretsQuery = `
  query {
    secrets {
      name
    }
  }
`;

export default function SecretsAutocomplete({ onChange }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const permissions = {
    list: usePermission('SecretsController-get-/secrets')
  };

  const [result] = useQuery({
    query: SecretsQuery,
    pause: !permissions.list
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange({ event, value: newValue.name });
  };

  return (
    <Autocomplete
      open
      classes={{
        paper: classes.paper,
        option: classes.option,
        popperDisablePortal: classes.popperDisablePortal,
        noOptions: classes.noOptions,
        listbox: classes.listbox
      }}
      ListboxProps={{
        style: {
          maxHeight: '300px'
        }
      }}
      value={value}
      onChange={handleChange}
      disableCloseOnSelect
      disablePortal
      renderTags={() => null}
      noOptionsText="No secret variables yet."
      autoHighlight
      options={result.data?.secrets || []}
      loading={result.fetching}
      loadingText={
        <Box display="flex" justifyContent="center">
          <CircularProgress size={20} />
        </Box>
      }
      getOptionLabel={option => option.name}
      renderInput={params => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          className={classes.inputBase}
        />
      )}
    />
  );
}
