import { useMemo } from 'react';

import { GetExternalServiceByUidOutput } from 'types/external/ExternalService';
import { useQuery } from 'urql';
import { z } from 'zod';

import { schema } from './schema';

const GetExternalIntegrationByUidQuery = `
  query ($uid: String!) {
    externalIntegration(uid: $uid) {
      id
      uid
      name
      responders {
        id
        uid
        type
      }
    }
  }
`;

function useExternalService(uid: string) {
  return useQuery<{
    externalIntegration: GetExternalServiceByUidOutput;
  }>({
    query: GetExternalIntegrationByUidQuery,
    variables: { uid },
    pause: !uid
  });
}

type StartValues = z.infer<typeof schema>;

export function useStartValues(
  integrationUid: string
): {
  startValues: StartValues | null;
  fetching: boolean;
} {
  const [{ fetching, data }] = useExternalService(integrationUid);

  return useMemo(() => {
    if (fetching)
      return {
        startValues: null,
        fetching
      };

    if (!data?.externalIntegration) {
      return {
        startValues: null,
        fetching: false
      };
    }

    const { id, name, uid, responders } = data.externalIntegration;

    return {
      startValues: {
        id,
        name,
        uid,
        responders: responders.map(responder => ({
          label: '',
          type: responder.type,
          value: responder.uid
        }))
      },
      fetching
    };
  }, [data?.externalIntegration, fetching]);
}
