import { Box, useTheme } from '@material-ui/core';
import { Skeleton as MuiSkeleton, SkeletonProps } from '@material-ui/lab';

const Skeleton = (props: SkeletonProps) => (
  <MuiSkeleton
    {...props}
    style={{
      transform: 'none'
    }}
  />
);

export function LoadingSkeleton() {
  const theme = useTheme();

  return (
    <Box padding={theme.spacing(14, 5, 0)}>
      <Skeleton height={40} width="100%" />
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)} mt={5}>
        <Skeleton height={64} width="100%" />
        <Box display="flex" gridGap={theme.spacing(2)}>
          <Skeleton height={130} width="100%" />
          <Skeleton height={130} width="100%" />
          <Skeleton height={130} width="100%" />
          <Skeleton height={130} width="100%" />
          <Skeleton height={130} width="100%" />
          <Skeleton height={130} width="100%" />
        </Box>
        <Box display="flex" gridGap={theme.spacing(2)}>
          <Skeleton height={150} width="100%" />
          <Skeleton height={150} width="100%" />
        </Box>
        <Skeleton height={300} width="100%" />
      </Box>
    </Box>
  );
}
