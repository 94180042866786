import ApplicationType from 'constants/ApplicationType';

import axios from 'redux/axios';

function useWebhookActions({ application, productId, applicationId, version }) {
  const isWebhook = application.applicationsSettings?.type === ApplicationType.Webhook.type;

  const isExternalWithoutAddons =
    application.applicationsSettings?.type === ApplicationType.ExternalWithoutAddons.type;

  const isAbleToMonitor =
    application?.agent?.status_code === 16 || isWebhook || isExternalWithoutAddons;

  const deployTimeline = (description, deployDate, deployType) => {
    axios
      .post(`/products/${productId}/applications/${applicationId}/timeline`, {
        description: description,
        type: deployType,
        version: version,
        createdAt: new Date(deployDate),
        updatedAt: new Date(deployDate),
        application_id: Number(applicationId),
        application_uid: application.uid,
        organization: application.org.uid
      })
      .then(() => {
        document.location.reload();
      });
  };

  return {
    deployTimeline,
    isWebhook,
    isAbleToMonitor
  };
}

export default useWebhookActions;
