import { MultiFlowStatusEnum, MultiflowQuery } from 'types/external/MultiFlow';

export const multiflowDefaultValue: MultiflowQuery = {
  id: 0,
  name: 'Synthetic Monitoring',
  status: MultiFlowStatusEnum.Off,
  uid: '',
  environment: {
    name: 'none',
    statusCode: 0
  },
  steps: []
};
