import { Chip } from '@material-ui/core';
import { ResourceStatusEnum } from 'types/external/Resource';

import { useStyles } from './styles';

export type StatusChipProps = {
  status: ResourceStatusEnum;
};

function getLabelByStatus(status: ResourceStatusEnum) {
  const statusLabelMap = {
    [ResourceStatusEnum.Off]: 'Inactive',
    [ResourceStatusEnum.Available]: 'Operational',
    [ResourceStatusEnum.Unavailable]: 'Not operational',
    [ResourceStatusEnum.Pending]: 'Pending',
    [ResourceStatusEnum.Maintenance]: 'In maintenance'
  };

  return statusLabelMap[status];
}

export function StatusChip({ status }: StatusChipProps) {
  const classes = useStyles({ status });

  return <Chip className={classes.chip} label={getLabelByStatus(status)} />;
}
