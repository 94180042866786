import uuid from 'uuid';
import validate from 'validate.js';

import actions from '../../actions';

const schemaSettings = {
  domain: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  check_interval: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    },
    presence: {
      allowEmpty: false
    }
  },
  timeout: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    },
    presence: {
      allowEmpty: false
    }
  }
};

const addons1 = (state, action) => {
  if (actions.APPLICATIONS_ADDONS_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      addons: {
        ...state.addons,
        data: action.payload
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_SETTINGS_FETCH_SUCCESSFUL === action.type) {
    const settings = {
      ...state.addon_view.settings,
      values: action.payload.addonSettings
        ? action.payload.addonSettings
        : state.addon_view.settings.values
    };

    return {
      ...state,
      addon_view: {
        ...state.addon_view,
        ...action.payload,
        settings
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_POPULATE_SETTINGS_ENTITY === action.type) {
    const settings = state.addon_view.settings;
    const { uid } = action.meta;

    const envs = settings.values.envs.map(item => {
      const selected = { values: {} };
      if (item.uid === uid) {
        selected.values = action.payload;
      }
      const result = {
        ...item,
        ...selected.values
      };
      return result;
    });

    settings.values.envs = envs;

    const values = {
      ...settings.values,
      ...action.payload
    };

    const errors = validate(values, schemaSettings);

    return {
      ...state,
      addon_view: {
        ...state.addon_view,
        settings: {
          ...state.addon_view.settings,
          values,
          errors,
          isValid: !errors
        }
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_NEW_ENVS_ADD === action.type) {
    const settings = state.addon_view.settings;
    const envs = settings.values.envs;

    envs.push({
      uid: uuid(),
      key: '',
      value: ''
    });

    settings.values.envs = envs;
    const errors = validate(settings.values, schemaSettings);

    return {
      ...state,
      addon_view: {
        ...state.addon_view,
        settings: {
          ...settings,
          isValid: !errors
        }
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_NEW_ENVS_REMOVE === action.type) {
    const settings = state.addon_view.settings;

    if (settings.values.envs.length > 1) {
      const envs = settings.values.envs.filter(item => item.uid !== action.payload.uid);
      settings.values.envs = envs;
    }

    const errors = validate(settings.values, schemaSettings);

    return {
      ...state,
      addon_view: {
        ...state.addon_view,
        settings: {
          ...settings,
          isValid: !errors
        }
      }
    };
  }

  return state;
};

// FIXME: identifier "addons" has already been declared or isn't helpful, rename this please!
export default addons1;
