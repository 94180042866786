import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { EventType } from 'types/external/EventsHistory';

import { useStyles as useComponentsStyles } from '../styles';

import { useStyles } from './styles';

type CreatedAtProps = {
  event: EventType;
};

export function CreatedAt({ event }: CreatedAtProps) {
  const componentsClasses = useComponentsStyles();
  const classes = useStyles();

  return (
    <>
      {event.createdAt && (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Typography variant="body1" className={classes.eventDate}>
            {format(new Date(event?.createdAt), 'MMM d, H:mm O')}
          </Typography>
        </>
      )}
    </>
  );
}
