import { useState } from 'react';

import DayJsUtils from '@date-io/dayjs';
import { Box, Checkbox, Grid, InputLabel, makeStyles, Typography } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { ButtonDefault } from 'oldComponents/Button';
import { useParams } from 'react-router';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import { useProductPermission } from 'hooks/useProductPermission';

const useStyles = makeStyles(theme => ({
  paragraphText: {
    paddingBottom: theme.spacing(3)
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(3)
  }
}));

const MaintenanceEditForm = ({ onClose, startValues, submitMutation }) => {
  const classes = useStyles();

  const { productId } = useParams();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const canPostMaintenanceWithProduct = useProductPermission({
    target: 'MaintenanceController-post-/maintenance',
    productId
  });

  const canPostMaintenanceWithoutProduct = usePermission('MaintenanceController-post-/maintenance');

  const canPostMaintenance = shouldUseNewApplications
    ? canPostMaintenanceWithoutProduct
    : canPostMaintenanceWithProduct;

  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });

  const defaultValues = {
    start: dayjs(new Date().setMinutes(new Date().getMinutes() + 1)).format('YYYY-MM-DDTHH:mm'),
    finish: dayjs(new Date().setMinutes(new Date().getMinutes() + 2)).format('YYYY-MM-DDTHH:mm')
  };

  const onSubmit = (start, finishDate, dependencies) => {
    submitMutation({
      start: dayjs(start).format('YYYY-MM-DDTHH:mm:ssZ'),
      finish: dayjs(finishDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      dependencies,
      productId: Number(productId)
    });
  };
  const [startDate, setStartDate] = useState(
    new Date(
      dayjs(startValues?.start).isAfter(new Date('Mon, 01 Jan 0001 00:00:00 GMT'))
        ? dayjs(startValues?.start).format('YYYY-MM-DDTHH:mm')
        : defaultValues.start
    )
  );
  const [finishDate, setFinishDate] = useState(
    new Date(
      dayjs(startValues?.finish).isAfter(new Date('Mon, 01 Jan 0001 00:00:00 GMT'))
        ? dayjs(startValues?.finish).format('YYYY-MM-DDTHH:mm')
        : defaultValues.finish
    )
  );
  const [dependencies, setDependencies] = useState(startValues?.dependencies || false);

  const hasErrorStartDate =
    new Date(startDate) < new Date(defaultValues.start)
      ? 'Start date must be in the future'
      : new Date(finishDate) <= new Date(startDate)
      ? 'The start date must be less than the end date'
      : '';

  const hasErrorFinishDate =
    new Date(finishDate) < new Date(defaultValues.finish)
      ? 'Finish date must be in the future'
      : new Date(finishDate) <= new Date(startDate)
      ? 'The finish date must be greater than the start date'
      : '';

  const disableSave = !canPostMaintenance || hasErrorStartDate || hasErrorFinishDate ? true : false;

  return (
    <form name="editMaintenance">
      <Typography className={classes.paragraphText}>
        Maintenance window signals a period where your application will be unavailable for a
        Scheduled Maintenance, pausing monitoring, alerts and related notifications.
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDateTimePicker
              className={classes.formControl}
              name="start"
              inputVariant="outlined"
              value={startDate}
              onChange={setStartDate}
              ampm={false}
              label="Start"
              format="DD/MM/YYYY HH:mm"
              InputLabelProps={{ shrink: true }}
              error={hasErrorStartDate}
              helperText={hasErrorStartDate ? hasErrorStartDate : null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DayJsUtils}>
            <KeyboardDateTimePicker
              className={classes.formControl}
              name="finish"
              inputVariant="outlined"
              value={finishDate}
              onChange={setFinishDate}
              ampm={false}
              label="End"
              format="DD/MM/YYYY HH:mm"
              InputLabelProps={{ shrink: true }}
              error={hasErrorFinishDate}
              helperText={hasErrorFinishDate ? hasErrorFinishDate : null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      {!useResourceFlag && (
        <InputLabel style={{ display: 'flex', marginBottom: '36px', alignItems: 'center' }}>
          <Checkbox
            color="primary"
            checked={dependencies}
            onChange={() => setDependencies(prev => !prev)}
          />
          <Typography fontSize={16}>Include dependencies (Add-on).</Typography>
        </InputLabel>
      )}

      <Box display="flex" justifyContent="flex-end">
        <ButtonDefault onClick={onClose} color="primary">
          Cancel
        </ButtonDefault>

        <ButtonDefault
          color="primary"
          variant="contained"
          type="submit"
          disabled={disableSave}
          style={{ marginLeft: '24px' }}
          onClick={() => onSubmit(startDate, finishDate, dependencies)}>
          Save
        </ButtonDefault>
      </Box>
    </form>
  );
};

export default MaintenanceEditForm;
