import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { Neo4jFormData } from 'views/ServicesHub/forms/monitoring/database/Neo4j';

import { monitoringAdapter } from '../monitoring';

export function neo4jAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & Neo4jFormData {
  const domain = new URI(data.domain);
  // ex: bolt://HOST:PORT@USER/PASSWORD

  // Respect this order. Neo4j connection URL uses HOST, PORT, USER, PASSWORD in this specific order.
  // Parsing the URL components according to the BOLT format
  const host = domain.username();
  const port = domain.password();
  const user = domain.hostname();
  const password = domain.pathname().replace('/', '');

  return {
    ...monitoringAdapter(data),
    host,
    port,
    user,
    password
  };
}
