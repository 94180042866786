import { Service } from 'types/cms/ServicesHub';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

export const getFeatureFlagServiceItem = (services: Service[]): boolean[][] => {
  const featureFlags = services.map(service =>
    service.items.map(item => useFeatureFlagUnleash(item.featureFlag, { queryString: true }))
  );

  return featureFlags;
};
