import { Box } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

export type ProductFormInput = {
  name: string;
  description: string;
};

export function ProductForm({ form }: FormProps) {
  const { register } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input label="Name" name="name" inputRef={register} errors={form.errors} />
      <Input
        label="Description"
        required={false}
        name="description"
        inputRef={register}
        errors={form.errors}
        multiline
        minRows={4}
        maxRows={4}
      />
    </Box>
  );
}
