import React from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import DiscordChannelIntegrationInstructionsDialog from '../IntegrationsInstructions/DiscordChannelIntegrationInstructionsDialog';

import CheckboxField from './CheckboxField';

const DiscordChannelField = ({ control, errors, field, index, register, setValue, disabled }) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  const discordChannelController = (field, index) => {
    return (
      <Controller
        id={field.id}
        name={`notificationSetup[${index}].config.discordChannelWebhookUrl`}
        control={control}
        defaultValue={field.config.discordChannelWebhookUrl}
        render={props => {
          const { onChange, ...otherProps } = props;

          return (
            <TextField
              label="Webhook url"
              disabled={disabled}
              variant="outlined"
              margin="dense"
              fullWidth
              multiline
              rows={4}
              error={Boolean(error?.config?.discordChannelWebhookUrl)}
              onChange={event => {
                const value = event.target.value;

                setValue(enabledFieldName, Boolean(value));

                onChange(event.target.value);
              }}
              {...otherProps}
              helperText={error?.config?.discordChannelWebhookUrl?.message}
            />
          );
        }}
      />
    );
  };

  return (
    <Grid xs={12} item style={{ margin: '5px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="DiscordChannel"
      />
      <CheckboxField
        name={`notificationSetup[${index}].enabled`}
        control={control}
        disabled={disabled}
        defaultValue={field.enabled}>
        <img alt="discord channel" src="/images/discord.svg" height={22} />
        <Box margin="0 10px" minWidth="20vw">
          <Typography>
            Send a notification to Discord Channel following the{' '}
            <DiscordChannelIntegrationInstructionsDialog
              field={discordChannelController(field, index)}
            />{' '}
            via
          </Typography>
        </Box>
        <Box minWidth="30vw">{discordChannelController(field, index)}</Box>
      </CheckboxField>
    </Grid>
  );
};

export default DiscordChannelField;
