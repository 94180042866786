import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { useFieldArray, useForm } from 'react-hook-form';

import { ButtonSimple } from 'componentsV4/Button';
import safeParseJSON from 'helpers/safeParseJSON';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

import DiscordChannelField from './Fields/DiscordChannelField';
import EmailField from './Fields/EmailField';
import GoogleChatField from './Fields/GoogleChatField';
import NameField from './Fields/NameField';
import SlackField from './Fields/SlackField';
import TeamsField from './Fields/TeamsField';
import TelegramField from './Fields/TelegramField';
import WebHookField from './Fields/WebHookField';
import WhatsappField from './Fields/WhatsappField';
import { createSchema, updateSchema } from './schemas';

const useStyles = makeStyles(theme => ({
  root: {},
  divider: {
    width: '105%',
    margin: `15px 0 0 -${theme.spacing(2)}px`
  },
  button: {
    marginTop: '8px',
    marginBottom: '-9px',
    marginLeft: '-6px'
  },
  hidden: {
    visibility: 'hidden'
  }
}));

const Fields = ({
  canEdit,
  canCreate,
  handleSave,
  initialValues,
  nameField,
  newTeam,
  numberOptions,
  teamId
}) => {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    errors,
    formState,
    register
  } = useForm({
    defaultValues: {
      ...initialValues,
      notificationSetup: initialValues.notificationSetup.map(item => {
        const { id, ...rest } = item;
        return { notificationSetupId: id, ...rest };
      })
    },
    resolver: zodResolver(nameField ? createSchema : updateSchema)
  });

  const { fields } = useFieldArray({
    control,
    name: 'notificationSetup'
  });

  const checkEmail = errors => {
    if (
      errors?.notificationSetup &&
      errors?.notificationSetup[0]?.config &&
      errors?.notificationSetup[0]?.config?.message === "Unrecognized key(s) in object: 'email'"
    ) {
      return {
        notificationSetup: [
          {
            config: {
              email: { message: 'Invalid e-mail' }
            }
          }
        ]
      };
    }
    return errors;
  };

  const onSubmit = data => {
    const notificationSetup = nameField
      ? initialValues.notificationSetup.map(item => {
          const configuredProvider = data.notificationSetup.find(
            provider => provider.providerName === item.providerName
          );

          if (configuredProvider) return configuredProvider;

          return item;
        })
      : data.notificationSetup;

    const formData = {
      ...data,
      notificationSetup: notificationSetup
        .map(item => {
          if (item.providerName !== 'Slack') {
            return item;
          }

          const slackIntegration = item.config.slackIntegration;

          return {
            ...item,
            config: {
              ...item.config,
              slackIntegration: safeParseJSON(slackIntegration)
            }
          };
        })
        .filter(Boolean)
    };

    handleSave(formData);
  };

  const canUseMailFeaturePlan = useFeaturePlan('NotifyChannels.Mail');
  const canUseTelegramFeaturePlan = useFeaturePlan('NotifyChannels.Telegram');
  const canUseSlackFeaturePlan = useFeaturePlan('NotifyChannels.Slack');
  const canUseTeamsFeaturePlan = useFeaturePlan('NotifyChannels.Teams');
  const canUseWhatsAppFeaturePlan = useFeaturePlan('NotifyChannels.WhatsApp');
  const canUseGoogleChatFeaturePlan = useFeaturePlan('NotifyChannels.GoogleChat');
  const canUseDiscordFeaturePlan = useFeaturePlan('NotifyChannels.Discord');

  const shouldUseWebhookNotificationProvider = useFeatureFlagUnleash(
    'useWebhookNotificationProvider',
    { queryString: true }
  );
  const shouldUseWebhookNotificationProviderWithRetry = useFeatureFlagUnleash(
    'useWebhookNotificationProviderWithRetry',
    { queryString: true }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {nameField && (
        <NameField
          control={control}
          errors={errors}
          disabled={!(newTeam ? canCreate : canEdit)}
          className={!nameField ? classes.hidden : null}
        />
      )}
      {fields.map((field, index) => {
        if (field.providerName === 'Mail' && canUseMailFeaturePlan) {
          return (
            <EmailField
              key={field.id}
              control={control}
              errors={checkEmail(errors)}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        if (field.providerName === 'Telegram' && canUseTelegramFeaturePlan) {
          return (
            <TelegramField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        if (field.providerName === 'Slack' && canUseSlackFeaturePlan) {
          return (
            <SlackField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              register={register}
              watch={watch}
              teamId={teamId}
              setValue={setValue}
            />
          );
        }
        if (field.providerName === 'Teams' && canUseTeamsFeaturePlan) {
          return (
            <TeamsField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        if (field.providerName === 'WhatsApp' && canUseWhatsAppFeaturePlan) {
          const numbers = watch(`notificationSetup[${index}].config.numbers`);

          return (
            <WhatsappField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              getValues={getValues}
              numbers={numbers}
              register={register}
              setValue={setValue}
              numberOptions={numberOptions}
              newTeam={newTeam}
            />
          );
        }
        if (field.providerName === 'GoogleChat' && canUseGoogleChatFeaturePlan) {
          return (
            <GoogleChatField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        if (field.providerName === 'DiscordChannel' && canUseDiscordFeaturePlan) {
          return (
            <DiscordChannelField
              key={field.id}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        if (
          shouldUseWebhookNotificationProvider &&
          field.providerName === 'WebHook' &&
          canUseDiscordFeaturePlan
        ) {
          return (
            <WebHookField
              key={field.id}
              withRetry={shouldUseWebhookNotificationProviderWithRetry}
              control={control}
              errors={errors}
              disabled={!(newTeam ? canCreate : canEdit)}
              field={field}
              index={index}
              setValue={setValue}
              register={register}
            />
          );
        }
        return null;
      })}
      {errors?.notificationSetup?.message && (
        <Typography color="error">{errors?.notificationSetup?.message}</Typography>
      )}
      {nameField ? <Divider className={classes.divider} /> : null}
      <ButtonSimple
        disableRipple
        color="primary"
        type="submit"
        variant="contained"
        disabled={!(formState.isDirty && (newTeam ? canCreate : canEdit))}
        style={!nameField ? { marginLeft: '9px' } : null}
        className={classes.button}
        text={'Save'}
      />
    </form>
  );
};

export default Fields;
