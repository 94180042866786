import React from 'react';

import validate from 'validate.js';

const initialFormState = {
  isValid: false,
  values: {},
  touched: {},
  errors: {}
};

const useFormState = ({ schema, initialValues }) => {
  const [formState, dispatch] = React.useReducer(
    (state, action) => {
      if (action.type === 'initialFetch') {
        const newValues = {
          ...state.values,
          ...action.payload
        };

        const errors = validate(newValues, schema);

        return {
          ...state,
          values: newValues,
          errors: errors || {},
          isValid: !errors
        };
      }

      if (action.type === 'change') {
        const newValues = {
          ...state.values,
          ...action.payload.values
        };

        const errors = validate(newValues, schema);

        return {
          ...state,
          values: newValues,
          errors: errors || {},
          touched: { ...state.touched, ...action.payload.touched },
          isValid: !errors
        };
      }

      throw new Error();
    },
    { ...initialFormState, values: { ...initialValues } }
  );

  const onChange = (name, value) => {
    dispatch({
      type: 'change',
      payload: { values: { [name]: value }, touched: { [name]: true } }
    });
  };

  return {
    formState,
    dispatch,
    onChange
  };
};

export default useFormState;
