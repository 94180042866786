import { useMutation } from 'urql';

const deleteProviderQuery = `#graphql
  mutation($id: Int!) {
    deleteProvider(id: $id)
  }
`;

export const useDeleteProvider = () => {
  const [{ fetching: isLoading }, deleteProvider] = useMutation(deleteProviderQuery);

  return { deleteProvider, isLoading };
};

export default useDeleteProvider;
