import { Resource } from 'types/external/Resource';

import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';

export function monitoringAdapter(data: Resource): MonitoringBaseForm & { method: string } {
  const { name, environmentId, interval, timeout, method, domain } = data;

  return {
    name,
    environmentId,
    interval,
    timeout,
    method,
    domainType: data.domainSettings.domainType ? data.domainSettings.domainType : 'userAndPass',
    domain
  };
}
