import MonitoringStatusV0 from 'constants/MonitoringStatusV0';

import { grey } from '@material-ui/core/colors';

import { Theme } from 'theme/v3';

const getColorForStatus = ({ status, theme }: { status: MonitoringStatusV0; theme: Theme }) => {
  if (status === MonitoringStatusV0.Up) {
    return theme.palette.primary.dark;
  }

  if (status === MonitoringStatusV0.Down) {
    return theme.palette.error.main;
  }

  if (status === MonitoringStatusV0.NotMonitoring) {
    return grey[400];
  }

  if (status === MonitoringStatusV0.Stale) {
    return grey[400];
  }

  return theme.palette.text.primary;
};

export default getColorForStatus;
