import { SetStateAction } from 'react';

import { SeverityStatusEnum } from 'types/external/Severity';
import { SourceEnum } from 'types/external/Source';
import { StatusEnum } from 'types/external/Status';

export const transformSourceQuery = (source: string, setSource: SetStateAction<any>) => {
  switch (source) {
    case 'integrations':
      return setSource('managed' as SourceEnum);
    case 'resources':
      return setSource('hit/fail' as SourceEnum);
    case 'hit/fail':
      return setSource('hit/fail' as SourceEnum);
    case 'managed':
      return setSource('managed' as SourceEnum);
    case 'All':
      return setSource('All' as SourceEnum);
    default:
      return setSource('all');
  }
};

export const transformStatusQuery = (status: string, setStatus: SetStateAction<any>) => {
  switch (status) {
    case 'alarmed':
      return setStatus('alarmed' as StatusEnum);
    case 'acknowledged':
      return setStatus('acknowledged' as StatusEnum);
    case 'resolved':
      return setStatus('resolved' as StatusEnum);
    case 'All':
      return setStatus('All' as StatusEnum);
    default:
      return setStatus('all');
  }
};

export const transformSeverityQuery = (severity: string, setSeverity: SetStateAction<any>) => {
  switch (severity) {
    case 'sev-1-critical':
    case 'sev1':
      return setSeverity('sev-1-critical' as SeverityStatusEnum);
    case 'sev-2-high':
    case 'sev2':
      return setSeverity('sev-2-high' as SeverityStatusEnum);
    case 'sev-3-moderate':
    case 'sev3':
      return setSeverity('sev-3-moderate' as SeverityStatusEnum);
    case 'sev-4-low':
    case 'sev4':
      return setSeverity('sev-4-low' as SeverityStatusEnum);
    case 'sev-5-informational':
    case 'sev5':
      return setSeverity('sev-5-informational' as SeverityStatusEnum);
    case 'not-classified':
      return setSeverity('not-classified' as SeverityStatusEnum);
    case 'All':
      return setSeverity('All' as SeverityStatusEnum);
    default:
      return setSeverity('all');
  }
};

export const transformPeriodQuery = (period: string, setPeriod: SetStateAction<any>) => {
  switch (period) {
    case 'all':
      return setPeriod('all');
    case '1':
      return setPeriod('1');
    case '7':
      return setPeriod('7');
    case '30':
      return setPeriod('30');
    case '0':
      return setPeriod('0');
    case '12':
      return setPeriod('0');
    default:
      return setPeriod('0');
  }
};

export const transformAlertPeriodQuery = (period: string, setPeriod: SetStateAction<any>) => {
  switch (period) {
    case 'All':
      return setPeriod('All');
    case '1':
      return setPeriod('1');
    case '7':
      return setPeriod('7');
    case '30':
      return setPeriod('30');
    case '12':
      return setPeriod('0');
    default:
      return setPeriod('0');
  }
};
