import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

type StyleProps = {
  isDragging: boolean;
};

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  avatar: {
    height: '96px',
    width: '96px',
    marginBottom: theme.spacing(1)
  },
  dragAndDropBox: {
    width: 552,
    height: 200,
    borderStyle: 'dashed',
    borderColor: theme.palette.gray[500],
    backgroundColor: props => ((props as StyleProps).isDragging ? theme.palette.green['100'] : ''),
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,

    '& > *': {
      fontSize: 12
    },
    '& > img': {
      width: 190,
      height: 158
    },
    '& p:first-child': {
      color: theme.palette.green['500'],
      fontWeight: 'bold'
    },
    '& button:last-child': {
      fontSize: 12,
      fontWeight: 'bold',
      width: 98,
      paddingLeft: 0,
      paddingRight: 0,
      height: 26
    }
  },

  'input-file': {
    display: 'block',
    border: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    zIndex: props => ((props as StyleProps).isDragging ? 1 : 0)
  },
  smallBox: {
    width: 24,
    height: 24,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.green[500],
    '& > svg': {
      width: 14,
      Height: 14,
      color: 'white'
    }
  }
}));
