import actions from '../../actions';

const fetch = (state, action) => {
  if (actions.FETCH_SERVICE_SUCCESSFUL === action.type) {
    const stateEntity = state.services_edit;
    const clouds = state.clouds;
    return {
      ...state,
      services_edit: {
        ...stateEntity,
        values: {
          ...stateEntity.values,
          ...action.payload.service
        }
      },
      clouds: {
        ...clouds,
        data: action.payload.clouds
      }
    };
  }

  return state;
};

export default fetch;
