import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ApplicationViewV0 from './ApplicationViewV0';

const Application = () => {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return <ApplicationViewV0 />;
};

export default Application;
