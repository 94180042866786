import SvgIcon from '@material-ui/core/SvgIcon';

export default function IncidentAlarmed({ style = {}, ...otherProps }) {
  return (
    <SvgIcon viewBox="0 0 18 18" style={{ fontSize: 18, ...style }} {...otherProps}>
      <path
        d="M12.75 14.415H3.75V5.25H9V3.75H3.75C2.925 3.75 2.25 4.425 2.25 5.25V14.25C2.25 15.075 2.925 15.75 3.75 15.75H12.75C13.575 15.75 14.25 15.075 14.25 14.25V9H12.75V14.415Z"
        fill="#F2C94C"
      />
      <path
        d="M14.25 1.5H12.75V3.75H10.5C10.5075 3.7575 10.5 5.25 10.5 5.25H12.75V7.4925C12.7575 7.5 14.25 7.4925 14.25 7.4925V5.25H16.5V3.75H14.25V1.5Z"
        fill="#F2C94C"
      />
      <path d="M11.25 6.75H5.25V8.25H11.25V6.75Z" fill="#F2C94C" />
      <path d="M5.25 9V10.5H11.25V9H9H5.25Z" fill="#F2C94C" />
      <path d="M11.25 11.25H5.25V12.75H11.25V11.25Z" fill="#F2C94C" />
    </SvgIcon>
  );
}
