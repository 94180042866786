import { ComponentProps, PropsWithChildren, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { Application, PartialMatrixQuery, useNewJourneyMatrix } from 'hooks/queriesGraphQL/useNewJourneyMatrix';

import DownsLineComponent from '../DownsLineComponent';
import StatusColors from '../StatusColors';

function checkArray (value: any, array: any[], key: string): boolean{
  const result = array.filter(item => item[key] === value);
  return !!result.length;
}

const WrappedDownsLineComponent = ({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof DownsLineComponent>>) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <DownsLineComponent {...props} />
  </Box>
);

const StatusLine = ({ productId }: { productId: number }) => {
  const [applications, setApplications] = useState<{
    uid: Application["uid"]
    name: Application["entityName"]
    status: Application["status"]
    entityUid: Application["entityUid"]
  }[]>([]);

  const [{ data: productData }] = useNewJourneyMatrix({
    productId,
    query: PartialMatrixQuery
  });

  useEffect(() => {
    const apps = [] as any;
    productData?.newJourneyMatrix?.applications.forEach((app) => {
      const appJustExist = checkArray(app.entityUid, apps, "entityUid");
      
      if(!appJustExist) {
          apps.push({
            name: app.entityName,
            uid: app.uid,
            status: app.status,
            entityUid: app.entityUid
          })
      }

      app.dependencies.forEach(dep => {
        const depJustExist = checkArray(dep.entityUid, apps, "entityUid");

        if(!depJustExist) {
          apps.push({
            name: dep.entityName,
            uid: dep.applicationUid,
            status: dep.status,
            entityUid: dep.entityUid
          });
        }
      })
      setApplications(apps)
    });
  }, [productData?.newJourneyMatrix?.applications]);



  const hasMonitoring = applications?.find(app => {
    if (app.status !== 'off' && app.status !== 'pending') {
      return app;
    }
    return null;
  });

  if (!hasMonitoring) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.none}
        text={`No monitored resources`}
        link={null}
      />
    );
  }

  const hasAppDown = applications?.filter(app => {
    if (app.status === 'unavailable' || app.status === 'error') {
      return app;
    }
    return null;
  });

  if (hasAppDown && hasAppDown.length === 1) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.down}
        text={`${hasAppDown[0].name} is not operational`}
        link={`/applications/${hasAppDown[0].uid}`}
      />
    );
  }

  if (hasAppDown && hasAppDown.length > 1) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.down}
        text={`${hasAppDown.length} resources are not operational`}
        link={null}
      />
    );
  }

  const hasAppInMaintence = applications?.filter(app => {
    if (app.status === 'maintenance') {
      return app;
    }
    return null;
  });

  if (hasAppInMaintence && hasAppInMaintence.length === 1) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.maintenance}
        text={`${hasAppInMaintence[0].name} is in maintenance`}
        link={`/applications/${hasAppInMaintence[0].uid}`}
      />
    );
  }

  if (hasAppInMaintence && hasAppInMaintence.length > 1) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.maintenance}
        text={`${hasAppInMaintence.length} resources are in maintenance`}
        link={null}
      />
    );
  }

  return <WrappedDownsLineComponent status={StatusColors.up} text={`Operational`} link={null} />;
};

export default StatusLine;
