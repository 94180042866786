import { Box } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';
import { FormProps } from 'views/ServicesHub/forms';

export function BaseForm({ form }: FormProps) {
  const { register } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input label="Agent Name" name="agentName" inputRef={register} />

      <Input label="Tag" name="tag" inputRef={register} />
    </Box>
  );
}
