import Breadcrumb from 'components/Breadcrumb';
import { Title } from 'components/Title';

import { AccountTabs } from './Tabs';

export const Account = () => {
  return (
    <>
      <Breadcrumb items={[{ label: 'Profile' }, { label: 'Profile Settings', link: '/profile' }]} />
      <Title title="Profile Settings" />
      <AccountTabs />
    </>
  );
};
