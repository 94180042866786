import ApplicationType from 'constants/ApplicationType';

import { push } from 'connected-react-router';
import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareSaveEntity(axios, action) {
  try {
    const productId = action.params.productId;
    const {
      meta: { url },
      payload: {
        healthcheckUrl,
        method,
        post_body,
        post_body_type,
        headers,
        timeout,
        external,
        check_interval,
        failuresToIncident,
        validationString,
        envs,
        ...otherValues
      }
    } = action;

    const axiosCall = data =>
      otherValues.id
        ? axios.put(`/products/${productId}/applications/${otherValues.id}`, data)
        : axios.post(`/products/${productId}/applications`, data);

    const application = yield axiosCall({
      ...otherValues,
      applicationsSettings: {
        healthcheckUrl: healthcheckUrl.trim(),
        method,
        post_body,
        post_body_type,
        headers,
        timeout,
        external,
        type: external ? ApplicationType.ExternalWithAddons.type : ApplicationType.Internal.type,
        check_interval,
        failuresToIncident,
        validationString,
        envs
      }
    });

    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Entity created successfully'
    });

    if (typeof url === 'function') {
      yield put(push(url(application)));
    } else {
      yield put(push(url));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchApplicationsSave(axios) {
  yield takeLatest(actions.PRODUCT_AVAILABILITY_APPLICATIONS_SAVE, prepareSaveEntity, axios);
}
