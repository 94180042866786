import React from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import CheckboxField from './CheckboxField';

const EmailField = ({ control, errors, field, index, register, setValue, disabled }) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  return (
    <Grid xs={12} item style={{ margin: '7px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="Mail"
      />
      <CheckboxField
        name={enabledFieldName}
        defaultValue={field.enabled}
        control={control}
        disabled={disabled}>
        <img alt="email" src="/images/mail.svg" height={22} />
        <Box margin="0 10px" minWidth="10vw">
          <Typography>Send a notification to the</Typography>
        </Box>
        <Controller
          name={`notificationSetup[${index}].config.email`}
          control={control}
          defaultValue={field.config.email}
          render={props => {
            const { onChange, ...otherProps } = props;

            return (
              <TextField
                label="Email group"
                disabled={disabled}
                variant="outlined"
                margin="dense"
                error={Boolean(error?.config?.email)}
                helperText={
                  error?.config?.email?.message === 'Invalid input'
                    ? 'Invalid email address'
                    : error?.config?.email?.message
                }
                style={{ minWidth: '15vw' }}
                onChange={event => {
                  const value = event.target.value;

                  setValue(enabledFieldName, Boolean(value));

                  onChange(event.target.value);
                }}
                {...otherProps}
              />
            );
          }}
        />
      </CheckboxField>
    </Grid>
  );
};

export default EmailField;
