import React from 'react';

import { Box, Typography } from '@material-ui/core';

const Message = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight={100}>
      <Typography gutterBottom>{children}</Typography>
    </Box>
  );
};

export default Message;
