import Dialog from '@material-ui/core/Dialog';

const IntegrationDialog = ({
  open,
  onClose,
  formComponent
}: {
  open: boolean;
  onClose: () => void;
  formComponent: JSX.Element;
}) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleCancel} aria-labelledby="provider-dialog" maxWidth="sm" open={open}>
      {formComponent}
    </Dialog>
  );
};

export default IntegrationDialog;
