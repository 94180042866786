import { useQuery } from 'react-query';

import axios from 'redux/axios';

const useGetInitialData = ({ teamId, memberId, onSuccess, enabled }) => {
  useQuery(
    ['users', memberId, 'account'],
    () => axios.get(`/teams/${teamId}/members/${memberId}`).then(response => response.data),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      enabled
    }
  );
};

export default useGetInitialData;
