import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles<Theme>(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(1)
  },
  iconFooter: {
    color: palette.primary.main,
    marginRight: spacing(1)
  },
  textFooter: {
    fontSize: 14,
    color: palette.primary.main,
    marginRight: spacing(1),
    textTransform: 'capitalize'
  },
  acknowledgeButton: {
    backgroundColor: palette.info.main,
    color: 'white',
    '&:hover': {
      backgroundColor: palette.info.dark
    }
  }
}));
