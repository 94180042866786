import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab-old';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation as useMutationGraphql } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';
import { useAccountList } from 'hooks/queriesGraphQL/useAccountList';
import { useRoles } from 'hooks/queriesGraphQL/useRoles';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import reducer from 'redux/reducer/members';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';
import useGetTeamMembers from 'views/Teams/hooks/useGetTeamMembers';

import DataGrid from '../../components/DataGrid';

import FormAddUser from './FormAddUser';
import schema from './schema';
import { useStyles } from './styles';

const CreateMember = `#graphql
  mutation(
    $name: String!
    $teamId: Float!
    $userId: Float!
    $userUid: String!
    $roleId: Float! 
    $isActive: Boolean!
  ){
    createMember(
      name: $name 
      teamId: $teamId 
      userId: $userId
      userUid: $userUid
      roleId: $roleId 
      isActive: $isActive
    ){
      id
      teamId
      userId
    }
  }
`;

const List = ({ cols, entity, disabled, notificationSetup }) => {
  const dispatch = useDispatch();
  const { members_new } = useSelector(({ members_new }) => ({ members_new }));
  const { id: teamId } = useParams();

  const useAddMemberButton = useFeatureFlagUnleash('addMemberButton', {
    queryString: true
  });

  const getTeamMembers = `#graphql
    query($teamId: Int!) {
      getTeamMembersV2(teamId: $teamId) {
        teamId
        id
        role{
          name
        }
        user{
          displayName
          email
          photoURL
          profile {
            phone
            verifiedPhone
          }
        }
      }
    }
  `;

  const DeleteMember = `#graphql 
    mutation($memberId: Int!){
      deleteMemberV2(memberId: $memberId)
    }
  `;

  const [resultQuery, reexecuteQuery] = useGetTeamMembers({
    getTeamMembers,
    teamId: Number(teamId)
  });

  const [, deleteMemberMutation] = useMutationGraphql(DeleteMember);

  const [, createMemberMutation] = useMutationGraphql(CreateMember);

  const onCreateMember = async data => {
    await createMemberMutation({
      name: '',
      teamId: Number(teamId),
      userId: Number(data.userId),
      userUid: data.userUid,
      roleId: Number(data.roleId),
      isActive: true
    })
      .then(() => {
        reexecuteQuery();
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const onDeleteMember = async ({ id }) => {
    await deleteMemberMutation({ memberId: Number(id) })
      .then(() => {
        reexecuteQuery();
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const graphqlResult = () => {
    if (resultQuery.data && resultQuery.data.getTeamMembersV2) {
      return resultQuery.data.getTeamMembersV2;
    }

    return [];
  };

  const graphqlData = graphqlResult();

  const fetch = React.useCallback(() => {
    dispatch({
      type: actions.MEMBERS_FETCH
    });
  }, [dispatch]);

  const onChangeAutocomplete = React.useCallback(
    payload => {
      dispatch({
        type: actions.MEMBERS_AUTOCOMPLETE,
        payload,
        meta: { reducer }
      });
    },
    [dispatch]
  );

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = React.useState(null);
  const [role, setRole] = React.useState('');

  const accounts = useAccountList({
    page: 0,
    perPage: 500,
    query: '',
    aggregationRefs: 'accounts',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true'
  });

  const roles = useRoles({});

  const userOptions = accounts.data.accounts.map(account => {
    return {
      id: account.id,
      name: account.name,
      email: account.email,
      userId: account.userId,
      userUid: account.userUid
    };
  });

  const roleOptions = roles.data.map(role => {
    return {
      label: role.name,
      value: String(role.id)
    };
  });

  const { control, register } = useForm({
    defaultValues: {
      name: '',
      roleId: '',
      userId: 0,
      userUid: ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const canSave = user && role;

  const handleAddMember = () => {
    onCreateMember({
      name: user?.name,
      teamId: Number(teamId),
      userId: user?.userId,
      userUid: user?.userUid,
      roleId: role,
      isActive: true
    });
    setRole(null);
    setUser(null);
  };

  return (
    <>
      {!useAddMemberButton ? (
        <FormAddUser
          members={members_new}
          disabled={disabled}
          onAddMember={onCreateMember}
          onChangeAutocomplete={onChangeAutocomplete}
        />
      ) : (
        <Grid container spacing={2} display="flex">
          <Grid md={4} sm={4} xs={12} item>
            <Controller
              name="name"
              control={control}
              render={() => (
                <Autocomplete
                  value={user}
                  onChange={(event, value) => setUser(value)}
                  disabled={disabled}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  getOptionSelected={(option, value) => option.email === value.email}
                  getOptionLabel={option => (option ? option.name : '')}
                  options={userOptions}
                  className={classes.select}
                  renderOption={option => (
                    <>
                      {option.name}
                      <small className={classes.helpertext}>{option.email}</small>
                    </>
                  )}
                  renderInput={params => (
                    <Input label="Name" name="name" {...params} inputRef={register} />
                  )}
                />
              )}
            />
          </Grid>
          <Grid sm={2} xs={12} item>
            <FormControl fullWidth>
              <Select
                value={role}
                options={roleOptions}
                name="role"
                label="Role"
                required={true}
                control={control}
                onChange={event => setRole(event.target.value)}
                disabled={disabled}
              />
            </FormControl>
          </Grid>

          <Grid sm={1} xs={12} item display="flex">
            <ButtonSimple
              fullWidth
              name="add-button"
              color="primary"
              variant="contained"
              disabled={!canSave}
              onClick={handleAddMember}
              text="ADD"
            />
          </Grid>
        </Grid>
      )}

      <DataGrid
        cols={cols}
        entity={entity}
        graphqlData={graphqlData}
        removeHandler={onDeleteMember}
        notificationSetup={notificationSetup}
      />
    </>
  );
};

export default List;
