import { Skeleton } from '@material-ui/lab-old';
import { formatDistanceStrict } from 'date-fns';
import { TableColumn } from 'react-data-table-component';
import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { FullDependency } from 'types/external/Dependency';

import formatSecondsInWords from 'helpers/formatSecondsInWords';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { StatusChip } from '../../../List/components/StatusChip';

const previousDayDate = new Date();
previousDayDate.setDate(previousDayDate.getDate() - 1);

export function useDependenciesListColumns() {
  const useSyntheticMtts = useFeatureFlagUnleash('useSyntheticMtts', {
    queryString: true
  });

  const columns: TableColumn<Partial<FullDependency>>[] = [
    {
      name: 'Status',
      selector: row => row?.main?.status || '',
      cell: row =>
        row?.main?.status && row?.entity ? (
          <StatusChip status={row?.main?.status} entity={row?.entity} />
        ) : (
          <Skeleton height={40} animation="wave" width={100} />
        ),
      width: '190px',
      grow: 1.5
    },
    {
      name: 'Monitoring name',
      selector: row => row.entityName || '',
      cell: row => {
        return row.entityName ? (
          row.entityName
        ) : (
          <Skeleton height={40} animation="wave" width={100} />
        );
      },
      grow: 3
    },

    {
      name: 'Last Check',
      selector: row => row?.main?.lastCheck || '',
      cell: row => {
        if (row.entity === ApplicationManagerEntityType.Multiflow) {
          return 'No data';
        }

        if (!row?.main?.lastCheck) {
          return <Skeleton height={40} animation="wave" width={70} />;
        }

        const lastCheck =
          new Date(row.main.lastCheck).getTime() > previousDayDate.getTime()
            ? formatDistanceStrict(new Date(row.main.lastCheck), new Date())
            : 'No data';

        return lastCheck;
      }
    },
    {
      name: 'MTBF',
      selector: row => row?.main?.mtts?.mtbf || '',
      cell: row => {
        if (row.entity === ApplicationManagerEntityType.Multiflow && !useSyntheticMtts) {
          return 'No data';
        }

        if (!row?.main?.mtts) {
          return <Skeleton height={40} animation="wave" width={70} />;
        }

        return formatSecondsInWords(row.main.mtts.mtbf || 0);
      }
    },
    {
      name: 'MTTA',
      selector: row => row?.main?.mtts?.mtta || '',
      cell: row => {
        if (row.entity === ApplicationManagerEntityType.Multiflow && !useSyntheticMtts) {
          return 'No data';
        }

        if (!row?.main?.mtts) {
          return <Skeleton height={40} animation="wave" width={70} />;
        }

        return formatSecondsInWords(row.main.mtts.mtta || 0);
      }
    },
    {
      name: 'MTTRecovery',
      selector: row => row?.main?.mtts?.mttr || '',
      cell: row => {
        if (row.entity === ApplicationManagerEntityType.Multiflow && !useSyntheticMtts) {
          return 'No data';
        }

        if (!row?.main?.mtts) {
          return <Skeleton height={40} animation="wave" width={70} />;
        }

        return formatSecondsInWords(row.main.mtts.mttr || 0);
      }
    },
    {
      name: 'Uptime',
      selector: row => row?.main?.uptime?.online1Hour || '',
      cell: row => {
        if (row.entity === ApplicationManagerEntityType.Multiflow) {
          return 'No data';
        }

        if (!row?.main?.uptime) {
          return <Skeleton height={40} animation="wave" width={70} />;
        }

        if (!row?.main?.uptime?.online1Hour && row?.main?.uptime?.online1Hour !== 0) {
          return 'No data';
        }

        return `${row?.main?.uptime?.online1Hour}%`;
      }
    }
  ];

  return columns;
}
