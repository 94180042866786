import { useQuery } from 'urql';

const GetDependency = `#graphql
  query($id: Int!) {
    dependencyWithOrgUid(id: $id) {
    	id
      applicationId
      sterasId
      serviceId
      addonSettings {
        domain
        method
        post_body_type
        post_body
        headers
        validationString
        timeout
        environmentId
        check_interval
        envs
        skip_ssl_validation
        queue_size
        domain_settings
        failuresToIncident
        openIncidentWithSeverity
      }
      service {
        id
        name
        method
        check_type
      }
      applicationDependency {
        id
        name
        uid
        orgUid
        sterasId
        environmentId
        resourceUid
      }
    }
  }
`;

type Dependency = {
  id: number;
  applicationId: number;
  serviceId: number;
  addonSettings: AddonSettings;
  service: Service;
  applicationDependency?: ApplicationDependency;
};

type ApplicationDependency = {
  id?: number;
  name?: string;
  uid?: string;
  orgUid?: string;
  sterasId?: number;
  environmentId?: number;
  resourceUid?: string;
};

type AddonSettings = {
  domain?: string;
  method?: string;
  post_body_type?: string;
  post_body?: string;
  headers?: string;
  validationString?: string;
  timeout?: number;
  environmentId?: number;
  check_interval?: number;
  envs?: object;
  skip_ssl_validation?: boolean;
  queue_size?: number;
  domain_settings?: string;
  failuresToIncident?: number;
  openIncidentWithSeverity?: string;
};

type Service = {
  id: number;
  name: string;
  method: string;
  check_type: string;
};

type Variables = {
  id: number;
};

const useNewGetDependencies = ({
  addonId,
  executeQuery,
  personalizedQuery
}: {
  addonId: number;
  executeQuery: boolean;
  personalizedQuery?: string;
}) => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<
    { dependencyWithOrgUid: Dependency },
    Variables
  >({
    query: personalizedQuery ? personalizedQuery : GetDependency,
    variables: { id: Number(addonId) },
    pause: !addonId || !executeQuery,
    requestPolicy: 'network-only'
  });

  const result = data?.dependencyWithOrgUid || {};

  return { result, fetching, reexecuteQuery };
};

export default useNewGetDependencies;
