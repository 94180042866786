import { useQuery } from 'urql';

const useGetTeamMembers = ({
  getTeamMembers,
  teamId,
  pause
}: {
  getTeamMembers: string;
  teamId: number;
  pause: boolean;
}) => {
  return useQuery({
    query: getTeamMembers,
    pause: pause || !teamId,
    variables: { teamId }
  });
};

export default useGetTeamMembers;
