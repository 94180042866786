import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import actions from 'redux/actions';
import axios from 'redux/axios';

import { types } from './types';

const useMutation1 = ({ startValues, statusPageComponents }) => {
  const isEditing = Boolean(startValues);

  const history = useHistory();

  const queryCache = useQueryCache();

  const dispatch = useDispatch();

  const [save, { isLoading }] = useMutation(
    data => {
      if (localStorage.getItem('file')) {
        data.cover = {
          file: localStorage.getItem('file'),
          contentType: localStorage.getItem('contentType'),
          fileName: localStorage.getItem('name')
        };

        localStorage.removeItem('file');
        localStorage.removeItem('contentType');
        localStorage.removeItem('name');
      }

      const groups = statusPageComponents.map(group => ({
        ...group,
        services: group.services.map(({ id, ...service }) => {
          // TODO: refactor payload and components state
          const syntheticUid = service.synthetic_uid || service.syntheticUid;
          const resourceUid = service.resource_uid || service.resourceUid;
          const useResource = service.use_resource || service.useResource;

          const type = (() => {
            if (syntheticUid) return 'synthetic';
            if (service.type === types.external) return 'external';
            return 'resource';
          })();

          return {
            ...service,
            steras_id: type === 'resource' ? Number(service.monitoringId) : null,
            type,
            resourceUid: resourceUid || undefined,
            syntheticUid: syntheticUid || undefined,
            useResource: useResource || undefined
          };
        })
      }));

      const payload = {
        id: startValues?.id,
        slug: data.slug,
        name: data.name,
        isPrivate: data.isPrivate,
        passphrase: data.passphrase,
        subtitle: data.subtitle,
        enableSubscription: data?.enableSubscription,
        components: groups.map(group => ({
          ...group,
          services: group.services
            .filter(service => service.type === 'resource')
            .map(service => ({ ...service, id: service.steras_id }))
        })),
        groups: groups,
        cover: data.cover
      };

      if (isEditing) {
        return axios
          .put('/statuspage', { ...payload, uid: startValues?.uid })
          .then(response => response.data);
      }

      return axios.post(`/statuspage`, payload).then(response => response.data);
    },
    {
      onSuccess: async data => {
        await queryCache.invalidateQueries(['statuspage', Number(data.id)]);
        history.push(`/statuspages/`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Saved successfully'
        });
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  return {
    save,
    isLoading
  };
};

// FIXME: identifier "useMutation" has already been declared or isn't helpful, rename this please!
export default useMutation1;
