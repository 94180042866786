import { useMemo, useCallback, useEffect } from 'react';

import agentStatus from 'constants/agentStatus';

import { withStyles, makeStyles, Tooltip, Box } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useHistory, useLocation } from 'react-router-dom';

import useQueryParams from 'helpers/useQueryParams';
import palette from 'theme/v3/palette';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ spacing, palette }) => ({
  tabContainer: {
    marginLeft: spacing(4),
    marginRight: spacing(4),
    position: 'relative'
  },
  tooltipIcon: {
    position: 'absolute',
    top: 1,
    left: 54 + spacing(1),
    color: palette.warning.main,
    fontSize: '0.9rem !important',
    height: 'fit-content'
  }
}));

const StyledTabs = withStyles((theme, props) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 54,
      width: '100%',
      height: '5px',
      borderRadius: '4px'
    },
    marginBottom: 40
  },
  root: {
    width: 'fit-content',
    minHeight: 33,
    '& span': {
      alignItems: 'normal',
      textAlign: 'left',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 700,
      transition: 'none'
    }
  }
}))(props => <Tabs {...props} scrollButtons="off" TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    width: 'fit-content',
    minWidth: 'fit-content',
    minHeight: 33,
    textTransform: 'upercase',
    color: theme.palette.text.secondary,
    padding: 0,
    opacity: '0.5',
    pointerEvents: props => (props.canClick ? 'auto' : 'none'),
    '&+&': {
      marginLeft: theme.spacing(4)
    },
    '& > span': {
      marginTop: 17
    },
    '&:focus': {
      opacity: 1
    },
    '&::before': {
      content: `' '`,
      width: '54px',
      height: '5px',
      position: 'absolute',
      top: '6px',
      left: 0,
      background: props => (props.statusColor ? props.statusColor : theme.palette.primary.main),
      borderRadius: '4px'
    }
  }
}))(({ canClick, ...props }) => {
  const classes = useStyles();

  if (
    (props.isAgent && props.status === 'INSTALLING') ||
    (props.isAgent && props.status === 'CREATED')
  ) {
    return (
      <Box className={classes.tabContainer}>
        <Tab disableRipple {...props} />
        <Tooltip title="The environment Development agent is being installed in your cloud. When this process is complete, this icon will turn green and you will be notified.">
          <InfoOutlinedIcon className={classes.tooltipIcon} />
        </Tooltip>
      </Box>
    );
  }

  return <Tab disableRipple {...props} />;
});

const getStatusColor = status => {
  const success = status === agentStatus.Running;
  const pending = status === agentStatus.Created || status === agentStatus.Installing;
  const error = status === agentStatus.Error;

  const colors = {
    success: palette.success.main,
    pending: palette.warning.main,
    error: palette.error.main
  };

  if (success) return colors.success;
  if (pending) return colors.pending;
  if (error) return colors.error;

  return colors.success;
};

const TabComponent = ({
  tabsList = [],
  onChange,
  tabIndex = 0,
  config: { navigation = true, queryString = false } = {}
}) => {
  const queryParams = useQueryParams();

  const history = useHistory();
  const location = useLocation();

  const getQueryParam = useCallback(() => {
    if (!queryString) {
      return 0;
    }

    const tabSlugInUrl = queryParams.get('tab');

    const tabIndexRequestedInUrl = tabsList.findIndex(tab => tab.slug === tabSlugInUrl);

    const initialState = tabSlugInUrl
      ? tabIndexRequestedInUrl >= 0
        ? tabIndexRequestedInUrl
        : 0
      : 0;

    return initialState;
  }, [queryString, queryParams, tabsList]);

  // Sets tab index from query string on first render
  useEffect(() => {
    if (queryString) {
      onChange(getQueryParam());
    }
  }, [queryString, getQueryParam, onChange]);

  // Sets tab index from query string when url changes
  useEffect(() => {
    if (!queryString) return;

    if (queryParams.has('tab')) {
      onChange(getQueryParam());
    }
  }, [location.search, queryParams, getQueryParam, queryString, onChange]);

  const handleChange = (event, newValue) => {
    // Clear the query string
    if (queryParams.has('tab')) {
      history.replace({
        search: ''
      });
    }

    onChange(newValue);
  };

  const tabs = useMemo(
    () =>
      tabsList.map((tab, index) => {
        return tab?.agent ? (
          <StyledTab
            key={tab.slug}
            canClick={navigation}
            label={`${index + 1}. ${tab.label}`}
            isAgent={'agent' in tab}
            status={tab.agent.status}
            statusColor={getStatusColor(tab.agent.status)}
          />
        ) : (
          <StyledTab key={tab.slug} canClick={navigation} label={`${index + 1}. ${tab.label}`} />
        );
      }),
    [navigation, tabsList]
  );

  return (
    <>
      <div>
        <StyledTabs value={tabIndex} onChange={handleChange} aria-label="styled tabs example">
          {tabs}
        </StyledTabs>
      </div>
    </>
  );
};

export default withThemeV3Hoc(TabComponent);
