import { ButtonProps as ButtonPropsMUI, CircularProgress } from '@material-ui/core';

import SimpleButton from './SimpleButton';

type ButtonProps = ButtonPropsMUI & {
  text?: string;
  isLoading?: boolean;
  border?: boolean;
};

export const ButtonSimple = ({
  text,
  disabled = false,
  onClick = () => {},
  isLoading = false,
  border = true,
  ...props
}: ButtonProps) => {
  return (
    <SimpleButton border={border} onClick={onClick} disabled={disabled || isLoading} {...props}>
      {isLoading ? <CircularProgress color="primary" size={32} /> : text}
    </SimpleButton>
  );
};

export default ButtonSimple;
