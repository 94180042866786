import { useState } from 'react';

import { Box, makeStyles, Typography, Grid } from '@material-ui/core';

import RadioGroup from 'componentsV3/RadioGroup';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

import EnvironmentForm from '../../View/Environment/EnvironmentForm';
import { useEnvironmentsQuery } from '../Application/ApplicationForm/EnvironmentSelect';

import ExistingEnvironmentForm from './ExistingEnvironmentForm';

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(4)
  }
}));

enum Mode {
  NewEnvironment,
  ExistingEnvironment
}

const EnvironmentTab = ({
  cloudId = null,
  onFinish,
  applicationType
}: {
  cloudId?: number | null;
  onFinish: (environment: any) => void;
  applicationType: string;
}) => {
  const classes = useStyles();

  const newApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [result] = useEnvironmentsQuery(cloudId, newApplications);

  const shouldShowModeToggle = Boolean(result.data?.environments.data.length);

  const [mode, setMode] = useState<Mode>(Mode.NewEnvironment);

  const firstAccess = useQueryParams().has('firstAccess');

  const existingEnvironmentForm = (
    <Grid item xs={12} className={classes.row}>
      <ExistingEnvironmentForm
        onEnvironmentSelected={(environmentId: number) => {
          onFinish(
            result.data?.environments.data.find(environment => environment.id === environmentId)
          );
        }}
        cloudId={cloudId}
        applicationType={applicationType}
      />
    </Grid>
  );

  const newEnvironmentForm = (
    <Grid item xs={12} className={classes.row}>
      <EnvironmentForm
        cloudId={cloudId}
        onEnvironmentCreated={onFinish}
        onSubmit={() => {
          const eventsMap: { [key: string]: string } = {
            internalMultiHttp: firstAccess ? 'fa_validate_env_multi' : 'validate_env_multi'
          };

          if (eventsMap[applicationType]) {
            analyticsEvent(eventsMap[applicationType]);
          }
        }}
      />
    </Grid>
  );

  const forms = (
    <>
      <Box marginTop={4}>
        <Typography variant="h6">Do you want to set up a new environment?</Typography>

        <RadioGroup
          value={mode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            const eventsMap: { [key: string]: { [key: string]: string } } = {
              internalMultiHttp: {
                [Mode.NewEnvironment]: firstAccess ? 'fa_new_env_multi' : 'new_env_multi',
                [Mode.ExistingEnvironment]: firstAccess
                  ? 'fa_existing_env_multi '
                  : 'existing_env_multi '
              },
              internal: {
                [Mode.NewEnvironment]: firstAccess
                  ? 'fa_new_iaea_create_new_env'
                  : 'new_iaea_create_new_env',
                [Mode.ExistingEnvironment]: firstAccess
                  ? 'fa_new_iaea_use_existing_env'
                  : 'new_iaea_use_existing_env'
              },
              externalWithAddons: {
                [Mode.NewEnvironment]: firstAccess
                  ? 'fa_new_eaea_create_new_env'
                  : 'new_eaea_create_new_env',
                [Mode.ExistingEnvironment]: firstAccess
                  ? 'fa_new_eaea_use_existing_env'
                  : 'new_eaea_use_existing_env'
              }
            };

            if (eventsMap[applicationType]) {
              analyticsEvent(eventsMap[applicationType][value]);
            }

            setMode(Number(value));
          }}
          options={[
            { value: Mode.NewEnvironment, label: 'New environment' },
            { value: Mode.ExistingEnvironment, label: 'Existent environment' }
          ]}
        />
      </Box>

      {mode === Mode.ExistingEnvironment && existingEnvironmentForm}

      {mode === Mode.NewEnvironment && newEnvironmentForm}
    </>
  );

  const renderForms = shouldShowModeToggle ? forms : newEnvironmentForm;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.row}>
        <Typography variant="h3" gutterBottom>
          Configure environment
        </Typography>

        <Typography>
          This is the environment inside your cloud were the monitoring will happen.
        </Typography>

        {result.fetching ? null : renderForms}
      </Grid>
    </Grid>
  );
};

export default withThemeV3Hoc(EnvironmentTab);
