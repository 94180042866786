import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>(({ palette, spacing, typography }: Theme) => ({
  input: {
    '&[data-visible=false]': {
      display: 'none'
    },
    '& svg': {
      // end-adornment
      marginRight: spacing(1)
    }
  }
}));

export default useStyles;
