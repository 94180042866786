const notificationInitialValues = {
  enabledEmail: false,
  enabledSlack: false,
  enabledTelegram: false,
  enabledTeams: false,
  enabledWhatsapp: false,
  enabledGoogleChat: false,
  emailGroup: '',
  slackWorkspace: '',
  slackChannel: '',
  telegramId: '',
  slackIntegration: null,
  teamsWebhook: '',
  whatsappNumbers: [],
  googleChatWebhook: '',
  webhookUrl: '',
  webhookRetryFailEmail: '',
  webhookRetry: '0'
};

export default notificationInitialValues;
