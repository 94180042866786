import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as z from 'zod';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import useMutationPersonalised from './useMutationPersonalised';

export const timeUnits = {
  minutes: {
    label: 'minute(s)',
    value: 'minutes'
  },
  hours: {
    label: 'hour(s)',
    value: 'hours'
  }
};

export const defaultValues = {
  id: null,
  teamMemberToContact: null,
  contactMethod: 'SMS',
  interval: 2,
  intervalTimeUnit: timeUnits.minutes.value,
  timeout: 15,
  timeoutTimeUnit: timeUnits.minutes.value,
  userUid: null
};

const schema = z.object({
  stepsGroupName: z.string().nonempty({ message: 'Required' }),
  steps: z.array(
    z
      .object({
        teamMemberToContact: z.union([z.string().nonempty({ message: 'Required' }), z.number()]),
        contactMethod: z.string().nonempty({ message: 'Required' }),
        interval: z.number().min(1, 'Required'),
        timeout: z.number().min(1, 'Required'),
        userUid: z.string().optional()
      })
      .nonstrict()
  )
});

const useFormCustom = ({ onSubmit, serverData }) => {
  const showWhatsAppOption = useFeatureFlagUnleash('callRotationWhatsApp', {
    queryString: true
  });

  const showMobileOption = useFeatureFlagUnleash('useNewProfile', {
    queryString: true
  });

  const contactMethods = {
    sms: {
      label: 'SMS',
      value: 'SMS'
    },
    voiceCall: {
      label: 'Voice call',
      value: 'Voice'
    },
    telegram: { label: 'Telegram', value: 'Telegram' },
    mail: { label: 'Mail', value: 'Mail' },
    ...(showWhatsAppOption ? { whatsapp: { label: 'WhatsApp', value: 'WhatsApp' } } : {}),
    ...(showMobileOption ? { mobile: { label: 'Mobile', value: 'Mobile' } } : {})
  };

  const { id: teamId } = useParams();

  const { register, control, handleSubmit, reset, errors, watch, setValue } = useForm({
    defaultValues: {
      stepsGroupName: null,
      steps: [defaultValues]
    },
    resolver: zodResolver(schema)
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'steps',
    keyName: 'key'
  });

  const { submitMutation, error: serverError } = useMutationPersonalised({
    serverData,
    onSubmit,
    teamId,
    timeUnits
  });

  return {
    onSubmit: handleSubmit(submitMutation),
    fields,
    control,
    register,
    remove,
    append,
    defaultValues,
    contactMethods,
    timeUnits,
    errors,
    serverError,
    reset,
    watch,
    setValue
  };
};

export default useFormCustom;
