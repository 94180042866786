import { useEffect } from 'react';

import { Box, Divider, Typography } from '@material-ui/core';
import { Service } from 'types/cms/ServicesHub';

import Breadcrumb from 'components/Breadcrumb';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useCms } from 'hooks/queriesGraphQL/useCms';

import { useStyles } from '../formStyles';
import { useLayoutFromServiceType } from '../lib/useLayoutFromServiceType';

function ServicesHubNewInner({ servicesDefinitions }: { servicesDefinitions: Service[] }) {
  const classes = useStyles();

  const {
    Layout,
    title,
    description,
    breadcrumb,
    baseBreadcrumb,
    backLabel,
    backLink
  } = useLayoutFromServiceType(servicesDefinitions);

  return (
    <>
      <Breadcrumb
        items={
          baseBreadcrumb
            ? [
                { label: baseBreadcrumb || 'Services Hub', link: backLink || '/services-hub' },
                { label: breadcrumb }
              ]
            : [
                { label: backLabel || 'Services Hub', link: backLink || '/services-hub' },
                { label: breadcrumb }
              ]
        }
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h4">New {title}</Typography>
      </Box>

      <Typography className={classes.description} variant="body1">
        {description}
      </Typography>

      <Divider className={classes.divider} />

      <Layout />
    </>
  );
}

export function ServicesHubNew() {
  const [{ fetching, data }] = useCms({
    page: 'servicesHub'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (fetching) {
    return (
      <>
        <LoadingOverlay />
      </>
    );
  }

  return <ServicesHubNewInner servicesDefinitions={data?.cms.content.services} />;
}
