import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentZabbixGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Zabbix',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/zabbix/${integrationUid}?token=${token}`
  };
};
