import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/availability';

function* prepareFetch(axios, action) {
  try {
    const { applicationId, productId } = action.params;
    const {
      data: { data: application }
    } = yield axios.get(`/products/${productId}/applications/${applicationId}`);
    const {
      data: { data: agent }
    } = yield axios.get(`/environments/${application.environment.id}/agent`);
    yield put({
      type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        application: { ...application, agent }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchApplicationAvailabilityViewFetch(axios) {
  yield takeLatest(actions.APPLICATION_AVAILABILITY_VIEW_FETCH, prepareFetch, axios);
}
