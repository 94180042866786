import { apm } from '@elastic/apm-rum';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import actions from 'redux/actions';

import mapFormDataToApi from '../mapFormDataToApi';
import { UpdateApplicationMutation } from '../queries/updateApplication';

import WebHookForm from './WebHookForm';

const defaultValues = {
  environmentId: '',
  openIncidentWithSeverity: 'not-classified',  
};

const WebHookFormEdit = ({
  onClose,
  resourceType,
  startValues,
  applicationId,
  productId,
  application
}) => {
  const dispatch = useDispatch();

  const [applicationResult, updateApplication] = useMutation(UpdateApplicationMutation);
  
  const { fetching: isUpdatingApp, error: appError } = applicationResult;
  
  const setStartValues = () => {
    if (startValues) {
      return { ...startValues };
    }
    return defaultValues;
  };

  const fullStartValues = setStartValues();

  const handleSubmit = async data => {
    const { ...applicationFormData } = data;

    const appPayload = mapFormDataToApi({
      formData: { ...applicationFormData },
      resourceType,
      productId,
      applicationId
    });

    const applicationResponse = await updateApplication({
      data: { ...appPayload }
    });

    if (applicationResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on update application' }
      });

      apm.captureError(applicationResponse.error);
      // eslint-disable-next-line no-console
      console.error(applicationResponse.error);

      return;
    }

    dispatch({
      type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
      params: { productId, applicationId }
    });

    onClose();
  };

  const isLoading =
    isUpdatingApp ;

  if (isLoading) {
    return (
      <Box
        style={{
          position: 'absolute',
          display: 'flex',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          justifyContent: 'center',
          alignItems: 'center',
          background: 'rgb(255 255 255 / 66%)',
          zIndex: 1000
        }}>
        <CircularProgress />
      </Box>
    );
  }

  const errorMessage = (appError) => {
    if (appError?.graphQLErrors) return appError.graphQLErrors[0]?.message;    

    return null;
  };

  return (
    <WebHookForm
      submitMutation={handleSubmit}
      onClose={onClose}
      startValues={fullStartValues}
      isLoading={isLoading}
      errorMessage={errorMessage(appError)}
      isEdit={true}
      application={application}
    />
  );
};

export default WebHookFormEdit;
