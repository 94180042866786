import { ApiFormData } from 'views/ServicesHub/forms/monitoring/api/API';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function apiAdapter(data: MonitoringCommomData & ApiFormData) {
  return {
    ...monitoringAdapter({
      ...data,
      method: 'api',
      timeout: data.timeout ?? 0,
      interval: data.interval ?? 0
    }),
    origin: 'api'
  };
}
