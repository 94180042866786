import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentGoogleCloudGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Google Cloud',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/googlecloud/${integrationUid}?token=${token}`
  };
};
