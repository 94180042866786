import React from 'react';

import cloudProviders from 'constants/cloudProviders';
import cloudStatus from 'constants/cloudStatus';
import instanceTypesAWS from 'constants/instanceTypesAWS';
import regionsAws from 'constants/regionsAws';

import { Grid, TextField, MenuItem } from '@material-ui/core';

import slugify from 'helpers/slugify';

import CrudForm from '../../../../oldComponents/Crud/Form';

import AzureFields from './AzureFields';
import SubnetSelectAWS from './SubnetSelectAWS';
import VpcSelect from './VpcSelect';

const Form = ({ clouds, formState, onChange, title, disableEditCloud }) => {
  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const onChangeCloud = event => {
    event.persist();
    const cloud = clouds.filter(item => item.id === event.target.value)[0];
    onChange(event.target.name, event.target.value);
    onChange('cloud', cloud);
  };

  const validateHandleChange = event => {
    event.persist();
    const value = event.target.value;
    onChange(event.target.name, slugify(value));
  };

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  const setInitialInstanceType = () => {
    onChange('instanceType', instanceTypesAWS[0]['value']);
    return instanceTypesAWS[0]['value'];
  };

  const provider = formState.values.cloud?.provider;

  return (
    <CrudForm entity="environments" formState={formState} title={title}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? formState.errors.name[0] : null}
            InputLabelProps={{
              shrink: true
            }}
            label="Name"
            margin="dense"
            name="name"
            onChange={validateHandleChange}
            required
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            label="Cloud"
            margin="dense"
            name="cloudId"
            onChange={onChangeCloud}
            disabled={disableEditCloud}
            required
            select
            value={formState.values.cloudId || ''}
            variant="outlined">
            {clouds.map(
              option =>
                (option.status === cloudStatus.Valid ||
                  option.provider === cloudProviders.custom) && (
                  <MenuItem title={option.name} key={`cloud-${option.id}`} value={option.id}>
                    {option.name}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>

        {provider === cloudProviders.aws && (
          <>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Region"
                margin="dense"
                name="region"
                onChange={handleChange}
                required
                select
                value={formState.values.region || ''}
                variant="outlined">
                {regionsAws.map(option => (
                  <MenuItem
                    title={option.label}
                    key={`region_${option.value}`}
                    value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <VpcSelect formState={formState} onChange={onChange} />
            </Grid>
            <Grid item md={4} xs={12}>
              <SubnetSelectAWS formState={formState} onChange={onChange} />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                label="Instance Type"
                margin="dense"
                name="instanceType"
                onChange={handleChange}
                required
                select
                value={formState.values.instanceType || setInitialInstanceType()}
                variant="outlined">
                {instanceTypesAWS.map(option => (
                  <MenuItem
                    title={option.label}
                    key={`instanceType_${option.value}`}
                    value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}

        {provider === cloudProviders.azure && (
          <AzureFields formState={formState} onChange={onChange} />
        )}

        <Grid item md={4} xs={12}>
          <TextField
            error={hasError('tag')}
            fullWidth
            helperText={hasError('tag') ? formState.errors.tag[0] : null}
            InputLabelProps={{
              shrink: true
            }}
            label="Tag"
            margin="dense"
            name="tag"
            onChange={validateHandleChange}
            required
            value={formState.values.tag || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </CrudForm>
  );
};

export default Form;
