import { getEnvUrl, getAbreviatedEnv } from 'helpers/getEnvString';

const setResponse = (setValues, data, org, tokenType) => {
  const groupName = `${org.id}-${org.name
    .normalize('NFD')
    .replace(/[\u0300-\u036f-\s]/g, '')
    .toLowerCase()}${getAbreviatedEnv()}`;

  if (data) {
    setValues({
      ...data,
      name: data?.client_name,
      access: data?.client_id,
      secret: data?.client_secret,
      token_type: `${tokenType}`,
      curl: `curl --request POST \\
        --url https://${getEnvUrl()}/external/auth/v1/client/${groupName} \\
        --header 'Content-Type: application/json' \\
        --header 'User-Agent: 1PwebhookAuth/1.0' \\
        --data '{
          "client_id": "${data.client_id}",
          "client_secret": "${data.client_secret}"
        }'`
    });
  }
};

export default setResponse;
