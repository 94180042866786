import ApplicationType from 'constants/ApplicationType';

import { useFlagsStatus } from '@unleash/proxy-client-react';
import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ApplicationTab from '../View/Application';

import WithAddons from './WithAddons';

function InternalApplication() {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  if (newApplications) {
    return <Redirect to="/not-found" />;
  }

  return (
    <WithAddons
      applicationType={ApplicationType.Internal}
      renderApplicationTab={({
        firstAccess,
        environmentId,
        productId,
        applicationType
      }: {
        firstAccess: boolean;
        environmentId: number;
        productId: number;
        applicationType: string;
      }) => (
        <ApplicationTab
          firstAccess={firstAccess}
          environmentId={environmentId}
          productId={productId}
          applicationType={applicationType}
        />
      )}
    />
  );
}

export default InternalApplication;
