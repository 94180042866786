import { PubSubFormData } from 'views/ServicesHub/forms/monitoring/queue/PubSub';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function pubSubAdapter(data: MonitoringCommomData & PubSubFormData) {
  return {
    ...monitoringAdapter({ ...data, method: 'pubsub' }),
    domain: data.topic,
    domainSettings: JSON.parse(data.credentials)
  };
}
