import { connect } from 'react-redux';
import { useQuery as useQueryGraphQl } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import actions from 'redux/actions';
import reducer from 'redux/reducer/products';

import TabPanels from '../components/TabPanels';
import Toolbar from '../components/Toolbar';

const GetMatrixByProductIdV5 = `#graphql
  query($productId: Float!) {
    matrixV5(productId: $productId) {
      data {
        name
        id
        cover
        totalApplications
      }
    }
  }
`;

const ProductView = ({ matrix_view: product, changeTab, remove, history, goBack, user, match }) => {
  const productId = Number(match.params.id);

  const GetMatrixByProductQuery = () => {
    return GetMatrixByProductIdV5;
  };

  const [resultMatrix] = useQueryGraphQl({
    query: GetMatrixByProductQuery(),
    pause: Boolean(!productId),
    variables: { productId }
  });

  const dataFromGraphQl = resultMatrix.data?.matrixV5?.data;

  const productData = { ...product, ...dataFromGraphQl };

  return (
    <>
      <Breadcrumb
        items={[{ label: 'Products', link: '/products' }, { label: productData?.name }]}
      />

      <Toolbar
        goBack={goBack}
        history={history}
        product={productData}
        remove={remove}
        user={user}
      />

      <TabPanels user={user} changeTab={changeTab} product={productData} />
    </>
  );
};

export default connect(
  ({ matrix_view, user }) => ({ matrix_view, user }),
  dispatch => ({
    goBack: payload =>
      dispatch({
        type: actions.GO_BACK,
        payload
      }),
    remove: payload =>
      dispatch({
        type: actions.DELETE_ENTITY,
        payload,
        meta: {
          entity: 'products'
        }
      }),
    changeTab: payload =>
      dispatch({
        type: actions.MATRIX_CHANGE_TAB,
        payload,
        meta: { reducer }
      })
  })
)(ProductView);
