/* eslint-disable */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { useCallback, useState } from 'react';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ExportDialog from './ExportDialog';
import { Generator } from './Recurrence';

export const getDaysArray = (data, recurrence, endOfRecurrence) => {
  const generator = new Generator();
  const arr = generator.create({
    startDate: data.start,
    endDate: data.end,
    recurrence: recurrence,
    endOfRecurrence: endOfRecurrence
  });

  return arr;
};

const Calendar = ({
  setDataSelected,
  events,
  handleOpenDialogCreateEvent,
  handleOpenDialogEditEvent,
  setDataInitSelected,
  setDataEndSelected,
  recurrence,
  endOfRecurrence,
  setCurrentDates,
  teamId
}) => {
  const [openExport, setOpenExport] = useState(false);

  const handleChangeExportDialog = useCallback(() => {
    setOpenExport(!openExport);
  }, [setOpenExport, openExport]);

  const handleSelectDate = data => {
    const days = getDaysArray(data, recurrence, endOfRecurrence);
    setDataSelected(days);
    setDataInitSelected(data.start);
    setDataEndSelected(data.end);
    handleOpenDialogCreateEvent();
  };

  const useExportCallRotation = useFeatureFlagUnleash('useExportCallRotation', {
    queryString: true
  });

  return (
    <>
      <FullCalendar
        datesSet={setCurrentDates}
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        selectable
        select={handleSelectDate}
        eventClick={handleOpenDialogEditEvent}
        events={events}
        headerToolbar={{
          left: 'title',
          right: useExportCallRotation ? `exportButton today prev,next` : 'today prev,next'
        }}
        customButtons={{
          exportButton: {
            text: 'Export',
            click: handleChangeExportDialog
          }
        }}
      />
      <ExportDialog open={openExport} onClose={handleChangeExportDialog} teamId={teamId} />
    </>
  );
};

export default Calendar;
