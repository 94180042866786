import { Box } from '@material-ui/core';
import { Tabs, TabsProps } from 'oldComponents/Tabs';

import { BetaChip } from 'components/BetaChip';
import { LockChip } from 'components/LockChip';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

import { TabApi } from '../TabApi';
import { TabAuditTrail } from '../TabAuditTrail';
import { TabGeneral } from '../TabGeneral';
import { TabIntegrations } from '../TabIntegrations';
import { TabSecrets } from '../TabSecrets';
import { TabSso } from '../TabSso';

function useTabsDefinition(): TabsProps['tabs'] {
  const canUseSSOIntegrations = useFeaturePlan('SSOIntegrations');

  const shouldUseAuditTrail = useFeatureFlagUnleash('auditTrail', { queryString: true });

  return [
    { label: 'General', slug: 'general', component: <TabGeneral /> },
    {
      label: (
        <Box display="flex" gridGap="0.5rem">
          Single Sign-On
          {!canUseSSOIntegrations && <LockChip />}
        </Box>
      ),
      slug: 'sso',
      component: <TabSso />,
      disabled: !Boolean(canUseSSOIntegrations)
    },
    { label: 'Integrations', slug: 'integrations', component: <TabIntegrations /> },
    { label: 'Secrets', slug: 'secrets', component: <TabSecrets /> },
    { label: 'API', slug: 'api', component: <TabApi /> },
    {
      label: (
        <Box display="flex" gridGap="0.5rem">
          Audit Trail
          <BetaChip />
        </Box>
      ),
      slug: 'audit-trail',
      component: <TabAuditTrail />,
      hide: !shouldUseAuditTrail
    }
  ].filter(tab => !tab?.hide);
}

export const OrganizationSettingsTabs = () => {
  const tabs = useTabsDefinition();

  return (
    <div>
      <Tabs tabs={tabs} />
    </div>
  );
};
