import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../../actions';

function* prepareAdd(axios, action) {
  try {
    const id = yield select(state => state.products_view.id);

    yield axios.delete(`/products/${id}/teams/${action.payload.id}`);
    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Team successfully removed from product'
    });
    yield put({
      type: actions.PRODUCT_TEAMS_FETCH,
      payload: id
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductsRemoveTeam(axios) {
  yield takeLatest(actions.PRODUCT_TEAMS_REMOVE_ACCOUNT, prepareAdd, axios);
}
