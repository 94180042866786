import { SidekiqFormData } from 'views/ServicesHub/forms/monitoring/queue/Sidekiq';

import { MonitoringBaseForm } from '../../../forms/monitoring/commom/Base';
import { monitoringAdapter } from '../monitoring';

export function sidekiqAdapter(data: MonitoringBaseForm & SidekiqFormData) {
  return {
    ...monitoringAdapter({
      ...data,
      method: 'sidekiq'
    }),
    domain: data.url,
    domainSettings: {
      skipSslValidation: data.skipSslValidation,
      queueSize: Number(data.queueSize),
      buzySize: Number(data.buzySize),
      scheduleSize: Number(data.scheduleSize),
      connectedClients: Number(data.connectedClients)
    }
  };
}
