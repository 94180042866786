import { Box, Card, CardContent, CardMedia, Chip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ServiceItem } from 'types/cms/ServicesHub';

import useUser from 'hooks/queriesRest/useUser';
import { Capture } from 'services/posthog';

import useStyles from '../styles';

type ServiceHubCardProps = {
  serviceTitle: string;
  item: ServiceItem;
};

type CardCustomProps = {
  isFeatureEnabled: boolean | null;
  item: ServiceItem;
};

export const ServiceHubCard = ({ serviceTitle, item }: ServiceHubCardProps) => {
  const classes = useStyles();

  const userData = useUser({});
  const planName = userData.data?.logged?.org?.planName;
  const isFeatureEnabled = item.plans.hasOwnProperty(planName) && item.plans[planName];

  return !isFeatureEnabled ? (
    <Link to="/plans" className={classes.link}>
      <CardCustom isFeatureEnabled={isFeatureEnabled} item={item} />
    </Link>
  ) : (
    <Link
      to={item.link}
      className={classes.link}
      onClick={() => {
        Capture('Services Hub', {
          name: `${serviceTitle} - ${item.title}`
        });
      }}>
      <CardCustom isFeatureEnabled={isFeatureEnabled} item={item} />
    </Link>
  );
};

const CardCustom = ({ isFeatureEnabled, item }: CardCustomProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ paddingBottom: '16px' }}>
        <Box className={classes.cardTop}>
          <CardMedia component="img" className={classes.cardMedia} src={item.image} />
          <Typography className={classes.cardTitle} variant="h6">
            {item.title}
          </Typography>
        </Box>
        <Typography className={classes.cardDescription} variant="body1">
          {item.description}
        </Typography>

        {!isFeatureEnabled ? (
          <Box className={classes.cardLocked}>
            <Chip
              label="Locked"
              variant="outlined"
              className={classes.cardChip}
              style={{ cursor: 'pointer' }}
            />
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default ServiceHubCard;
