import * as z from 'zod';

import { domainSettingsValidation } from 'helpers/domainSettingsHelper';

export default z
  .object({
    name: z.string().nonempty({ message: "Name can't be blank" }),
    domain: z.string().optional(),
    cloudId: z.any().nullable(),
    environmentId: z.number({ message: "Environment can't be blank" }),
    check_type: z.string().nonempty({ message: "Type can't be blank" }),
    method: z.string().nonempty({ message: "Method can't be blank" }),
    headers: z.any(),
    post_body_raw: z.any(),
    post_body_urlencoded: z.any(),
    post_body_type: z.any(),
    post_body_raw_type: z.any(),
    skip_ssl_validation: z.boolean().optional(),
    tls_renegotiation: z.any().optional(),
    queue_size: z
      .string()
      .refine(
        querySize => {
          try {
            if (Number(querySize) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Queue size should be a positive number' }
      )
      .optional(),
    json_pubsub_body_raw: z.any(),
    failuresToIncident: z
      .string()
      .refine(
        failuresToIncident => {
          try {
            if (Number(failuresToIncident) >= 1) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Fails number should be a greater than zero' }
      )
      .optional(),
    ...domainSettingsValidation()
  })
  .refine(
    values => {
      if (values.method !== 'webhook' && values.domain?.length === 0) {
        return false;
      }

      return true;
    },
    {
      message: 'Should be at least 1 characters',
      path: ['domain']
    }
  );
