import { Card, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { formatMetricsValue, MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Diff } from '../Diff';
import { MetricsTitle } from '../MetricsTitle';

import { useStyles } from './styles';

export const Metrics = ({
  name,
  value,
  valueFormat,
  invertColor = false,
  isFetching = true,
  tooltipTitle,
  diff = 0,
  diffFormat,
  startDate = new Date(),
  endDate = new Date()
}: {
  name: string;
  value: number;
  valueFormat: MetricsValueFormatEnum;
  invertColor?: boolean;
  isFetching?: boolean;
  tooltipTitle?: string;
  diff?: number;
  diffFormat?: MetricsValueFormatEnum;
  startDate?: Date;
  endDate?: Date;
}) => {
  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.paperTop} variant="outlined">
      <MetricsTitle title={name} tooltipTitle={tooltipTitle} />

      <Typography variant="h5" className={classes.paperTextMiddle}>
        {isFetching ? (
          <Skeleton variant="text" width="100%" />
        ) : (
          formatMetricsValue({
            value: value,
            valueFormat: valueFormat
          })
        )}
      </Typography>
      {isFetching ? (
        <Skeleton variant="text" width="100%" />
      ) : (
        <Diff
          value={diff}
          valueFormat={diffFormat}
          invertColor={invertColor}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </Card>
  );
};
