export const updateAccountMutation = `#graphql
    mutation($updateAccountInputs: UpdateAccountInputs!) {
      updateAccount (updateAccountInputs: $updateAccountInputs){
        id
      }
    }
`;

export const updateAccountWithAragornMutation = `#graphql
  mutation($updateAccountWithAragornInputs: UpdateAccountWithAragornInputs!) {
    updateAccountWithAragorn (updateAccountWithAragornInputs: $updateAccountWithAragornInputs){
      message
      account {
        id
        user {
          id
        }
      }
    }
  }
`;
