import { Resource } from 'types/external/Resource';

import { SidekiqFormData } from 'views/ServicesHub/forms/monitoring/queue/Sidekiq';

import { monitoringAdapter } from '../monitoring';

export function sidekiqAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & SidekiqFormData {
  return {
    ...monitoringAdapter(data),
    url: data.domain,
    skipSslValidation: data.domainSettings.skipSslValidation,
    queueSize: data.domainSettings.queueSize,
    buzySize: data.domainSettings.buzySize,
    scheduleSize: data.domainSettings.scheduleSize,
    connectedClients: data.domainSettings.connectedClients
  };
}
