export const functionsStartValues = ({ method, domain_settings }) => {
  if (method !== 'functions' && method !== 'functionsgen2') {
    return;
  }

  let domainSettingsObj = domain_settings;
  if (typeof domain_settings === 'string') {
    domainSettingsObj = JSON.parse(domain_settings);
  }

  return {
    locations: domainSettingsObj?.locations,
    search_period: domainSettingsObj?.search_period,
    credentials: domainSettingsObj?.credentials
  };
};
