import {
  Typography,
  Box,
  ListItemText,
  ListItem,
  ListItemIcon,
  List,
  Link,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: '400px',
    maxWidth: '100%'
  },
  icon: {
    fontSize: 10
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: props =>
      props.isFontWeightRegular
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  },
  link: {
    pointerEvents: 'none'
  }
}));

const ShowDependencies = ({
  dependencies,
  title,
  button = undefined,
  isFontWeightRegular = undefined,
  secondTitle = undefined
}) => {
  const classes = useStyles({ isFontWeightRegular });

  if (dependencies && dependencies.length) {
    return (
      <Box style={{ paddingBottom: '0px' }}>
        <List className={classes.root} component="nav" aria-label="applications-being-monitored">
          {dependencies?.some(dependency => dependency?.items.length > 0) ? (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          ) : null}

          {dependencies.map((dependency, index) => {
            if (
              dependency.type === 'Member' ||
              (dependency.type === 'Team' && dependency.tab === 'members')
            ) {
              return (
                <div key={index}>
                  {dependency.items.map((item, i) => {
                    const route = () => {
                      if (dependency.type === 'Team') return `/teams/${item.id}`;
                    };

                    return (
                      <Box>
                        <Link
                          component={RouterLink}
                          to={route}
                          underline="none"
                          key={item.id + i}
                          className={dependency.type === 'Member' ? classes.link : null}>
                          <ListItem button={button} key={item.name}>
                            <Box ml={2} display="flex" alignItems="center">
                              <ListItemIcon>
                                <FiberManualRecordIcon className={classes.icon} />
                              </ListItemIcon>
                              <ListItemText
                                variant="h5"
                                style={{ marginLeft: '-35px' }}
                                primary={
                                  <Typography variant="h5">
                                    {dependency.type === 'Team'
                                      ? `${dependency.type}: ${item.name}`
                                      : item.name}
                                  </Typography>
                                }
                              />
                            </Box>
                          </ListItem>
                        </Link>
                      </Box>
                    );
                  })}

                  {dependency.items.length ? (
                    <Box mt={2}>
                      <Typography variant="h5">{secondTitle}</Typography>
                    </Box>
                  ) : null}
                </div>
              );
            }

            return (
              <div key={index}>
                {dependency.items.map((item, i) => {
                  const route = () => {
                    switch (dependency.type) {
                      case 'Resource':
                        return `/monitoring/${item.uid}`;
                      case 'Synthetic':
                        return `/synthetic/${item.uid}`;
                      case 'Application':
                        return `/products/${item.productId}/availability/applications/${item.id}`;
                      case 'Environment':
                        return `/environments/${item.id}/edit`;
                      case 'Service':
                        return `/services/${item.id}/edit`;
                      case 'Team':
                        if (dependency.tab === 'members') {
                          return `/teams/${item.id}`;
                        }
                        return `/teams/${item.id}?tab=call-rotation`;
                      case 'Step Group':
                        return `/teams/${item.teamId}/stepGroups`;
                      default:
                        return null;
                    }
                  };

                  return (
                    <Link component={RouterLink} to={route} underline="none" key={item.id + i}>
                      <ListItem button key={item.id}>
                        <ListItemIcon>
                          <ArrowRightIcon />
                        </ListItemIcon>
                        <ListItemText
                          style={{ marginLeft: '-20px' }}
                          primary={`${dependency.type}: ${item.name}`}
                        />
                      </ListItem>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </List>
      </Box>
    );
  }

  return (
    <Box style={{ paddingBottom: '0px' }}>
      <List className={classes.root} component="nav" aria-label="applications-being-monitored">
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </List>
    </Box>
  );
};

export default ShowDependencies;
