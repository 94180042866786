import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab-old';

export const useStyles = makeStyles(() => ({
  skeleton: {
    transform: 'none'
  }
}));

export function PostmortemViewLoadingSkeleton() {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" gridGap="2rem">
      <Skeleton className={classes.skeleton} width="100%" height="40px" />
      <Box display="flex" flex={1} gridGap="5rem">
        <Box display="flex" flexDirection="column" gridGap="1rem" flex={1}>
          <Skeleton className={classes.skeleton} width="10%" height="2rem" />
          <Skeleton className={classes.skeleton} width="20%" height="2rem" />
          <Skeleton className={classes.skeleton} width="30%" height="2rem" />
          <Skeleton className={classes.skeleton} width="40%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
          <Skeleton className={classes.skeleton} width="100%" height="2rem" />
        </Box>
        <Skeleton className={classes.skeleton} width="20%" height="300px" />
      </Box>
    </Box>
  );
}
