import { useQuery as useQueryUrql } from 'urql';

const GetSchedulesList = `#graphql
  query($teamId: Int!, $startDate: String!, $endDate: String!) {
    getSchedulesByTeamIdFromTeams(teamId: $teamId, startDate: $startDate, endDate: $endDate) {
      id
      uid
      groupId
      color
      startDate
      endDate
      createdAt
      updatedAt
      teamId
      callRotationRecurrenceId
      teamIdFromTeams
      group {
        id
        name
      }
    }
  }
`;

const useSchedulesList = ({ teamId, start, end, }: { teamId: number, start: string, end: string }) => {
  const [{ data: scheduleListByTeamIdFromTeams, fetching }, reexecuteQuery] = useQueryUrql({
    query: GetSchedulesList,
    variables: { teamId: Number(teamId), startDate: start, endDate: end }
  });

  return {
    data: scheduleListByTeamIdFromTeams?.getSchedulesByTeamIdFromTeams,
    isFetching: fetching,
    reexecuteQuery
  };
};

export default useSchedulesList;
