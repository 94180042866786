import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { TCPFormData } from 'views/ServicesHub/forms/monitoring/others/TCP';

import { monitoringAdapter } from '../monitoring';

export function tcpAdapter(data: MonitoringBaseForm & TCPFormData) {
  const domain = prepareDomain(data);

  return {
    ...monitoringAdapter({
      ...data,
      method: 'tcp'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringBaseForm & TCPFormData) => {
  if (data.domainType === 'userAndPass') {
    // ex: HOST:PORT
    return `${data.host}:${data.port}`;
  }

  return data.domain;
};
