import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function IntegrationInstructions(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M11 15.17L8.83 13L11 10.83L9.59 9.41L6 13L9.59 16.59L11 15.17Z" />
      <path d="M14.41 16.59L18 13L14.41 9.41L13 10.83L15.17 13L13 15.17L14.41 16.59Z" />
      <path d="M19 4H14.82C14.4 2.84 13.3 2 12 2C10.7 2 9.6 2.84 9.18 4H5C4.86 4 4.73 4.01 4.6 4.04C4.21 4.12 3.86 4.32 3.59 4.59C3.41 4.77 3.26 4.99 3.16 5.23C3.06 5.46 3 5.72 3 6V20C3 20.27 3.06 20.54 3.16 20.78C3.26 21.02 3.41 21.23 3.59 21.42C3.86 21.69 4.21 21.89 4.6 21.97C4.73 21.99 4.86 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM12 3.75C12.41 3.75 12.75 4.09 12.75 4.5C12.75 4.91 12.41 5.25 12 5.25C11.59 5.25 11.25 4.91 11.25 4.5C11.25 4.09 11.59 3.75 12 3.75ZM19 20H5V6H19V20Z" />
    </SvgIcon>
  );
}
