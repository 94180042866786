import React from 'react';

import { useStateRequest } from 'hooks/useApiGeneric';
import axios from 'redux/axios';

const useVpc = ({ cloudId, region }) => {
  const [state, dispatch] = useStateRequest();
  const { status } = state;

  React.useEffect(() => {
    if (cloudId && region) {
      dispatch({ type: 'started' });
      axios
        .get(`/vpcs?cloudId=${cloudId}&region=${region}`)
        .then(response => dispatch({ type: 'success', data: response.data.data }))
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }, [cloudId, region, dispatch]);

  return {
    isLoading: status === 'idle' || status === 'pending',
    isIdle: status === 'idle',
    isPending: status === 'pending',
    isResolved: status === 'resolved',
    isRejected: status === 'rejected',
    ...state
  };
};

export default useVpc;
