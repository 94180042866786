/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { useMutation, useQuery } from 'urql';

export type IncidentStatusUpdate = {
  id: number;
  incidentId: number;
  orgUid: string;
  description: string;
  private: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  lastAction: {
    id: number;
    action: string;
    createdAt: string;
    user: {
      id: number;
      name: string;
    };
  };
};

const CreateIncidentStatusUpdateQuery = `#graphql
  mutation($data: IncidentStatusUpdateInput!) {
    createIncidentStatusUpdate(data: $data)
  }
`;
const UpdateIncidentStatusUpdateQuery = `#graphql
  mutation($incidentId: Int!, $id: Int!, $data: UpdateIncidentStatusUpdateInput!) {
    updateIncidentStatusUpdate(
      incidentId:$incidentId,
      id:$id,
      data: $data
    )
  }
`;

const DeleteIncidentStatusUpdateMutation = `#graphql
mutation($incidentId:Int!, $id:Int!){
  deleteIncidentStatusUpdate(
    incidentId:$incidentId,
    id:$id
  )
}
`;

const GetPrivateIncidentStatusUpdateQuery = `#graphql
  query($incidentId: Int!) {
    privateIncidentStatusUpdate(incidentId: $incidentId) {
      id
      incidentId
      orgUid
      description
      private
      createdAt
      updatedAt
      deletedAt
      lastAction {
        id
        action
        createdAt 
        user {
          id
          name
      }}
    }
  }
`;

function useIncidentStatusUpdate() {
  const createIncidentStatusUpdate = useMutation(CreateIncidentStatusUpdateQuery);
  const updateIncidentStatusUpdate = useMutation(UpdateIncidentStatusUpdateQuery);
  const deleteIncidentStatusUpdate = useMutation(DeleteIncidentStatusUpdateMutation);
  const getPrivateIncidentStatusUpdate = (args?: { pause: boolean; incidentId: number }) =>
    useQuery<{ privateIncidentStatusUpdate: IncidentStatusUpdate[] }>({
      query: GetPrivateIncidentStatusUpdateQuery,
      variables: {
        incidentId: args?.incidentId
      },
      pause: args?.pause ?? false
    });

  return useMemo(
    () => ({
      createIncidentStatusUpdate,
      getPrivateIncidentStatusUpdate,
      deleteIncidentStatusUpdate,
      updateIncidentStatusUpdate
    }),
    []
  );
}

export default useIncidentStatusUpdate;
