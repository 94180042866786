import { TableColumn } from 'react-data-table-component';
import { Step } from 'types/external/MultiFlow';

import { StepName } from '../StepName';
import { StepStatusChip } from '../StepStatusChip';

const ColumnsSteps: TableColumn<Step>[] = [
  {
    name: 'Status',
    selector: row => row.status,
    cell: row => <StepStatusChip status={row.status} />,
    width: '250px',
    grow: 1.5
  },
  {
    name: 'Name',
    minWidth: '600px',
    selector: (row: Step) => row.id,
    cell: row => <StepName step={row.step} name={row.name} />
  }
];

export default ColumnsSteps;
