import { Tab as MuiTab, withStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const CustomTab = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[800],
    fontSize: 14,
    '&$selected': {
      color: theme.palette.primary.main
    },
    textTransform: 'initial'
  },
  selected: {}
}))(MuiTab);
