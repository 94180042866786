export const getTeamsByResourceId = `#graphql
    query($resourceId: Float!) {
      getTeamsByResourceId(resourceId: $resourceId, resourceType: "Product") {
        name
        orgUid
        resourceTeams {
          teamId
          resourceId
          resourceType
        }
      }
    }
  `;
