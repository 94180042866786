import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { MongoDBFormData } from 'views/ServicesHub/forms/monitoring/database/MongoDB';

import { monitoringAdapter } from '../monitoring';

export function mongodbAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & MongoDBFormData {
  const { domain, protocol, user, password, host, port, database } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    protocol,
    user,
    password,
    host,
    port,
    database
  };
}

const prepareDomain = (data: Resource) => {
  let protocol = '';
  let user = '';
  let password = '';
  let host = '';
  let port = '';
  let database = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    const domainURI = new URI(data.domain);

    protocol = domainURI.protocol();
    user = domainURI.username();
    password = domainURI.password();
    host = domainURI.hostname();
    port = domainURI.port();
    database = domainURI.path().replace('/', '');
  }

  return { domain, protocol, user, password, host, port, database };
};
