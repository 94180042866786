import { ComparisonTypes } from 'constants/postBody';

import { z } from 'zod';

import { comparisonOptions } from 'views/ServicesHub/forms/multiflow/common/comparisonOptions';
import { rulesSchema } from 'views/ServicesHub/schema/monitoring/rules';

export const schema = z
  .object({
    id: z.coerce
      .number()
      .int()
      .optional(),
    uid: z.string().optional(),
    name: z.string().min(1, 'Name is required'),
    intervalCheck: z.coerce
      .number()
      .int()
      .min(
        Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING),
        `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
      ),
    environmentId: z.coerce.number().int(),
    ...rulesSchema,
    steps: z.array(
      z.object({
        id: z.string(),
        name: z.string().min(1, 'Name is required'),
        timeout: z.coerce.number().min(1, `The timeout number must be greater than zero.`),
        validationString: z.string().optional(),
        headers: z.array(z.object({ key: z.string(), value: z.string() })).optional(),
        healthcheckUrl: z.string().url({ message: 'Invalid url. E.g.: http://host' }),
        assertions: z
          .array(
            z.object({
              id: z.string(),
              source: z.string(),
              comparison: z.string(),
              targetValue: z.string().optional(),
              property: z.string().optional()
            })
          )
          .optional(),
        method: z.string(),
        tlsRenegotiation: z.coerce.number().int(),
        skipSslValidation: z.coerce.string(),
        rawType: z.string().optional(),
        postBody: z.string().optional(),
        postBodyType: z.string().optional(),
        postBodyUrlEnconded: z.array(z.object({ key: z.string(), value: z.string() })).optional()
      })
    )
  })
  .superRefine((data, ctx) => {
    data.steps.forEach((step: any, stepIndex: any) =>
      step.assertions?.forEach((assertion: any, assertionIndex: any) => {
        if (assertion.source && !!assertion.comparison === false) {
          ctx.addIssue({
            message: 'This field is required',
            path: [`steps.${stepIndex}.assertions.${assertionIndex}.comparison`],
            code: z.ZodIssueCode.custom
          });
        }

        if (assertion.comparison && !!assertion.source === false) {
          ctx.addIssue({
            message: 'This field is required',
            path: [`steps.${stepIndex}.assertions.${assertionIndex}.source`],
            code: z.ZodIssueCode.custom
          });
        }

        if (assertion.source) {
          const options = comparisonOptions(assertion.source);

          const match = options.find(
            (option: { value: ComparisonTypes; label: string }) =>
              option.value === assertion.comparison
          );

          if (!match) {
            ctx.addIssue({
              message: 'Select a valid option',
              path: [`steps.${stepIndex}.assertions.${assertionIndex}.comparison`],
              code: z.ZodIssueCode.custom
            });
          }
        }

        if (assertion.source === 'json_body' && !!assertion.property?.length === false) {
          ctx.addIssue({
            message: 'This field is required',
            path: [`steps.${stepIndex}.assertions.${assertionIndex}.property`],
            code: z.ZodIssueCode.custom
          });
        }
      })
    );
  })
  .superRefine((data, ctx) => {
    const hasSomeStepWithTimeoutGreaterThanIntervalCheck = data.steps.findIndex(
      step => Number(step?.timeout) > Number(data.intervalCheck)
    );

    if (hasSomeStepWithTimeoutGreaterThanIntervalCheck !== -1) {
      ctx.addIssue({
        path: [`intervalCheck`],
        message: `Interval must be equal to or greater than Timeout on step ${hasSomeStepWithTimeoutGreaterThanIntervalCheck +
          1}`,
        code: z.ZodIssueCode.custom
      });
    }
  })
  .superRefine((data, ctx) => {
    const hasSomeStepWithTimeoutGreaterThanIntervalCheck = data.steps.findIndex(
      step => Number(step?.timeout) > Number(data.intervalCheck)
    );

    if (hasSomeStepWithTimeoutGreaterThanIntervalCheck !== -1) {
      ctx.addIssue({
        path: [`steps.${hasSomeStepWithTimeoutGreaterThanIntervalCheck}.timeout`],
        message: 'Timeout must be less than interval check',
        code: z.ZodIssueCode.custom
      });
    }
  })
  .superRefine((data, ctx) => {
    const {
      intervalFailures,
      intervalHits,
      failuresToOpenIncident,
      hitsToCloseIncident,
      enable
    } = data.rules.incident;

    const isIncidentRuleIntervalFailureValid =
      intervalFailures >= data.intervalCheck * failuresToOpenIncident;

    if (enable && !isIncidentRuleIntervalFailureValid) {
      ctx.addIssue({
        path: ['rules.incident.intervalFailures'],
        message: 'Interval failures should be less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['rules.incident.failuresToOpenIncident'],
        message: 'Interval failures should be less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: [`intervalCheck`],
        message: 'Interval should be equal or less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });
    }

    const isIncidentRuleIntervalHitsValid =
      intervalHits >= data.intervalCheck * hitsToCloseIncident;

    if (enable && !isIncidentRuleIntervalHitsValid) {
      ctx.addIssue({
        path: ['rules.incident.intervalHits'],
        message: 'Interval hits should be less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['rules.incident.hitsToCloseIncident'],
        message: 'Interval hits should be less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: [`intervalCheck`],
        message: 'Interval should be equal or less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });
    }
  });
