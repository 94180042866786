export enum TourOrder {
  Toolbar,
  SidebarList,
  IncidentsManager
}

export const TourLocalStorageKeys: Record<keyof typeof TourOrder, string> = {
  Toolbar: 'tour',
  SidebarList: 'tour:sidebar-list',
  IncidentsManager: 'tour:incidents-manager'
};
