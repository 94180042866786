import React from 'react';

import {
  Box,
  Checkbox as CheckboxUI,
  Typography,
  Tooltip,
  Icon,
  makeStyles
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {},
  CheckboxUI: {
    color: theme.palette.grey[800]
  }
}));

function CheckBox({
  inputRef,
  defaultChecked = false,
  name,
  label,
  tooltipText,
  disabled = false
}) {
  const classes = useStyles();
  return (
    <Box display="flex" marginBottom="10px" alignItems="center">
      <Typography style={{ whiteSpace: 'nowrap' }} color="textPrimary">
        <CheckboxUI
          className={classes.CheckboxUI}
          inputRef={inputRef}
          color="primary"
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
        {label}
      </Typography>

      <Box style={{ marginTop: '-3px' }} ml={1}>
        <Tooltip title={tooltipText}>
          <Icon style={{ color: grey[500], fontSize: 18 }}>help</Icon>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default CheckBox;
