import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes blinker': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  up: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  down: {
    fontSize: 18,
    fontWeight: 'bold',
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  },
  link: {
    fontSize: 18
  },
  tooltip: {
    fontSize: 12,
    padding: 8
  }
}));
