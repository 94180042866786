import { Box, Typography, BoxProps, Link } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import useStyles from './styles';

export interface WarningBoxProps extends BoxProps {
  text: string;
  linkText?: string;
  linkHref?: string;
}

export const WarningBox = ({ text, linkText, linkHref, ...props }: WarningBoxProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.content} {...props}>
      <Warning className={classes.icon} />
      <Typography variant="body2" className={classes.text}>
        {text}
        {linkText && (
          <Link href={linkHref} target="_blank" rel="noopener noreferrer">
            {linkText}
          </Link>
        )}
      </Typography>
    </Box>
  );
};
