import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import { DeleteEntity as NewDeleteEntity } from 'oldComponents/DeleteDialog';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import ShowDependencies from 'oldComponents/DeleteEntityDialog/components/ShowDependencies';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { usePermission } from 'hooks/usePermission';
import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

const TeamsDataGrid = ({
  entity,
  fetchEntity,
  cols,
  title,
  teams_view,
  editHandler,
  removeHandler,
  graphqlData,
  notificationSetup
}) => {
  const dispatch = useDispatch();

  const { id: teamId } = useParams();

  const fetchMembersFromBff = entity === 'teams_members';

  React.useEffect(() => {
    if (!fetchMembersFromBff) {
      fetchEntity(entity);
    }
  }, [fetchEntity, entity, fetchMembersFromBff]);

  const data = teams_view[entity].data;

  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, entity: null });

  const [disassociateModalState, setDisassociateModalState] = React.useState({
    isOpen: false,
    entity: null
  });

  const { data: teamDependencies, isLoading } = useQuery(
    ['teamDependencies', deleteModalState.entity?.teamId, deleteModalState.entity?.id],
    () =>
      axios
        .get(
          `/teams/${deleteModalState.entity?.teamId}/members/${deleteModalState.entity?.id}/dependencies`
        )
        .then(response => response.data.data),
    { enabled: deleteModalState.isOpen }
  );

  const handleSort = ({ selector }, sortDirection) => {};

  const columns = [...cols];

  const queryCache = useQueryCache();

  const notificationSetupDependencies = () => {
    const dependencies = [];

    if (!deleteModalState.isOpen) return dependencies;

    if (
      notificationSetup.some(
        provider => provider.config.email === deleteModalState.entity?.user?.email
      )
    ) {
      dependencies.push({ name: 'Email' });
    }

    if (
      notificationSetup.some(
        provider =>
          provider.providerName === 'WhatsApp' &&
          provider.config.numbers.length &&
          provider.config.numbers.some(
            number => number === deleteModalState.entity?.user?.profile?.phone
          )
      )
    ) {
      dependencies.push({ name: 'WhatsApp' });
    }

    return dependencies;
  };

  if (entity === 'teams_members') {
    columns.push({
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          title="Edit"
          onClick={event => {
            event.preventDefault();
            editHandler(`/teams/${row.teamId}/members/${row.id}/edit`);
          }}
          disabled={!permissions.update}
          variant="outlined">
          <EditIcon />
        </IconButton>
      )
    });
    columns.push({
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          title="Remove"
          member-data={row.user?.email}
          onClick={() => {
            queryCache.invalidateQueries('teams');

            setDeleteModalState({ isOpen: true, entity: row });
          }}
          disabled={!permissions.remove}
          variant="outlined">
          <DeleteIcon />
        </IconButton>
      )
    });
  }

  const [unlinkHandler] = useMutation(
    productId => axios.delete(`/products/${productId}/teams/${teamId}`),
    {
      onSuccess: () => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Product successfully removed from team'
        });
        fetchEntity(entity);
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  if (entity === 'teams_products') {
    columns.push({
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          title="Remove"
          variant="outlined"
          disabled={!permissions.unlinkProduct}
          onClick={() => setDisassociateModalState({ isOpen: true, id: row.id })}>
          <DeleteIcon />
        </IconButton>
      )
    });
  }

  const dataGridTheme = {
    title: {
      height: title ? 56 : 0
    }
  };

  const permissions = {
    update: usePermission('TeamController-put-/teams/:teamId/members/:memberId'),
    remove: usePermission('TeamController-delete-/teams/members/:id'),
    unlinkProduct: useProductPermission({
      productId: String(disassociateModalState.id),
      target: 'ProductController-delete-/products/:id/teams/:teamId'
    })
  };

  return (
    <>
      <DataTable
        clicked
        columns={columns}
        customTheme={dataGridTheme}
        data={fetchMembersFromBff ? graphqlData : data}
        highlightOnHover
        noDataComponent="No data"
        onSort={handleSort}
        sortServer
        striped
        title={title}
      />

      <DeleteEntity
        open={disassociateModalState.isOpen}
        disassociate={true}
        handleClose={() => setDisassociateModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          unlinkHandler(disassociateModalState.id);
          setDisassociateModalState({ isOpen: false, entity: null });
        }}
        entityName="Product"
        disabled={!permissions.remove}
      />
      <NewDeleteEntity
        open={deleteModalState.isOpen}
        isLoading={isLoading}
        handleClose={() => setDeleteModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          removeHandler(deleteModalState.entity);
          setDeleteModalState({ isOpen: false, entity: null });
        }}
        entityName="Member"
        disabled={!permissions.remove || teamDependencies?.length}>
        <ShowDependencies
          dependencies={[{ items: teamDependencies || [], type: 'Step Group' }]}
          title="Cannot remove because the member is in a step group (call rotation)"
        />
        <ShowDependencies
          dependencies={[{ items: notificationSetupDependencies(), type: 'Member' }]}
          title="This member is receiving notifications from the following providers:"
          button={false}
          secondTitle="If you remove this member, he will no longer receive notifications. You can configure notifications again in the notification tab."
          isFontWeightRegular={true}
        />
      </NewDeleteEntity>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchEntity: entity =>
    dispatch({
      type: actions.TEAMS_FETCH_ENTITY,
      meta: {
        entity
      }
    }),
  editHandler: route => dispatch(push(route))
});

const mapStateToProps = ({ teams_view, user }) => {
  return {
    teams_view: teams_view
  };
};

const ConnectedTeamsDataGrid = connect(mapStateToProps, mapDispatchToProps)(TeamsDataGrid);

export default ConnectedTeamsDataGrid;
