import { postBodyTypeValues } from 'constants/postBody';

import { takeLatest, put } from 'redux-saga/effects';

import convertJsonToString from 'helpers/convertJsonToString.js';
import reducer from 'redux/reducer/products/availability';

import actions from '../../actions';

function* prepareFetchEntities(axios, action) {
  try {
    const applicationId = action.params.applicationId;
    const productId = action.params.productId;
    const {
      data: { data: product }
    } = yield axios.get(`/products/${productId}`);

    const {
      data: {
        data: { id, environmentId, name, applicationsSettings, status }
      }
    } = yield axios.get(`/products/${productId}/applications/${applicationId}`);

    const {
      healthcheckUrl,
      timeout,
      check_interval,
      failuresToIncident,
      validationString,
      method,
      post_body,
      post_body_type,
      headers,
      external,
      envs
    } = {
      ...applicationsSettings
    };

    const {
      data: { data }
    } = yield axios.get('/environments');

    yield put({
      type: actions.PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        environments: { data },
        product,
        product_availability_application_edit: {
          values: {
            id,
            environmentId,
            name,
            healthcheckUrl: healthcheckUrl.trim(),
            timeout,
            method,
            post_body_type,
            headers,
            external,
            check_interval,
            failuresToIncident,
            validationString,
            envs,
            status,
            postBodyRaw: convertJsonToString(post_body_type, post_body),
            postBodyXFormUrlEncoded:
              post_body_type === postBodyTypeValues.urlencoded ? post_body : ''
          }
        }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationEditFetch(axios) {
  yield takeLatest(
    actions.PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH,
    prepareFetchEntities,
    axios
  );
}
