import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation as useMutationURQL } from 'urql';

import { encodeData } from 'helpers/encodeData';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud/';

import NewCloudsForm from '../components/Form/NewCloud';
import schema from '../components/schema';

const CreateCloudMutation = `#graphql
    mutation($cloudInput: CloudInput!){
      createCloud (cloudInput: $cloudInput){
        id
        provider
      }
    }
  `;

function CloudsNew({ clouds_new: formState, onChange }) {
  const shouldUseGraphqlOnClouds = useFeatureFlagUnleash('cloudsGraphql', { queryString: true });

  const router = useHistory();
  const dispatch = useDispatch();

  const [createCloudMutation] = useMutation(
    async data => {
      const payload = encodeData(data);
      const response = await axios.post('/clouds', payload);
      return response;
    },
    {
      onSuccess: async () => {
        router.push('/clouds');
      }
    }
  );

  const [, createCloudMutationURQL] = useMutationURQL(CreateCloudMutation);

  const submitMutation = data => {
    if (!shouldUseGraphqlOnClouds) {
      return createCloudMutation(data);
    }

    const { name, provider, newCloud, ...providerCredentials } = encodeData(data);

    createCloudMutationURQL({
      cloudInput: { name, provider, providerCredentials: { provider, ...providerCredentials } }
    }).then(result => {
      if (result.error || !result.data)
        return dispatch({
          type: actions.GLOBAL_WARNING,
          payload: 'Unable to create cloud'
        });

      router.push('/clouds');
    });
  };

  return <NewCloudsForm submitMutation={submitMutation} title="New cloud" />;
}

export default connect(
  ({ clouds_new }) => ({ clouds_new }),
  dispatch => ({
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'clouds_new'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'clouds_new',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(CloudsNew);
