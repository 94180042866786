import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    width: '100%',
    '& h1': {
      fontSize: '12.5rem',
      lineHeight: '100%'
    },
    '& h2': {
      fontSize: '3rem',
      lineHeight: '100%'
    },
    '& img': {
      marginTop: theme.spacing(2)
    }
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export function ForbiddenView() {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="h1" className={classes.title}>
            403
          </Typography>
          <Typography variant="h2">Error Forbidden</Typography>
        </Box>
        <img src="/images/forbidden.svg" alt="Forbidden" />
      </Box>
    </Box>
  );
}
