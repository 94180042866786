import React from 'react';

import { Dialog, Tabs, Tab } from '@material-ui/core';
import { DialogTitle } from 'oldComponents/LegacyDialog';
import PropTypes from 'prop-types';

import TabPanel from './components/TabPanel';
import DeployManual from './DeployManual/index';
import DeployWebhook from './DeployWebhook/index';

const Dialog1 = ({
  handleClose,
  handleSave,
  open,
  application,
  version,
  setVersion,
  deployType,
  setDeployType,
  deployV2,
  applicationUid,
  orgUid
}) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [value, setValue] = React.useState(0);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const handleOpen = () => {
    setCurrentDate(new Date());
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="new-timelineDeploy-dialog"
        onClose={handleClose}
        onEntering={handleOpen}
        fullWidth>
        <DialogTitle id="new-timelineDeploy-dialog" onClose={handleClose}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="deploy-tabs"
            indicatorColor="primary"
            textColor="primary"
            style={{
              marginLeft: 10
            }}
            scrollButtons="auto">
            <Tab label="Deploy" />
            <Tab label="Deploy by API" />
          </Tabs>
        </DialogTitle>
        <TabPanel value={value} index={0}>
          <DeployManual
            handleSave={handleSave}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            version={version}
            setVersion={setVersion}
            setDeployType={setDeployType}
            deployType={deployType}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DeployWebhook
            application={application}
            applicationUid={applicationUid}
            deployV2={deployV2}
            orgUid={orgUid}
          />
        </TabPanel>
      </Dialog>
    </>
  );
};

// FIXME: identifier "Dialog" has already been declared or isn't helpful, rename this please!
export default Dialog1;
