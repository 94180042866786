import { useEffect } from 'react';

import { Box, Typography, useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import ModalCompound from 'componentsV3/ModalCompound';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

function ModalAllReady({
  title,
  description,
  onClick,
  disabled = false,
  mainAction,
  mainActionLabel,
  firstAccess,
  applicationType,
  ...otherProps
}) {
  const theme = useTheme();

  const redirectAnaliticEvent = () => {
    const eventsMap = {
      internalMultiHttp: firstAccess
        ? 'fa_button_modal_confirm_multi'
        : 'button_modal_confirm_multi',
      externalWithoutAddons: firstAccess
        ? 'fa_go_to_application_page_button_epdw'
        : 'go_to_application_page_button_epdw',
      internal: firstAccess
        ? 'fa_go_to_application_page_button_iaea'
        : 'go_to_application_page_button_iaea',
      externalWithAddons: firstAccess
        ? 'fa_go_to_application_page_button_eaea'
        : 'go_to_application_page_button_eaea'
    };

    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }
  };

  useEffect(
    () => {
      const eventsMap = {
        internalMultiHttp: firstAccess ? 'fa_view_modal_app_multi' : 'view_modal_app_multi',
        externalWithoutAddons: firstAccess
          ? 'fa_new_epwd_confirmation_modal'
          : 'new_epwd_confirmation_modal',
        internal: firstAccess ? 'fa_new_iaea_confirmation_modal' : 'new_iaea_confirmation_modal',
        externalWithAddons: firstAccess
          ? 'fa_new_eaea_confirmation_modal'
          : 'new_eaea_confirmation_modal'
      };

      if (otherProps.isOpen && eventsMap[applicationType]) {
        analyticsEvent(eventsMap[applicationType]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [otherProps.isOpen]
  );

  return (
    <ModalCompound {...otherProps}>
      <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
        <CheckCircleOutlineIcon style={{ fontSize: 45, fill: theme.palette.primary.dark }} />

        <Box marginTop={1}>
          <Typography variant="h2">{title}</Typography>
        </Box>

        <Box marginTop={2} marginBottom={3}>
          <Typography>{description}</Typography>
        </Box>

        <ButtonSimple
          color="primary"
          text={mainActionLabel}
          disabled={disabled}
          onClick={() => {
            redirectAnaliticEvent();
            mainAction();
          }}
        />
      </Box>
    </ModalCompound>
  );
}

export default withThemeV3Hoc(ModalAllReady);
