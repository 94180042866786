import { Button, ButtonProps as MuiButtonProps, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './style';

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  isLoading?: boolean;
  size: 'small' | 'medium';
};

export const ButtonDefault = ({
  className,
  variant,
  size = 'medium',
  children,
  isLoading,
  startIcon,
  endIcon,
  ...props
}: ButtonProps) => {
  const classes = useStyles({ size });
  return (
    <Button
      startIcon={isLoading ? undefined : startIcon}
      endIcon={isLoading ? undefined : endIcon}
      className={clsx(
        classes.base,
        {
          [classes.buttonContained]: variant === 'contained',
          [classes.buttonOutlined]: variant === 'outlined',
          [classes.buttonText]: variant === 'text'
        },
        size && classes[size],
        className
      )}
      {...props}>
      {children}
      {isLoading ? (
        <CircularProgress className={classes.spacingLoading} color="primary" size={24} />
      ) : null}
    </Button>
  );
};
