export const getDescription = (appName, type) => {
  if (!appName) {
    appName = '';
  }
  switch (type) {
    case 'deploy':
      return `${appName} is DEPLOYED`;
    case 'post_mortem':
      return 'Post mortem';
    case 'alarmed':
      return `${appName} is DOWN`;
    case 'acknowledged':
      return `${appName} is DOWN`;
    case 'change_description':
      return `${appName} is CHANGED`;
    case 'resolved':
      return `${appName} is UP`;
    case 'rollback':
      return `${appName} is Rollback`;
    case 'hotfix':
      return `${appName} is Hotfix`;
    default:
      return null;
  }
};
