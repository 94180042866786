import { useState, useCallback, useEffect } from 'react';

import { apm } from '@elastic/apm-rum';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import useQueryParams from 'helpers/useQueryParams';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { Incident, IncidentNewFields } from './types';

const AcknowledgeIncidentMutation = `#graphql
  mutation(
    $incidentId: Int!,
  ) {
    acknowledgeIncident (
      incidentId: $incidentId,
    ) {
      ok
      message
    }
  }
`;

type AcknowledgeIncidentMutationVariables = {
  incidentId: number;
};

const useAcknowledgeIncident = ({
  incident,
  isResolved,
  isAcknowledged,
  onAcknowledge
}: {
  incident: Incident & Partial<IncidentNewFields>;
  isResolved: boolean;
  isAcknowledged: boolean;
  onAcknowledge: () => void;
}) => {
  const [acknowledge, setAcknowledge] = useState(useQueryParams().get('acknowledge') === 'true');

  const dispatch = useDispatch();

  const [acknowledgeIncidentMutationResult, acknowledgeIncidentMutation] = useMutation<
    {
      acknowledgeIncident: { ok: boolean; message: string };
    },
    AcknowledgeIncidentMutationVariables
  >(AcknowledgeIncidentMutation);

  const isFetching = acknowledgeIncidentMutationResult.fetching;

  const hasIncidentAcknowledgePermission = usePermission(
    'IncidentController-post-/incidents/:incidentId/acknowledge'
  );

  const canAcknowledge =
    hasIncidentAcknowledgePermission && !(isResolved || isAcknowledged || isFetching);

  const handleAcknowledge = useCallback(() => {
    if (!canAcknowledge) {
      return;
    }

    acknowledgeIncidentMutation({
      incidentId: incident.incidentId
    })
      .then(result => {
        if (result.data?.acknowledgeIncident?.ok) {
          onAcknowledge();

          return dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Acknowledge Successful'
          });
        }

        const error = new Error(
          result.data?.acknowledgeIncident?.message || "Couldn't acknowledge incident :("
        );

        apm.setCustomContext({
          graphqlRequestError: result.error,
          graphqlRequestData: result.data
        });
        apm.captureError(error);

        throw error;
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  }, [acknowledgeIncidentMutation, canAcknowledge, dispatch, incident.incidentId, onAcknowledge]);

  useEffect(() => {
    if (acknowledge) {
      handleAcknowledge();
      setAcknowledge(false);
    }
  }, [acknowledge, handleAcknowledge]);

  return { handleAcknowledge, isFetching, canAcknowledge };
};

export default useAcknowledgeIncident;
