import cloudProviders from 'constants/cloudProviders';

const mapDataToApi = data => {
  const getVpcLabel = vpc => {
    const Name = vpc.Tags.find(({ Key }) => Key === 'Name')?.Value;
    const name = `(${Name ? Name : 'no name'})`;
    const vpcDefault = vpc.IsDefault ? '(default)' : '';
    return `${vpc.VpcId} ${name} ${vpcDefault}`.trim();
  };

  const getSubnetLabel = subnet => {
    const name = `(${subnet?.subnetName ? subnet?.subnetName : 'no name'})`;
    return `${subnet?.subnetId} ${name}`.trim();
  };

  const awsFields =
    data.provider === cloudProviders.aws
      ? {
          instanceType: data.instanceType,
          region: data.region,
          associatePublicIpAddress: data.associatePublicIpAddress === 'true' ? true : false,
          vpcId: data.vpc.VpcId,
          vpcLabel: getVpcLabel(data.vpc),
          vpcDefault: data.vpc.IsDefault,
          subnetId: data.subnet.subnetId,
          subnetLabel: getSubnetLabel(data.subnet)
        }
      : {};

  const azureFields =
    data.provider === cloudProviders.azure
      ? {
          vnetName: data.vnet.name,
          location: data.location,
          subnetName: data.subnetAzure.name,
          resourceGroup: data.resourceGroup
        }
      : {};

  return {
    name: data.name,
    cloudName: data.cloud.name,
    provider: data.provider,
    tag: data.tag,
    ...awsFields,
    ...azureFields
  };
};
export default mapDataToApi;
