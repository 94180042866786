import { Chip } from '@material-ui/core';
import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import { useStyles } from './styles';

export type StatusChipProps = {
  status: ResourceStatusEnum | MultiFlowStatusEnum;
};

function getResourceLabel(status: ResourceStatusEnum) {
  const resourceStatusLabelMap = {
    [ResourceStatusEnum.Off]: 'Inactive',
    [ResourceStatusEnum.Available]: 'Operational',
    [ResourceStatusEnum.Unavailable]: 'Not operational',
    [ResourceStatusEnum.Pending]: 'Pending',
    [ResourceStatusEnum.Maintenance]: 'In maintenance'
  };

  return resourceStatusLabelMap[status];
}

function getMultiflowLabel(status: MultiFlowStatusEnum) {
  const multiflowStatusLabelMap = {
    [MultiFlowStatusEnum.Off]: 'Off',
    [MultiFlowStatusEnum.Success]: 'Success',
    [MultiFlowStatusEnum.Error]: 'Error',
    [MultiFlowStatusEnum.Pending]: 'Pending',
    [MultiFlowStatusEnum.Maintenance]: 'In maintenance'
  };

  return multiflowStatusLabelMap[status];
}

export function StatusChip({
  status,
  entity
}: {
  status: ResourceStatusEnum | MultiFlowStatusEnum;
  entity: ApplicationManagerEntityType;
}) {
  const classes = useStyles({ status, entity });

  const isResource = entity === ApplicationManagerEntityType.Resource;

  return (
    <Chip
      className={classes.chip}
      label={
        isResource
          ? getResourceLabel(status as ResourceStatusEnum)
          : getMultiflowLabel(status as MultiFlowStatusEnum)
      }
    />
  );
}
