import { useQuery } from 'react-query';

import { directusV2 } from 'services/directus';

export function useSSOForm() {
  const query = useQuery('cms_sso_form', async () => {
    return directusV2.singleton('SSO_Form').read();
  });

  return query;
}
