import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DiscordIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      height="55"
      viewBox="0 0 24 24"
      width="71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path fill="none" d="M0 0h24v24H0z" />{' '}
      <path
        fill-rule="nonzero"
        d="M5.68 7.314l-1.82 5.914L12 19.442l8.14-6.214-1.82-5.914L16.643 11H7.356L5.681 7.314zM15.357 9l2.888-6.354a.4.4 0 0 1 .747.048l3.367 10.945a.5.5 0 0 1-.174.544L12 21.958 1.816 14.183a.5.5 0 0 1-.174-.544L5.009 2.694a.4.4 0 0 1 .747-.048L8.644 9h6.712z"
      />
    </SvgIcon>
  );
}
