import useMutationUrql from './useMutationUrql';

const useMutationPersonalised = ({
  serverData,
  onSubmit,
  teamId,
  timeUnits
}: {
  serverData: any;
  onSubmit: any;
  teamId: string;
  timeUnits: any;
}) => {
  const { newSubmitMutation: submitMutationUrql, error: errorUrql } = useMutationUrql({
    serverData,
    onSubmit,
    teamId,
    timeUnits
  });

  return {
    submitMutation: (props: any) => {
      return submitMutationUrql(props);
    },
    error: errorUrql
  };
};

export default useMutationPersonalised;
