import { green, red, orange, grey } from '@material-ui/core/colors';

const Status = {
  'DOES-NOT-DEPEND': {
    active: false,
    label: 'Does not depend',
    color: grey[300]
  },

  'MONITORING-IS-INACTIVE': {
    active: false,
    label: 'Inactive',
    color: grey[800]
  },

  UNAVAILABLE: {
    active: true,
    label: 'Not Operational',
    color: red[300]
  },

  AVAILABLE: {
    active: true,
    label: 'Operational',
    color: green[300]
  },

  INITIAL: {
    label: 'Pending',
    color: grey[400]
  },

  DEGRADED: {
    active: true,
    label: 'Degraded',
    color: orange[300]
  },

  MAINTENANCE: {
    active: false,
    label: 'In Maintenance',
    color: '#F57D27'
  }
};

export default Status;
