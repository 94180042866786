import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    minWidth: 122,
    height: 40
  },
  buttons: {
    minWidth: 122,
    height: 40
  },
  divider: {
    marginTop: theme.spacing(3)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  },
  deleteUser: {
    minWidth: 122,
    height: 40,
    right: theme.spacing(22)
  }
}));
