import actions from '../../actions';

const fetchSuccessful = (state, action) => {
  if (actions.TEAMS_FETCH_ENTITY_SUCCESSFUL === action.type) {
    const teams_view = state.teams_view;
    const entity = teams_view[action.meta.entity];

    return {
      ...state,
      teams_view: {
        ...teams_view,
        [action.meta.entity]: {
          ...entity,
          data: action.payload
        }
      }
    };
  }

  if (actions.TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL === action.type) {
    const teamsViewState = state.teams_view;
    // const stateEntity = teamsViewState[action.meta.entity];

    return {
      ...state,
      teams_view: {
        ...teamsViewState,
        autoComplete: {
          ...teamsViewState.autoComplete,
          options: action.payload,
          loading: false
        }
      }
    };
  }

  return state;
};

export default fetchSuccessful;
