import { MenuSidebar } from 'types/directus';

export const checkIsActive = ({ menuItem }: { menuItem: MenuSidebar }) => {
  const currentPath = window.location.pathname;
  const currentHash = window.location.hash;
  const currentQueryString = window.location.search;
  const currentFullPath = currentPath + currentQueryString + currentHash;

  if (menuItem.href && currentFullPath === menuItem.href) {
    return true;
  }

  if (
    menuItem.href &&
    !currentQueryString &&
    !currentHash &&
    currentPath.startsWith(menuItem.href)
  ) {
    return true;
  }

  if (menuItem.children.some(item => currentFullPath === item.href)) {
    return true;
  }

  if (
    menuItem.children.some(
      item => !currentQueryString && !currentHash && currentPath.startsWith(item.href)
    )
  ) {
    return true;
  }

  return false;
};
