import React from 'react';

import { Box, Button, Icon, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { Maintenance } from 'types/external/Maintenance';

import MaintenanceEditDialog from '../../../../Products/View/Availability/components/Maintenance/Dialog/MaintenanceEditDialog';
import { useStyles } from '../../styles';

export function MaintenanceButton({
  name,
  serviceId,
  uid,
  entity,
  maintenance,
  setMaintenance,
  reexecuteEntityQuery,
  disabled
}: {
  name: string;
  serviceId: number;
  uid: string;
  entity: string;
  maintenance: Maintenance;
  setMaintenance: (maintenance: Maintenance) => void;
  reexecuteEntityQuery: () => void;
  disabled: boolean;
}) {
  const classes = useStyles();

  const MaintenanceButton = React.useMemo(
    () => ({ onClick }: { onClick: () => void }) => {
      return (
        <Box display="inline">
          <Tooltip
            title={'Maintenance Window'}
            arrow
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow
            }}>
            <Button
              disabled={disabled}
              className={classes.icons}
              variant="outlined"
              type="submit"
              onClick={onClick}>
              <Icon fontSize="small">construction</Icon>
              <Box className={clsx(maintenance?.id && classes.outMarginPoint)} />
            </Button>
          </Tooltip>
        </Box>
      );
    },
    [
      maintenance,
      classes.outMarginPoint,
      classes.icons,
      classes.tooltip,
      classes.tooltipArrow,
      disabled
    ]
  );

  return (
    <MaintenanceEditDialog
      key="construction"
      openerComponent={MaintenanceButton}
      applicationName={name}
      serviceId={Number(serviceId)}
      applicationId={0}
      uid={uid}
      entity={entity}
      setActivated={setMaintenance}
      reexecuteEntityQuery={reexecuteEntityQuery}
    />
  );
}
