import { LOCATION_CHANGE } from 'connected-react-router';
import { parseUrl } from 'query-string';
import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';
import crudReducer from '../../reducer/crud';

function* resetPassword({ payload }) {
  try {
    const { location } = payload;
    const { query } = parseUrl(location.search);
    if (query.oobCode) {
      yield put({
        type: actions.POPULATE_ENTITY,
        payload: {
          code: query.oobCode
        },
        meta: {
          entity: 'reset_password',
          touched: {
            code: true
          },
          reducer: crudReducer,
          schema: {}
        }
      });
    }
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR' }
    });
  }
}

export default function* watchReset() {
  yield takeLatest(LOCATION_CHANGE, resetPassword);
}
