import { UseFormMethods } from 'react-hook-form';

import { AgentLayout } from '../layouts/Agent';
import { ApplicationLayout } from '../layouts/Application';
import { ExternalServiceLayout } from '../layouts/ExternalService';
import { IncidentManualLayout } from '../layouts/Incident';
import { ItsmJiraLayout } from '../layouts/ItsmJira';
import { ItsmServiceNowLayout } from '../layouts/ItsmServiceNow';
import { MonitoringLayout } from '../layouts/Monitoring';
import { MultiFlowLayout } from '../layouts/MultiFlow';
import { ProductLayout } from '../layouts/Product';
import { TeamsLayout } from '../layouts/Teams';
import { UserLayout } from '../layouts/User';

import { KubernetesForm } from './agent/Kubernetes';
import { OperationalSystemForm } from './agent/OperationalSystem';
import { ApplicationForm } from './application';
import { AlertCloudwatchGuide } from './externalService/alert/AlertCloudwatch';
import { AlertCustomGuide } from './externalService/alert/AlertCustomGuide';
import { AlertDatadogGuide } from './externalService/alert/AlertDatadog';
import { AlertDynatraceGuide } from './externalService/alert/AlertDynatraceGuide';
import { AlertGoogleCloudGuide } from './externalService/alert/AlertGoogleCloudGuide';
import { AlertGrafanaGuide } from './externalService/alert/AlertGrafanaGuide';
import { AlertMongoDbAtlasGuide } from './externalService/alert/AlertMongoDbAtlasGuide';
import { AlertNewrelicGuide } from './externalService/alert/AlertNewrelicGuide';
import { AlertPrometheusGuide } from './externalService/alert/AlertPrometheusGuide';
import { AlertSentryGuide } from './externalService/alert/AlertSentryGuide';
import { AlertZabbixGuide } from './externalService/alert/AlertZabbixGuide';
import { IncidentCloudwatchGuide } from './externalService/incident/IncidentCloudwatch';
import { IncidentCustomGuide } from './externalService/incident/IncidentCustomGuide';
import { IncidentDatadogGuide } from './externalService/incident/IncidentDatadog';
import { IncidentDynatraceGuide } from './externalService/incident/IncidentDynatraceGuide';
import { IncidentGoogleCloudGuide } from './externalService/incident/IncidentGoogleCloudGuide';
import { IncidentGrafanaGuide } from './externalService/incident/IncidentGrafanaGuide';
import { IncidentMongoDbAtlasGuide } from './externalService/incident/IncidentMongoDbAtlasGuide';
import { IncidentNewrelicGuide } from './externalService/incident/IncidentNewrelicGuide';
import { IncidentPrometheusGuide } from './externalService/incident/IncidentPrometheusGuide';
import { IncidentSentryGuide } from './externalService/incident/IncidentSentryGuide';
import { IncidentZabbixGuide } from './externalService/incident/IncidentZabbixGuide';
import { IncidentManualForm } from './incident/IncidentManual';
import { JiraForm } from './itsmIntegration/Jira';
import { ServiceNowForm } from './itsmIntegration/ServiceNow';
import { ApiForm } from './monitoring/api/API';
import { MemcachedForm } from './monitoring/cache/Memcached';
import { RedisForm } from './monitoring/cache/Redis';
import { CassandraForm } from './monitoring/database/Cassandra';
import { DynamodbForm } from './monitoring/database/Dynamodb';
import { MongoDBForm } from './monitoring/database/MongoDB';
import { MysqlForm } from './monitoring/database/Mysql';
import { Neo4jForm } from './monitoring/database/Neo4j';
import { OracleForm } from './monitoring/database/Oracle';
import { PostgresForm } from './monitoring/database/Postgres';
import { SqlserverForm } from './monitoring/database/SqlserverForm';
import { GetForm } from './monitoring/http/Get';
import { PostForm } from './monitoring/http/Post';
import { PingForm } from './monitoring/others/Ping';
import { TCPForm } from './monitoring/others/TCP';
import { KafkaForm } from './monitoring/queue/Kafka';
import { PubSubForm } from './monitoring/queue/PubSub';
import { RabbitmqForm } from './monitoring/queue/Rabbitmq';
import { ServiceBusForm } from './monitoring/queue/ServiceBus';
import { SidekiqForm } from './monitoring/queue/Sidekiq';
import { SqsForm } from './monitoring/queue/Sqs';
import { ElasticForm } from './monitoring/searchEngine/Elastic';
import { SolrForm } from './monitoring/searchEngine/Solr';
import { FunctionForm } from './monitoring/serveless/Function';
import { LambdaForm } from './monitoring/serveless/Lambda';
import { MultiFlowForm } from './multiflow/MultiFlow';
import { ProductForm } from './product';
import { TeamsForm } from './teams';
import { UserForm } from './User';

export type FormProps = {
  form: UseFormMethods<any>;
  field?: any;
  index?: number;
  startValues?: any;
  setSubmitDisabled?: any;
  domainType?: string;
};

export type FormType = {
  Layout: (props: { startValues?: any; isEdit?: boolean }) => JSX.Element;
  title: string;
  description: string;
  breadcrumb: string;
  baseBreadcrumb?: string;
  backLink: string;
  backLabel: string;
};

export type LayoutType = {
  Form: (props: FormProps) => JSX.Element;
  startValues?: any;
  isEdit?: boolean;
};

export const forms: Record<string, FormType> = {
  postgres: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Postgres',
    title: 'Monitor Postgres Database',
    description:
      'Postgres is an open source relational database management system. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={PostgresForm} isEdit={isEdit} />
    )
  },
  sqlserver: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'SQL Server',
    title: 'Monitor SQL Server Database',
    description:
      'SQL Server is a relational database management system developed by Microsoft, used for storing and managing data in various applications. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={SqlserverForm} isEdit={isEdit} />
    )
  },
  cassandra: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Cassandra',
    title: 'Monitor Cassandra Database',
    description:
      'Cassandra is a highly scalable and distributed NoSQL database designed for handling large amounts of data across multiple servers. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={CassandraForm} isEdit={isEdit} />
    )
  },
  neo4j: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Neo4j',
    title: 'Monitor Neo4j Database',
    description:
      'Neo4j is a highly scalable and flexible graph database that leverages relationships to provide efficient storage and querying of connected data. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={Neo4jForm} isEdit={isEdit} />
    )
  },
  dynamodb: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'DynamoDB',
    title: 'Monitor DynamoDB Database',
    description:
      'DynamoDB is a fully managed NoSQL database service provided by Amazon Web Services (AWS), offering high scalability and performance. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={DynamodbForm} isEdit={isEdit} />
    )
  },
  memcached: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Memcached',
    title: 'Monitor Memcached Cache',
    description:
      'Memcache is a distributed memory caching system used to improve performance by storing frequently accessed data in memory. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout Form={MemcachedForm} startValues={startValues} isEdit={isEdit} />
    )
  },
  redisv2: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'redis',
    title: 'Monitor Redis Cache',
    description:
      'Redis is an in-memory data structure store known for its high performance and versatility in caching, messaging, and data manipulation. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={RedisForm} isEdit={isEdit} />
    )
  },
  get: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'HTTP GET',
    title: 'Monitor an HTTP GET',
    description:
      'A request method that retrieves data from a server using the GET method in the HTTP protocol. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout Form={GetForm} startValues={startValues} isEdit={isEdit} />
    )
  },
  post: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'HTTP POST',
    title: 'Monitor an HTTP POST',
    description:
      'It is a request method that sends data to a server to create or update a resource. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout Form={PostForm} startValues={startValues} isEdit={isEdit} />
    )
  },
  'agent-kubernetes': {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Kubernetes Agent',
    title: 'Kubernetes Agent',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at aliquet nibh',
    Layout: () => <AgentLayout Form={KubernetesForm} />
  },
  'agent-os': {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Operacional System Agent',
    title: 'Operacional System Agent',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at aliquet nibh',
    Layout: () => <AgentLayout Form={OperationalSystemForm} />
  },
  synthetic: {
    backLabel: 'Synthetic Center',
    backLink: '/synthetic',
    baseBreadcrumb: 'Monitoring',
    breadcrumb: 'Synthetic Monitoring',
    title: 'Monitor Synthetic monitoring',
    description:
      'Used to monitor multi-step processes within your infrastructure. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ isEdit }) => <MultiFlowLayout Form={MultiFlowForm} isEdit={isEdit} />
  },
  mongodb: {
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'MongoDB',
    title: 'Monitor MongoDB Database',
    description:
      'MongoDB is an open source relational database management system. Here you can configure its monitoring and create alerts and incidents thresholds.',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={MongoDBForm} isEdit={isEdit} />
    )
  },
  oracle: {
    description:
      'Oracle DB is a powerful relational database management system known for its scalability, security, and comprehensive feature set. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Oracle Database',
    title: 'Monitor Oracle Database',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={OracleForm} isEdit={isEdit} />
    )
  },
  mysql: {
    description:
      'MySQL is an open-source relational database management system used for storing, managing, and retrieving data efficiently in various applications. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'MySQL Database',
    title: 'Monitor MySQL Database',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={MysqlForm} isEdit={isEdit} />
    )
  },
  kafka: {
    description:
      'Kafka is a distributed streaming platform that allows for the efficient, fault-tolerant, and real-time processing of large-scale data streams. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Kafka Queue',
    title: 'Monitor Kafka Queue',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={KafkaForm} isEdit={isEdit} />
    )
  },
  rabbitmq: {
    description:
      'RabbitMQ is a robust and flexible message broker that enables efficient communication and integration between distributed systems and applications. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Rabbitmq Queue',
    title: 'Monitor Rabbitmq Queue',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={RabbitmqForm} isEdit={isEdit} />
    )
  },
  lambdaawsv2: {
    description:
      'Lambda is a serverless compute service provided by AWS, allowing developers to run code in response to events without managing infrastructure. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'AWS Lambda',
    title: 'Monitor a AWS Lambda',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={LambdaForm} isEdit={isEdit} />
    )
  },
  functions: {
    description:
      'Functions, also known as Cloud Functions, is a serverless compute service by Google Cloud Platform (GCP) for executing event-driven functions without infrastructure management. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'GCP Function',
    title: 'Monitor a GCP Function',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={FunctionForm} isEdit={isEdit} />
    )
  },
  'incident-manual': {
    description: 'incident manually and notify your team instantly.',
    backLabel: 'Incident Management',
    backLink: '/incidents',
    breadcrumb: 'incident',
    title: 'incident',
    Layout: ({ isEdit }) => <IncidentManualLayout Form={IncidentManualForm} isEdit={isEdit} />
  },
  ping: {
    description:
      'Ping is a network utility used to check the reachability and response time of a host or IP address in a network. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Ping check',
    title: 'Monitor via Ping',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={PingForm} isEdit={isEdit} />
    )
  },
  tcp: {
    description:
      'TCP (Transmission Control Protocol) is a reliable, connection-oriented protocol that ensures error-free and ordered data transmission over IP networks.Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'TCP check',
    title: 'Monitor via TCP',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={TCPForm} isEdit={isEdit} />
    )
  },
  grpc: {
    description:
      'gRPC is a high-performance, open-source framework that facilitates efficient and scalable communication between distributed systems using remote procedure calls (RPCs). Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'gRPC check',
    title: 'Monitor via gRPC',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={TCPForm} isEdit={isEdit} />
    )
  },
  elastic: {
    description:
      'Elasticsearch is a highly scalable and distributed search and analytics engine used for real-time data indexing and search operations. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Elastic Search',
    title: 'Monitor Elastic Search',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={ElasticForm} isEdit={isEdit} />
    )
  },
  solr: {
    description:
      'Solr is a scalable, open-source search platform based on Apache Lucene, used for indexing and searching large volumes of data. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Solr',
    title: 'Monitor Solr',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={SolrForm} isEdit={isEdit} />
    )
  },
  sqs: {
    description:
      'SQL Server is a relational database management system developed by Microsoft, used for storing and managing data in various applications. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'SQS',
    title: 'Monitor SQS',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={SqsForm} isEdit={isEdit} />
    )
  },
  servicebus: {
    description:
      'Service Bus is a messaging service by Microsoft Azure that facilitates reliable and asynchronous communication between distributed systems and applications. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Service Bus (Azure)',
    title: 'Monitor a Service Bus (Azure)',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={ServiceBusForm} isEdit={isEdit} />
    )
  },
  functionsgen2: {
    description:
      'Functions, also known as Cloud Functions, is a serverless compute service by Google Cloud Platform (GCP) for executing event-driven functions without infrastructure management. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'GCP Function V2',
    title: 'Monitor a GCP Function V2',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={FunctionForm} isEdit={isEdit} />
    )
  },
  sidekiq: {
    description:
      'Sidekiq is a background processing framework for Ruby that efficiently manages and executes asynchronous jobs, improving application performance. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'Sidekiq',
    title: 'Monitor Sidekiq',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={SidekiqForm} isEdit={isEdit} />
    )
  },
  pubsub: {
    description:
      'PubSub is a messaging service by Google Cloud Platform (GCP) that enables reliable and scalable communication between independent applications and services. Here you can configure its monitoring and create alerts and incidents thresholds.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'GCP PubSub',
    title: 'Monitor a GCP PubSub',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={PubSubForm} isEdit={isEdit} />
    )
  },
  'itsm-service-now': {
    description: 'Integrate with ServiceNow',
    backLabel: 'Services Hub',
    backLink: '/services-hub',
    breadcrumb: 'New ServiceNow integration',
    title: 'ServiceNow integration',
    Layout: ({ isEdit }) => <ItsmServiceNowLayout Form={ServiceNowForm} isEdit={isEdit} />
  },
  'itsm-jira': {
    description: 'Integrate with Jira',
    backLabel: 'Services Hub',
    backLink: '/services-hub',
    breadcrumb: 'New Jira integration',
    title: 'Jira integration',
    Layout: ({ isEdit }) => <ItsmJiraLayout Form={JiraForm} isEdit={isEdit} />
  },
  users: {
    description: 'Add and manage new users to your work organization',
    backLabel: 'Users',
    backLink: '/users',
    breadcrumb: 'User',
    title: 'User',
    Layout: ({ isEdit }) => <UserLayout Form={UserForm} isEdit={isEdit} />
  },
  product: {
    backLabel: 'Product Center',
    backLink: '/products',
    baseBreadcrumb: 'Monitoring Aggregator',
    breadcrumb: 'Product Configuration',
    title: 'Product',
    description: `The creation of products keeps your organization's monitoring grouped according to each context. Grouping your website, applications, or integrations in one single place!`,
    Layout: ({ isEdit }) => <ProductLayout Form={ProductForm} isEdit={isEdit} />
  },
  api: {
    description:
      'monitoring feature where clients send us server data via curl. Configure monitoring and set thresholds for alerts and incidents.',
    backLabel: 'Resource Center',
    baseBreadcrumb: 'Monitoring',
    backLink: '/monitoring',
    breadcrumb: 'API',
    title: 'Monitor via API',
    Layout: ({ startValues, isEdit }) => (
      <MonitoringLayout startValues={startValues} Form={ApiForm} isEdit={isEdit} />
    )
  },
  'incident-datadog': {
    description: 'Integrate with Datadog and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Datadog Incident',
    title: 'external service incident Datadog',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentDatadogGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-datadog': {
    description: 'Integrate with Datadog and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Datadog Alert',
    title: 'external service alert Datadog',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertDatadogGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-cloudwatch': {
    description: 'Integrate with Cloudwatch and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Cloudwatch Incident',
    title: 'external service incident Cloudwatch',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentCloudwatchGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-cloudwatch': {
    description: 'Integrate with Cloudwatch and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Cloudwatch Alert',
    title: 'external service alert Cloudwatch',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertCloudwatchGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-dynatrace': {
    description: 'Integrate with Dynatrace and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Dynatrace Incident',
    title: 'external service incident Dynatrace',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentDynatraceGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-grafana': {
    description: 'Integrate with Grafana and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Grafana Incident',
    title: 'external service incident Grafana',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentGrafanaGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-newrelic': {
    description: 'Integrate with New Relic and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Newrelic Incident',
    title: 'external service incident Newrelic',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentNewrelicGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-newrelic': {
    description: 'Integrate with New Relic and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Newrelic Alert',
    title: 'external service alert New Relic',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertNewrelicGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-prometheus': {
    description: 'Integrate with Prometheus and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Prometheus Incident',
    title: 'external service incident Prometheus',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentPrometheusGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-prometheus': {
    description: 'Integrate with Prometheus and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Prometheus Alert',
    title: 'external service alert Prometheus',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertPrometheusGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-custom': {
    description: 'Create a custom integration and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Custom Incident',
    title: 'external service incident Custom',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentCustomGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-custom': {
    description: 'Create a custom integration and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Custom Alert',
    title: 'external service alert Custom',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertCustomGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-sentry': {
    description: 'Integrate with Sentry and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Sentry Incident',
    title: 'external service incident Sentry',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentSentryGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-sentry': {
    description: 'Integrate with Sentry and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Sentry Alert',
    title: 'external service alert Sentry',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertSentryGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-zabbix': {
    description: 'Integrate with Zabbix and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Zabbix Incident',
    title: 'external service incident Zabbix',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentZabbixGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-zabbix': {
    description: 'Integrate with Zabbix and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Zabbix Alert',
    title: 'external service alert Zabbix',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertZabbixGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-googlecloud': {
    description: 'Integrate with Google Cloud and be notified in case of interruptions',
    backLabel: ' External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Google Incident',
    title: 'external service incident Google Cloud',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentGoogleCloudGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-googlecloud': {
    description: 'Integrate with Google Cloud and be notified in case of interruptions',
    backLabel: ' External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Google Alert',
    title: 'external service alert Google Cloud',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertGoogleCloudGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-grafana': {
    description: 'Integrate with Grafana and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Grafana Alert',
    title: 'external service alert Grafana',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertGrafanaGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-dynatrace': {
    description: 'Integrate with Dynatrace and be notified in case of interruptions',
    backLabel: 'External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'Dynatrace Alert',
    title: 'external service alert Dynatrace',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertDynatraceGuide}
        isEdit={isEdit}
      />
    )
  },
  'alert-mongodb-atlas': {
    description: 'Integrate with MongoDB Atlas and be notified in case of interruptions',
    backLabel: ' External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'MongoDB Alert',
    title: 'external service alert MongoDB Atlas',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={AlertMongoDbAtlasGuide}
        isEdit={isEdit}
      />
    )
  },
  'incident-mongodb-atlas': {
    description: 'Integrate with MongoDB Atlas and be notified in case of interruptions',
    backLabel: ' External Services',
    baseBreadcrumb: 'Monitoring',
    backLink: '/external-services',
    breadcrumb: 'MongoDB Incident',
    title: 'external service incident MongoDB Atlas',
    Layout: ({ startValues, isEdit }) => (
      <ExternalServiceLayout
        startValues={startValues}
        GuideProps={IncidentMongoDbAtlasGuide}
        isEdit={isEdit}
      />
    )
  },
  application: {
    backLabel: 'Application Center',
    baseBreadcrumb: 'Monitoring Aggregator',
    backLink: '/applications',
    breadcrumb: 'Application Configuration',
    title: 'Application',
    description:
      'Create applications to centralize your monitoring and expand your visualization of monitored resources in a single location.',
    Layout: ({ isEdit }) => <ApplicationLayout Form={ApplicationForm} isEdit={isEdit} />
  },
  teams: {
    backLabel: 'Teams',
    backLink: '/teams',
    baseBreadcrumb: 'Teams',
    breadcrumb: 'Teams',
    title: 'Team',
    description: `Teams`,
    Layout: ({ isEdit }) => <TeamsLayout Form={TeamsForm} isEdit={isEdit} />
  }
};
