import { ServiceItem } from 'types/cms/ServicesHub';

import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const useServicePermission = (item: ServiceItem): boolean => {
  const userData = useUser({});
  const featureFlag = useFeatureFlagUnleash(item.featureFlag, { queryString: true });

  if (item.title === '') {
    return true;
  }

  if (item.hidden) {
    return false;
  }

  const planName = userData.data?.logged?.org?.planName;
  const isFeatureEnabled = item.plans.hasOwnProperty(planName) && item.plans[planName];

  if (!isFeatureEnabled) {
    return false;
  }

  if (item.featureFlag === '') {
    return true;
  }

  return featureFlag;
};

export default useServicePermission;
