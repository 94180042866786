import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';

import { Input } from 'views/ServicesHub/components/Input';

import { useStyles } from '../../styles';

interface CustomAutocompleteProps<T> {
  origins: T[];
  handleChangeOrigin: (value: T[]) => void;
  orderedOrigins: T[];
  getOptionLabel: (option: T) => string;
  getOptionSelected: (option: T, value: T) => boolean;
  label: string;
}

function CustomAutocomplete<T>({
  origins,
  handleChangeOrigin,
  orderedOrigins,
  getOptionLabel,
  getOptionSelected,
  label
}: CustomAutocompleteProps<T>) {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      value={origins}
      onChange={(_, value) => handleChangeOrigin(value as T[])}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      options={orderedOrigins}
      size="small"
      fullWidth
      ChipProps={{
        color: 'secondary'
      }}
      renderInput={params => (
        <Input
          {...params}
          label={label}
          required={false}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            className: classNames(classes.autocomplete, params.InputProps?.className)
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
