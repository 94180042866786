import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

const getMTTsQuery = (query: string) => `#graphql
    query (
      $startDate: String! 
      $endDate: String!    
      $originUid: String!  
    ) {
      insights {
        incidents {
          ${query}(startDate: $startDate, endDate: $endDate, originUid: $originUid){
            period
            compared
          }
        }      
      }
    }
  `;

const getMTTQuery = (query: string, periods: string[]) => `#graphql
 query(
    $serviceId: Int!
  ) {
    ${query}(
      serviceId: $serviceId
    ) {
      period {
        ${periods.join('\n')}
      }
    }
  }
`;

const endDate = dayjs().format('YYYY-MM-DD');
const startDate = dayjs()
  .subtract(30, 'day')
  .format('YYYY-MM-DD');

function getAvailablePeriods(enabledDataRetentions: {
  canFetchDataFromTheLast30Days: boolean | null;
}): string[] {
  return [
    {
      field: 'oneDay',
      enabled: true // always available in Free
    },
    {
      field: 'thirtyDays',
      enabled: enabledDataRetentions.canFetchDataFromTheLast30Days
    }
  ]
    .filter(option => option.enabled)
    .map(({ field }) => field);
}

const useMtta = ({
  serviceId,
  disabled = false,
  useInsightsForMTTs = false
}: {
  serviceId?: number;
  disabled?: boolean;
  useInsightsForMTTs?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !serviceId;

  const [resultMtta, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMtta', periods),
    pause: pause || disabled || useInsightsForMTTs,
    variables: { serviceId }
  });

  const [{ data, fetching: fetchingMTTs }, reexecuteQueryMTTs] = useQuery({
    query: getMTTsQuery('mtta'),
    pause: !useInsightsForMTTs,
    variables: {
      startDate,
      endDate,
      originUid: String(serviceId)
    }
  });

  const { fetching } = resultMtta || fetchingMTTs;

  const mtta = data?.insights?.incidents?.mtta.period ?? 0;

  const dataFromGraphQl = useInsightsForMTTs ? mtta : resultMtta.data?.getMtta;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      useInsightsForMTTs ? reexecuteQueryMTTs() : reexecuteQuery();
    }, 1000 * 60);

    return () => clearInterval(intervalId);
  }, [fetching, useInsightsForMTTs, reexecuteQueryMTTs, reexecuteQuery]);

  return dataFromGraphQl;
};

const useMtbf = ({
  serviceId,
  disabled = false,
  useInsightsForMTTs = false
}: {
  serviceId?: number;
  disabled?: boolean;
  useInsightsForMTTs?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !serviceId;

  const [resultMtbf, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMtbf', periods),
    pause: pause || disabled || useInsightsForMTTs,
    variables: { serviceId }
  });

  const [{ data, fetching: fetchingMTTs }, reexecuteQueryMTTs] = useQuery({
    query: getMTTsQuery('mtbf'),
    pause: !useInsightsForMTTs,
    variables: {
      startDate,
      endDate,
      originUid: String(serviceId)
    }
  });

  const { fetching } = resultMtbf || fetchingMTTs;

  const mtbf = data?.insights?.incidents?.mtbf.period ?? 0;

  const dataFromGraphQl = useInsightsForMTTs ? mtbf : resultMtbf.data?.getMtbf;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      useInsightsForMTTs ? reexecuteQueryMTTs() : reexecuteQuery();
    }, 1000 * 60);

    return () => clearInterval(intervalId);
  }, [fetching, useInsightsForMTTs, reexecuteQueryMTTs, reexecuteQuery]);

  return dataFromGraphQl;
};

const useMttrecovery = ({
  serviceId,
  disabled = false,
  useInsightsForMTTs = false
}: {
  serviceId?: number;
  disabled?: boolean;
  useInsightsForMTTs?: boolean;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics.SRE');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const periods = getAvailablePeriods({
    canFetchDataFromTheLast30Days
  });

  const pause = !canUseMetrics || periods.length === 0 || !serviceId;

  const [resultMttrecovery, reexecuteQuery] = useQuery({
    query: getMTTQuery('getMttrecovery', periods),
    pause: pause || disabled || useInsightsForMTTs,
    variables: { serviceId: serviceId }
  });

  const [{ data, fetching: fetchingMTTs }, reexecuteQueryMTTs] = useQuery({
    query: getMTTsQuery('mttr'),
    pause: !useInsightsForMTTs,
    variables: {
      startDate,
      endDate,
      originUid: String(serviceId)
    }
  });

  const { fetching } = resultMttrecovery || fetchingMTTs;

  const mttr = data?.insights?.incidents?.mttr.period ?? 0;

  const dataFromGraphQl = useInsightsForMTTs ? mttr : resultMttrecovery.data?.getMttrecovery;

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      useInsightsForMTTs ? reexecuteQueryMTTs() : reexecuteQuery();
    }, 1000 * 60);

    return () => clearInterval(intervalId);
  }, [fetching, useInsightsForMTTs, reexecuteQueryMTTs, reexecuteQuery]);

  return dataFromGraphQl;
};

export { useMtta, useMtbf, useMttrecovery };
