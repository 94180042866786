export enum ApplicationType {
  Internal = 'Internal',
  ExternalWithAddons = 'ExternalWithAddons',
  ExternalWithoutAddons = 'ExternalWithoutAddons',
  InternalMultiHttp = 'InternalMultiHttp',
  Webhook = 'Webhook'
}

const applicationType = {
  Internal: {
    type: 'internal',
    title: 'An internal application monitored by our agent',
    agentType: 'Internal',
    description: 'Your application monitored by our agent installed in your environment.',
    agentRequirement: 'Agent required',
    addonsInfo: 'Allow dependencies',
    predefinedSettings: {
      external: false
    }
  },

  ExternalWithAddons: {
    type: 'externalWithAddons',
    title: 'An external application with an Elvenworks agent',
    agentType: 'External',
    description: 'You will install our agent in your cloud and you can add dependencies.',
    agentRequirement: 'Agent required',
    addonsInfo: 'Allow dependencies',
    predefinedSettings: {
      external: true
    }
  },

  ExternalWithoutAddons: {
    type: 'externalWithoutAddons',
    title: 'Monitor a web application without a cloud account',
    agentType: 'External',

    description: 'An external application with no dependencies, such as your own website or blog.',
    agentRequirement: 'No agent',
    addonsInfo: 'No dependencies',
    predefinedSettings: {
      external: true
    }
  },

  Webhook: {
    type: 'webhook',
    title: 'Receive and record hits and failures via Webhook',
    agentType: 'N/A',
    description: 'Monitor via API, which will run the script and report the status to the platform',
    agentRequirement: 'No agent',
    addonsInfo: 'No dependencies',
    predefinedSettings: {}
  },

  InternalMultiHttp: {
    type: 'internalMultiHttp',
    title: 'Internal application with authentication request flow',
    agentType: 'Internal',
    description: 'Your application monitored by our agent installed in your environment.',
    agentRequirement: 'Agent required',
    addonsInfo: 'Allow dependencies',
    predefinedSettings: {
      external: false
    }
  }
};

export default applicationType;
