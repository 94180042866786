import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';

import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { Origin } from 'componentsV4/Filters/Search';
import { useServiceMonitors } from 'hooks/queriesGraphQL/useServiceMonitors';

import CustomAutocomplete from '../Common/customAutocompleteProps';

type HandleApplyFilters = ({
  newStatus,
  newSeverity,
  newOrigins
}: {
  newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
  newSeverity?: SeverityStatusEnum | 'all';
  newOrigins?: Origin[] | undefined;
}) => void;

export const OriginsFilter = ({
  origins,
  setOrigins,
  handleApplyFilters,
  originsQuery
}: {
  origins: Origin[];
  setOrigins: Dispatch<SetStateAction<Origin[]>>;
  handleApplyFilters: HandleApplyFilters;
  originsQuery: string;
}) => {
  const handleChangeOrigin = (event: Origin[]) => {
    setOrigins(event);
    handleApplyFilters({ newOrigins: event });
  };

  const [{ data }] = useServiceMonitors({
    query: originsQuery
  });

  const monitorsData = useMemo(() => {
    return data?.getMonitorsService?.data || [];
  }, [data]);

  const monitorsOptions = monitorsData.map(({ id, uid, type, name }) => ({
    value: type === 'service' ? String(id) : String(uid),
    label: name,
    type
  }));

  const orderedOrigins = monitorsOptions.filter(
    monitor => !origins?.find((origin: { value: string }) => monitor.value === origin.value)
  );

  useEffect(() => {
    if (
      monitorsData &&
      monitorsData.length &&
      origins &&
      origins.length &&
      origins[0]?.label === ''
    ) {
      const formatedOrigins = origins.map(origin => {
        const option = monitorsData.find(
          originOption =>
            originOption?.uid === origin?.value || originOption?.id?.toString() === origin?.value
        );

        const valueToUse = option?.uid?.length === 36 ? option?.uid : option?.id?.toString();

        return {
          label: option?.name || 'Without Name',
          value: valueToUse || origin?.value,
          type: option?.type || ''
        };
      });

      setOrigins(formatedOrigins);
    }
  }, [monitorsData, origins, setOrigins]);

  return (
    <CustomAutocomplete
      origins={origins}
      handleChangeOrigin={handleChangeOrigin}
      orderedOrigins={orderedOrigins}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      label="Origins"
    />
  );
};
