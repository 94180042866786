export const ListPostmortemsQuery = `#graphql
  query ListPostmortems(
    $query: String
    $size: Int!
    $from: Int!
  ) {
    postmortems(
      query: $query
      size: $size
      from: $from
    ) {
      data {
        title
        uid
      }
      
      total
    }
  }
`;
