import React from 'react';

import { makeStyles } from '@material-ui/core';
import RadioGroupBase from 'oldComponents/RadioGroup';

import { withThemeV4 } from 'theme/v4';

const useStyles = makeStyles(theme => {
  return {
    root: {
      justifyContent: 'flex-end'
    },
    checked: {
      color: theme.palette.primary.main
    },
    radioGrup: {
      display: 'flex'
    },
    radio: {
      color: theme.palette.grey[300]
    },
    label: {
      ...theme.typography.body2
    }
  };
});

const RadioGroup = ({ options, defaultValue, control, name, label, ...otherProps }) => {
  const classes = useStyles();

  return (
    <RadioGroupBase
      options={options}
      defaultValue={defaultValue}
      control={control}
      name={name}
      label={label}
      classes={classes}
      {...otherProps}
    />
  );
};

export default withThemeV4(RadioGroup);
