import React from 'react';

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import useListStepGroups from 'hooks/useListStepGroups';
import actions from 'redux/actions';
import axios from 'redux/axios';

import NewStepsGroupDialog from './NewStepGroupDialog';
import useForm, { timeUnits, defaultValues } from './NewStepGroupDialog/useForm';
const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    marginRight: theme.spacing()
  }
}));

const StepsGroups = ({ onChange, selectedStepsGroupId, fetchSchedulesList, permissions }) => {
  const classes = useStyles();

  const [selectedStepsGroup, setSelectedStepsGroup] = React.useState(selectedStepsGroupId || null);
  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, entity: null });

  const handleStepsGroupChange = value => {
    setSelectedStepsGroup(value);
    onChange(value);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { id: teamId } = useParams();

  const queryCache = useQueryCache();

  const queryKey = ['stepsGroups', teamId];

  const { stepsGroups, reexecuteQuery } = useListStepGroups({ teamId, queryKey });

  const stepsGroupValues = stepsGroups?.find(stepsGroup => stepsGroup.id === selectedStepsGroup);

  const invalidateQuery = () => {
    queryCache.invalidateQueries(queryKey);
  };

  const dispatch = useDispatch();

  const [deleteStepsGroup] = useMutation(
    id => {
      return axios.delete(`/callrotation/stepGroups/${id}`);
    },
    {
      onSuccess: () => {
        handleStepsGroupChange(null);
        invalidateQuery();
        reexecuteQuery();
      },
      onError: data => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: data.response?.data?.message || data.message }
        });
      }
    }
  );

  const formProps = useForm({
    onSubmit: response => {
      invalidateQuery();
      handleClose();
      handleStepsGroupChange(response.data.data.id);
      reexecuteQuery();
      fetchSchedulesList();
    },
    serverData: stepsGroupValues
  });

  return (
    <Box display="flex" alignItems="center">
      <FormControl className={classes.select} variant="outlined" size="small">
        <InputLabel id="demo-simple-select-outlined-label">Step Group</InputLabel>

        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Step Group"
          value={selectedStepsGroup || ''}
          disabled={!permissions?.newCallRotation}
          onChange={event => {
            event.persist();
            handleStepsGroupChange(event.target.value);
          }}>
          {stepsGroups &&
            stepsGroups.map(stepsGroup => {
              return (
                <MenuItem key={stepsGroup.id} value={stepsGroup.id}>
                  {stepsGroup.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <NewStepsGroupDialog
        open={open}
        mode={stepsGroupValues ? 'edit' : 'create'}
        onClose={handleClose}
        formProps={formProps}
        startValues={stepsGroupValues}
        canCreate={permissions?.newCallRotation}
      />

      <IconButton
        title="Create Step Groups"
        onClick={() => {
          formProps.reset({
            steps: [defaultValues]
          });
          handleStepsGroupChange(null);
          handleClickOpen();
        }}
        disabled={!permissions?.newCallRotation}>
        <AddIcon />
      </IconButton>

      <IconButton
        title="Edit Step Groups"
        onClick={() => {
          formProps.reset({
            stepsGroupName: stepsGroupValues.name,
            steps: stepsGroupValues.steps.map(step => ({
              ...step,
              teamMemberToContact: step.teamMemberToContact?.id
                ? step.teamMemberToContact.id
                : null,
              timeout: step.timeoutInMinutes,
              interval: step.intervalInMinutes,
              timeoutTimeUnit: timeUnits.minutes.value,
              intervalTimeUnit: timeUnits.minutes.value
            }))
          });
          handleClickOpen();
        }}
        disabled={!permissions?.editCallRotation || !selectedStepsGroup}>
        <EditIcon />
      </IconButton>

      <IconButton
        title="Remove Step Groups"
        onClick={() => {
          setDeleteModalState({ isOpen: true, entity: null });
        }}
        disabled={!permissions?.deleteCallRotation || !selectedStepsGroup}>
        <DeleteIcon />
      </IconButton>

      <DeleteEntity
        open={deleteModalState.isOpen}
        handleClose={() => setDeleteModalState({ isOpen: false, id: null })}
        handleRemove={() => {
          deleteStepsGroup(selectedStepsGroup);
          setDeleteModalState({ isOpen: false, entity: null });
        }}
        entityName="Steps Group"
        disabled={!permissions?.deleteCallRotation}></DeleteEntity>
    </Box>
  );
};

export default StepsGroups;
