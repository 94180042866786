import { Box } from '@material-ui/core';

import { FormProps } from '../';
import { Input } from '../../components/Input';

export function MultiFlowForm({ form }: FormProps) {
  const { register, errors } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input
        label="Interval check"
        name="intervalCheck"
        inputRef={register}
        type="number"
        errors={errors}
      />
    </Box>
  );
}
