import { Resource } from 'types/external/Resource';

import { GRPCFormData } from 'views/ServicesHub/forms/monitoring/others/GRPC';

import { monitoringAdapter } from '../monitoring';

export function grpcAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & GRPCFormData {
  const domain = data.domain;
  // ex: HOST:PORT

  const tcpDomainRegex = /(.+):(.+)/;

  const match = domain.match(tcpDomainRegex)!;

  const host = match[1];
  const port = match[2];

  return {
    ...monitoringAdapter(data),
    host,
    port
  };
}
