import React from 'react';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

const ButtonWithIcon = ({ text, title = '', disabled = false, startIcon, onClick }) => {
  const SimpleButtonStyle = withStyles(theme => ({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '8px 16px',
      borderRadius: '4px',
      lineHeight: 1.5,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.white
      }
    }
  }))(Button);

  return (
    <SimpleButtonStyle
      fullWidth
      disabled={disabled}
      title={title}
      startIcon={startIcon}
      onClick={onClick}>
      {text}
    </SimpleButtonStyle>
  );
};

export default withThemeV3Hoc(ButtonWithIcon);
