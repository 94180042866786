const NAMESPACE_MUI = '1P_';

const actions = {
  GO_BACK: `${NAMESPACE_MUI}GO_BACK`,
  GLOBAL_SUCCESS: `${NAMESPACE_MUI}GLOBAL_SUCCESS`,
  GLOBAL_WARNING: `${NAMESPACE_MUI}GLOBAL_WARNING`,

  POPULATE_ENTITY: `${NAMESPACE_MUI}POPULATE_ENTITY`,
  PAGINATE_ENTITY: `${NAMESPACE_MUI}PAGINATE_ENTITY`,
  SAVE_ENTITY: `${NAMESPACE_MUI}SAVE_ENTITY`,
  DELETE_ENTITY: `${NAMESPACE_MUI}DELETE_ENTITY`,
  PROVISION_ENTITY: `${NAMESPACE_MUI}PROVISION_ENTITY`,
  DEPROVISION_ENTITY: `${NAMESPACE_MUI}DEPROVISION_ENTITY`,
  EDIT_ENTITY: `${NAMESPACE_MUI}EDIT_ENTITY`,
  MERGE_ENTITY: `${NAMESPACE_MUI}MERGE_ENTITY`,

  SAVE_CLOUD_ENTITY: `${NAMESPACE_MUI}SAVE_CLOUD_ENTITY`,
  EDIT_CLOUD_ENTITY: `${NAMESPACE_MUI}EDIT_CLOUD_ENTITY`,

  FETCH_ENTITY: `${NAMESPACE_MUI}FETCH_ENTITY`,
  FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}FETCH_ENTITY_SUCCESSFUL`,
  ENTITY_ERROR: `${NAMESPACE_MUI}ENTITY_ERROR`,
  CLEAR_ERROR: `${NAMESPACE_MUI}CLEAR_ERROR`,
  RESET_ENTITY: `${NAMESPACE_MUI}RESET_ENTITY`,
  RESET_FORM_ENTITY: `${NAMESPACE_MUI}RESET_FORM_ENTITY`,

  SIGN_UP: `${NAMESPACE_MUI}SIGN_UP`,
  SIGN_UP_FAILED: `${NAMESPACE_MUI}SIGN_UP_FAILED`,
  LOGIN_USER_PASSWORD: `${NAMESPACE_MUI}LOGIN_USER_PASSWORD`,
  LOGIN_USER: `${NAMESPACE_MUI}LOGIN_USER`,
  LOGIN_USER_SUCCESSFUL: `${NAMESPACE_MUI}LOGIN_USER_SUCCESSFUL`,
  LOGIN_USER_FAILED: `${NAMESPACE_MUI}LOGIN_USER_FAILED`,
  LOGGED_USER: `${NAMESPACE_MUI}LOGGED_USER`,
  VALIDATE_EMAIL: `${NAMESPACE_MUI}VALIDATE_EMAIL`,
  SIGN_UP_INVALID_EMAIL: `${NAMESPACE_MUI}SIGN_UP_INVALID_EMAIL`,

  SIGN_IN_CHANGE: `${NAMESPACE_MUI}SIGN_IN_CHANGE`,
  SIGN_UP_CHANGE: `${NAMESPACE_MUI}SIGN_UP_CHANGE`,

  RECOVERY_USER: `${NAMESPACE_MUI}RECOVERY_USER`,
  RECOVERY_USER_ERROR: `${NAMESPACE_MUI}RECOVERY_USER_ERROR`,
  RESET_PASSWORD: `${NAMESPACE_MUI}RESET_PASSWORD`,
  RESET_PASSWORD_ERROR: `${NAMESPACE_MUI}RESET_PASSWORD_ERROR`,
  UPLOAD_AVATAR: `${NAMESPACE_MUI}UPLOAD_AVATAR`,
  UPLOAD_AVATAR_SUCCESSFUL: `${NAMESPACE_MUI}UPLOAD_AVATAR_SUCCESSFUL`,
  SAVE_SECURITY: `${NAMESPACE_MUI}SAVE_SECURITY`,
  FETCH_CEP: `${NAMESPACE_MUI}FETCH_CEP`,

  RELOAD: `${NAMESPACE_MUI}RELOAD`,

  TEAMS_ADD_ACCOUNT: `${NAMESPACE_MUI}TEAMS_ADD_ACCOUNT`,
  TEAMS_DELETE_MEMBER: `${NAMESPACE_MUI}TEAMS_DELETE_MEMBER`,
  TEAMS_CHANGE_TAB: `${NAMESPACE_MUI}TEAMS_CHANGE_TAB`,
  TEAMS_AUTOCOMPLETE_OPEN: `${NAMESPACE_MUI}TEAMS_AUTOCOMPLETE_OPEN`,
  TEAMS_AUTOCOMPLETE_CHANGE: `${NAMESPACE_MUI}TEAMS_AUTOCOMPLETE_CHANGE`,
  TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL`,
  TEAMS_FETCH_ENTITY: `${NAMESPACE_MUI}TEAMS_FETCH_ENTITY`,
  TEAMS_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}TEAMS_FETCH_ENTITY_SUCCESSFUL`,

  RESET_FORM_USER: `${NAMESPACE_MUI}RESET_FORM_USER`,
  USERS_FETCH: `${NAMESPACE_MUI}USERS_FETCH`,
  USERS_ADD: `${NAMESPACE_MUI}USERS_ADD`,
  USERS_REMOVE: `${NAMESPACE_MUI}USERS_REMOVE`,
  USERS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}USERS_FETCH_SUCCESSFUL`,
  USERS_POPULATE_ENTITY: `${NAMESPACE_MUI}USERS_POPULATE_ENTITY`,
  USERS_POPULATE_ENTITY_SIGNUP: `${NAMESPACE_MUI}USERS_POPULATE_ENTITY_SIGNUP`,
  USERS_SIGNUP: `${NAMESPACE_MUI}USERS_SIGNUP`,
  USERS_SIGNUP_ERROR: `${NAMESPACE_MUI}USERS_SIGNUP_ERROR`,

  MEMBERS_FETCH: `${NAMESPACE_MUI}MEMBERS_FETCH`,
  MEMBERS_ADD: `${NAMESPACE_MUI}MEMBERS_ADD`,
  MEMBERS_REMOVE: `${NAMESPACE_MUI}MEMBERS_REMOVE`,
  MEMBERS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}MEMBERS_FETCH_SUCCESSFUL`,
  MEMBERS_POPULATE_ENTITY: `${NAMESPACE_MUI}MEMBERS_POPULATE_ENTITY`,
  MEMBERS_POPULATE_ENTITY_SIGNUP: `${NAMESPACE_MUI}MEMBERS_POPULATE_ENTITY_SIGNUP`,
  MEMBERS_SIGNUP: `${NAMESPACE_MUI}MEMBERS_SIGNUP`,
  MEMBERS_SIGNUP_ERROR: `${NAMESPACE_MUI}MEMBERS_SIGNUP_ERROR`,
  MEMBERS_SAVE_ENTITY: `${NAMESPACE_MUI}MEMBERS_SAVE_ENTITY`,
  MEMBERS_AUTOCOMPLETE: `${NAMESPACE_MUI}MEMBERS_AUTOCOMPLETE`,
  MEMBERS_AUTOCOMPLETE_SUCCESSFUL: `${NAMESPACE_MUI}MEMBERS_AUTOCOMPLETE_SUCCESSFUL`,

  ENVIRONMENTS_FETCH: `${NAMESPACE_MUI}ENV_FETCH`,
  ENVIRONMENTS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}ENV_FETCH_SUCCESSFUL`,

  ENVIRONMENT_CHANGE_TAB: `${NAMESPACE_MUI}ENVIRONMENT_CHANGE_TAB`,
  ENVIRONMENT_FETCH: `${NAMESPACE_MUI}ENVIRONMENT_FETCH`,
  ENVIRONMENT_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}ENVIRONMENT_FETCH_SUCCESSFUL`,

  ENVIRONMENT_CLUSTERS_FETCH: `${NAMESPACE_MUI}ENVIRONMENT_CLUSTERS_FETCH`,
  ENVIRONMENT_CLUSTERS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}ENVIRONMENT_CLUSTERS_FETCH_SUCCESSFUL`,

  PRODUCT_NEW_CHANGE_NAME: `${NAMESPACE_MUI}PRODUCT_NEW_CHANGE_NAME`,
  PRODUCT_NEW_CHANGE_DESCRIPTION: `${NAMESPACE_MUI}PRODUCT_NEW_CHANGE_DESCRIPTION`,
  PRODUCT_NEW_STEPPER_ACTIVE: `${NAMESPACE_MUI}PRODUCT_NEW_STEPPER_ACTIVE`,
  PRODUCT_NEW_STEPPER_COMPLETE: `${NAMESPACE_MUI}PRODUCT_NEW_STEPPER_COMPLETE`,

  PRODUCT_CHANGE_TAB: `${NAMESPACE_MUI}PRODUCT_CHANGE_TAB`,
  PRODUCT_RESET: `${NAMESPACE_MUI}PRODUCT_RESET`,
  PRODUCT_FETCH: `${NAMESPACE_MUI}PRODUCT_FETCH`,
  PRODUCT_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_FETCH_SUCCESSFUL`,
  RESET_FORM_PRODUCT: `${NAMESPACE_MUI}RESET_FORM_PRODUCT`,
  PRODUCT_UPLOAD_REFRESH: `${NAMESPACE_MUI}PRODUCT_UPLOAD_REFRESH`,
  PRODUCT_UPLOAD_AVATAR: `${NAMESPACE_MUI}PRODUCT_UPLOAD_AVATAR`,
  PRODUCT_UPLOAD_AVATAR_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_UPLOAD_AVATAR_SUCCESSFUL`,
  PRODUCT_UPLOAD_NEW_AVATAR: `${NAMESPACE_MUI}PRODUCT_UPLOAD_NEW_AVATAR`,
  PRODUCT_UPLOAD_NEW_AVATAR_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_UPLOAD_NEW_AVATAR_SUCCESSFUL`,
  PRODUCT_EDIT_FETCH: `${NAMESPACE_MUI}PRODUCT_EDIT_FETCH`,
  PRODUCT_EDIT_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_EDIT_FETCH_SUCCESSFUL`,

  PRODUCT_APPLICATIONS_DELETE_ENTITY: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_DELETE_ENTITY`,

  PRODUCT_APPLICATIONS_FETCH: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_FETCH`,
  PRODUCT_APPLICATIONS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_FETCH_SUCCESSFUL`,

  PRODUCT_APPLICATIONS_MONITORING: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_MONITORING`,

  PRODUCT_APPLICATIONS_DEPLOY: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_DEPLOY`,

  PRODUCT_AVAILABILITY_APPLICATIONS_RESET: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_RESET`,
  PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_POPULATE_ENTITY: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_POPULATE_ENTITY`,
  PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH`,
  PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_EDIT_FETCH_SUCCESSFUL`,
  PRODUCT_AVAILABILITY_APPLICATIONS_POPULATE_ENTITY: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_POPULATE_ENTITY`,
  PRODUCT_AVAILABILITY_APPLICATIONS_SAVE: `${NAMESPACE_MUI}PRODUCT_AVAILABILITY_APPLICATIONS_SAVE`,

  PRODUCT_APPLICATIONS_RESET: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_RESET`,
  PRODUCT_APPLICATIONS_POPULATE_ENTITY: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_POPULATE_ENTITY`,
  PRODUCT_APPLICATIONS_NEW_FETCH: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_FETCH`,
  PRODUCT_APPLICATIONS_NEW_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_FETCH_SUCCESSFUL`,
  PRODUCT_APPLICATIONS_SAVE: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_SAVE`,
  PRODUCT_APPLICATIONS_NEW_ENVS_ADD: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_ENVS_ADD`,
  PRODUCT_APPLICATIONS_NEW_ENVS_REMOVE: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_ENVS_REMOVE`,

  PRODUCT_APPLICATIONS_EDIT_POPULATE_ENTITY: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_EDIT_POPULATE_ENTITY`,
  PRODUCT_APPLICATIONS_EDIT_FETCH: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_EDIT_FETCH`,
  PRODUCT_APPLICATIONS_EDIT_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_EDIT_FETCH_SUCCESSFUL`,

  PRODUCT_APPLICATIONS_SETTINGS_RESET: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_SETTINGS_RESET`,
  PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH`,
  PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH_SUCCESSFUL`,
  PRODUCT_APPLICATIONS_POPULATE_SETTINGS_ENTITY: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_POPULATE_SETTINGS_ENTITY`,

  PRODUCT_APPLICATIONS_VIEW_FETCH: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_VIEW_FETCH`,
  PRODUCT_APPLICATIONS_VIEW_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_VIEW_FETCH_SUCCESSFUL`,

  PRODUCT_STATUS_APPLICATIONS_FETCH: `${NAMESPACE_MUI}PRODUCT_STATUS_APPLICATIONS_FETCH`,
  PRODUCT_STATUS_APPLICATIONS_TIMER_STOP: `${NAMESPACE_MUI}PRODUCT_STATUS_APPLICATIONS_TIMER_STOP`,
  PRODUCT_STATUS_APPLICATIONS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_STATUS_APPLICATIONS_FETCH_SUCCESSFUL`,

  PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_START: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_START`,
  PRODUCT_APPLICATIONS_STATUS_PAGE_VIEW_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_STATUS_PAGE_VIEW_FETCH_SUCCESSFUL`,
  PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_STOP: `${NAMESPACE_MUI}PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_STOP`,

  PRODUCT_SERVICES_FETCH: `${NAMESPACE_MUI}PRODUCT_SERVICES_FETCH`,
  PRODUCT_SERVICES_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_SERVICES_FETCH_SUCCESSFUL`,

  PRODUCT_SERVICES_ADD_ACCOUNT: `${NAMESPACE_MUI}PRODUCT_SERVICES_ADD_ACCOUNT`,
  PRODUCT_SERVICES_REMOVE_ACCOUNT: `${NAMESPACE_MUI}PRODUCT_SERVICES_REMOVE_ACCOUNT`,
  PRODUCT_SERVICES_AUTOCOMPLETE_OPEN: `${NAMESPACE_MUI}PRODUCT_SERVICES_AUTOCOMPLETE_OPEN`,
  PRODUCT_SERVICES_AUTOCOMPLETE_CHANGE: `${NAMESPACE_MUI}PRODUCT_SERVICES_AUTOCOMPLETE_CHANGE`,
  PRODUCT_SERVICES_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_SERVICES_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL`,

  PRODUCT_TEAMS_FETCH: `${NAMESPACE_MUI}PRODUCT_TEAMS_FETCH`,
  PRODUCT_TEAMS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_TEAMS_FETCH_SUCCESSFUL`,

  PRODUCT_TEAMS_ADD_ACCOUNT: `${NAMESPACE_MUI}PRODUCT_TEAMS_ADD_ACCOUNT`,
  PRODUCT_TEAMS_REMOVE_ACCOUNT: `${NAMESPACE_MUI}PRODUCT_TEAMS_REMOVE_ACCOUNT`,
  PRODUCT_TEAMS_AUTOCOMPLETE_OPEN: `${NAMESPACE_MUI}PRODUCT_TEAMS_AUTOCOMPLETE_OPEN`,
  PRODUCT_TEAMS_AUTOCOMPLETE_CHANGE: `${NAMESPACE_MUI}PRODUCT_TEAMS_AUTOCOMPLETE_CHANGE`,
  PRODUCT_TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}PRODUCT_TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL`,

  APPLICATIONS_ADDONS_FETCH: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_FETCH`,
  APPLICATIONS_ADDONS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_FETCH_SUCCESSFUL`,
  APPLICATIONS_ADDONS_AUTOCOMPLETE_CHANGE: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_AUTOCOMPLETE_CHANGE`,
  APPLICATIONS_ADDONS_AUTOCOMPLETE_OPEN: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_AUTOCOMPLETE_OPEN`,
  APPLICATIONS_ADDONS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL`,
  APPLICATIONS_ADDONS_ADD_ADDON: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_ADD_ADDON`,
  APPLICATIONS_ADDONS_RESET: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_RESET`,
  APPLICATIONS_ADDONS_DELETE_ENTITY: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_DELETE_ENTITY`,

  APPLICATIONS_ADDONS_SETTINGS_FETCH: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_SETTINGS_FETCH`,
  APPLICATIONS_ADDONS_SETTINGS_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_SETTINGS_FETCH_SUCCESSFUL`,

  APPLICATIONS_ADDONS_POPULATE_SETTINGS_ENTITY: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_POPULATE_SETTINGS_ENTITY`,
  APPLICATIONS_ADDONS_NEW_ENVS_ADD: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_NEW_ENVS_ADD`,
  APPLICATIONS_ADDONS_NEW_ENVS_REMOVE: `${NAMESPACE_MUI}APPLICATIONS_ADDONS_NEW_ENVS_REMOVE`,

  APPLICATION_AVAILABILITY_VIEW_FETCH: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_VIEW_FETCH`,
  APPLICATION_AVAILABILITY_VIEW_RESET: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_VIEW_RESET`,
  APPLICATION_AVAILABILITY_VIEW_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_VIEW_FETCH_SUCCESSFUL`,
  APPLICATION_INCIDENT_ACKNOWLEDGE: `${NAMESPACE_MUI}APPLICATION_INCIDENT_ACKNOWLEDGE`,
  APPLICATION_AVAILABILITY_STATUS_TIMER: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_STATUS_TIMER`,
  APPLICATION_AVAILABILITY_STATUS_TIMER_SUCCESSFUL: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_STATUS_TIMER_SUCCESSFUL`,
  APPLICATION_AVAILABILITY_STATUS_TIMER_STOP: `${NAMESPACE_MUI}APPLICATION_AVAILABILITY_STATUS_TIMER_STOP`,

  SERVICE_NEW_SETTINGS_ADD: `${NAMESPACE_MUI}SERVICE_NEW_SETTINGS_ADD`,
  SERVICE_NEW_SETTINGS_REMOVE: `${NAMESPACE_MUI}SERVICE_NEW_SETTINGS_REMOVE`,
  SERVICE_NEW_POPULATE_SETTINGS_ENTITY: `${NAMESPACE_MUI}SERVICE_NEW_POPULATE_SETTINGS_ENTITY`,
  SERVICE_EDIT_SETTINGS_ADD: `${NAMESPACE_MUI}SERVICE_EDIT_SETTINGS_ADD`,
  SERVICE_EDIT_SETTINGS_REMOVE: `${NAMESPACE_MUI}SERVICE_EDIT_SETTINGS_REMOVE`,
  SERVICE_EDIT_POPULATE_SETTINGS_ENTITY: `${NAMESPACE_MUI}SERVICE_EDIT_POPULATE_SETTINGS_ENTITY`,

  FETCH_SERVICE: `${NAMESPACE_MUI}FETCH_SERVICE`,
  FETCH_SERVICE_SUCCESSFUL: `${NAMESPACE_MUI}FETCH_SERVICE_SUCCESSFUL`,

  INCIDENT_VIEW_FETCH: `${NAMESPACE_MUI}INCIDENT_VIEW_FETCH`,
  INCIDENT_VIEW_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}INCIDENT_VIEW_FETCH_SUCCESSFUL`,
  SAVE_INCIDENT_INFORMATIONS: `${NAMESPACE_MUI}SAVE_INCIDENT_INFORMATIONS`,

  CLOUD_CHECK: `${NAMESPACE_MUI}CLOUD_CHECK`,

  MATRIX_RESET: `${NAMESPACE_MUI}MATRIX_RESET`,
  MATRIX_CHANGE_TAB: `${NAMESPACE_MUI}MATRIX_CHANGE_TAB`,
  MATRIX_FETCH_SUCCESSFUL: `${NAMESPACE_MUI}MATRIX_FETCH_SUCCESSFUL`
};

export { actions as default, NAMESPACE_MUI };
