import { ReactNode } from 'react';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Popper,
  PopperProps,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useStyles } from './styles';

export type FiltersPopperProps = {
  anchorEl: HTMLElement | null;
  handleClose?: () => void;
  handleOpen?: () => void;
  handleClearFilters: () => void;
  handleApplyFilters: () => void;
  children: ReactNode;
} & Pick<PopperProps, 'open' | 'placement'>;

export function FiltersPopper({
  open,
  anchorEl,
  placement = 'bottom',
  handleClose,
  handleClearFilters,
  handleApplyFilters,
  children
}: FiltersPopperProps) {
  const classes = useStyles();
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      className={classes.popper}
      style={{ width: anchorEl?.getBoundingClientRect().width }}>
      <Paper className={classes.paper}>
        <Box className={classes.content}>
          <Box className={classes.dialogTitle}>
            <Typography variant="h4">Filters</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider className={classes.divider} />
          <Box className={classes.selectContainer}>{children}</Box>

          <Box className={classes.buttonsContainer} mt={2}>
            <Button onClick={handleClearFilters} variant="outlined" color="primary">
              Clear
            </Button>
            <Button onClick={handleApplyFilters} variant="contained" color="primary">
              Apply
            </Button>
          </Box>
        </Box>
      </Paper>
    </Popper>
  );
}
