import React from 'react';

import actions from 'redux/actions';

import Form from '../components/Form';

class CloudsEditForm extends React.Component {
  render() {
    const { formState, onChange } = this.props;

    return (
      <Form
        formState={formState}
        onChange={onChange}
        title="Edit Cloud"
        saveAction={actions.SAVE_CLOUD_ENTITY}
        cloudDisable
      />
    );
  }
}

export default CloudsEditForm;
