import { useState } from 'react';

import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ApplicationsList from 'componentsV3/CardResource/ApplicationsList';
import CustomButton from 'componentsV3/DropDownButton';
import withThemeV3Hoc from 'theme/v3/withTheme';

const DropDownApplication = ({
  productId,
  totalApplications
}: {
  productId: number;
  totalApplications: number;
}) => {
  const [anchorElAppDropDown, setAnchorElAppDropDown] = useState(null);

  const open = Boolean(anchorElAppDropDown);

  const openDropDown = (event: any) => {
    setAnchorElAppDropDown(event.currentTarget);
  };

  const handleCloseAppDropDown = () => {
    setAnchorElAppDropDown(null);
  };

  const popper = (
    <Popper id="applications-list" placement="top-start" anchorEl={anchorElAppDropDown} open={open}>
      <Paper style={{ minWidth: 343 }}>
        <ApplicationsList
          productId={productId}
          totalApplications={totalApplications}
          enableQuery={open}
        />
      </Paper>
    </Popper>
  );

  return (
    <Box>
      <CustomButton
        onClick={openDropDown}
        children={`${totalApplications} configured applications`}
        endIcon={<ExpandMoreIcon color="primary" style={{ fontSize: 30 }} />}
      />

      {!open && popper}
      {open && <ClickAwayListener onClickAway={handleCloseAppDropDown}>{popper}</ClickAwayListener>}
    </Box>
  );
};

export default withThemeV3Hoc(DropDownApplication);
