import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

const CustomButton = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: '4px',
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: (theme.palette as any).white
    }
  }
}))(Button);

export default withThemeV3Hoc(CustomButton);
