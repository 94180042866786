import { StepType } from './StepType';
import { TimeUnitsType } from './TimeUnitsType';

export const composeStep = ({
  step,
  index,
  timeUnits
}: {
  step: StepType;
  index: number;
  timeUnits: TimeUnitsType;
}) => {
  return {
    contactMethod: step.contactMethod,
    teamMemberToContact: null,
    memberFromTeams: step.teamMemberToContact,
    intervalInMinutes: Number(
      step.intervalTimeUnit === timeUnits.hours.value ? step.interval * 60 : step.interval
    ),
    timeoutInMinutes: Number(
      step.timeoutTimeUnit === timeUnits.hours.value ? step.timeout * 60 : step.timeout
    ),
    order: index,
    userUid: step?.userUid
  };
};
