import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { DeployEnum } from 'componentsV4/Filters';
import { Origin } from 'componentsV4/Filters/Search';

import { useStyles } from '../../styles';

export const DeployFilter = ({
  deployment,
  setDeployment,
  handleApplyFilters
}: {
  deployment: DeployEnum | 'all';
  setDeployment: Dispatch<SetStateAction<'all' | DeployEnum>>;
  handleApplyFilters: ({
    newStatus,
    newSeverity,
    newOrigins,
    newDeployment
  }: {
    newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
    newSeverity?: SeverityStatusEnum | 'all';
    newOrigins?: Origin[] | undefined;
    newDeployment?: DeployEnum | 'all';
  }) => void;
}) => {
  const classes = useStyles();

  const handleChangeDeployment = (event: ChangeEvent<HTMLInputElement>) => {
    const deploymentValue = event.target.value as DeployEnum;

    setDeployment(deploymentValue);
    handleApplyFilters({ newDeployment: deploymentValue });
  };

  const options = [
    { value: 'all', label: 'All' },
    { value: DeployEnum.Deploy, label: 'Deploy' },
    { value: DeployEnum.Hotfix, label: 'Hotfix' },
    { value: DeployEnum.Rollback, label: 'Rollback' }
  ];

  return (
    <TextField
      name="deploymentFilter"
      label="Deploy Type"
      variant="outlined"
      onChange={handleChangeDeployment}
      value={deployment}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        shrink: true,
        color: 'secondary'
      }}
      select
      fullWidth>
      {options?.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
