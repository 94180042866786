import { useEffect } from 'react';

import postMortemTemplate from 'constants/postMortemTemplate';

import { Box, Button, Divider, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import MarkdownEditor from 'componentsV4/MarkdownEditor';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import { usePostmortem } from 'hooks/queriesGraphQL/usePostmortem';
import actions from 'redux/actions';
import { CreatePostMortem } from 'views/Products/View/Availability/View/Incidents/View/V3/PostMortemForm/PostMortemFormV2/CreatePostMortem';
import {
  UpdatePostMortem,
  UpdatePostMortemVariables
} from 'views/Products/View/Availability/View/Incidents/View/V3/PostMortemForm/PostMortemFormV2/UpdatePostMortem';
import { Input } from 'views/ServicesHub/components/Input';

import { IncidentsSelect } from '../components/IncidentsSelect';
import { PostmortemViewLoadingSkeleton } from '../components/Skeleton';
import { useStyles } from '../styles';

type FormData = {
  postmortem: string;
  title: string;
  incidentId: number;
};

export function PostmortemForm() {
  const { uid } = useParams<{ uid: string }>();

  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const { control, register, handleSubmit, formState, errors, reset, watch } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      title: 'Untitled document',
      postmortem: postMortemTemplate,
      incidentId: 0
    }
  });

  const { isValid } = formState;

  const isEdit = window.location.pathname.includes('/edit');

  const { fetching: fetchingPostmortem, postmortem } = usePostmortem({ uid, pause: !isEdit });

  const [{ fetching: updatingPostmortem }, updatePostmortem] = useMutation<
    {
      updatePostMortemV2: { uid: string };
    },
    UpdatePostMortemVariables
  >(UpdatePostMortem);

  const [{ fetching: creatingPostmortem }, createPostmortem] = useMutation<{
    createPostMortemV2: { uid: string };
  }>(CreatePostMortem);

  useEffect(() => {
    if (!postmortem) return;

    reset({
      title: postmortem.title,
      postmortem: postmortem.postMortem,
      incidentId: postmortem.incidentId
    });
  }, [postmortem, reset]);

  if (isEdit && fetchingPostmortem) return <PostmortemViewLoadingSkeleton />;

  if (isEdit && !postmortem) {
    dispatch({
      type: actions.ENTITY_ERROR,
      payload: new Error('Not found postmortem or not exists.'),
      ga: { category: 'ERROR' }
    });
    return <Redirect to="/postmortems" />;
  }

  const handleSave = async (data: FormData) => {
    if (isEdit) {
      const response = await updatePostmortem({
        id: postmortem?.id!,
        incidentId: data.incidentId,
        postMortem: data.postmortem,
        title: data.title
      });

      if (response.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: new Error('Cannot update postmortem. Please try again'),
          ga: { category: 'ERROR' }
        });

        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Postmortem successfully updated'
      });

      return history.push(`/postmortems/${response.data?.updatePostMortemV2.uid}`);
    }

    const response = await createPostmortem({
      incidentId: data.incidentId,
      postMortem: data.postmortem,
      title: data.title
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error('Cannot create postmortem. Please try again'),
        ga: { category: 'ERROR' }
      });

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Postmortem successfully created'
    });

    return history.push(`/postmortems/${response.data?.createPostMortemV2.uid}`);
  };

  const fetching = updatingPostmortem || creatingPostmortem;

  const watchedTitle = watch('title');

  const breadcrumbTitle = watchedTitle || 'Postmortem';

  return (
    <>
      {fetching && <LoadingOverlay />}
      <Breadcrumb
        items={[
          { label: 'Postmortem Center', link: '/postmortems' },
          { label: isEdit ? breadcrumbTitle : 'New Postmortem' }
        ]}
      />

      <Typography variant="h4">Postmortem</Typography>

      <Divider className={globalClasses.divider} />

      <Box
        component="form"
        display="flex"
        gridGap="5rem"
        flexDirection="column"
        onSubmit={handleSubmit(handleSave)}>
        <Box display="flex" flexDirection="column" gridGap="2rem">
          <Typography variant="h4" className={classes.informationTitle}>
            Basic informations
          </Typography>

          <Box display="flex" flexDirection="column" gridGap="0.5rem">
            <Typography variant="h5">Title</Typography>

            <Input
              inputRef={register({
                required: 'Title is required'
              })}
              name="title"
              variant="outlined"
              placeholder="Postmortem title"
              required={false}
              errors={errors}
            />
          </Box>

          <MarkdownEditor name="postmortem" control={control} style={{ marginTop: 0 }} />

          <Box flex={0.5} display={isEdit ? 'none' : 'flex'} flexDirection="column" gridGap="1rem">
            <Typography variant="h5">Linked Incident</Typography>

            <IncidentsSelect
              name="incidentId"
              control={control}
              label="Choose incident"
              placeholder="Try to search for a incident"
              errors={errors}
            />
          </Box>
        </Box>

        <Box display="flex" gridGap="1rem">
          <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
            CANCEL
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
            SAVE POSTMORTEM
          </Button>
        </Box>
      </Box>
    </>
  );
}
