import { useState } from 'react';

import { Box, Button, Typography, useTheme } from '@material-ui/core';
import DataTable from 'react-data-table-component';

import { Dependencies } from 'hooks/queriesGraphQL/useNewAddons';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import newAddonColumns from './NewAddonsRow/columnsAddons';
import { NewDataRow } from './NewAddonsRow/DataRow';

const ADDON_INITIAL_QUANTITY_TO_SHOW = 10;
const ADDON_LOAD_MORE_TO_SHOW = 10;

const MiddleSection = ({
  toolbar,
  application,
  applicationLatenciesGraphData,
  applicationParentMonitoringActive,
  newAddons,
  monitoringActive,
  permissions,
  useInsightsForMTTs
}: {
  toolbar: any;
  application: any;
  applicationLatenciesGraphData: any;
  applicationParentMonitoringActive: any;
  newAddons: Dependencies[];
  monitoringActive: boolean;
  permissions: {
    editAddon: boolean;
    deleteAddon: boolean;
    viewDeploy: boolean;
  };
  useInsightsForMTTs: boolean;
}) => {
  const useLoadMoreAddons = useFeatureFlagUnleash('useLoadMoreAddons', { queryString: true });

  const newAddonsRows: NewDataRow[] = newAddons?.map(addon => ({
    ...addon,
    addon: true,
    name: addon.name,
    applicationParent: application,
    latenciesGraphData: applicationLatenciesGraphData?.addons?.find(
      (addonGraphData: any) => addonGraphData.id === addon.id
    ),
    applicationParentMonitoringActive: applicationParentMonitoringActive,
    monitoringStatus: addon.informations?.monitoringStatus,
    monitoringActive: monitoringActive,
    useInsightsForMTTs
  }));

  const [linesToShow, setLinesToShow] = useState(ADDON_INITIAL_QUANTITY_TO_SHOW);

  const newNonMultiHttpAddonsRows = newAddonsRows.filter(addon => addon.multiFlow === false);

  const newMultiHttpAddonsRows = newAddonsRows.filter(addon => addon.multiFlow === true);

  const theme = useTheme();

  const isMultiHttp = application.applicationsSettings?.type === 'internalMultiHttp';

  return (
    <>
      {isMultiHttp && (
        <>
          <Typography
            variant="h3"
            style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }}>
            Authentication Step
          </Typography>

          <>
            <DataTable
              striped
              columns={newAddonColumns(permissions)}
              noHeader
              data={newMultiHttpAddonsRows}
              noDataComponent="No data"
              highlightOnHover={true}
            />
          </>
        </>
      )}

      {toolbar}

      <DataTable
        striped
        columns={newAddonColumns(permissions)}
        noHeader
        data={
          useLoadMoreAddons
            ? newNonMultiHttpAddonsRows.slice(0, linesToShow)
            : newNonMultiHttpAddonsRows
        }
        noDataComponent="No data"
        highlightOnHover={true}
      />

      {useLoadMoreAddons && linesToShow < newNonMultiHttpAddonsRows.length ? (
        <Box mt={2} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setLinesToShow(currentQuantity => currentQuantity + ADDON_LOAD_MORE_TO_SHOW);
            }}>
            Load more
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default MiddleSection;
