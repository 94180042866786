import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  redEllipse: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    backgroundColor: '#F9A990',
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  blueEllipse: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    backgroundColor: '#2D9CDB',
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  textFieldBudget: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  inputTextFieldBudget: {
    width: theme.typography.pxToRem(15),
    height: theme.typography.pxToRem(6),
    [theme.breakpoints.only('lg')]: {
      width: theme.typography.pxToRem(15)
    }
  },
  buttonSave: {
    marginLeft: theme.spacing(1),
    height: theme.typography.pxToRem(28)
  },
  captionTextBudget: {
    color: '#898989',
    fontSize: theme.typography.pxToRem(14)
  },
  textBudget: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap'
  },
  insideMarginText: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(1)
    }
  },
  outMarginText: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(1)
    }
  },
  marginBox: {
    display: 'flex',
    [theme.breakpoints.only('lg')]: {
      display: 'block'
    }
  },
  outMargin: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(1, 2),
    backgroundColor: '#F9A990',
    borderRadius: '5px 0px 0px 5px'
  },
  outMarginWithRadius: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(1, 2),
    backgroundColor: '#F9A990',
    borderRadius: '5px 5px 5px 5px'
  },
  insideMargin: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(1, 2),
    backgroundColor: '#2D9CDB',
    borderRadius: '0px 5px 5px 0px'
  },
  insideMarginWithRadius: {
    height: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: theme.spacing(1, 2),
    backgroundColor: '#2D9CDB',
    borderRadius: '5px 5px 5px 5px'
  },
  margin: {
    color: 'white',
    textAlign: 'center',
    alignItems: 'center',
    width: 800,
    borderRadius: '10px 0px 0px 0px'
  },
  formTextBudget: {
    whiteSpace: 'nowrap'
  },
  inputLabel: {
    marginRight: theme.spacing(2)
  }
}));
