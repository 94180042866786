import SelectSimple from 'componentsV3/Select';

const SubnetSelectAzure = ({ errors, control, subnetList, required, disabled }) => {
  return (
    <SelectSimple
      disabled={disabled}
      errors={errors}
      name="subnetName"
      control={control}
      label="Subnet"
      defaultValue=""
      items={subnetList}
      required={required}
    />
  );
};

export default SubnetSelectAzure;
