import {
  Dialog as MuiDialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

export const OldDeleteDialog = ({
  open,
  onClose,
  title,
  children,
  ...otherProps
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <MuiDialog
      classes={{ paper: classes.dialogRounded }}
      open={open}
      onClose={onClose}
      {...otherProps}>
      <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
        <Typography variant="h4">{title}</Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>{children}</DialogContent>
    </MuiDialog>
  );
};
