import { Divider, Typography } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { useStyles as useComponentsStyles } from '../styles';

type UserActionProps = {
  event: EventType;
};

export function UserAction({ event }: UserActionProps) {
  const componentsClasses = useComponentsStyles({ event });

  switch (event.type) {
    case EventTypeEnum.Alarmed:
    case EventTypeEnum.Acknowledged:
    case EventTypeEnum.Resolved:
    case EventTypeEnum.IncidentStatusUpdate:
      return (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Typography className={componentsClasses.eventDescription}>
            {event?.content?.userName || 'Automatically generated'}
          </Typography>
        </>
      );
  }
  return <Divider />;
}
