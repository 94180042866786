import React from 'react';

import { Grid, Link, MenuItem } from '@material-ui/core';
import { Select, TextField } from 'oldComponents/Inputs';
import SecretsField from 'oldComponents/SecretsField';

export const ServiceBusForm = ({
  control,
  errors,
  setValue,
  watch,
  getValues,
  register,
  disabled = false,
  startValues
}) => {
  const typeSelected = watch('type');

  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12}>
        <SecretsField
          TextFieldProps={{
            label: 'Connection String',
            name: 'connection_string',
            required: true
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          errors={errors}
          disabled={disabled}
          register={register}
          defaultHelperText={
            <>
              ex:
              Endpoint=sb://namespace.servicebus.windows.net/;SharedAccessKeyName=keyname;SharedAccessKey=keyvalue
              <br></br>
              See more in: <> </>
              <Link
                href="https://docs.microsoft.com/en-us/azure/service-bus-messaging/service-bus-quickstart-portal#get-the-connection-string"
                target={'_blank'}>
                How to get my Connection String?
              </Link>
            </>
          }
        />
      </Grid>
      <Grid item md={typeSelected === undefined ? 12 : 6} sm={12}>
        <Select
          control={control}
          label="Monitoring Type"
          name="type"
          errors={errors}
          watch={watch}
          disabled={disabled}
          required={true}>
          <MenuItem value="queue">Queue</MenuItem>
          <MenuItem value="topic">Topic</MenuItem>
        </Select>
      </Grid>

      {typeSelected === 'topic' && (
        <>
          <Grid item md={3} sm={12}>
            <TextField
              label="Topic name"
              name="topic"
              register={register}
              errors={errors}
              disabled={disabled}
              type="text"
            />
          </Grid>
          <Grid item md={3} sm={12}>
            <TextField
              label="Subscription name"
              name="subscription"
              register={register}
              errors={errors}
              disabled={disabled}
              type="text"
            />
          </Grid>
        </>
      )}

      {typeSelected === 'queue' && (
        <>
          <Grid item md={6} sm={12}>
            <TextField
              label="Queue name"
              name="queue"
              register={register}
              errors={errors}
              disabled={disabled}
              type="text"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
