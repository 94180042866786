import { useQuery } from 'urql';

const EnvironmentsListQuery = `#graphql
  query($cloudId: Float, $newApplications: Boolean) {
    environments(page: 1, per_page: 1000, sort: "name,ASC", cloudId: $cloudId, newApplications: $newApplications ) {
      data {
        id
        name
      }
    }
  }
`;

export const useEnvironments = (cloudId: number | null, newApplications: boolean) =>
  useQuery<{ environments: { data: { id: number; name: string }[] } }>({
    query: EnvironmentsListQuery,
    variables: { cloudId, newApplications }
  });
