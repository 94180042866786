import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  IconButton as MuiIconButton
} from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { TextField } from 'oldComponents/Inputs';
import TooltipButton from 'oldComponents/TooltipButton';
import { Controller } from 'react-hook-form';
import { useQuery } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';
import { usePermission } from 'hooks/usePermission';
import withThemeV3Hoc from 'theme/v3/withTheme';
import NewSecretForm from 'views/Organization/components/Secrets/NewSecretForm';

import Autocomplete from './Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.modal
  }
}));

const SecretsQuery = `
  query {
    secrets {
      name
    }
  }
`;

const InputWithSecrets = ({
  InputComponent = TextField,
  IconButton = (
    <MuiIconButton>
      <VpnKeyIcon />
    </MuiIconButton>
  ),
  TextFieldProps,
  errors,
  defaultHelperText,
  control,
  watch,
  setValue,
  disabled,
  defaultValue,
  disableOffset
}) => {
  const classes = useStyles();

  const permissions = {
    list: usePermission('SecretsController-get-/secrets'),
    new: usePermission('SecretsController-post-/addSecret')
  };

  // eslint-disable-next-line no-unused-vars
  const [result, reexecuteQuery] = useQuery({
    query: SecretsQuery,
    pause: !permissions.list
  });

  const multiplicationValue = 7;
  const standardMargin = 80;

  const [open, setOpen] = React.useState(false);
  const [startedTyping, setStartedTyping] = React.useState(false);
  const anchorRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [[selectionStart, selectionEnd], setSelectionRange] = React.useState([null, null]);
  const [inputComponent, setInputComponent] = React.useState();
  const [charactersLength, setCharactersLength] = React.useState();
  const [openDialogNewSecret, setOpenDialogNewSecret] = React.useState(false);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setInputComponent();
  };

  const handleItemSelection = ({ event, value }) => {
    const connectionString = watch(TextFieldProps.name) || '';
    const start = connectionString.substring(0, selectionStart || connectionString.length);
    const end = connectionString.substring(selectionEnd || connectionString.length);

    handleClose(event);

    inputRef.current.focus();
    inputRef.current.type = 'text';

    if (startedTyping) {
      setValue(TextFieldProps.name, `${start}.${value}}}${end}`);
      setStartedTyping(false);
      return;
    }

    setValue(TextFieldProps.name, `${start}{{.${value}}}${end}`);
  };

  const errorMessage = errors && errors[TextFieldProps?.name]?.message;

  const helperTextMessage = () => {
    if (errorMessage && !defaultHelperText) return errorMessage;
    if (defaultHelperText && !errorMessage) return defaultHelperText;
    if (errorMessage && defaultHelperText)
      return (
        <p>
          {errorMessage} <br /> {defaultHelperText}
        </p>
      );
  };

  const handleOpenDialogNewSecret = () => {
    setOpenDialogNewSecret(true);
  };

  const handleCloseDialogNewSecret = () => {
    setOpenDialogNewSecret(false);
  };

  useEffect(() => {
    if (TextFieldProps.type === 'password' && /{/g.test(inputRef.current.value)) {
      inputRef.current.type = 'text';
    }
  }, [TextFieldProps.type, inputRef.current?.value]);

  return (
    <>
      <Controller
        control={control}
        name={TextFieldProps.name}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name, ref }) => (
          <InputComponent
            onBlur={e => {
              setSelectionRange([e.target.selectionStart, e.target.selectionEnd]);
              onBlur(e);
            }}
            onChange={e => {
              if (TextFieldProps.type === 'password') {
                inputRef.current.type = /{/g.test(e.target.value) ? 'text' : 'password';
              }

              if (/{{$/.test(e.target.value)) {
                setCharactersLength(e.target.value.length);
                setInputComponent(e.target);
                handleToggle();
                setStartedTyping(true);
              }
              onChange(e.target.value);
            }}
            value={value}
            inputRef={e => {
              ref.current = e;
              inputRef.current = e;
            }}
            name={name}
            InputProps={{
              endAdornment: React.cloneElement(IconButton, {
                ref: anchorRef,
                'aria-controls': open ? 'secrets-autocomplete' : undefined,
                'aria-haspopup': 'true',
                size: 'small',
                onClick: handleToggle,
                disabled: disabled
              })
            }}
            error={Boolean(errorMessage)}
            helperText={helperTextMessage()}
            disabled={disabled}
            {...TextFieldProps}
          />
        )}
      />

      <Popper
        open={open || openDialogNewSecret}
        anchorEl={inputComponent ? inputComponent : anchorRef.current}
        role={undefined}
        transition
        autoFocus={false}
        className={classes.root}
        placement={inputComponent ? 'top-start' : 'top'}
        modifiers={{
          offset: {
            enabled: true,
            offset: !disableOffset
              ? `${standardMargin + charactersLength * multiplicationValue}, -100`
              : `${inputComponent ? '100' : '0'}, -100`
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }}>
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={openDialogNewSecret ? () => {} : handleClose}>
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper style={{ padding: '7px' }}>
                <>
                  <Autocomplete onChange={handleItemSelection} />
                  <TooltipButton
                    title={
                      !permissions.new ? "You don't have permission to add a secret variable" : ''
                    }>
                    <ButtonSimple
                      style={{ margin: '5px' }}
                      variant="contained"
                      color="primary"
                      disabled={!permissions.new}
                      onClick={handleOpenDialogNewSecret}
                      text="Create secret variable"
                    />
                  </TooltipButton>
                </>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>

      <NewSecretForm
        open={openDialogNewSecret}
        handleClose={handleCloseDialogNewSecret}
        onSecretAdd={() => {
          reexecuteQuery({ requestPolicy: 'network-only' });
        }}
      />
    </>
  );
};

export default withThemeV3Hoc(InputWithSecrets);
