const removeService = ({ source, draggableId }) => statusPageComponents => {
  return statusPageComponents.map((component, index) => {
    if (!index === Number(source.droppableId.split('group-')[1])) {
      return component;
    }

    return {
      ...component,
      services: [
        ...component.services.filter(
          service => String(service.id) !== String(draggableId.split('service-')[1])
        )
      ]
    };
  });
};

export default removeService;
