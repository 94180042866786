export const extractNumbers = response => {
  const numbers = response
    .map(member => {
      if (member.user.profile && member.user.profile.verifiedPhone) {
        return {
          name: member.user?.profile?.displayName
            ? member.user?.profile?.displayName
            : member.user?.displayName,
          phone: member.user.profile.phone
        };
      }
      return null;
    })
    .filter(Boolean);
  return numbers;
};
