import { Chip, ChipProps, Divider } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { useStyles } from './styles';

type SecondaryChipProps = ChipProps & {
  event: EventType;
};

function getLabelByIncidentStatusUpdate(isPrivate: boolean) {
  return isPrivate ? 'Private incident status update' : 'Incident status update';
}

function getLabelByDeployType(event: EventType) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: 'Alarmed',
    [EventTypeEnum.Acknowledged]: 'Acknowledged',
    [EventTypeEnum.Resolved]: 'Resolved',
    [EventTypeEnum.PostMortem]: 'Post Mortem',
    [EventTypeEnum.Deploy]: 'Deploy',
    [EventTypeEnum.HotFix]: 'HotFix',
    [EventTypeEnum.Rollback]: 'Rollback',
    [EventTypeEnum.IncidentStatusUpdate]: getLabelByIncidentStatusUpdate(
      event.content?.private ? event.content?.private : false
    )
  };

  return labelMap[event?.type];
}

export function SecondaryChip({ event, ...props }: SecondaryChipProps) {
  const classes = useStyles({ event });

  switch (event.type) {
    case EventTypeEnum.Alarmed:
    case EventTypeEnum.Acknowledged:
    case EventTypeEnum.Resolved:
      return (
        <Chip
          variant="outlined"
          label={getLabelByDeployType(event)}
          className={classes.chip}
          {...props}
        />
      );
  }
  return <Divider />;
}
