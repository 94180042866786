import { Dispatch, SetStateAction } from 'react';

import { externalIntegrationsOriginsList } from 'types/external/ExternalService';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { ExternalIntegrationsOrigin, Origin } from 'componentsV4/Filters/Search';

import CustomAutocomplete from '../Common/customAutocompleteProps';

type HandleApplyFilters = ({
  newStatus,
  newSeverity,
  newOrigins,
  newExternalIntegrationsOrigins
}: {
  newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
  newSeverity?: SeverityStatusEnum | 'all';
  newOrigins?: Origin[] | undefined;
  newExternalIntegrationsOrigins?: ExternalIntegrationsOrigin[] | undefined;
}) => void;

export const ExternalIntegrationsOriginsFilter = ({
  origins,
  setOrigins,
  handleApplyFilters
}: {
  origins: ExternalIntegrationsOrigin[];
  setOrigins: Dispatch<SetStateAction<ExternalIntegrationsOrigin[]>>;
  handleApplyFilters: HandleApplyFilters;
}) => {
  const handleChangeOrigin = (event: ExternalIntegrationsOrigin[]) => {
    setOrigins(event);
    handleApplyFilters({ newExternalIntegrationsOrigins: event });
  };

  const originsOptions = externalIntegrationsOriginsList.map(
    (origin: { value: string; label: string }) => ({
      value: origin.value,
      label: origin.label
    })
  );

  const orderedOrigins = originsOptions.filter(
    item => !origins?.find((origin: { value: string }) => item.value === origin.value)
  );

  return (
    <CustomAutocomplete
      origins={origins}
      handleChangeOrigin={handleChangeOrigin}
      orderedOrigins={orderedOrigins}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      label="Player"
    />
  );
};
