import { Box, CardContent, Divider, Icon, Paper, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useImpactedResources } from 'hooks/queriesGraphQL/useImpactedResources';
import ApplicationIcon from 'icons/Application';

/*We are reusing the ImpactedOrigins styles to avoid code duplication. Since this item is part of the old journey, it will be discontinued soon.*/
import { useStyles } from '../ImpactedOrigins/styles';

type ImpactedResourcesProps = {
  incidentId: number;
};

function ImpactedResources({ incidentId }: ImpactedResourcesProps) {
  const classes = useStyles();
  const theme = useTheme();

  const { data: impactedResources, isLoading } = useImpactedResources(incidentId);

  const hasData = impactedResources.length > 0;

  return (
    <CardContent className={classes.cardContent}>
      <Paper elevation={1} className={classes.paper}>
        <Typography className={classes.titleCard} variant="subtitle2">
          Resources impacted
        </Typography>
        <Divider className={classes.dividerSections} variant="fullWidth" />
        <Box
          className={clsx(classes.scrollBar, !hasData && classes.noData)}
          position="relative"
          gridGap={theme.spacing()}>
          {impactedResources.map(resource => (
            <Box display="flex" gridGap={theme.spacing()}>
              <Icon>
                <ApplicationIcon />
              </Icon>
              <Typography variant="h6" style={{ lineHeight: 'inherit' }}>
                {resource.application.name}
                <Typography display="block" variant="caption" color="primary">
                  {resource.application.product.name}
                </Typography>
              </Typography>
            </Box>
          ))}

          {!hasData && !isLoading && (
            <Typography color="textPrimary" variant="h6">
              No data
            </Typography>
          )}

          {isLoading && <LoadingOverlay size={theme.spacing(4)} />}
        </Box>
      </Paper>
    </CardContent>
  );
}

export default ImpactedResources;
