export const DeleteAccountMutation = `#graphql
  mutation($id: Float!) {
    deleteAccount(id: $id)
  }
`;

export const DeleteAccountWithAragornMutation = `#graphql
  mutation($userId: Float!) {
    deleteAccountWithAragorn(userId: $userId){
        message
        success
    }
  }
`;
