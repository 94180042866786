import { Resource } from 'types/external/Resource';

import { OracleFormData, Role } from 'views/ServicesHub/forms/monitoring/database/Oracle';

import { monitoringAdapter } from '../monitoring';

export function oracleAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & OracleFormData {
  const domain = data.domain;
  // ex: USER/PASSWORD@HOST:PORT/DATABASE/ as SYSDBA

  const oracleDomainRegex = /(.+)\/(.+)@([\w.-]+):(\d+)\/(\w+)(?:\s+as\s+(\w+))?/;

  const match = domain.match(oracleDomainRegex)!;

  const username = match[1];
  const password = match[2];
  const host = match[3];
  const port = match[4];
  const database = match[5];
  const role: OracleFormData['role'] = match[6]
    ? (match[6].trim() as OracleFormData['role'])
    : Role['Normal'];

  return {
    ...monitoringAdapter(data),
    username,
    password,
    host,
    port,
    database,
    role
  };
}
