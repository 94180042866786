import regionsAws from 'constants/regionsAws';

import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type SqsFormData = {
  accessKey: string;
  secretAccessKey: string;
  awsRegion: string;
  testQueue: string;
  queueSize: number;
};

export function SqsForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <>
      {domainType === 'userAndPass' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <Box display="flex" gridGap="1rem">
              <InputWithSecrets
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                TextFieldProps={{
                  type: 'text',
                  label: 'Access Key',
                  name: 'accessKey'
                }}
                InputComponent={Input}
              />
              <InputWithSecrets
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                TextFieldProps={{
                  type: 'password',
                  label: 'Secret Access Key',
                  name: 'secretAccessKey'
                }}
                InputComponent={Input}
              />
            </Box>
            <Box display="flex" gridGap="1rem">
              <Select options={regionsAws} control={control} name="awsRegion" label="AWS Region" />
              <Input label="Test Queue" name="testQueue" inputRef={register} />
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" gridGap="1rem">
        <Input
          label="Queue Size"
          name="queueSize"
          inputRef={register}
          type="number"
          inputProps={{
            min: 0
          }}
          required={false}
        />
      </Box>
    </>
  );
}
