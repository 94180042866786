import uuid from 'uuid';
import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  email: {
    email: {
      message: 'invalid'
    },
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500
    }
  },
  displayName: {
    length: {
      maximum: 500
    }
  },
  role: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const notUnique = (list, item) => {
  const found = list.filter(
    guest => guest.email === item.email && guest.email.trim() !== '' && guest.uid !== item.uid
  ).length;

  if (found) {
    const msg = 'Duplicate email in the list';
    if (item.errors) {
      const errors = item.errors.email ? item.errors.email : [];
      errors.push(msg);
      item.errors.email = errors;
    } else {
      item.errors = {
        email: [msg]
      };
    }
  }
};

const users = (state, action) => {
  if (actions.USERS_POPULATE_ENTITY_SIGNUP === action.type) {
    const { guest_signup } = state;
    const values = guest_signup.values;
    return {
      ...state,
      guest_signup: {
        ...guest_signup,
        values: {
          ...values,
          ...action.payload
        }
      }
    };
  }

  if (actions.USERS_REMOVE === action.type) {
    const users_new = state.users_new;
    const data = users_new.data;

    const newData = data.reduce((col, item) => {
      if (item.uid !== action.meta.uid) {
        item.errors = validate(item, schema);

        col.push(item);
      }
      return col;
    }, []);

    const isValid = !newData.filter(item => item.errors).length;

    if (data.length > 1) {
      return {
        ...state,
        users_new: {
          ...users_new,
          isValid,
          data: newData
        }
      };
    }
  }

  if (actions.USERS_ADD === action.type) {
    const users_new = state.users_new;
    const data = users_new.data;
    data.push({
      uid: uuid(),
      email: '',
      displayName: '',
      teams: [],
      role: null,
      admin: false
    });
    return {
      ...state,
      users_new: {
        ...users_new,
        isValid: false,
        data
      }
    };
  }

  if (actions.USERS_POPULATE_ENTITY === action.type) {
    const users_new = state.users_new;
    const { uid } = action.meta;

    const data = users_new.data.map(item => {
      const selected = { values: {} };
      if (item.uid === uid) {
        selected.values = action.payload;
      }
      const result = {
        ...item,
        ...selected.values
      };
      result.errors = validate(result, schema);
      notUnique(users_new.data, result);
      return result;
    });

    const isValid = !data.filter(item => item.errors).length;

    return {
      ...state,
      users_new: {
        ...users_new,
        isValid,
        data
      }
    };
  }

  if (actions.USERS_FETCH_SUCCESSFUL === action.type) {
    const users_new = state.users_new;
    const users_edit = state.users_edit;
    const {
      teams,
      roles,
      users_edit: { values }
    } = action.payload;
    return {
      ...state,
      users_edit: {
        ...users_edit,
        values: {
          ...users_edit.values,
          ...values
        }
      },
      users_new: {
        ...users_new,
        teams,
        roles,
        data: [
          {
            uid: uuid(),
            email: '',
            displayName: '',
            admin: false,
            teams: []
          }
        ]
      }
    };
  }
  return state;
};

export default users;
