import MainLayoutV5 from 'layouts/v5/Main';
import { Account as AccountV1 } from 'views/Account/Views/v1/Account';
import { Account as AccountV2 } from 'views/Account/Views/v2/Account';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const accountsRoutesV4WithLayoutV2 = [
  <RouteWithLayoutV2
    component={AccountV1}
    exact
    layout={MainLayoutV5}
    key="/account"
    path="/account"
  />,
  <RouteWithLayoutV2
    component={AccountV2}
    exact
    layout={MainLayoutV5}
    key="/profile"
    path="/profile"
  />
];
