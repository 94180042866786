import { PostBodyTypes, PostBodyValues } from 'constants/postBody';

import { Resource } from 'types/external/Resource';

import { objectToKeyValueList } from 'helpers/getObjectList';
import { PostFormData } from 'views/ServicesHub/forms/monitoring/http/Post';

import { monitoringAdapter } from '../monitoring';

export function postAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & PostFormData {
  return {
    ...monitoringAdapter(data),
    url: data.domain,
    skipSslValidation: data.domainSettings.skipSslValidation
      ? data.domainSettings.skipSslValidation
      : false,
    tlsRenegotiation: data.domainSettings.tlsRenegotiation,
    headers: prepareHeader(data.domainSettings.headers),
    postBodyType: preparePostBodyType(data.domainSettings.postBodyType),
    rawType: prepareRawType(data.domainSettings.postBodyType),
    postBodyRaw: preparePostBodyRaw(data.domainSettings.postBody, data.domainSettings.postBodyType),
    postBodyUrlencoded: preparePostBodyUrlencoded(
      data.domainSettings.postBody,
      data.domainSettings.postBodyType
    ),
    elvenAgentCloud: data.domainSettings.poolConfig
      ? data.domainSettings.poolConfig.cloud
      : undefined,
    elvenAgentRegion: data.domainSettings.poolConfig
      ? data.domainSettings.poolConfig.region
      : undefined,
    validationString: data.domainSettings.validationString
  };
}

const preparePostBodyType = (postBodyType: string) => {
  if (postBodyType === PostBodyTypes.urlencoded) {
    return PostBodyTypes.urlencoded;
  }
  return PostBodyTypes.raw;
};

const prepareRawType = (postBodyType: string) => {
  if (postBodyType === PostBodyTypes.urlencoded) {
    return undefined;
  }
  return postBodyType as PostBodyValues;
};

const preparePostBodyUrlencoded = (postBody: any, postBodyType: string) => {
  if (postBodyType !== PostBodyTypes.urlencoded) return undefined;
  return objectToKeyValueList(JSON.parse(postBody));
};

const preparePostBodyRaw = (postBody: any, postBodyType: string) => {
  if (postBodyType === PostBodyTypes.urlencoded) return undefined;
  return postBody;
};

const prepareHeader = (headers: { key: string; value: string }[]) => {
  if (!headers) return [];
  return objectToKeyValueList(headers);
};
