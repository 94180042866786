import V3 from './V3';

const ProductsList = (props: {
  showEmptyHome: boolean;
  firstAccess: boolean;
  setFirstAccess: (firstAccess: boolean) => void;
}) => {
  return <V3 {...props} />;
};

export default ProductsList;
