export const queryGetResource = `#graphql
query(
  $teamId: Int!
  $page: Float!
  $per_page: Float!
  $sort: String!
  $query: String!
) {
  getResourcesByTeamId(
  teamId: $teamId,
  page: $page, 
  per_page: $per_page, 
  sort: $sort,
  query: $query
){
   total
      data {
        id
        name
        description
      }
  
    }
  }
`;
