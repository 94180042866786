import { ChangeEvent, useState, useEffect } from 'react';

import { MenuItem, TextField, Box } from '@material-ui/core';
import { FiltersPopper, FiltersPopperProps } from 'oldComponents/FiltersPopper';
import { Origin } from 'types/external/IncidentState';
import { SeverityStatusEnum } from 'types/external/Severity';
import { SourceEnum } from 'types/external/Source';
import { StatusEnum } from 'types/external/Status';

import { useStyles } from '../styles';

export type PageFilters = {
  status: StatusEnum | 'all';
  severity: SeverityStatusEnum | 'all';
  origins?: Origin[];
  source: SourceEnum | 'all';
  period: string | '1';
};

type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  defaultValues: DefaultValues;
} & Pick<FiltersPopperProps, 'anchorEl' | 'handleClose'>;

type DefaultValues = {
  status?: string;
  severity?: string;
  source?: string;
  period?: string;
};

export function Filters({
  anchorEl,
  onApplyFilters,
  onClearFilters,
  handleClose,
  defaultValues
}: FiltersProps) {
  const classes = useStyles();

  const [status, setStatus] = useState<'all' | StatusEnum>('all');
  const [source, setSource] = useState<'all' | SourceEnum>('all');
  const [severity, setSeverity] = useState<'all' | SeverityStatusEnum>('all');
  const [period, setPeriod] = useState<'all' | string>('1');

  const handleChangeSource = (event: ChangeEvent<HTMLInputElement>) => {
    setSource(event.target.value as SourceEnum);
  };

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as StatusEnum);
  };

  const handleChangeSeverity = (event: ChangeEvent<HTMLInputElement>) => {
    setSeverity(event.target.value as SeverityStatusEnum);
  };

  const handleChangePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    setPeriod(event.target.value as string);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ status, severity, source, period });
  };

  useEffect(() => {
    defaultValues.status && setStatus(defaultValues.status as StatusEnum);
    defaultValues.source && setSource(defaultValues.source as SourceEnum);
    defaultValues.severity && setSeverity(defaultValues.severity as SeverityStatusEnum);
    defaultValues.period && setPeriod(defaultValues.period);
  }, [defaultValues, setStatus, setSource, setSeverity, setPeriod]);

  const handleClearFilters = () => {
    setSource('all');
    setStatus('all');
    setSeverity('all');
    setPeriod('all');
    onClearFilters();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <Box display="flex" justifyContent="space-between" gridGap={12}>
        <Box width="475px">
          <TextField
            name="statusFilter"
            label="Source"
            variant="outlined"
            onChange={handleChangeSource}
            value={source}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value={SourceEnum.Resources}>Resources</MenuItem>
            <MenuItem value={SourceEnum.Integrations}>Integrations</MenuItem>
          </TextField>
        </Box>

        <Box width="475px">
          <TextField
            name="statusFilter"
            label="Incident Status"
            variant="outlined"
            onChange={handleChangeStatus}
            value={status}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value={StatusEnum.Alarmed}>Alarmed</MenuItem>
            <MenuItem value={StatusEnum.Resolved}>Resolved</MenuItem>
            <MenuItem value={StatusEnum.Acknowledged}>Acknowledged</MenuItem>
          </TextField>
        </Box>

        <Box width="475px">
          <TextField
            name="severityFilter"
            label="Severity Level"
            variant="outlined"
            onChange={handleChangeSeverity}
            value={severity}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value={SeverityStatusEnum.NotClassified}>Not Classified</MenuItem>
            <MenuItem value={SeverityStatusEnum.Critical}>SEV 1 - Critical</MenuItem>
            <MenuItem value={SeverityStatusEnum.High}>SEV 2 - High</MenuItem>
            <MenuItem value={SeverityStatusEnum.Moderate}>SEV 3 - Moderate</MenuItem>
            <MenuItem value={SeverityStatusEnum.Low}>SEV 4 - Low</MenuItem>
            <MenuItem value={SeverityStatusEnum.Informational}>SEV 5 - Informational</MenuItem>
          </TextField>
        </Box>

        <Box width="475px">
          <TextField
            name="periodFilter"
            label="Period"
            variant="outlined"
            onChange={handleChangePeriod}
            value={period}
            InputProps={{
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            select
            fullWidth>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="1">Last 24 hours</MenuItem>
            <MenuItem value="7">Last 7 days</MenuItem>
            <MenuItem value="30">Last 30 days</MenuItem>
            <MenuItem value="0">Last 12 months</MenuItem>
          </TextField>
        </Box>
      </Box>
    </FiltersPopper>
  );
}
