import { Directus } from '@directus/sdk';
import { Collections } from 'types/directus';

const DIRECTUS_SERVER_URL = process.env.REACT_APP_DIRECTUS_SERVER_URL!;
const DIRECTUSV2_SERVER_URL = process.env.REACT_APP_DIRECTUSV2_SERVER_URL!;
const DIRECTUS_ACCESS_TOKEN = process.env.REACT_APP_DIRECTUS_ACCESS_TOKEN;

export const directus = new Directus<Collections>(DIRECTUS_SERVER_URL, {
  auth: {
    staticToken: DIRECTUS_ACCESS_TOKEN
  }
});

export const directusV2 = new Directus<Collections>(DIRECTUSV2_SERVER_URL, {
  auth: {
    staticToken: DIRECTUS_ACCESS_TOKEN
  }
});
