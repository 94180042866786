import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

export const MTTA = () => {
  const GetIncidentsMTTA = `#graphql
    query (
      $startDate: String! 
      $endDate: String!      
    ) {
      insights {
        incidents {
          mtta(startDate: $startDate, endDate: $endDate){
            period
            compared
          }
        }      
      }
    }
  `;

  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentsMTTA,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const mtta = data?.insights?.incidents?.mtta;

  return (
    <Metrics
      name="MTTA"
      value={mtta?.period}
      valueFormat={MetricsValueFormatEnum.Time}
      isFetching={fetching}
      tooltipTitle="Mean Time to Acknowledge (MTTA) is the average time to acknowledge an incident."
      diff={mtta?.compared}
      diffFormat={MetricsValueFormatEnum.Time}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
