import * as z from 'zod';

import { domainSettingsValidation } from 'helpers/domainSettingsHelper';

export default z
  .object({
    teamId: z
      .any()
      .nullable()
      .optional(),
    name: z.string().nonempty({ message: 'Required' }),
    environmentId: z.union([z.string().nonempty({ message: 'Required' }), z.number()]),
    healthcheckUrl: z.string().url({ message: 'Invalid url. E.g.: http://host' }),
    method: z.string().nonempty({ message: 'Required' }),
    headers: z.any(),
    post_body_default: z.any(),
    post_body_urlencoded: z.any(),
    post_body_raw: z.any(),
    post_body_type: z.any(),
    post_body_raw_type: z.any(),
    validationString: z.union([z.string().nonempty({ message: 'Required' }), z.any()]),
    check_interval: z
      .string()
      .refine(
        check_interval => {
          try {
            if (
              Number(check_interval) >=
              Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
            ) {
              return true;
            }
          } catch {
            return false;
          }
        },
        {
          message: `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
        }
      )
      .optional(),
    timeout: z
      .string()
      .refine(
        timeout => {
          try {
            if (Number(timeout) >= 1) {
              return true;
            }
          } catch {
            return false;
          }
        },
        {
          message: `The timeout number must be greater than zero.`
        }
      )
      .optional(),
    failuresToIncident: z.union([z.string().nonempty({ message: 'Required' }), z.number()]),
    skip_ssl_validation: z.boolean().optional(),
    tls_renegotiation: z.any().optional(),
    openIncidentWithSeverity: z.string().optional(),
    ...domainSettingsValidation()
  })
  .refine(
    values => {
      if (Number(values.check_interval) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval number can only be up to six digits',
      path: ['check_interval']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Timeout number can only be up to six digits',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The timeout must not be greater than the interval',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The interval must not be lesser than the timeout',
      path: ['check_interval']
    }
  );
// .refine(
//   data => {
//     // If empty do not validate as JSON
//     if (!data.post_body_raw) {
//       return true;
//     }

//     // Only validate JSON if type raw is selected
//     // otherwise it wouldn't allow sending the form if user inserted raw json then switched to urlencoded
//     if (
//       data.post_body_type === postBodyTypeValues.raw &&
//       data.post_body_raw_type === postBodyRawTypeValues.json
//     ) {
//       try {
//         if (data.post_body_raw === `""` || data.post_body_raw === `"{}"`) {
//           throw new Error('Invalid json');
//         }
//         return JSON.parse(data.post_body_raw);
//       } catch {
//         return false;
//       }
//     }

//     return true;
//   },
//   { message: 'Invalid json', path: ['post_body_raw'] }
// );
