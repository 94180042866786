import React from 'react';

import { Product } from 'constants/Product';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography } from '@material-ui/core';
import { CreateProductV3Mutation } from 'graphqlQueries/createProductV3';
import { UpdateProductMutation } from 'graphqlQueries/updateProduct';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import { useProduct } from 'hooks/queriesGraphQL/useProduct';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { ProductFormData } from 'views/ServicesHub/adapters/new/product';
import { LayoutType } from 'views/ServicesHub/forms';

import { schema } from './schema';
import { useStyles } from './styles';

type FormValues = {
  name: string;
  description?: string;
};

export function ProductLayout({ Form, isEdit }: LayoutType) {
  const classes = useStyles();

  const { uid } = useParams<{ uid: string }>();
  const productId = Number(uid);

  const [{ data: productData, fetching: isFetchingProduct }] = useProduct(productId);

  const [{ fetching: isCreating }, createProductV3] = useMutation<{
    createProductV3: Product;
  }>(CreateProductV3Mutation);

  const [{ fetching: isUpdating }, updateProduct] = useMutation<{
    updateProduct: Product;
  }>(UpdateProductMutation);

  const product = productData?.product;

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: ''
    },
    resolver: zodResolver(schema)
  });

  const { reset } = form;

  React.useEffect(() => {
    reset({
      name: product?.name,
      description: product?.description
    });
  }, [reset, product]);

  const hasPermissionCreate = usePermission('ProductController-post-/products');
  const hasPermissionEdit = usePermission('ProductController-put-/products/:id');

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async (data: ProductFormData) => {
    if (isEdit) {
      const productResponse = await updateProduct({
        productData: {
          productId: product?.id,
          name: data.name,
          description: data.description
        }
      });

      if (productResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on Create Product' }
        });
        // eslint-disable-next-line no-console
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Service created successfully'
      });

      history.push(`/products/${product?.id}`);
    }

    if (!isEdit) {
      const productResponse = await createProductV3({
        data
      });

      if (productResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on Create Product' }
        });
        // eslint-disable-next-line no-console
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Service created successfully'
      });

      history.push(`/products/${productResponse.data?.createProductV3?.id}`);
    }
  };

  const fetching = isCreating || isUpdating || isFetchingProduct;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Configure a Product
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Form form={form} />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Support configuration
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              To create a product and ensure the grouping of its monitored items, make sure to fill
              out the form data. If you have any questions, click here to access the documentation.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          disabled={isCreating}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!(isEdit ? hasPermissionEdit : hasPermissionCreate) || isCreating}>
          {isEdit ? 'Save' : 'Create'} Product
        </Button>
      </Box>
    </Box>
  );
}
