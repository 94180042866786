import { take, put, call, cancel, fork } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/availability';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function* prepareFetch(axios, action) {
  while (true) {
    yield call(delay, process.env.REACT_APP_POLLING_APPLICATION_VIEW || 60000);
    try {
      const { productId, applicationId } = action.params;
      const {
        data: { data: application }
      } = yield axios.get(`/products/${productId}/applications/${applicationId}`);
      const {
        data: { data: agent }
      } = yield axios.get(`/environments/${application.environment.id}/agent`);
      yield put({
        type: actions.APPLICATION_AVAILABILITY_STATUS_TIMER_SUCCESSFUL,
        meta: {
          reducer
        },
        payload: {
          application: {
            ...application,
            agent
          }
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      yield put({
        type: actions.ENTITY_ERROR,
        payload: err,
        ga: { category: 'ERROR', action: action.type }
      });
    }
  }
}

export default function* watchApplicationAvailabilityStatusTimer(axios) {
  while (true) {
    const action = yield take(actions.APPLICATION_AVAILABILITY_STATUS_TIMER);
    const bgSyncTask = yield fork(prepareFetch, axios, action);
    yield take(actions.APPLICATION_AVAILABILITY_STATUS_TIMER_STOP);
    yield cancel(bgSyncTask);
  }
}
