import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MaintenanceIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.9038 13.1602L13.7825 15.2815L19.7788 21.2777L21.9001 19.1564L15.9038 13.1602Z"
        fill={props.fill}
      />
      <path
        d="M17.4999 10.1097C19.4299 10.1097 20.9999 8.53967 20.9999 6.60967C20.9999 6.02967 20.8399 5.48967 20.5899 5.00967L17.8899 7.70967L16.3999 6.21967L19.0999 3.51967C18.6199 3.26967 18.0799 3.10967 17.4999 3.10967C15.5699 3.10967 13.9999 4.67967 13.9999 6.60967C13.9999 7.01967 14.0799 7.40967 14.2099 7.76967L12.3599 9.61967L10.5799 7.83967L11.2899 7.12967L9.87985 5.71967L11.9999 3.59967C10.8299 2.42967 8.92985 2.42967 7.75985 3.59967L4.21985 7.13967L5.62985 8.54967H2.80985L2.09985 9.25967L5.63985 12.7997L6.34985 12.0897V9.25967L7.75985 10.6697L8.46985 9.95967L10.2499 11.7397L2.83985 19.1497L4.95985 21.2697L16.3399 9.89967C16.6999 10.0297 17.0899 10.1097 17.4999 10.1097Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}
