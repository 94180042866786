import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { SqlserverFormData } from 'views/ServicesHub/forms/monitoring/database/SqlserverForm';

import { monitoringAdapter } from '../monitoring';

export function sqlserverAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & SqlserverFormData {
  const { domain, user, password, host, database } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    user,
    password,
    host,
    database
  };
}

const prepareDomain = (data: Resource) => {
  let user = '';
  let password = '';
  let host = '';
  let database = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    // ex: sqlserver://USER:PASSWORD@HOST?database=DB
    const domainURI = new URI(data.domain);

    user = domainURI.username();
    password = domainURI.password();
    host = domainURI.hostname();
    database = domainURI.query(true).database;
  }

  return { domain, user, password, host, database };
};
