const updateComponentName = ({ name, index }) => statusPageComponents =>
  statusPageComponents.map((component, componentIndex) => {
    if (index !== componentIndex) {
      return component;
    }

    return {
      ...component,
      name
    };
  });

export default updateComponentName;
