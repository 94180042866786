import { ApiKey } from 'types/external/Token';
import { useQuery } from 'urql';

const GetApiKeyQuery = `#graphql
    query(
        $apiKeyId: String!,
        $consumerId: String!
    ) {
    apiKey(
        apiKeyId: $apiKeyId,
        consumerId: $consumerId
    ) {
        id
        key
      }
    }
`;

type Variables = {
  apiKeyId: string;
  consumerId: string;
};

function useApiKey({ apiKeyId, consumerId }: { apiKeyId: string; consumerId: string }) {
  return useQuery<ApiKey, Variables>({
    query: GetApiKeyQuery,
    pause: Boolean(!apiKeyId || !consumerId),
    variables: {
      apiKeyId,
      consumerId
    }
  });
}

export default useApiKey;
