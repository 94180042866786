import * as z from 'zod';

export const updateSchema = z.object({
  notificationSetup: z
    .array(
      z
        .union([
          z.undefined(),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('Mail'),
            enabled: z.boolean(),
            config: z.object({
              email: z
                .string()
                .email()
                .optional()
                .or(z.literal(''))
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('Telegram'),
            enabled: z.boolean(),
            config: z.object({
              telegramId: z
                .string()
                .nullable()
                .optional()
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('Slack'),
            enabled: z.boolean(),
            config: z.object({
              slackIntegration: z.any(),
              slackWorkspace: z
                .string()
                .nullable()
                .optional(),
              slackChannel: z
                .string()
                .nullable()
                .optional()
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('Teams'),
            enabled: z.boolean(),
            config: z.object({
              teamsWebhookUrl: z
                .string()
                .nullable()
                .optional()
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('WhatsApp'),
            enabled: z.boolean(),
            config: z.object({
              numbers: z.array(z.string())
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('GoogleChat'),
            enabled: z.boolean(),
            config: z.object({
              gchatWebhookUrl: z
                .string()
                .nullable()
                .optional()
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('DiscordChannel'),
            enabled: z.boolean(),
            config: z.object({
              discordChannelWebhookUrl: z
                .string()
                .nullable()
                .optional()
            })
          }),
          z.object({
            notificationSetupId: z.string().optional(),
            providerName: z.literal('WebHook'),
            enabled: z.boolean(),
            config: z.object({
              webhookUrl: z
                .string()
                .nullable()
                .optional(),
              webhookRetryFailEmail: z
                .string()
                .nullable()
                .optional(),
              webhookRetry: z
                .string()
                .nullable()
                .optional()
            })
          })
        ])
        .refine(
          values => {
            if (values?.providerName === 'Mail' && values.enabled && !values.config.email) {
              return false;
            }
            return true;
          },
          { message: "Email can't be blank", path: ['config', 'email'] }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'Telegram' &&
              values.enabled &&
              !values.config.telegramId
            ) {
              return false;
            }

            return true;
          },
          { message: "Telegram ID can't be blank", path: ['config', 'telegramId'] }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'Slack' &&
              ((values.enabled && !values.config.slackChannel) ||
                (values.enabled && !values.config.slackWorkspace))
            ) {
              return false;
            }

            return true;
          },
          { message: 'Install our slack app first' }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'Teams' &&
              values.enabled &&
              !values.config.teamsWebhookUrl
            ) {
              return false;
            }

            return true;
          },
          { message: "Webhook url can't be blank", path: ['config', 'teamsWebhookUrl'] }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'WhatsApp' &&
              values.enabled &&
              !values.config.numbers.length
            ) {
              return false;
            }

            return true;
          },
          { message: 'Select at least 1 contact', path: ['config', 'numbers'] }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'GoogleChat' &&
              values.enabled &&
              !values.config.gchatWebhookUrl
            ) {
              return false;
            }

            return true;
          },
          { message: "Webhook url can't be blank", path: ['config', 'gchatWebhookUrl'] }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'DiscordChannel' &&
              values.enabled &&
              !values.config.discordChannelWebhookUrl
            ) {
              return false;
            }

            return true;
          },
          {
            message: "Webhook url can't be blank",
            path: ['config', 'discordChannelWebhookUrl']
          }
        )
        .refine(
          values => {
            if (values?.providerName === 'WebHook' && values.enabled && !values.config.webhookUrl) {
              return false;
            }

            return true;
          },
          {
            message: "Webhook url can't be blank",
            path: ['config', 'webhookUrl']
          }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'WebHook' &&
              values.enabled &&
              values.config.webhookRetry &&
              !values.config.webhookRetryFailEmail.length
            ) {
              return false;
            }
            return true;
          },
          {
            message: "E-mail Retry can't be blank",
            path: ['config', 'webhookRetryFailEmail']
          }
        )
        .refine(
          values => {
            if (
              values?.providerName === 'WebHook' &&
              values.enabled &&
              Number(values.config.webhookRetry) > 0 &&
              !values.config.webhookRetryFailEmail.length
            ) {
              return false;
            }
            return true;
          },
          {
            message: 'Invalid E-mail',
            path: ['config', 'webhookRetryFailEmail']
          }
        )
    )
    .transform(values => values.filter(Boolean))
    .refine(
      values => {
        const atLeastOneEnabled = values.some(
          notificationProviderFields => notificationProviderFields.enabled
        );
        if (!atLeastOneEnabled) {
          return false;
        }

        return true;
      },
      { message: 'You must activate at least one notification method' }
    )
});
