import { ResourceOrigin } from 'types/external/Resource';

import keyValuesToObject from 'helpers/keyValuesToObject';
import { GetFormData } from 'views/ServicesHub/forms/monitoring/http/Get';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function getAdapter(data: MonitoringCommomData & GetFormData) {
  const headers = keyValuesToObject(data.headers);
  return {
    ...monitoringAdapter({ ...data, method: 'GET' }),
    origin: data.elvenAgentRegion ? ResourceOrigin.Pool : ResourceOrigin.Agent,
    domain: data.url,
    domainSettings: {
      ...(data.skipSslValidation ? { skipSslValidation: data.skipSslValidation } : {}),
      tlsRenegotiation: Number(data.tlsRenegotiation),
      ...(headers ? { headers } : {}),
      ...(data.elvenAgentRegion
        ? {
            poolConfig: {
              cloud: data.elvenAgentCloud,
              region: data.elvenAgentRegion
            }
          }
        : {}),
      validationString: data.validationString
    }
  };
}
