import { Avatar, ButtonBase } from '@material-ui/core';

import useStyles from './styles';

export const CustomAvatar = ({
  index,
  watch,
  changeEnabled,
  checkDay
}: {
  index: number;
  watch: any;
  changeEnabled: (index: number, enabled: boolean) => void;
  checkDay: (numberDay: number) => string;
}) => {
  const classes = useStyles();

  const value = watch(`frequencies.${index}`);

  return (
    <Avatar
      component={ButtonBase}
      className={value?.enabled ? classes.avatarEnabled : classes.avatarDisabled}
      onClick={() => changeEnabled(index, value?.enabled)}>
      {checkDay(Number(value?.dayOfWeek)).charAt(0)}
    </Avatar>
  );
};
