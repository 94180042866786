import { useMemo } from 'react';

import { useParams } from 'react-router';
import { IncidentManual } from 'types/external/IncidentManual';
import { IncidentManualWithAlerts } from 'types/external/IncidentManualWithAlerts';
import { useQuery } from 'urql';

import { useAlertsState } from 'hooks/queriesGraphQL/useAlertsState';
import { useResponders } from 'hooks/queriesGraphQL/useResponders';
import { useServiceMonitors } from 'hooks/queriesGraphQL/useServiceMonitors';
import { useTeams } from 'hooks/queriesGraphQL/useTeams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { incidentManualStartValuesAdapter } from 'views/ServicesHub/adapters/edit/incidentManual';
import { incidentManualWithAlertsStartValuesAdapter } from 'views/ServicesHub/adapters/edit/incidentManualWithAlerts';

import { getIncident, getIncidentV2 } from './providers/queries';

export function useStartValues() {
  const shouldUseIncidentManualWithAlerts = useFeatureFlagUnleash('useIncidentManualWithAlerts', {
    queryString: true
  });
  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const { uid: incidentId } = useParams<{ uid: string }>();

  const [{ data: incidentData, fetching: fetchingGetIncident }] = useQuery<{
    getIncidentWithOriginsAndTeams?: IncidentManual;
    getIncidentV2?: IncidentManualWithAlerts;
  }>({
    variables: { incidentId: Number(incidentId) },
    query: shouldUseIncidentManualWithAlerts ? getIncidentV2 : getIncident,
    pause: !incidentId
  });

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [{ data: originsData, fetching: fetchingGetOrigins }] = useServiceMonitors({
    query: shouldUseNewApplications
      ? `(type: integration) OR (type: multiflow) OR (type: resource)`
      : `(type: integration) OR (type: multiflow)`
  });

  const { data: dataAlerts, fetching: fetchingAlerts } = useAlertsState({
    pause: !shouldUseIncidentManualWithAlerts
  });

  const [{ data: teamsData, fetching: fetchingGetTeams }] = useTeams();
  const [{ data: responders, fetching: fetchingGetResponders }] = useResponders();

  const incident = incidentData?.getIncidentWithOriginsAndTeams || incidentData?.getIncidentV2;

  return useMemo(() => {
    const teamsFieldOptions =
      teamsData?.getTeamsByOrgUid?.data?.map(team => ({
        value: String(team.id),
        label: team.name
      })) ?? [];

    const respondersFieldOptions =
      responders?.getRespondersByOrgUid?.data?.map(responder => ({
        value: String(responder.uid),
        label: responder.name
      })) ?? [];

    const monitorsOptions =
      originsData?.getMonitorsService?.data?.map(monitor => ({
        value: monitor.type === 'service' ? String(monitor.id) : String(monitor.uid),
        label: monitor.name,
        type: monitor.type
      })) ?? [];

    const alertsOptions =
      dataAlerts?.alertsState?.data?.map(alert => ({
        value: alert.uid,
        label: alert.title
      })) ?? [];

    if (!incident)
      return {
        startValues: undefined,
        fetchingGetIncident: false,
        fetchingGetTeams: false,
        fetchingGetResponders: false,
        fetchingGetOrigins: false
      };

    let incidentManualStartValues = { incidentId: incident.id };

    if (shouldUseIncidentManualWithAlerts) {
      incidentManualStartValues = incidentManualWithAlertsStartValuesAdapter({
        incident: incident as IncidentManualWithAlerts,
        respondersFieldOptions: shouldUseRespondersInput
          ? respondersFieldOptions
          : teamsFieldOptions,
        monitorsOptions,
        alertsOptions
      });
    }

    if (!shouldUseIncidentManualWithAlerts) {
      incidentManualStartValues = incidentManualStartValuesAdapter({
        incident: incident as IncidentManual,
        respondersFieldOptions: shouldUseRespondersInput
          ? respondersFieldOptions
          : teamsFieldOptions,
        monitorsOptions
      });
    }

    const startValues = {
      ...incidentManualStartValues
    };

    return {
      startValues,
      fetchingGetIncident,
      fetchingAlerts,
      fetchingGetTeams,
      fetchingGetResponders,
      fetchingGetOrigins
    };
  }, [
    fetchingGetIncident,
    incident,
    fetchingGetTeams,
    fetchingGetResponders,
    fetchingGetOrigins,
    fetchingAlerts,
    teamsData,
    responders,
    originsData,
    dataAlerts,
    shouldUseIncidentManualWithAlerts,
    shouldUseRespondersInput
  ]);
}
