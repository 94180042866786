import { Resource } from 'types/external/Resource';

import { DynamodbFormData } from 'views/ServicesHub/forms/monitoring/database/Dynamodb';

import { monitoringAdapter } from '../monitoring';

export function dynamodbAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & DynamodbFormData {
  const domain = data.domain;
  // ex1: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TABLE_NAME
  // ex2: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TABLE_NAME:PRIMARY_KEY:VALUE_ITEM

  const dynamoDBDomainRegex = /(.+):(.+)\/(.+)@([^:]+)(?::([^.]+):(.+))?/;

  const match = domain.match(dynamoDBDomainRegex)!;

  const accessKey = match[1];
  const secretAccessKey = match[2];
  const awsRegion = match[3];
  const tableName = match[4];
  const primarykey = match[5] ?? '';
  const valueItem = match[6] ?? '';

  return {
    ...monitoringAdapter(data),
    accessKey,
    secretAccessKey,
    awsRegion,
    tableName,
    primarykey,
    valueItem
  };
}
