import { useEffect, useMemo, useCallback } from 'react';

import { useQuery } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import SelectSimple from 'componentsV3/Select';

const GetSubnetQuery = `#graphql
  query (
    $cloudId: Float!
    $region: String!
    $vpcId: String!
  ) {
    subnet(
      page: 1
      per_page: 1000
      sort: "name,ASC"
      cloudId: $cloudId
      region: $region
      vpcId: $vpcId
    ) {
      data {
        subnetName
        subnetId
      }
    }
  }
`;

const GetSubnetStatusQuery = `#graphql
  query (
    $cloudId: Float!
    $region: String!
    $subnetId: String!
  ) {
    subnetStatus(
      page: 1
      per_page: 1000
      sort: "name,ASC"
      cloudId: $cloudId
      region: $region
      subnetId: $subnetId
    ) {
      data {
        subnetStatus
        associatePublicIpAddress
      }
    }
  }
`;

export const useSubnetQuery = ({ cloudId, region, vpcId }) => {
  const [result] = useQuery({
    query: GetSubnetQuery,
    pause: [cloudId, region, vpcId].some(value => !value),
    variables: {
      cloudId,
      region,
      vpcId
    }
  });

  return [result];
};

const useSubnetStatusQuery = ({ cloudId, region, subnetId }) => {
  const [result] = useQuery({
    query: GetSubnetStatusQuery,
    pause: [cloudId, region, subnetId].some(value => !value),
    variables: {
      cloudId,
      region,
      subnetId
    }
  });

  return [result];
};

const SubnetSelectAWS = ({
  disabled,
  control,
  selectedCloudId,
  selectedSubnetId,
  region,
  vpcId,
  errors,
  warnings,
  setWarning,
  setValue,
  setError,
  clearErrors,
  clearWarnings,
  required
}) => {
  const [subnetResult] = useSubnetQuery({
    cloudId: selectedCloudId,
    region,
    vpcId
  });

  const [subnetStatusResult] = useSubnetStatusQuery({
    cloudId: selectedCloudId,
    region: region,
    subnetId: selectedSubnetId
  });

  const { data: subnetData, fetching } = subnetResult;
  const { data: subnetStatusData, fetching: fetchingSubnetStatus } = subnetStatusResult;

  const subnetList = useMemo(() => {
    if (fetching)
      return [
        {
          label: 'Loading...',
          value: ''
        }
      ];
    if (!subnetData) return [];

    const subnets = subnetData.subnet.data;

    return subnets.map(({ subnetId, subnetName }) => {
      const name = `(${subnetName ? subnetName : 'no name'})`;
      const label = `${subnetId} ${name}`.trim();

      return {
        label,
        value: subnetId
      };
    });
  }, [subnetData, fetching]);

  const validateSubnet = useCallback(
    status => {
      if (status.subnetStatus === 'unavailable') {
        clearWarnings('subnet');

        return setError('subnet', {
          message: 'Subnet not elegible! Verify your RouteTable.'
        });
      }

      if (status.subnetStatus === 'unknown') {
        clearErrors('subnet');

        setWarning('subnet', {
          message: 'Unknown subnet RouteTable! Use it at your own risk.'
        });
      }

      if (status.subnetStatus === 'available') {
        clearWarnings('subnet');
        clearErrors('subnet');
      }

      setValue('associatePublicIpAddress', Boolean(status.associatePublicIpAddress));
    },
    [clearErrors, clearWarnings, setError, setValue, setWarning]
  );

  useEffect(() => {
    if (!selectedSubnetId || fetchingSubnetStatus || !subnetStatusData?.subnetStatus?.data) return;

    const subnet = subnetList.find(({ value }) => value === selectedSubnetId);

    const subnetStatus = subnetStatusData.subnetStatus.data;

    if (subnet) {
      validateSubnet(subnetStatus);
    }
  }, [fetchingSubnetStatus, selectedSubnetId, subnetList, subnetStatusData, validateSubnet]);

  return (
    <>
      <SelectSimple
        disabled={disabled}
        errors={errors}
        warnings={warnings}
        name="subnet"
        control={control}
        label="Subnet"
        defaultValue=""
        items={subnetList}
        required={required}
      />

      {fetchingSubnetStatus && <LoadingOverlay text="Validating subnet..." size={60} />}
    </>
  );
};

export default SubnetSelectAWS;
