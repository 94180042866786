import { SvgIconProps, useTheme } from '@material-ui/core';
import {
  CodeSharp as CodeSharpIcon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatQuoteOutlined as FormatQuoteIcon,
  InsertLink as InsertLinkIcon
} from '@material-ui/icons';
import MDEditor, { commands, MDEditorProps } from '@uiw/react-md-editor';
import { Control, Controller } from 'react-hook-form';

import ChecklistIcon from 'icons/Checklist';
import palette from 'theme/v4/palette';

import './styles.css';

const commandIconProps: SvgIconProps = {
  style: {
    fontSize: '1.5rem'
  },
  htmlColor: palette.grey[800]
};

const commandsMdEditor = {
  bold: {
    ...commands.bold,
    icon: <FormatBoldIcon {...commandIconProps} />
  },
  italic: {
    ...commands.italic,
    icon: <FormatItalicIcon {...commandIconProps} />
  },
  quote: {
    ...commands.quote,
    icon: <FormatQuoteIcon {...commandIconProps} />
  },
  code: {
    ...commands.code,
    icon: <CodeSharpIcon {...commandIconProps} />
  },
  link: {
    ...commands.link,
    icon: <InsertLinkIcon {...commandIconProps} />
  },
  divider: commands.divider,
  orderedListCommand: {
    ...commands.orderedListCommand,
    icon: <FormatListNumberedIcon {...commandIconProps} />
  },
  unorderedList: {
    ...commands.unorderedListCommand,
    icon: <FormatListBulletedIcon {...commandIconProps} />
  },
  checkedList: {
    ...commands.checkedListCommand,
    icon: <ChecklistIcon {...commandIconProps} />
  }
};

const MdEditorConfig: MDEditorProps = {
  height: 400,
  preview: 'edit'
};

type Commands =
  | 'bold'
  | 'italic'
  | 'quote'
  | 'code'
  | 'link'
  | 'orderedList'
  | 'unorderedList'
  | 'divider'
  | 'checkedList';

type MarkdownEditorProps = {
  /**
   * React Hook Form Control
   */
  control: Control;
  /**
   * Tip: Enter a markdown template
   */
  defaultValue?: string;
  /**
   * Field form name
   */
  name: string;
  /**
   * Md Editor Commands
   */
  MdEditorCommands?: Commands[];
  /**
   * CSS Properties
   */
  style?: React.CSSProperties;
};

const defaultCommands: MarkdownEditorProps['MdEditorCommands'] = [
  'bold',
  'italic',
  'quote',
  'code',
  'link',
  'orderedList',
  'unorderedList',
  'checkedList'
];

function MarkdownEditor({
  control,
  defaultValue = '',
  name,
  MdEditorCommands = defaultCommands,
  style = {}
}: MarkdownEditorProps) {
  const theme = useTheme();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      disableHTML={true}
      render={({ onChange, onBlur, value }) => (
        <MDEditor
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          style={{ marginTop: theme.spacing(4), ...style }}
          textareaProps={{
            name
          }}
          {...MdEditorConfig}
          commands={MdEditorCommands?.map(command => {
            return (commandsMdEditor as any)[command];
          })}
        />
      )}
    />
  );
}

export default MarkdownEditor;
