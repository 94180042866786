import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type ElasticFormData = {
  host: string;
  port: string;
  user?: string | undefined;
  password?: string | undefined;
  apiKey?: string | undefined;
  index?: string | undefined;
  query?: { key: string; value: string }[] | undefined;
  monitoringType: ElasticMonitoringType;
  protocol: string;
};
export enum ElasticMonitoringType {
  noAuth = 'noAuth',
  userAndPass = 'userAndPass',
  apiKey = 'apiKey'
}

// ex1: http://HOST:PORT/_cluster/health/staging_entities-orgid
// ex2: http://USER:PASSWORD@HOST:PORT/_cluster/health/staging_entities-orgid
// ex3: http://APIKEY@HOST:PORT/_cluster/health/staging_entities-orgid

function SelectExemple(monitoringType: string) {
  if (monitoringType === ElasticMonitoringType.noAuth) {
    return `[protocol]://[host]:[port(optional)]/_cluster/health`;
  }
  if (monitoringType === ElasticMonitoringType.userAndPass) {
    return `[protocol]://[user]:[password]@[host]:[port(optional)]/_cluster/health`;
  }

  return `[protocol]://[apiKey]@[host]:[port(optional)]/_cluster/health`;
}

export function ElasticForm({ form }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  const monitoringType = watch('monitoringType');

  const domainExmple = SelectExemple(watch('monitoringType'));

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <Select
          options={[
            { value: ElasticMonitoringType.noAuth, label: 'No Auth' },
            {
              value: ElasticMonitoringType.userAndPass,
              label: 'User and Password'
            },
            {
              value: ElasticMonitoringType.apiKey,
              label: 'API Key'
            }
          ]}
          control={control}
          name="monitoringType"
          label="Monitoring Type"
          required={true}
          helperText={`Ex: ${domainExmple}`}
        />
      </Box>

      {monitoringType === 'userAndPass' && (
        <Box display="flex" gridGap="1rem">
          <Box>
            <Select
              options={[
                { value: 'https', label: 'https://' },
                { value: 'http', label: 'http://' }
              ]}
              control={control}
              name="protocol"
              label="Protocol"
              required={true}
            />
          </Box>
          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'text',
              label: 'User',
              name: 'user',
              required: true
            }}
            InputComponent={Input}
          />
          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'password',
              label: 'Password',
              name: 'password'
            }}
            InputComponent={Input}
          />
        </Box>
      )}

      {monitoringType === 'apiKey' && (
        <Box display="flex" gridGap="1rem">
          <Box>
            <Select
              options={[
                { value: 'https', label: 'https://' },
                { value: 'http', label: 'http://' }
              ]}
              control={control}
              name="protocol"
              label="Protocol"
              required={true}
            />
          </Box>
          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'password',
              label: 'API Key',
              name: 'apiKey'
            }}
            InputComponent={Input}
          />
        </Box>
      )}
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" width="100%" gridGap="1rem">
          {monitoringType === 'noAuth' && (
            <Box>
              <Select
                options={[
                  { value: 'https', label: 'https://' },
                  { value: 'http', label: 'http://' }
                ]}
                control={control}
                name="protocol"
                label="Protocol"
                required={true}
              />
            </Box>
          )}
          <Box display="flex" alignItems="center" width={'100%'}>
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Host',
                name: 'host'
              }}
              InputComponent={Input}
            />
          </Box>
          <Box width="50%">
            <Input
              label="Port"
              name="port"
              required={false}
              inputRef={register}
              type="number"
              inputProps={{
                min: 1,
                max: 65535
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input label="Index" name="index" inputRef={register} required={false} />
      </Box>
    </Box>
  );
}
