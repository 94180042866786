import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

interface CreateManualIncidentButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

function CreateManualIncidentButton({ disabled }: CreateManualIncidentButtonProps) {
  const canShowMetricsButton = useFeaturePlan('Metrics');

  if (!canShowMetricsButton) {
    return null;
  }

  return (
    <Button
      component={Link}
      to="/services-hub/new?type=incident-manual"
      title="Create new incident"
      disabled={Boolean(disabled)}>
      Incident
    </Button>
  );
}

export default CreateManualIncidentButton;
