import { ComponentProps, PropsWithChildren } from 'react';

import { HealthStatus } from 'constants/MonitoringStatusV1';
import { ResourceMonitoringAndHealthStatus } from 'constants/ResourceMonitoringAndHealthStatus';

import { Box } from '@material-ui/core';

import DownsLineComponent from '../DownsLineComponent';
import StatusColors from '../StatusColors';

const WrappedDownsLineComponent = ({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof DownsLineComponent>>) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <DownsLineComponent {...props} />
  </Box>
);

const StatusLine = ({
  monitoredApplications,
  resourcesMonitoringAndHealthStatus,
  productId
}: {
  monitoredApplications: boolean;
  resourcesMonitoringAndHealthStatus: ResourceMonitoringAndHealthStatus[] | null;
  productId: number;
}) => {
  if (!monitoredApplications || !resourcesMonitoringAndHealthStatus?.length) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.none}
        text={`No monitored resources`}
        link={null}
      />
    );
  }

  const resourcesDown = resourcesMonitoringAndHealthStatus.filter(
    resource => resource.healthStatus === HealthStatus.Down
  );
  const hasResourcesDown = resourcesDown.length > 0;
  const moreThanOneResourceDown = resourcesDown.length > 1;

  if (hasResourcesDown && !moreThanOneResourceDown) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.down}
        text={`${resourcesDown[0].name} is not operational`}
        link={`/products/${productId}/availability/applications/${resourcesDown[0].rootApplicationId}`}
      />
    );
  }

  if (hasResourcesDown && moreThanOneResourceDown) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.down}
        text={`${resourcesDown.length} resources are not operational`}
        link={null}
      />
    );
  }

  const resourcesInMaintenance = resourcesMonitoringAndHealthStatus.filter(
    resource => resource.monitoringStatus === 'Maintenance'
  );
  const hasResourcesInMaintenance = resourcesInMaintenance.length > 0;
  const moreThanOneResourceInMaintenance = resourcesInMaintenance.length > 1;

  if (hasResourcesInMaintenance && !moreThanOneResourceInMaintenance) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.maintenance}
        text={`${resourcesInMaintenance[0].name} is in maintenance`}
        link={`/products/${productId}/availability/applications/${resourcesInMaintenance[0].rootApplicationId}`}
      />
    );
  }

  if (hasResourcesInMaintenance && moreThanOneResourceInMaintenance) {
    return (
      <WrappedDownsLineComponent
        status={StatusColors.maintenance}
        text={`${resourcesInMaintenance.length} resources are in maintenance`}
        link={null}
      />
    );
  }

  return <WrappedDownsLineComponent status={StatusColors.up} text={`Operational`} link={null} />;
};

export default StatusLine;
