import { postBodyRawTypeValues } from 'constants/postBody';

const convertJsonToString = (post_body_type, post_body) => {
  if (post_body_type !== postBodyRawTypeValues.json || !post_body) {
    return '';
  }

  if (post_body === '""') {
    return '';
  }

  if (typeof post_body === 'string') {
    return JSON.stringify(JSON.parse(post_body), undefined, 4);
  }

  return JSON.stringify(post_body, undefined, 4);
};

export default convertJsonToString;
