import { PingFormData } from 'views/ServicesHub/forms/monitoring/others/Ping';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function pingAdapter(data: MonitoringCommomData & PingFormData) {
  return {
    ...monitoringAdapter({ ...data, method: 'ping' }),
    domain: data.host
  };
}
