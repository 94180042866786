import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab-old/Autocomplete';

import { ButtonSimple } from 'componentsV4/Button';

const FormAddUser = ({ members, onAddMember, onChangeAutocomplete, disabled }) => {
  const [user, setUser] = React.useState(null);
  const [value, setValue] = React.useState('');
  const [role, setRole] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const addButtonDisabled = !user || !role;

  React.useEffect(() => {
    setOptions(
      members.autoComplete.options.map(option => ({
        id: option._source.userId,
        email: option._source.email,
        name: option._source.displayName
      }))
    );
  }, [members]);

  React.useEffect(() => {
    if (!value) {
      setOptions([]);
    } else {
      onChangeAutocomplete(value);
    }
  }, [value, onChangeAutocomplete]);

  const handleAddMember = () => {
    onAddMember({ userId: user.id, roleId: role });
    setRole('');
    setUser(null);
    setValue('');
  };

  return (
    <Grid container spacing={2} display="flex" alignItems="center">
      <Grid md={3} sm={4} xs={12} item>
        <FormControl fullWidth>
          <Autocomplete
            inputValue={value}
            open={open}
            disabled={disabled}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={(event, value) => setUser(value)}
            onInputChange={(event, value) => setValue(value)}
            getOptionSelected={(option, value) => option.email === value.email}
            getOptionLabel={option => option.email}
            options={options}
            renderInput={params => (
              <TextField
                {...params}
                disabled={disabled}
                label="Email"
                name="email"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid sm={2} xs={12} item>
        <FormControl fullWidth variant="outlined" margin="dense" disabled={disabled}>
          <InputLabel>Role</InputLabel>
          <Select
            label="Role"
            name="role"
            value={role}
            onChange={event => setRole(event.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {members.roles.map(role => (
              <MenuItem key={role.name} value={role.id} name={role.name}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid sm={1} xs={12} item display="flex">
        <ButtonSimple
          fullWidth
          name="add-button"
          color="primary"
          variant="contained"
          disabled={addButtonDisabled}
          onClick={handleAddMember}
          text="ADD"
        />
      </Grid>
    </Grid>
  );
};

export default FormAddUser;
