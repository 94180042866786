import { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { blue, purple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import MuiTypography from '@material-ui/core/Typography';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { makeStyles } from '@material-ui/styles';
import dayjs from 'dayjs';
import Fullscreen, { useFullscreenActions } from 'oldComponents/Fullscreen';
import { Typography } from 'oldComponents/Typography';
import DataTable from 'react-data-table-component';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import Timeline from '../Timeline';

import Status from './checkAppAvailability/Status';
import validateApplicationStatus from './checkAppAvailability/validateApplicationStatus';
import { Timer } from './Timer';

const dataGridTheme = {
  title: {
    height: 0
  }
};

const customStyles = {
  table: {
    style: {
      border: '1px solid #ececec'
    }
  },
  headRow: {
    style: {
      minHeight: '32px',
      maxHeight: '32px',
      '& div': {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }
    }
  }
};

const useStyle = makeStyles({
  '@keyframes blinker': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  up: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  down: {
    fontSize: 18,
    fontWeight: 'bold',
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  },
  link: {
    fontSize: 18
  },
  tooltip: {
    fontSize: 12,
    padding: 8
  }
});

function createFirstRowToApplicationsStatus(data, applicationsStatus) {
  data.forEach(app => {
    applicationsStatus[app.id] = app;
  });
  return applicationsStatus;
}

function createEnvironmentsData(data) {
  return data.reduce((envs, app) => {
    const env = envs[app.environment.id];
    if (env) {
      env.data.push(app);
    } else {
      envs[app.environment.id] = {
        name: app.environment.name,
        id: app.environment.id,
        data: [app]
      };
    }
    return envs;
  }, {});
}

function buildConditionalCell(fn, bgColor, labelColor) {
  return {
    when: fn,
    style: {
      backgroundColor: bgColor,
      color: labelColor || 'white',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  };
}

function validateColumnIsApplicationAsAddonName(row) {
  return row.resourceType === 'application';
}

function validateColumnIsServiceAsAddonName(row) {
  return row.resourceType === 'service';
}

function validateColumnIsServiceAsAddonAuthStep(row) {
  return row.resourceType === 'auth step';
}

function conditionalCellWhenColumnIsServiceAsAddonAuthStep() {
  return buildConditionalCell(row => validateColumnIsServiceAsAddonAuthStep(row), '#003DA3');
}

function conditionalCellWhenColumnIsServiceAsAddonName() {
  return buildConditionalCell(row => validateColumnIsServiceAsAddonName(row), blue[500]);
}

function conditionalCellWhenColumnIsApplicationAsAddonName() {
  return buildConditionalCell(row => validateColumnIsApplicationAsAddonName(row), purple[500]);
}

function createFirstColumnWithAddonName() {
  return {
    name: '',
    conditionalCellStyles: [
      conditionalCellWhenColumnIsApplicationAsAddonName(),
      conditionalCellWhenColumnIsServiceAsAddonName(),
      conditionalCellWhenColumnIsServiceAsAddonAuthStep()
    ],
    cell: row => {
      const item = {
        name: 'Dependency'
      };
      if (row.resourceName) {
        item.name = row.resourceName;
      }
      return <div>{item.name}</div>;
    }
  };
}

// Addons
function validateAddonStatus(app, row, shouldUseResource) {
  const response = {
    status: '',
    sla: 0
  };

  if (row.resourceId) {
    response.status = Status['DOES-NOT-DEPEND'];
    if (row.apps) {
      const hasApp = row.apps.find(application => application.id === app.id);
      if (hasApp) {
        if (!hasApp.status) {
          response.status = Status['MONITORING-IS-INACTIVE'];
          return response;
        }

        const addon = app.addons.find(
          addon =>
            (row.resourceType === 'service' &&
              row.resourceId === (shouldUseResource ? addon.resourceUid : addon.serviceId)) ||
            (row.resourceType === 'application' &&
              row.resourceId === addon.applicationDependency?.id) ||
            (row.resourceType === 'auth step' && row.resourceId === addon.serviceId)
        );
        if (addon) {
          response.status = Status['MONITORING-IS-INACTIVE'];
          const statusAddon = addon.status;

          if (statusAddon) {
            response.statusService = statusAddon.status;
            response.sla = statusAddon.online_1_hour;

            if (response?.statusService === 'Maintenance') {
              response.status = Status['MAINTENANCE'];
              return response;
            }

            if (statusAddon.online_1_hour >= 99.9) {
              response.status = Status['AVAILABLE'];
            } else if (statusAddon.online_1_hour < 99.9 && statusAddon.online_1_hour >= 98) {
              response.status = Status['DEGRADED'];
            } else {
              response.status = Status['UNAVAILABLE'];
            }
          }
        }
      }
    }
  }
  return response;
}

function conditionalCellToAddonStatus({ app, status, labelColor, shouldUseResource }) {
  return buildConditionalCell(
    row => {
      const response = validateAddonStatus(app, row, shouldUseResource);
      return response.status.label === status.label;
    },
    status.color,
    labelColor
  );
}

function conditionalCellToApplicationStatus(app, status) {
  return buildConditionalCell(row => {
    const response = validateApplicationStatus(app, row);
    return response.status.label === status.label;
  }, status.color);
}

const GetMatrixByProductIdV5 = `#graphql
  query($productId: Float!) {
    matrixV5(productId: $productId) {
      data {
        name
        id
        cover
        applications {
          id
          name
          productId
          addons {
            id
            serviceId
            useResource
            resourceName
            resourceUid
            applicationDependency {
              id
              name
            }
            multiFlow
            service {
              id
              name
            }
            status {
              addon_id
              application_id
              status
              online_1_hour
            }
          }
          environment {
            id
            name
          }
          status {
            addon_id
            application_id
            status
            online_1_hour
          }
        }
      }
    }
  }
`;

const REFRESH_INTERVAL_IN_MS = 1000 * 60; // 60 secs

const Grid = ({ gridTitle, columns, orderedRows }) => {
  return (
    <>
      <DataTable
        customTheme={dataGridTheme}
        customStyles={customStyles}
        clicked
        columns={columns}
        data={orderedRows}
        highlightOnHover
        noDataComponent="No data"
        striped
        title={gridTitle}
      />
      <div className="availability-label" style={{ display: 'flex', marginTop: 10 }}>
        <div
          className="availability-label-app"
          style={{
            color: 'white',
            backgroundColor: purple[500],
            padding: 10,
            marginRight: 5
          }}></div>
        <div
          style={{
            marginRight: 10
          }}>
          Application Dependency
        </div>
        <div
          className="availability-label-service"
          style={{
            color: 'white',
            backgroundColor: '#003DA3',
            padding: 10,
            marginRight: 5
          }}></div>
        <div
          style={{
            marginRight: 10
          }}>
          Authentication Step
        </div>
        <div
          className="availability-label-service"
          style={{
            color: 'white',
            backgroundColor: blue[500],
            padding: 10,
            marginRight: 5
          }}></div>
        <div>Service Dependency</div>
      </div>
    </>
  );
};

const Datagrid = ({ productId }) => {
  const [resultMatrix, reexecuteQuery] = useQuery({
    query: GetMatrixByProductIdV5,
    pause: !productId,
    variables: { productId }
  });

  const [lastUpdate, setLastUpdate] = useState(new Date());

  const { fetching } = resultMatrix;

  const shouldUseResource = useFeatureFlagUnleash('useResource', { queryString: true });

  const productData = resultMatrix.data?.matrixV5?.data || { applications: [] };

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, REFRESH_INTERVAL_IN_MS);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  useEffect(() => {
    if (!fetching && resultMatrix.data?.matrixV5?.data) {
      setLastUpdate(new Date());
    }

    return;
  }, [fetching, resultMatrix.data?.matrixV5?.data]);

  const { name: productName, applications } = productData;

  const classes = useStyle();
  const { open, handleClickOpen, handleClose } = useFullscreenActions();

  const timer = useMemo(
    () => <Timer seconds={REFRESH_INTERVAL_IN_MS / 1000} lastUpdate={lastUpdate} />,
    [lastUpdate]
  );

  if (fetching && !productData) {
    return (
      <Box pt={8} display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const envs = createEnvironmentsData(applications);

  const grids = Object.keys(envs).map(key => {
    const env = envs[key];
    const columns = [createFirstColumnWithAddonName()];
    const applicationsStatus = createFirstRowToApplicationsStatus(env.data, {});
    const rows = [applicationsStatus];

    //  Create ROWS
    env.data.forEach(app => {
      if (app?.addons?.length) {
        app.addons.forEach(addon => {
          const resourceType = () => {
            if (addon.multiFlow) {
              return 'auth step';
            }

            return addon?.applicationDependency ? 'application' : 'service';
          };

          const resourceName = () => {
            if (addon?.applicationDependency) {
              return addon.applicationDependency?.name;
            }

            if (addon.multiFlow) {
              return addon.service.name;
            }

            if (shouldUseResource) {
              return addon.resourceName;
            }

            return addon.service.name;
          };

          const resourceId = () => {
            if (addon?.applicationDependency) {
              return addon?.applicationDependency?.id;
            }

            if (addon.multiFlow) {
              return addon?.serviceId;
            }

            if (shouldUseResource) {
              return addon?.resourceUid;
            }

            return addon.serviceId;
          };

          const item = {
            apps: [app],
            resourceId: resourceId(),
            resourceType: resourceType(),
            resourceName: resourceName()
          };
          const founded = rows.find(
            resource =>
              resource.resourceId === resourceId() && resource.resourceType === resourceType()
          );
          if (founded) {
            founded.apps.push(app);
          } else {
            rows.push(item);
          }
        });
      }
    });

    const orderedRows = rows.sort((a, b) => {
      if (a.resourceType === 'application' && b.resourceType === 'service') {
        return -1;
      }
      if (a.resourceType === 'application' && b.resourceType === 'auth step') {
        return -1;
      }
      if (a.resourceType === 'auth step' && b.resourceType === 'service') {
        return -1;
      }
      if (a.resourceType === 'service' && b.resourceType === 'application') {
        return 1;
      }
      return 0;
    });
    env.data.forEach(app => {
      const url = `/products/${app.productId}/availability/applications/${app.id}`;
      columns.push({
        name: (
          <Link app-data={app.name} component={RouterLink} to={url} className={classes.link}>
            <div style={{ width: '100%', whiteSpace: 'nowrap', alignItems: 'center' }}>
              <Box
                textAlign="center"
                title={app.name}
                component="div"
                my={2}
                textOverflow="ellipsis"
                overflow="hidden">
                {app.name}
              </Box>
            </div>
          </Link>
        ),
        conditionalCellStyles: [
          conditionalCellToApplicationStatus(app, Status['UNAVAILABLE']),
          conditionalCellToApplicationStatus(app, Status['DEGRADED']),
          conditionalCellToApplicationStatus(app, Status['AVAILABLE']),
          conditionalCellToApplicationStatus(app, Status['MONITORING-IS-INACTIVE']),
          conditionalCellToApplicationStatus(app, Status['MAINTENANCE']),

          conditionalCellToAddonStatus({ app, status: Status['UNAVAILABLE'], shouldUseResource }),
          conditionalCellToAddonStatus({ app, status: Status['DEGRADED'], shouldUseResource }),
          conditionalCellToAddonStatus({ app, status: Status['AVAILABLE'], shouldUseResource }),
          conditionalCellToAddonStatus({
            app,
            status: Status['DOES-NOT-DEPEND'],
            labelColor: 'black',
            shouldUseResource
          }),
          conditionalCellToAddonStatus({
            app,
            status: Status['MONITORING-IS-INACTIVE'],
            shouldUseResource
          }),
          conditionalCellToAddonStatus({ app, status: Status['MAINTENANCE'], shouldUseResource })
        ],
        cell: row => {
          if (row.resourceId) {
            const response = validateAddonStatus(app, row, shouldUseResource);
            return renderCell(response);
          }
          const response = validateApplicationStatus(app, row);
          return renderCell(response);
        }
      });
    });

    const renderCell = response => {
      if (response.status.active) {
        return (
          <Tooltip
            title="In the last hour"
            aria-label="In the last hour"
            classes={{ tooltip: classes.tooltip }}>
            <Box display="flex" justifyContent="space-beetwen" width="100%">
              <Box width={80}>
                {(response.statusService === 'ON' ||
                  response.statusService === 'Up' ||
                  response.statusService === 'available') && <div className={classes.up}>UP</div>}
                {(response.statusService === 'OFF' ||
                  response.statusService === 'Down' ||
                  response.statusService === 'unavailable') && (
                  <div className={classes.down}>DOWN</div>
                )}
              </Box>
              <Box width="100%" textAlign="center" fontSize={18}>
                {response.sla || 0}%
              </Box>
            </Box>
          </Tooltip>
        );
      }
      return (
        <Box display="flex" width="100%">
          <Box width="100%" textAlign="center" fontSize={14}>
            {response.status.label}
          </Box>
        </Box>
      );
    };

    const fullscreenOpen = open === key;

    const grid = (
      <Grid
        gridTitle={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MuiTypography variant="h5" style={{ marginRight: 8 }}>
              {env.name}
            </MuiTypography>

            {!fullscreenOpen && (
              <IconButton
                onClick={() => {
                  handleClickOpen(key);
                }}
                title="Open in fullscreen"
                aria-label="Open in fullscreen">
                <FullscreenIcon />
              </IconButton>
            )}

            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Last sync: {dayjs(lastUpdate).format('HH:mm')}• Next check in: {timer}
            </Typography>
          </div>
        }
        columns={columns}
        orderedRows={orderedRows}
      />
    );

    return (
      <div key={key} style={{ marginBottom: 32 }}>
        {!fullscreenOpen && grid}

        <Fullscreen title={productName} open={fullscreenOpen} handleClose={handleClose}>
          {fullscreenOpen && grid}

          <Timeline
            productId={productId}
            environmentId={env.id}
            applications={applications}
            environment={env}
          />
        </Fullscreen>
      </div>
    );
  });

  return grids;
};

export default Datagrid;
