import { useState } from 'react';

import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import mapData from 'componentsV3/ApplicationMultiHttp/mapData';
import { ButtonSimple } from 'componentsV3/ButtonSimple';
import ModalAllReady from 'componentsV3/ModalAllReady';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';
import { createQuery } from 'views/Products/View/Availability/components/Resources/Form/queries';

import FormMultiHttp from './FormMultiHttp';
import useForm from './useForm';

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    marginTop: spacing(3),
    color: palette.text.primary
  },
  subtitle: {
    margin: spacing(2, 0, 4)
  },
  form: {
    maxWidth: 830
  },
  post: {
    marginTop: spacing(4),
    marginBottom: spacing(2)
  },
  button: {
    marginTop: spacing(9)
  },
  serverError: {
    color: palette.error.main
  }
}));

const CreateMultiHttpApplication = `#graphql
  mutation($applicationInput: ApplicationMultiHttpInput!) {
    createMultiHttpApplication(
      applicationInput: $applicationInput
    ) {
      id
    }
  }
`;

const defaultValues = {
  environmentId: '',
  productId: '',
  check_interval: 60,
  timeout: 60,
  failuresToIncident: 2,

  pre_name: '',
  pre_healthcheckUrl: '',
  pre_method: 'GET',
  pre_headers: [{ key: '', value: '' }],
  pre_validationString: '',
  pre_skip_ssl_validation: false,
  pre_post_body_default: [{ key: '', value: '' }],
  pre_post_body_urlencoded: [{ key: '', value: '' }],
  pre_post_body_raw: '',
  pre_post_body_type: postBodyTypeValues.raw,
  pre_post_body_raw_type: postBodyRawTypeValues.json,

  pos_name: '',
  pos_healthcheckUrl: '',
  pos_method: 'GET',
  pos_headers: [{ key: '', value: '' }],
  pos_validationString: '',
  pos_skip_ssl_validation: false,
  pos_post_body_default: [{ key: '', value: '' }],
  pos_post_body_urlencoded: [{ key: '', value: '' }],
  pos_post_body_raw: '',
  pos_post_body_type: postBodyTypeValues.raw,
  pos_post_body_raw_type: postBodyRawTypeValues.json,
  pos_teamId: '',
  openIncidentWithSeverity: 'not-classified'
};

type ApplicationMultiHttpProps = {
  productId: number | null;
  environmentId: number | null;
  firstAccess: boolean | null;
};

const ApplicationMultiHttp = ({
  productId,
  environmentId,
  firstAccess
}: ApplicationMultiHttpProps) => {
  const classes = useStyles();

  const [{ fetching, error }, createApplication] = useMutation(CreateMultiHttpApplication);

  const serverErrorMessage =
    error?.graphQLErrors && error?.graphQLErrors[0] && error.graphQLErrors[0].originalError
      ? error.graphQLErrors[0].originalError.message
      : null;

  const {
    control,
    handleSubmit,
    register,
    errors,
    watch,
    setValue,
    pre_headersFields,
    pre_headersAppend,
    pre_headersRemove,
    pre_postBodyXFormUrlEncodedFields,
    pre_postBodyXFormUrlEncodedAppend,
    pre_postBodyXFormUrlEncodedRemove,
    pos_headersFields,
    pos_headersAppend,
    pos_headersRemove,
    pos_postBodyXFormUrlEncodedFields,
    pos_postBodyXFormUrlEncodedAppend,
    pos_postBodyXFormUrlEncodedRemove,
    // TODO: usar o isValid
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { isValid }
  } = useForm({
    defaultValues: {
      ...defaultValues,
      environmentId,
      productId
    }
  });

  const history = useHistory();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [, addResource] = useMutation(createQuery);

  const onSubmit = (data: any) => {
    const mappedData = mapData({
      ...data,
      teamId: null,
      productId,
      environmentId: environmentId
    });
    createApplication({
      applicationInput: { ...mappedData }
    }).then(async result => {
      if (result.error) {
        return analyticsEvent(firstAccess ? 'fa_error_app_multi' : 'error_app_multi');
      }

      analyticsEvent(firstAccess ? 'fa_success_app_multi' : 'success_app_multi');
      setApplicationId(result.data.createMultiHttpApplication.id);

      const resultTeamId = data.pos_teamId;
      if (!!resultTeamId) {
        await addResource({
          teamIds: Number(resultTeamId),
          resourceId: Number(result.data.createMultiHttpApplication.id)
        });
      }
      setShowSuccessModal(true);
    });
  };

  return (
    <Box>
      <ModalAllReady
        title="All set"
        description="Go to the Application page to activate monitoring"
        mainActionLabel="Go to Application page"
        mainAction={() => {
          const params = new URLSearchParams();

          if (firstAccess) {
            params.append('firstAccess', 'true');
          }

          params.append('redirectedBy', 'internalMultiHttp');

          history.push(
            `/products/${productId}/availability/applications/${applicationId}?${params.toString()}`
          );
        }}
        isOpen={showSuccessModal}
        firstAccess={firstAccess}
        applicationType="internalMultiHttp"
      />

      <Grid>
        <Typography className={classes.title} variant="h2">
          Configure application
        </Typography>

        <Typography className={classes.subtitle} variant="body2">
          In an external application without dependencies, it will not be necessary to register a
          cloud or environment.
        </Typography>
        <Typography className={classes.post} variant="h2">
          Pre Request
        </Typography>
        <form className={classes.form} name="newApplication" onSubmit={handleSubmit(onSubmit)}>
          <FormMultiHttp
            type="pre"
            isLoading={fetching}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            headersFields={pre_headersFields}
            headersAppend={pre_headersAppend}
            headersRemove={pre_headersRemove}
            postBodyXFormUrlEncodedFields={pre_postBodyXFormUrlEncodedFields}
            postBodyXFormUrlEncodedAppend={pre_postBodyXFormUrlEncodedAppend}
            postBodyXFormUrlEncodedRemove={pre_postBodyXFormUrlEncodedRemove}
          />
          <Typography className={classes.post} variant="h2">
            Post Request
          </Typography>

          <FormMultiHttp
            type="pos"
            isLoading={fetching}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            headersFields={pos_headersFields}
            headersAppend={pos_headersAppend}
            headersRemove={pos_headersRemove}
            postBodyXFormUrlEncodedFields={pos_postBodyXFormUrlEncodedFields}
            postBodyXFormUrlEncodedAppend={pos_postBodyXFormUrlEncodedAppend}
            postBodyXFormUrlEncodedRemove={pos_postBodyXFormUrlEncodedRemove}
          />
          {serverErrorMessage && (
            <Grid item xs={12}>
              <Typography className={classes.serverError}>{serverErrorMessage}</Typography>
            </Grid>
          )}
          <Grid className={classes.button} xs={3} item>
            <ButtonSimple
              text="Finish Application"
              type="submit"
              color="primary"
              onClick={() =>
                analyticsEvent(
                  firstAccess ? 'fa_button_finish_app_multi' : 'button_finish_app_multi'
                )
              }
            />
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};

export default withThemeV3Hoc(ApplicationMultiHttp);
