import actions from '../actions';

const msg = {
  'auth/wrong-password': 'Invalid password or account has no password',
  'auth/popup-closed-by-user': 'Popup closed before completing the authentication process',
  'auth/user-not-found':
    'User not found with the requested email. Preferable to go to create an account in the option below the form',
  'auth/not-active': 'User inactive',
  'auth/too-many-requests':
    'Many attempts in a row with the same email, please wait a few minutes to try again'
};

const signInValidation = (state, action) => {
  if (actions.LOGIN_USER_FAILED === action.type) {
    if (action.payload.response) {
      return {
        ...state,
        signInError: msg[action.payload.response.data.errors.code]
      };
    }

    return {
      ...state,
      signInError: msg[action.payload.code]
    };
  }

  return state;
};

export default signInValidation;
