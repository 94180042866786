import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginLeft: 'auto',
    minWidth: 42,
    height: 34,
    border: '1px solid #595959',
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  menuItem: {
    '&:hover': {
      color: theme.palette.green[400],
      backgroundColor: theme.palette.green[50]
    }
  },
  paperProps: {
    width: '220px'
  }
}));
