import { useQuery } from 'react-query';

import axios from 'redux/axios';

async function getServices({ page, per_page, sort, query }) {
  const { data } = await axios.get('/services', {
    params: {
      page,
      sort,
      per_page,
      q: query
    }
  });

  return {
    data: data.data,
    total: data.total
  };
}

export default function useServices(queryKey, { page, per_page, sort, query }, queryOptions) {
  return useQuery(queryKey, () => getServices({ page, per_page, sort, query }), {
    ...queryOptions,
    refetchOnWindowFocus: false
  });
}
