import { useQuery } from 'urql';

export type Application = {
  uid: string;
  name: string;
  status: string;
  lastCheck: string | null;
  serviceId: number | null;
  entity: string;
  entityUid: string;
  entityName: string;
  origin: string;
  environment: {
    id: number;
    name: string;
  };
  uptime: {
    online1Hour: number | null;
  };
  dependencies: Dependencies[];
};

export type Dependencies = {
  uid: string;
  entityUid: string;
  entity: string;
  entityName: string;
  status: string;
  serviceId: number | null;
  lastCheck: string | null;
  applicationUid: string;
  uptime: {
    online1Hour: number | null;
  };
};

export type NewJourneyMatrixType = {
  id: number;
  name: string;
  cover: string | null;
  applications: Application[];
};

export const GetNewJourneyMatrixQuery = `#graphql
  query($productId: Float!) {
    newJourneyMatrix(productId: $productId) {
      id
      name
      cover
      applications {
        uid
        name
        status
        lastCheck
        serviceId
        entity
        origin
        environment {
          id
          name
        }
        uptime {
          online1Hour
        }
        dependencies {
          uid
          entityUid
          entity
          entityName
          status
          serviceId
          lastCheck
          applicationUid
          uptime {
            online1Hour
          }
        }
      }
    }
  }
`;

export const PartialMatrixQuery = `#graphql
  query($productId: Float!) {
    newJourneyMatrix(productId: $productId) {
      applications {
        uid
        status
        name
        serviceId
        entityUid
        entityName
        dependencies {
          uid
          entityUid
          entity
          entityName
          status
          serviceId
          applicationUid
        }
      }
    }
  }
`;

export function useNewJourneyMatrix({ productId, query }: { productId: number; query: string }) {
  return useQuery<{ newJourneyMatrix: NewJourneyMatrixType }>({
    query: query,
    variables: {
      productId
    }
  });
}