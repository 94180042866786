import { apiAdapter } from './api/api';
import { memcachedAdapter } from './cache/memcached';
import { redisAdapter } from './cache/redis';
import { cassandraAdapter } from './database/cassandra';
import { dynamodbAdapter } from './database/dynamodb';
import { mongodbAdapter } from './database/mongodb';
import { mysqlAdapter } from './database/mysql';
import { neo4jAdapter } from './database/neo4j';
import { oracleAdapter } from './database/oracle';
import { postgresAdapter } from './database/postgres';
import { sqlserverAdapter } from './database/sqlserver';
import { getAdapter } from './http/get';
import { postAdapter } from './http/post';
import { grpcAdapter } from './others/grpc';
import { pingAdapter } from './others/ping';
import { tcpAdapter } from './others/tcp';
import { kafkaAdapter } from './queue/kafka';
import { pubSubAdapter } from './queue/pubsub';
import { rabbitmqAdapter } from './queue/rabbitmq';
import { serviceBusAdapter } from './queue/serviceBus';
import { sidekiqAdapter } from './queue/sidekiq';
import { sqsAdapter } from './queue/sqs';
import { elasticAdapter } from './searchEngine/elastic';
import { solrAdapter } from './searchEngine/solr';
import { functionAdapter } from './serverless/function';
import { functionV2Adapter } from './serverless/functionV2';
import { lambdaAdapter } from './serverless/lambda';

export const adapters = {
  cassandra: cassandraAdapter,
  dynamodb: dynamodbAdapter,
  mongodb: mongodbAdapter,
  mysql: mysqlAdapter,
  neo4j: neo4jAdapter,
  memcached: memcachedAdapter,
  redisv2: redisAdapter,
  oracle: oracleAdapter,
  postgres: postgresAdapter,
  kafka: kafkaAdapter,
  rabbitmq: rabbitmqAdapter,
  sqlserver: sqlserverAdapter,
  get: getAdapter,
  post: postAdapter,
  lambdaawsv2: lambdaAdapter,
  functions: functionAdapter,
  functionsgen2: functionV2Adapter,
  ping: pingAdapter,
  tcp: tcpAdapter,
  grpc: grpcAdapter,
  solr: solrAdapter,
  elastic: elasticAdapter,
  sqs: sqsAdapter,
  servicebus: serviceBusAdapter,
  sidekiq: sidekiqAdapter,
  pubsub: pubSubAdapter,
  api: apiAdapter
};
