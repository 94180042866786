import { SvgIcon, SvgIconProps } from '@material-ui/core';

export function TechnologyNetworkShare(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      {...props}>
      <path
        d="M9.1981 9.24963H11.5001V10.3326H10.1871C9.9111 10.3326 9.6871 10.5566 9.6871 10.8326C9.6871 11.1086 9.9111 11.3326 10.1871 11.3326H13.8121C14.0881 11.3326 14.3121 11.1086 14.3121 10.8326C14.3121 10.5566 14.0881 10.3326 13.8121 10.3326H12.5001V9.24963H14.8021C15.6261 9.24963 16.2961 8.57962 16.2961 7.75562V4.38463C16.2961 3.56063 15.6261 2.89062 14.8021 2.89062H9.1981C8.3741 2.89062 7.7041 3.56063 7.7041 4.38463V7.75562C7.7041 8.57962 8.3741 9.24963 9.1981 9.24963ZM8.7041 4.38463C8.7041 4.11263 8.9261 3.89062 9.1981 3.89062H14.8021C15.0741 3.89062 15.2961 4.11263 15.2961 4.38463V7.75562C15.2961 8.02762 15.0741 8.24963 14.8021 8.24963H9.1981C8.9261 8.24963 8.7041 8.02762 8.7041 7.75562V4.38463Z"
        fill="#19A888"
      />
      <path
        d="M8.00419 15.043H3.99619C3.44219 15.043 2.99219 15.493 2.99219 16.047V18.58C2.99219 19.134 3.44219 19.584 3.99619 19.584H5.50019V19.96H4.80319C4.52719 19.96 4.30319 20.184 4.30319 20.46C4.30319 20.736 4.52719 20.96 4.80319 20.96H7.19819C7.47419 20.96 7.69819 20.736 7.69819 20.46C7.69819 20.184 7.47419 19.96 7.19819 19.96H6.50019V19.584H8.00419C8.55819 19.584 9.00819 19.134 9.00819 18.58V16.047C9.00819 15.493 8.55819 15.043 8.00419 15.043ZM8.00519 18.583L3.99219 18.579L3.99619 16.042L8.00819 16.046L8.00519 18.583Z"
        fill="#19A888"
      />
      <path
        d="M20.0042 15.043H15.9962C15.4422 15.043 14.9922 15.493 14.9922 16.047V18.58C14.9922 19.134 15.4422 19.584 15.9962 19.584H17.5002V19.96H16.8032C16.5272 19.96 16.3032 20.184 16.3032 20.46C16.3032 20.736 16.5272 20.96 16.8032 20.96H19.1982C19.4742 20.96 19.6982 20.736 19.6982 20.46C19.6982 20.184 19.4742 19.96 19.1982 19.96H18.5002V19.584H20.0042C20.5582 19.584 21.0082 19.134 21.0082 18.58V16.047C21.0082 15.493 20.5582 15.043 20.0042 15.043ZM20.0052 18.583L15.9922 18.579L15.9962 16.042L20.0082 16.046L20.0052 18.583Z"
        fill="#19A888"
      />
      <path
        d="M17.5 14.167C17.5 14.443 17.724 14.667 18 14.667C18.276 14.667 18.5 14.443 18.5 14.167V13.125C18.5 12.849 18.276 12.625 18 12.625H12.5V12C12.5 11.724 12.276 11.5 12 11.5C11.724 11.5 11.5 11.724 11.5 12V12.625H6C5.724 12.625 5.5 12.849 5.5 13.125V14.167C5.5 14.443 5.724 14.667 6 14.667C6.276 14.667 6.5 14.443 6.5 14.167V13.625H12H17.5V14.167Z"
        fill="#19A888"
      />
    </SvgIcon>
  );
}
