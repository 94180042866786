import { z } from 'zod';

export default z.object({
  name: z.string().nonempty({ message: 'Product name is required' }),
  description: z
    .string()
    .max(256, { message: 'Max for description is 256 characters' })
    .nullable(),
  cover: z
    .any()
    .refine(
      data => {
        /** @type {File | null}*/
        const file = data[0] || null;

        if (!file) return true;

        const supportedTypes = ['image/jpeg', 'image/png'];

        if (!supportedTypes.includes(file.type)) return false;

        return true;
      },
      { message: 'File format not supported. Supported formats: jpeg, png.' }
    )
    .refine(
      data => {
        /** @type {File | null}*/
        const file = data[0] || null;

        if (!file) return true;

        const TWO_MB = 1000 * 1000 * 2;

        if (file.size > TWO_MB) return false;

        return true;
      },
      { message: 'Max file size is 2MB' }
    )
    .nullable(),
  teams: z.union([z.array(z.any()).min(1), z.string().nonempty()])
});
