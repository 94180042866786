export const customElasticQuery = ({
  queryFilters,
  customRegex = ''
}: {
  queryFilters: { key: string; value: string | string[] | boolean; isRegex?: boolean }[];
  customRegex?: string;
}) => {
  const filters: string[] = [];

  queryFilters.forEach(query => {
    const { key, value, isRegex } = query;

    if (!key || value === '' || value === 'all' || (Array.isArray(value) && !value.length)) return;

    if (Array.isArray(value) && value.length > 1) {
      const matchQueries = value.map(
        originUid => `${key}=${isRegex ? `/.*${originUid}.*/` : `"${originUid}"`}`
      );

      const matchQuery = `(${matchQueries.join(' OR ')})`;

      filters.push(matchQuery);

      return;
    }

    if (Array.isArray(value) && value.length === 1) {
      const matchQuery = `(${key}=${isRegex ? `/.*${value[0]}.*/` : `"${value[0]}"`})`;
      filters.push(matchQuery);

      return;
    }

    if (typeof value === 'boolean') {
      const matchQuery = `(${key}: ${value})`;
      filters.push(matchQuery);
      return;
    }

    const matchQuery = `(${key}: ${isRegex ? `/.*${value}.*/` : `${value}`})`;

    filters.push(matchQuery);
  });

  return filters.length ? `${customRegex}.${filters.join(' AND ')}.${customRegex}` : '';
};
