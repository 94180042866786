import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export enum Role {
  Normal = 'NORMAL',
  Sysdba = 'SYSDBA',
  Sysoper = 'SYSOPER'
}

export type OracleFormData = {
  username: string;
  password: string;
  host: string;
  port: string;
  database: string;
  role: Role;
};

export function OracleForm({ form }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'Username',
            name: 'username',
            required: true
          }}
          InputComponent={Input}
        />
        <Select
          label="Role"
          name="role"
          control={control}
          options={[
            { value: 'NORMAL', label: 'Normal' },
            { value: 'SYSDBA', label: 'SYSDBA' },
            { value: 'SYSOPER', label: 'SYSOPER' }
          ]}
        />
      </Box>

      <InputWithSecrets
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        TextFieldProps={{
          type: 'password',
          label: 'Password',
          name: 'password'
        }}
        InputComponent={Input}
      />

      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'Host',
            name: 'host'
          }}
          InputComponent={Input}
        />

        <Input
          label="Port"
          name="port"
          inputRef={register}
          type="number"
          inputProps={{
            min: 1,
            max: 65535
          }}
          errors={errors}
        />
      </Box>

      <InputWithSecrets
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        TextFieldProps={{
          type: 'text',
          label: 'Database',
          name: 'database'
        }}
        InputComponent={Input}
      />
    </Box>
  );
}
