import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../../actions';
import reducer from '../../../reducer/products/services/fetchSuccessful';

function* prepareFetchEntities(axios, action) {
  try {
    const {
      autoComplete: { searchText }
    } = yield select(state => state.product_services_view);
    const {
      data: { data: payload }
    } = yield axios.get(`/services?q=${searchText}`);
    yield put({
      type: actions.PRODUCT_SERVICES_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL,
      meta: {
        reducer
      },
      payload
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchFetchEntity(axios) {
  yield takeLatest(actions.PRODUCT_SERVICES_AUTOCOMPLETE_CHANGE, prepareFetchEntities, axios);
}
