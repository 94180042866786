import { useHasPermission } from './permissions/hasPermission';
import useUser from './queriesRest/useUser';

export function usePermission(target: string) {
  const { data: user } = useUser();

  const hasPermission = useHasPermission({
    roleId: user?.logged?.roleId,
    userId: user?.id,
    target
  });

  return hasPermission;
}
