import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/applications';

function* prepareFetchEntities(axios, action) {
  try {
    const applicationId = action.params.applicationId;
    const productId = action.params.productId;
    const {
      data: { data: product }
    } = yield axios.get(`/products/${productId}`);
    const {
      data: { data: values }
    } = yield axios.get(`/products/${productId}/applications/${applicationId}`);
    const {
      data: { data }
    } = yield axios.get('/environments');
    yield put({
      type: actions.PRODUCT_APPLICATIONS_EDIT_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        environments: { data },
        product,
        product_applications_edit: { values }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationEditFetch(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_EDIT_FETCH, prepareFetchEntities, axios);
}
