import { useState } from 'react';

import agentStatus from 'constants/agentStatus';
import cloudProviders from 'constants/cloudProviders';

import { Divider, Typography, makeStyles, Box } from '@material-ui/core';
import { useMutation } from 'urql';

import TabComponent from 'componentsV3/Tab';
import TabAgent from 'componentsV3/TabAgent';
import TabCloud from 'componentsV3/TabCloud';

import TabPanel from '../components/TabPanel';
import { useArgsApplication } from '../hooks/useArgsApplication';
import TabEnvironment from '../View/Environment';
import TabProduct from '../View/Product';

const useStyles = makeStyles(({ spacing }) => ({
  divider: {
    margin: spacing(4, 0)
  },
  tabs: {
    marginTop: spacing(4)
  }
}));

type Cloud = { id: number; provider: keyof typeof cloudProviders };

const InstallAgentMutation = `#graphql
  mutation(
    $environmentId: Float!
  ) {
    installAgent(
      environmentId: $environmentId
    ) 
  }
`;

type InstallAgentMutationResult = {
  installAgent: null;
};

export type Environment = { id: number; agentToken: string; status: keyof typeof agentStatus };

function WithAddons({
  applicationType,
  renderApplicationTab
}: {
  applicationType: any;
  renderApplicationTab: Function;
}) {
  const {
    environment,
    productId,
    currentAgentStatus,
    firstAccess,
    setEnvironment,
    setProductId
  } = useArgsApplication();

  const classes = useStyles();
  const [cloud, setCloud] = useState<Cloud | null>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [, installAgent] = useMutation<InstallAgentMutationResult, { environmentId: number }>(
    InstallAgentMutation
  );

  return (
    <>
      <Typography variant="h5">{applicationType.title}</Typography>
      <Typography variant="body1">{applicationType.description}</Typography>

      <Divider className={classes.divider} />

      <TabComponent
        tabsList={[
          { label: 'Cloud', slug: 'cloud' },
          { label: 'Environment', slug: 'environment' },
          {
            label: 'Install Agent',
            slug: 'agent',
            agent: {
              status: currentAgentStatus
            }
          },
          { label: 'Product', slug: 'product' },
          { label: 'Application', slug: 'application' }
        ]}
        config={{ navigation: process.env.REACT_APP_ENVIRONMENT !== 'production' }}
        tabIndex={tabIndex}
        onChange={(index: number) => setTabIndex(index)}
      />

      <Box marginTop={4}>
        <TabPanel value={tabIndex} index={0}>
          <TabCloud
            onFinish={(cloud: Cloud) => {
              setCloud(cloud);
              setTabIndex(1);
            }}
            applicationType={applicationType.type}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <TabEnvironment
            cloudId={cloud?.id}
            onFinish={(environment: Environment) => {
              setEnvironment(environment);

              const agentIsNotPending =
                environment.status === agentStatus.Running ||
                environment.status === agentStatus.Installing;

              if (cloud?.provider === cloudProviders.custom && !agentIsNotPending) {
                return setTabIndex(2);
              }

              if (cloud?.provider === cloudProviders.custom && agentIsNotPending) {
                return setTabIndex(3);
              }

              installAgent({ environmentId: environment.id });
              return setTabIndex(3);
            }}
            applicationType={applicationType.type}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <TabAgent
            environment={environment}
            onFinish={() => {
              setTabIndex(3);
            }}
            applicationType={applicationType.type}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <TabProduct
            firstAccess={firstAccess}
            onFinish={(productId: number) => {
              setProductId(productId);
              setTabIndex(4);
            }}
            applicationType={applicationType.type}
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={4}>
          {renderApplicationTab({
            environmentId: environment?.id,
            productId,
            firstAccess,
            applicationType: applicationType.type
          })}
        </TabPanel>
      </Box>
    </>
  );
}

export default WithAddons;
