import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles<Theme>(theme => ({
  root: {
    height: 100,
    borderRadius: 8,
    padding: theme.spacing(2, 2)
  },
  title: {
    fontSize: 14,
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}));
