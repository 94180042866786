import { ChangeEvent } from 'react';

import locations from 'constants/locations';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Icon,
  Link,
  Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab-old/Alert';
import { Controller } from 'react-hook-form';

import { useStyles } from 'views/Monitoring/View/styles';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type FunctionFormData = {
  function: string;
  notInvoke: boolean;
  credentials: string;
  locations: typeof locations;
};

export function FunctionForm({ form }: FormProps) {
  const classes = useStyles();
  const { register, control, setValue } = form;

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const reader = new FileReader();
    reader.onload = () => {
      const json = JSON.stringify(JSON.parse(String(reader.result))).trim();
      setValue('credentials', json);
    };
    reader.readAsText(file!);
  };

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <Input label="Function" name="function" inputRef={register} />
        <Select options={locations} control={control} name="locations" label="GCP Locations" />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input
          label="Credentials (JSON)"
          name="credentials"
          inputRef={register}
          multiline
          maxRows={10}
          minRows={5}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" title="Upload a .json file" component="label">
          Upload a JSON
          <input
            accept="application/json"
            id="json-file"
            name="fileInput"
            style={{ display: 'none' }}
            type="file"
            onChange={onUploadFile}
          />
        </Button>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Controller
          control={control}
          name="notInvoke"
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="Check not invoke on interval"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                        required={false}
                      />
                    }
                  />
                  <Tooltip title="If checked, we're going to generate a failure when there isn't any invocations in the configured interval">
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
      </Box>

      <Box gridGap="1rem">
        <Alert severity="warning">
          We use GCP Logging for searching by Google Cloud logs
          <Link href="https://cloud.google.com/logging#section-7" target="_blank">
            Click here to read more about it's pricing.
          </Link>
        </Alert>
      </Box>
    </Box>
  );
}
