import Status from './Status';

export function validateApplicationStatusUniqueResource(monitoringStatus) {
  if (!monitoringStatus || monitoringStatus === 'NotMonitoring' || monitoringStatus === 'off') {
    return { status: Status['MONITORING-IS-INACTIVE'] };
  }

  if (monitoringStatus === 'Up' || monitoringStatus === 'available') {
    return { status: Status['AVAILABLE'] };
  }

  if (monitoringStatus === 'Down' || monitoringStatus === 'unavailable') {
    return { status: Status['UNAVAILABLE'] };
  }

  if (monitoringStatus === 'Stale' || monitoringStatus === 'pending') {
    return { status: Status['INITIAL'] };
  }
}

function validateApplicationStatus(app) {
  const response = { status: Status['MONITORING-IS-INACTIVE'], sla: 0 };
  if (app.status) {
    const status = Array.isArray(app.status) ? app.status[0] : app.status;
    response.sla = status.online_1_hour;
    response.statusService = status.status;

    if (response?.statusService === 'Maintenance') {
      response.status = Status['MAINTENANCE'];
      return response;
    }

    if (status?.online_1_hour >= 0 && status?.online_1_hour < 98) {
      response.status = Status['UNAVAILABLE'];
    } else if (status?.online_1_hour < 99.5 && status?.online_1_hour >= 98) {
      response.status = Status['DEGRADED'];
    } else if (status?.online_1_hour >= 99.5) {
      response.status = Status['AVAILABLE'];
    }
  }

  return response;
}

export default validateApplicationStatus;
