import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { AlertCenter } from 'views/AlertCenter/index';

export const alertsRoutes = [
  <AuthorizedRoute
    component={AlertCenter}
    exact
    layout={MainLayoutV5}
    path="/alert-center"
    planFeature="IncidentManagement"
  />
];
