import { makeStyles } from '@material-ui/core';

export const useStylesV2 = makeStyles(theme => ({
  popper: {
    marginTop: theme.spacing(),
    zIndex: theme.zIndex.appBar,
    borderRadius: 8,
    minWidth: 930
  },
  content: {
    padding: theme.spacing(3)
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(0.5)
  },
  divider: {
    backgroundColor: theme.palette.grey[100]
  },
  dialogTitle: {
    alignItems: 'center'
  },
  closeButton: {
    color: theme.palette.grey[500]
  },
  criticalEventLink: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    color: 'inherit',

    '&:hover': {
      textDecoration: 'none'
    },

    '&:hover .criticalEventTitle': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  }
}));
