import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    paddingTop: theme.spacing(8),
    justifyContent: 'center'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '30.375rem',
    height: '100%'
  },
  body: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2)
  },
  input: {
    height: theme.typography.pxToRem(48)
  },
  button: {
    height: theme.typography.pxToRem(52),
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-12px',
    color: theme.palette.grey[500]
  },
  linkPolicy: {
    marginLeft: theme.spacing(0.4),
    fontWeight: theme.typography.fontWeightBold
  },
  policyText: {
    color: theme.palette.grey[800]
  },
  dialogPaper: {
    minHeight: '500px'
  }
}));
