import { Product } from 'constants/Product';

const ProductsHomeSubscriptionOptimized = `
  subscription {
    productsHomeSubscriptionOptimized(page: 1, per_page: 1000, sort: "name,ASC") {
      data {
        id
        name
        description
        cover
        applicationsDown {
          id
          name
        }
        addonsDown {
          id
          name
          applicationId
        }
        teams {
          id
        }
        monitoredApplications
        totalApplications
        lastEvent {
          id
          type
          applicationId
          incidentId
          addonId
          createdAt
          applicationName
        }
        resourcesMonitoringAndHealthStatus {
          name
          sterasId
          rootApplicationId
          monitoringStatus
          healthStatus
        }
      }
      isLoadingAdditionalData
    }
  }
`;

export type ProductsHomeSubscriptionOptimizedType = {
  productsHomeSubscriptionOptimized: {
    data: Product[];
    isLoadingAdditionalData: boolean;
  };
};

export default ProductsHomeSubscriptionOptimized;
