import { PropsWithChildren } from 'react';

import { Box, Typography } from '@material-ui/core';

import useStyles from './styles';
import { MetabaseDashboardProps } from './types';

type MetabaseDashboardContainerProps = Pick<
  MetabaseDashboardProps,
  'title' | 'subtitle' | 'header' | 'showSubtitle'
>;

function MetabaseDashboardContainer({
  title,
  subtitle,
  header = true,
  showSubtitle = true,
  children
}: PropsWithChildren<MetabaseDashboardContainerProps>) {
  const classes = useStyles();

  return (
    <>
      {header && (
        <Box>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          {showSubtitle && (
            <Typography className={classes.body} variant="body1" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}
      {children}
    </>
  );
}

export default MetabaseDashboardContainer;
