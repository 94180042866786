import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    alignItems: 'baseline'
  },
  textTitle: {
    whiteSpace: 'nowrap'
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  textMainName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '600px'
  },
  icons: {
    marginLeft: theme.spacing(2),
    minWidth: 56,
    height: 42,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[100]
    }
  },
  topBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #C2C2C2',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(5),
    padding: theme.spacing(2)
  },
  paperTop: {
    padding: theme.spacing(2),
    border: '1px solid #C2C2C2',
    height: '149px'
  },
  paperMiddle: {
    padding: theme.spacing(2),
    border: '1px solid #C2C2C2',
    height: '160px'
  },
  paperBottom: {
    padding: theme.spacing(2),
    boxShadow: 'none',
    border: '1px solid #D5D5D5',
    borderRadius: '5px'
  },
  paperTitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  paperTextMiddle: {
    marginTop: theme.spacing(3),
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular
  },
  paperTextBottom: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    color: '#898989',
    fontWeight: theme.typography.fontWeightRegular
  },
  descriptionIcon: {
    fontSize: 18,
    color: '#C2C2C2',
    marginLeft: theme.spacing(0.5)
  },
  topBoxText: {
    marginLeft: theme.spacing(10),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(4)
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  },
  paperProps: {
    width: '290px'
  },
  menuItem: {
    '& a': {
      color: 'inherit',
      display: 'block',
      width: '100%'
    },
    '& a:hover': {
      textDecoration: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.green[50],
      color: theme.palette.green[400]
    }
  },
  openingHoursMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#3CB371',
    borderRadius: 20,
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(2)
  },
  outMarginPoint: {
    width: 10,
    height: 10,
    backgroundColor: '#F57D27',
    borderRadius: 20,
    marginLeft: theme.spacing(-1),
    marginBottom: theme.spacing(2)
  },
  deleteDialogText: {
    fontSize: theme.typography.pxToRem(16)
  },
  tooltip: {
    backgroundColor: theme.palette.gray[700],
    marginBottom: theme.spacing(3),
    top: theme.spacing(-1)
  },
  tooltipArrow: {
    color: theme.palette.gray[700]
  },
  noDataText: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '32px',
    color: theme.palette.gray[700]
  },
  upgradePlanText: {
    marginTop: theme.spacing(3)
  },
  spacingButton: {
    marginTop: theme.spacing(1),
    padding: 0
  }
}));
