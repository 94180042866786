import { useTheme, Box, Typography, Tooltip } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { useStyles } from './styles';

type MetricsTitleProps = {
  title: string;
  tooltipTitle?: string;
};

export function MetricsTitle({ title, tooltipTitle }: MetricsTitleProps) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing()}>
      <Typography variant="subtitle2">{title}</Typography>
      {tooltipTitle && (
        <Tooltip title={tooltipTitle}>
          <Help className={classes.tooltipIcon} />
        </Tooltip>
      )}
    </Box>
  );
}
