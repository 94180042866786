export const getIncident = `#graphql
  query( $incidentId: Float!) {
    getIncidentWithOriginsAndTeams(
      incidentId: $incidentId
    ) {
      id
      name
      cause
      started_at
      type
      severity
      status
      linked_origins {
        id
        incident_id
        origin_entity
        origin_uid
      }
      linked_teams {
        id
        incident_id
        team_id
      }
      war_room_provider
      war_room_integration_id
    }
  }`;

export const getIncidentV2 = `#graphql
  query( $incidentId: Float!) {
    getIncidentV2(
      incidentId: $incidentId
    ) {
        id
      title
      description
      type
      severity
      status
      failureHappenedAt
      origins {
        id
        originEntity
        originUid
      }
      alerts {
        id
        alertUid
      }
      teams {
        id
        teamId
      }
      responders {
        type
        uid
      }
      warRoomProvider
      warRoomIntegrationId
    }
  }`;
