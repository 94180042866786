import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  useTheme
} from '@material-ui/core';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import { ButtonSimple } from 'componentsV4/Button';
import axios from 'redux/axios';

import Form from '../components/Form';
import mapFormDataToApi from '../components/Form/mapFormDataToApi';

function ServicesNewForm() {
  const router = useHistory();
  const theme = useTheme();

  const [submitMutation] = useMutation(
    async service => {
      const data = mapFormDataToApi(service);

      return await axios.post('/services', data);
    },
    {
      onSuccess: () => {
        router.push('/services');
      }
    }
  );

  return (
    <Box maxWidth={theme.breakpoints.width('lg')} clone>
      <Card>
        <CardHeader title="New Service" />
        <Divider />
        <CardContent>
          <Form submitMutation={submitMutation} />
        </CardContent>
        <Divider />
        <CardActions>
          <ButtonSimple
            title="Save"
            color="primary"
            type="submit"
            variant="contained"
            form="serviceForm"
            text="Save"
          />
        </CardActions>
      </Card>
    </Box>
  );
}

export default ServicesNewForm;
