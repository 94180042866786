import { UseDependenciesParams } from 'types/external/Dependency';
import { UseDependenciesData } from 'types/external/Dependency';
import { useQuery } from 'urql';

const GetDependenciesQuery = `#graphql
    query(
        $applicationId: Int!,
        $from: Int!,
        $size: Int!,
        $query: String!
    ) {
    dependenciesV2(
      applicationId: $applicationId
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        entityName
        uid
        entityUid
        entity
        main {
          serviceId
          status
          lastCheck
          mtts {
            mtta
            mtbf
            mttr
          }
          uptime {
            online1Hour
          }
        }
      }
      total
    }
  }
`;

export function useDependencies({
  applicationId,
  page,
  perPage: size,
  query
}: UseDependenciesParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ dependenciesV2: UseDependenciesData }>({
    query: GetDependenciesQuery,
    variables: {
      applicationId,
      from,
      size,
      query
    }
  });
}
