import React from 'react';

import { useQuery } from 'urql';

import SelectSimple from 'componentsV3/Select';

const GetResourceGroupQuery = `#graphql
  query(
    $cloudId: Float!
  ) {
    resourceGroup(
      page: 1
      per_page: 1000
      sort: "name,ASC"
      cloudId: $cloudId
    ) {
      data {
        name
      }
    }
  }
`;

const useResourceGroupQuery = ({ cloudId }) => {
  const [result] = useQuery({
    query: GetResourceGroupQuery,
    pause: !cloudId,
    variables: {
      cloudId
    }
  });

  return [result];
};

const ResourceGroupSelect = ({ errors, control, selectedCloudId, required, disabled }) => {
  const [result] = useResourceGroupQuery({
    cloudId: selectedCloudId
  });

  const { data, fetching } = result;

  const resourceGroups = React.useMemo(() => {
    if (fetching)
      return [
        {
          label: 'Loading...',
          value: ''
        }
      ];

    if (!data) return [];

    return data.resourceGroup.data.map(({ name }) => ({
      value: name,
      label: name
    }));
  }, [data, fetching]);

  return (
    <SelectSimple
      disabled={disabled}
      name="resourceGroup"
      label="Resource Group"
      defaultValue=""
      items={resourceGroups}
      errors={errors}
      control={control}
      required={required}
    />
  );
};

export default ResourceGroupSelect;
