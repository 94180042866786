import { formatDistanceStrict } from 'date-fns';
import { TableColumn } from 'react-data-table-component';

import AddonMetricsButton from '../../components/AddonMetricsButton';
import MonitoringStatusV1 from '../../components/MonitoringStatus/MonitoringStatusV1';
import { MTBFTitle, MTTATitle, MTTRecoveryTitle, UptimeTitle } from '../../components/Titles';

import { AddonMetrics, AddonUptimeLine } from './columnsAddonsComponents';
import { AddonHitsAndFailuresAPI } from './columnsAddonsComponents';
import { GetMtta } from './columnsAddonsComponents';
import { GetMttrecovery } from './columnsAddonsComponents';
import { GetMtbf } from './columnsAddonsComponents';
import { AppStatus } from './columnsAddonsComponents';
import { NewDataRow } from './DataRow';
import DeleteAddonButton from './DeleteAddon/DeleteAddonButton';
import EditAddonButton from './EditAddon/EditAddonButton';

const ColumnsAddons = (permissions: {
  editAddon: boolean;
  deleteAddon: boolean;
  viewDeploy: boolean;
}): TableColumn<NewDataRow>[] => [
  {
    name: '',
    width: '100px',
    cell: (row: NewDataRow) => {
      if (row.monitoringStatus === 'Maintenance') {
        return <MonitoringStatusV1 monitoringStatus={row.monitoringStatus} iconOnly />;
      }

      return <AppStatus row={row} />;
    }
  },
  {
    name: 'Name',
    selector: row => row.name || ''
  },
  {
    name: 'Last Check',
    selector: row => {
      const previousDayDate = new Date();
      previousDayDate.setDate(previousDayDate.getDate() - 1);

      if (
        row?.informations &&
        new Date(row.informations.lastCheck || '').getTime() > previousDayDate.getTime()
      ) {
        return formatDistanceStrict(new Date(row.informations.lastCheck || ''), new Date());
      } else {
        return 'No data';
      }
    }
  },
  {
    name: <MTBFTitle />,
    cell: (row: NewDataRow) => <GetMtbf row={row} />
  },
  {
    name: <MTTATitle />,
    cell: (row: NewDataRow) => <GetMtta row={row} />
  },
  {
    name: <MTTRecoveryTitle />,
    cell: (row: NewDataRow) => <GetMttrecovery row={row} />
  },
  {
    name: <UptimeTitle />,
    cell: (row: NewDataRow) => <AddonUptimeLine row={row} />
  },
  {
    name: '',
    cell: (row: NewDataRow) => {
      return (
        <>
          {row?.informations && row.applicationParent && (
            <>
              {!row?.multiFlow && (
                <AddonMetrics row={row} latenciesGraphData={row?.latenciesGraphData} />
              )}

              {row.webhook && (
                <AddonHitsAndFailuresAPI
                  sterasId={row.sterasId}
                  orgUid={row.orgUid}
                  disabled={!permissions.viewDeploy}
                />
              )}
            </>
          )}

          <AddonMetricsButton sterasId={row.sterasId} serviceName={row.name} />

          {!row.multiFlow && (
            <>
              <EditAddonButton
                addonId={row.id}
                productId={row.applicationParent?.productId}
                disabled={!permissions.editAddon}
              />
              <DeleteAddonButton
                addonId={row?.id}
                productId={row.applicationParent?.productId}
                name={row?.name}
                applicationId={row.applicationId}
                disabled={!permissions.deleteAddon}
              />
            </>
          )}
        </>
      );
    }
  }
];

export default ColumnsAddons;
