import { useMutation } from 'urql';

const CloseTicketMutation = `#graphql
  mutation($data: CloseTicketInput!) {
    closeTicket(data: $data) {
      message
      success
    }
  }`;

type CloseTicketResponse = {
  closeTicket: { message: string; success: boolean };
};

type CloseTicketVariables = {
  data: {
    incidentId: number;
    integrationUid: string;
  };
};

export const useCloseTicket = () => {
  const [{ data, fetching }, closeTicket] = useMutation<CloseTicketResponse, CloseTicketVariables>(
    CloseTicketMutation
  );

  const success = data?.closeTicket.success;

  return { closeTicket, fetching, success };
};
