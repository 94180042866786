import { jiraLanguageOptions } from 'constants/jiraLanguages';

import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Select } from 'views/ServicesHub/components/Select';
import { ServiceNowData } from 'views/ServicesHub/layouts/ItsmServiceNow';

import { Input } from '../../components/Input';

export function JiraForm({ form }: { form: UseFormMethods<ServiceNowData> }) {
  const { register, control, setValue, watch, getValues, errors } = form;

  const showItsmOpenAutomatic = useFeatureFlagUnleash('showItsmOpenAutomatic', {
    queryString: true
  });

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <input type="hidden" name="uid" ref={register} />

      <Input label="Integration name" name="name" inputRef={register} />

      <Select
        options={jiraLanguageOptions}
        control={control}
        name="defaultLanguage"
        label="Default Jira Language"
        required={true}
      />

      <Input label="Subdomain" name="subdomain" inputRef={register} />

      <Input label="Project Key" name="projectKey" inputRef={register} />

      <FormControlLabel
        style={!showItsmOpenAutomatic ? { display: 'none' } : undefined}
        control={
          <Controller
            name="openAutomatic"
            control={control}
            defaultValue={false}
            render={({ value, onChange }) => (
              <Checkbox checked={Boolean(value)} onChange={(_, val) => onChange(val)} />
            )}
          />
        }
        label="Open and update tickets automatically"
      />

      <Box
        display="flex"
        style={{ backgroundColor: '#DBE8FF', padding: '1rem' }}
        alignItems="center">
        <InfoOutlined style={{ color: '#2979FF' }} />
        <Typography variant="body2" style={{ marginLeft: 10 }}>
          The following fields are required if you choose to authenticate via username and api token
          instead of 0Auth.
        </Typography>
      </Box>

      <Input label="Username" name="username" inputRef={register} required={true} />

      <InputWithSecrets
        TextFieldProps={{
          label: 'Api Token',
          name: 'apiToken',
          type: 'password'
        }}
        required={true}
        control={control}
        setValue={setValue}
        watch={watch}
        getValues={getValues}
        errors={errors}
      />
    </Box>
  );
}
