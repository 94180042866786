import React from 'react';

import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab-old/Alert';

import withTheme from 'theme/withTheme';

const GlobalMessage = ({ globalErrors, globalSuccess, reset, globalWarning }) => {
  const open = !!globalErrors.length || !!globalSuccess.length || !!globalWarning;

  const actionButton = (
    <IconButton aria-label="close" color="inherit" onClick={reset} size="small">
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  return (
    <>
      {globalSuccess && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={6000}
          onClose={reset}>
          <Alert elevation={2} variant="filled" action={actionButton} severity="success">
            {globalSuccess}
          </Alert>
        </Snackbar>
      )}
      {globalErrors && (
        <>
          {globalErrors.map((err, index) => (
            <Snackbar
              key={`error_${index}`}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={open}
              autoHideDuration={6000}
              onClose={reset}>
              <Alert elevation={2} variant="filled" action={actionButton} severity="error">
                {err.message}
              </Alert>
            </Snackbar>
          ))}
        </>
      )}
      {globalWarning && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={open}
          autoHideDuration={6000}
          onClose={reset}>
          <Alert elevation={2} variant="filled" action={actionButton} severity="warning">
            {globalWarning}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default withTheme(GlobalMessage);
