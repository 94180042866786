import { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Typography } from '@material-ui/core';
import { ButtonDefault } from 'oldComponents/Button';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Input from 'componentsV4/Input';
import useIncidentStatusUpdate, {
  IncidentStatusUpdate
} from 'hooks/queriesGraphQL/useIncidentStatusUpdate';
import actions from 'redux/actions';

import schema from './schema';
import { useStyles } from './styles';

type IncidentStatusUpdateFormProps = {
  onCreate: () => void;
};

function IncidentStatusUpdateForm({ onCreate }: IncidentStatusUpdateFormProps) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { incidentId } = useParams<{ incidentId: string }>();

  const { createIncidentStatusUpdate } = useIncidentStatusUpdate();

  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
    createIncidentStatusUpdateMutation
  ] = createIncidentStatusUpdate;

  const { register, handleSubmit, reset, errors, clearErrors } = useForm({
    resolver: zodResolver(schema),
    mode: 'onSubmit'
  });

  async function onSubmit(data: Pick<IncidentStatusUpdate, 'description'>) {
    setIsLoading(true);
    const result = await createIncidentStatusUpdateMutation({
      data: {
        ...data,
        incidentId: Number(incidentId),
        private: true
      }
    });

    setIsLoading(false);
    if (result.error) {
      return dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Something went wrong :(. Please try again.' },
        ga: { category: 'ERROR' }
      });
    }

    onCreate();

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Incident status updated successfully'
    });

    reset();
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.container} container item xs={12} spacing={1} alignItems="flex-end">
        <Grid item xs={10}>
          <Input
            errors={errors}
            inputRef={register}
            inputProps={{
              maxLength: 281
            }}
            onBlur={() => clearErrors('description')}
            className={classes.input}
            defaultLabel="Write how you’re proceeding"
            name="description"
          />
        </Grid>
        <Grid item xs={2}>
          <ButtonDefault
            className={classes.button}
            children="Send update"
            size="medium"
            variant="contained"
            type="submit"
            disabled={isLoading}
          />
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="h6" className={classes.text}>
            Only your organization can see this update
          </Typography>
        </Grid>
        {isLoading && <LoadingOverlay />}
      </Grid>
    </form>
  );
}

export default IncidentStatusUpdateForm;
