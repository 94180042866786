import React from 'react';

import { makeStyles, IconButton, useTheme, Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import InputDefault from 'componentsV3/InputDefault/index';
import InputWithSecrets from 'componentsV3/InputWithSecrets/index';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles((theme, props) => {
  return {
    icon: {
      marginTop: 19,
      marginRight: -10
    },
    input: {
      marginBottom: theme.spacing(1)
    }
  };
});

const InputKeyValue = ({
  propsKey,
  propsValue,
  disabled,
  item,
  index,
  fieldAppend,
  fieldRemove,
  register,
  name,
  fieldsListLength,
  control,
  setValue,
  errors,
  watch
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid className={classes.input} wrap="nowrap" container display="flex" alignItems="flex-start">
      <Grid spacing={3} container xs={11}>
        <Grid item xs={6}>
          <InputDefault
            name={`${name}.key`}
            label={propsKey.label}
            placeholder={propsValue.placeholder}
            defaultValue={item ? item.key : ''}
            inputRef={register()}
            {...propsKey}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithSecrets
            iconColor={theme.palette.primary.main}
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            TextFieldProps={{
              name: `${name}.value`,
              label: propsValue.label,
              placeholder: propsValue.placeholder
            }}
            InputComponent={InputDefault}
          />
        </Grid>
      </Grid>

      {fieldsListLength > 1 && (
        <IconButton
          className={classes.icon}
          color="primary"
          onClick={() => {
            fieldRemove(index);
          }}
          disabled={disabled}>
          <DeleteIcon />
        </IconButton>
      )}
      {index === fieldsListLength - 1 && (
        <IconButton
          color="primary"
          onClick={() => fieldAppend({ key: '', value: '' })}
          className={classes.icon}
          disabled={disabled}>
          <AddCircle />
        </IconButton>
      )}
    </Grid>
  );
};

export default withThemeV3Hoc(InputKeyValue);
