import { useFlagsStatus } from '@unleash/proxy-client-react';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ProductListV3 from '../../Home/List/V3';

import { ProductsList as ProductsListV4 } from './NewProducts';

const Products = () => {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  return newApplications ? <ProductsListV4 /> : <ProductListV3 />;
};

export default Products;
