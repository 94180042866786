import { useQuery } from 'urql';

const ProductsQuery = `#graphql
query {
  products(page: 1, per_page: 1000, sort: "name,ASC"){
    data {
      id
      name
    }
    total
  }
}
`;

export const useProducts = () =>
  useQuery<{ products: { data: { id: number; name: string }[] } }>({
    query: ProductsQuery
  });
