import { UseFullDependenciesParams } from 'types/external/Dependency';
import { UseDependenciesData } from 'types/external/Dependency';
import { useQuery } from 'urql';

const GetFullDependenciesQuery = `#graphql
    query(
      $dependencies: [FullDependenciesInput!]!,
    ) {
    fullDependencies(
      dependencies: $dependencies
    ) {
      entityName
      uid
      entityUid
      entity
      main {
        serviceId
        status
        lastCheck
        mtts {
          mtta
          mtbf
          mttr
        }
        uptime {
          online1Hour
        }
      }
    }
  }
`;

export function useFullDependencies({
  fullDependencies
}: {
  fullDependencies: UseFullDependenciesParams[];
}) {
  const dependencies = fullDependencies
    ? fullDependencies.map((dependency: any) => {
        delete dependency.main.__typename;
        delete dependency.__typename;
        return dependency;
      })
    : [];

  return useQuery<{ fullDependencies: UseDependenciesData }>({
    query: GetFullDependenciesQuery,
    pause: !dependencies.length,
    variables: {
      dependencies
    }
  });
}
