export const postBodyTypeValues = {
  raw: 'raw',
  urlencoded: 'application/x-www-form-urlencoded'
};

export const postBodyRawTypeValues = {
  json: 'application/json',
  text: 'text/plain',
  javaScript: 'application/javascript',
  xml: 'application/xml',
  html: 'text/html'
};

export enum PostBodyValues {
  json = 'application/json',
  text = 'text/plain',
  javaScript = 'application/javascript',
  xml = 'application/xml',
  html = 'text/html',
  urlencoded = 'application/x-www-form-urlencoded'
}

export enum PostBodyTypes {
  raw = 'raw',
  urlencoded = 'application/x-www-form-urlencoded'
}

export enum ComparisonTypes {
  Equals = 'equals',
  NotEqual = 'not_equal',
  IsEmpty = 'is_empty',
  IsNotEmpty = 'is_not_empty',
  Contains = 'contains',
  NotContains = 'not_contains',
  LessThan = 'less_than',
  LessThanOrEqual = 'less_than_or_equal',
  GreaterThan = 'greater_than',
  GreaterThanOrEqual = 'greater_than_or_equal',
  IsNumber = 'is_number',
  IsNull = 'is_null',
  IsTrue = 'is_true',
  IsFalse = 'is_false',
  EqualsNumber = 'equals_number',
  NotEqualNumber = 'not_equal_number'
}

export enum SourceTypes {
  StatusCode = 'status_code',
  TextBody = 'text_body',
  JSONBody = 'json_body'
}
