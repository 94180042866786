import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  select: {
    marginBottom: theme.spacing(2)
  },
  helpertext: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing()
  },
  container: {
    border: '2px solid #e2e2e2'
  },
  roleText: {
    fontWeight: 600
  },
  roleContainer: {
    borderTop: '2px solid #e2e2e2',
    borderRight: '2px solid #e2e2e2'
  }
}));
