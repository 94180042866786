export const formatGetServiceStatus = ({
  resourceType,
  applicationId,
  addonId,
  applicationDependencyId,
  sterasId
}: {
  resourceType: string;
  applicationId?: number;
  applicationDependencyId?: number | null;
  addonId?: number;
  sterasId?: number | null;
}) => {
  return {
    serviceId: sterasId,
    applicationId:
      resourceType === 'Application' || applicationDependencyId ? applicationId : undefined,
    addonId: resourceType !== 'Application' && !applicationDependencyId ? addonId : undefined
  };
};
