import React from 'react';

import Datagrid from './Datagrid';

const Apps = ({
  permissions,
  isLoadingApiTokens,
  isErrorApiTokens,
  apiTokens,
  rowsPerPage,
  setRowsPerPage,
  refetchTokens
}) => {
  return (
    <Datagrid
      permissions={permissions}
      isLoadingApiTokens={isLoadingApiTokens}
      isErrorApiTokens={isErrorApiTokens}
      apiTokens={apiTokens}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      refetchTokens={refetchTokens}
    />
  );
};

export default Apps;
