import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import slugify from 'slugify';
import * as z from 'zod';

const schema = z
  .object({
    name: z.string().min(1),
    slug: z.string().min(1),
    isPrivate: z.boolean().optional(),
    passphrase: z.string().optional(),
    subtitle: z.string().min(0),
    displayName: z.string().optional(),
    displayEnabled: z.boolean().optional(),
    enableSubscription: z.boolean().optional()
  })
  .refine(data => !(data?.isPrivate && data?.passphrase === ''), {
    message: 'Password is required when Status Page is private',
    path: ['passphrase']
  })
  .refine(data => !(data.isPrivate && data.passphrase.length < 6), {
    message: 'Password require minimun 6 characters',
    path: ['passphrase']
  });

const useForm1 = startValues => {
  const { register, handleSubmit, formState, watch, getValues, reset, errors, control } = useForm({
    defaultValues: {
      name: startValues?.name || '',
      slug: startValues?.slug || '',
      isPrivate: startValues?.isPrivate || false,
      passphrase: startValues?.passphrase || '',
      subtitle: startValues?.subtitle || '',
      displayName: startValues?.displayName || '',
      enableSubscription: startValues?.enableSubscription ?? false
    },
    resolver: zodResolver(schema)
  });

  const name = watch('name');
  const slug = watch('slug');
  const subtitle = watch('subtitle');
  const isPrivate = watch('isPrivate');
  const passphrase = watch('passphrase');

  const slugFromStartValues = startValues?.slug;

  React.useEffect(() => {
    if (slugFromStartValues) {
      return;
    }

    const newSlug = slugify(name).toLowerCase();
    if (!formState.dirtyFields.slug && slug !== newSlug) {
      reset({ ...getValues(), slug: newSlug });
    }
  }, [
    formState,
    name,
    slug,
    subtitle,
    passphrase,
    isPrivate,
    reset,
    getValues,
    slugFromStartValues
  ]);

  return {
    control,
    register,
    handleSubmit,
    errors,
    values: { slug },
    reset
  };
};

// FIXME: identifier "useForm" has already been declared or isn't helpful, rename this please!
export default useForm1;
