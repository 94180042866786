export const CreatePostMortem = `#graphql
  mutation(
    $incidentId: Int!,
    $postMortem: String!,
    $serviceId: Int,
    $title: String
  ) {
    createPostMortemV2(
      data: {
        incidentId: $incidentId
        postMortem: $postMortem
        serviceId: $serviceId
        title: $title
      }
    ) {
      id
      postMortem
      uid
    }
  }
`;

export type CreatePostMortemData = {
  createPostMortem: {
    id: number;
    postMortem: string;
  };
};

export type CreatePostMortemVariables = {
  incidentId: number;
  postMortem: string;
  serviceId?: number;
  title?: string;
};
