import { ChangeEvent, useEffect, useRef, useState, useMemo } from 'react';

import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
  useTheme,
  Badge
} from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { SeverityStatusEnum } from 'types/external/Severity';
import { SourceEnum } from 'types/external/Source';
import { StatusEnum } from 'types/external/Status';
import { useMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Title } from 'components/Title';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { EventsFilters } from 'componentsV4/Filters';
import { PageFilters as NewPageFilters } from 'componentsV4/Filters/Search';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { reduceString } from 'helpers/reduceString';
import useQueryParams from 'helpers/useQueryParams';
import { useIncidentState } from 'hooks/queriesGraphQL/useIncidentState';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import CreateManualIncidentButton from './components/CreateManualIncidentButton';
import { Filters, PageFilters } from './components/Filters';
import IncidentMenu from './components/Menu';
import { useStyles } from './styles';
import {
  transformPeriodQuery,
  transformSeverityQuery,
  transformSourceQuery,
  transformStatusQuery
} from './transformQuery';
import { useIncidents } from './useQuery/useIncidents';

const TYPING_DEBOUNCE_TIME = 500; // 0.5s

const DeleteIncidentMutation = `#graphql
  mutation ($incidentId: Float!){
    deleteIncident(incidentId: $incidentId)
  }
`;

interface FilterValues {
  source: string;
  period: string;
  status: string;
  severity: string;
}

export const days = ['1y', '1d', '7d', '30d'];

export function IncidentCenter() {
  const queryParams = useQueryParams();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();

  const shouldUseNewIncidentCenterFilters = useFeatureFlagUnleash('useNewIncidentCenterFilters', {
    queryString: true
  });
  const enableFilterWithQuery = useFeatureFlagUnleash('enableFilterWithQuery', {
    queryString: true
  });
  const enableFiltersWithIndicative = useFeatureFlagUnleash('enableFiltersWithIndicative', {
    queryString: true
  });

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<StatusEnum | 'all'>('all');
  const [severity, setSeverity] = useState<SeverityStatusEnum | 'all'>('all');
  const [source, setSource] = useState<SourceEnum | 'all'>('all');
  const [period, setPeriod] = useState<string | 'all'>('all');
  const [page, setPage] = useState(1);
  const [betweenValues, setBetweenValues] = useState('now-1y,now');
  const [origins, setOrigins] = useState<string[]>([]);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    source: 'all',
    status: 'all',
    severity: 'all',
    period: 'all'
  });
  const [badgeContent, setBadgeContent] = useState(0);

  const searchContainerRef = useRef<HTMLDivElement>(null);
  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const sourceURL = queryParams.get('source');
  const statusURL = queryParams.get('status');
  const severityURL = queryParams.get('severity');
  const periodURL = queryParams.get('period');
  const originsURL = queryParams.get('origins');

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    sourceURL && transformSourceQuery(sourceURL, setSource);
    statusURL && transformStatusQuery(statusURL, setStatus);
    severityURL && transformSeverityQuery(severityURL, setSeverity);
    originsURL && setOrigins(originsURL.split(','));

    if (periodURL?.length && shouldUseNewIncidentCenterFilters) {
      days.includes(periodURL)
        ? setBetweenValues(`now-${periodURL},now`)
        : setBetweenValues(periodURL ?? 'now-1y,now');

      return;
    }

    periodURL && transformPeriodQuery(periodURL, setPeriod);
    setFilterValues({
      period: period,
      severity: severity,
      status: status,
      source: source
    });
  }, [
    sourceURL,
    statusURL,
    severityURL,
    periodURL,
    period,
    severity,
    status,
    source,
    betweenValues,
    setBetweenValues,
    shouldUseNewIncidentCenterFilters,
    originsURL,
    setOrigins
  ]);

  const [incidentid] = useState<{
    incidentId: number | undefined;
  }>({ incidentId: 0 });

  const [, deleteIncidentMutation] = useMutation(DeleteIncidentMutation);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const deleteIncident = () => {
    deleteIncidentMutation({ incidentId: incidentid.incidentId }).then(() => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Incident deleted successfully'
      });

      setTimeout(() => reexecuteQuery(), 2000);
    });

    setOpenDeleteDialog(false);
  };

  const {
    data: { incidents: incidentsWithSeverity, total: totalIncidentsWithSeverity },
    isFetching: isFetchingIncidentsWithSeverity,
    reexecuteQuery: reexecuteIncidentsWithSeverityQuery
  } = useIncidents({
    page,
    period: period === 'all' ? 0 : Number(period),
    perPage: 10,
    query: search,
    status,
    type: source,
    severity,
    pause: shouldUseNewIncidentCenterFilters
  });

  const {
    data: { incidents: incidentCenterResult, total: incidentCenterTotal },
    fetching: isFetchingIncidents,
    reexecuteQuery: reexecuteIncidentsQuery
  } = useIncidentState({
    page,
    perPage: 10,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'title',
          value: search,
          isRegex: true
        },
        {
          key: 'status',
          value: status,
          isRegex: false
        },
        {
          key: 'severity',
          value: severity,
          isRegex: false
        },
        {
          key: 'origin.uid',
          value: origins,
          isRegex: false
        }
      ]
    }),
    betweenKey: 'failure_happened_at',
    betweenValues,
    pause: !shouldUseNewIncidentCenterFilters
  });

  const incidents = shouldUseNewIncidentCenterFilters
    ? incidentCenterResult
    : incidentsWithSeverity;

  const total = shouldUseNewIncidentCenterFilters
    ? incidentCenterTotal
    : totalIncidentsWithSeverity;

  const isFetching = shouldUseNewIncidentCenterFilters
    ? isFetchingIncidents
    : isFetchingIncidentsWithSeverity;

  const reexecuteQuery = shouldUseNewIncidentCenterFilters
    ? reexecuteIncidentsQuery
    : reexecuteIncidentsWithSeverityQuery;

  const newHandleApplyFilters = (filters: NewPageFilters) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    filters.severity === 'all'
      ? removeQueryParams('severity')
      : addQueryParams('severity', filters.severity);

    const originUids = filters?.origins?.map((origin: { value: string }) => origin.value);

    !filters.origins?.length
      ? removeQueryParams('origins')
      : addQueryParams('origins', originUids?.toString() || '');

    setStatus(filters.status as StatusEnum);
    setSeverity(filters.severity);
    setPage(1);

    if (filters?.origins) {
      setOrigins(filters.origins.map((origin: { value: string }) => origin.value));
    }

    handleToggleFilters();
    reexecuteQuery();
  };

  const handleApplyFilters = (filters: PageFilters) => {
    setSource(filters.source);
    setStatus(filters.status);
    setSeverity(filters.severity);
    setPeriod(filters.period);
    setPage(1);
    handleToggleFilters();
    reexecuteQuery();
  };

  const defaults = useMemo<FilterValues>(
    () => ({
      status: 'all',
      severity: 'all',
      source: 'all',
      period: 'all'
    }),
    []
  );

  useEffect(() => {
    const changedFields = Object.keys(filterValues).filter(
      key => filterValues[key as keyof FilterValues] !== defaults[key as keyof FilterValues]
    );

    const content = Math.min(changedFields.length, 4);
    setBadgeContent(content);
  }, [filterValues, defaults]);

  const handleApplyFiltersWithQuery = (filters: PageFilters) => {
    setFilterValues({
      period: filters.period,
      severity: filters.severity,
      status: filters.status,
      source: filters.source
    });
    addQueryParams('source', filters.source);
    addQueryParams('status', filters.status);
    addQueryParams('severity', filters.severity);
    addQueryParams('period', filters.period);
    setSource(filters.source);
    setStatus(filters.status);
    setSeverity(filters.severity);
    setPeriod(filters.period);
    setPage(1);
    handleToggleFilters();
  };

  const handleClearQueryFilters = () => {
    setFilterValues({
      period: 'all',
      severity: 'all',
      status: 'all',
      source: 'all'
    });
    removeQueryParams('source');
    removeQueryParams('status');
    removeQueryParams('severity');
    removeQueryParams('period');
    setSource('all');
    setStatus('all');
    setSeverity('all');
    setPeriod('all');
    reexecuteQuery();
  };

  const handleClearFilters = () => {
    setSource('all');
    setStatus('all');
    setSeverity('all');
    setPeriod('1');
    setOrigins([]);
    setPage(1);
    reexecuteQuery();
  };

  const newHandleClearFilters = () => {
    removeQueryParams('status');
    removeQueryParams('severity');
    removeQueryParams('origins');

    setStatus('all');
    setSeverity('all');
    setOrigins([]);

    setPage(1);
    reexecuteQuery();
  };

  const hasIncidentManualPermission = usePermission(
    'IncidentController-post-/incidents/incident_manual'
  );

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    reexecuteQuery();
  }, [page, reexecuteQuery]);

  const handleApplyPeriod = ({ period }: { period: string }) => {
    if (period === 'all') {
      removeQueryParams('period');
      setBetweenValues(`now-1y,now`);
      setPage(1);
      return;
    }

    addQueryParams('period', period);
    setBetweenValues(`now-${period},now`);
    setPage(1);
  };

  const handleApplyCustomRange = ({ betweenValue }: { betweenValue: string }) => {
    addQueryParams('period', betweenValue);
    setBetweenValues(betweenValue);
    setPage(1);
  };

  const handleClearCustomRange = () => {
    removeQueryParams('period');
    setBetweenValues('now-1y,now');
    setPage(1);
  };

  const appliedFilters = () => {
    return {
      status,
      severity,
      betweenValues: periodURL ? betweenValues : '',
      origins
    };
  };

  const incidentStatusOptions = {
    label: 'Incident Status',
    options: [
      { value: 'all', label: 'All' },
      { value: StatusEnum.Alarmed, label: 'Alarmed' },
      { value: StatusEnum.Resolved, label: 'Resolved' },
      { value: StatusEnum.Acknowledged, label: 'Acknowledged' }
    ]
  };

  return (
    <>
      <Breadcrumb
        items={[{ label: 'Incident Management' }, { label: 'Incident Center', link: '/incidents' }]}
      />

      <Title title="Incident Center">
        <CreateManualIncidentButton disabled={!hasIncidentManualPermission} />
      </Title>

      {!shouldUseNewIncidentCenterFilters ? (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={7.5}
          gridGap={theme.spacing(1.5)}
          {...{
            ref: searchContainerRef
          }}>
          <TextField
            variant="outlined"
            label="Search"
            onChange={handleChangeSearch}
            name="search"
            placeholder="Try to search by name"
            InputProps={{
              endAdornment: <Search />,
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
          />

          {enableFiltersWithIndicative ? (
            <Badge
              classes={{ badge: classes.badgeContent }}
              color="primary"
              badgeContent={badgeContent}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}>
              <Button
                onClick={handleToggleFilters}
                color="primary"
                variant="outlined"
                className={classNames(classes.button, {
                  [classes.backgroundButton]: badgeContent !== 0
                })}>
                <FilterList />
                <Typography color="primary" className={classes.textFilters}>
                  Filters
                </Typography>
              </Button>
            </Badge>
          ) : (
            <Button onClick={handleToggleFilters} color="primary" variant="outlined">
              <FilterList />
            </Button>
          )}
        </Box>
      ) : null}

      {!shouldUseNewIncidentCenterFilters && isFiltersOpen && (
        <Filters
          onApplyFilters={!enableFilterWithQuery ? handleApplyFilters : handleApplyFiltersWithQuery}
          anchorEl={searchContainerRef?.current}
          handleClose={handleCloseFilters}
          onClearFilters={!enableFilterWithQuery ? handleClearFilters : handleClearQueryFilters}
          defaultValues={{
            source,
            period,
            severity,
            status
          }}
        />
      )}

      {shouldUseNewIncidentCenterFilters ? (
        <EventsFilters
          handleChangeSearch={handleChangeSearch}
          onApplyFilters={newHandleApplyFilters}
          onApplyPeriod={handleApplyPeriod}
          onApplyCustomRange={handleApplyCustomRange}
          appliedFilters={appliedFilters}
          onClearFilters={newHandleClearFilters}
          onClearCustomRange={handleClearCustomRange}
          statusOptions={incidentStatusOptions}
          originsQuery={
            shouldUseNewApplications
              ? '(type: integration) OR (type: multiflow) OR (type: resource)'
              : '(type: integration) OR (type: multiflow)'
          }
        />
      ) : null}

      <Box>
        {isFetching ? (
          <LoadingOverlay isBlock />
        ) : incidents.length > 0 ? (
          <>
            <Divider className={classes.incidentDivider} />

            {incidents.map(incident => (
              <>
                <Box key={incident?.incidentId} mt={3}>
                  <Box display="flex" alignItems="center">
                    <StatusChip
                      type="Severity"
                      severityStatus={incident.severity}
                      isAlertStatus={false}
                    />
                    <StatusChip type="Event" status={incident.status} isAlertStatus={true} />
                    <Link
                      className={classes.incidentTitle}
                      component={RouterLink}
                      to={`/incidents/${incident?.incidentId}`}
                      variant="h6">
                      {incident.title}
                    </Link>

                    <IncidentMenu incident={incident} reexecuteQuery={reexecuteQuery} />
                  </Box>

                  <Box display="flex">
                    <Typography variant="body2" className={classes.incidentCauseTitle}>
                      Cause: &nbsp;
                    </Typography>
                    <Typography
                      variant="body2"
                      title={incident.description}
                      className={classes.incidentCause}>
                      {reduceString(incident.description, 899)}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Typography variant="body2" className={classes.incidentIdTitle}>
                      Incident ID: &nbsp;
                    </Typography>
                    <Link
                      className={classes.incidentIdLink}
                      component={RouterLink}
                      to={`/incidents/${incident.incidentId}`}
                      variant="body2">
                      #{incident?.incidentId}
                    </Link>

                    <span className={`${classes.ellipse} ${classes.firstChild}`}></span>

                    <Typography variant="body2" className={classes.incidentDateTitle}>
                      Started at: &nbsp;
                    </Typography>
                    <Typography variant="body2" className={classes.incidentDate}>
                      {format(parseISO(incident?.happenedAt), "dd/MM/yyyy HH:mm 'GMT-3'")} &nbsp;
                    </Typography>
                    {incident.status === StatusEnum.Resolved && (
                      <>
                        <span className={classes.ellipse}></span>

                        <Typography variant="body2" className={classes.incidentDateTitle}>
                          Ended at: &nbsp;
                        </Typography>
                        <Typography variant="body2" className={classes.incidentDate}>
                          {format(parseISO(incident?.updatedAt), "dd/MM/yyyy HH:mm 'GMT-3'")}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>

                <Divider className={classes.incidentDivider} />
              </>
            ))}

            <Box display="flex" justifyContent="center">
              <Pagination
                page={page}
                count={Math.ceil(total / 10)}
                size="large"
                color="primary"
                onChange={handleChangePage}
              />
            </Box>
          </>
        ) : (
          <Typography variant="h6" className={classes.textNoResult}>
            No results found
          </Typography>
        )}

        <DeleteEntity
          open={openDeleteDialog}
          entityName="Incident"
          handleClose={handleDeleteDialog}
          disabled={false}
          isLoading={false}
          handleRemove={deleteIncident}
        />
      </Box>
    </>
  );
}
