import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Theme } from 'theme/v4';

export default withStyles((theme: Theme) => ({
  root: {
    height: '44px',
    width: '100%',
    boxShadow: 'none',
    textTransform: 'none',
    border: (props: any) =>
      props.disabled === true || props.border === false ? theme.palette.grey[500] : undefined,
    borderWidth: '1px',
    borderStyle: 'solid',
    '&:hover': {
      boxShadow: 'none',
      borderColor: theme.palette.primary.dark
    }
  }
}))(Button);
