import { useMemo } from 'react';

import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Control } from 'react-hook-form';
import { useQuery, UseQueryState, OperationContext } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Select from 'componentsV3/Select';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';

const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  icon: {
    marginTop: spacing(1),
    marginLeft: spacing(1)
  }
}));

const TokenQuery = `#graphql
  query {
    appTokens(page: 1, per_page: 1000, sort: "id,DESC", tokenType: "hits/failures") {
      data {
        id
        name
      }
    }
  }
`;

export type Token = {
  id: number;
  name: string;
};

type Tokens = {
  appTokens: {
    data: Token[];
  };
};

export const useTokensQuery = (): [
  UseQueryState<Tokens, object>,
  (opts?: Partial<OperationContext> | undefined) => void
] => {
  const [result, reexecuteQuery] = useQuery<Tokens>({
    query: TokenQuery
  });
  return [result, reexecuteQuery];
};

const GeneratorToken = ({
  result,
  control,
  setShowTokensModal,
  firstAccess,
  setValue
}: {
  result: UseQueryState<Tokens, object>;
  control: Control;
  setShowTokensModal: () => void;
  firstAccess: Boolean;
  setValue: any;
}) => {
  const classes = useStyles();

  const { data, fetching } = result;

  const hasTokens = Boolean(result.data?.appTokens?.data?.length);

  const appTokens = useMemo(() => {
    return (
      data?.appTokens.data.map(token => ({
        label: token.name,
        value: String(token.id)
      })) || []
    );
  }, [data]);

  return (
    <>
      {fetching && <LoadingOverlay />}

      <Box style={{ display: fetching ? 'none' : undefined }}>
        {hasTokens && (
          <Grid container>
            <Grid item xs={10}>
              <Select
                style={{ display: !hasTokens ? 'none' : undefined }}
                name="token"
                items={appTokens}
                type="string"
                defaultValue={''}
                error={false}
                disabled={false}
                state="default"
                control={control}
                setValue={setValue}
                selectFirst
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                title={'Create new Token'}
                onClick={setShowTokensModal}
                className={classes.icon}
                size="small"
                color="primary">
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid>
        )}

        {!hasTokens && (
          <ButtonSimple
            onClick={setShowTokensModal}
            color="primary"
            disabled={false}
            text={'Create your first Token'}
            onCLick={() => {
              analyticsEvent(firstAccess ? 'fa_first_token_hook' : 'first_token_hook');
            }}
          />
        )}
      </Box>
    </>
  );
};
export default GeneratorToken;
