import React from 'react';

import cloudProviders from 'constants/cloudProviders';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { red, green, orange, blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import AgentLoading from '@material-ui/icons/Timelapse';
import WarningIcon from '@material-ui/icons/Warning';
import Axios from 'axios';
import { addMinutes, compareAsc, parseISO } from 'date-fns';
import { useQuery } from 'react-query';
import semverCompare from 'semver-compare';

import { useInterval } from 'hooks/useInterval';

import AgentInstructionsDialog from '../../CustomAgentInstructions/AgentInstructionsDialog';

import UninstallDialog from './UninstallDialog';

const PopoverMessage = ({ children, open, setAnchorEl, anchorEl }) => (
  <Popover
    onClose={() => setAnchorEl(null)}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}>
    {children}
  </Popover>
);

const AgentNotLoaded = ({ environment, onLoadAgent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const handleLoadAgent = () => {
    setAnchorEl(null);
    onLoadAgent();
  };

  return (
    <>
      <IconButton
        buttonRef={iconRef}
        onClick={() => setAnchorEl(iconRef.current)}
        data-environment={environment.name}
        title={'Failed to load the agent'}>
        <WarningIcon style={{ color: orange[500] }} />
      </IconButton>
      <PopoverMessage open={open} setAnchorEl={setAnchorEl} anchorEl={anchorEl}>
        <Box padding={2} component={Paper} title="Failed to load the agent.">
          <Typography>Failed to load the agent.</Typography>
          <Box display="flex" justifyContent="flex-end" marginTop={1}>
            <Button color="primary" onClick={handleLoadAgent}>
              Try again?
            </Button>
          </Box>
        </Box>
      </PopoverMessage>
    </>
  );
};

const CustomPendingAgentInstallation = ({ environment, permissions }) => {
  return (
    <>
      <AgentInstructionsDialog
        environment={environment}
        renderIcon={({ onOpen }) => (
          <IconButton
            onClick={onOpen}
            data-environment={environment.name}
            title={'Show instructions on how to install the agent'}
            disabled={!permissions.installAgent}>
            <QueuePlayNextIcon />
          </IconButton>
        )}
      />
    </>
  );
};

const CustomAgentInstallationFailed = ({ environment, permissions }) => {
  return (
    <>
      <AgentInstructionsDialog
        environment={environment}
        renderIcon={({ onOpen }) => (
          <IconButton
            onClick={onOpen}
            data-environment={environment.name}
            title={'Show instructions on how to install the agent'}
            disabled={!permissions.installAgent}>
            <QueuePlayNextIcon style={{ color: red[500] }} />
          </IconButton>
        )}
      />
    </>
  );
};

const ProvisionerAgentInstalling = ({ environment }) => {
  return (
    <div title="Pending agent installation" data-environment={environment.name}>
      <IconButton disabled={true}>
        <AgentLoading style={{ color: orange[500] }} />
      </IconButton>
    </div>
  );
};

const CustomUpdateAvailableInstallation = ({ environment, permissions }) => {
  return (
    <>
      <AgentInstructionsDialog
        environment={environment}
        renderIcon={({ onOpen }) => (
          <IconButton
            onClick={onOpen}
            data-environment={environment.name}
            title={'Show instructions on how to install the agent'}
            disabled={!permissions.installAgent}>
            <WarningIcon style={{ color: red[500] }} />
          </IconButton>
        )}
      />
    </>
  );
};

const CustomAgentInstalled = ({ environment }) => {
  return (
    <>
      <IconButton data-environment={environment.name} title={'Custom agent installed'}>
        <QueuePlayNextIcon style={{ color: blue[700] }} />
      </IconButton>
    </>
  );
};

const ProvisionerUpdateAvailable = ({
  environment,
  permissions,
  agentLastVersion,
  onUpgradeAgent
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconRef = React.useRef(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        buttonRef={iconRef}
        onClick={() => setAnchorEl(iconRef.current)}
        data-environment={environment.name}
        title={'Update available'}
        disabled={!permissions.upgradeAgent}>
        <WarningIcon style={{ color: red[500] }} />
      </IconButton>
      <PopoverMessage open={open} setAnchorEl={setAnchorEl} anchorEl={anchorEl}>
        <Box padding={2} component={Paper} title="Update available.">
          <Typography gutterBottom variant="h6">
            Update available
          </Typography>
          <Typography variant="subtitle2" color="primaryText">
            Current version: {environment.agent?.object?.version}
          </Typography>
          <Typography variant="subtitle2">Last version: {agentLastVersion}</Typography>
          <Box display="flex" justifyContent="flex-end" marginTop={1}>
            <Button color="primary" onClick={onUpgradeAgent}>
              Upgrade to last version
            </Button>
          </Box>
        </Box>
      </PopoverMessage>
    </>
  );
};

const ProvisionerAgentInstallationFailed = ({ environment, permissions, onRetryInstallation }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const iconRef = React.useRef(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        buttonRef={iconRef}
        onClick={() => setAnchorEl(iconRef.current)}
        data-environment={environment.name}
        title={'Failed to install the agent'}
        disabled={!permissions.installAgent}>
        <QueuePlayNextIcon style={{ color: red[500] }} />
      </IconButton>
      <PopoverMessage open={open} setAnchorEl={setAnchorEl} anchorEl={anchorEl}>
        <Box padding={2} component={Paper} title="Agent is failing. Please contact us for support.">
          <Typography>Agent is failing. Please contact us for support.</Typography>
          <Box display="flex" justifyContent="flex-end" marginTop={1}>
            <Button color="primary" onClick={onRetryInstallation}>
              Try again?
            </Button>
          </Box>
        </Box>
      </PopoverMessage>
    </>
  );
};

const ProvisionerAgentInstalled = ({
  environment,
  permissions,
  disableButton,
  onUninstallAgent
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        title={'Uninstall agent'}
        disabled={!permissions.uninstallAgent || disableButton}>
        <QueuePlayNextIcon style={{ color: green[500] }} />
      </IconButton>
      <Box minWidth="md">
        <UninstallDialog
          open={open}
          handleClose={handleClose}
          environment={environment}
          onUninstallAgent={onUninstallAgent}
        />
      </Box>
    </>
  );
};

const ProvisionerPendingAgentInstallation = ({ environment, permissions, onAgentInstall }) => {
  return (
    <IconButton
      onClick={onAgentInstall}
      data-environment={environment.name}
      title={'Install agent'}
      disabled={!permissions.installAgent}>
      <QueuePlayNextIcon />
    </IconButton>
  );
};

const SetupAgent = ({
  environment,
  permissions,
  getAgent,
  installAgent,
  upgradeAgent,
  retryInstallAgent,
  updateEnvironment
}) => {
  const [disableButton, setDisableButton] = React.useState(false);

  const updateAgent = React.useCallback(
    agent => {
      updateEnvironment({
        ...environment,
        agent: { ...environment.agent, ...agent }
      });
    },
    [environment, updateEnvironment]
  );

  const loadAgent = React.useCallback(() => {
    getAgent(environment).then(({ data }) => {
      updateAgent(data.data);
    });
  }, [environment, getAgent, updateAgent]);

  useInterval(() => {
    if ([0, 32, 64].includes(environment.agent?.status_code)) {
      loadAgent();
    }
  }, 30000);

  const handleDisableButtonTimeout = timeout => {
    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, timeout);
  };

  const s3Url = () => {
    let environment = '';
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      environment = 'latest';
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      environment = 'staging';
    }

    if (
      process.env.REACT_APP_ENVIRONMENT === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'local'
    ) {
      environment = 'development';
    }
    return `https://1p-installers.s3.amazonaws.com/agent/bin/linux/${environment}/1p-agent`;
  };

  const { data: agentLastVersion, isLoading } = useQuery(
    'agentMetaData',
    async () => {
      const now = new Date();

      const agentVersionFromCache = JSON.parse(window.localStorage.getItem('agentVersion'));

      // if `agentVersionFromCache.ttl` is after `now`, i.e. cache is still valid
      if (agentVersionFromCache && compareAsc(parseISO(agentVersionFromCache.ttl), now) === 1) {
        return agentVersionFromCache.version;
      }

      const response = await Axios.head(s3Url());
      const version = response.headers['x-amz-meta-version'];

      if (!version) {
        return null;
      }

      const agentVersionCache = {
        version,
        ttl: addMinutes(new Date(), 5)
      };

      window.localStorage.setItem('agentVersion', JSON.stringify(agentVersionCache));

      return version;
    },
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return (
      <IconButton data-environment={environment.name} title={'Loading...'} disabled>
        <QueuePlayNextIcon />
      </IconButton>
    );
  }

  const handleUpgradeAgent = () => {
    updateAgent({ status_code: 0 });
    upgradeAgent({ environment, agentTargetVersion: agentLastVersion });
  };

  const handleInstallAgent = () => {
    updateAgent({ status_code: 0 });
    installAgent(environment);
  };

  const handleRetryInstallAgent = () => {
    updateAgent({ status_code: 0 });
    retryInstallAgent(environment);
  };

  const handleLoadAgent = () => {
    loadAgent();
  };

  if (!environment.agent) {
    return <AgentNotLoaded environment={environment} onLoadAgent={handleLoadAgent} />;
  }

  const customCloud = environment.providerData?.provider === cloudProviders.custom;

  const updateAvailable =
    environment.agent.object?.version &&
    agentLastVersion &&
    semverCompare(agentLastVersion, environment.agent.object.version) === 1;

  const status = {
    // Custom cloud
    customPendingAgentInstallation: customCloud && environment.agent.status === 'CREATED',
    customUpdateAvailable: customCloud && updateAvailable,
    customAgentInstallationFailed: customCloud && environment.agent.status === 'ERROR',
    customAgentInstalled: customCloud && environment.agent.status === 'RUNNING',

    // Managed by provisioner
    provisionerPendingAgentInstallation: !customCloud && [17].includes(environment.agent.status),
    provisionerAgentInstalling: !customCloud && [0, 32, 64].includes(environment.agent.status_code),
    provisionerAgentInstallationFailed:
      !customCloud && [13].includes(environment.agent.status_code),
    provisionerUpdateAvailable: !customCloud && updateAvailable,
    provisionerAgentInstalled: !customCloud && [16].includes(environment.agent.status_code)
  };

  if (status.customPendingAgentInstallation) {
    return <CustomPendingAgentInstallation environment={environment} permissions={permissions} />;
  }

  if (status.provisionerPendingAgentInstallation) {
    return (
      <ProvisionerPendingAgentInstallation
        environment={environment}
        permissions={permissions}
        onAgentInstall={handleInstallAgent}
      />
    );
  }

  if (status.provisionerAgentInstalling) {
    return <ProvisionerAgentInstalling environment={environment} />;
  }

  if (status.customUpdateAvailable) {
    return (
      <CustomUpdateAvailableInstallation environment={environment} permissions={permissions} />
    );
  }

  if (status.customAgentInstalled) {
    return <CustomAgentInstalled environment={environment} permissions={permissions} />;
  }

  if (status.customAgentInstallationFailed) {
    return <CustomAgentInstallationFailed environment={environment} permissions={permissions} />;
  }

  if (status.provisionerAgentInstallationFailed) {
    return (
      <ProvisionerAgentInstallationFailed
        environment={environment}
        permissions={permissions}
        onRetryInstallation={handleRetryInstallAgent}
      />
    );
  }

  if (status.provisionerUpdateAvailable) {
    return (
      <ProvisionerUpdateAvailable
        environment={environment}
        permissions={permissions}
        agentLastVersion={agentLastVersion}
        onUpgradeAgent={handleUpgradeAgent}
      />
    );
  }

  if (status.provisionerAgentInstalled) {
    return (
      <ProvisionerAgentInstalled
        environment={environment}
        disableButton={disableButton}
        handleDisableButtonTimeout={handleDisableButtonTimeout}
        permissions={permissions}
        onUninstallAgent={() => {
          updateAgent({ status_code: 0 });
        }}
      />
    );
  }

  return (
    <ProvisionerPendingAgentInstallation
      environment={environment}
      permissions={permissions}
      onAgentInstall={handleInstallAgent}
    />
  );
};

export default SetupAgent;
