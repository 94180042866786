import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import CloudsEditView from 'views/Clouds/Edit';
import CloudsListView from 'views/Clouds/List';
import CloudsNewView from 'views/Clouds/New';

export const cloudsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={CloudsListView}
    exact
    layout={MainLayoutV5}
    key="/clouds"
    path="/clouds"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={CloudsNewView}
    exact
    layout={MainLayoutV5}
    key="/clouds/new"
    path="/clouds/new"
    planFeature="Monitoring"
    featureTarget="CloudController-post-/clouds"
  />,
  <AuthorizedRoute
    component={CloudsEditView}
    exact
    layout={MainLayoutV5}
    key="/clouds/:id/edit"
    path="/clouds/:id/edit"
    planFeature="Monitoring"
    featureTarget="CloudController-put-/clouds/:id"
  />
];
