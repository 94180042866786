import MonitoringStatusV0 from 'constants/MonitoringStatusV0';

const getTextForStatus = (status?: MonitoringStatusV0) => {
  if (status === MonitoringStatusV0.Up) {
    return { statusText: 'UP', statusTooltipText: 'UP' };
  }

  if (status === MonitoringStatusV0.Down) {
    return { statusText: 'DOWN', statusTooltipText: 'DOWN' };
  }

  if (status === MonitoringStatusV0.NotMonitoring) {
    return { statusText: 'Not monitoring', statusTooltipText: 'Not monitoring' };
  }

  if (status === MonitoringStatusV0.Stale) {
    return {
      statusText: 'Pending/stale monitoring',
      statusTooltipText: 'Pending/stale monitoring'
    };
  }

  return { statusText: 'unknown', statusTooltipText: 'unknown' };
};

export default getTextForStatus;
