import { Box } from '@material-ui/core';

import { EnvironmentSelect } from 'views/ServicesHub/components/EnvironmentSelect';
import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '../..';

export type MultiFlowBaseForm = {
  name: string;
  environmentId: number;
};

export function BaseForm({ form }: FormProps) {
  const { register } = form;
  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <input ref={register} type="hidden" name="id" />
      <input ref={register} type="hidden" name="uid" />

      <Input label="Resource Name" name="name" inputRef={register} errors={form.errors} />

      <EnvironmentSelect form={form} />
    </Box>
  );
}
