export const buildConditionalCell = ({
  fn,
  bgColor,
  labelColor
}: {
  fn: any;
  bgColor: any;
  labelColor?: any;
}) => {
  return {
    when: fn,
    style: {
      backgroundColor: bgColor,
      color: labelColor || 'white',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  };
};
