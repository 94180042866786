import { ProvidersEnum } from 'hooks/queriesGraphQL/useGetProvider';

export function getFeaturePlanProviderName(provider: ProvidersEnum) {
  return {
    [ProvidersEnum.Mail]: 'Mail',
    [ProvidersEnum.Telegram]: 'Telegram',
    [ProvidersEnum.Slack]: 'Slack',
    [ProvidersEnum.Teams]: 'Teams',
    [ProvidersEnum.GoogleChat]: 'GoogleChat',
    [ProvidersEnum.DiscordChannel]: 'Discord',
    [ProvidersEnum.WebHook]: 'CustomWebhook'
  }[provider];
}
