import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { SolrFormData } from 'views/ServicesHub/forms/monitoring/searchEngine/Solr';

import { monitoringAdapter } from '../monitoring';

export function solrAdapter(data: MonitoringBaseForm & SolrFormData) {
  const domain = `http://${data.host}:${data.port}/solr/gettingstarted/admin/ping`;
  // http://HOST:PORT/solr/gettingstarted/admin/ping

  return {
    ...monitoringAdapter({
      ...data,
      method: 'solr'
    }),
    domain
  };
}
