import { useMutation } from 'urql';

const SelectUserAccountMutation = `#graphql
  mutation ($orgId: Float!) {
    selectUserAccount(orgId: $orgId)
  }
`;

interface SelectAccountParams {
  orgId: number;
}

interface SelectAccountResponse {
  selectUserAccount: Boolean;
}

const useSelectAccount = () => {
  const [{ fetching: isLoading }, selectUserAccount] = useMutation<
    SelectAccountResponse,
    SelectAccountParams
  >(SelectUserAccountMutation);

  return { selectUserAccount, isLoading };
};

export default useSelectAccount;
