import { ProductV2 } from 'types/external/Product';
import { useQuery } from 'urql';

type UseProductParams = {
  page: number;
  perPage: number;
  query: string;
};

type UseProductData = {
  data: ProductV2[];
  total: number;
};

const ProductsQuery = `#graphql
  query(
    $from: Int!,
    $size: Int!,
    $query: String!
  ) {
    getProductsFromIndexer(
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        id
        name        
      }
      total
    }
  }
`;

export function useProducts({ page, perPage: size, query }: UseProductParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ getProductsFromIndexer: UseProductData }>({
    query: ProductsQuery,
    variables: {
      from,
      size,
      query
    }
  });
}
