import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  content: {
    backgroundColor: '#FFEFD0',
    padding: theme.spacing(2)
  },
  icon: {
    color: '#FAAD14',
    marginTop: theme.spacing(1.4)
  },
  list: {
    marginLeft: theme.spacing(1.3)
  },
  item: {
    padding: 2
  }
}));
