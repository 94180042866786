import { ChangeEvent, useState } from 'react';

import { MenuItem, TextField, Box, Typography } from '@material-ui/core';
import { FiltersPopper, FiltersPopperProps } from 'oldComponents/FiltersPopper';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { useQuery } from 'urql';

import { useStyles as useGlobalStyles } from 'helpers/globalStyles';

import { useStyles } from '../../styles';

export type PageFilters = {
  status: 'all' | ResourceStatusEnum;
  productId: string | 'all';
};

export type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  handleClose: () => void;
} & Pick<FiltersPopperProps, 'anchorEl'>;

const GetProductsQuery = `#graphql
  query {
    products(page: 1, per_page: 1000, sort: "name,ASC") {
      data {
        id
        name
      }
    }
  }
`;

export function Filters({ anchorEl, onApplyFilters, onClearFilters, handleClose }: FiltersProps) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [status, setStatus] = useState<ResourceStatusEnum | 'all'>('all');
  const [productId, setProductId] = useState<string>('all');

  const [{ data: products }] = useQuery({
    query: GetProductsQuery
  });

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as ResourceStatusEnum);
  };

  const handleChangeProduct = (event: ChangeEvent<HTMLInputElement>) => {
    setProductId(event.target.value as string);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ status, productId });
    handleClose();
  };

  const handleClearFilters = () => {
    setStatus('all');
    setProductId('all');
    onClearFilters();
    handleClose();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <Box display="flex" justifyContent="space-between" gridGap={12}>
        <Box width="720px">
          <TextField
            name="statusFilter"
            label="Status"
            variant="outlined"
            onChange={handleChangeStatus}
            value={status}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true
            }}
            select
            fullWidth>
            <MenuItem value={'all'}>All</MenuItem>
            <MenuItem value={ResourceStatusEnum.Available}>
              Available &nbsp;
              <Typography variant="caption" className={classes.caption}>
                Resource
              </Typography>
            </MenuItem>
            <MenuItem value={ResourceStatusEnum.Unavailable}>
              Unavailable &nbsp;
              <Typography variant="caption" className={classes.caption}>
                Resource
              </Typography>
            </MenuItem>
            <MenuItem value={MultiFlowStatusEnum.Success}>
              Success &nbsp;
              <Typography variant="caption" className={classes.caption}>
                Synthetic
              </Typography>
            </MenuItem>
            <MenuItem value={MultiFlowStatusEnum.Error}>
              Error &nbsp;
              <Typography variant="caption" className={classes.caption}>
                Synthetic
              </Typography>
            </MenuItem>
            <MenuItem value={ResourceStatusEnum.Off}>Off</MenuItem>
            <MenuItem value={ResourceStatusEnum.Pending}>Pending</MenuItem>
            <MenuItem value={ResourceStatusEnum.Maintenance}>Maintenance</MenuItem>
          </TextField>
        </Box>
        <Box width="720px">
          <TextField
            name="productIdFilter"
            label="Product"
            variant="outlined"
            onChange={handleChangeProduct}
            value={productId}
            InputProps={{
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true
            }}
            select
            SelectProps={{
              MenuProps: {
                style: { maxHeight: '50%' }
              }
            }}
            fullWidth>
            <MenuItem value="all">All</MenuItem>
            {products?.products?.data &&
              products?.products?.data?.length &&
              products?.products?.data?.map((product: { id: number; name: string }) => {
                return <MenuItem value={product.id}>{product.name}</MenuItem>;
              })}
          </TextField>
        </Box>
      </Box>
    </FiltersPopper>
  );
}
