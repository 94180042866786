export const setOneTeamForResource = `#graphql
      mutation ($teamId: Float!, $resourceId: Float!){
        setOneTeamForResource(
          teamId: $teamId,
          resourceId: $resourceId,
          resourceType: "Application"
        ){
          resourceId
          resourceType
        }
      }
    `;
