import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

function MetricsLeadTimeForChanges() {
  const dashboardName = MetabaseDashboards.LeadTimeForChanges;

  return (
    <MetabaseDashboard
      title=" Lead Time for Changes"
      subtitle="Lead Time for Changes metrics of your applications"
      dashboardName={dashboardName}
    />
  );
}

export default MetricsLeadTimeForChanges;
