import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useQuery } from 'urql';

const GetIncidentsQuery = `#graphql
  query (
    $index: String!
    $from: Int!
    $period: Int
    $size: Int!
    $query: String!
  ) {
    incidentsWithSeverity(
      index: $index
      from: $from
      gte: $period
      query: $query
      size: $size
      fields: ["title^3", "description"]
    ) {
      data {
        incidentId
        title
        description
        status
        type
        severity
        createdAt
        updatedAt
        happenedAt
      }
      total
    }
  }
`;

export type Incident = {
  incidentId: number;
  status: StatusEnum;
  severity: SeverityStatusEnum;
  title: string;
  type: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  happenedAt: string;
};

export function useIncidents({
  page,
  period,
  perPage,
  query,
  status,
  type,
  severity,
  pause
}: {
  type: string;
  status: string;
  severity?: string;
  page: number;
  period: number;
  perPage: number;
  query: string;
  pause?: boolean;
}) {
  const filterQuery = (() => {
    const filters = [];

    if (query) {
      filters.push(`(*${query}*)`);
    }

    if (status !== 'all') {
      filters.push(`(status: "${status}")`);
    }

    if (type !== 'all') {
      filters.push(`(type: "${type}")`);
    }

    if (severity !== 'all') {
      filters.push(`(severity: "${severity}")`);
    }

    return filters.reduce((query, filter) => {
      if (!query) return filter;

      return `${query} AND ${filter}`;
    }, '');
  })();

  const index = process.env.REACT_APP_ES_INCIDENTS_MANAGER_INDEX;
  const from = Math.max(page - 1, 0) * perPage;

  const [{ fetching, data }, reexecuteQuery] = useQuery<{
    incidentsWithSeverity: { data: Incident[]; total: number };
  }>({
    query: GetIncidentsQuery,
    variables: {
      index,
      query: filterQuery,
      size: perPage,
      from,
      period
    },
    pause
  });

  return {
    isFetching: fetching,
    reexecuteQuery,
    data: {
      incidents: data?.incidentsWithSeverity?.data || [],
      total: data?.incidentsWithSeverity?.total || 0
    }
  };
}
