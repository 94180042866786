import { makeStyles, Box, Divider, Typography } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useParams } from 'react-router';

import ButtonWithIcon from 'componentsV3/ButtonWithIcon';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useProductPermission } from 'hooks/useProductPermission';

import Info from './Info';

const useStyles = makeStyles(theme => ({
  dividerTop: {
    backgroundColor: 'black',
    opacity: '10%',
    marginBottom: theme.spacing(3)
  },
  dividerBottom: {
    backgroundColor: 'black',
    opacity: '10%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  paragraphText: {
    maxWidth: '42ch',
    paddingBottom: theme.spacing(3)
  },
  closeButton: {
    marginRight: theme.spacing(5)
  }
}));

const MaintenanceViewForm = ({ onClose, startValues, fetchingCreate, setCurrentForm }) => {
  const classes = useStyles();

  const { productId } = useParams();

  const canDeleteMaintenance = useProductPermission({
    target: 'MaintenanceController-delete-/maintenance',
    productId
  });
  const canPostMaintenance = useProductPermission({
    target: 'MaintenanceController-post-/maintenance',
    productId
  });

  return (
    <>
      <Typography className={classes.paragraphText}>You have maintenance scheduled.</Typography>

      <Divider className={classes.dividerTop} />

      <Info startValues={startValues} />

      <Divider className={classes.dividerBottom} />

      <Box display="flex" justifyContent="space-beetwen">
        <ButtonWithIcon text="Close" onClick={onClose} />

        <ButtonWithIcon
          text="Delete"
          title="Delete Maintenance"
          disabled={!canDeleteMaintenance}
          startIcon={<Delete />}
          onClick={() => setCurrentForm('delete')}
        />

        <ButtonWithIcon
          text="Edit"
          title="Edit Maintenance"
          disabled={!canPostMaintenance}
          startIcon={<Edit />}
          onClick={() => setCurrentForm('edit')}
        />
      </Box>

      {fetchingCreate && <LoadingOverlay />}
    </>
  );
};

export default MaintenanceViewForm;
