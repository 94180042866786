import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { MongoDBFormData } from 'views/ServicesHub/forms/monitoring/database/MongoDB';

import { monitoringAdapter } from '../monitoring';

export function mongodbAdapter(data: MonitoringBaseForm & MongoDBFormData) {
  const domain = prepareDomain(data);
  // ex: mongodb://HOST:PORT

  return {
    ...monitoringAdapter({
      ...data,
      method: 'mongodb'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringBaseForm & MongoDBFormData) => {
  if (data.domainType === 'userAndPass') {
    const port = data.port ? `:${data.port}` : '';
    const database = data.database ? `/${data.database}` : '';

    return `${data.protocol}://${data.user}:${data.password}@${data.host}${port}${database}`;
  }

  return data.domain;
};
