import axios from 'axios';

import { Features } from './types';

export function fetchPlanFeatures(planName: string) {
  return async () => {
    if (!planName) return {};

    const planNameSanitized = planName.replace(/\s/g, '_');

    const bucketUrl = process.env.REACT_APP_PLANS_FEATURES_BUCKET_URL;
    const fetchUrl = `${bucketUrl}/${planNameSanitized}.json`;

    const { data } = await axios.get<Features>(fetchUrl);

    return data;
  };
}
