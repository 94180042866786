import { composeStep } from './composeStep';
import { StepGroupsType } from './StepGroupsType';
import { StepType } from './StepType';
import { TimeUnitsType } from './TimeUnitsType';

export const composePayloadCreate = ({
  data,
  teamId,
  timeUnits
}: {
  data: StepGroupsType;
  teamId: string;
  timeUnits: TimeUnitsType;
}) => {
  return {
    name: data.stepsGroupName,
    teamIdFromTeams: Number(teamId),
    team: null,
    steps: data.steps.map((step: StepType, index: number) => ({
      ...composeStep({ step, index, timeUnits })
    }))
  };
};
