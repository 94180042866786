import React from 'react';

import { Grid, Input, InputLabel, Select, MenuItem, Switch } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Form = ({ onChange, roles, formState }) => {
  const handleRolesChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange({ name, value });
  };

  const handleActiveToggleChange = event => {
    event.preventDefault();
    onChange({
      name: 'isActive',
      value: event.target.checked
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={1} xs={12}>
        <InputLabel shrink>Role</InputLabel>
        <Select
          input={<Input />}
          MenuProps={MenuProps}
          name="roleId"
          onChange={handleRolesChange}
          style={{ minWidth: '100%' }}
          value={String(formState.values.roleId) || ''}
          variant="outlined">
          {roles.map(role => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item md={1} xs={12}>
        <InputLabel shrink>Active</InputLabel>
        <Switch
          color="primary"
          checked={!!formState.values.isActive}
          inputProps={{ 'aria-label': 'isActive checkbox' }}
          name="isActive"
          onChange={handleActiveToggleChange}
          value={formState.values.isActive}
        />
      </Grid>
    </Grid>
  );
};

export default Form;
