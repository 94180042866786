import React from 'react';

import { Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import InputKeyValue from 'componentsV3/InputKeyValue/index';
import withThemeV3Hoc from 'theme/v3/withTheme';

const KeyValueWithAdd = ({
  control,
  errors,
  fieldAppend,
  fieldRemove,
  fieldsList,
  name,
  propsKey,
  propsValue,
  register,
  setValue,
  watch
}) => {
  const renderFieldsList = (item, index) => {
    return (
      <InputKeyValue
        control={control}
        errors={errors}
        fieldAppend={fieldAppend}
        fieldRemove={fieldRemove}
        fieldsListLength={fieldsList.length}
        icon={<DeleteIcon />}
        index={index}
        isItemList={true}
        item={item}
        key={item.id}
        name={`${name}[${index}]`}
        propsKey={propsKey}
        propsValue={propsValue}
        register={register}
        setValue={setValue}
        watch={watch}
      />
    );
  };

  return <Box>{fieldsList?.map(renderFieldsList)}</Box>;
};

export default withThemeV3Hoc(KeyValueWithAdd);
