import { useEffect } from 'react';

import { useQuery } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';

const getFrequenciesMetrics = `#graphql
  query(
    $applicationUid: String!
    $periods: [String!]
  ) {
    frequenciesMetricsV2(
      applicationUid: $applicationUid
      periods: $periods
    ) {
      applicationId
      applicationUid
      orgUid
      metrics{
        type
        changeFailure
        deployFrequency
      }   
    }
  }
`;

export const useFrequenciesMetricsV2 = ({
  applicationUid,
  periods
}: {
  applicationUid: string;
  periods: string[];
}) => {
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const [{ fetching: fetchingFrequenciesMetrics, data }, reexecuteQuery] = useQuery({
    query: getFrequenciesMetrics,
    pause: !useFrequenciesMetricsV2 || !applicationUid,
    variables: { applicationUid, periods }
  });

  const metrics = data?.frequenciesMetricsV2 ?? null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (fetchingFrequenciesMetrics) return;

      reexecuteQuery();
    }, 6000 * 10 /* ms = 10 seconds */);

    return () => clearInterval(intervalId);
  }, [fetchingFrequenciesMetrics, reexecuteQuery]);

  const metric = metrics?.metrics?.find((metric: { type: string }) => {
    if (canFetchDataFromTheLast30Days) {
      return metric.type === '30 days';
    }

    return metric.type === '1 day';
  });

  return metric;
};
