import { useState, useCallback } from 'react';

import { IconButton, Icon } from '@material-ui/core';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import useNewAddons from 'hooks/queriesGraphQL/useNewAddons';
import actions from 'redux/actions';
import axios from 'redux/axios';

const DeleteAddonButton = ({
  productId,
  addonId,
  name,
  applicationId,
  disabled
}: {
  productId: number;
  addonId: number;
  name: string;
  applicationId: number;
  disabled: boolean;
}) => {
  const { reexecuteQuery: refetchNewDependencies } = useNewAddons({ applicationId });

  const dispatch = useDispatch();

  const [dialog, setDialog] = useState(false);

  const handleChange = useCallback(() => {
    setDialog(!dialog);
  }, [setDialog, dialog]);

  const [deleteAddon] = useMutation(
    async id => axios.delete(`/products/${productId}/addonsV4/${addonId}`),
    {
      onSuccess: () => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Addon removed successfully'
        });
        refetchNewDependencies();
        handleChange();
      }
    }
  );

  return (
    <>
      <IconButton
        id={`removeAddonIcon`}
        title="Remove Dependency"
        onClick={handleChange}
        disabled={disabled}>
        <Icon>delete</Icon>
      </IconButton>

      <DeleteEntity
        open={dialog}
        handleClose={handleChange}
        dependencyName={name}
        handleRemove={() => {
          deleteAddon();
          handleChange();
        }}
        entityName="Dependency"
        disabled={undefined}
        children={undefined}
        agentInstalled={undefined}
        isLoading={undefined}
      />
    </>
  );
};

export default DeleteAddonButton;
