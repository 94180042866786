import { Box } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import { LoadingOverlay } from 'componentsV4/Loading';

import { Select } from '../../../ServicesHub/components/Select';

type ProductSelectProps = {
  form: UseFormMethods<any>;
  options: { label: string; value: string }[];
  fetching: boolean;
  required: boolean;
  refetch: () => void;
};

export function ProductSelect({
  form: { control, register },
  options,
  required,
  fetching
}: ProductSelectProps) {
  if (fetching) {
    return <LoadingOverlay />;
  }

  return (
    <Box position="relative" display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <Select
          options={options}
          name={`productId`}
          label="Choose product"
          control={control}
          required={required}
        />
      </Box>
    </Box>
  );
}
