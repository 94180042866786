export const assertionDefaultValue = {
  source: 'status_code',
  comparison: 'equals_number',
  targetValue: undefined,
  property: undefined
};

export const stepDefaultValue = {
  assertions: [
    {
      source: 'status_code',
      comparison: 'equals_number',
      targetValue: undefined,
      property: undefined
    }
  ],
  headers: [
    {
      key: '',
      value: ''
    }
  ],
  healthcheckUrl: '',
  id: '',
  method: 'GET',
  name: '',
  postBody: '',
  postBodyType: '',
  rawType: '',
  skipSslValidation: 'false',
  timeout: '60',
  tlsRenegotiation: 0,
  validationString: ''
};

export const defaultValues = {
  id: '',
  uid: '',
  name: '',
  intervalCheck: 60,
  rules: {
    incident: {
      intervalHits: 120,
      intervalFailures: 120,
      failuresToOpenIncident: 2,
      hitsToCloseIncident: 2,
      type: 'incident',
      severity: 'not-classified',
      teams: [],
      responders: [],
      enable: false
    }
  },
  environmentId: '',
  steps: [
    {
      ...stepDefaultValue,
      method: 'POST',
      postBody: '{}',
      rawType: 'application/json',
      postBodyType: 'raw'
    },
    stepDefaultValue
  ]
};
