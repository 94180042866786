import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/addons/fetchSuccessful';

function* prepareFetchEntities(axios, action) {
  try {
    const {
      application_services_view,
      application_services_view: {
        autoComplete: { searchText }
      }
    } = yield select(state => state);

    const applicationId = application_services_view.id;
    const { productId } = action.params;

    const {
      data: { data: payload }
    } = yield axios.get(
      `/products/${productId}/applications/${applicationId}/addons/search?q=${searchText}`
    );

    yield put({
      type: actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL,
      meta: {
        reducer
      },
      payload
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchFetchEntity(axios) {
  yield takeLatest(actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_CHANGE, prepareFetchEntities, axios);
}
