import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: 560,
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 8px 0px #00000026'
  },
  cardContent: {
    padding: theme.spacing(2.5),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  description: {
    fontWeight: 400
  },
  cardTop: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  divider: {
    height: 1,
    color: theme.palette.gray[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  chip: {
    color: 'white',
    backgroundColor: '#898989',
    '&[data-integrated=true]': {
      backgroundColor: theme.palette.primary.main
    }
  },
  button: {
    minWidth: 42,
    height: 34,
    padding: theme.spacing(1)
  },
  paperProps: {
    width: '200px'
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.green[50],
      color: theme.palette.green[400]
    }
  }
}));
