import applicationType from 'constants/ApplicationType';

import mapFull from './mapFull';
import mapMultiHttp from './mapMultiHttp';
import mapWebhook from './mapWebhook';

const getApplicationsSettingsForType = ({ formData, resourceType }) => {
  if (
    [
      applicationType.Internal.type,
      applicationType.ExternalWithAddons.type,
      applicationType.ExternalWithoutAddons.type
    ].includes(resourceType)
  ) {
    return mapFull({ formData });
  }

  if (resourceType === applicationType.InternalMultiHttp.type) {
    return mapMultiHttp({ formData });
  }

  if (resourceType === applicationType.Webhook.type) {
    return mapWebhook({ formData });
  }

  return mapFull({ formData });
};

const mapFormDataToApi = ({
  formData,
  resourceType,
  applicationId,
  productId,
  teamId,
  predefinedSettings
}) => {
  const applicationsSettings = getApplicationsSettingsForType({
    formData,
    resourceType
  });

  if (resourceType === applicationType.InternalMultiHttp.type) {
    const data = {
      pre: {
        ...applicationsSettings.pre
      },
      pos: {
        id: applicationId,
        environmentId: formData.environmentId,
        productId: productId,
        teamId: teamId || null,
        name: formData.pos_name,
        applicationsSettings: {
          type: resourceType,
          ...applicationsSettings.pos,
          ...predefinedSettings
        }
      },
      addonSettings: {
        ...applicationsSettings.addonSettings
      }
    };

    return data;
  }

  const data = {
    id: applicationId,
    environmentId: formData.environmentId,
    name: formData.name,
    productId: productId,
    teamId: teamId || null,
    applicationsSettings: {
      type: resourceType,
      ...applicationsSettings,
      ...predefinedSettings,
      p50xp90_anomaly_percent: null,
      p50xp95_anomaly_percent: null
    }
  };

  return data;
};

export default mapFormDataToApi;
