const postMortemTemplate = `
# Summary


# Root Cause


# Recovery


# Corrective Actions
`.trim();

export default postMortemTemplate;
