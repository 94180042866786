import palette from '../palette';

const MuiMenuItem = {
  root: {
    color: palette.text.primary,
    fontSize: '1rem',
    marginTop: '11px',
    marginBottom: '11px'
  }
};

export default MuiMenuItem;
