import {
  Box,
  Checkbox,
  Chip,
  Paper,
  PaperProps,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  ExpandMore,
  AddCircle
} from '@material-ui/icons';
import Autocomplete, { RenderInputParams } from '@material-ui/lab-old/Autocomplete';
import { Control, Controller } from 'react-hook-form';

import palette from 'theme/palette';
import { withThemeV4 } from 'theme/v4';

import useStyles from './style';

type Props = {
  teams: Team[];
  onClick: Function;
  control: Control;
  name: string;
};

type Team = {
  label: string;
  value: string;
  members: Members[];
  products: Product[];
};

type Members = {
  label: string;
  value: string;
};

type Product = {
  id: number;
  name: string;
};

const CustomPaper = ({ children, ...props }: PaperProps) => {
  return <Paper {...props}>{children}</Paper>;
};

const formatWord = (items: any[], pluralWord: string, notPluralWord: string) => {
  if (!items?.length) return '';

  return items.length > 1 ? pluralWord : notPluralWord;
};

const DropDownTeams = ({ teams, onClick, control, name }: Props) => {
  const classes = useStyles();

  const renderOption = (option: Team, { selected }: { selected: boolean }) => {
    const usersQuantity = option.members.length;
    const productsQuantity = option.products.length;
    return (
      <Box
        style={{
          backgroundColor: selected ? palette.green[50] : 'transparent'
        }}
        className={classes.selectItem}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon htmlColor={palette.green[400]} />}
          checkedIcon={<CheckBoxIcon htmlColor={palette.green[400]} />}
          checked={selected}
        />
        <Box className={classes.selectItemsInfo}>
          <Typography>{option.label}</Typography>

          <Typography>
            {formatWord(option?.products, `${option?.products?.length} products`, '1 product')}
            {usersQuantity > 0 && productsQuantity > 0 && ' | '}
            {formatWord(option?.members, `${option?.members?.length} members`, '1 member')}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderTags = (value: Team[], getTagProps: ({ index }: { index: number }) => any) =>
    value.map((option: Team, index: number) => (
      <Chip
        deleteIcon={<ClearIcon htmlColor={palette.green[400]} />}
        classes={{ root: classes.chip }}
        color="primary"
        variant="outlined"
        label={option.label}
        size="small"
        {...getTagProps({ index })}
      />
    ));

  const renderInput = (value: any) => {
    return (params: RenderInputParams) => (
      <TextField
        style={{ width: 356 }}
        {...params}
        InputProps={{
          classes: {
            root: classes.input,
            focused: classes.inputFocused,
            notchedOutline: classes.notchedOutline
          },
          ...params.InputProps
        }}
        inputProps={{
          style: {
            padding: 6
          },
          ...params.inputProps
        }}
        variant="outlined"
        placeholder={value?.length ? '' : `Select ${formatWord(teams, 'Teams', 'Team')}`}
      />
    );
  };

  return (
    <Controller
      render={({ onChange, value }) => (
        <Box className={classes.containerNoWidth}>
          <Autocomplete
            options={teams}
            multiple
            onChange={(_, data) => {
              onChange(data);
            }}
            value={value}
            renderOption={renderOption}
            getOptionSelected={(option, value) => {
              return option.value === value.value;
            }}
            renderTags={renderTags}
            renderInput={renderInput(value)}
            disableCloseOnSelect
            size="small"
            PaperComponent={CustomPaper}
            className={classes.autocomplete}
            classes={{
              root: classes.root,
              paper: classes.paper,
              option: classes.option
            }}
            getOptionLabel={option => option.label}
            popupIcon={<ExpandMore htmlColor={palette.green[400]} />}
          />
          <Box ml={1}>
            <IconButton title={'Create New Team'} size="small" onClick={() => onClick()}>
              <AddCircle htmlColor={palette.green[400]} />
            </IconButton>
          </Box>
        </Box>
      )}
      name={name}
      control={control}
    />
  );
};

export default withThemeV4(DropDownTeams);
