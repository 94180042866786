import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

function UptimeMetrics() {
  const dashboardName = MetabaseDashboards.UptimeByOrg;

  return (
    <MetabaseDashboard
      title="Organization uptime metrics"
      subtitle="List of resources with their uptimes"
      dashboardName={dashboardName}
    />
  );
}

export default UptimeMetrics;
