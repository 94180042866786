const deployTypes = ['deploy', 'rollback', 'hotfix'];

const getCreatedAt = (item: any): string => {
  const createdAt = deployTypes.includes(item.type.toLowerCase())
    ? item.content && item.content.createdAt
    : item.createdAt;

  return createdAt;
};

export default getCreatedAt;
