import { getEnvUrl } from 'helpers/getEnvString';

const curlTextDeployV2 = (setValues, data, orgUid, deployType, applicationUid) => {
  if (data) {
    setValues({
      ...data,
      curl: `curl --request POST \\
      --url https://${getEnvUrl()}/external/v2/delivery/${applicationUid}?token=${data.key} \\
      --header 'Content-Type: application/json' \\
      --header 'User-Agent: 1PwebhookDeliveries/1.0' \\
      --data '{
        "organization": "${orgUid}",
        "description": "< Description >",
        "version": "< Version >",
        "type": "${deployType}"
      }'`
    });
  }
};

export default curlTextDeployV2;
