import { CreateStepGroupsMutation } from 'graphqlQueries/createStepGroupsUsingTeams';
import { UpdateStepGroupsMutation } from 'graphqlQueries/updateStepGroups';
import { useMutation } from 'urql';

import { composePayloadCreate } from './composePayloadCreate';
import { composePayloadUpdate } from './composePayloadUpdate';
import { StepGroupsType } from './StepGroupsType';
import { TimeUnitsType } from './TimeUnitsType';

const useMutationUrql = ({
  serverData,
  onSubmit,
  teamId,
  timeUnits
}: {
  serverData: StepGroupsType;
  onSubmit: any;
  teamId: string;
  timeUnits: TimeUnitsType;
}) => {
  const [createResponse, submitCreateMutation] = useMutation(CreateStepGroupsMutation);
  const [updateResponse, submitUpdateMutation] = useMutation(UpdateStepGroupsMutation);

  const updateMutation = async ({
    data,
    serverData
  }: {
    data: StepGroupsType;
    serverData: StepGroupsType;
  }) => {
    const updatedResult = await submitUpdateMutation({
      updateStepGroupInput: composePayloadUpdate({ data, serverData, timeUnits })
    });

    if (updatedResult.error) {
      return;
    }

    onSubmit({
      data: {
        data: updatedResult.data.updateStepGroups
      }
    });

    return;
  };

  const createMutation = async ({ data }: { data: StepGroupsType }) => {
    const result = await submitCreateMutation({
      createStepGroupInput: composePayloadCreate({ data, teamId, timeUnits })
    });

    if (result.error) {
      return;
    }

    onSubmit({
      data: {
        data: result.data.createStepGroupsUsingTeams
      }
    });
    return;
  };

  const newSubmitMutation = async (data: StepGroupsType) => {
    if (serverData) {
      return await updateMutation({ data, serverData });
    }

    return await createMutation({ data });
  };

  return { newSubmitMutation, error: serverData ? updateResponse.error : createResponse.error };
};
export default useMutationUrql;
