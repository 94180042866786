import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

type Variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2';

const useStyles = makeStyles({
  h1: {
    fontSize: '64px',
    lineHeight: '100%',
    fontWeight: 700
  },
  h2: {
    fontSize: '52px',
    lineHeight: '100%',
    fontWeight: 700
  },
  h3: {
    fontSize: '40px',
    lineHeight: '100%',
    fontWeight: 500
  },
  h4: {
    fontSize: '28px',
    lineHeight: '100%',
    fontWeight: 'bold'
  },
  h5: {
    fontSize: '24px',
    lineHeight: '100%',
    fontWeight: 500
  },
  h6: {
    fontSize: '16px',
    lineHeight: '100%',
    fontWeight: 'bold'
  },
  body1: {
    fontSize: '20px',
    lineHeight: '120%'
  },
  body2: {
    fontSize: '16px',
    lineHeight: '120%'
  }
});

type Props = Omit<MuiTypographyProps, 'variant'> & {
  variant: Variants;
};

export const Typography = ({ children, variant, className, ...props }: Props) => {
  const classes = useStyles();

  if (variant) {
    return (
      <MuiTypography className={clsx(classes[variant], className)} {...props}>
        {children}
      </MuiTypography>
    );
  }

  return <MuiTypography {...props}>{children}</MuiTypography>;
};
