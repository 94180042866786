import dayjs from 'dayjs';
import { IncidentManual, UpdateManualIncidentPayload } from 'types/external/IncidentManual';

import { IncidentManualData } from 'views/ServicesHub/layouts/Incident';

export function incidentManualStartValuesAdapter({
  incident,
  respondersFieldOptions,
  monitorsOptions
}: {
  incident: IncidentManual;
  respondersFieldOptions: {
    value: string;
    label: string;
  }[];
  monitorsOptions: {
    value: string;
    label: string;
    type: string;
  }[];
}): IncidentManualData {
  const date = dayjs(incident.started_at);

  const getLabelForTeams = ({
    team,
    options
  }: {
    team: number;
    options: { value: string; label: string }[];
  }) => {
    const selected = options.find(option => {
      return Number(option.value) === team;
    });
    return selected ? selected.label : '';
  };

  const getMonitorsData = ({
    origin,
    options
  }: {
    origin: string;
    options: { value: string; label: string; type: string }[];
  }) => {
    const selected = options.find(option => {
      return option.value === origin;
    });
    return selected ? { label: selected.label, type: selected.type } : { label: '', type: '' };
  };

  const teams = !incident.linked_teams
    ? []
    : incident.linked_teams.map(team => {
        return {
          value: String(team.team_id),
          linkedTeamId: team.id,
          label: respondersFieldOptions
            ? getLabelForTeams({ team: team.team_id, options: respondersFieldOptions })
            : ''
        };
      });

  const origins = !incident.linked_origins
    ? []
    : incident.linked_origins.map(origin => {
        const originData = monitorsOptions
          ? getMonitorsData({ origin: origin.origin_uid, options: monitorsOptions })
          : { label: '', type: '' };
        return {
          value: origin.origin_uid,
          label: originData.label,
          type: originData.type,
          linkedOriginId: origin.id
        };
      });

  const responders = incident.responders?.map(responder => {
    return {
      value: responder.uid,
      type: responder.type,
      helperText: responder.type,
      label:
        respondersFieldOptions.find(option => option.value === String(responder.uid))?.label ?? ''
    };
  });

  return {
    incidentId: incident.id,
    incidentName: incident.name,
    cause: incident.cause,
    responders,
    incidentStatus: incident.status,
    severity: incident.severity,
    startedAt: date.format('YYYY-MM-DDTHH:mm'),
    teams,
    origins
  };
}

export function updateManualIncidentAdapter(
  incident: IncidentManualData,
  incidentId: number
): UpdateManualIncidentPayload {
  return {
    incidentId: incidentId,
    name: incident.incidentName,
    cause: incident.cause,
    status: incident.incidentStatus,
    severity: incident.severity,
    startedAt: incident.startedAt,
    linkedTeams: incident.teams
      ? incident.teams.map(team => ({
          teamId: Number(team.value),
          linkedTeamId: team.linkedTeamId ? Number(team.linkedTeamId) : undefined
        }))
      : [],
    responders: incident.responders
      ? incident.responders.map(responder => ({
          uid: responder.value,
          type: responder.type
        }))
      : [],
    linkedOrigins: incident.origins
      ? incident.origins.map(origin => ({
          originUid: origin.value,
          originEntity: origin.type,
          linkedOriginId: origin.linkedOriginId ? Number(origin.linkedOriginId) : undefined
        }))
      : []
  };
}
