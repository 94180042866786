import { useQuery } from 'urql';

const GetQRCodeToken = `
    query {
        getQRCodeToken {
            token
        }
    }
`;

const CreateQRCodeToken = `
    mutation {
        createQRCodeToken {
            token
        }
    }
`;

export const useGetQRCodeToken = ({ pause }: { pause: boolean }) => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<{ getQRCodeToken: { token: string } }>({
    query: GetQRCodeToken,
    pause
  });

  return {
    fetching,
    token: data?.getQRCodeToken.token,
    reexecuteQuery
  };
};

export const useCreateQRCodeToken = ({ pause }: { pause: boolean }) => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<{
    createQRCodeToken: { token: string };
  }>({
    query: CreateQRCodeToken,
    pause
  });

  return {
    fetching,
    token: data?.createQRCodeToken.token,
    reexecuteQuery
  };
};
