import { PostBodyTypes, PostBodyValues } from 'constants/postBody';

import { ResourceOrigin } from 'types/external/Resource';

import keyValuesToObject from 'helpers/keyValuesToObject';
import { PostFormData } from 'views/ServicesHub/forms/monitoring/http/Post';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function postAdapter(data: MonitoringCommomData & PostFormData) {
  const postBody = preparePostBody({
    postBodyRaw: data.postBodyRaw,
    postBodyUrlencoded: data.postBodyUrlencoded,
    postBodyType: data.postBodyType
  });
  const headers = keyValuesToObject(data.headers);
  const postBodyType = preparePostBodyType(data.postBodyType, data.rawType);

  return {
    ...monitoringAdapter({ ...data, method: 'POST' }),
    origin: data.elvenAgentRegion ? ResourceOrigin.Pool : ResourceOrigin.Agent,
    domain: data.url,
    domainSettings: {
      ...(data.skipSslValidation ? { skipSslValidation: data.skipSslValidation } : {}),
      tlsRenegotiation: Number(data.tlsRenegotiation),
      ...(headers ? { headers } : {}),
      ...(postBody ? { postBody } : {}),
      postBodyType,
      ...(data.elvenAgentRegion
        ? {
            poolConfig: {
              cloud: data.elvenAgentCloud,
              region: data.elvenAgentRegion
            }
          }
        : {}),
      validationString: data.validationString
    }
  };
}

const preparePostBody = ({
  postBodyUrlencoded,
  postBodyRaw,
  postBodyType
}: {
  postBodyUrlencoded: { key: string; value: string }[] | undefined;
  postBodyRaw: string | undefined;
  postBodyType: PostBodyTypes;
}) => {
  if (postBodyType === 'raw') return postBodyRaw;

  return JSON.stringify(keyValuesToObject(postBodyUrlencoded) ?? {});
};

const preparePostBodyType = (postBodyType: PostBodyTypes, rawType: PostBodyValues | undefined) => {
  if (postBodyType === PostBodyTypes.urlencoded) return PostBodyTypes.urlencoded;
  return rawType as PostBodyValues;
};
