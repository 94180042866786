import { useState } from 'react';

import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import Breadcrumb from 'components/Breadcrumb';
import { usePermission } from 'hooks/usePermission';

import DataGridFromTeams from '../../oldComponents/Crud/DataGrid/v2';
import Toolbar from '../../oldComponents/Crud/ToolbarQueryProps';

import { deleteQuery, queryGetTeamsByOrgUid } from './queries';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  }
});

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    cell: row => (
      <div style={{ display: 'flex' }}>
        <div>
          <Link component={RouterLink} to={`/teams/${row.id}`}>
            {row.name}
          </Link>
          <div>{row.members?.length} members</div>
        </div>
      </div>
    )
  }
];

const TeamList = ({ classes }) => {
  const [query, setQuery] = useState('');

  const DataGrid = DataGridFromTeams;

  const permissions = {
    new: usePermission('TeamController-post-/teams'),
    update: usePermission('TeamController-put-/teams/:id'),
    remove: usePermission('TeamController-delete-/teams/:id')
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'People' }, { label: 'Team Center' }]} />
      <Toolbar
        title="Teams Center"
        disableNew={!permissions.new}
        buttonNewName="New"
        entity="teams"
        searchPlaceholder="Search team"
        setQuery={setQuery}
      />
      <div className={classes.content}>
        <DataGrid
          fetchQuery={queryGetTeamsByOrgUid}
          deleteQuery={deleteQuery}
          argsDelete={['id']}
          query={query}
          cols={columns}
          permissions={permissions}
          entity="teams"
        />
      </div>
    </>
  );
};

export default withStyles(styles)(TeamList);
