import { Box, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { formatMetricsValue, MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { useStyles } from './styles';

dayjs.extend(localizedFormat);

export const Diff = ({
  value,
  startDate,
  valueFormat,
  invertColor = false,
  endDate
}: {
  value: number;
  valueFormat?: MetricsValueFormatEnum;
  invertColor?: boolean;
  startDate: Date;
  endDate: Date;
}) => {
  const isDown = value < 0 ? true : false;
  const classes = useStyles({ isDown: invertColor ? !isDown : isDown });

  const getInterval = () => {
    const interval = dayjs(endDate).diff(startDate, 'day');

    return `Compared to ${dayjs(startDate)
      .subtract(interval, 'day')
      .format('ll')} - ${dayjs(endDate)
      .subtract(interval, 'day')
      .format('ll')}`;
  };

  const getIcon = () => {
    return isDown ? (
      <ArrowDownward className={classes.icon} />
    ) : (
      <ArrowUpward className={classes.icon} />
    );
  };

  return (
    <>
      {!value ? (
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="body2" className={classes.paperTextBottomPeriod}>
            No data for comparison
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="body2">{getIcon()} </Typography>
          <Typography variant="body2" className={classes.paperTextBottomTime}>
            {formatMetricsValue({
              value: value,
              valueFormat: valueFormat
            })}
          </Typography>
          <Typography variant="body2" className={classes.paperTextBottomPeriod}>
            {getInterval()}
          </Typography>
        </Box>
      )}
    </>
  );
};
