export const kubernetsNoAutoUpdate = (environmentId, agentToken) => {
  return `
apiVersion: v1
kind: Namespace
metadata:
  name: elvenworks
---
apiVersion: apps/v1
kind: Deployment
metadata:
  name: 1p-agent
  namespace: elvenworks
spec:
  selector:
    matchLabels:
      app: 1p-agent
  replicas: 1
  template:
    metadata:
      labels:
        app: 1p-agent
    spec:
      containers:
        - name: 1p-agent
          image: elvenworks/1p-agent:latest
          imagePullPolicy: Always
          env:
            - name: ENVIRONMENT_ID
              value: "${environmentId}"
            - name: AGENT_TOKEN
              value: "${agentToken}"
          resources:
            limits:
              cpu: 200m
              memory: 200Mi`.trim();
};
