import { Redirect } from 'react-router';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useGetAccounts } from 'hooks/useGetAccounts';

import SignInSelect from './SignInSelect';

const SelectOrg = () => {
  const { data: accounts, fetching: loadingAccounts } = useGetAccounts();

  if (loadingAccounts) return <LoadingOverlay />;

  if (!accounts?.length) {
    return <Redirect to="/new_org" />;
  }

  return <SignInSelect accounts={accounts} />;
};

export default SelectOrg;
