import { decodeString } from 'helpers/encodeData';

const getCloudStartValues = (cloud: any) => {
  switch (cloud?.provider) {
    case 'aws':
      return {
        name: cloud?.name,
        provider: cloud?.provider,
        accessKeyId: decodeString(cloud?.providerCredentials?.accessKeyId),
        secretAccessKey: decodeString(cloud?.providerCredentials?.secretAccessKey)
      };
    case 'azure':
      return {
        name: cloud?.name,
        provider: cloud?.provider,
        clientId: decodeString(cloud?.providerCredentials?.clientId),
        clientSecret: decodeString(cloud?.providerCredentials?.clientSecret),
        tenantId: decodeString(cloud?.providerCredentials?.tenantId),
        subscriptionId: decodeString(cloud?.providerCredentials?.subscriptionId)
      };
    case 'custom':
      return {
        name: cloud?.name,
        provider: cloud?.provider
      };
    default:
      return {};
  }
};

export default getCloudStartValues;
