import { useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useQuery } from 'urql';
import * as z from 'zod';

import ButtonSimple from 'componentsV3/ButtonSimple';
import SelectWithStatus from 'componentsV3/SelectWithStatus';
import useQueryParams from 'helpers/useQueryParams';
import { analyticsEvent } from 'services/firebase/analytics';

const schema = z.object({
  cloudId: z.string().nonempty({ message: 'You must select a cloud' })
});

const GetCloudsQuery = `#graphql
  query clouds {
    clouds(page: 1, per_page: 1000, sort: "name,ASC") {
      data {
        id
        name
        provider
      }
    }
  }
`;

const useCloudsQuery = () => {
  return useQuery<{
    clouds: { data: { id: number; name: string; provider: string }[] };
  }>({
    query: GetCloudsQuery
  });
};

const ExistingCloudForm = ({
  onFinish,
  applicationType
}: {
  // onFinish: (
  //   cloudInfo: { id: number; provider: keyof typeof cloudProviders | string } | undefined
  // ) => void;
  onFinish: any;
  applicationType: string;
}) => {
  const theme = useTheme();

  const [result] = useCloudsQuery();

  const { clouds } = result?.data || {};

  const cloudsList = useMemo(() => {
    return clouds?.data
      ? clouds?.data?.map(cloud => ({
          value: String(cloud.id),
          text: cloud.name,
          status: cloud.provider
        }))
      : [];
  }, [clouds]);

  const {
    errors,
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    defaultValues: { cloudId: '' },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const firstAccess = useQueryParams().has('firstAccess');

  return (
    <form
      onSubmit={handleSubmit(formData => {
        onFinish(result?.data?.clouds.data?.find(cloud => cloud.id === Number(formData.cloudId)));
      })}>
      <SelectWithStatus
        control={control}
        defaultLabel="Cloud"
        defaultValue=""
        errors={errors}
        items={cloudsList}
        name="cloudId"
        required
      />

      <ButtonSimple
        style={{ marginTop: theme.spacing(9) }}
        type="submit"
        color="primary"
        text="Go to Environment"
        disabled={!isValid}
        onClick={() => {
          const eventsMap: { [key: string]: string } = {
            internalMultiHttp: firstAccess
              ? 'fa_select_existing_cloud_multi'
              : 'select_existing_cloud_multi'
          };

          if (eventsMap[applicationType]) {
            analyticsEvent(eventsMap[applicationType]);
          }
        }}
      />
    </form>
  );
};

export default ExistingCloudForm;
