import { Typography, Tooltip } from '@material-ui/core';

import { useStyles } from './styles';

export const TooltipHeader = ({ text, children }: { text: string; children: string }) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={`Shows if the resource uptime is > 99.5, between 98 and 99.5, or < 98, in the last ${text}`}>
      <Typography className={classes.cellTitle}>{children}</Typography>
    </Tooltip>
  );
};
