import React from 'react';

import { Grid, Typography, Paper, TextField, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab-old';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  zuruca: {},
  glenio: {
    '& > fieldset': {
      border: 0
    },
    '& > input': {
      padding: '6px 0 7px'
    }
  }
}));

const SearchInput = props => {
  const {
    className,
    style,
    view,
    handleOpen,
    autoCompleteHandleChange,
    placeholder,
    addHandler,
    ...rest
  } = props;
  const { open, options, loading, searchText } = view.autoComplete;

  const classes = useStyles();

  const setOpen = bool => {
    handleOpen(bool);
  };

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Autocomplete
        autoComplete
        className={classes.input}
        disableClearable
        disableOpenOnFocus
        filterOptions={x => x}
        freeSolo
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        includeInputInList
        inputValue={searchText}
        loading={loading}
        onChange={(event, value) => addHandler(value)}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            autoComplete="false"
            fullWidth
            InputProps={{
              ...params.InputProps,
              className: classes.glenio,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            onChange={event => {
              event.persist();
              autoCompleteHandleChange(event.target.value);
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
        renderOption={option => {
          return (
            <Grid alignItems="center" container>
              <Grid item xs>
                <Typography color="textSecondary" variant="body2">
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Paper>
  );
};

export default SearchInput;
