
import { apm } from '@elastic/apm-rum';
import { CreateApplicationMutation } from 'graphqlQueries/createApplication';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import actions from 'redux/actions';
import mapDataToApi from 'views/V3/Products/View/TabSetup/Componets/TabApplicationWebhook/mapDataToApi';

import WebHookForm from './WebHookForm';

const WebHookFormNew = ({ onClose, productId }) => {
  const dispatch = useDispatch();

  const [applicationResult, createApplication] = useMutation(CreateApplicationMutation);

  const { fetching: isCreatingApp, error: appError } = applicationResult;

  const history = useHistory();

  const handleSubmit = async data => {
    const { ...applicationData } = data;

    const mappedData = mapDataToApi({
      ...applicationData,
      productId
    });

    const applicationResponse = await createApplication({
      applicationInput: { ...mappedData, productId }
    });

    if (applicationResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create application' }
      });

      apm.captureError(applicationResponse.error);
      // eslint-disable-next-line no-console
      console.error(applicationResponse.error);

      return;
    }

    const applicationInfo = applicationResponse.data.createApplication;

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Application created successfully'
    });

    history.push(
      `/products/${applicationInfo.productId}/availability/applications/${applicationInfo.id}`
    );
  };
  const isLoading = isCreatingApp;

  const errorMessage = (appError) => {
    if (appError?.graphQLErrors) return appError.graphQLErrors[0]?.message;    
    return null;
  };

  return (
    <WebHookForm
      submitMutation={handleSubmit}
      isLoading={isLoading}
      onClose={onClose}
      errors={{ appError }}
      errorMessage={errorMessage(appError)}
    />
  );
};

export default WebHookFormNew;
