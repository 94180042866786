import actions from '../actions';

const upload1 = (state, action) => {
  if (
    actions.UPLOAD_AVATAR === action.type ||
    actions.PRODUCT_UPLOAD_NEW_AVATAR === action.type ||
    actions.PRODUCT_UPLOAD_AVATAR === action.type
  ) {
    return {
      ...state,
      upload: {
        status: true
      }
    };
  }

  if (
    actions.UPLOAD_AVATAR_SUCCESSFUL === action.type ||
    actions.PRODUCT_UPLOAD_AVATAR_SUCCESSFUL === action.type
  ) {
    return {
      ...state,
      upload: {
        status: false
      }
    };
  }

  if (actions.PRODUCT_UPLOAD_REFRESH === action.type) {
    const products_new = state.products_new;
    const steps = products_new.steps.map(step => ({
      ...step,
      entity: action.payload
    }));

    return {
      ...state,
      upload: {
        status: false
      },
      products_new: {
        ...products_new,
        steps
      }
    };
  }

  return state;
};

// FIXME: identifier "upload" has already been declared or isn't helpful, rename this please!
export default upload1;
