import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

const encodeData = data => {
  if (data.provider === 'aws') {
    return {
      ...data,
      accessKeyId: btoa(data.accessKeyId),
      secretAccessKey: btoa(data.secretAccessKey)
    };
  }
  if (data.provider === 'azure') {
    return {
      ...data,
      clientId: btoa(data.clientId),
      clientSecret: btoa(data.clientSecret),
      tenantId: btoa(data.tenantId),
      subscriptionId: btoa(data.subscriptionId)
    };
  }
  return data;
};

function* prepareSaveEntity(action) {
  try {
    yield put({ ...action, type: actions.SAVE_ENTITY, payload: encodeData(action.payload) });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSaveCloud() {
  yield takeLatest(actions.SAVE_CLOUD_ENTITY, prepareSaveEntity);
}
