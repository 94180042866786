import { PostBodyValues } from 'constants/postBody';

import { adapters } from 'views/ServicesHub/adapters/new';
import { Role } from 'views/ServicesHub/forms/monitoring/database/Oracle';

const baseMonitoring = {
  environmentId: 0,
  name: '',
  interval: 60,
  timeout: 60,
  domain: '',
  domainType: 'userAndPass',
  domainSettings: {}
};

const rabbitmqForm = {
  port: 5672,
  protocol: 'amqp'
};

const memcachedForm = {
  port: 11211
};

const mongodbForm = {
  port: 27017,
  protocol: 'mongodb+srv'
};

const mysqlForm = {
  port: 3306
};

const postgresForm = {
  host: '',
  port: 5432,
  database: '',
  username: '',
  password: '',
  sslMode: ''
};

const oracleForm = {
  port: 1521,
  role: Role['Normal']
};

const elasticForm = {
  port: 9200,
  monitoringType: 'noAuth',
  protocol: 'https'
};

const solrForm = {
  port: 8983
};

const redisForm = {
  databaseRedis: 0,
  maxRetries: 1,
  isCluster: false,
  tls: false
};

const kafkaForm = {
  authentication: false,
  tls: false,
  sasl: false,
  mechanism: 'PLAIN',
  lagTolerance: false
};

const functionsForm = {
  locations: '',
  notInvoke: false
};

const postForm = {
  elvenAgentCloud: 'aws',
  elvenAgentRegion: 'us-east-1',
  postBodyType: 'raw',
  rawType: PostBodyValues['json'],
  skipSslValidation: false,
  tlsRenegotiation: '0'
};

const dynamodbForm = {
  awsRegion: 'us-east-1'
};

const getForm = {
  elvenAgentCloud: 'aws',
  elvenAgentRegion: 'us-east-1',
  skipSslValidation: false,
  tlsRenegotiation: '0'
};

const servicebusForm = {
  monitoringType: 'queue'
};

const sqsForm = {
  awsRegion: 'us-east-1',
  queueSize: 0
};

const commonData = {};

const rules = {
  rules: {
    incident: {
      intervalHits: 60,
      intervalFailures: 120,
      failuresToOpenIncident: 2,
      hitsToCloseIncident: 1,
      type: 'incident',
      severity: 'not-classified',
      teams: [],
      responders: [],
      enable: false
    },
    timeout: {
      timeout: 60,
      type: 'alert',
      severity: 'not-classified',
      teams: [],
      responders: [],
      enable: false
    }
  }
};

const defaultValues = { ...baseMonitoring, ...commonData, ...rules };

export function getDefaultValuesByType(type: keyof typeof adapters) {
  const values: Partial<{ [key in keyof typeof adapters]: any }> = {
    postgres: postgresForm,
    rabbitmq: rabbitmqForm,
    memcached: memcachedForm,
    mongodb: mongodbForm,
    mysql: mysqlForm,
    oracle: oracleForm,
    elastic: elasticForm,
    solr: solrForm,
    redisv2: redisForm,
    kafka: kafkaForm,
    functions: functionsForm,
    post: postForm,
    dynamodb: dynamodbForm,
    get: getForm,
    servicebus: servicebusForm,
    sqs: sqsForm
  };

  return values?.[type] ? { ...values[type], ...defaultValues } : defaultValues;
}
