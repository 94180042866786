
export const jiraLanguageOptions = [
  {
    value: "english",
    label: 'English'
  },
  {
    value: "portuguese",
    label: 'Portuguese (Brazil)'
  },
];
