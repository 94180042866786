import { DefaultValuesServiceItem } from 'types/cms/ServicesHub';
import { Service, ServiceItem } from 'types/cms/ServicesHub';

export function useGetServiceItemByLink(
  link: string,
  servicesDefinitions: Service[] = []
): ServiceItem {
  const service = servicesDefinitions
    .flatMap(service => service.items)
    .find(item => item.link === link) as ServiceItem;

  return { ...DefaultValuesServiceItem(), ...service };
}
