import React from 'react';

import { Box, Typography, Grid, TextField, CircularProgress } from '@material-ui/core';

import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';

import Message from '../Message';

import SecretItem from './SecretItem';

const SecretsList = ({ permissions, result, reexecuteQuery }) => {
  const { data, fetching, error } = result;

  const errorMessage = getErrorMessageFromUrql(error);

  const [filter, setFilter] = React.useState('');

  if (!permissions.list) {
    return <Message>You don't have permission to load secret variables.</Message>;
  }

  if (fetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight={100}>
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return <Typography>Something went wrong: {errorMessage}</Typography>;
  }

  if (!data.secrets.length) {
    return <Message>You don't have secret variables yet.</Message>;
  }

  return (
    <>
      <Box my={4}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search secret variables"
          onChange={event => {
            setFilter(event.target.value);
          }}
          value={filter}
        />
      </Box>
      <Box p={1}>
        <Grid container spacing={3}>
          {data.secrets
            .filter(({ name }) => name.includes(filter))
            .map(secret => {
              return (
                <Grid key={secret.name} item xs={12} md={3}>
                  <SecretItem
                    permissions={permissions}
                    secret={secret}
                    onSecretRemove={() => {
                      reexecuteQuery({ requestPolicy: 'network-only' });
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </>
  );
};

export default SecretsList;
