import { Box, Typography } from '@material-ui/core';

export const Title = ({ title, children }: { title: string; children?: React.ReactNode }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={5}>
      <Typography variant="h4">{title}</Typography>

      {children}
    </Box>
  );
};
