import { ReactNode } from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { DataTableLoading } from './Loading';
import { useStyles } from './styles';
import { TablePagination } from './TablePagination';

type DataTableProps<T> = {
  columns: {
    name: string;
    selector: (row: T) => ReactNode;
  }[];
  data: T[];
  fetching?: boolean;
  title: ReactNode;
} & (
  | {
      pagination?: false;
    }
  | {
      pagination: true;
      onPageChange: (page: number) => void;
      /**
       * The current page. Starts at 0.
       */
      page: number;
      /**
       * Total data count
       */
      total: number;
    }
);

export function DataTable<T>({
  columns,
  data,
  title,
  fetching = false,
  ...props
}: DataTableProps<T>) {
  const classes = useStyles();

  const handlePageChange = (newPage: number) => {
    if (!props.pagination) return;

    props.onPageChange(newPage);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={1}
      variant="outlined"
      className={classes.tableContainer}>
      <Box padding={1} paddingLeft={2} bgcolor="#F6F6F6" borderBottom="1px solid #C2C2C2">
        <Typography variant="body2">{title}</Typography>
      </Box>
      {fetching && <DataTableLoading />}

      {!fetching && (
        <Table size="small" style={{ marginBottom: 'auto' }}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.name}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {columns.map(column => (
                  <TableCell key={column.name} className={classes.tableCell}>
                    {column.selector(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {!data.length && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {props.pagination && (
            <TableFooter>
              <TablePagination
                onPageChange={(_, newPage) => handlePageChange(newPage)}
                count={props.total}
                page={props.page}
              />
            </TableFooter>
          )}
        </Table>
      )}
    </TableContainer>
  );
}
