import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(10),
    [theme.breakpoints.only('lg')]: {
      marginLeft: theme.spacing(4)
    }
  },
  name: {
    whiteSpace: 'nowrap'
  }
}));
