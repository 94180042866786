import Breadcrumb from 'components/Breadcrumb';

import DragAndDropBoxes from '../components/DragAndDropBoxes';

const StatupsPageNew = () => {
  return (
    <>
      <Breadcrumb items={[{ link: `/statuspages`, label: 'Status Pages' }, { label: 'New' }]} />
      <DragAndDropBoxes />
    </>
  );
};

export default StatupsPageNew;
