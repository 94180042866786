import { ComponentType } from 'react';

import { SvgIconProps } from '@material-ui/core';

import { ProvidersEnum } from 'hooks/queriesGraphQL/useGetProvider';
import DiscordIcon from 'icons/DiscordFilled';
import Email from 'icons/Email';
import GoogleChat from 'icons/GoogleChatColored';
import MicrosoftTeams from 'icons/MicrosoftTeams';
import SlackColouredIcon from 'icons/SlackColoured';
import Telegram from 'icons/Telegram';
import Webhook from 'icons/Webhook';

export type NotificationCardProps = {
  title: string;
  description: string;
  icon: ComponentType<SvgIconProps>;
  providerName: ProvidersEnum;
};

export const providerCards: NotificationCardProps[] = [
  {
    title: 'Discord',
    description: 'Integrate with Discord and receive your notifications of alerts and incidents.',
    icon: DiscordIcon,
    providerName: ProvidersEnum.DiscordChannel
  },
  {
    title: 'Slack',
    description:
      'Before connecting as a notification channel in the team, go to Service Hub and join the organization.',
    icon: SlackColouredIcon,
    providerName: ProvidersEnum.Slack
  },
  {
    title: 'Microsoft Teams',
    description:
      'Integrate with Microsoft Teams and receive your notifications of alerts and incidents.',
    icon: MicrosoftTeams,
    providerName: ProvidersEnum.Teams
  },
  {
    title: 'Google Chat',
    description:
      'Integrate with Google Chat and receive your notifications of alerts and incidents.',
    icon: GoogleChat,
    providerName: ProvidersEnum.GoogleChat
  },
  {
    title: 'E-mail group',
    description: 'Receive your notifications of alerts and incidents in your corporate email.',
    icon: Email,
    providerName: ProvidersEnum.Mail
  },
  {
    title: 'Telegram group',
    description: 'Receive your notifications of alerts and incidents in your Telegram group.',
    icon: Telegram,
    providerName: ProvidersEnum.Telegram
  },
  {
    title: 'Webhook',
    description: 'Receive your notifications of alerts and incidents by webhook URL.',
    icon: Webhook,
    providerName: ProvidersEnum.WebHook
  }
];
