import { Resource } from 'types/external/Resource';

import { PingFormData } from 'views/ServicesHub/forms/monitoring/others/Ping';

import { monitoringAdapter } from '../monitoring';

export function pingAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & PingFormData {
  return {
    ...monitoringAdapter(data),
    host: data.domain
  };
}
