import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  label: {
    fontSize: 16,
    marginLeft: 8,
    color: theme.palette.gray[900]
  }
}));
