import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareFetch(axios, action) {
  try {
    const { productId, applicationId, incidentId } = action.params;
    yield axios.post(
      `/products/${productId}/applications/${applicationId}/incidents/${incidentId}/acknowledge`,
      {
        incidentId,
        applicationId
      }
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchIncidentAcknowledge(axios) {
  yield takeLatest(actions.APPLICATION_INCIDENT_ACKNOWLEDGE, prepareFetch, axios);
}
