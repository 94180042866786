import { fork } from 'redux-saga/effects';

import watchNewProductFetch from 'views/Products/New/Availability/redux/sagas/fetch';
import watchNewTeamForProductAvailability from 'views/Products/New/Availability/redux/sagas/newTeam';
import watchSaveProductAvailability from 'views/Products/New/Availability/redux/sagas/save';

import axios from '../axios';

import watchAddAddon from './addons/addAddon';
import watchAddonsAutocomplete from './addons/autocomplete';
import watchAddonFetch from './addons/fetch';
import watchAddonsSettingsFetch from './addons/fetchSettings';
import watchProductApplicationsDeploy from './applications/deploy';
import watchProductApplicationEditFetch from './applications/edit';
import watchProductApplicationFetch from './applications/fetch';
import watchProductApplicationSettingsFetch from './applications/fetchSettings';
import watchProductApplicationsMonitoring from './applications/monitoring';
import watchProductApplicationSave from './applications/save';
import watchProductApplicationViewFetch from './applications/view';
import watchLogged from './auth/logged';
import wathLoginWithPassword from './auth/loginWithPassword';
import watchRecovery from './auth/recovery';
import watchReset from './auth/reset';
import watchResetSubmit from './auth/resetSubmit';
import watchSignup from './auth/signup';
import watchValidateEmail from './auth/validateEmail';
import watchApplicationAvailabilityEditFetch from './availability/edit';
import watchApplicationAvailabilityViewFetch from './availability/fetch';
import watchApplicationAvailabilityStatusTimer from './availability/status';
import watchEditCloud from './cloud/edit';
import watchSaveCloud from './cloud/save';
import watchEdit from './crud/edit';
import watchList from './crud/list';
import watchRemove from './crud/remove';
import watchSave from './crud/save';
import watchEnvironmentClustersFetch from './environment/clusters';
import watchEnvironmentFetch from './environment/fetch';
import watchEnvironmentsFetch from './environments/fetch';
import watchIncidentAcknowledge from './incident/acknowledge';
import watchPrepareFetchEntities from './incident/fetch';
import watchSaveIncidentInformations from './incident/save';
import watchMemberAutocomplete from './members/autocomplete';
import watchMemberFetch from './members/fetch';
import watchMemberSave from './members/save';
import watchgoBack from './navigation/goBack';
import watchProductApplicationsFetch from './products/applications';
import watchProductEdit from './products/edit';
import watchProductFetch from './products/fetch';
import watchProductNew from './products/new';
import watchProductServicesFetch from './products/services';
import watchProductsAddService from './products/services/addAccount';
import watchProductsServicesAutocomplete from './products/services/autocomplete';
import watchProductsRemoveService from './products/services/removeAccount';
import watchProductTeamsFetch from './products/teams';
import watchProductsAddTeam from './products/teams/addAccount';
import watchProductsTeamsAutocomplete from './products/teams/autocomplete';
import watchProductsRemoveTeam from './products/teams/removeAccount';
import watchProductUpload from './products/upload';
import watchProductUploadNew from './products/uploadNew';
import watchDeprovisioning from './provision/deprovisioning';
import watchProvisioning from './provision/provisioning';
import watchServiceFetch from './services/fetch';
import watchStatusPageApplicationViewFetch from './statusPage/fetch';
import watchStatusPageApplicationListFetch from './statusPage/matrix';
import watchTeamsAutocomplete from './teams/autocomplete';
import watchTeamsFetchEntity from './teams/list';
import watchCep from './user/cep';
import watchUserSecurity from './user/security';
import watchUserUpload from './user/upload';
import watchUsersFetch from './users/fetch';

export default function* rootSaga(action) {
  yield fork(watchgoBack, axios);
  yield fork(watchList, axios);
  yield fork(watchSave, axios);
  yield fork(watchEdit, axios);
  yield fork(watchRemove, axios);

  yield fork(watchSaveCloud);
  yield fork(watchEditCloud, axios);

  yield fork(watchSignup, axios);
  yield fork(watchRecovery, axios);
  yield fork(watchResetSubmit, axios);
  yield fork(watchReset);
  yield fork(wathLoginWithPassword, axios);
  yield fork(watchLogged, axios);
  yield fork(watchUserUpload, axios);
  yield fork(watchCep);
  yield fork(watchUserSecurity, axios);
  yield fork(watchValidateEmail, axios);

  yield fork(watchTeamsFetchEntity, axios);
  yield fork(watchTeamsAutocomplete, axios);

  yield fork(watchUsersFetch, axios);

  yield fork(watchMemberFetch, axios);
  yield fork(watchMemberAutocomplete, axios);
  yield fork(watchMemberSave, axios);

  yield fork(watchEnvironmentsFetch, axios);
  yield fork(watchEnvironmentFetch, axios);
  yield fork(watchEnvironmentClustersFetch, axios);

  yield fork(watchProductUpload, axios);
  yield fork(watchProductUploadNew, axios);
  yield fork(watchProductFetch, axios);
  yield fork(watchProductEdit, axios);
  yield fork(watchProductApplicationsFetch, axios);
  yield fork(watchProductServicesFetch, axios);
  yield fork(watchProductTeamsFetch, axios);
  yield fork(watchProductNew, axios);

  yield fork(watchProductApplicationSave, axios);
  yield fork(watchProductApplicationFetch, axios);
  yield fork(watchProductApplicationSettingsFetch, axios);
  yield fork(watchProductApplicationEditFetch, axios);
  yield fork(watchProductApplicationViewFetch, axios);
  yield fork(watchProductApplicationsDeploy, axios);
  yield fork(watchProductApplicationsMonitoring, axios);

  yield fork(watchApplicationAvailabilityViewFetch, axios);
  yield fork(watchApplicationAvailabilityStatusTimer, axios);
  yield fork(watchApplicationAvailabilityEditFetch, axios);
  yield fork(watchIncidentAcknowledge, axios);
  yield fork(watchSaveIncidentInformations, axios);

  yield fork(watchStatusPageApplicationViewFetch, axios);
  yield fork(watchStatusPageApplicationListFetch, axios);

  yield fork(watchProductsServicesAutocomplete, axios);
  yield fork(watchProductsAddService, axios);
  yield fork(watchProductsRemoveService, axios);

  yield fork(watchProductsTeamsAutocomplete, axios);
  yield fork(watchProductsAddTeam, axios);
  yield fork(watchProductsRemoveTeam, axios);

  yield fork(watchAddonFetch, axios);
  yield fork(watchAddonsAutocomplete, axios);
  yield fork(watchAddAddon, axios);
  yield fork(watchAddonsSettingsFetch, axios);

  yield fork(watchProvisioning, axios);
  yield fork(watchDeprovisioning, axios);

  yield fork(watchServiceFetch, axios);

  yield fork(watchPrepareFetchEntities, axios);

  yield fork(watchNewProductFetch, axios);
  yield fork(watchSaveProductAvailability, axios);
  yield fork(watchNewTeamForProductAvailability, axios);
}
