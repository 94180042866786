import { Role } from 'types/external/Role';
import { useQuery } from 'urql';

const GetRoleQuery = `#graphql
  query {
    roles {
      id
      name
    }
  }
`;

export const useRoles = ({ pause = false }: { pause?: boolean }) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery<{ roles: Role[] }>({
    query: GetRoleQuery,
    pause
  });

  return {
    fetching,
    data: data?.roles || [],
    reexecuteQuery
  };
};
