import { useMutation } from 'urql';

const UpdateTicketMutation = `#graphql
  mutation($data: UpdateTicketInput!) {
    updateTicket(data: $data) {
      message
      success
    }
  }`;

type UpdateTicketResponse = {
  updateTicket: { message: string; success: boolean };
};

type UpdateTicketVariables = {
  data: {
    title: string;
    description: string;
    state: string;
    incidentId: number;
    integrationUid: string;
  };
};

export const useUpdateTicket = () => {
  const [{ data, fetching }, updateTicket] = useMutation<
    UpdateTicketResponse,
    UpdateTicketVariables
  >(UpdateTicketMutation);

  const success = data?.updateTicket.success;

  return { updateTicket, fetching, success };
};
