import { FormControl, InputLabel, Select as MuiSelect, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller } from 'react-hook-form';

const Select = ({ control, errors, label, name, children, required, ...otherProps }) => {
  const errorMessage = errors && errors[name]?.message;

  return (
    <FormControl
      onClick={e => e.stopPropagation()}
      fullWidth
      size="small"
      required={required}
      variant="outlined"
      margin="dense"
      {...otherProps}>
      <InputLabel>{label}</InputLabel>
      <Controller
        as={
          <MuiSelect
            label={label}
            id={name}
            IconComponent={ExpandMoreIcon}
            error={Boolean(errorMessage)}>
            {children || ''}
          </MuiSelect>
        }
        name={name}
        control={control}
      />
      {errorMessage && (
        <FormHelperText error variant="outlined">
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
