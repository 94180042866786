import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function Markdown(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M18.5577 3.75H1.4423C0.647012 3.75 0 4.39701 0 5.1923V14.8077C0 15.603 0.647012 16.25 1.4423 16.25H18.5577C19.353 16.25 20 15.603 20 14.8077V5.1923C20 4.39701 19.353 3.75 18.5577 3.75ZM11.25 13.7476L8.75 13.75V10L6.875 12.4038L5 10V13.75H2.5V6.25H5L6.875 8.75L8.75 6.25L11.25 6.24756V13.7476ZM14.9822 14.3726L11.875 10H13.75V6.25H16.25V10H18.125L14.9822 14.3726Z"
        fill="#263338"
      />
    </SvgIcon>
  );
}
