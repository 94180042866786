import { ReactNode } from 'react';

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useTheme,
  Typography,
  DialogContentText,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'components/Button';

import { useStyles } from './styles';
import { Action } from './types';

type Props = DialogProps & {
  open: boolean;
  title: ReactNode;
  description?: ReactNode;
  onClose: () => void;
  onSubmit?: () => void;
  showCloseButton?: boolean;
  children: ReactNode;
  actions: Action[];
};

const Dialog = ({
  open,
  title,
  onClose,
  showCloseButton = false,
  children,
  actions,
  description,
  onSubmit,
  ...props
}: Props) => {
  const theme = useTheme();

  const classes = useStyles();

  return (
    <MuiDialog open={open} onClose={onClose} {...props}>
      <Box
        display="flex"
        flexDirection="column"
        onSubmit={onSubmit}
        component={onSubmit ? 'form' : 'div'}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>

          {showCloseButton && (
            <IconButton
              style={{ marginTop: -12, marginRight: -12, marginLeft: theme.spacing(2) }}
              aria-label="close"
              onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>

        <DialogContent>
          {description && (
            <DialogContentText>
              <Typography className={classes.description}>{description}</Typography>
            </DialogContentText>
          )}

          {children}
        </DialogContent>

        <DialogActions
          style={{
            padding: `${theme.spacing(3)}px 20px ${theme.spacing(4)}px`
          }}>
          {actions.map(({ action, label, ...props }, i) => (
            <Button
              key={i}
              size="medium"
              variant="contained"
              startIcon={null}
              onClick={action}
              {...props}>
              {label}
            </Button>
          ))}
        </DialogActions>
      </Box>
    </MuiDialog>
  );
};

export default Dialog;
