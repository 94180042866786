import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

const GetAvgLatencyQuery = `#graphql
  query(
    $startDate: String!
    $endDate: String!
  ){
    insights{
      monitoringPerformance {
        avgLatency(startDate: $startDate, endDate: $endDate){
            period
            compared
        }
      }
    }
  }
`;

export const AvgLatencyMetrics = () => {
  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetAvgLatencyQuery,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const avgLatency = data?.insights?.monitoringPerformance?.avgLatency;

  return (
    <Metrics
      name="Avg Hour Latency"
      value={avgLatency?.period}
      valueFormat={MetricsValueFormatEnum.Milliseconds}
      tooltipTitle="Average response time (latency) in monitoring."
      isFetching={fetching}
      diff={avgLatency?.compared}
      diffFormat={MetricsValueFormatEnum.Percentile}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
