import { isCompanyEmail } from 'company-email-validator';
import { z } from 'zod';

export const createUserSchema = z
  .object({
    users: z.array(
      z.object({
        id: z.string().optional(),
        email: z.string().email({ message: 'Please enter a valid email address' }),
        name: z.string().min(3, { message: 'Please enter a name with at least 3 characters.' }),
        role: z.number().optional()
      })
    )
  })
  .superRefine((data, ctx) => {
    const emailsList: string[] = [];

    data.users.forEach((user, i) => {
      if (emailsList.includes(user.email)) {
        ctx.addIssue({
          path: [`users.${i}.email`],
          message: 'Please, provide a unique email address for each user.',
          code: z.ZodIssueCode.custom
        });
      }
      emailsList.push(user.email);

      if (!isCompanyEmail(user.email)) {
        ctx.addIssue({
          path: [`users.${i}.email`],
          message: 'Please, use a business email address.',
          code: z.ZodIssueCode.custom
        });
      }
    });
  });

export const editUserSchema = z.object({
  users: z.array(
    z.object({
      id: z.string().optional(),
      role: z.number().optional(),
      status: z.string().optional()
    })
  )
});
