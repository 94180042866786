import { Resource } from 'types/external/Resource';

import { objectToKeyValueList } from 'helpers/getObjectList';
import { GetFormData } from 'views/ServicesHub/forms/monitoring/http/Get';

import { monitoringAdapter } from '../monitoring';

export function getAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & GetFormData {
  return {
    ...monitoringAdapter(data),
    url: data.domain,
    skipSslValidation: data.domainSettings.skipSslValidation
      ? data.domainSettings.skipSslValidation
      : false,
    tlsRenegotiation: data.domainSettings.tlsRenegotiation,
    headers: prepareHeader(data.domainSettings.headers),
    elvenAgentCloud: data.domainSettings.poolConfig
      ? data.domainSettings.poolConfig.cloud
      : undefined,
    elvenAgentRegion: data.domainSettings.poolConfig
      ? data.domainSettings.poolConfig.region
      : undefined,
    validationString: data.domainSettings.validationString
  };
}

const prepareHeader = (headers: { key: string; value: string }[]) => {
  if (!headers) return [];
  return objectToKeyValueList(headers);
};
