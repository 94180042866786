import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    marginLeft: theme.spacing(2),
    minWidth: 56,
    height: 42,
    padding: theme.spacing(1),
    '& span': {
      width: 'fit-content'
    },
    '& svg': {
      width: '1.5rem',
      height: '1.5rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[100]
    }
  },
  tooltip: {
    backgroundColor: theme.palette.gray[700],
    marginBottom: theme.spacing(3),
    top: theme.spacing(-1)
  },
  tooltipArrow: {
    color: theme.palette.gray[700]
  }
}));
