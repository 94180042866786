import { Link } from '@material-ui/core';

import Dialog from 'components/Dialog';

import FormWithTextFieldInput from '../../GenericForms/FormWithTextFieldInput';
import { Props as GenericFormsProps } from '../../GenericForms/types';
import { PropsEdit } from '../types';

import { TelegramInstructions } from './components/TelegramInstructions';

const staticsContents = {
  default: {
    description: (
      <>
        To update your phone number, please contact our{' '}
        <Link about="_blank" href="/suporte">
          <strong>support</strong>
        </Link>{' '}
        team, and we'll handle it for you.
      </>
    ),
    title: 'Edit your number',
    Form: () => null
  },
  telegram: {
    title: 'Enter your Telegram ID to receive notifications  from our platform.',
    description: <TelegramInstructions />,
    inputLabel: 'Telegram ID',
    Form: (props: GenericFormsProps) => <FormWithTextFieldInput {...props} />
  },
  email: {
    title: 'Edit your email',
    description: 'Enter your email address to receive notifications from our platform.',
    inputLabel: 'Email',
    Form: (props: GenericFormsProps) => <FormWithTextFieldInput {...props} />
  }
};

export const EditMethodDialog = ({ onClose, form, onSubmit, currentContact, type }: PropsEdit) => {
  const isCreating = !currentContact.verified && !currentContact.value;

  const staticContent = staticsContents[type] || staticsContents['default'];

  return (
    <Dialog
      onSubmit={onSubmit}
      open={true}
      onClose={onClose}
      title={staticContent.title}
      description={staticContent.description}
      actions={[
        {
          action: () => onClose(),
          label: 'Cancel',
          startIcon: null,
          variant: 'outlined'
        },
        {
          type: 'submit',
          disabled: !currentContact.editable,
          label: isCreating ? 'Advance' : 'Save',
          startIcon: null,
          variant: 'contained'
        }
      ]}>
      <staticContent.Form {...staticContent} type={type} form={form} />
    </Dialog>
  );
};
