import { Ref } from 'react';

import { FormControlLabel, FormGroup, CheckboxProps } from '@material-ui/core';
import { Checkbox, Box } from '@material-ui/core';

import HelperIcon from 'componentsV3/HelperIcon';

interface Props extends CheckboxProps {
  label: string;
  name: string;
  tooltip?: string;
  inputRef?: Ref<HTMLInputElement> | undefined;
  defaultChecked?: boolean;
}

function StyledCheckbox(props: CheckboxProps) {
  return <Checkbox disableRipple color="primary" {...props} />;
}

const CheckBox = ({ label, name, tooltip, inputRef, defaultChecked = false, ...props }: Props) => {
  return (
    <FormGroup>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          style={{ marginRight: 0, marginLeft: -8 }}
          name={name}
          control={
            <StyledCheckbox
              inputRef={inputRef}
              name={name}
              defaultChecked={defaultChecked}
              {...props}
            />
          }
          label={label}
        />
        {tooltip && <HelperIcon tooltipText={2} />}
      </Box>
    </FormGroup>
  );
};

export default CheckBox;
