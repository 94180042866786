import { postBodyTypeValues } from 'constants/postBody';

import keyValuesToObject from 'helpers/keyValuesToObject';

const formatPreRequestPayload = formData => {
  const pre_postBodyType =
    formData.post_body_type !== 'raw'
      ? formData.pre_post_body_type
      : formData.pre_post_body_raw_type;

  const pre_postBodyRaw = formData.pre_post_body_raw;

  const pre_postBodyXFormUrlEncoded = keyValuesToObject(formData.pre_post_body_urlencoded || []);

  const pre_postBodyForSelectedType =
    pre_postBodyType === postBodyTypeValues.urlencoded
      ? pre_postBodyXFormUrlEncoded
      : pre_postBodyRaw;

  return {
    name: formData.pre_name,
    domain: formData.pre_healthcheckUrl.trim(),
    method: formData.pre_method,
    check_type: 'http',
    timeout: Number(formData.timeout),
    check_interval: Number(formData.check_interval),
    failuresToIncident: Number(formData.failuresToIncident),
    headers: keyValuesToObject(formData.pre_headers),
    post_body: pre_postBodyForSelectedType,
    post_body_type: pre_postBodyType,
    skip_ssl_validation: formData.pre_skip_ssl_validation,
    validationString: formData.pre_validationString,
    domain_settings: JSON.stringify({ tls_renegotiation: Number(formData.pre_tls_renegotiation) }),
    openIncidentWithSeverity: formData?.openIncidentWithSeverity
  };
};

const formatPosRequestPayload = formData => {
  const pos_postBodyType =
    formData.post_body_type !== 'raw'
      ? formData.pos_post_body_type
      : formData.pos_post_body_raw_type;

  const pos_postBodyRaw = formData.pos_post_body_raw;

  const pos_postBodyXFormUrlEncoded = keyValuesToObject(formData.pos_post_body_urlencoded || []);

  const pos_postBodyForSelectedType =
    pos_postBodyType === postBodyTypeValues.urlencoded
      ? pos_postBodyXFormUrlEncoded
      : pos_postBodyRaw;

  return {
    healthcheckUrl: formData.pos_healthcheckUrl.trim(),
    method: formData.pos_method,
    headers: keyValuesToObject(formData.pos_headers),
    validationString: formData.pos_validationString,
    timeout: Number(formData.timeout),
    check_interval: Number(formData.check_interval),
    failuresToIncident: Number(formData.failuresToIncident),
    post_body: pos_postBodyForSelectedType,
    post_body_type: pos_postBodyType,
    skip_ssl_validation: formData.pos_skip_ssl_validation,
    domain_settings: JSON.stringify({ tls_renegotiation: Number(formData.pos_tls_renegotiation) }),
    p50xp90_anomaly_percent: null,
    p50xp95_anomaly_percent: null,
    openIncidentWithSeverity: formData?.openIncidentWithSeverity
  };
};

const mapMultiHttp = ({ formData }) => {
  const prePayload = formatPreRequestPayload(formData);
  const posPayload = formatPosRequestPayload(formData);
  const addonPayload = formatPreRequestPayload(formData);

  delete addonPayload.name;
  delete addonPayload.check_type;

  return {
    pre: prePayload,
    pos: posPayload,
    addonSettings: addonPayload
  };
};

export default mapMultiHttp;
