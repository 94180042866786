import { SearchParams } from 'types/external/Common';
import { useQuery } from 'urql';

type UseApplicationsParams = {
  page: number;
  perPage: number;
  query: string;
  pause?: boolean;
} & Pick<
  SearchParams,
  'functions' | 'ignoreDate' | 'maxBoost' | 'scoreMode' | 'boostMode' | 'minScore' | 'sortKey'
>;

type Applications = {
  id: number;
  uid: string;
  name: string;
  productId?: number;
  entityUid: string;
  entity: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
};

type UseApplicationsData = {
  data: Applications[];
  total: number;
};

const GetApplicationsQuery = `#graphql
    query(
        $from: Int!,
        $size: Int!,
        $query: String!
        $ignoreDate: String
        $functions: [SearchFunction!]
        $maxBoost: Float
        $scoreMode: String
        $boostMode: String
        $minScore: Float
    ) {
    applicationsV2(
      from: $from
      size: $size
      query: $query
      ignoreDate: $ignoreDate
      functions: $functions
      maxBoost: $maxBoost
      scoreMode: $scoreMode
      boostMode: $boostMode
      minScore: $minScore
    ) {
      data {
        id
        uid
        name
        productId
        entityUid
        entity
        main {
          name
          serviceId
          status
        }
      }
      total
    }
  }
`;

export function useApplications({
  page,
  perPage: size,
  query,
  pause,
  ...otherParams
}: UseApplicationsParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ applicationsV2: UseApplicationsData }>({
    query: GetApplicationsQuery,
    pause: pause,
    variables: {
      from,
      size,
      query,
      ...otherParams
    }
  });
}
