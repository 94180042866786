import actions from '../actions';

const msg = {
  'auth/email-already-exists': 'Email already exists',
  'auth/email-already-in-use':
    'Email is already in use, enter the password correctly to create a new organization with that same email',
  'auth/weak-password': 'Weak password, must be at least 6 characters',
  'auth/invalid-password': 'must be at least 6 characters',
  'auth/wrong-password': 'Email is already registered and the password is invalid',
  'auth/user-not-found': 'Email not found',
  'auth/too-many-requests':
    'We have blocked all orders for this device due to unusual activity. Try later.',
  'auth/invalid-action-code':
    'The action code is invalid. This can happen if the code is malformed, expired or has already been used',
  23505: 'There was a problem with your account, this user already seems to exist, please contact the administrators'
};

const signUpValidation = (state, action) => {
  if (actions.SIGN_UP_FAILED === action.type) {
    if (action.payload.code) {
      return {
        ...state,
        signUpError: msg[action.payload.code]
      };
    }
    if (action.payload.response) {
      return {
        ...state,
        signUpError: msg[action.payload.response.data.errors.code]
      };
    }
  }
  return state;
};

export default signUpValidation;
