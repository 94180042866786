import { takeLatest } from 'redux-saga/effects';

import actions from '../../actions';

function* goBack(action) {
  yield action.payload.goBack();
}

export default function* watchgoBack() {
  yield takeLatest(actions.GO_BACK, goBack);
}
