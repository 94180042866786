import React from 'react';

import { Grid, Box, Typography, TextField, Link, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import CheckboxField from './CheckboxField';

const useStyles = makeStyles(theme => ({
  root: {},
  '@keyframes blink': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  slackLink: {
    animation: '$blink 1s linear 0.3s 3',
    fontWeight: 'bold'
  }
}));

const SlackField = ({ control, field, index, register, watch, teamId, setValue, disabled }) => {
  const classes = useStyles();

  const enabled = watch(`notificationSetup[${index}].enabled`);

  const watchSlackWorkspace = watch(`notificationSetup[${index}].config.slackWorkspace`);
  const watchSlackChannel = watch(`notificationSetup[${index}].config.slackChannel`);

  const slackPopupLink = `https://slack.com/oauth/v2/authorize?client_id=${process.env['REACT_APP_SLACK_CLIENT_ID_1P']}&scope=incoming-webhook,chat:write.public,channels:manage,chat:write&user_scope=&redirect_uri=${window.location.protocol}//${window.location.host}/teams/${teamId}/slack-confirmation`;
 
  const focusEventListener = React.useCallback(() => {
    const slackIntegrationString = sessionStorage.getItem('slackIntegration');
    const slackIntegration = slackIntegrationString ? JSON.parse(slackIntegrationString) : null;

    if (slackIntegration) {
      setValue(`notificationSetup[${index}].config.slackIntegration`, slackIntegrationString, {
        shouldDirty: true
      });
      setValue(`notificationSetup[${index}].config.slackWorkspace`, slackIntegration.team?.name);
      setValue(
        `notificationSetup[${index}].config.slackChannel`,
        slackIntegration.incoming_webhook?.channel
      );
      setValue(`notificationSetup[${index}].enabled`, true);
      sessionStorage.removeItem('slackIntegration');
    }
  }, [index, setValue]);

  React.useEffect(() => {
    window.addEventListener('focus', focusEventListener);

    return () => {
      window.removeEventListener('focus', focusEventListener);
    };
  }, [focusEventListener]);

  const SlackInstall = ({ children }) => (
    <Link
      href={slackPopupLink}
      className={enabled && (!watchSlackWorkspace || !watchSlackChannel) ? classes.slackLink : null}
      target="popup"
      onClick={() => {
        window.open(slackPopupLink, 'popup', 'width=600,height=600');
        return false;
      }}>
      {children}
    </Link>
  );

  return (
    <Grid xs={12} item style={{ margin: '5px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="Slack"
      />
      <Controller
        name={`notificationSetup[${index}].config.slackIntegration`}
        control={control}
        defaultValue={JSON.stringify(field.config.slackIntegration)}
        render={props => <input ref={props.ref} type="hidden" value={props.value} />}
      />
      <CheckboxField
        name={`notificationSetup[${index}].enabled`}
        disabled={disabled}
        defaultValue={field.enabled}
        control={control}
        setValue={setValue}>
        <img alt="slack" src="/images/slack.svg" height={22} />
        <Box margin="0 10px" minWidth="20vw">
          <Typography>
            Send a notification to Slack{' '}
            {field.config.slackWorkspace && field.config.slackChannel && (
              <>
                (change workspace and/or channel by{' '}
                <SlackInstall>reinstalling our slack app</SlackInstall>)
              </>
            )}
            {(!field.config.slackWorkspace || !field.config.slackChannel) && (
              <>
                <SlackInstall>installing our Slack App</SlackInstall> first
              </>
            )}
          </Typography>
        </Box>

        <Controller
          id="slack-workspace"
          name={`notificationSetup[${index}].config.slackWorkspace`}
          control={control}
          defaultValue={field.config.slackWorkspace}
          render={({ ref, value, ...otherProps }) => (
            <TextField
              label="Slack workspace"
              disabled={disabled}
              variant="outlined"
              margin="dense"
              style={{ minWidth: '15vw' }}
              value={value || ''}
              inputRef={ref}
              {...otherProps}
            />
          )}
        />

        <Box margin="0 10px">
          <Typography>on</Typography>
        </Box>

        <Controller
          id={field.id}
          name={`notificationSetup[${index}].config.slackChannel`}
          control={control}
          defaultValue={field.config.slackChannel}
          render={({ ref, value, ...otherProps }) => (
            <TextField
              label="Slack channel"
              variant="outlined"
              margin="dense"
              disabled={disabled}
              style={{ minWidth: '15vw' }}
              value={value || ''}
              inputRef={ref}
              {...otherProps}
            />
          )}
        />
      </CheckboxField>
    </Grid>
  );
};

export default SlackField;
