import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function GoogleChatColored(props: SvgIconProps) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0.00 0.00 311.00 320.00" {...props}>
      <g strokeWidth={2} fill="none">
        <path stroke="#7e916f" vectorEffect="non-scaling-stroke" d="M76.37.51l.01 76.47" />
        <path stroke="#1375eb" vectorEffect="non-scaling-stroke" d="M76.38 76.98L0 76.96" />
        <path
          stroke="#f3801d"
          vectorEffect="non-scaling-stroke"
          d="M235.08 1.09q-.16.06-.27.13-.17.09-.17.28l-.02 75.51"
        />
        <path stroke="#7eb426" vectorEffect="non-scaling-stroke" d="M234.62 77.01h-.05" />
        <path stroke="#91a080" vectorEffect="non-scaling-stroke" d="M76.41 77.01l-.03-.03" />
        <path stroke="#75783e" vectorEffect="non-scaling-stroke" d="M310.53 76.77l-75.91.24" />
        <path stroke="#138495" vectorEffect="non-scaling-stroke" d="M76.43 182.69L0 182.67" />
        <path stroke="#00983a" vectorEffect="non-scaling-stroke" d="M76.44 259.13l-.01-38.28" />
      </g>
      <path
        fill="#0066da"
        d="M76.37.51l.01 76.47L0 76.96V20.77q.85-5.96 3.53-10.01Q10.14.74 22.75.67 49.41.53 76.37.51z"
      />
      <path
        fill="#fbbc04"
        d="M76.37.51l157.42.02a1.61 1.57-26.7 01.92.29l.37.27q-.16.06-.27.13-.17.09-.17.28l-.02 75.51H76.41l-.03-.03L76.37.51z"
      />
      <path
        fill="#ea4335"
        d="M235.08 1.09l75.45 75.68-75.91.24.02-75.51q0-.19.17-.28.11-.07.27-.13z"
      />
      <path fill="#2684fc" d="M0 76.96l76.38.02.03.03.02 105.68L0 182.67V76.96z" />
      <path
        fill="#00ac47"
        d="M310.53 76.77l.47.34v161.9q-2.66 14.53-15.06 18.77-4.42 1.52-13.03 1.5-55.89-.09-112.92-.17-8.28-.01-16.8.12-.47.01-.8.34-27.9 27.77-56 56.02c-2.87 2.89-6.12 4.5-10.24 3.89q-5.76-.85-8.49-5.94-1.15-2.16-1.17-7.88-.07-23.19-.05-46.53l-.01-38.28 37.78-37.78a1.79 1.77 22.3 011.26-.52l118.3.04a.83.83 0 00.83-.83l-.03-104.75h.05l75.91-.24z"
      />
      <path
        fill="#00832d"
        d="M76.43 182.69v38.16l.01 38.28q-23.97.14-47.53.09-9.82-.02-14.15-1.54Q2.62 253.44 0 238.88v-56.21l76.43.02z"
      />
    </SvgIcon>
  );
}
