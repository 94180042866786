import { Skeleton as MuiSkeleton, SkeletonProps } from '@material-ui/lab';

export function Skeleton(props: SkeletonProps) {
  return (
    <MuiSkeleton
      {...props}
      style={{
        transform: 'none'
      }}
    />
  );
}
