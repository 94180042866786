import { take, put, call, cancel, fork } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/products';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function* prepareFetchEntities(axios, action) {
  while (true) {
    yield call(delay, 60000);
    try {
      const {
        data: { data }
      } = yield axios.get(`/products/${action.payload}/availability`);
      yield put({
        type: actions.MATRIX_FETCH_SUCCESSFUL,
        meta: {
          reducer
        },
        payload: data
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      yield put({
        type: actions.ENTITY_ERROR,
        payload: err,
        ga: { category: 'ERROR', action: action.type }
      });
    }
  }
}

export default function* watchStatusPageApplicationListFetch(axios) {
  while (true) {
    const action = yield take(actions.PRODUCT_STATUS_APPLICATIONS_FETCH);
    const bgSyncTask = yield fork(prepareFetchEntities, axios, action);
    yield take(actions.PRODUCT_STATUS_APPLICATIONS_TIMER_STOP);
    yield cancel(bgSyncTask);
  }
}
