import { useQuery } from 'urql';

const GetMonitorsServiceQuery = `#graphql
query getMonitorsService(
  $page: Float!
  $per_page: Float!
  $sort: String
  $query: String
){
  getMonitorsService(
    page: $page
    per_page: $per_page 
    sort: $sort
    query: $query
  ){
    data{
      id,
      name,
      type,
      uid
    }
  }
}
`;

export const useServiceMonitors = ({
  pause = false,
  query
}: {
  pause?: boolean;
  query?: string;
}) => {
  return useQuery<{
    getMonitorsService: {
      data: {
        id: number;
        uid: string;
        name: string;
        type: string;
      }[];
    };
  }>({
    query: GetMonitorsServiceQuery,
    variables: {
      page: 1,
      per_page: 500,
      sort: 'name,ASC',
      query
    },
    pause
  });
};
