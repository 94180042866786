import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { PostmortemForm } from 'views/Postmortems/Form';
import { PostmortemList } from 'views/Postmortems/List';
import { PostmortemView } from 'views/Postmortems/View';

export const postmortemRoutes = [
  <AuthorizedRoute
    component={PostmortemList}
    exact
    layout={MainLayoutV5}
    path="/postmortems"
    planFeature="IncidentManagement"
  />,
  <AuthorizedRoute
    component={PostmortemForm}
    exact
    layout={MainLayoutV5}
    path="/postmortems/new"
    planFeature="IncidentManagement"
    featureTarget="PostMortemController-post-/post-mortem"
  />,
  <AuthorizedRoute
    component={PostmortemView}
    exact
    layout={MainLayoutV5}
    path="/postmortems/:uid"
    planFeature="IncidentManagement"
  />,
  <AuthorizedRoute
    component={PostmortemForm}
    exact
    layout={MainLayoutV5}
    path="/postmortems/edit/:uid"
    planFeature="IncidentManagement"
    featureTarget="PostMortemController-put-/post-mortem"
  />
];
