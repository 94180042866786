import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  eventsHistoryTitle: {
    fontWeight: theme.typography.fontWeightRegular
  },
  eventsDate: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.gray[700]
  },
  accordion: {
    '& .Mui-disabled': {
      opacity: 1,
      backgroundColor: 'white'
    }
  },
  accordionSummary: {
    '& div': {
      margin: 0
    },
    '& .Mui-expanded': {
      margin: 'auto'
    }
  },
  accordionDetails: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  divider: {
    backgroundColor: theme.palette.gray[100]
  },
  ellipse: {
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  descriptionEllipse: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    backgroundColor: theme.palette.gray[300],
    marginRight: theme.spacing(1)
  },
  eventDescription: {
    marginRight: theme.spacing(1),
    fontSize: '16px'
  },
  eventDate: {
    color: theme.palette.gray[500],
    fontSize: '16px'
  },
  timelineConnector: {
    width: '2px',
    height: '35px',
    backgroundColor: theme.palette.gray[200]
  },
  noIncidentsMessage: {
    color: theme.palette.gray[500],
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },
  noDataText: {
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '32px',
    color: theme.palette.gray[700]
  }
}));
