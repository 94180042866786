import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { TeamList } from './List';
import TeamsList from './TeamsList';

const Teams = () => {
  const newTeamsList = useFeatureFlagUnleash('newApplications', { queryString: true });
  return newTeamsList ? <TeamList /> : <TeamsList />;
};

export default Teams;
