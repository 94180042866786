export const createQuery = `#graphql
      mutation ($teamIds: Float!, $resourceId: Float!){
        createResourceTeam(
          teamIds: [$teamIds],
          resourceId: $resourceId,
          resourceType: "Product"
        ){
          teamId
          resourceId
          resourceType
        }
      }
    `;
