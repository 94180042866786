import React from 'react';

import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Typography,
  IconButton,
  Icon
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const DialogTitle = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(2)
  },
  title: {
    marginRight: theme.spacing(8)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h4">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = MuiDialogContent;

export const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);
