import actions from '../../actions';

const incident = (state, action) => {
  if (actions.INCIDENT_VIEW_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      incident_view: {
        ...state.incident_view,
        ...action.payload
      }
    };
  }

  return state;
};

export default incident;
