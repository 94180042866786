import { ChangeEvent, useState, useEffect } from 'react';

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  ExternalIntegrationFromIndexer,
  ExternalIntegrationStatusEnum,
  ExternalIntegrationsOrigins
} from 'types/external/ExternalService';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import { Title } from 'components/Title';
import { EventsFilters } from 'componentsV4/Filters';
import { PageFilters as NewPageFilters } from 'componentsV4/Filters/Search';
import { customElasticQuery } from 'helpers/customElasticQuery';
import useQueryParams from 'helpers/useQueryParams';
import { useExternalIntegrationsList } from 'hooks/queriesGraphQL/useExternalIntegrationsList';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';

import { getColumns } from './components/columns';

const TYPING_DEBOUNCE_TIME = 500;
const PER_PAGE_OPTIONS = [10, 25, 50];

export function ExternalIntegrationsList() {
  const queryParams = useQueryParams();
  const history = useHistory();

  const shouldShowDeleteExternalIntegrationButton = useFeatureFlagUnleash(
    'showDeleteExternalIntegrationButton',
    {
      queryString: true
    }
  );

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<ExternalIntegrationStatusEnum | null | 'all'>(null);
  const [externalIntegrationsOrigins, setExternalIntegrationsOrigins] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const statusURL = queryParams.get('status');
  const originsURL = queryParams.get('origins');

  const [{ fetching, data, error }, reexecuteQuery] = useExternalIntegrationsList({
    page,
    perPage: perPage,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'name',
          value: search,
          isRegex: true
        },
        {
          key: 'status',
          value: status || 'all',
          isRegex: false
        },
        {
          key: 'origins',
          value: externalIntegrationsOrigins || 'all',
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'external_integration',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true',
    functions: [
      { filter: { term: { status: 'deprecated' } }, weight: 10 },
      { filter: { term: { status: 'outage' } }, weight: 8 },
      { filter: { term: { status: 'pending' } }, weight: 5 },
      { filter: { term: { status: 'available' } }, weight: 2 }
    ],
    sortKey: 'created_at',
    maxBoost: 10,
    scoreMode: 'sum',
    boostMode: 'replace',
    minScore: 1,
    pause: false
  });

  const externalIntegrationsList = data?.getExternalIntegrationsFromIndexer?.data || [];
  const externalIntegrationsTotal = data?.getExternalIntegrationsFromIndexer?.total || 0;

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    statusURL && setStatus(statusURL as ExternalIntegrationStatusEnum);
    originsURL && setExternalIntegrationsOrigins(originsURL.split(','));
  }, [statusURL, originsURL, setStatus, setExternalIntegrationsOrigins]);

  useEffect(() => {
    reexecuteQuery();
  }, [reexecuteQuery]);

  const hasCreateExternalIntegrationPermission = usePermission(
    'ExternalIntegrations-post-/external_integrations_create'
  );

  const dataToDisplay = () => {
    if (!externalIntegrationsList.length && !fetching) return [];

    return externalIntegrationsList.map(integration => ({
      ...integration,
      origin: (integration.origin[0].toUpperCase() +
        integration.origin.substring(1)) as ExternalIntegrationsOrigins
    }));
  };

  const [externalServices, setExternalServices] = useState<ExternalIntegrationFromIndexer[]>(
    dataToDisplay()
  );

  const [deletedItem, setDeletedItem] = useState('');

  useEffect(() => {
    if (!deletedItem.length) {
      return;
    }

    const updatedData = dataToDisplay();

    if (updatedData.some(item => item.uid === deletedItem)) {
      reexecuteQuery();
      return;
    }

    setDeletedItem('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, deletedItem, setDeletedItem, reexecuteQuery]);

  useEffect(() => {
    if (deletedItem.length) {
      return;
    }

    setExternalServices(dataToDisplay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setExternalServices, deletedItem]);

  const ExternalServicesTable = () => {
    if (error) {
      return (
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#000000de',
            fontSize: '16px'
          }}>
          Error on load data
        </Typography>
      );
    }

    const COLUMNS = getColumns({
      showDeleteButton: shouldShowDeleteExternalIntegrationButton,
      externalServices,
      setExternalServices,
      setDeletedItem
    });

    return (
      <>
        {externalServices?.length ? <Divider /> : null}

        <DataTable
          columns={COLUMNS}
          data={externalServices}
          noDataComponent="No results found"
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeSize}
          paginationComponentOptions={{
            rowsPerPageText: 'Items per page:',
            rangeSeparatorText: 'of',
            noRowsPerPage: false
          }}
          paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
          paginationPerPage={perPage}
          paginationTotalRows={externalIntegrationsTotal}
          paginationServer
          pagination
          progressComponent={<CircularProgress color="primary" size="3rem" />}
          progressPending={fetching}
          highlightOnHover
          striped
          pointerOnHover={true}
          onRowClicked={() => {}}
          // onRowClicked={(externalIntegration, event) => {
          //   if (event.ctrlKey) {
          //     return window.open(`applications/${application.uid}`, '_blank');
          //   }
          //   history.push(`applications/${application.uid}`);
          // }}
        />
      </>
    );
  };

  const appliedFilters = () => {
    return {
      status: status ? status : 'all',
      externalIntegrationsOrigins: externalIntegrationsOrigins
    };
  };

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const newHandleApplyFilters = (filters: NewPageFilters) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    setStatus(filters.status as ExternalIntegrationStatusEnum);

    const origins = filters?.externalIntegrationsOrigins?.map(origin => origin.value);

    !filters.externalIntegrationsOrigins?.length
      ? removeQueryParams('origins')
      : addQueryParams('origins', origins?.toString() || '');

    if (filters?.externalIntegrationsOrigins) {
      setExternalIntegrationsOrigins(
        filters.externalIntegrationsOrigins.map((origin: { value: string }) => origin.value)
      );
    }

    setPage(1);
    reexecuteQuery();
  };

  const handleClearFilters = () => {
    removeQueryParams('status');
    removeQueryParams('origins');
    setStatus(null);
    setExternalIntegrationsOrigins([]);
    setPage(1);
  };

  const externalServicesStatusOptions = {
    label: 'Status',
    options: [
      { value: 'all', label: 'All' },
      { value: ExternalIntegrationStatusEnum.Outage, label: 'Outage' },
      { value: ExternalIntegrationStatusEnum.Available, label: 'Operational' },
      { value: ExternalIntegrationStatusEnum.Pending, label: 'Pending' }
    ]
  };

  return (
    <>
      <Breadcrumb
        items={[
          { label: 'Monitoring' },
          { link: `/external-services`, label: 'External Services Center' }
        ]}
      />
      <Title title="External Services Center">
        <Box>
          <Button
            component={Link}
            to="/services-hub#external-services-monitoring"
            title="Create new external service"
            disabled={!hasCreateExternalIntegrationPermission}>
            External Services
          </Button>
        </Box>
      </Title>

      <EventsFilters
        handleChangeSearch={handleChangeSearch}
        onApplyFilters={newHandleApplyFilters}
        onClearFilters={handleClearFilters}
        appliedFilters={appliedFilters}
        hideSeverity={true}
        hideOrigins={true}
        hideExternalIntegrationsOrigins={false}
        statusOptions={externalServicesStatusOptions}
        includePeriod={false}
        includeCustomRange={false}
      />

      {ExternalServicesTable()}
    </>
  );
}
