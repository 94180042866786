import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  paperTop: {
    padding: theme.spacing(2),
    height: '450px'
  },
  paperTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 0, 3)
  }
}));
