import { useState } from 'react';

import { Box, Fade, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFlagsStatus } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router';

import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { useStyles } from './styles';

function ImportantUpdates() {
  const classes = useStyles();
  const isSelectOrg = useLocation().pathname === '/select-org';

  const monitoringJourneyMessageClosed =
    localStorage.getItem('monitoring_journey_message') === 'done';

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const { flagsReady } = useFlagsStatus();

  function handleClose() {
    localStorage.setItem('monitoring_journey_message', 'done');
    setIsClosed(true);
  }

  const [isClosed, setIsClosed] = useState(false);

  const { data: user } = useUser();

  const shouldMonitoringJourneyMessage =
    user &&
    !isSelectOrg &&
    !monitoringJourneyMessageClosed &&
    shouldUseNewApplications &&
    !isClosed &&
    flagsReady;

  return !shouldMonitoringJourneyMessage ? (
    <></>
  ) : (
    <>
      <Fade in timeout={{ enter: 1000 }}>
        <Box className={classes.alert}>
          <Box display="flex" alignItems="center" justifyContent="center" ml={12} mr={12}>
            <Grid className={classes.message}>
              "Hey, {user.displayName}! We have important updates on our monitoring journey. How
              about keeping up and staying informed about the news?
              <a
                href="https://elven.works/press/novidades-one-platfom-nova-jornada-de-monitoria/"
                style={{
                  color: '#5FBC91',
                  margin: '0 6px 0 6px'
                }}
                target="_blank"
                rel="noreferrer">
                <span>
                  <strong>Click here</strong>
                </span>
              </a>
              to find out more!"
            </Grid>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.iconCloseButton}>
              CLOSE
              <CloseIcon fontSize="inherit" className={classes.iconClose} />
            </IconButton>
          </Box>
        </Box>
      </Fade>
    </>
  );
}

export default ImportantUpdates;
