export const kafkaStartValues = ({ method, domain_settings }) => {
  if (method !== 'kafka_advanced') {
    return;
  }

  const domainSettingsObj =
    typeof domain_settings === 'string' ? JSON.parse(domain_settings) : domain_settings;

  const { brokers, consumer, lag, mechanism, user, password, sasl, tls, topic } = domainSettingsObj;

  return {
    brokers,
    consumer,
    lag,
    mechanism: mechanism || '',
    password,
    sasl,
    tls,
    topic,
    user
  };
};
