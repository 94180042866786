import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';
import { useQuery } from 'urql';

const ListSSOIntegrationsQuery = `#graphql
  query ListSSOIntegrations { 
    ssoIntegrations {
      id
      type
      uid
    }
  }
`;

export function useSSOIntegrations() {
  return useQuery<{
    ssoIntegrations: SSOIntegration[];
  }>({
    query: ListSSOIntegrationsQuery
  });
}
