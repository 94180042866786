import React from 'react';

import { Button, Dialog, DialogContentText, Link } from '@material-ui/core';
import { DialogActions, DialogContent, DialogTitle } from 'oldComponents/LegacyDialog';

const GoogleChatIntegrationInstructionsDialog = ({ field }) => {
  const [showInstructions, setShowInstructions] = React.useState(false);

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>
      <Dialog open={showInstructions} aria-labelledby="new-service-dialog" onClose={handleClose}>
        <DialogTitle id="new-service-dialog" onClose={handleClose}>
          Google Chat integration instructions
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant="h5">
            1. Configure incoming webhook and get webhook url
          </DialogContentText>

          <DialogContentText>
            Set a name (e.g. Elven Platform) and image for the connector, then copy the webhook url
            and paste below.
          </DialogContentText>

          {field}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Link
        href="#"
        onClick={() => {
          setShowInstructions(true);
        }}>
        Integration instructions
      </Link>
    </>
  );
};

export default GoogleChatIntegrationInstructionsDialog;
