import validate from 'validate.js';

validate.validators.atLeastOneNotificationChannel = (value, options, key, attributes) => {
  const {
    enabledEmail,
    enabledTelegram,
    enabledSlack,
    enabledTeams,
    enabledWhatsapp,
    enabledGoogleChat
  } = attributes;
  if (
    !enabledEmail &&
    !enabledTelegram &&
    !enabledSlack &&
    !enabledTeams &&
    !enabledWhatsapp &&
    !enabledGoogleChat
  ) {
    return validate.format('^Should select at least one notification channel');
  }
};

validate.validators.presenceIfEnabledChannel = (value, options, key, attributes) => {
  const { enabledKey, fieldKey } = options;
  if (attributes[enabledKey] && !attributes[fieldKey]) {
    return "can't be blank";
  }
};

validate.validators.slackIntegration = (value, options, key, attributes) => {
  if (attributes.enabledSlack && !attributes.slackIntegration) {
    return validate.format('^Install our Slack app');
  }
};

validate.validators.emailIfEnabled = (value, options, key, attributes) => {
  const { enabledEmail, emailGroup } = attributes;
  if (enabledEmail) {
    const errors = validate({ emailGroup }, { emailGroup: { email: true } });
    return errors?.emailGroup[0];
  }
};

const notificationSchema = {
  enabledEmail: {
    atLeastOneNotificationChannel: true
  },

  enabledTelegram: {
    atLeastOneNotificationChannel: true
  },

  enabledSlack: {
    atLeastOneNotificationChannel: true
  },

  enabledTeams: {
    atLeastOneNotificationChannel: true
  },

  enabledWhatsapp: {
    atLeastOneNotificationChannel: true
  },

  enabledGoogleChat: {
    atLeastOneNotificationChannel: true
  },

  emailGroup: {
    presenceIfEnabledChannel: {
      enabledKey: 'enabledEmail',
      fieldKey: 'emailGroup'
    },
    emailIfEnabled: true
  },

  telegramId: {
    presenceIfEnabledChannel: {
      enabledKey: 'enabledTelegram',
      fieldKey: 'telegramId'
    }
  },

  slackWorkspace: {
    slackIntegration: true
  },

  slackChannel: {
    slackIntegration: true
  },

  teamsWebhook: {
    presenceIfEnabledChannel: {
      enabledKey: 'enabledTeams',
      fieldKey: 'teamsWebhook'
    }
  },

  whatsappNumbers: {
    presenceIfEnabledChannel: {
      enabledKey: 'enabledWhatsapp',
      fieldKey: 'whatsappNumbers'
    }
  },

  googleChatWebhook: {
    presenceIfEnabledChannel: {
      enabledKey: 'enabledGoogleChat',
      fieldKey: 'googleChatWebhook'
    }
  }
};

export default notificationSchema;
