export const UpdateStepGroupsMutation = `mutation($updateStepGroupInput: UpdateStepGroupInput!) {
  updateStepGroups(updateStepGroupInput: $updateStepGroupInput) {
      id
      team
      teamIdFromTeams
      name
      steps {
        id
        teamMemberToContact
        memberFromTeams
        contactMethod
        intervalInMinutes
        timeoutInMinutes
        order
      }
    }
  }
`;
