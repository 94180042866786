import { FormControl, FormHelperText, Grid, Icon, IconButton } from '@material-ui/core';
import SecretsField from 'oldComponents/SecretsField';

function ArrayField({
  defaultValues,
  register,
  control,
  watch,
  setValue,
  label,
  fields = [],
  fieldAppend,
  fieldRemove,
  name,
  disabled,
  defaultValue,
  defaultHelperText,
  errors,
  ...otherProps
}) {
  const errorMessage = errors && errors[name]?.message;

  const helperTextMessage = () => {
    if (errorMessage && !defaultHelperText) return errorMessage;
    if (defaultHelperText && !errorMessage) return defaultHelperText;
    if (errorMessage && defaultHelperText)
      return (
        <p>
          {errorMessage} <br /> {defaultHelperText}
        </p>
      );
  };

  return (
    <FormControl fullWidth size="small" variant="outlined" {...otherProps}>
      {fields.map((item, index) => (
        <Grid container spacing={1} key={item.id}>
          <Grid item container sm={10}>
            <SecretsField
              TextFieldProps={{
                label: index === 0 ? label : '',
                name: `${name}.${index}`,
                fullWidth: false,
                style: { flexGrow: 1 }
              }}
              control={control}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
              defaultValue={item.value}
              errors={errors}
            />
          </Grid>

          <Grid item sm={2} container alignItems="center">
            {fields.length > 1 && (
              <IconButton
                color="primary"
                onClick={() => {
                  fieldRemove(index);
                }}
                disabled={disabled}>
                <Icon>delete</Icon>
              </IconButton>
            )}

            {index === fields.length - 1 && (
              <IconButton color="primary" onClick={() => fieldAppend('')} disabled={disabled}>
                <Icon>add_circle</Icon>
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      <FormHelperText error={Boolean(errorMessage)} variant="outlined">
        {helperTextMessage()}
      </FormHelperText>
    </FormControl>
  );
}

export default ArrayField;
