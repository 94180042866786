import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  incidentIdLink: {
    color: theme.palette.primary.main,
    fontSize: '16px'
  }
}));
