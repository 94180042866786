import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { useStyles as useComponentsStyles } from '../styles';

import { useStyles } from './styles';

type MoreDetailsProps = {
  event: EventType;
};

export function MoreDetails({ event }: MoreDetailsProps) {
  const componentsClasses = useComponentsStyles();
  const classes = useStyles();

  switch (event.type) {
    case EventTypeEnum.Alarmed:
    case EventTypeEnum.Acknowledged:
    case EventTypeEnum.Resolved:
      return (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Link
            className={classes.incidentIdLink}
            component={RouterLink}
            to={`/incidents/${event.incidentId}`}
            variant="body1">
            View more details
          </Link>
        </>
      );
  }

  return null;
}
