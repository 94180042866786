import { RedisFormData } from 'views/ServicesHub/forms/monitoring/cache/Redis';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function redisAdapter({ hosts, ...data }: MonitoringCommomData & RedisFormData) {
  const domain = data.domainType !== 'url' ? prepareHostAndPortsList({ hosts }) : data.domain;
  //   ex1: HOST:PORT
  //   ex2 (cluster): HOST1:PORT1,HOST2:PORT2,HOST3:PORT3...
  //   ex3 (cluster): HOSTCONFIG:PORT

  return {
    ...monitoringAdapter({ ...data, method: 'redisV2' }),
    domain,
    domainSettings: {
      ...(data.user ? { user: data.user } : {}),
      ...(data.password ? { password: data.password } : {}),
      database: Number(data.databaseRedis),
      maxRetries: Number(data.maxRetries),
      isCluster: data.isCluster,
      tls: data.tls,
      domainType: data.domainType
    }
  };
}

const prepareHostAndPortsList = ({ hosts }: { hosts: { key: string; value: string }[] }) => {
  const hostsAndPortsList = hosts.map((host, index) => {
    return `${host.key}:${host.value}`;
  });

  return hostsAndPortsList.join(',');
};
