import { Jira, UpdateJiraPayload } from 'types/external/itsmIntegration/jira';

import { JiraData } from 'views/ServicesHub/layouts/ItsmJira';

export function jiraStartValuesAdapter(integration: Jira): JiraData {
  const {
    configuration: { ...configurationProps },
    ...props
  } = integration;

  return {
    ...props,
    ...configurationProps
  };
}

export function updateJiraAdapter(integration: JiraData): UpdateJiraPayload {
  const { subdomain, apiToken, projectKey, username, uid, defaultLanguage, ...props } = integration;

  return {
    ...props,
    origin: 'jira',
    configuration: {
      subdomain,
      apiToken,
      projectKey,
      username,
      defaultLanguage
    }
  };
}
