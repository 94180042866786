import { types } from './types';

function getServiceType(resource) {
  if (resource?.syntheticUid) {
    return 'synthetic';
  }

  if (resource.type === types.external) {
    return 'external';
  }

  return 'resource';
}

const addService = ({ resources, destination, draggableId }) => statusPageComponents =>
  statusPageComponents.map((component, index) => {
    if (index !== Number(destination.droppableId.split('group-')[1])) {
      return component;
    }

    const resource = resources.find(resource => resource.key === Number(draggableId));

    const response = {
      ...component,
      services: [
        ...component.services,
        {
          id: String(resource.monitoringId || resource.sterasId),
          monitoringId: String(resource.monitoringId || resource.sterasId),
          name: resource.name,
          application_id:
            resource.type === types.applicationresourceUid
              ? resource?.id
              : resource.type === types.addon && resource?.applicationDependeny
              ? resource?.applicationId
              : undefined,
          addon_id:
            resource.type === types.addon && !resource?.applicationDependenyId
              ? resource?.id
              : undefined,
          displayEnabled: true,
          use_resource: resource.useResource,
          resource_uid: resource?.resourceUid,
          synthetic_uid: resource?.synthetic_uid,
          type: getServiceType(resource)
        }
      ]
    };

    return response;
  });

export default addService;
