import { JiraData } from 'views/ServicesHub/layouts/ItsmJira';

export function jiraAdapter(integration: JiraData) {
  const { subdomain, projectKey, apiToken, username, uid, defaultLanguage, ...props } = integration;

  return {
    ...props,
    origin: 'jira',
    configuration: {
      subdomain,
      username,
      projectKey,
      apiToken,
      defaultLanguage
    }
  };
}
