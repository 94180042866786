import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { PostgresFormData } from 'views/ServicesHub/forms/monitoring/database/Postgres';

import { monitoringAdapter } from '../monitoring';

export function postgresAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & PostgresFormData {
  const { domain, username, password, host, port, database, sslMode } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    username,
    password,
    host,
    port,
    database,
    sslMode
  };
}

const prepareDomain = (data: Resource) => {
  let username = '';
  let password = '';
  let host = '';
  let port = '';
  let database = '';
  let sslMode = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    const domainURI = new URI(data.domain);

    username = domainURI.username();
    password = domainURI.password();
    host = domainURI.hostname();
    port = domainURI.port();
    database = domainURI.path().replace('/', '');

    if (domainURI.hasQuery('sslmode')) {
      sslMode = domainURI.query(true).sslmode;
    }
  }

  return { domain, username, password, host, port, database, sslMode };
};
