import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/members';

function* prepareFetchEntities(axios, action) {
  try {
    const {
      data: { data: roles }
    } = yield axios.get('/roles/org');
    const members_edit = {
      values: {}
    };
    if (action.payload && action.payload.id) {
      const {
        data: { data: values }
      } = yield axios.get(`/users/${action.payload.id}/account`);
      members_edit.values = values;
    }

    yield put({
      type: actions.MEMBERS_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        roles,
        members_edit
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchGuestsFetch(axios) {
  yield takeLatest(actions.MEMBERS_FETCH, prepareFetchEntities, axios);
}
