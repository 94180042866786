import { TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa'
    },
    '&:hover': {
      backgroundColor: '#eeeeee'
    }
  }
}))(TableRow);
