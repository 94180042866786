import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

export const IncidentsTotal = () => {
  const GetIncidentsTotal = `#graphql
    query (
      $startDate: String! 
      $endDate: String!      
    ) {
      insights {
        incidents {
          total(startDate: $startDate, endDate: $endDate){
            period
            compared
          }
        }      
      }
    }
  `;

  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentsTotal,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const incidentsTotal = data?.insights?.incidents?.total;

  return (
    <Metrics
      name="Total incidents"
      value={incidentsTotal?.period}
      valueFormat={MetricsValueFormatEnum.Count}
      isFetching={fetching}
      tooltipTitle="Total number of incidents that occurred in the organization over a specific period."
      diff={incidentsTotal?.compared}
      diffFormat={MetricsValueFormatEnum.Percentile}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
