import React from 'react';

import { Grid, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const NameField = ({ control, errors, disabled }) => {
  return (
    <Grid item md={6} xs={12}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        as={
          <TextField
            label="Name"
            variant="outlined"
            margin="dense"
            error={Boolean(errors.name?.message)}
            helperText={errors.name?.message}
            fullWidth
            disabled={disabled}
            InputLabelProps={{
              shrink: true
            }}
          />
        }
      />
    </Grid>
  );
};

export default NameField;
