import { Box, Typography } from '@material-ui/core';

import Breadcrumb from 'components/Breadcrumb';
import useQueryParams from 'helpers/useQueryParams';

import { OrganizationSettingsTabs } from './components/Tabs';

function OrganizationView() {
  const queryParams = useQueryParams();

  const tab = queryParams.get('tab') || 'general';

  const breadcrumbTabsMapping: Record<string, string> = {
    general: 'Informations',
    sso: 'Single Sign-on',
    integrations: 'Integrations',
    secrets: 'Secrets',
    api: 'API',
    'audit-trail': 'Audit Trail'
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            label: 'Settings Organization'
          },
          {
            label: breadcrumbTabsMapping[tab]
          }
        ]}
      />
      <Box
        id="page-title-section"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
        mb={3}>
        <Typography variant="h4">Organization Settings</Typography>
      </Box>

      <OrganizationSettingsTabs />
    </>
  );
}

export default OrganizationView;
