import React from 'react';

import { ThemeProvider } from '@material-ui/core';

import theme from './';

const WithTheme = Component => props => (
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>
);

export default WithTheme;
