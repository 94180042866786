import { ThemeProvider } from '@material-ui/core';

import theme from '.';

const withThemeHoc = Component => props => (
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>
);

export default withThemeHoc;
