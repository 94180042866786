import { useMutation } from 'urql';

const DeleteMember = `#graphql 
  mutation($memberId: Int!){
    deleteMemberV2(memberId: $memberId)
  }
`;

export const useDeleteMembers = () => {
  const [{ fetching: isLoading }, deleteMember] = useMutation(DeleteMember);

  return { deleteMember, isLoading };
};

export default useDeleteMembers;
