import { z } from 'zod';

export const genericMonitoringSchema = z
  .any()
  .superRefine((data, ctx) => {
    if (Number(data.interval) < Number(data.timeout)) {
      ctx.addIssue({
        path: ['timeout'],
        message: 'The timeout must not be greater than the interval',
        code: z.ZodIssueCode.custom
      });
    }
    if (String(data.interval).length > 6) {
      ctx.addIssue({
        path: ['interval'],
        message: 'Interval number can only be up to six digits',
        code: z.ZodIssueCode.custom
      });
    }
    if (String(data.timeout).length > 6) {
      ctx.addIssue({
        path: ['timeout'],
        message: 'Timeout number can only be up to six digits',
        code: z.ZodIssueCode.custom
      });
    }
  })
  .superRefine((data, ctx) => {
    if (!data.rules?.incident) return true;

    const {
      intervalFailures,
      intervalHits,
      failuresToOpenIncident,
      hitsToCloseIncident,
      enable
    } = data.rules.incident;

    const isIncidentRuleIntervalFailureValid =
      intervalFailures >= data.interval * failuresToOpenIncident;

    if (enable && Number(failuresToOpenIncident) < 1) {
      ctx.addIssue({
        path: ['rules.incident.failuresToOpenIncident'],
        message: 'Failures to open incident must be greater than zero.',
        code: z.ZodIssueCode.custom
      });
    }

    if (enable && Number(hitsToCloseIncident) < 1) {
      ctx.addIssue({
        path: ['rules.incident.hitsToCloseIncident'],
        message: 'Failures to close incident must be greater than zero.',
        code: z.ZodIssueCode.custom
      });
    }

    if (enable && !isIncidentRuleIntervalFailureValid) {
      ctx.addIssue({
        path: ['rules.incident.intervalFailures'],
        message: 'Interval failures should be less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['rules.incident.failuresToOpenIncident'],
        message: 'Interval failures should be less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['interval'],
        message: 'Interval should be equal or less than interval check x failures to open incident',
        code: z.ZodIssueCode.custom
      });
    }

    const isIncidentRuleIntervalHitsValid = intervalHits >= data.interval * hitsToCloseIncident;

    if (enable && !isIncidentRuleIntervalHitsValid) {
      ctx.addIssue({
        path: ['rules.incident.intervalHits'],
        message: 'Interval hits should be less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['rules.incident.hitsToCloseIncident'],
        message: 'Interval hits should be less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });

      ctx.addIssue({
        path: ['interval'],
        message: 'Interval should be equal or less than interval check x hits to close incident',
        code: z.ZodIssueCode.custom
      });
    }
  })
  .superRefine((data, ctx) => {
    if (!data.rules.timeout?.enable) return true;

    if (Number(data.rules.timeout.timeout) < 1) {
      ctx.addIssue({
        path: ['rules.timeout.timeout'],
        message: 'Timeout must be greater than zero.',
        code: z.ZodIssueCode.custom
      });

      return;
    }

    if (Number(data.rules.timeout.timeout) < Number(data.interval)) {
      ctx.addIssue({
        path: ['rules.timeout.timeout'],
        message: 'The timeout must not be less than the interval',
        code: z.ZodIssueCode.custom
      });
    }
  })
  .superRefine((data, ctx) => {
    if (Number(data.port) > 65535) {
      ctx.addIssue({
        path: ['port'],
        message: 'The port must not be greater than the 65535',
        code: z.ZodIssueCode.custom
      });
    }
  });
