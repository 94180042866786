import { useFlagsStatus } from '@unleash/proxy-client-react';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { HomeView } from '..';

import ProductsCardsList from './V3';
import NewProductsCardsList from './V4';

const Home = () => {
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });
  const newHome = useFeatureFlagUnleash('newHome', { queryString: true });

  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <LoadingOverlay />;
  }

  function homeView() {
    if (!newApplications) {
      return <ProductsCardsList />;
    }

    if (newHome) {
      return <HomeView />;
    }

    return <NewProductsCardsList />;
  }

  return homeView();
};

export default Home;
