import { Metric } from 'oldComponents/Metric';
import { MetricsItem } from 'oldComponents/MetricsItem';

import useFrequenciesMetrics from 'hooks/queriesGraphQL/useFrequenciesMetrics';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import {
  ChangeFailureTitle,
  DeployFrequencyTitle
} from 'views/Products/View/Availability/View/components/Titles';

export const FrequenciesMetrics = ({ applicationId }: { applicationId: number }) => {
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const availableMetricPeriods = [
    {
      type: '1 day',
      enabled: true
    },
    {
      type: '30 days',
      enabled: canFetchDataFromTheLast30Days
    }
  ].filter(period => period.enabled);

  const frequenciesMetrics = useFrequenciesMetrics({
    applicationId: Number(applicationId),
    periods: availableMetricPeriods.map(period => period.type)
  });

  const metricsHelperText = canFetchDataFromTheLast30Days ? 'Last 30 days' : 'Last 24 hours';

  const changeFailure = {
    name: <ChangeFailureTitle />,
    value: frequenciesMetrics
      ? frequenciesMetrics?.changeFailure > 0
        ? `${frequenciesMetrics?.changeFailure.toFixed(2)}%`
        : `${frequenciesMetrics?.changeFailure}%`
      : '0%',
    success: true
  };
  const deployFrequency = {
    name: <DeployFrequencyTitle />,
    value: frequenciesMetrics ? frequenciesMetrics?.deployFrequency : 0,
    success: true
  };

  return (
    <>
      <MetricsItem>
        <Metric entity={changeFailure} helperText={metricsHelperText} featurePlan="Metrics.DORA" />
      </MetricsItem>
      <MetricsItem>
        <Metric
          entity={deployFrequency}
          helperText={metricsHelperText}
          featurePlan="Metrics.DORA"
        />
      </MetricsItem>
    </>
  );
};
