import React from 'react';

import { TextField as MuiTextField } from '@material-ui/core';

const TextField = ({ register, errors, name, defaultHelperText = '', ...otherProps }) => {
  const errorMessage = errors && errors[name]?.message;

  const helperTextMessage = () => {
    if (errorMessage && !defaultHelperText) return errorMessage;
    if (defaultHelperText && !errorMessage) return defaultHelperText;
    if (errorMessage && defaultHelperText)
      return (
        <p>
          {errorMessage} <br /> {defaultHelperText}
        </p>
      );
  };

  return (
    <MuiTextField
      fullWidth
      size="small"
      variant="outlined"
      inputRef={register}
      name={name}
      margin="dense"
      error={Boolean(errorMessage)}
      helperText={helperTextMessage()}
      InputLabelProps={{
        shrink: true
      }}
      {...otherProps}
    />
  );
};

export default TextField;
