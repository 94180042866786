import React from 'react';

import { postBodyTypeValues } from 'constants/postBody';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  useTheme
} from '@material-ui/core';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';

import { ButtonSimple } from 'componentsV4/Button';
import getObjectList from 'helpers/getObjectList';
import axios from 'redux/axios';

import Form from '../components/Form';
import mapFormDataToApi from '../components/Form/mapFormDataToApi';

function ServicesEditForm({ service }) {
  const theme = useTheme();

  const router = useHistory();

  const queryCache = useQueryCache();

  const [submitMutation] = useMutation(
    async data => {
      const mappedData = mapFormDataToApi(data);

      return await axios.put(`/services/${service.id}`, { ...mappedData, id: service.id });
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries('services');
        router.push('/services');
      }
    }
  );

  const startValues = React.useMemo(() => {
    return {
      name: service?.name,
      skip_ssl_validation: service?.skip_ssl_validation,
      domain: service?.domain,
      cloudId: service?.cloudId,
      environmentId: service?.environmentId,
      check_type: service?.check_type,
      method: service?.method,
      headers: getObjectList(service?.headers),
      post_body_raw: service?.post_body || '',
      post_body_urlencoded:
        service?.post_body_type === postBodyTypeValues.urlencoded
          ? getObjectList(service?.post_body)
          : [{ key: '', value: '' }],
      post_body_type: service?.post_body_type,
      queue_size: service?.queue_size,
      json_pubsub_body_raw: service?.method === 'pubsub' ? service?.domain_settings || '' : '',
      failuresToIncident: service?.failuresToIncident,
      domain_settings: service?.domain_settings
    };
  }, [service]);

  return (
    <Box maxWidth={theme.breakpoints.width('lg')} clone>
      <Card>
        <CardHeader title="Edit Service" />
        <Divider />
        <CardContent>
          {service && (
            <Form submitMutation={submitMutation} startValues={startValues} isAddon={false} />
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <ButtonSimple
            title="Save"
            color="primary"
            type="submit"
            variant="contained"
            form="serviceForm"
            text="Save"
          />
        </CardActions>
      </Card>
    </Box>
  );
}

export default ServicesEditForm;
