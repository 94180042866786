const regionsAzure = [
  { label: 'East Asia', value: 'eastasia' },
  { label: 'Southeast Asia', value: 'southeastasia' },
  { label: 'Central US', value: 'centralus' },
  { label: 'East US', value: 'eastus' },
  { label: 'East US 2', value: 'eastus2' },
  { label: 'West US', value: 'westus' },
  { label: 'North Central US', value: 'northcentralus' },
  { label: 'South Central US', value: 'southcentralus' },
  { label: 'North Europe', value: 'northeurope' },
  { label: 'West Europe', value: 'westeurope' },
  { label: 'Japan West', value: 'japanwest' },
  { label: 'Japan East', value: 'japaneast' },
  { label: 'Brazil South', value: 'brazilsouth' },
  { label: 'Australia East', value: 'australiaeast' },
  { label: 'Australia Southeast', value: 'australiasoutheast' },
  { label: 'South India', value: 'southindia' },
  { label: 'Central India', value: 'centralindia' },
  { label: 'West India', value: 'westindia' },
  { label: 'Canada Central', value: 'canadacentral' },
  { label: 'Canada East', value: 'canadaeast' },
  { label: 'UK South', value: 'uksouth' },
  { label: 'UK West', value: 'ukwest' },
  { label: 'West Central US', value: 'westcentralus' },
  { label: 'West US 2', value: 'westus2' },
  { label: 'Korea Central', value: 'koreacentral' },
  { label: 'Korea South', value: 'koreasouth' },
  { label: 'France Central', value: 'francecentral' },
  { label: 'France South', value: 'francesouth' },
  { label: 'Australia Central', value: 'australiacentral' },
  { label: 'Australia Central 2', value: 'australiacentral2' },
  { label: 'South Africa North', value: 'southafricanorth' },
  { label: 'South Africa West', value: 'southafricawest' }
];

export default regionsAzure;
