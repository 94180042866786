import { ReactNode, useEffect, useRef } from 'react';

import posthog from 'posthog-js';

import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
const posthogApiHost = process.env.REACT_APP_POSTHOG_API_HOST!;
const posthogToken = process.env.REACT_APP_POSTHOG_TOKEN!;

function loadToolbar(toolbar: string | null) {
  if (!toolbar) return;

  try {
    posthog.loadToolbar(JSON.parse(toolbar));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load posthog toolbar");
  }
}

function initPosthog({ toolbar }: { toolbar: string | null }) {
  if (!isProduction) return;

  posthog.init(posthogToken, {
    api_host: posthogApiHost
  });

  loadToolbar(toolbar);
}

function identify({ id, email }: { id: string; email: string }) {
  if (!isProduction || !id || !email) return;

  posthog.identify(id);
  posthog.people && posthog.people.set({ email });
}

export function PosthogSetup({ children }: { children: ReactNode }) {
  const toolbarJSON = useRef<string>(
    new URLSearchParams(window.location.hash.substring(1)).get('__posthog')
  );

  const { data: user } = useUser({});

  const isEnabled = useFeatureFlagUnleash('posthog');

  useEffect(() => {
    if (!isEnabled) return;

    initPosthog({ toolbar: toolbarJSON.current });
  }, [isEnabled, toolbarJSON]);

  useEffect(() => {
    if (!isEnabled) return;

    identify({ id: user?.id, email: user?.email });
  }, [user?.id, user?.email, isEnabled]);

  return children;
}

export function Capture(event: string, properties: object) {
  if (!isProduction) return;

  posthog.init(posthogToken, {
    api_host: posthogApiHost
  });

  const pre = !isProduction ? '[non-production] ' : '';

  posthog.capture(`${pre}${event}`, properties);
}
