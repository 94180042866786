import React from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation } from 'urql';

import useFormState from 'hooks/crud/useFormState';
import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';
import notificationSchema from 'views/Teams/components/notificationSchema';
import NewFields from 'views/Teams/components/NotificationsForm/NewFields';

import UseTeamFromMsTeam from '../hooks/useTeamFromMsTeam';

const updateProvidersMutation = `#graphql
mutation ($data: UpdateProvidersInput!) {
  updateProviders (data: $data) {
    affected
  }
}`;

const Notifications = ({ canEdit, setShowBackdrop, initialValues, refetchProviders }) => {
  const [updateProvidersResult, updateProviders] = useMutation(updateProvidersMutation, {});

  const reduxDispatch = useDispatch();
  const { data: userData } = useUser();
  const { id: teamId } = useParams();

  const { formState: notificationSettings } = useFormState({
    schema: notificationSchema,
    initialValues: { ...initialValues }
  });

  const { membersNumbers } = UseTeamFromMsTeam(teamId);

  React.useEffect(() => {
    setShowBackdrop(false);

    if (updateProvidersResult.fetching) {
      setShowBackdrop(true);
    }
  }, [updateProvidersResult, updateProvidersResult.fetching, setShowBackdrop]);

  const handleSave = async data => {
    setShowBackdrop(true);
    await updateProviders({
      data: {
        providers: data.notificationSetup.map(
          ({ notificationSetupId, enabled, providerName, config }) => ({
            teamId: Number(teamId),
            orgUid: userData?.logged?.org?.uid,
            id: +notificationSetupId,
            enabled,
            providerName,
            config
          })
        )
      }
    })
      .then(async response => {
        reduxDispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Team updated successfully'
        });

        await refetchProviders();

        setShowBackdrop(false);
      })
      .catch(error => {
        reduxDispatch({
          type: actions.ENTITY_ERROR,
          payload: new Error(
            (() => {
              try {
                return error.response.data.payload.issues[0].message;
              } catch (e) {
                return error.message;
              }
            })()
          ),
          ga: { category: 'ERROR' }
        });

        setShowBackdrop(false);
      });
  };

  return (
    <NewFields
      initialValues={notificationSettings.values}
      canEdit={canEdit}
      handleSave={handleSave}
      nameField={false}
      numberOptions={membersNumbers}
      teamId={teamId}
    />
  );
};

export default Notifications;
