import { useEffect } from 'react';

import { HealthStatus, MonitoringStatus } from 'constants/MonitoringStatusV1';

import { useQuery } from 'urql';

const GetApplication = `#graphql
  query($id: Int!) {
    application(id: $id) {
      id
      uid
      name
      monitoringStatusV1
      addons {
        id
        name
        sterasId
        monitoringStatus
        healthStatus
      }
    }
  }
`;

type Addon = {
  id: number;
  name: string;
  sterasId: number;
  monitoringStatus: MonitoringStatus;
  healthStatus: HealthStatus;
};

type Application = {
  id: number;
  name: string;
  monitoringStatusV1: MonitoringStatus;
  addons: Addon[];
};

type Variables = {
  id: number;
};

const useGetApplication = ({ applicationId }: { applicationId: number }) => {
  const [result, reexecuteQuery] = useQuery<{ application: Application }, Variables>({
    query: GetApplication,
    variables: { id: Number(applicationId) }
  });

  useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(
        () => reexecuteQuery({ requestPolicy: 'network-only' }),
        Number(process.env.REACT_APP_POLLING_APPLICATION_VIEW) || 60000
      );
      return () => clearTimeout(id);
    }
  }, [result.fetching, reexecuteQuery]);

  return { result, reexecuteQuery };
};

export default useGetApplication;
