import React from 'react';

import { Box, Divider, TextField, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import useDebounce from 'hooks/useDebounce';
import theme from 'theme';

import { NewDataGrid as DataGrid } from '../components/Crud/NewDataGrid';

const TYPING_DEBOUNCE_TIME = 500;

function EnvironmentsList({ history, permissions }) {
  const [query, setQuery] = React.useState('');

  const dispatch = useDispatch();

  const handleChangeSearch = useDebounce(event => {
    const search = event.target.value;

    if (search.length === 0) return setQuery('');

    if (search.length < 3) return;

    setQuery(search);
  }, TYPING_DEBOUNCE_TIME);

  return (
    <>
      <Breadcrumb items={[{ label: 'Monitoring' }, { label: 'Environment Center' }]} />

      <Box display="flex" alignItems="center">
        <Typography variant="h4">Environments</Typography>
        <Button
          children="New"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => dispatch(push(`/environments/new`))}
          style={{ width: 'initial', marginLeft: 'auto' }}
        />
      </Box>
      <Divider
        style={{
          border: `1px solid ${theme.palette.gray[200]}`,
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3)
        }}
      />
      <TextField
        name="search"
        label="Search"
        variant="outlined"
        onChange={handleChangeSearch}
        placeholder="Search by name"
        InputProps={{
          endAdornment: <Search />
        }}
        InputLabelProps={{
          shrink: true,
          color: 'secondary'
        }}
        fullWidth
      />
      <Box marginTop={4}>
        <DataGrid entity="environments" permissions={permissions} provider="aws" query={query} />
        <DataGrid entity="environments" permissions={permissions} provider="azure" query={query} />
        <DataGrid entity="environments" permissions={permissions} provider="custom" query={query} />
      </Box>
    </>
  );
}

export default EnvironmentsList;
