import { MonitoringStatus as MonitoringStatusType } from 'constants/MonitoringStatusV1';

import MonitoringStatusV0 from './MonitoringStatusV0';
import MonitoringStatusV1 from './MonitoringStatusV1';

const MonitoringStatus = ({
  application,
  applicationStatusUniqueResource,
  monitoringStatus
}: {
  application: any;
  applicationStatusUniqueResource: any;
  monitoringStatus: MonitoringStatusType;
}) => {
  if (monitoringStatus) {
    return <MonitoringStatusV1 monitoringStatus={monitoringStatus} />;
  }

  return (
    <MonitoringStatusV0
      application={application}
      applicationStatusUniqueResource={applicationStatusUniqueResource}
    />
  );
};

export default MonitoringStatus;
