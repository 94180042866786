import { green, grey, red } from '@material-ui/core/colors';

import MaintenanceIcon from 'icons/Maintenance';

export enum MonitoringStatus {
  Maintenance = 'Maintenance',
  Off = 'Off'
}

export enum HealthStatus {
  Up = 'Up',
  Down = 'Down'
}

export const monitoringStatusColors = {
  [MonitoringStatus.Maintenance]: '#F57D27'
};

export const MonitoringStatusDictionary = {
  [MonitoringStatus.Off]: {
    label: 'Monitoring is inactive',
    color: grey[800]
  },

  [MonitoringStatus.Maintenance]: {
    label: 'In Maintenance',
    color: monitoringStatusColors[MonitoringStatus.Maintenance],
    icon: MaintenanceIcon
  }
};

export const HealthStatusDictionary = {
  [HealthStatus.Up]: {
    label: 'Available',
    color: green[300]
  },

  [HealthStatus.Down]: {
    label: 'Unavailable',
    color: red[300]
  }
};
