import * as z from 'zod';

import { functionsSchemaValidation } from 'views/Services/components/Form/Functions/schema';
import { lambdaSchemaValidation } from 'views/Services/components/Form/LambdaV2/schema';
import { redisSchemaValidation } from 'views/Services/components/Form/RedisV2/schema';
import { serviceBusSchemaValidation } from 'views/Services/components/Form/ServiceBus/schema';

import { kafkaFieldSchemaValidation } from '../views/Services/components/Form/Kafka/schema';

export const domainSettingsValidation = () => {
  return {
    busy_size: z
      .string()
      .refine(
        busySize => {
          try {
            if (Number(busySize) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Busy size should be a positive number' }
      )
      .optional(),
    scheduled_size: z
      .string()
      .refine(
        scheduledSize => {
          try {
            if (Number(scheduledSize) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Scheduled size should be a positive number' }
      )
      .optional(),
    connected_clients: z
      .string()
      .refine(
        connectedClients => {
          try {
            if (Number(connectedClients) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Connected clients should be a positive number' }
      )
      .optional(),
    failuresToIncident: z
      .string()
      .refine(
        failuresToIncident => {
          try {
            if (Number(failuresToIncident) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Fails number should be a greater than zero' }
      )
      .optional(),
    ...redisSchemaValidation(),
    ...lambdaSchemaValidation(),
    ...functionsSchemaValidation(),
    ...kafkaFieldSchemaValidation(),
    ...serviceBusSchemaValidation()
  };
};
