import { MonitoringBaseForm } from '../../../forms/monitoring/commom/Base';
import { RabbitmqFormData } from '../../../forms/monitoring/queue/Rabbitmq';
import { monitoringAdapter } from '../monitoring';

export function rabbitmqAdapter(data: MonitoringBaseForm & RabbitmqFormData) {
  const domain = data.domainType === 'url' ? data.domain : prepareDomain(data);

  return {
    ...monitoringAdapter({
      ...data,
      method: 'rabbitmq'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: RabbitmqFormData) => {
  const protocol = `${data.protocol}://`;
  const virtualhost = data.virtualhost ? `/${data.virtualhost}` : '';

  return `${protocol}${data.user}:${data.password}@${data.host}:${data.port}${virtualhost}`;
};
