import { OverrideFn } from '.';

export const MuiMenuItem: OverrideFn<'MuiMenuItem'> = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[900],
      textShadow: '0px 0px currentColor' // This is a hack to bold effect without break layout
    },
    '&[data-active=true]': {
      color: theme.palette.grey[900],
      fontWeight: 'bold'
    }
  },
  gutters: {
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  }
});
