export const getMsTeamMembersQuery = `#graphql
query($teamId: Int!) {
  getTeamMembersV2(teamId: $teamId) {
        user{
          displayName
          email
          profile {
            displayName
            photoURL
            phone
            verifiedPhone
          }
        }
      }
    }
`;

export const getProviders = `#graphql
query ($teamId: [Int!]!) {
  listProviders (data: { teamId: $teamId}) {
    id
    providerName
    enabled

    config {
      email
      numbers
      telegramId
      slackChannel
      slackWorkspace
      slackIntegration
      teamsWebhookUrl
      gchatWebhookUrl
      discordChannelWebhookUrl
      webhookUrl
      webhookRetry
      webhookRetryFailEmail
      apikey
    }
  }
}
`;
