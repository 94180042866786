import { Box, Button, Divider, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { BaseForm } from '../../forms/agent/commom/Base';

import { useStyles } from './styles';

export function AgentLayout({ Form }: any) {
  const classes = useStyles();
  const form = useForm();

  const handleSubmit = (data: any) => {
    // eslint-disable-next-line no-console
    console.log(data);
  };

  return (
    <Box
      component="form"
      display="flex"
      gridGap="2.5rem"
      flexDirection="column"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box
        component="form"
        display="flex"
        gridGap="2.5rem"
        flexDirection="column"
        onSubmit={form.handleSubmit(handleSubmit)}>
        <Box>
          <Typography className={classes.sectionTitle} variant="h4">
            Configure your agent
          </Typography>

          <Box display="flex" gridGap="12rem">
            <Box width="50%">
              <BaseForm form={form} />
            </Box>
          </Box>
        </Box>

        <Divider className={classes.sectionDivider} />

        <Box display="flex" gridGap="2rem" flexDirection="column" width="50%">
          {/* TODO: Make instructions dinamically by type */}
          <Typography variant="h4">Agent installation instructions</Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Please install the Agent on your cloud using the Kubernetes manifest below to be able to
            monitor applications there. We will automatically update your Agent Status when you
            install.
          </Typography>

          <Form form={form} />

          {/* TODO: Add instructions */}
        </Box>

        <Box display="flex">
          <Button variant="contained" color="primary" type="submit">
            SAVE EDITIONS
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
