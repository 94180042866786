import { useQuery } from 'urql';

type UseFullApplicationsParams = {
  id: number;
  uid: string;
  name: string;
  entityUid: string;
  entity: string;
  productId?: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  __typename?: string;
  main: {
    name: string;
    serviceId?: number;
    status: string;
    __typename?: string;
  };
};

type MonitoringMain = {
  name: string;
  status: string;
  serviceId: number;
  latency: string;
  mtbf: string;
  mtta: string;
  mttrecovery: string;
  uptime_1_hour: string;
};

type UseFullApplicationsData = {
  id: number;
  name: string;
  uid: string;
  entity: string;
  entityUid: string;
  main: MonitoringMain;
  dependencies: {
    available: number | null;
    unavailable: number | null;
    maintenance: number | null;
    pending: number | null;
    off: number | null;
    success: number | null;
    error: number | null;
  };
};

const GetFullApplicationsQuery = `#graphql
    query(
      $applications: [FullApplicationInput!]!
    ) {
    fullApplications(
      applications: $applications
    ) {
      id
      uid
      name
      productId
      entityUid
      entity
      dependencies {
        available
        unavailable
        maintenance
        pending
        off
        success
        error
      }
      main {
        name
        serviceId
        status
        uptime {
          online1Hour
        }
        mtts {
          mtta
          mtbf
          mttr
        }
        latency {
          hour
          latency
        }
      }
    }
  }
`;

export function useFullApplications({
  fullApplications
}: {
  fullApplications: UseFullApplicationsParams[] | undefined;
}) {
  const applications = fullApplications
    ? fullApplications.map(application => {
        delete application.main.__typename;
        delete application.__typename;
        return application;
      })
    : [];

  return useQuery<{ fullApplications: UseFullApplicationsData[] }>({
    query: GetFullApplicationsQuery,
    pause: !applications.length,
    variables: {
      applications
    }
  });
}
