import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { BuildIcon } from 'icons';

const useStyles = makeStyles(theme => ({
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: '0.5rem',
    color: theme.palette.icon
  }
}));

function ProviderIcon({ provider }) {
  const classes = useStyles();
  const icons = {
    aws: <img alt="AWS" classes={classes.icon} src="/images/aws.png" />,
    azure: <img alt="Azure" classes={classes.icon} src="/images/azure.png" />,
    custom: <BuildIcon className={classes.icon} />
  };

  return icons[provider];
}

export default ProviderIcon;
