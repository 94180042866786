import { useQuery } from 'urql';

import useUser from './queriesRest/useUser';

const RoleQuery = `#graphql
  query($id: Int!) {
    role(id: $id) {
      name
    }
  }
`;

type Role = {
  role: {
    name: string;
  };
};

type RoleVariables = {
  id: number;
};

export function useLoggedUserRole(options?: { fromCache?: boolean }) {
  const fromCache = options?.fromCache;

  const { data: user } = useUser();

  const roleId = user?.logged?.roleId;

  return useQuery<Role, RoleVariables>({
    query: RoleQuery,
    variables: { id: roleId },
    pause: !roleId,
    requestPolicy: fromCache ? 'cache-only' : undefined
  });
}
