import { Box } from '@material-ui/core';

import { useMultiOrigins } from 'hooks/queriesGraphQL/useMultiOriginsMonitorings';
import { DependenciesSelect } from 'views/Applications/components/DependenciesSelect';

import { FormProps } from '..';

export type DependenciesFormInput = {
  name: string;
  description: string;
};

export function DependenciesForm({ form }: FormProps) {
  const [{ data, fetching: isFetching }, refetch] = useMultiOrigins();
  const monitoringsList = data?.getMultiOriginsMonitorings?.data;
  const monitoringOptions =
    monitoringsList?.map((item: { uid: string; name: string; entity: string }) => {
      return {
        value: item.uid,
        label: item.name,
        helperText: item.entity === 'Multiflow' ? 'Synthetic' : item.entity
      };
    }) ?? [];

  const monitoringOptionsWithDefault = [
    {
      value: '',
      label: 'Select one',
      helperText: ''
    },
    ...monitoringOptions
  ];

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <DependenciesSelect
        form={form}
        options={monitoringOptionsWithDefault}
        required={false}
        fetching={isFetching}
        refetch={() => refetch()}
      />
    </Box>
  );
}
