export const CreateOrgMutation = `#graphql
  mutation ($orgInput: OrgInput!) {
    createOrg(
      orgInput: $orgInput
    ) {
      name
      policy
    }
  }
`;
