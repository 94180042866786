import Breadcrumb from 'oldComponents/Breadcrumb';
import { useMutation } from 'react-query';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation as useMutationURQL } from 'urql';

import { encodeData } from 'helpers/encodeData';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/crud';

import NewCloudsForm from '../components/Form/NewCloud';
import schema from '../components/schema';

import CloudsEditForm from './CloudsEditForm';

const UpdateCloudMutation = `#graphql
  mutation($updateCloudInput: UpdateCloudInput!){
    updateCloud (updateCloudInput: $updateCloudInput){
      id
    }
  }
`;

function CloudsEdit({ clouds_edit: formState, onChange, match }) {
  const router = useHistory();
  const dispatch = useDispatch();

  const shouldUseGraphqlOnClouds = useFeatureFlagUnleash('cloudsGraphql', { queryString: true });

  const { id: cloudId } = match.params;

  const { data: clouds, isLoading } = useQuery(
    ['clouds/edit', cloudId],
    async () => (await axios.get(`/clouds/${cloudId}`)).data.data
  );

  const [updateCloudMutation] = useMutation(
    async data => {
      let payload = encodeData(data);
      payload = { ...payload, orgId: clouds?.orgId, id: clouds?.id };
      const response = await axios.put(`/clouds/${cloudId}`, payload);
      return response;
    },
    {
      onSuccess: async () => {
        router.push('/clouds');
      }
    }
  );

  const [, updateCloudMutationURQL] = useMutationURQL(UpdateCloudMutation);

  const submitMutation = data => {
    if (!shouldUseGraphqlOnClouds) {
      return updateCloudMutation(data);
    }

    const { name, provider, newCloud, ...providerCredentials } = encodeData(data);

    updateCloudMutationURQL({
      updateCloudInput: {
        name,
        provider,
        id: Number(cloudId),
        providerCredentials: { provider, ...providerCredentials }
      }
    }).then(result => {
      if (result.error || !result.data)
        return dispatch({
          type: actions.GLOBAL_WARNING,
          payload: 'Unable to update cloud credentials'
        });

      router.push('/clouds');
    });
  };

  return !isLoading ? (
    <>
      <Breadcrumb items={[{ link: `/clouds`, label: 'Clouds' }, { label: 'Edit' }]} />
      <NewCloudsForm submitMutation={submitMutation} startValues={clouds} title="Edit cloud" />
    </>
  ) : (
    <>
      <Breadcrumb items={[{ link: `/clouds`, label: 'Clouds' }, { label: 'Edit' }]} />
      <CloudsEditForm formState={formState} onChange={onChange} />
    </>
  );
}

export default connect(
  ({ clouds_edit }) => ({ clouds_edit }),
  dispatch => ({
    fetch: id =>
      dispatch({
        type: actions.EDIT_CLOUD_ENTITY,
        meta: {
          entity: 'clouds',
          id: id
        }
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...entity_new },
        meta: {
          entity: 'clouds_edit'
        }
      }),
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'clouds_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  })
)(CloudsEdit);
