/* eslint-disable no-console */
import applicationType from 'constants/ApplicationType';
import { postBodyTypeValues } from 'constants/postBody';

const getHeadersList = headers => {
  const headersList = Object.keys(headers).map(header => {
    return { key: header, value: headers[header] };
  });
  return headersList;
};

const getPostBodyList = postBody => {
  try {
    const postBodyParsed = typeof postBody === 'string' ? JSON.parse(postBody) : postBody;
    return postBodyParsed
      ? Object.keys(postBodyParsed).map(postBodyKey => {
          return { key: postBodyKey, value: postBodyParsed[postBodyKey] };
        })
      : [{ key: '', value: '' }];
  } catch (error) {
    return [{ key: '', value: '' }];
  }
};

export const getStartValues = application => {
  const isWebhook = application.applicationsSettings?.type === applicationType.Webhook.type;

  return {
    name: application.name,
    environmentId: application.environmentId,
    type: application.applicationsSettings?.type,
    ...(isWebhook
      ? {
          check_interval: application.applicationsSettings?.check_interval,
          failuresToIncident: application.applicationsSettings?.failuresToIncident,
          openIncidentWithSeverity:
            application?.applicationsSettings?.openIncidentWithSeverity || 'not-classified'
        }
      : {
          healthcheckUrl: application.applicationsSettings?.healthcheckUrl?.trim(),
          method: application.applicationsSettings?.method,
          headers: application.applicationsSettings?.headers
            ? getHeadersList(application.applicationsSettings.headers)
            : [{ key: '', value: '' }],
          skip_ssl_validation: application.applicationsSettings?.skip_ssl_validation,
          validationString: application.applicationsSettings?.validationString,
          timeout: application.applicationsSettings?.timeout,
          check_interval: application.applicationsSettings?.check_interval,
          failuresToIncident: application.applicationsSettings?.failuresToIncident,
          post_body_type: application.applicationsSettings?.post_body_type,
          openIncidentWithSeverity:
            application?.applicationsSettings?.openIncidentWithSeverity || 'not-classified',
          post_body_raw: (function() {
            const { post_body_type, post_body } = application.applicationsSettings || {};

            if (!post_body_type || post_body_type === postBodyTypeValues.urlencoded) {
              return '';
            }

            return post_body;
          })(),
          post_body_urlencoded:
            application.applicationsSettings?.post_body_type === postBodyTypeValues.urlencoded
              ? getPostBodyList(application.applicationsSettings?.post_body)
              : [{ key: '', value: '' }],
          domain_settings: application.applicationsSettings?.domain_settings
        })
  };
};

export const getStartValuesForHttpFlow = (application, addon) => {
  const service = addon ? addon.service : {};
  const preRequestHeaders = JSON.parse(service.headers);

  return {
    // Global
    environmentId: application.environmentId,
    type: application.applicationsSettings?.type,
    timeout: application.applicationsSettings?.timeout,
    check_interval: application.applicationsSettings?.check_interval,
    failuresToIncident: application.applicationsSettings?.failuresToIncident,
    openIncidentWithSeverity:
      application?.applicationsSettings?.openIncidentWithSeverity || 'not-classified',
    pos_name: application.name,
    pos_healthcheckUrl: application.applicationsSettings?.healthcheckUrl?.trim(),
    pos_method: application.applicationsSettings?.method,
    pos_headers: application.applicationsSettings?.headers
      ? getHeadersList(application.applicationsSettings.headers)
      : [{ key: '', value: '' }],
    pos_skip_ssl_validation: application.applicationsSettings?.skip_ssl_validation,
    pos_validationString: application.applicationsSettings?.validationString,
    pos_post_body_type: application.applicationsSettings?.post_body_type,
    pos_post_body_raw: (function() {
      const { post_body_type, post_body } = application.applicationsSettings || {};

      if (!post_body_type || post_body_type === postBodyTypeValues.urlencoded) {
        return '';
      }

      return post_body;
    })(),
    pos_post_body_urlencoded:
      application.applicationsSettings?.post_body_type === postBodyTypeValues.urlencoded
        ? getPostBodyList(application.applicationsSettings?.post_body)
        : [{ key: '', value: '' }],
    pos_domain_settings: application.applicationsSettings?.domain_settings,
    pos_teamId: application.teamId ? String(application.teamId) : null,

    pre_name: service.name,
    pre_healthcheckUrl: service.domain,
    pre_method: service.method,
    pre_check_type: 'http',
    pre_headers: preRequestHeaders ? getHeadersList(preRequestHeaders) : [{ key: '', value: '' }],
    pre_skip_ssl_validation: service.skip_ssl_validation,
    pre_validationString: service.validationString,

    pre_post_body_type: service.post_body_type,
    pre_post_body_raw: (function() {
      const { post_body_type, post_body } = service;

      if (!post_body_type || post_body_type === postBodyTypeValues.urlencoded) {
        return '';
      }

      return post_body;
    })(),
    pre_post_body_urlencoded:
      service.post_body_type === postBodyTypeValues.urlencoded
        ? getPostBodyList(service.post_body)
        : [{ key: '', value: '' }],
    pre_domain_settings: service?.domain_settings
  };
};
