import { Divider, Typography } from '@material-ui/core';
import { DefaultValues } from 'types/cms/ServicesHub';

import Breadcrumb from 'components/Breadcrumb';
import { useCms } from 'hooks/queriesGraphQL/useCms';

import { ServicesHubList } from './components/List';
import useStyles from './styles';

export function ServicesHubView() {
  const classes = useStyles();

  const [{ fetching, data }] = useCms({
    page: 'servicesHub'
  });

  const content = { ...DefaultValues(), ...data?.cms?.content };

  return (
    <>
      {!fetching && (
        <>
          <Breadcrumb items={content.breadcrumb} custom={{ isLastItemGray: true }} />

          <Typography className={classes.title} variant="h4">
            {content.title}
          </Typography>

          <Typography className={classes.description} variant="h6">
            {content.description}
          </Typography>

          <Divider className={classes.divider} />

          {<ServicesHubList services={content.services} />}
        </>
      )}
    </>
  );
}
