export const serviceBusMapToApi = keyValues => {
  const { connection_string, type, topic, subscription, queue } = keyValues;

  return {
    connection_string: connection_string,
    type: type,
    topic: topic,
    subscription: subscription,
    queue: queue
  };
};
