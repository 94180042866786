import React from 'react';

import { Grid, TextField } from '@material-ui/core';

import CrudForm from '../../../../oldComponents/Crud/Form';
import providers from '../provider';

const Form = ({ formState, onChange, title, cloudDisable, saveAction }) => {
  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  return (
    <CrudForm entity="clouds" formState={formState} title={title} saveAction={saveAction}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            label="Cloud"
            margin="dense"
            name="provider"
            onChange={handleChange}
            required
            select
            SelectProps={{ native: true }}
            value={formState.values.provider}
            disabled={cloudDisable}
            variant="outlined">
            {providers.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            error={hasError('name')}
            fullWidth
            helperText={hasError('name') ? formState.errors.name[0] : null}
            InputLabelProps={{
              shrink: true
            }}
            label="Name"
            margin="dense"
            name="name"
            onChange={handleChange}
            required
            value={formState.values.name || ''}
            variant="outlined"
          />
        </Grid>

        {formState.values.provider === 'aws' && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('accessKeyId')}
                fullWidth
                helperText={hasError('accessKeyId') ? formState.errors.accessKeyId[0] : null}
                InputLabelProps={{
                  shrink: true
                }}
                label="AccessKeyId"
                type="password"
                margin="dense"
                name="accessKeyId"
                onChange={handleChange}
                required
                value={formState.values.accessKeyId || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('secretAccessKey')}
                fullWidth
                helperText={
                  hasError('secretAccessKey') ? formState.errors.secretAccessKey[0] : null
                }
                InputLabelProps={{
                  shrink: true
                }}
                label="secretAccessKey"
                type="password"
                margin="dense"
                name="secretAccessKey"
                onChange={handleChange}
                required
                value={formState.values.secretAccessKey || ''}
                variant="outlined"
              />
            </Grid>
          </>
        )}

        {formState.values.provider === 'azure' && (
          <>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('clientId')}
                fullWidth
                helperText={hasError('clientId') ? formState.errors.clientId[0] : null}
                InputLabelProps={{
                  shrink: true
                }}
                label="clientId"
                type="password"
                margin="dense"
                name="clientId"
                onChange={handleChange}
                required
                value={formState.values.clientId || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('clientSecret')}
                fullWidth
                helperText={hasError('clientSecret') ? formState.errors.clientSecret[0] : null}
                InputLabelProps={{
                  shrink: true
                }}
                label="clientSecret"
                type="password"
                margin="dense"
                name="clientSecret"
                onChange={handleChange}
                required
                value={formState.values.clientSecret || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('tenantId')}
                fullWidth
                helperText={hasError('tenantId') ? formState.errors.tenantId[0] : null}
                InputLabelProps={{
                  shrink: true
                }}
                label="tenantId"
                type="text"
                margin="dense"
                name="tenantId"
                onChange={handleChange}
                required
                value={formState.values.tenantId || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={hasError('subscriptionId')}
                fullWidth
                helperText={hasError('subscriptionId') ? formState.errors.subscriptionId[0] : null}
                InputLabelProps={{
                  shrink: true
                }}
                label="subscriptionId"
                type="text"
                margin="dense"
                name="subscriptionId"
                onChange={handleChange}
                required
                value={formState.values.subscriptionId || ''}
                variant="outlined"
              />
            </Grid>
          </>
        )}
      </Grid>
    </CrudForm>
  );
};

export default Form;
