import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Toolbar from '../../../oldComponents/Crud/ToolbarQueryProps';
import DataGrid from '../components/Crud/DataGrid';

import columns from './columns';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1),
    position: 'absolute',
    color: theme.palette.primary.dark
  }
});

const ServicesList = ({ history, permissions, classes }) => {
  const [query, setQuery] = useState('');

  return (
    <div className={classes.root}>
      <Toolbar
        title="Services"
        buttonNewName="New"
        entity="services"
        history={history}
        searchPlaceholder="Search services"
        disableNew={!permissions.new}
        setQuery={setQuery}
      />
      <div className={classes.content}>
        <DataGrid cols={columns} entity="services" permissions={permissions} query={query} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ServicesList);
