import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  openBtn: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: 194,
    height: 42,
    border: '1px #515151 solid',
    borderRadius: 4,
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  line: {
    height: 42,
    backgroundColor: '#515151'
  },
  drop: {
    width: 194,
    borderRadius: 4,
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)'
  },
  listItem: {
    height: 36,
    width: '100%',
    padding: theme.spacing(1, 3),
    display: 'flex',
    alignItems: 'center'
  },
  listItemTypography: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    marginLeft: 8
  },
  boxTitle: {
    padding: 8
  },
  title: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '166%',
    letterSpacing: '0.4px'
  },
  titleButton: {
    fontSize: 15,
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.46px',
    textTransform: 'uppercase'
  }
}));
