import { useMutation } from 'urql';

const UpdateMemberMutation = `#graphql
  mutation(
    $id: Float!
    $teamId: Float!
    $roleId: Float!
    $isActive: Boolean!
  ){
    updateMember(
      id: $id
      teamId: $teamId
      roleId: $roleId
      isActive: $isActive
    )
  }
`;
export const useUpdateMember = () => {
  const [{ fetching: isLoading }, updateMember] = useMutation(UpdateMemberMutation);

  return { updateMember, isLoading };
};

export default useUpdateMember;
