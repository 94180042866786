import { Box, CircularProgress, FormHelperText, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import ModalCompound from 'componentsV3/ModalCompound';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';
import WithThemeV3Hoc from 'theme/v3/withTheme';

import { RefreshToken } from '../..';

import useForm from './useForm';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  button: {
    marginTop: spacing(4)
  },
  input: {
    marginTop: spacing(4)
  }
}));

const CreateAppTokenMutation = `#graphql
  mutation (
    $name: String!
  ) {
    createAppToken(
      appTokenInput: { 
        name: $name
        tokenType: "hits/failures"
      }
    ) {
      client_id
      client_secret
    }
  }
`;

const startValues = {
  name: ''
};

type ModalTokenProps = {
  onTokenCreate: (appToken: RefreshToken | undefined) => void;
  handleClose: () => void;
  isOpen: boolean;
  firstAccess: boolean;
};

function ModalToken({ onTokenCreate, handleClose, isOpen, firstAccess }: ModalTokenProps) {
  const classes = useStyles();

  const [createAppTokenResult, createAppToken] = useMutation<{ createAppToken: RefreshToken }>(
    CreateAppTokenMutation
  );

  const { handleSubmit, register, errors } = useForm({
    defaultValues: startValues
  });

  const submitMutation = (data: object) => {
    createAppToken(data).then(result => {
      onTokenCreate(result?.data?.createAppToken);
      if (!result.error?.graphQLErrors) {
        return handleClose();
      }
    });
  };

  const { fetching, error } = createAppTokenResult;

  const serverErrorMessage = error?.graphQLErrors
    ? error.graphQLErrors[0]?.originalError?.message
    : null;

  return (
    <ModalCompound title={'New Token'} isOpen={isOpen} handleClose={handleClose}>
      <form onSubmit={handleSubmit(submitMutation)}>
        <Grid item className={classes.input}>
          <InputDefault
            inputRef={register}
            errors={errors}
            name="name"
            id="token-name"
            label={'Token Name *'}
            placeholder={'Ex: New_Token'}
          />
        </Grid>
        <Grid item xs={12} className={classes.button}>
          <ButtonSimple
            type="submit"
            color="primary"
            text={'Create Token'}
            onClick={() => {
              analyticsEvent(firstAccess ? 'fa_new_token_hook' : 'new_token_hook');
            }}
          />
          {serverErrorMessage && (
            <Box>
              <FormHelperText error>{serverErrorMessage}</FormHelperText>
            </Box>
          )}
        </Grid>
        {fetching && (
          <Box
            style={{
              width: '97%',
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 12,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </ModalCompound>
  );
}

export default WithThemeV3Hoc(ModalToken);
