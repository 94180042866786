import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import schema from './schema';

const useFormKeysComplete = ({ defaultValues }) => {
  const { control, handleSubmit, register, errors, watch, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(schema)
  });

  return {
    control,
    handleSubmit,
    register,
    errors,
    watch,
    setValue
  };
};

export default useFormKeysComplete;
