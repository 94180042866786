import { Box, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import useCopy from 'hooks/useCopy';
import { analyticsEvent } from 'services/firebase/analytics';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  pre: {
    minHeight: 190,
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbarWidth: 'auto',
    scrollbarColor: '#888888 #595959',

    /* Chrome, Edge, and Safari */
    '&::-webkit-scrollbar': {
      width: 25,
      minHeight: 20
    },

    '&::-webkit-scrollbar-track': {
      background: '#595959'
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888888',
      borderRadius: spacing(2),
      border: '7px solid #595959'
    },

    '&::-webkit-scrollbar-corner': {
      background: 'none'
    },

    color: 'white',
    border: '1px solid rgba(29,28,29,.04)',
    borderRadius: 4,
    background: '#595959',
    whiteSpace: 'pre-wrap',
    textOverflow: 'clip',
    padding: spacing(1),
    maxHeight: 200,
    overflowY: 'scroll'
  },
  box: {
    backgroundColor: '#F5F5F5    ',
    borderRadius: 4,
    border: '1px solid lightGrey',
    padding: spacing(2, 3, 0),
    minWidth: 950
  },
  checked: {
    color: palette.primary.main
  },
  doubts: {
    textAlign: 'center'
  }
}));

type DialogBoxDoubleProps = {
  sterasId: number | null;
  orgUid: string;
  firstAccess: boolean;
};

function DialogBoxDouble({ sterasId, orgUid, firstAccess }: DialogBoxDoubleProps) {
  const classes = useStyles();

  const { handleCopy: handleCopyHits, copiedSuccessfully: copiedSuccessfullyHits } = useCopy();

  const {
    handleCopy: handleCopyFailures,
    copiedSuccessfully: copiedSuccessfullyFailures
  } = useCopy();

  const environmentType =
    process.env.REACT_APP_ENVIRONMENT === 'development'
      ? '.dev'
      : process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? '.st'
      : process.env.REACT_APP_ENVIRONMENT === 'production'
      ? ''
      : '.dev';

  const curlHits = `curl -X POST \\
  -H 'Content-type: application/json' \\
  -H "Authorization: Bearer <token>" \\
  -H "User-Agent: 1PwebhookHits/1.0" \\
  https://apis${environmentType}.elven.works/external/monitoring/v1/hits \\
  \`# Latency in microseconds\` \\
  --data '{
    "latency": 100000, 
    "service": ${sterasId}, 
    "organization": "${orgUid}"
  }'`;

  const curlFailures = `curl -X POST \\
  -H 'Content-type: application/json' \\
  -H "Authorization: Bearer <token>" \\
  -H "User-Agent: 1PwebhookFailures/1.0" \\
  https://apis${environmentType}.elven.works/external/monitoring/v1/failures \\
  --data '{
    "issue": "Error message", 
    "service": ${sterasId}, 
    "organization": "${orgUid}"
  }'`;

  return (
    <Box className={classes.box}>
      <Grid container>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between" xs={12} alignItems="center">
            <Typography>Use this curl for Hits</Typography>
            <IconButton
              disableRipple
              color="primary"
              className={classes.buttonCopy}
              style={{
                padding: 0
              }}
              onClick={() => {
                handleCopyHits(curlHits);

                analyticsEvent(firstAccess ? 'fa_copy_to_hits_hook' : 'copy_to_hits_hook');
              }}>
              {/* FIXME: <button> cannot appear as a descendant of <button>. */}
              <ButtonSimple
                style={{
                  paddingTop: 2,
                  paddingBottom: 2
                }}
                text={copiedSuccessfullyHits ? 'Copied!' : 'Copy code'}
                color="primary"
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <pre className={classes.pre}>{curlHits}</pre>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center">
            <Typography style={{ marginLeft: 25 }}>Use this curl for Failures</Typography>
            <IconButton
              disableRipple
              color="primary"
              className={classes.buttonCopy}
              style={{
                padding: 0
              }}
              onClick={() => {
                handleCopyFailures(curlFailures);

                analyticsEvent(firstAccess ? 'fa_copy_to_failure_hook' : 'copy_to_failure_hook');
              }}>
              {/* FIXME: <button> cannot appear as a descendant of <button>. */}
              <ButtonSimple
                style={{
                  paddingTop: 2,
                  paddingBottom: 2
                }}
                text={copiedSuccessfullyFailures ? 'Copied!' : 'Copy code'}
                color="primary"
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <pre style={{ marginLeft: 25 }} className={classes.pre}>
              {curlFailures}
            </pre>
          </Grid>
        </Grid>
      </Grid>
      <Box marginTop={2} marginBottom={3}>
        <Typography className={classes.doubts}>
          Doubts?{' '}
          <a
            target="_blank"
            className={classes.checked}
            href={
              'https://elvenworks.zendesk.com/hc/en-us/articles/17616626566932-Sending-Hits-and-Failures-to-a-Webhook-Application'
            }
            rel="noreferrer"
            onClick={() => {
              analyticsEvent(
                firstAccess
                  ? 'fa_read_article_hits_failures_hook'
                  : 'read_article_hits_failures_hook'
              );
            }}>
            Read this article
          </a>{' '}
          or{' '}
          <a
            target="_blank"
            className={classes.checked}
            href="https://elvenworks.zendesk.com/hc/en-us/requests/new"
            rel="noreferrer"
            onClick={() => {
              analyticsEvent(
                firstAccess ? 'fa_open_ticket_hits_failures_hook' : 'open_ticket_hits_failures_hook'
              );
            }}>
            open a ticket
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
}

export default withThemeV3Hoc(DialogBoxDouble);
