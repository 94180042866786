import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Backdrop, Box, CircularProgress, Grid, makeStyles, useTheme } from '@material-ui/core';
import ApplicationAlertParams from 'oldComponents/ApplicationAlertParams';

import CheckBoxDefault from 'componentsV3/CheckBox';
import InputDefault from 'componentsV3/InputDefault';
import InputWithSecrets from 'componentsV3/InputWithSecrets';
import KeyValueWithAdd from 'componentsV3/KeyValueWithAdd';
import MuiRadioGroup from 'componentsV3/RadioGroup';
import SelectSimple from 'componentsV3/Select';
import KeysForm from 'views/Products/View/Availability/components/Resources/Form/MultiHttpForm/KeysComplete/KeysForm';
import { useMsTeamsQuery } from 'views/V3/Products/View/Product/TeamsAssign';

const useStyles = makeStyles(({ palette, zIndex, spacing }) => ({
  form: {
    maxWidth: 830
  },
  button: {
    marginTop: spacing(9)
  },
  serverError: {
    color: palette.error.main
  },
  backdrop: {
    zIndex: zIndex.drawer + 100
  },
  text: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  }
}));

type FormMultiHttpProps = {
  isLoading: boolean;
  type: 'pre' | 'pos';
  register: any;
  control: any;
  errors: any;
  setValue: any;
  watch: any;
  headersFields: any;
  headersAppend: any;
  headersRemove: any;
  postBodyXFormUrlEncodedFields: any;
  postBodyXFormUrlEncodedAppend: any;
  postBodyXFormUrlEncodedRemove: any;
};

const FormMultiHttp = ({
  isLoading,
  type,
  register,
  control,
  errors,
  setValue,
  watch,
  headersFields,
  headersAppend,
  headersRemove,
  postBodyXFormUrlEncodedFields,
  postBodyXFormUrlEncodedAppend,
  postBodyXFormUrlEncodedRemove
}: FormMultiHttpProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const selectedMethod = watch(`${type}_method`);
  const selectedPostBodyType = watch(`${type}_post_body_type`);

  const useQuery = useMsTeamsQuery;

  type Teams = {
    data: {
      teams: {
        data: {
          id: number;
          name: string;
        }[];
      };
    };
  };

  const [teamsQueryResult] = (useQuery() as unknown) as Teams[];

  const resultData =
    teamsQueryResult.data?.teams?.data?.map(team => ({
      label: team.name,
      value: String(team.id)
    })) || [];

  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {type === 'pre' ? (
              <InputDefault
                inputRef={register}
                errors={errors}
                label="Pre Request Name"
                placeholder="Ex: Auth"
                name={`pre_name`}
                required
              />
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <InputDefault
                    inputRef={register}
                    errors={errors}
                    label="Application Name"
                    placeholder="Ex: My Website"
                    name={`pos_name`}
                    required
                  />
                </Grid>

                <input type="hidden" ref={register} name="environmentId" />
                <input type="hidden" ref={register} name="productId" />

                <Grid item xs={6}>
                  <SelectSimple
                    errors={errors}
                    name={`${type}_teamId`}
                    label="Team"
                    control={control}
                    defaultValue=""
                    items={[
                      { value: '', label: 'No Team' },
                      ...resultData.map(team => ({ value: team.value, label: team.label }))
                    ]}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', paddingLeft: '12px' }}>
            <Box display="flex" width="100%">
              <Box flexGrow="1" mr={3}>
                <InputWithSecrets
                  iconColor={theme.palette.primary.main}
                  errors={errors}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  TextFieldProps={{
                    name: `${type}_healthcheckUrl`,
                    label: 'Healtcheck URL',
                    placeholder: 'Ex: http://mywebsite.com',
                    required: true
                  }}
                  InputComponent={InputDefault}
                />
              </Box>

              <Box mr={3} flexBasis="16%">
                <SelectSimple
                  errors={errors}
                  name={`${type}_method`}
                  label="Method"
                  defaultValue="GET"
                  control={control}
                  items={[
                    { value: 'GET', label: 'GET' },
                    { value: 'POST', label: 'POST' }
                  ]}
                />
              </Box>

              <Box pt={3}>
                <CheckBoxDefault
                  inputRef={register}
                  label="Skip SSL"
                  name={`${type}_skip_ssl_validation`}
                  tooltip={
                    '(TLS) By default, every SSL connection connector makes is verified to be secure. This option allows connector to proceed and operate even for server connections otherwise considered insecure.'
                  }
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <KeyValueWithAdd
              control={control}
              errors={errors}
              fieldAppend={headersAppend}
              fieldRemove={headersRemove}
              propsKey={{ label: 'Header', placeholder: 'Ex: header_key' }}
              propsValue={{ label: 'Value', placeholder: 'Ex: header_value' }}
              fieldsList={headersFields}
              name={`${type}_headers`}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </Grid>

          {selectedMethod === 'POST' && (
            <>
              <Grid container style={{ marginLeft: 16 }} xs={12}>
                <MuiRadioGroup
                  errors={errors}
                  name={`${type}_post_body_type`}
                  control={control}
                  label="Post-body type"
                  defaultValue="raw"
                  options={[
                    { value: postBodyTypeValues.raw, label: 'raw' },
                    { value: postBodyTypeValues.urlencoded, label: postBodyTypeValues.urlencoded }
                  ]}
                />

                {selectedPostBodyType === postBodyTypeValues.raw && (
                  <Grid item xs={2}>
                    <SelectSimple
                      errors={errors}
                      name={`${type}_post_body_raw_type`}
                      control={control}
                      label="Raw type"
                      defaultValue={postBodyRawTypeValues.json}
                      items={[
                        { value: postBodyRawTypeValues.json, label: 'JSON' },
                        { value: postBodyRawTypeValues.text, label: 'Text' },
                        { value: postBodyRawTypeValues.javaScript, label: 'JavaScript' },
                        { value: postBodyRawTypeValues.xml, label: 'XML' },
                        { value: postBodyRawTypeValues.html, label: 'HTML' }
                      ]}
                    />
                  </Grid>
                )}
              </Grid>

              {selectedPostBodyType === postBodyTypeValues.raw && (
                <Grid item xs={12}>
                  <InputDefault
                    errors={errors}
                    inputRef={register}
                    label="Post body"
                    multiline={true}
                    linesNumber={3}
                    name={`${type}_post_body_raw`}
                  />
                </Grid>
              )}

              {selectedPostBodyType === postBodyTypeValues.urlencoded && (
                <Grid item xs={12}>
                  <KeyValueWithAdd
                    control={control}
                    errors={errors}
                    fieldAppend={postBodyXFormUrlEncodedAppend}
                    fieldRemove={postBodyXFormUrlEncodedRemove}
                    fieldsList={postBodyXFormUrlEncodedFields}
                    name={`${type}_post_body_urlencoded`}
                    propsKey={{ label: 'Post body', placeholder: 'Ex: post_body_key' }}
                    propsValue={{ label: 'Value', placeholder: 'Ex: post_body_value' }}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  />
                </Grid>
              )}
            </>
          )}

          {type === 'pre' && (
            <Grid item xs={12}>
              <KeysForm />
            </Grid>
          )}

          {type === 'pos' && (
            <ApplicationAlertParams register={register} errors={errors} control={control} />
          )}
        </Grid>
      </Box>
      {isLoading && (
        <Backdrop open={true} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>
      )}
    </>
  );
};

export default FormMultiHttp;
