import React from 'react';

import { Dialog as MuiDialog, useTheme, Box, CircularProgress } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { DialogTitle, DialogContent, DialogActions } from 'oldComponents/LegacyDialog';
import { useQuery } from 'react-query';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { ButtonSimple } from 'componentsV4/Button';
import useNewAddons from 'hooks/queriesGraphQL/useNewAddons';
import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';
import axios from 'redux/axios';
import Form from 'views/Services/components/Form';
import mapFormDataToApi from 'views/Services/components/Form/mapFormDataToApi';

const Dialog = ({ productId, applicationId, formProps, disabled, onAddAddon, onReset }) => {
  const { reexecuteQuery: reexecuteQueryNewAddons } = useNewAddons({ applicationId });

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [createServiceMutation, { isLoading: isLoadingService }] = useMutation(
    async service => {
      const data = mapFormDataToApi(service);

      return (await axios.post(`/products/${productId}/services`, data)).data;
    },
    {
      onSuccess: ({ data: { id: serviceId } }) => {
        addAddonMutation({ applicationId, serviceId });
        onReset();
      }
    }
  );

  const [addAddonMutation, { isLoading: isLoadingAddon }] = useMutation(
    data => axios.post(`/products/${productId}/addonsV4`, data).then(response => response.data),
    {
      onSuccess: ({ data: { id: addonId } }) => {
        reexecuteQueryNewAddons();
        dispatch({
          type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
          params: { productId, applicationId }
        });
        handleClose();
      }
    }
  );

  const { data: product } = useQuery(
    ['products', productId],
    () => axios.get(`/products/${productId}`).then(response => response.data.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const permission = {
    newAddon: useProductPermission({
      productId: String(product?.id),
      target: 'AddonController-post-/products/:productId/addons'
    })
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    formProps.onCancel();
    handleClose();
  };

  return (
    <>
      <IconButton
        title="New Service"
        color="primary"
        variant="contained"
        onClick={handleOpen}
        disabled={!permission.newAddon || disabled}
        fontSize="medium">
        <Icon style={{ fontSize: 35 }}>add_circle</Icon>
      </IconButton>
      <MuiDialog
        fullWidth
        open={open}
        aria-labelledby="new-service-dialog"
        onClose={handleClose}
        maxWidth="lg"
        disableEnforceFocus
        PaperProps={{
          style: {
            padding: theme.spacing(2)
          }
        }}>
        <DialogTitle id="new-service-dialog" onClose={handleClose}>
          New service
        </DialogTitle>

        <DialogContent style={{ overflowX: 'hidden' }}>
          <Form
            submitMutation={createServiceMutation}
            isAddon
            application={formProps?.application}
          />
        </DialogContent>

        <DialogActions>
          <ButtonSimple onClick={handleCancel} text="Cancel" variant="outlined" color="primary" />
          <ButtonSimple
            color="primary"
            variant="contained"
            type="submit"
            form="serviceForm"
            disabled={isLoadingService || isLoadingAddon}
            text="Save"
          />
        </DialogActions>

        {(isLoadingService || isLoadingAddon) && (
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </MuiDialog>
    </>
  );
};

export default Dialog;
