import { useState } from 'react';

import { Grid, Box, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  Select as SelectMUI
} from '@material-ui/core';
import Axios from 'axios';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import actions from 'redux/actions';

import CheckboxField from './CheckboxField';

const useStyle = makeStyles(theme => ({
  inputsBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: 15,
    alignItems: 'center',
    maxWidth: '57vw'
  },
  inputLabel: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 10,
    padding: '4px 0',
    borderRadius: 3
  },
  validateButton: {
    width: 82,
    height: 42,
    marginTop: '-22px'
  },
  webhookUrlInput: {
    width: 285,
    height: 63
  },
  selectInput: {
    marginBottom: '18px',
    width: 150
  },
  emailInput: {
    height: 63,
    width: 315
  }
}));

const WebHookField = ({
  control,
  errors,
  field,
  index,
  register,
  setValue,
  disabled,
  withRetry
}) => {
  const dispatch = useDispatch();

  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const classes = useStyle();

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  const [webhookUrl, setWebhookUrl] = useState(field.config['webhookUrl']);

  let hasValue = webhookUrl.length;

  const [validateUrl, { isLoading: isValidating }] = useMutation(
    url => Axios.post(url, { ok: true }).then(response => response.data),
    {
      onSuccess: data => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Successfully validated Webhook'
        });
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: `
          Sorry, we couldn't validate webhook URL: ${
            err?.response?.status ? `Status ${err?.response?.status}` : err.message
          }`
          },
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const webhookFieldsController = (field, index, fieldName, helperText, label, className) => {
    return (
      <Controller
        id={field.id}
        name={`notificationSetup[${index}].config[${fieldName}]`}
        control={control}
        defaultValue={field.config[fieldName]}
        render={props => {
          const { onChange, ...otherProps } = props;

          return (
            <>
              <TextField
                className={className}
                label={label}
                disabled={disabled}
                variant="outlined"
                margin="dense"
                error={error?.config ? Boolean(error?.config[fieldName]) : false}
                onChange={event => {
                  const value = event.target.value;

                  if (!(event.target.name.split('config')[1] === '[webhookRetryFailEmail]')) {
                    setWebhookUrl(value);
                  }

                  setValue(enabledFieldName, Boolean(value));

                  onChange(event.target.value);
                }}
                {...otherProps}
                helperText={error?.config?.[fieldName]?.message ?? helperText}
              />
            </>
          );
        }}
      />
    );
  };

  const select = ({ errors, control, name, label, fieldName }) => {
    const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

    const enabledFieldName = `notificationSetup[${index}].enabled`;

    return (
      <>
        <Controller
          id={field.id}
          name={`notificationSetup[${index}].config[${fieldName}]`}
          control={control}
          defaultValue={field.config[fieldName]}
          render={props => {
            const { onChange, ...otherProps } = props;

            return (
              <>
                <FormControl variant="outlined">
                  <InputLabel
                    className={classes.inputLabel}
                    shrink={true}
                    htmlFor="outlined-select">
                    {label}
                  </InputLabel>

                  <SelectMUI
                    className={classes.selectInput}
                    variant="outlined"
                    name={name}
                    margin="dense"
                    error={error?.config ? Boolean(error?.config[fieldName]) : false}
                    native
                    labelId="outlined-select"
                    labelWidth={50}
                    onChange={event => {
                      const value = event.target.value;

                      setValue(enabledFieldName, Boolean(value));

                      onChange(event.target.value);
                    }}
                    label={label}
                    inputProps={{
                      name,
                      id: 'outlined-select'
                    }}
                    {...otherProps}>
                    <option aria-label="None" value="">
                      Value
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </SelectMUI>
                  {error?.config?.[fieldName]?.message && (
                    <FormHelperText error={Boolean(error?.config?.[fieldName]?.message)}>
                      {error?.config[fieldName]?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            );
          }}
        />
      </>
    );
  };

  return (
    <Grid xs={12} item style={{ margin: '5px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="WebHook"
      />
      <CheckboxField
        name={`notificationSetup[${index}].enabled`}
        control={control}
        disabled={disabled}
        defaultValue={field.enabled}>
        <img alt="webhook" src="/images/webhook.svg" height={22} />
        <Box margin="0 10px" minWidth="20vw">
          <Typography>Send a notification via Webhook</Typography>
        </Box>
        <Box className={classes.inputsBox} minWidth="30vw">
          {webhookFieldsController(
            field,
            index,
            'webhookUrl',
            'The URL we should send the webhooks',
            'Webhook URL',
            classes.webhookUrlInput
          )}
          <Button
            className={classes.validateButton}
            variant="outlined"
            color="primary"
            text="TEST"
            disabled={!hasValue}
            onClick={() => validateUrl(webhookUrl)}>
            {isValidating ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            ) : (
              'TEST'
            )}
          </Button>
          {withRetry &&
            select({ errors, control, name: 'retry', label: 'Retry*', fieldName: 'webhookRetry' })}
          {withRetry &&
            webhookFieldsController(
              field,
              index,
              'webhookRetryFailEmail',
              "We'll send you an email when your endpoints fails",
              'E-mail address*',
              classes.emailInput
            )}
        </Box>
      </CheckboxField>
    </Grid>
  );
};

export default WebHookField;
