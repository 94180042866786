import React from 'react';

import { Button, Dialog, DialogContentText, Link } from '@material-ui/core';
import { DialogTitle, DialogContent, DialogActions } from 'oldComponents/LegacyDialog';

const WhatsappIntegrationInstructionsDialog = ({ field }) => {
  const [showInstructions, setShowInstructions] = React.useState(false);

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>
      <Dialog open={showInstructions} aria-labelledby="new-service-dialog" onClose={handleClose}>
        <DialogTitle id="new-service-dialog" onClose={handleClose}>
          WhatsApp integration instructions
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant="h5">
            1. Only verified phones can be selected.
          </DialogContentText>

          <DialogContentText>
            Check your phone number here:{' '}
            <Link href="https://1p.elven.works/account" rel="noopener noreferrer">
              account
            </Link>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Link
        href="#"
        onClick={() => {
          setShowInstructions(true);
        }}>
        Integration instructions
      </Link>
    </>
  );
};

export default WhatsappIntegrationInstructionsDialog;
