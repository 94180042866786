import React from 'react';

import { ThemeProvider } from '@material-ui/core';

import theme from '.';

interface WithThemeV4HocProps {}

const WithThemeV3Hoc = <P extends WithThemeV4HocProps = WithThemeV4HocProps>(
  Component: React.ComponentType<P>
) => (props: Omit<P, keyof WithThemeV4HocProps>) => (
  <ThemeProvider theme={theme}>
    <Component {...(props as P)} />
  </ThemeProvider>
);

export default WithThemeV3Hoc;
