import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import Status from 'views/Products/View/Availability/List/Datagrid/checkAppAvailability/Status';

export type Application = {
  name: string;
  uid: string;
  entity: ApplicationManagerEntityType;
  status: string;
  dependencies: {
    entity: ApplicationManagerEntityType;
    entityUid: string;
    status: string;
    uptime: { online1Hour: number };
  }[];
  uptime: {
    online1Hour: number;
  };
};

export const validateApplicationStatus = ({ application }: { application: Application }) => {
  const response: {
    status: { active: boolean; label: string; color: string };
    sla: number | string;
    statusService: string;
  } = { status: Status['MONITORING-IS-INACTIVE'], sla: 0, statusService: '' };

  const status = {
    status: application?.status,
    online1Hour: application?.uptime?.online1Hour
  };

  if (application.entity === ApplicationManagerEntityType.Multiflow) {
    response.sla = status.online1Hour || 'No data';
    response.statusService = status.status;

    if (status.status === MultiFlowStatusEnum.Error) {
      response.status = Status['UNAVAILABLE'];
      return response;
    }

    if (status.status === MultiFlowStatusEnum.Success) {
      response.status = Status['AVAILABLE'];
      return response;
    }
  }

  if (
    application?.status !== ResourceStatusEnum.Off &&
    application?.status !== ResourceStatusEnum.Pending &&
    application?.uptime?.online1Hour
  ) {
    const status = {
      status: application?.status,
      online1Hour: application?.uptime?.online1Hour
    };

    response.sla = status.online1Hour;
    response.statusService = status.status;

    if (response?.statusService === ResourceStatusEnum.Maintenance) {
      response.status = Status['MAINTENANCE'];
      return response;
    }

    if (status.online1Hour >= 0 && status?.online1Hour < 98) {
      response.status = Status['UNAVAILABLE'];
      return response;
    }

    if (status?.online1Hour < 99.5 && status?.online1Hour >= 98) {
      response.status = Status['DEGRADED'];
      return response;
    }

    if (status?.online1Hour >= 99.5) {
      response.status = Status['AVAILABLE'];
      return response;
    }
  }

  return response;
};
