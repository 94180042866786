import { z } from 'zod';

export const schema = z.object({
  id: z.coerce
    .number()
    .int()
    .optional(),
  uid: z.string().optional(),
  name: z.string().min(1, 'Name is required'),
  responders: z.array(
    z.object({ value: z.string(), label: z.string(), type: z.string().optional() })
  )
});
