const checkTypes = [
  {
    value: 'db',
    label: 'DB',
    options: [
      {
        value: '',
        label: ''
      },
      {
        value: 'postgres',
        label: 'Postgres'
      },
      {
        value: 'mysql',
        label: 'MySQL'
      },
      {
        value: 'sqlserver',
        label: 'SQL Server'
      },
      {
        value: 'mongodb',
        label: 'MongoDB'
      },
      {
        value: 'oracle',
        label: 'Oracle'
      },
      {
        value: 'cassandra',
        label: 'Cassandra'
      },
      {
        value: 'neo4j',
        label: 'Neo4j'
      },
      {
        value: 'dynamodb',
        label: 'DynamoDB'
      }
    ]
  },
  {
    value: 'cache',
    label: 'Cache',
    options: [
      {
        value: '',
        label: ''
      },
      /* FF no Methods.js
    {
      value: 'redis',
      label: 'Redis'
    },*/
      {
        value: 'memcached',
        label: 'memcached'
      }
    ]
  },
  {
    value: 'http',
    label: 'HTTP',
    options: [
      {
        value: '',
        label: ''
      },
      {
        value: 'GET',
        label: 'GET'
      },
      {
        value: 'POST',
        label: 'POST'
      }
    ]
  },
  {
    value: 'queue',
    label: 'Queue',
    options: [
      {
        value: '',
        label: ''
      },
      {
        value: 'kafka',
        label: 'Kafka'
      },

      /* Se for definitivo descomentar aqui e remover FeatureFlag useKafkaAdvanced em Methods.js
      {
        value: 'kafka_advanced',
        label: 'Kafka Advanced'
      },*/
      {
        value: 'rabbitmq',
        label: 'RabbitMQ'
      },
      {
        value: 'sqs',
        label: 'SQS (AWS)'
      },
      {
        value: 'pubsub',
        label: 'Pubsub (GCP)'
      },
      {
        value: 'servicebus',
        label: 'Service Bus (Azure)'
      }
    ]
  },
  {
    value: 'lambda',
    label: 'Serverless',
    options: [
      {
        value: '',
        label: ''
      }
      /** FF no methods */
      // {
      //   value: 'lambdaaws',
      //   label: 'AWS'
      // },
      // {
      //   value: 'lambdaawsv2',
      //   label: 'AWS V2'
      // }
      // {
      //   value: 'functions',
      //   label: 'Functions(GCP)'
      // }
    ]
  },
  {
    value: 'search',
    label: 'Search Engine',
    options: [
      {
        value: '',
        label: ''
      },
      {
        value: 'elastic',
        label: 'Elastic Search'
      },
      {
        value: 'solr',
        label: 'Solr'
      }
    ]
  },
  {
    value: 'others',
    label: 'Others',
    options: [
      {
        value: '',
        label: ''
      },
      {
        value: 'ping',
        label: 'Ping'
      },
      {
        value: 'tcp',
        label: 'TCP'
      }
      /*Se for definitivo descomentar aqui e remover FeatureFlag usePubsub em Methods.js
    {
      value: 'webhook',
      label: 'Webhook'
    }*/
    ]
  }
];

export default checkTypes;
