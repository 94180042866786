import MonitoringStatusV0 from 'constants/MonitoringStatusV0';

import { CheckCircle } from 'icons';
import ErrorCircle from 'icons/ErrorCircle';
import MinusCircle from 'icons/MinusCircle';

const IconForStatus = ({ status, style }: { status: MonitoringStatusV0; style?: object }) => {
  if (status === MonitoringStatusV0.Up) {
    return <CheckCircle style={style} />;
  }

  if (status === MonitoringStatusV0.Down) {
    return <ErrorCircle style={style} />;
  }

  if (status === MonitoringStatusV0.NotMonitoring) {
    return <MinusCircle style={style} />;
  }

  if (status === MonitoringStatusV0.Stale) {
    return <MinusCircle style={style} />;
  }

  return null;
};

export default IconForStatus;
