import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { MttsCard as MonitoringMttsCard } from 'views/Monitoring/View/components/MttsCard';
import { useStyles } from 'views/Monitoring/View/styles';

import { MetricCard } from '../MetricCard';

export function MttsCard({
  name,
  originUid,
  entity,
  monitoringIsActive
}: {
  name: string;
  originUid: string;
  entity: string;
  monitoringIsActive: boolean;
}) {
  const classes = useStyles();

  const canUseMetrics = useFeaturePlan('Metrics.SRE');

  if (originUid)
    return (
      <MonitoringMttsCard
        originUid={originUid}
        entity={entity}
        monitoringIsActive={monitoringIsActive}
        name={name}
      />
    );

  return (
    <MetricCard name={name}>
      {canUseMetrics ? (
        <Typography variant="h5" className={classes.paperTextMiddle}>
          No data
        </Typography>
      ) : (
        <Link to="/plans">
          <Typography variant="h5" color="primary" className={classes.upgradePlanText}>
            Upgrade your plan
          </Typography>
        </Link>
      )}
    </MetricCard>
  );
}
