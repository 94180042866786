import React from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import GoogleChatIntegrationInstructionsDialog from '../IntegrationsInstructions/GoogleChatIntegrationInstructionsDialog';

import CheckboxField from './CheckboxField';

const GoogleChatField = ({ control, errors, field, index, register, setValue, disabled }) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  const googleChatController = (
    <Controller
      id={field.id}
      name={`notificationSetup[${index}].config.gchatWebhookUrl`}
      control={control}
      defaultValue={field.config.gchatWebhookUrl}
      render={props => {
        const { onChange, ...otherProps } = props;

        return (
          <TextField
            label="Webhook url"
            disabled={disabled}
            fullWidth
            margin="dense"
            variant="outlined"
            multiline
            rows={4}
            error={Boolean(error?.config?.gchatWebhookUrl)}
            helperText={error?.config?.gchatWebhookUrl?.message}
            onChange={event => {
              const value = event.target.value;

              setValue(enabledFieldName, Boolean(value));

              onChange(event.target.value);
            }}
            {...otherProps}
          />
        );
      }}
    />
  );
  return (
    <Grid xs={12} item>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="GoogleChat"
      />
      <CheckboxField
        name={enabledFieldName}
        control={control}
        defaultValue={field.enabled}
        disabled={disabled}>
        <img alt="ms googleChat" src="/images/google_chat.svg" height={22} />
        <Box margin="0 10px" minWidth="20vw">
          <Typography>
            Send a notification to the Google Chat{' '}
            <GoogleChatIntegrationInstructionsDialog field={googleChatController} />
          </Typography>
        </Box>
        <Box minWidth="30vw">{googleChatController}</Box>
      </CheckboxField>
    </Grid>
  );
};

export default GoogleChatField;
