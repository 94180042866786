import { useMutation } from 'urql';

const UpdateProfileMutation = `#graphql
  mutation ($updateProfileInput: UpdateProfileInputs!) {
    updateProfile(updateProfileInput: $updateProfileInput) {
      displayName
      photoURL
      verifiedPhone
      telegramId
      phone
    }
  }
`;

const useProfile = () => {
  const [{ fetching: isLoading }, updateProfile] = useMutation(UpdateProfileMutation);

  return { updateProfile, isLoading };
};

export default useProfile;
