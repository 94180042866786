import { Box, Divider, Typography } from '@material-ui/core';
import { Service } from 'types/cms/ServicesHub';

import Breadcrumb from 'components/Breadcrumb';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useCms } from 'hooks/queriesGraphQL/useCms';

import { useStyles } from '../formStyles';
import { useLayoutFromServiceType } from '../lib/useLayoutFromServiceType';

function ServicesHubEditInner({ servicesDefinitions }: { servicesDefinitions: Service[] }) {
  const classes = useStyles();

  const {
    Layout,
    title,
    description,
    breadcrumb,
    backLabel,
    backLink,
    baseBreadcrumb
  } = useLayoutFromServiceType(servicesDefinitions);

  return (
    <>
      <Breadcrumb
        items={
          baseBreadcrumb
            ? [
                { label: baseBreadcrumb },
                { label: backLabel, link: backLink },
                { label: `Edit ${breadcrumb}` }
              ]
            : [{ label: backLabel, link: backLink }, { label: `Edit ${breadcrumb}` }]
        }
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={6} mb={1}>
        <Typography variant="h4" className={classes.title}>
          Edit {title}
        </Typography>
      </Box>

      <Typography className={classes.description} variant="body1">
        {description}
      </Typography>

      <Divider className={classes.divider} />

      <Layout isEdit />
    </>
  );
}

export function ServicesHubEdit() {
  const [{ fetching, data }] = useCms({
    page: 'servicesHub'
  });

  if (fetching) {
    return <LoadingOverlay />;
  }

  return <ServicesHubEditInner servicesDefinitions={data?.cms.content.services} />;
}
