const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Cloud',
    selector: 'provider',
    sortable: true
  }
];

export default columns;
