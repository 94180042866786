import { z } from 'zod';

export const rulesSchema = {
  rules: z
    .object({
      incident: z.object({
        intervalHits: z.coerce
          .number()
          .int()
          .min(1, 'Interval hits should be a greater than zero'),
        intervalFailures: z.coerce
          .number()
          .int()
          .min(1, 'Interval failures number should be a greater than zero'),
        failuresToOpenIncident: z.coerce
          .number()
          .int()
          .min(1, 'Failures to open incident number should be a greater than zero'),
        hitsToCloseIncident: z.coerce
          .number()
          .int()
          .min(1, 'Hits to close incident number should be a greater than zero'),
        type: z.string(),
        severity: z.string(),
        teams: z.array(z.object({ value: z.string(), label: z.string() }).optional()).optional(),
        responders: z
          .array(
            z
              .object({
                value: z.string(),
                label: z.string(),
                type: z.string(),
                helperText: z.string()
              })
              .optional()
          )
          .optional(),
        enable: z.boolean()
      })
    })
    .required()
};
