import React from 'react';

import DayJsUtils from '@date-io/dayjs';
import { Button, Grid, TextField, Box, Typography, Divider, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

export default function DeployManual({
  handleSave,
  currentDate,
  setCurrentDate,
  version,
  setVersion,
  deployType,
  setDeployType
}) {
  const [description, setDescription] = React.useState('');

  const hasError =
    currentDate > new Date() ? 'The date time cannot be later than the current date' : null;

  const handleChange = event => {
    setVersion(event.target.value);
  };

  const handleChangeCheckBox = event => {
    if (event.target.name === deployType) {
      setDeployType('deploy');
    } else {
      setDeployType(event.target.name);
    }
  };

  return (
    <>
      <Box>
        <Box
          style={{
            marginBottom: 15
          }}>
          <Typography variant="h4">Deploy Type</Typography>

          <Typography
            variant="body2"
            style={{
              marginTop: 5,
              marginBottom: 10
            }}>
            Advanced Settings
          </Typography>

          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color="default"
              name={'rollback'}
              checked={deployType === 'rollback'}
              onChange={handleChangeCheckBox}
            />

            <Typography variant="body1">Rollback</Typography>

            <Checkbox
              color="default"
              name={'hotfix'}
              checked={deployType === 'hotfix'}
              onChange={handleChangeCheckBox}
            />

            <Typography variant="body1">Hot Fix</Typography>
          </Box>
          <Divider
            style={{
              marginBottom: 10
            }}
          />
        </Box>

        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <KeyboardDateTimePicker
            id="deployDate"
            value={currentDate}
            onChange={setCurrentDate}
            ampm={false}
            label="Deploy date"
            format="YYYY/MM/DD HH:mm"
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            error={hasError}
            helperText={hasError ? hasError : null}
            style={{ paddingRight: 20 }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          value={version}
          onChange={handleChange}
          id="application-version"
          label="Version"
          style={{ width: 180 }}
        />
      </Box>
      <Typography style={{ marginTop: 15 }}> Description </Typography>
      <Grid fullWidth>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          value={description}
          onChange={event => setDescription(event.target.value)}
          rows={6}
          style={{
            marginTop: 8
          }}
          variant="outlined"
          fullWidth
          multiline
        />
      </Grid>
      <Box style={{ textAlign: 'center' }}>
        <Button
          color="primary"
          variant="outlined"
          style={{
            marginTop: 15,
            border: '1px solid green',
            borderRadius: 7
          }}
          disabled={hasError}
          onClick={() => handleSave(currentDate, description)}>
          Register in timeline
        </Button>
      </Box>
    </>
  );
}
