import { buildConditionalCell } from './buildConditionalCell';
import { validateDependencyStatus } from './validateDependencyStatus';

export const conditionalCellToAddonStatus = ({
  app,
  status,
  labelColor
}: {
  app: any;
  status: {
    label: string;
    color: string;
  };
  labelColor?: any;
}) => {
  return buildConditionalCell({
    fn: (row: any) => {
      const response = validateDependencyStatus({ app, row });
      return response.status.label === status.label;
    },
    bgColor: status.color,
    labelColor
  });
};
