import { zodResolver } from '@hookform/resolvers/zod';
import { useForm as useRHForm, useFieldArray } from 'react-hook-form';

import schema from './schema';

const useForm = ({ defaultValues }: { defaultValues: any }) => {
  const useFormMethods = useRHForm({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  // pre
  const { control } = useFormMethods;

  const {
    fields: pre_headersFields,
    append: pre_headersAppend,
    remove: pre_headersRemove
  } = useFieldArray({
    control,
    name: 'pre_headers'
  });

  const {
    fields: pre_postBodyDefaultFields,
    append: pre_postBodyDefaultAppend,
    remove: pre_postBodyDefaultRemove
  } = useFieldArray({
    control,
    name: 'pre_post_body_default'
  });

  const {
    fields: pre_postBodyXFormUrlEncodedFields,
    append: pre_postBodyXFormUrlEncodedAppend,
    remove: pre_postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'pre_post_body_urlencoded'
  });

  // post
  const {
    fields: pos_headersFields,
    append: pos_headersAppend,
    remove: pos_headersRemove
  } = useFieldArray({
    control,
    name: 'pos_headers'
  });

  const {
    fields: pos_postBodyDefaultFields,
    append: pos_postBodyDefaultAppend,
    remove: pos_postBodyDefaultRemove
  } = useFieldArray({
    control,
    name: 'pos_post_body_default'
  });

  const {
    fields: pos_postBodyXFormUrlEncodedFields,
    append: pos_postBodyXFormUrlEncodedAppend,
    remove: pos_postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'pos_post_body_urlencoded'
  });

  return {
    pre_headersFields,
    pre_headersAppend,
    pre_headersRemove,
    pre_postBodyDefaultFields,
    pre_postBodyDefaultAppend,
    pre_postBodyDefaultRemove,
    pre_postBodyXFormUrlEncodedFields,
    pre_postBodyXFormUrlEncodedAppend,
    pre_postBodyXFormUrlEncodedRemove,

    pos_headersFields,
    pos_headersAppend,
    pos_headersRemove,
    pos_postBodyDefaultFields,
    pos_postBodyDefaultAppend,
    pos_postBodyDefaultRemove,
    pos_postBodyXFormUrlEncodedFields,
    pos_postBodyXFormUrlEncodedAppend,
    pos_postBodyXFormUrlEncodedRemove,
    ...useFormMethods
  };
};

export default useForm;
