const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Cloud',
    selector: row => row.cloud?.name
  }
];

export default columns;
