import React from 'react';

import { FormControl, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const RadioButtons = ({ label, name, options, control, ...otherProps }) => {
  return (
    <FormControl size="small" variant="outlined" {...otherProps}>
      <Typography>{label}</Typography>
      <Controller
        as={
          <RadioGroup row>
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        }
        name={name}
        control={control}
      />
    </FormControl>
  );
};
export default RadioButtons;
