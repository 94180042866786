import React from 'react';

import { Box, Card, CircularProgress, FormGroup, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation as useMutationUrql, useQuery } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';
import actions from 'redux/actions';
import useGetNotificationSettingsMatrix from 'views/Teams/hooks/useGetNotifySettingsMatrix';

import UseProvidersFromMsTeam from '../Notifications/hooks/useProvidersFromMsTeams';

import { TableAdvancedNotifySettings as TableAdvancedNotifySettingsV2 } from './components/Table/v2/';
import { postAdvancedSettings, queryNotificationSettingsMatrix } from './query';

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

function useHasExistingSchedules(teamIdFromTeams) {
  const [{ data }] = useQuery({
    query: `query ($teamIdFromTeams: Int!) {
      hasExistingSchedules(teamIdFromTeams: $teamIdFromTeams)
    }`,
    variables: { teamIdFromTeams },
    pause: !teamIdFromTeams
  });

  return data?.hasExistingSchedules;
}

const AdvancedNotifySettings = ({ view, canEdit }) => {
  const classes = useStyles();

  const reduxDispatch = useDispatch();
  const { id: teamId } = useParams();
  const queryCache = useQueryCache();

  const TableAdvancedNotifySettings = TableAdvancedNotifySettingsV2;

  const [, postAdvanced] = useMutationUrql(postAdvancedSettings);

  const [resultQuery] = useGetNotificationSettingsMatrix({
    query: queryNotificationSettingsMatrix,
    teamId: Number(teamId)
  });

  const resultBff = {
    data: resultQuery?.data?.getNotificationSettingsMatrix?.data,
    isLoading: resultQuery?.fetching,
    isSuccess: resultQuery?.data?.getNotificationSettingsMatrix?.data !== undefined,
    refetchOnWindowFocus: false
  };

  const { data: initialValues, isLoading, isSuccess } = resultBff;

  const [formState, setFormState] = React.useState([]);

  const onChange = async (name, checked, productId, environmentId) => {
    const findElement = formState.find(product => {
      if (
        product.productId === productId &&
        product.environmentId === environmentId &&
        name.replace('_disabled', '') === product.notificationProvider
      ) {
        return product;
      }

      return false;
    });

    const payload = {
      notificationProvider: name.replace('_disabled', ''),
      disabled: !checked ? true : false,
      productId,
      environmentId
    };

    if (!findElement) return setFormState(formState => [...formState, payload]);

    const newFormState = formState.filter(product => {
      if (
        product.productId !== productId ||
        product.notificationProvider !== name.replace('_disabled', '') ||
        product.environmentId !== environmentId
      ) {
        return product;
      }
      return false;
    });

    return setFormState([...newFormState]);
  };

  const handleMutation = async () => {
    const data = formState.map(form => ({
      disabled: form.disabled,
      environmentId: form.environmentId,
      notificationProvider: form.notificationProvider,
      resourceId: form.productId,
      resourceType: 'Product'
    }));
    await postAdvanced({
      teamId: Number(teamId),
      notifySettingsInput: data
    })
      .then(() => {
        queryCache.invalidateQueries('notify_settings');
        setFormState([]);
        reduxDispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Team updated successfully'
        });
      })
      .catch(err => {
        reduxDispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  const { data: providersConfigData } = UseProvidersFromMsTeam({ teamId });

  const hasExistingSchedules = useHasExistingSchedules(Number(teamId));

  const handleReset = () => {
    setFormState([]);
    setTableKey(tableKey + 1);
  };

  const [tableKey, setTableKey] = React.useState(1);

  return (
    <>
      {isLoading && <CircularProgress className={classes.loading} />}

      {isSuccess && !initialValues?.length && (
        <Card>
          <Typography style={{ padding: '15px', textAlign: 'center' }}>
            No monitored products
          </Typography>
        </Card>
      )}

      {isSuccess && initialValues?.length && providersConfigData ? (
        <TableAdvancedNotifySettings
          key={tableKey}
          initialValues={initialValues}
          providersConfigData={providersConfigData}
          hasSchedules={hasExistingSchedules}
          onChange={onChange}
          view={view}
          canEdit={canEdit}
        />
      ) : null}

      <Grid xs={12} item>
        <FormGroup>
          <Box mt={4} display="flex" alignItems="center">
            <ButtonSimple
              disabled={!(formState.length && canEdit)}
              title="Save"
              variant="contained"
              color="primary"
              type="submit"
              display="flex"
              onClick={handleMutation}
              text="Save"
            />
            <Box ml={2}>
              <ButtonSimple
                title="Cancel"
                text="cancel"
                color="primary"
                variant="outlined"
                disabled={!(formState.length && canEdit)}
                onClick={handleReset}
              />
            </Box>
          </Box>
        </FormGroup>
      </Grid>
    </>
  );
};

export default AdvancedNotifySettings;
