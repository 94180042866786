import { ReactNode } from 'react';

import { Grid } from '@material-ui/core';

type Props = {
  children: ReactNode;
};

export const MetricsItem = ({ children }: Props) => {
  return (
    <Grid item style={{ flex: '1' }}>
      {children}
    </Grid>
  );
};
