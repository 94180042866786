import { MenuItem, TextFieldProps, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Controller, UseFormMethods } from 'react-hook-form';

import { Input } from '../Input';

type SelectProps = {
  control: UseFormMethods['control'];
  errors?: UseFormMethods['errors'];
  options: {
    label: string;
    value: string;
    helperText?: string;
  }[];
} & TextFieldProps;

const useStyles = makeStyles(theme => ({
  helpertext: {
    color: grey[500],
    marginLeft: theme.spacing()
  }
}));

export function Select({ options, control, errors, defaultValue, ...textFieldProps }: SelectProps) {
  const classes = useStyles();
  const { name, ...inputProps } = textFieldProps;

  return (
    <Controller
      control={control}
      name={name!}
      defaultValue={defaultValue}
      render={({ value, onChange }) => {
        return (
          <Input
            select
            value={value}
            onChange={event => onChange(event.target.value)}
            errors={errors}
            {...inputProps}>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
                {option.helperText && (
                  <small className={classes.helpertext}>{option.helperText}</small>
                )}
              </MenuItem>
            ))}
          </Input>
        );
      }}
    />
  );
}
