import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import schema from './schema';

type UseFormParams = {
  defaultValues: any;
};

const useCloudUseForm = ({ defaultValues }: UseFormParams) => {
  const useFormMethods = useForm({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: zodResolver(schema)
  });

  return useFormMethods;
};

export default useCloudUseForm;
