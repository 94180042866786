import axios from 'axios';
import { put, takeLatest, select } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/merge';

function* fetchCep(action) {
  try {
    const account_edit = yield select(state => state.account_edit);
    const { data } = yield axios.get(`https://viacep.com.br/ws/${action.payload}/json/`);
    const values = {
      ...account_edit.values,
      endereco: data.logradouro,
      numero: '',
      complemento: '',
      bairro: data.bairro,
      cidade: data.localidade,
      uf: data.uf
    };
    yield put({
      type: actions.MERGE_ENTITY,
      meta: { entity: 'account_edit', reducer },
      payload: { values }
    });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchCEP() {
  yield takeLatest(actions.FETCH_CEP, fetchCep);
}
