import React from 'react';

import { Box, CardContent, Divider, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { IncidentUpdate } from 'hooks/queriesGraphQL/useIncidentUpdate';

import { useStyles } from './styles';
import { Update } from './Update';

type IncidentStatusUpdateListProps = {
  incident: any;
  data: IncidentUpdate[];
  isLoading: boolean;
  onDelete: () => void;
  onUpdate: () => void;
};

function IncidentUpdateList({
  incident,
  data,
  isLoading,
  onDelete,
  onUpdate
}: IncidentStatusUpdateListProps) {
  const classes = useStyles();

  const hasData = data.length > 0;

  return (
    <Grid container>
      <CardContent className={classes.cardContent}>
        <Paper elevation={1} className={classes.paper}>
          <Typography className={classes.titleCard} variant="h6">
            Events history
          </Typography>
          <Divider className={classes.dividerSections} variant="fullWidth" />
          <Box maxHeight={220} className={clsx(classes.scrollBar, !hasData && classes.noData)}>
            {hasData
              ? data.map(update => (
                  <React.Fragment key={update.incidentStatusUpdate?.id}>
                    <Update
                      incident={incident}
                      update={update}
                      onDelete={onDelete}
                      onUpdate={onUpdate}
                    />
                  </React.Fragment>
                ))
              : !isLoading && <Typography variant="subtitle2">No Data</Typography>}
          </Box>
        </Paper>
      </CardContent>
      {isLoading && <LoadingOverlay />}
    </Grid>
  );
}

export default IncidentUpdateList;
