import { zodResolver } from '@hookform/resolvers/zod';
import { useForm as useReactHookForm } from 'react-hook-form';

import schema from './schema';

const startValues = {
  name: '',
  provider: ('' as unknown) as undefined
};

function useForm() {
  const useFormMethods = useReactHookForm({
    defaultValues: startValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  return useFormMethods;
}

export default useForm;
