import { useEffect } from 'react';

import { Box, Button, Divider, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { UseFormMethods, useFieldArray } from 'react-hook-form';

import { MultiFlowFormData } from 'views/ServicesHub/adapters/new/multiflow';
import { MultiFlowStepsForm } from 'views/ServicesHub/forms/multiflow/common/MultiFlowSteps';

import { assertionDefaultValue } from '../defaultValues';
import { useStyles } from '../styles';

type Props = {
  field: any;
  index: any;
  removeStep: (param: any) => any;
  form: any;
};

const Step = ({ field, index, form, removeStep }: Props) => {
  const classes = useStyles();
  const {
    fields: fieldsAssertions,
    append: addAssertions,
    remove: removeAssertions
  } = useFieldArray({
    control: (form as UseFormMethods<MultiFlowFormData>).control,
    keyName: 'assertionId',
    name: `steps.${index}.assertions`
  });

  useEffect(() => {
    if (fieldsAssertions?.length === 0) {
      addAssertions(assertionDefaultValue);
    }
  }, [fieldsAssertions, addAssertions]);

  useEffect(() => {
    if (!field.id) {
      removeAssertions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeStep]);

  return (
    <>
      {index !== 0 && <Divider className={classes.sectionDivider} style={{ marginTop: 40 }} />}

      <Box display="flex" gridGap="3rem" mt={4}>
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5">Step {index + 1}</Typography>
              <Button variant="outlined" color="primary" onClick={() => removeStep(index)}>
                <Delete />
              </Button>
            </Box>
            <MultiFlowStepsForm
              form={form}
              field={field}
              index={index}
              assertionActions={{ fieldsAssertions, addAssertions, removeAssertions }}
            />
          </Box>
        </Box>
        <Box flex={1}></Box>
      </Box>
    </>
  );
};

export default Step;
