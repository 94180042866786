import { Box } from '@material-ui/core';
import { Tabs, TabsProps } from 'oldComponents/Tabs';

import { LockChip } from 'components/LockChip';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

import IntegrationTab from './IntegrationTab';
import MemberTab from './MemberTab';
import NotificationRulesTab from './NotificationRulesTab';
import OnCallTab from './OnCallTab';

function useTabsDefinition({
  teamId,
  refetchMembers,
  setRefetchMembers
}: {
  teamId: number;
  refetchMembers: boolean;
  setRefetchMembers: (value: boolean) => void;
}): TabsProps['tabs'] {
  const canUseCallRotation = useFeaturePlan('CallRotation');

  const tabs: TabsProps['tabs'] = [
    {
      label: 'Members',
      slug: 'member',
      component: (
        <MemberTab
          teamId={teamId}
          refetchMembers={refetchMembers}
          setRefetchMembers={setRefetchMembers}
        />
      )
    },
    {
      label: (
        <Box display="flex" gridGap="0.5rem">
          On-Call
          {!canUseCallRotation && <LockChip />}
        </Box>
      ),
      slug: 'on-call',
      component: <OnCallTab />,
      disabled: !canUseCallRotation
    },
    {
      label: 'Integrations for notifications',
      slug: 'integrations-for-notifications',
      component: <IntegrationTab teamId={teamId} />
    },
    {
      label: 'Notification Rules',
      slug: 'notification-rules',
      component: <NotificationRulesTab teamId={teamId} />
    }
  ];

  return tabs.filter(tab => !tab?.hide);
}

export const TeamTabs = ({
  teamId,
  refetchMembers,
  setRefetchMembers
}: {
  teamId: number;
  refetchMembers: boolean;
  setRefetchMembers: (value: boolean) => void;
}) => {
  const tabs = useTabsDefinition({ teamId, refetchMembers, setRefetchMembers });

  return (
    <Box>
      <Tabs tabs={tabs} />
    </Box>
  );
};
