import { kubernetsAutoUpdate } from './autoUpdateK8s';
import { kubernetsNoAutoUpdate } from './noAutoUpdateK8s';

const amazonCentOSInstructions = (environmentId, agentToken) => {
  return `
sudo su -
yum install git -y
git clone https://github.com/elvenworks/agent-custom.git
cd agent-custom
bash setup.sh --install ENVIRONMENT_ID=${environmentId} AGENT_TOKEN=${agentToken}
  `.trim();
};

const ubuntuInstructions = (environmentId, agentToken) => {
  return `
sudo su -
apt-get install git -y
git clone https://github.com/elvenworks/agent-custom.git
cd agent-custom
bash setup.sh --install ENVIRONMENT_ID=${environmentId} AGENT_TOKEN=${agentToken}
    `.trim();
};

export const instructions = (environmentId, agentToken, type) => {
  if (type === 'amazonLinux') {
    return amazonCentOSInstructions(environmentId, agentToken);
  }
  if (type === 'centOS7And8') {
    return amazonCentOSInstructions(environmentId, agentToken);
  }
  if (type === 'redHat') {
    return amazonCentOSInstructions(environmentId, agentToken);
  }
  if (type === 'ubuntu') {
    return ubuntuInstructions(environmentId, agentToken);
  }
  if (type === 'noAutoUpdate') {
    return kubernetsNoAutoUpdate(environmentId, agentToken);
  }
  if (type === 'autoUpdate') {
    return kubernetsAutoUpdate(environmentId, agentToken);
  }
};
