import { useState } from 'react';

import { Box, Link, Switch, Tooltip, Typography } from '@material-ui/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useDispatch } from 'react-redux';
import { UserProviderSettings, UserProviderType } from 'types/external/UserProvider';

import Dialog from 'components/Dialog';
import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import useGetUserCallRotation from 'hooks/queriesGraphQL/useGetUserCallRotation';
import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';

import { useStyles } from './styles';
import { useNotifySwitch } from './useNotifySwitch';

type Props = {
  method: UserProviderType;
  contacts: UserProviderSettings[];
  contact?: UserProviderSettings;
  refetch: () => void;
  label?: string;
  type: 'alert' | 'incident' | 'on-call';
};

type StaticContent = Record<
  UserProviderType,
  {
    label?: string;
  }
>;

const staticContent: StaticContent = {
  whatsapp: {
    label: 'WhatsApp'
  },
  telegram: {
    label: 'Telegram'
  },
  email: {
    label: 'e-mail'
  },
  sms: {
    label: 'SMS'
  },
  voice: {
    label: 'voice'
  },
  mobile: {
    label: 'Mobile App'
  }
};

const DISABLE_MESSAGE = `By disabling this channel, you will stop receiving notifications sent through it until it is reactivated. Do you wish to continue?`;
const ACTIVE_MESSAGE = `By activating this channel, you'll start receiving notifications sent through it. Do you wish to continue?`;

const notificationText = {
  alert: 'Notify me at',
  incident: 'Notify me at',
  'on-call': 'Trigger me at'
};

const NotifySwitch = ({ contact, contacts, label, method, refetch, type }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: user } = useUser();

  const currentContact = contact ?? contacts.find(contact => contact.provider.provider === method);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openCallRotationErrorModal, setOpenCallRotationErrorModal] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(Boolean(currentContact?.active));

  const { enableProvider, disableProvider } = useNotifySwitch();

  const content = staticContent[method];

  const { data: hasCallRotation } = useGetUserCallRotation({
    type,
    userUid: user?.uid,
    providerType: content?.label
  });

  if (!currentContact) return <></>;

  const onToggleStatus = () => {
    if (hasCallRotation?.length) return setOpenCallRotationErrorModal(true);

    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const disableProviderActionModal = () => {
    onCloseModal();
    setChecked(false);

    disableProvider({
      type: currentContact.type,
      providerId: currentContact.provider.id
    })
      .then(result => {
        if (result?.error) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: getErrorMessageFromUrql(result?.error.message) }
          });

          return;
        }

        if (!result?.data?.disableUserProvider) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: {
              message: 'Unexpected error when trying to deactivate'
            }
          });
          setChecked(true);
          return;
        }

        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Contact method disabled sucessfully'
        });
        refetch();
      })
      .catch(() => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: 'Unexpected error when trying to deactivate'
          }
        });
        setChecked(true);
      });
  };

  const enableProviderActionModal = () => {
    onCloseModal();
    setChecked(true);

    enableProvider({
      type: currentContact.type,
      providerId: currentContact.provider.id
    })
      .then(result => {
        if (result?.error) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: getErrorMessageFromUrql(result?.error.message) }
          });

          return;
        }

        if (!result?.data?.enableUserProvider) {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: {
              message: 'Unexpected error when trying to activate'
            }
          });
          setChecked(false);
          return;
        }

        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Contact method enabled sucessfully'
        });
        refetch();
      })
      .catch(() => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: 'Unexpected error when trying to activate'
          }
        });
        setChecked(false);
      });
  };

  const hasOnlyOneActiveProvider = contacts.filter(contact => contact.active).length === 1;

  const isLastActiveProvider = checked && hasOnlyOneActiveProvider;

  const tooltipTitle = isLastActiveProvider
    ? "Can't disable because you must have at least one notification method"
    : '';

  const providerValue = (() => {
    if (currentContact.provider.provider === 'mobile') {
      const { deviceName, modelName } = currentContact.provider.config;

      return `${deviceName} - ${modelName}`;
    }

    const value = currentContact.provider.config.value;

    const isPhoneNumberContactMethod = ['sms', 'voice', 'whatsapp'].includes(
      currentContact.provider.provider
    );

    if (isPhoneNumberContactMethod) {
      const phoneNumber = parsePhoneNumberFromString(`+${value}`);

      return phoneNumber?.formatInternational() || value;
    }

    return value;
  })();

  return (
    <>
      <Box display="flex" alignItems="center">
        <Tooltip title={tooltipTitle}>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {/* The span is needed because: https://v4.mui.com/components/tooltips/#disabled-elements */}
            <Switch
              size="small"
              color="primary"
              defaultChecked={currentContact.active}
              checked={checked}
              onChange={onToggleStatus}
              disabled={isLastActiveProvider}
            />
          </span>
        </Tooltip>
        {label && <Typography className={classes.label}>{label}</Typography>}
        {!label && (
          <Typography className={classes.label}>
            {notificationText[type]} {content.label}: {providerValue}
          </Typography>
        )}
      </Box>
      <Dialog
        title={`${checked ? 'Disable' : 'Enable'} notifications`}
        description={checked ? DISABLE_MESSAGE : ACTIVE_MESSAGE}
        open={openModal}
        onClose={onCloseModal}
        actions={[
          {
            label: 'Cancel',
            action: onCloseModal,
            variant: 'outlined'
          },
          {
            label: checked ? 'Disable' : 'Enable',
            action: checked ? disableProviderActionModal : enableProviderActionModal
          }
        ]}>
        <></>
      </Dialog>
      <Dialog
        title={`Error disabling ${content.label} notification`}
        description={
          <>
            Unable to disable this notification channel as it is linked to an active on-call shift.
            To proceed, first go to Call-Rotation, replace the channel, and then return to
            Notification Rules.If you have any questions, access the{' '}
            <Link>
              <strong>documentation</strong>
            </Link>{' '}
            and follow the steps.
          </>
        }
        open={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        actions={[
          {
            label: 'Cancel',
            action: () => setOpenErrorModal(false),
            variant: 'outlined'
          },
          {
            label: checked ? 'Disable' : 'Enable',
            disabled: true
          }
        ]}>
        <></>
      </Dialog>
      <Dialog
        title={`Error disabling ${content.label} notification`}
        description={
          <>
            Unable to disable this notification channel as it is currently linked to an active
            on-call shift.
            <br />
            <br />
            {hasCallRotation?.map((cr: any) => {
              return (
                <li key={cr.id}>
                  {cr?.org.name} - {cr.name}
                </li>
              );
            })}
          </>
        }
        open={openCallRotationErrorModal}
        onClose={() => setOpenCallRotationErrorModal(false)}
        actions={[
          {
            label: 'Cancel',
            action: () => setOpenCallRotationErrorModal(false),
            variant: 'outlined'
          },
          {
            label: checked ? 'Disable' : 'Enable',
            disabled: true
          }
        ]}>
        <></>
      </Dialog>
    </>
  );
};

export default NotifySwitch;
