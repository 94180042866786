import React from 'react';

import { Box, Grid, TextField, CircularProgress, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';

import useApiKey from 'hooks/queriesGraphQL/useApiKey';
import useApiTokens from 'hooks/queriesGraphQL/useGetApiTokens';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';

import curlText from './curlText';
import curlTextDeployV2 from './curlTextDeployV2';
import WebHookCurl from './WebHookCurl';

const useStyles = makeStyles(theme => ({
  actionsButtons: {
    color: 'primary',
    marginRight: theme.spacing(2)
  },
  linkCreateToken: {
    marginTop: theme.spacing(2)
  },
  LinkOrganization: {
    marginLeft: theme.spacing(0.5)
  }
}));

export default function DeployWebhook({ application, applicationUid, deployV2, orgUid }) {
  const [token, setToken] = React.useState();
  const [tokens, setTokens] = React.useState([]);
  const [page] = React.useState(1);
  const [sort] = React.useState('name,ASC');
  const [rowsPerPage] = React.useState(10);
  const [deployType, setDeployType] = React.useState('deploy');
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const [values, setValues] = React.useState({
    curl: ''
  });

  const [consumerId, setConsumerId] = React.useState('');
  const [apiKeyId, setApiKeyId] = React.useState('');

  const queryKey = ['apptokens/deploy', page, rowsPerPage, sort];

  const handleInvalidateQueries = () => {
    queryCache.invalidateQueries(queryKey);
  };

  const shouldUseApiTokens = useFeatureFlagUnleash('useApiTokens');

  const [{ fetching: isLoadingApiTokens, data: apiTokensData }] = useApiTokens({
    pause: !shouldUseApiTokens,
    from: 0,
    size: 50,
    query: ''
  });

  const apiTokens = apiTokensData?.tokens;

  const [{ data: apiKeyData, fetching: isLoadingApiKey }] = useApiKey({
    apiKeyId,
    consumerId
  });

  const apiKey = apiKeyData?.apiKey;

  React.useEffect(() => {
    if (shouldUseApiTokens && apiKey) {
      deployV2
        ? curlTextDeployV2(setValues, { key: apiKey?.key }, orgUid, deployType, applicationUid)
        : curlText(setValues, { key: apiKey?.key }, application, deployType);
    }
  }, [deployV2, shouldUseApiTokens, apiKey, orgUid, application, applicationUid, deployType]);

  const handleChange = event => {
    setToken(event.target.value);

    if (shouldUseApiTokens) {
      const tokenFound = apiTokens?.data?.find(
        findToken => findToken.id === Number(event?.target.value)
      );

      setConsumerId(tokenFound?.consumer?.kongConsumerId);
      setApiKeyId(tokenFound?.kongApiKeyId);

      deployV2
        ? curlTextDeployV2(setValues, tokenFound, orgUid, deployType, applicationUid)
        : curlText(setValues, tokenFound, application, deployType);

      return;
    }

    getKeyForCurl();
  };

  const { isSuccess, isLoading } = useQuery(
    queryKey,
    (key, page, rowsPerPage) =>
      axios
        .get(`/apptokens?page=${page}&per_page=${rowsPerPage}&sort=${sort}&tokenType=deploy`)
        .then(response => {
          setTokens(response.data.data);
        }),
    {
      refetchOnWindowFocus: false,
      enabled: !shouldUseApiTokens
    }
  );

  React.useEffect(() => {
    if (!shouldUseApiTokens) {
      return;
    }

    if (!tokens.length && apiTokens?.data?.length) {
      setTokens(apiTokens?.data);
    }
  }, [shouldUseApiTokens, apiTokens, tokens]);

  const [getKeyForCurl, { data }] = useMutation(
    async () => axios.get(`/apptokens/${token}?tokenType=deploy`).then(response => response.data),
    {
      onSuccess: ({ data }) => {
        deployV2
          ? curlTextDeployV2(setValues, data, orgUid, deployType, applicationUid)
          : curlText(setValues, data, application, deployType);
        handleInvalidateQueries();
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            label="Integration Token"
            required
            margin="dense"
            name="token_type"
            onChange={handleChange}
            select
            SelectProps={{ native: true }}
            value={token}
            disabled={tokens.length === 0}
            variant="outlined">
            {isLoading || isLoadingApiTokens ? (
              <option value="">...Loading</option>
            ) : (
              <option value="">Select your API token</option>
            )}
            {(isSuccess || apiTokens?.data?.length) && (
              <>
                {tokens?.map(token => {
                  return (
                    <option key={token.id} value={token.id}>
                      {token.name}
                    </option>
                  );
                })}
              </>
            )}
          </TextField>
          {tokens.length === 0 ? (
            <>
              <Typography variant="h6" className={classes.linkCreateToken}>
                You don't have an API token,
                <Link
                  href="/organization"
                  color="primary"
                  underline="none"
                  className={classes.LinkOrganization}>
                  click here to create!
                </Link>
              </Typography>
            </>
          ) : null}
        </Grid>
        <Grid item xs={12} maxWidth={300}>
          {(isLoading || isLoadingApiTokens) && <CircularProgress />}
          {token && (
            <WebHookCurl
              deployType={deployType}
              values={values}
              setDeployType={setDeployType}
              setValues={setValues}
              application={application}
              data={
                shouldUseApiTokens
                  ? apiTokens?.data?.find(findToken => findToken.id === Number(token))
                  : data?.data
              }
              token={token}
              isLoadingApiKey={isLoadingApiKey}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
