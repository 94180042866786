export const maintenanceDefaultValue = {
  id: 0,
  status: 'pending',
  applicationId: 0,
  dependencies: null,
  orgUid: '',
  serviceId: 0,
  start: '',
  userUid: '',
  finish: '',
  forceFinish: ''
};
