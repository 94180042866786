export const serviceBusStartValues = ({ method, domain_settings }) => {
  if (method !== 'servicebus') {
    return;
  }

  let domainSettingsObj = domain_settings;
  if (typeof domain_settings === 'string') {
    domainSettingsObj = JSON.parse(domain_settings);
  }

  return {
    connection_string: domainSettingsObj?.connection_string,
    type: domainSettingsObj?.type,
    topic: domainSettingsObj?.topic,
    subscription: domainSettingsObj?.subscription,
    queue: domainSettingsObj?.queue
  };
};
