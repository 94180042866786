import { apm } from '@elastic/apm-rum';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationGraphQL } from 'urql';

import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import actions from 'redux/actions';

import mapFormDataToApi from '../mapFormDataToApi';
import { UpdateApplicationMutation } from '../queries/updateApplication';

import MonitoringForm from './MonitoringForm';

const MonitoringFormEdit = ({
  onClose,
  startValues,
  predefinedSettings,
  resourceType,
  productId,
  teamId,
  applicationId,
  application,
  reexecuteQueryUniqueResourceStatus
}) => {
  const dispatch = useDispatch();

  const [{ fetching }, updateApplication] = useMutationGraphQL(UpdateApplicationMutation);

  const [submitMutation, { error: errorUseMutation, isLoading }] = useMutation(
    async formData => {
      const data = mapFormDataToApi({
        formData,
        resourceType,
        productId,
        applicationId
      });

      const response = await updateApplication({ data });

      if (response.error) {
        throw new Error(getErrorMessageFromUrql(response.error));
      }

      return {
        data: {
          data: response.data.updateApplication
        }
      };
    },
    {
      onSuccess: () => {
        onClose();
        dispatch({
          type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
          params: { productId, applicationId }
        });
        reexecuteQueryUniqueResourceStatus();
        return;
      },
      onError: error => {
        apm.captureError(error);

        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: 'Error to update application. Please try again or contact our support'
          }
        });
      }
    }
  );

  return (
    <MonitoringForm
      applicationId={applicationId}
      submitMutation={submitMutation}
      errorUseMutation={errorUseMutation}
      isLoading={isLoading || fetching}
      application={application}
      onClose={onClose}
      teamId={teamId}
      startValues={startValues}
    />
  );
};

export default MonitoringFormEdit;
