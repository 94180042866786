import { useRouteMatch } from 'react-router';

import NewResourcesDialog from '../components/Resources/Dialog/NewResourcesDialog';

import Datagrid from './Datagrid';

const TabAvailability = () => {
  const productId = Number(useRouteMatch().params.id);

  return (
    <div>
      <div
        style={{
          paddingBottom: 10
        }}>
        <NewResourcesDialog productId={productId} />
      </div>

      <Datagrid productId={Number(productId)} />
    </div>
  );
};

export default TabAvailability;
