import { Box, Button, Link } from '@material-ui/core';
import { Add, Loop } from '@material-ui/icons';
import { UseFormMethods } from 'react-hook-form';

import { LoadingOverlay } from 'componentsV4/Loading';

import { Select } from '../../../ServicesHub/components/Select';

type MonitoringSelectProps = {
  form: UseFormMethods<any>;
  options: { label: string; value: string; helperText: string }[];
  fetching: boolean;
  required: boolean;
  refetch: () => void;
};

export function MonitoringSelect({
  form: { control },
  refetch,
  options,
  required,
  fetching
}: MonitoringSelectProps) {
  if (fetching) {
    return <LoadingOverlay />;
  }

  return (
    <Box position="relative" display="flex" gridGap="1.5rem">
      <Select
        options={options}
        name="entityUid"
        label="Choose monitoring main"
        control={control}
        required={required}
      />

      <Box display="flex" gridGap="1rem">
        <Button
          variant="outlined"
          color="primary"
          title="Reload monitoring list"
          onClick={() => refetch()}>
          <Loop />
        </Button>
        <Link target="_blank" href="/monitoring">
          <Button variant="outlined" color="primary">
            <Add /> MONITORING
          </Button>
        </Link>
      </Box>
    </Box>
  );
}
