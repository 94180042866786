import { useState } from 'react';

import { Product } from 'constants/Product';

import {
  Card,
  makeStyles,
  Typography,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from '@material-ui/lab-old';
import { useTheme } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import LastUpdate from 'componentsV3/CardApplication/LastUpdate';
import DropDownApplications from 'componentsV3/DropDownApplications';
import LinkButton from 'componentsV3/LinkButton';
import { useProductPermission } from 'hooks/useProductPermission';
import QueryStats from 'icons/QueryStats';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

import StatusLine from './StatusLine';

const useStyles = makeStyles<Theme>(theme => {
  return {
    root: {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    },
    image: {
      display: 'block',
      width: 146,
      height: 146,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.inputBorder.default}`,
      borderRadius: 8,
      '& > img': {
        objectFit: 'contain',
        width: '100%',
        height: '100%'
      }
    },
    menu: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    MenuItem: {
      padding: theme.spacing(1, 2),
      color: theme.palette.primary.main
    },
    iconButton: {
      position: 'absolute',
      top: theme.spacing(),
      right: theme.spacing(),
      zIndex: 1
    }
  };
});

const CardResource = (
  props: Product & {
    isLoadingAdditionalData: boolean;
    title: string;
    linkTo: string;
    image: string;
    appLink: string;
    teamsLink: string;
    showMetricButton: boolean;
    showStatusLine: boolean;
    showLoadingAdditionalData: boolean;
  }
) => {
  const {
    id,
    appLink,
    teamsLink,
    linkTo,
    image,
    isLoadingAdditionalData,
    title,
    lastEvent,
    totalApplications,
    showMetricButton,
    showStatusLine,
    showLoadingAdditionalData
  } = props;

  const classes = useStyles();
  const theme = useTheme<Theme>();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasNewAppPermission = useProductPermission({
    productId: String(id),
    target: 'ApplicationController-post-/products/:productId/applications'
  });

  return (
    <Card className={classes.root}>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{ style: { paddingTop: 16, paddingBottom: 16 } }}
        transformOrigin={{
          vertical: -40,
          horizontal: 'left'
        }}>
        <Link to={appLink}>
          {hasNewAppPermission && (
            <MenuItem className={classes.MenuItem} onClick={handleClose}>
              Add new application
            </MenuItem>
          )}
        </Link>
        <Link to={teamsLink}>
          <MenuItem className={classes.MenuItem} onClick={handleClose}>
            Teams and call rotation
          </MenuItem>
        </Link>
      </Menu>

      <Box display="flex" width="100%">
        <Box marginRight={3}>
          <Tooltip title="View product">
            <Link to={linkTo} className={classes.image}>
              <img
                alt="Product"
                src={image || `${process.env.PUBLIC_URL}/images/not_product_2.png`}
              />
            </Link>
          </Tooltip>
        </Box>

        <Box width="100%" display="flex" alignItems="center" position="relative">
          <Box display="flex" flexDirection="column" flex={1}>
            {showStatusLine &&
              (isLoadingAdditionalData ? (
                <Skeleton width="80%" height="25px" />
              ) : (
                <StatusLine {...props} productId={id} />
              ))}
            <Tooltip title="View product" placement="top-start">
              <Link to={linkTo}>
                <Typography variant="h5" gutterBottom>
                  {title}
                </Typography>
              </Link>
            </Tooltip>
            {showLoadingAdditionalData &&
              (isLoadingAdditionalData ? (
                <Skeleton variant="rect" width="80%" height="50px" />
              ) : lastEvent ? (
                <LastUpdate
                  type={lastEvent.type}
                  incidentId={lastEvent.incidentId}
                  applicationId={lastEvent.applicationId}
                  applicationName={lastEvent.applicationName}
                  productId={id}
                  createdAt={new Date(lastEvent.createdAt)}
                  inlineDate={false}
                  textStylePrimary={{ fontSize: '12px', fontWeight: 500, color: '#595959' }}
                  textStyleSecondary={{ fontSize: '12px', color: '#8C8C8C' }}
                />
              ) : null)}
          </Box>
        </Box>
      </Box>

      <Box mt={3} mb={2}>
        <Divider style={{ background: theme.palette.inputBorder.default }} />
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <DropDownApplications productId={id} totalApplications={totalApplications} />
        <Box display="flex" alignItems="center">
          {showMetricButton ? (
            <Link to="/metrics?tab=monitoring-performance">
              <LinkButton
                startIcon={
                  <QueryStats
                    style={{ fontSize: 14, marginLeft: theme.spacing(0.5), color: '#5FBC91' }}
                  />
                }>
                View metrics
              </LinkButton>
            </Link>
          ) : (
            <Link to={linkTo}>
              <LinkButton
                endIcon={
                  <ArrowForwardIos
                    style={{ fontSize: 14, marginLeft: theme.spacing(0.5), color: '#5FBC91' }}
                  />
                }>
                View product
              </LinkButton>
            </Link>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default withThemeV3Hoc(CardResource);
