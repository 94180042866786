import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

const GetDowntimeInsightsQuery = `#graphql
    query(
      $startDate: String!
      $endDate: String!
    ){
        insights{
            monitoringPerformance {
                downtime(startDate: $startDate, endDate: $endDate){
                    period
                    compared
                }
            }
        }
    }
`;

export const DowntimeMetrics = () => {
  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetDowntimeInsightsQuery,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const downtime = data?.insights?.monitoringPerformance?.downtime;

  return (
    <Metrics
      name="Downtime"
      value={downtime?.period}
      valueFormat={MetricsValueFormatEnum.Time}
      tooltipTitle="Time of inactivity in your monitoring."
      isFetching={fetching}
      diff={downtime?.compared}
      diffFormat={MetricsValueFormatEnum.Percentile}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
