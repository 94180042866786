import React from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationURQL } from 'urql';

import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';

import AppDialog from '../AppDialog';
import DeleteDialog from '../AppDialog/DeleteDialog';
import setResponse from '../AppDialog/setResponse';
import Message from '../Message';

import cols from './columns';

const DeleteAppTokenMutation = `#graphql
  mutation($appTokenId: Int!) {
    deleteAppToken(appTokenId: $appTokenId)
  }
`;

const DeleteTokenMutation = `#graphql
  mutation($tokenUid: String!) {
    deleteToken(tokenUid: $tokenUid)
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  titleToken: {
    fontWeight: 'bold'
  }
}));

const Datagrid = ({
  permissions,
  isLoadingApiTokens,
  isErrorApiTokens,
  apiTokens,
  rowsPerPage,
  setRowsPerPage,
  refetchTokens
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: user } = useUser();
  const org = user?.logged?.org;

  const shouldUseApiTokens = useFeatureFlagUnleash('useApiTokens');

  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [sort] = React.useState('name,ASC');
  const [mutateValues, setMutateValues] = React.useState({
    id: '',
    name: '',
    tokenUid: ''
  });
  const [values, setValues] = React.useState({
    id: '',
    name: '',
    access: '',
    secret: '',
    token_type: '',
    curl: ''
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const queryCache = useQueryCache();
  const queryKeyDeploy = ['apptokens/deploy', page, rowsPerPage, sort];
  const queryKeyHits = ['apptokens/hits', page, rowsPerPage, sort];

  const handleInvalidateQueries = () => {
    queryCache.invalidateQueries(queryKeyDeploy);
    queryCache.invalidateQueries(queryKeyHits);
  };

  const {
    data: dataDeploy,
    isSuccess: isSuccessDeploy,
    isError: isErrorDeploy,
    isLoading: isLoadingDeploy
  } = useQuery(
    queryKeyDeploy,
    (key, page, rowsPerPage) =>
      axios
        .get(`/apptokens?page=${page}&per_page=${rowsPerPage}&sort=${sort}&tokenType=deploy`)
        .then(response => response.data),
    {
      refetchOnWindowFocus: false,
      enabled: !shouldUseApiTokens
    }
  );
  const {
    data: dataHits,
    isSuccess: isSuccessHits,
    isError: isErrorHits,
    isLoading: isLoadingHits
  } = useQuery(
    queryKeyHits,
    (key, page, rowsPerPage) =>
      axios
        .get(`/apptokens?page=${page}&per_page=${rowsPerPage}&sort=${sort}&tokenType=hits/failures`)
        .then(response => response.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const isError = isErrorHits || isErrorDeploy || isErrorApiTokens;
  const isLoading = isLoadingHits || isLoadingDeploy || isLoadingApiTokens;
  const isSuccess = Boolean(isSuccessHits || isSuccessDeploy || apiTokens?.data);

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'of',
    noRowsPerPage: false
  };

  const shouldDeleteAppTokenFromBFF = useFeatureFlagUnleash('useDeleteAppTokenFromBFF');

  const [, deleteAppTokenGraphqlMutation] = useMutationURQL(DeleteAppTokenMutation);
  const [, deleteTokenMutation] = useMutationURQL(DeleteTokenMutation);

  const [handleRemove, { isLoading: isLoadingDelete }] = useMutation(
    async ({ id, tokenType, tokenUid }) => {
      if (tokenType === 'api_token') {
        const response = await deleteTokenMutation({
          tokenUid
        });

        if (response.error) {
          throw new Error(response.error.message.replace('[GraphQL] ', ''));
        }

        return;
      }

      if (shouldDeleteAppTokenFromBFF) {
        const response = await deleteAppTokenGraphqlMutation({
          appTokenId: id
        });

        if (response.error) {
          throw new Error(response.error.message.replace('[GraphQL] ', ''));
        }

        return;
      }

      return axios.delete(`/apptokens/${id}?tokenType=${tokenType}`);
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          refetchTokens();
        }, 3000);
        handleInvalidateQueries();
        handleCloseDelete();
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Credentials Removed'
        });
      },

      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const [handleRegenerateToken, { isLoading: mutationLoading }] = useMutation(
    async () =>
      axios
        .put(`/apptokens/${mutateValues.id}?tokenType=${mutateValues.tokenType}`, {
          name: values.name
        })
        .then(response => response.data),
    {
      onSuccess: ({ data }) => {
        setResponse(setValues, data, org, mutateValues.tokenType);
        handleInvalidateQueries();
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  const { isLoading: queryLoading, isError: queryError, isSuccess: querySuccess } = useQuery(
    'editToken',
    async () =>
      axios
        .get(`/apptokens/${mutateValues.id}?tokenType=${mutateValues.tokenType}`)
        .then(response => response.data),
    {
      onSuccess: ({ data }) => {
        if (data) {
          setResponse(setValues, data, org, mutateValues.tokenType);
        } else {
          // invalidateQuery();
          handleClose();
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: { message: 'App credentials not found' },
            ga: { category: 'ERROR' }
          });
        }
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      },
      enabled: open,
      refetchOnWindowFocus: false
    }
  );
  const columns = [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row =>
        row.tokenType === 'hits/failures' ? (
          <IconButton
            title="Edit"
            disabled={!permissions.update}
            onClick={() => {
              handleOpen();
              setMutateValues({
                ...mutateValues,
                id: row.id,
                name: row.name,
                tokenType: row.tokenType
              });
            }}>
            <EditIcon />
          </IconButton>
        ) : null
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <IconButton
          disabled={!permissions.remove}
          title="Remove"
          onClick={() => {
            handleOpenDelete();
            setMutateValues({
              ...mutateValues,
              id: row.id,
              name: row.name,
              tokenType: row.tokenType || row.type,
              tokenUid: row.uid
            });
          }}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight={100}>
        <CircularProgress />
      </Box>
    );
  }

  const total =
    Number(dataHits?.total ?? 0) + Number(dataDeploy?.total ?? 0) + Number(apiTokens?.total ?? 0);

  if (isSuccess && !total) {
    return <Message>You don't have app tokens yet.</Message>;
  }

  const columnsWithoutAuthor = columns.filter(column => column.name !== 'Author');

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {isError && "Couldn't load tokens :("}

        {isSuccess && Number(dataHits?.total ?? 0) > 0 ? (
          <>
            <Typography className={classes.titleToken}> Hits/Failures Tokens </Typography>
            <DataTable
              clicked
              columns={shouldUseApiTokens ? columnsWithoutAuthor : columns}
              data={dataHits.data}
              highlightOnHover
              noDataComponent="No data"
              onChangePage={setPage}
              onChangeRowsPerPage={setRowsPerPage}
              // onSort={setSort}
              pagination
              paginationDefaultPage={page}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50]}
              paginationServer
              paginationTotalRows={dataHits.total}
              sortServer
              striped
              noHeader
            />
            <AppDialog
              mutationLoading={mutationLoading}
              queryLoading={queryLoading}
              isError={queryError}
              values={values}
              setValues={setValues}
              isSuccess={querySuccess}
              title={'View App Token'}
              open={open}
              permissions={permissions}
              handleClose={handleClose}
              handleOpen={handleOpen}
              handleReGenerateToken={handleRegenerateToken}
            />
            <DeleteDialog
              handleClose={handleCloseDelete}
              open={openDelete}
              handleDelete={handleRemove}
              isLoadingDelete={isLoadingDelete}
              values={mutateValues}
            />
            {/* <EditAppDialog
              queryCache={queryCache}
              invalidateQuery={handleInvalidateQueries}
              open={open}
              handleClose={handleClose}
              values={editValues}
              setValues={setEditValues}
            /> */}
          </>
        ) : null}
        {!shouldUseApiTokens && isSuccess && Number(dataDeploy?.total ?? 0) > 0 ? (
          <>
            <Typography className={classes.titleToken}> Deploy Tokens </Typography>
            <DataTable
              clicked
              columns={columns}
              data={dataDeploy.data}
              highlightOnHover
              noDataComponent="No data"
              onChangePage={setPage}
              onChangeRowsPerPage={setRowsPerPage}
              // onSort={setSort}
              pagination
              paginationDefaultPage={page}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50]}
              paginationServer
              paginationTotalRows={dataDeploy.total}
              sortServer
              striped
              noHeader
            />
            <DeleteDialog
              handleClose={handleCloseDelete}
              open={openDelete}
              handleDelete={handleRemove}
              isLoadingDelete={isLoadingDelete}
              values={mutateValues}
            />
          </>
        ) : null}
        {shouldUseApiTokens && isSuccess && apiTokens?.data?.length ? (
          <>
            <Typography className={classes.titleToken}>API Tokens</Typography>
            <DataTable
              clicked
              columns={shouldUseApiTokens ? columnsWithoutAuthor : columns}
              data={apiTokens?.data}
              highlightOnHover
              noDataComponent="No data"
              onChangePage={setPage}
              onChangeRowsPerPage={setRowsPerPage}
              pagination
              paginationDefaultPage={page}
              paginationComponentOptions={paginationComponentOptions}
              paginationRowsPerPageOptions={[10, 25, 50]}
              paginationServer
              paginationTotalRows={apiTokens?.total}
              sortServer
              striped
              noHeader
            />
            <DeleteDialog
              handleClose={handleCloseDelete}
              open={openDelete}
              handleDelete={handleRemove}
              isLoadingDelete={isLoadingDelete}
              values={mutateValues}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Datagrid;
