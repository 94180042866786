import { OverrideFn } from '.';

export const MuiInputLabel: OverrideFn<'MuiInputLabel'> = theme => ({
  root: {
    '&$focused': {
      color: theme.palette.gray[700]
    },
    '&:hover': {
      color: theme.palette.gray[700]
    }
  }
});
