import { Application } from 'types/external/ApplicationManager';
import { useQuery } from 'urql';

const GetApplicationV2Query = `#graphql
query ($applicationUid: String!) {
    applicationV2(
        applicationUid: $applicationUid
    ) {
      id
      uid
      entityUid
      entity
      name
      productId
    }
  }
`;

export const useApplication = (applicationUid: string) =>
  useQuery<{ applicationV2: Application }>({
    query: GetApplicationV2Query,
    variables: { applicationUid }
  });
