export const CreateManualIncident = `#graphql
mutation ($incidentManual: CreateManualIncidentInput!) {
  createManualIncident(
    incidentManual: $incidentManual
  ) {
    id
  }
}
`;

export const UpdateManualIncident = `#graphql
mutation ($incidentManual: UpdateManualIncidentArgs!) {
  updateManualIncident(
    incidentManual: $incidentManual
  )
}
`;
