import { Box } from '@material-ui/core';

import { useMultiOrigins } from 'hooks/queriesGraphQL/useMultiOriginsMonitorings';
import { useProducts } from 'hooks/queriesGraphQL/useProducts';
import { MonitoringSelect } from 'views/Applications/components/MonitoringSelect';
import { ProductSelect } from 'views/Applications/components/ProductSelect';
import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

export type ApplicationFormInput = {
  name: string;
  dependencies: { entityUid: string; dependencyUid: string }[];
  entityUid: string;
  productId?: string;
};

export function ApplicationForm({ form }: FormProps) {
  const { register } = form;

  const [{ data, fetching: isFetching }, refetch] = useMultiOrigins();
  const monitoringList = data?.getMultiOriginsMonitorings?.data;
  const monitoringOptions =
    monitoringList?.map((item: { uid: string; name: string; entity: string }) => {
      return {
        value: item.uid,
        label: item.name,
        helperText: item.entity === 'Multiflow' ? 'Synthetic' : item.entity
      };
    }) ?? [];

  const monitoringOptionsWithDefault = [
    {
      value: '',
      label: 'Select one',
      helperText: ''
    },
    ...monitoringOptions
  ];

  const [{ data: productList, fetching: isFetchingProducts }] = useProducts();
  const productOptions =
    productList?.products?.data?.map((product: { id: number; name: string }) => {
      return { value: product.id.toString(), label: product.name };
    }) ?? [];
  const productOptionsWithDefault = [
    {
      value: '',
      label: 'None'
    },
    ...productOptions
  ];

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input
        label="Display name"
        name="name"
        inputRef={register({
          required: true
        })}
        required={true}
        errors={form.errors}
      />
      <ProductSelect
        form={form}
        required={false}
        options={productOptionsWithDefault}
        fetching={isFetchingProducts}
        refetch={() => refetch()}
      />

      <MonitoringSelect
        form={form}
        required={true}
        options={monitoringOptionsWithDefault}
        fetching={isFetching}
        refetch={() => refetch()}
      />
    </Box>
  );
}
