import { Chip } from '@material-ui/core';
import { ExternalIntegrationStatusEnum } from 'types/external/ExternalService';

import { useStyles } from './styles';

export type StatusChipProps = {
  status: ExternalIntegrationStatusEnum;
};

function getLabelByStatus(status: ExternalIntegrationStatusEnum) {
  const statusLabelMap = {
    [ExternalIntegrationStatusEnum.Outage]: 'Outage',
    [ExternalIntegrationStatusEnum.Available]: 'Operational',
    [ExternalIntegrationStatusEnum.Pending]: 'Pending'
  };

  return statusLabelMap[status];
}

export function StatusChip({ status }: StatusChipProps) {
  const classes = useStyles({ status });

  return <Chip className={classes.chip} label={getLabelByStatus(status)} />;
}
