import SelectSimple from 'componentsV3/Select';

const VnetSelect = ({ errors, control, vnetList, required, disabled }) => {
  return (
    <SelectSimple
      disabled={disabled}
      errors={errors}
      name="vnet"
      control={control}
      label="Virtual Network"
      defaultValue=""
      items={vnetList}
      required={required}
    />
  );
};

export default VnetSelect;
