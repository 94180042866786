import React, { useCallback, useEffect } from 'react';

import { Box, Button, MenuItem } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { accountExists as accountExistsQuery } from 'graphqlQueries/accountExists';
import { Controller, UseFormMethods, useFieldArray } from 'react-hook-form';
import { useClient } from 'urql';

import { User } from 'views/ServicesHub/adapters/new/user';
import { ServiceNowData } from 'views/ServicesHub/layouts/ItsmServiceNow';

import { Input } from '../../components/Input';

import { Role } from './utils/useDefaultUser';

type Props = {
  form: UseFormMethods<ServiceNowData>;
  isEdit: boolean;
  startValues: User;
  defaultUser: User;
  organizationRoles: Role[] | null;
  setHasInvalidEmail: (param: boolean) => void;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function UserForm({
  form,
  isEdit,
  startValues,
  defaultUser,
  organizationRoles,
  setHasInvalidEmail
}: Props) {
  const urqlClient = useClient();

  const { register, control, errors: formErrors, getValues, setError } = form;

  const { fields, append: addMoreUser, remove: removeUser } = useFieldArray({
    control: form.control,
    name: 'users',
    keyName: 'userId'
  });

  const verifyAccountExists = useCallback(
    async (email: string) => {
      const response = await urqlClient.query(accountExistsQuery, { email }).toPromise();
      return response.data.accountExists;
    },
    [urqlClient]
  );

  const errors = (formErrors as any)?.users
    ? (formErrors as any).users.reduce((_users: any, user: any, index: any) => {
        if (user && typeof user === 'object') {
          Object.keys(user!).forEach(key => {
            _users[`users.${index}.${key}`] = user![key as keyof typeof user];
          });
        }

        return _users;
      }, {} as Record<string, any>)
    : {};

  useEffect(() => {
    if (fields.length === 0 && defaultUser && organizationRoles) {
      addMoreUser(defaultUser);
    }
  }, [fields.length, addMoreUser, defaultUser, organizationRoles]);

  useEffect(() => {
    if (isEdit && startValues) {
      addMoreUser(startValues);
      removeUser(0);
    }
  }, [startValues, removeUser, addMoreUser, isEdit]);

  return (
    <Box position="relative" flex={2} display="flex" flexDirection="column">
      {organizationRoles &&
        fields.map((user, userIndex) => {
          return (
            <React.Fragment key={user.userId}>
              <Box display="flex" key={user.userId} gridGap="1rem" mt="32px">
                <Input
                  label="Email"
                  disabled={isEdit}
                  name={`users.${userIndex}.email`}
                  onBlur={async () => {
                    const fieldKey: any = `users.${userIndex}.email`;

                    if (isEdit) return;

                    const email: string = getValues(`users.${userIndex}.email`);

                    setHasInvalidEmail(false);

                    if (errors[fieldKey]) {
                      setError(fieldKey, {
                        type: errors[fieldKey]?.type,
                        message: errors[fieldKey]?.message
                      });
                    }

                    if (await verifyAccountExists(email)) {
                      setError(fieldKey, {
                        type: 'validate',
                        message: 'This user has already been added to this organization.'
                      });
                      setHasInvalidEmail(true);
                    } else {
                      setHasInvalidEmail(false);
                    }
                  }}
                  onFocus={() => setHasInvalidEmail(true)}
                  inputRef={register()}
                  defaultValue={user.email}
                  errors={errors}
                />

                <Input
                  label="Name"
                  disabled={isEdit}
                  name={`users.${userIndex}.name`}
                  inputRef={register()}
                  defaultValue={user.name}
                  errors={errors}
                />

                <Controller
                  control={control}
                  name={`users.${userIndex}.role`}
                  render={({ value, onChange }) => {
                    return (
                      <Input
                        errors={errors}
                        style={{
                          width: '15%'
                        }}
                        label="Role"
                        defaultValue={user.role}
                        value={value}
                        select
                        onChange={event => onChange(event.target.value)}>
                        {organizationRoles
                          ?.sort((role, nextRole) => role.id - nextRole.id)
                          .map(role => {
                            return (
                              <MenuItem key={role.id} value={role.id}>
                                {capitalizeFirstLetter(role.name)}
                              </MenuItem>
                            );
                          })}
                      </Input>
                    );
                  }}
                />
                {!isEdit && (
                  <Box display="flex" height={40} gridGap={16}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => removeUser(userIndex)}>
                      <Delete />
                    </Button>
                  </Box>
                )}
                <input
                  ref={register()}
                  type="hidden"
                  name={`users.${userIndex}.userId`}
                  defaultValue={user?.userId}
                />
              </Box>
              {isEdit && (
                <Controller
                  control={control}
                  name={`users.${userIndex}.status`}
                  render={({ value, onChange }) => {
                    return (
                      <Input
                        errors={errors}
                        style={{
                          width: '100%',
                          marginTop: '32px'
                        }}
                        label="User Status"
                        defaultValue={user.status}
                        value={value}
                        select
                        onChange={event => onChange(event.target.value)}>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="disable">Disabled</MenuItem>
                      </Input>
                    );
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      <Box hidden={isEdit} mt="24px" mb={4}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            addMoreUser(defaultUser);
          }}>
          <Add /> USERS
        </Button>
      </Box>
    </Box>
  );
}
