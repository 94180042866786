import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    paddingTop: 0
  },
  paper: {
    borderRadius: 15
  },
  closeButton: {
    fontSize: 28,
    color: theme.palette.primary.main,
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    margingBotton: theme.spacing(2)
  }
}));
