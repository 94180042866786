import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  makeStyles,
  ListItem
} from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import useUser from 'hooks/queriesRest/useUser';

import WhatsappIntegrationInstructionsDialog from '../IntegrationsInstructions/WhatsappIntegrationInstructionsDialog';

import CheckboxField from './CheckboxField';

const useStyles = makeStyles(theme => ({
  root: {},
  select: {
    width: '200px'
  }
}));

const WhatsappField = ({
  control,
  errors,
  field,
  index,
  register,
  newTeam,
  numberOptions,
  numbers,
  setValue,
  disabled
}) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const classes = useStyles();

  const { data: teamCreator } = useUser({
    refetchOnWindowFocus: false
  });

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  const SelectMembersToNotifyByWhatsapp = numberOptions => {
    if (
      newTeam &&
      teamCreator?.profile?.verifiedPhone &&
      teamCreator?.displayName &&
      teamCreator?.profile?.phone
    ) {
      return (
        <MenuItem key={teamCreator?.id} value={teamCreator?.profile.phone}>
          <Checkbox
            color="primary"
            checked={numbers.some(option => option === teamCreator?.profile.phone)}
            disabled={disabled}
          />
          <ListItemText primary={teamCreator?.displayName} />
        </MenuItem>
      );
    }

    if (numberOptions) {
      return numberOptions
        .map(number => {
          const format = '+$1 ($2) $3-$4';

          if (number.phone.length === 13) {
            return {
              ...number,
              phoneWithMask: number.phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, format)
            };
          }

          if (number.phone.length === 12) {
            return {
              ...number,
              phoneWithMask: number.phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, format)
            };
          }

          if (number.phone.length === 11) {
            return {
              ...number,
              phoneWithMask: number.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/g, format)
            };
          }

          return number;
        })
        .map(number => {
          return (
            <ListItem key={number.id} value={number.phone}>
              <Checkbox color="primary" checked={numbers.some(option => option === number.phone)} />
              <ListItemText primary={`${number.name}, ${number.phoneWithMask}`} />
            </ListItem>
          );
        });
    }

    return null;
  };

  return (
    <Grid xs={12} item>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="WhatsApp"
      />
      <CheckboxField
        name={enabledFieldName}
        defaultValue={field.enabled}
        disabled={disabled}
        control={control}
        onChange={enabledFieldName => {
          if (!enabledFieldName) {
            return setValue(`notificationSetup[${index}].config.numbers`, []);
          }
        }}>
        <img alt="whatsapp" src="/images/whatsapp.svg" height={22} />
        <Box margin="0 10px" minWidth="15vw">
          <Typography>
            Send a notification to WhatsApp following the <WhatsappIntegrationInstructionsDialog />
          </Typography>
        </Box>
        <FormControl error={Boolean(error?.config?.numbers)}>
          <Controller
            id={field.id}
            name={`notificationSetup[${index}].config.numbers`}
            control={control}
            defaultValue={field.config.numbers}
            render={props => {
              const { onChange, ...otherProps } = props;

              return (
                <Select
                  id="demo-mutiple-checkbox"
                  labelId="demo-mutiple-checkbox-label"
                  disabled={disabled}
                  displayEmpty
                  multiple
                  className={classes.select}
                  style={{ minWidth: '5vw' }}
                  MenuProps={{
                    // https://stackoverflow.com/a/64753453
                    getContentAnchorEl: null
                  }}
                  renderValue={phones => {
                    if (!phones.length) {
                      return <em>Select members</em>;
                    }

                    const listMembers = [];

                    if (newTeam) {
                      listMembers.push(teamCreator?.displayName);
                      return listMembers.join(',  ');
                    }

                    if (numberOptions) {
                      numberOptions.forEach(element => {
                        if (phones.find(phone => phone === element.phone)) {
                          listMembers.push(element.name);
                        }
                      });
                    }

                    return listMembers.join(',  ');
                  }}
                  onChange={event => {
                    const value = event.target.value;

                    setValue(enabledFieldName, Boolean(value.length));

                    onChange(event.target.value);
                  }}
                  {...otherProps}>
                  {SelectMembersToNotifyByWhatsapp(numberOptions)}
                </Select>
              );
            }}
          />
          <FormHelperText>{error?.config?.numbers?.message}</FormHelperText>
        </FormControl>
      </CheckboxField>
    </Grid>
  );
};

export default WhatsappField;
