import React from 'react';

import { Grid } from '@material-ui/core';
import { TextField } from 'oldComponents/Inputs';
import CheckBox from 'oldComponents/ResourceInputs/CheckBox';
import SecretsField from 'oldComponents/SecretsField';

export const RedisForm = ({
  control,
  errors,
  setValue,
  watch,
  getValues,
  register,
  disabled = false,
  startValues
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={2} sm={12}>
        <CheckBox
          inputRef={register}
          errors={errors}
          name="is_cluster"
          tooltipText="Check it up if you are going to monitor a Redis cluster"
          label="Cluster"
          defaultChecked={startValues?.is_cluster}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={2} sm={12}>
        <CheckBox
          inputRef={register}
          errors={errors}
          name="tls"
          tooltipText="Check it up if you are going to monitor a Redis TLS Connection"
          label="TLS"
          defaultChecked={startValues?.tls}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={2} sm={6}>
        <SecretsField
          TextFieldProps={{
            label: 'User',
            name: 'user'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={2} sm={6}>
        <SecretsField
          TextFieldProps={{
            label: 'Password',
            name: 'password'
          }}
          control={control}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          errors={errors}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={2} sm={6}>
        <TextField
          label="Database"
          name="db"
          register={register}
          required={true}
          errors={errors}
          type="number"
          defaultValue={0}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={2} sm={6}>
        <TextField
          label="Max Retries"
          name="max_retries"
          register={register}
          required={true}
          errors={errors}
          type="number"
          defaultValue={1}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
