import React from 'react';

import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import DataTable from 'react-data-table-component';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import useListStepGroups from 'hooks/useListStepGroups';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

import NewStepsGroupDialog from '../NewStepGroupDialog';
import useForm, { timeUnits } from '../NewStepGroupDialog/useForm';

const DataGrid = () => {
  const { id: teamId } = useParams();

  const editCallRotation = usePermission('CallrotationController-put-/callrotation/schedule/:id');

  const [deleteModalState, setDeleteModalState] = React.useState({ isOpen: false, id: null });

  const [selectedStepsGroup, setSelectedStepsGroup] = React.useState(null);

  const queryKey = ['stepGroups', teamId];

  const queryCache = useQueryCache();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { stepsGroups, isFetching, reexecuteQuery } = useListStepGroups({ teamId, queryKey });

  const handleStepsGroupChange = value => {
    setSelectedStepsGroup(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formProps = useForm({
    onSubmit: response => {
      invalidateQuery();
      handleClose();
      handleStepsGroupChange(response.data.data.id);
    },
    serverData: stepsGroups?.find(stepsGroup => stepsGroup.id === selectedStepsGroup)
  });

  const invalidateQuery = () => {
    queryCache.invalidateQueries(queryKey);
  };

  const dispatch = useDispatch();

  const [deleteStepsGroup] = useMutation(id => axios.delete(`/callrotation/stepGroups/${id}`), {
    onSuccess: () => {
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Step Group successfully removed from team'
      });
      invalidateQuery(queryKey);
      reexecuteQuery();
      handleStepsGroupChange(null);
    },
    onError: err => {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: err,
        ga: { category: 'ERROR' }
      });
    }
  });

  const columns = [
    {
      grow: 2,
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      grow: 2,
      name: 'Steps',
      selector: row => row.steps?.length
    },
    {
      grow: 0.5,
      compact: true,
      center: true,
      cell: row => (
        <IconButton
          title="Edit Step Groups"
          onClick={() => {
            formProps.reset({
              stepsGroupName: row?.name,
              steps: row.steps?.map(step => ({
                ...step,
                teamMemberToContact: step.teamMemberToContact?.id
                  ? step.teamMemberToContact.id
                  : null,
                timeout: step.timeoutInMinutes,
                interval: step.intervalInMinutes,
                timeoutTimeUnit: timeUnits.minutes.value,
                intervalTimeUnit: timeUnits.minutes.value
              }))
            });
            handleStepsGroupChange(row?.id);
            handleClickOpen();
          }}
          user-data={row?.name}
          variant="outlined">
          <EditIcon />
        </IconButton>
      )
    },
    {
      grow: 0.5,
      compact: true,
      center: true,
      cell: row => (
        <IconButton
          onClick={() => setDeleteModalState({ isOpen: true, id: row.id })}
          user-data={row?.name}
          title="Edit"
          variant="outlined">
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'of',
    noRowsPerPage: false
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={stepsGroups}
        highlightOnHover
        noDataComponent="No data"
        noHeader
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={0}
        sortServer
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={isFetching}
        striped
      />

      <NewStepsGroupDialog
        open={open}
        mode="edit"
        onClose={handleClose}
        formProps={formProps}
        startValues={stepsGroups?.find(stepsGroup => stepsGroup.id === selectedStepsGroup)}
        canCreate={editCallRotation}
      />

      <DeleteEntity
        open={deleteModalState.isOpen}
        handleClose={() => setDeleteModalState({ row: null, open: false })}
        entityName="Step Group"
        handleRemove={() => {
          deleteStepsGroup(deleteModalState.id);
          setDeleteModalState({ isOpen: false, id: null });
        }}
        isLoading={isFetching}
      />
    </>
  );
};

export default DataGrid;
