import { useEffect } from 'react';

import { useQuery as useQueryGraphQL } from 'urql';

import { useFeaturePlan } from 'hooks/useFeaturePlan';
import getCreatedAt from 'views/Products/View/Availability/View/BottomSection/Timeline/getCreatedAt';

const GetTimelineQuery = `#graphql
  query (
    $index: String!
    $termValues: [String!]
    $from: Int!
    $size: Int!
    $gte: Int!
  ) {
    getTimeline(
      index: $index
      termKey: "application_id"
      termValues: $termValues
      from: $from
      gte: $gte
      size: $size
    ) {
      data {
        id
        applicationId
        applicationName
        addonId
        content {
          name
          version
          createdAt
          description
        }
        incidentId
        type
        createdAt
      }
    }
  }
`;

type GetTimelineType = {
  getTimeline: {
    data: any[];
  };
};

const useTimeline = ({
  applicationIds,
  from = 0,
  size = 5
}: {
  applicationIds: string[];
  from: number;
  size: number;
}) => {
  const index = process.env.REACT_APP_ES_EVENTS_INDEX;

  const canFetchDataFromTheLast7Days = useFeaturePlan('DataRetention.7Days');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');
  const canFetchDataFromTheLast365Days = useFeaturePlan('DataRetention.365Days');

  const [{ value: gte }] = [
    {
      type: 'Last 12 months',
      value: 0,
      enabled: canFetchDataFromTheLast365Days
    },
    {
      type: 'Last 30 days',
      value: 30,
      enabled: canFetchDataFromTheLast30Days
    },
    {
      type: 'Last 7 days',
      value: 7,
      enabled: canFetchDataFromTheLast7Days
    },
    {
      type: 'Last 24 hours',
      value: 1,
      enabled: true
    }
  ].filter(period => period.enabled);

  const [resultTimelineQuery, reexecuteQuery] = useQueryGraphQL<GetTimelineType>({
    query: GetTimelineQuery,
    pause: !index || !applicationIds,
    variables: { index, termValues: applicationIds, from, size, gte }
  });

  const { fetching: fetchingData } = resultTimelineQuery;

  const dataFromGraphQL = resultTimelineQuery.data?.getTimeline?.data || [];

  const timeline = dataFromGraphQL.sort((a, b) => {
    return new Date(getCreatedAt(b)).getTime() - new Date(getCreatedAt(a)).getTime();
  });

  useEffect(() => {
    if (fetchingData) return;
    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [fetchingData, reexecuteQuery]);

  return { timeline, reexecuteQuery };
};

export { useTimeline };
