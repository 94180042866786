import { Route, Redirect } from 'react-router-dom';

interface RouteComponentProps {
  layout: React.ElementType;
  component: React.ElementType;
  path: string;
  exact: boolean;
}

interface RedirectComponentProps {
  path: string;
}

export const RouteComponent = (props: RouteComponentProps) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export const RedirectComponent = (props: RedirectComponentProps) => {
  const { path, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <Redirect
            to={{
              pathname: path,
              search: matchProps.location.search,
              state: { from: matchProps.location }
            }}
          />
        );
      }}
    />
  );
};
