import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertCloudwatchGuide = ({ orgUid, token, integrationUid}: GuideParams): GuideType => {
  return {
    name: 'Cloudwatch',
    errors: ['Select an api token'],
    useToken: true,
    snippets: [
      {
        key: 'Lambda',
        value: `
      const https = require("https");

      exports.handler = async (event) => {
        const data = {
          ...JSON.parse(event["Records"][0]["Sns"]["Message"]),
          severity: "",
        };
      
        return httpsrequest(JSON.stringify(data)).then(() => {
          const response = {
            statusCode: 202,
          };
          return response;
        });
      };
      
      function httpsrequest(data) {
        return new Promise((resolve, reject) => {
          const options = {
            hostname: "${getEnvUrl()}",
            path: "/${orgUid}/alerts/v1/cloudwatch/${integrationUid}?token=${token}",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "User-Agent": "1PclaudWatchAuth/1.0"
            },
          };
      
          const req = https.request(options, (res) => {
            if (res.statusCode !== 202) {
              return reject(new Error("statusCode= " + res.statusCode));
            }
      
            res.on("end", function () {
              resolve();
            });
          });
      
          req.on("error", (err) => {
            reject(err.message);
          });
      
          req.write(data);
          req.end();
        });
      }
      `
      }
    ]
  };
};
