import { useEffect, useState } from 'react';

import { Box, Card, CircularProgress, Typography, useTheme } from '@material-ui/core';
import ProductsHomeSubscriptionOptimized, {
  ProductsHomeSubscriptionOptimizedType
} from 'graphqlQueries/productsHomeParallelSubscription';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { useSubscription } from 'urql';

import CardProduct from 'componentsV3/CardProduct';
import CardResource from 'componentsV3/CardResource';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import DashboardIcon from 'icons/Dashboard';
import { analyticsEvent } from 'services/firebase/analytics';

import { useStyles } from './styles';

const Products = ({
  showEmptyHome,
  setFirstAccess
}: {
  showEmptyHome: boolean;
  firstAccess: boolean;
  setFirstAccess: (firstAccess: boolean) => void;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const shouldFetchProducts = useFeaturePlan('Monitoring');
  const newApplications = useFeatureFlagUnleash('newApplications', { queryString: true });
  const showMetricButton = useFeatureFlagUnleash('showMetricButton', { queryString: true });
  const showStatusLine = useFeatureFlagUnleash('showStatusLine', { queryString: true });
  const showLoadingAdditionalData = useFeatureFlagUnleash('showLoadingAdditionalData', {
    queryString: true
  });

  const [result] = useSubscription<ProductsHomeSubscriptionOptimizedType | undefined>(
    { query: ProductsHomeSubscriptionOptimized, pause: !Boolean(shouldFetchProducts) },
    (current, newPayload) => {
      if (newPayload) {
        return newPayload;
      }

      return current;
    }
  );

  const products = result.data?.productsHomeSubscriptionOptimized;

  // Needed because only the first product was rendering, the others
  // were only rendering upon scrolling the page
  useEffect(() => {
    forceCheck();
  }, [products]);

  const { fetching, error } = result;

  const [control, setControl] = useState(false);

  const shouldUseLazyLoad = useFeatureFlagUnleash('useLazyLoadOnProductsHome', {
    queryString: true
  });

  if (error) {
    return (
      <>
        <Typography gutterBottom>Oops, couldn't load products :(</Typography>
        <Typography>
          Error: <em>{error.message.replace('[GraphQL] ', '')}</em>
        </Typography>
      </>
    );
  }

  if (fetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!products?.data?.length) {
    if (control === false) {
      setFirstAccess(true);
      analyticsEvent('fa_products_screen');
      setControl(true);
    }

    if (showEmptyHome) {
      return (
        <Card className={classes.root} variant="outlined">
          <div className={classes.details}>
            <Typography>
              Hello, welcome! You don't have a product created, click on new resource to start your
              journey.
            </Typography>
            <DashboardIcon className={classes.dashboard} />
          </div>
        </Card>
      );
    }

    return null;
  }

  if (control === false) {
    analyticsEvent('products_screen');
    setControl(true);
  }

  return (
    <>
      <Typography gutterBottom variant="body1">
        You have <strong>{products?.data?.length} products</strong> configured on the platform.
      </Typography>

      <Box marginTop={2} display="flex" flexWrap="wrap" justifyContent="space-between">
        {products?.data?.map((product, index: number) => {
          const { id, cover, name } = product;

          const content = (
            <Box
              className={index === 0 ? 'firstProductCard' : undefined}
              key={id}
              flexBasis={`calc(50% - ${theme.spacing(3) / 2}px)`}
              marginBottom={3}>
              {newApplications ? (
                <CardProduct
                  title={name}
                  linkTo={`/products/${id}`}
                  showMetricButton={showMetricButton}
                />
              ) : (
                <CardResource
                  {...product}
                  isLoadingAdditionalData={products.isLoadingAdditionalData}
                  teamsLink={`/products/${id}/?tab=teams`}
                  appLink={`/products/${id}/?newresource`}
                  linkTo={`/products/${id}`}
                  image={cover}
                  title={name}
                  showMetricButton={showMetricButton}
                  showStatusLine={showStatusLine}
                  showLoadingAdditionalData={showLoadingAdditionalData}
                />
              )}
            </Box>
          );

          // Always render the first 10 products
          // (without it, only the first product was being rendered, the rest only rendered upon scrolling)
          if (!shouldUseLazyLoad || index < 10) {
            return content;
          }

          return (
            <LazyLoad
              key={id}
              style={{ flexBasis: 'calc(50% - 12px)' }}
              height={269}
              offset={100}
              once>
              {content}
            </LazyLoad>
          );
        })}
      </Box>
    </>
  );
};

export default Products;
