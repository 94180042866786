import { Tabs, TabsProps } from 'oldComponents/Tabs';

import GeneralTab from './GeneralTab';
import NotificationsRulesTab from './NotificationsRulesTab';

function useTabsDefinition(): TabsProps['tabs'] {
  return [
    {
      label: 'General',
      slug: 'general',
      component: <GeneralTab />,
      hide: false
    },
    {
      label: 'Notification Rules',
      slug: 'notification-rules',
      component: <NotificationsRulesTab />,
      hide: false
    },
    {
      label: 'Roles',
      slug: 'roles',
      component: <>Roles</>,
      hide: true
    }
  ].filter(tab => !tab?.hide);
}

export const AccountTabs = () => {
  const tabs = useTabsDefinition();

  return (
    <div>
      <Tabs tabs={tabs} />
    </div>
  );
};
