enum EventType {
  Deploy = 'Deploy',
  Hotfix = 'Hotfix',
  Rollback = 'Rollback',
  PostMortem = 'PostMortem',
  Alarmed = 'Alarmed',
  Acknowledged = 'Acknowledged',
  ChangeDescription = 'ChangeDescription',
  Resolved = 'Resolved'
}

export default EventType;
