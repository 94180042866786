import { Resource } from 'types/external/Resource';

import { KafkaFormData } from 'views/ServicesHub/forms/monitoring/queue/Kafka';

import { monitoringAdapter } from '../monitoring';

export function kafkaAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & KafkaFormData {
  const domain = data.domain;

  if (data.domainSettings.domainType === 'url') {
    return {
      ...monitoringAdapter(data),
      domain,
      domainType: data.domainSettings.domainType,
      username: '',
      password: '',
      mechanism: '',
      tls: false,
      sasl: false,
      brokers: [],
      topic: '',
      authentication: false,
      lagTolerance: String(data.domainSettings.lagTolerance),
      lag: undefined,
      consumer: data.domainSettings.lagTolerance ? '' : undefined
    };
  }

  const splitted = domain.split('@');

  const hasCredentials = splitted.length === 2;

  const [credentials, tail] = hasCredentials ? splitted : [undefined, splitted[0]];

  const [username, password, mechanism, tls, sasl] = credentials ? credentials.split(':') : [];

  const [hostAndPort, topic, consumer, lag] = tail.split('/');

  const brokersList = prepareHostAndPort(hostAndPort);

  return {
    ...monitoringAdapter(data),
    username,
    password,
    mechanism,
    tls: tls === 'true' ? true : false,
    sasl: sasl === 'true' ? true : false,
    brokers: brokersList,
    topic,
    consumer,
    authentication: username ? true : false,
    lagTolerance: data.domainSettings.lagTolerance,
    lag: lag ? Number(lag) : undefined,
    domain: '',
    domainType: data.domainSettings.domainType
  };
}

const prepareHostAndPort = (hostAndPort: string) => {
  const pattern = /[[\]]/g;
  const splitDomainsList = hostAndPort.replace(pattern, '').split(',');

  const brokersList = splitDomainsList.map(item => {
    const [key, value] = item.split(':');
    return { key, value };
  });

  return brokersList;
};
