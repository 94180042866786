import React from 'react';

import { useHistory } from 'react-router';

import { usePermission } from 'hooks/usePermission';

import NewEnvironmentsList from './NewEnvironmentsList';

const Environments = () => {
  const history = useHistory();

  const permissions = {
    new: usePermission('EnvironmentController-post-/environments'),
    update: usePermission('EnvironmentController-put-/environments/:id'),
    remove: usePermission('EnvironmentController-delete-/environments/:id'),
    installAgent: usePermission('EnvironmentController-post-/environments/:id/agent/install'),
    upgradeAgent: usePermission('EnvironmentController-post-/environments/:id/agent/upgrade'),
    uninstallAgent: usePermission('EnvironmentController-delete-/environments/:id/agent/uninstall')
  };

  return <NewEnvironmentsList history={history} permissions={permissions} />;
};

export default Environments;
