import React from 'react';

import { Box, useTheme } from '@material-ui/core';
import Breadcrumb from 'oldComponents/Breadcrumb';

import DataGrid from './DataGrid';

function StepGroupList() {
  const theme = useTheme();

  return (
    <Box padding={3} paddingBottom={theme.spacing(1)}>
      <Breadcrumb
        items={[
          {
            link: `/teams`,
            label: 'Teams'
          },
          {
            label: 'Step Groups'
          }
        ]}
      />
      <DataGrid />
    </Box>
  );
}

export default StepGroupList;
