import { Input } from 'views/ServicesHub/components/Input';

import { Props } from './types';

const FormWithTextFieldInput = ({ form, type, inputLabel }: Props) => {
  return (
    <>
      <input hidden ref={form.register} value={type} name="method" />
      <Input label={inputLabel} name="value" inputRef={form.register} required={true} />
    </>
  );
};

export default FormWithTextFieldInput;
