type BuildQueryArgs = {
  search: string;
} & Record<string, string | number | boolean | null | undefined>;

/**
 *
 * Builds a query for Elastic Search based on the provided search parameters.
 * @param search - The search term. E.g. filter by name
 * @param ...queryFilters - Additional query filters.
 * @example
 * buildElasticQuery({
 *   search: 'test',
 *   status: 'active',
 *   'nested.field': 'value'
 * });
 * // Returns "*test* AND (status: "active") AND (nested.field: "value")"
 */
export function buildElasticQuery({ search, ...queryFilters }: BuildQueryArgs) {
  const filters = [];

  if (search) {
    filters.push(`*${search}*`);
  }

  Object.entries(queryFilters).forEach(([key, value]) => {
    if (!value) return;

    if (typeof value === 'string' && value.includes(',')) {
      const values = value.split(',');
      filters.push(`(${values.map(v => `(${key}: "${v}")`).join(' OR ')})`);
      return;
    }

    filters.push(`(${key}: "${value}")`);
  });

  return filters.join(' AND ');
}
