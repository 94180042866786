import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/applications';

function* prepareFetchEntities(axios, action) {
  try {
    const productId = action.params.productId;
    const applicationId = action.params.applicationId;
    const {
      data: { data }
    } = yield axios.get(`/products/${productId}/applications/${applicationId}`);
    const {
      data: { data: product }
    } = yield axios.get(`/products/${productId}`);
    yield put({
      type: actions.PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        product,
        applications: { data },
        product_applications_settings: { productId }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationSettingsFetch(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_NEW_SETTINGS_FETCH, prepareFetchEntities, axios);
}
