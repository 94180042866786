import { useState } from 'react';

import { Button, CircularProgress, DialogContentText } from '@material-ui/core';
import Axios from 'axios';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import actions from 'redux/actions';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function WebhookForm({ form }: FormProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, control, watch, errors } = form;

  const [webhookUrl, setWebhookUrl] = useState(watch('config.webhookUrl'));

  const hasValue = webhookUrl?.length;

  const [validateUrl, { isLoading: isValidating }] = useMutation(
    (url: string) => Axios.post(url, { ok: true }).then(response => response.data),
    {
      onSuccess: _ => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Successfully validated Webhook'
        });
      },
      onError: (error: Error) => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: `
          Sorry, we couldn't validate webhook URL: ${error.message}`
          },
          ga: { category: 'ERROR' }
        });
      }
    }
  );

  return (
    <>
      <DialogContentText>Send a notification via Webhook</DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.webhookUrl"
          label="Webhook url"
          errors={errors}
          minRows={5}
          multiline
          required
          onChange={event => {
            const value = event.target.value;
            setWebhookUrl(value);
          }}
        />
      </DialogContentText>
      <DialogContentText>
        <Button
          onClick={() => validateUrl('www.google.com')}
          disabled={!hasValue}
          color="primary"
          variant="outlined">
          {isValidating ? <CircularProgress size={24} /> : 'Test'}
        </Button>
      </DialogContentText>
      <DialogContentText>
        <Select
          options={[
            { value: '0', label: '0' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' }
          ]}
          defaultValue="0"
          control={control}
          inputRef={register}
          name="config.webhookRetry"
          label="Retry"
          required={true}
        />
      </DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.webhookRetryFailEmail"
          label="E-mail address"
          errors={errors}
          required
        />
      </DialogContentText>
    </>
  );
}
