import MainLayoutV5 from 'layouts/v5/Main';
import BlockedView from 'views/Blocked';
import { PlansView } from 'views/Plans';

import RouteWithLayoutV2 from '../components/RouteWithLayoutV2';

export const plansRoutesWithLayoutV2 = [
  <RouteWithLayoutV2
    component={BlockedView}
    exact
    layout={MainLayoutV5}
    key="/blocked"
    path="/blocked"
  />,
  <RouteWithLayoutV2 component={PlansView} exact layout={MainLayoutV5} key="/plans" path="/plans" />
];
