import { MouseEvent, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  MenuItem,
  Typography,
  capitalize,
  useTheme
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { SSOIntegration } from 'types/external/Aragorn/SSOIntegration';
import { OperationContext } from 'urql';

import { DeleteEntity } from 'components/DeleteEntity';
import { Menu } from 'components/Menu';
import { useDeleteSSOIntegration } from 'hooks/queriesGraphQL/useDeleteSSOIntegration';
import { GoogleColored } from 'icons/GoogleColored';
import { Microsoft } from 'icons/Microsoft';
import actions from 'redux/actions';

import SyncReportDialog from '../SyncReportDialog';

import { useStyles } from './styles';

type SSOIntegrationCardProps = {
  type: SSOIntegration['type'];
  connected?: boolean;
  ssoUid?: string;
  ssoRefetch?: (opts?: Partial<OperationContext>) => void;
};

function getSSOIntegrationIcon(type: SSOIntegrationCardProps['type']) {
  switch (type) {
    case 'google':
      return <GoogleColored fontSize="large" />;
    case 'microsoft':
      return <Microsoft fontSize="large" />;
  }
}

function SSOIntegrationCardComponent({
  type,
  connected = false,
  ssoUid,
  ssoRefetch
}: SSOIntegrationCardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [menuActionAnchorEl, setMenuActionAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openSyncDialog, setOpenSyncDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [{ fetching }, deleteSSOIntegration] = useDeleteSSOIntegration();

  const handleOpenMenuActions = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuActionAnchorEl(event.currentTarget);
  };

  const handleCloseMenuActions = () => {
    setMenuActionAnchorEl(null);
  };

  const handleOpenSyncReportDialog = () => {
    setOpenSyncDialog(true);
  };

  const handleCloseSyncReportDialog = () => {
    setOpenSyncDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const onDeleteWorkspace = async () => {
    const result = await deleteSSOIntegration({ ssoUid });

    if (!result.data) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: {
          message: 'Error on delete workspace'
        }
      });
      handleCloseDeleteDialog();
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Workspace deleted successfully'
    });
    handleCloseDeleteDialog();

    if (ssoRefetch) ssoRefetch({ requestPolicy: 'network-only' });
  };

  const createSSOIntegrationPagePath = `/organization/single-sign-on/new/${type}`;

  return (
    <Card className={classes.root}>
      <Link to={connected ? '#' : createSSOIntegrationPagePath} data-disabled={connected}>
        <CardContent className="card-content">
          <Box display="flex" alignItems="center" gridGap={theme.spacing()} mb={2}>
            {getSSOIntegrationIcon(type)}

            <Typography className={classes.title} variant="h4">
              SAML {capitalize(type)}
            </Typography>
          </Box>
          <Typography variant="caption">
            {capitalize(type)} SSO is a secure authentication method that allows a user to log in to
            a session granting access to various related applications and services.
          </Typography>
        </CardContent>
      </Link>

      <Divider className={classes.divider} />

      <CardActions className={classes.actions}>
        <Chip
          data-connected={connected}
          label={connected ? 'Connected' : 'Unconnected'}
          className="chip"
        />

        <Button
          className={classes.menuActionsButton}
          variant="outlined"
          onClick={handleOpenMenuActions}
          color="primary">
          <MoreVert />
        </Button>

        <Menu anchorEl={menuActionAnchorEl} onClose={handleCloseMenuActions} placement="bottom">
          {/* Uncomment when SSO Integrations CRUD is implemented. */}
          {/* {connected && (
            <>
              <Link to={`${window.location.pathname}${window.location.search}`}>
                <MenuItem>Edit Workspace</MenuItem>
              </Link>
              <MenuItem>Delete Workspace</MenuItem>
            </>
          )} */}
          {!connected ? (
            <Link to={createSSOIntegrationPagePath}>
              <MenuItem>Integrate now</MenuItem>
            </Link>
          ) : (
            <>
              <MenuItem onClick={handleOpenSyncReportDialog}>Show sync report</MenuItem>
              <MenuItem onClick={handleOpenDeleteDialog}>Delete workspace</MenuItem>
            </>
          )}
        </Menu>

        <DeleteEntity
          entityName="Workspace"
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          disabled={false}
          isLoading={fetching}
          handleRemove={onDeleteWorkspace}
        />

        <SyncReportDialog open={openSyncDialog} onClose={handleCloseSyncReportDialog} />
      </CardActions>
    </Card>
  );
}

export const SSOIntegrationCard = SSOIntegrationCardComponent;
