import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import RouteWithLayoutV2 from 'routes/components/RouteWithLayoutV2';
import IncidentView from 'views/Products/View/Availability/View/Incidents/View/index';
import IncidentViewV1 from 'views/Products/View/Availability/View/Incidents/View/V1';
import IncidentsView from 'views/V4/Incidents/view';

export const incidentsRoutesWithLayoutV2 = [
  <AuthorizedRoute
    planFeature="IncidentManagement"
    component={IncidentView}
    layout={MainLayoutV5}
    key="/incidents/:incidentId"
    path="/incidents/:incidentId"
    exact
  />,
  <AuthorizedRoute
    planFeature="IncidentManagement"
    component={IncidentsView}
    layout={MainLayoutV5}
    key="/incidents"
    path="/incidents"
    exact
  />,
  <RouteWithLayoutV2
    component={IncidentViewV1}
    exact
    layout={MainLayoutV5}
    key="/products/:productId/availability/applications/:applicationId/incidents/:incidentId/v1"
    path="/products/:productId/availability/applications/:applicationId/incidents/:incidentId/v1"
  />,
  <RouteWithLayoutV2
    component={IncidentView}
    exact
    layout={MainLayoutV5}
    key="/products/:productId/availability/applications/:applicationId/incidents/:incidentId/v2"
    path="/products/:productId/availability/applications/:applicationId/incidents/:incidentId/v2"
  />
];
