import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/users';

function* prepareFetchEntities(axios, action) {
  try {
    const { payload } = action;

    const {
      data: { data: teams }
    } = yield axios.get('/teams?page=1&per_page=10000&sort=name,ASC');
    const {
      data: { data: roles }
    } = yield axios.get('/roles/org?page=1&per_page=10000&sort=name,ASC');

    const users_edit = {
      values: {}
    };

    if (payload && payload.id) {
      const {
        data: { data: values }
      } = yield axios.get(`/users/${payload.id}/account`);
      users_edit.values = values;
    }

    yield put({
      type: actions.USERS_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        roles,
        teams,
        users_edit
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchUsersFetch(axios) {
  yield takeLatest(actions.USERS_FETCH, prepareFetchEntities, axios);
}
