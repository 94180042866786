import { useEffect } from 'react';

import { useQuery } from 'react-query';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { directusV2 } from 'services/directus';

export function PlansView() {
  const { data: plansPageData, isLoading } = useQuery('cms_plans_page', async () => {
    return directusV2.singleton('plans_page').read();
  });

  useEffect(() => {
    if (isLoading) return;
    if (!plansPageData?.url) {
      window.history.back();
      return;
    }

    window.location.href = plansPageData?.url!;
  }, [isLoading, plansPageData?.url]);

  return <LoadingOverlay />;
}
