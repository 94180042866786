import { useQuery } from 'urql';

type ImpactedOrigins = {
  name: string;
  uid: string;
  origin: string;
};

const GetImpactedOriginsQuery = `#graphql
    query ($incidentId: Int!) {
      originImpacted(incidentId: $incidentId) {
        name,
        uid,
        origin
      }
    }
  `;

export function useImpactedOrigins(incidentId: number) {
  const [{ data, fetching }] = useQuery<{ originImpacted: ImpactedOrigins[] }>({
    query: GetImpactedOriginsQuery,
    variables: { incidentId }
  });

  return { data: data?.originImpacted ?? [], isLoading: fetching };
}
