import { Box } from '@material-ui/core';
import { Service } from 'types/cms/ServicesHub';

import useQueryParams from 'helpers/useQueryParams';
import { useGetServiceItemByLink } from 'hooks/useGetServiceItem';
import useServicePermission from 'hooks/useServicePermission';

import { forms, FormType } from '../forms';

const DEFAULT_FALLBACK: FormType = {
  title: 'Unknown service type',
  description: 'Unknown service type',
  breadcrumb: 'Unknown service type',
  backLabel: 'Services-hub',
  backLink: '/services-hub',
  Layout: () => (
    <Box>
      <strong>Unknown service type</strong>
    </Box>
  )
};

export function useLayoutFromServiceType(servicesDefinitions: Service[]): FormType {
  const queryParams = useQueryParams();

  const formType = queryParams.get('type') as keyof typeof forms;

  const serviceItem = useGetServiceItemByLink(
    `services-hub/new?type=${formType}`,
    servicesDefinitions
  );
  const servicePermission = useServicePermission(serviceItem);

  if (!formType || !(formType in forms) || !servicePermission) {
    return DEFAULT_FALLBACK;
  }

  return forms[formType];
}
