import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  input: {
    marginTop: theme.spacing(1)
  },
  link: {
    marginLeft: theme.spacing(0.5)
  }
}));
