import { useEffect, useMemo, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import axios from 'redux/axios';
import { User } from 'views/ServicesHub/adapters/new/user';

import { startValuesAdapter } from './adapters/startValuesAdapter';

interface StartUser {
  id: number;
  name: string;
  role: string;
  email: string;
  status: string;
}
interface UseStartValuesResult {
  startValues?: StartUser;
  fetching: boolean;
}

const GetAccountQuery = `#graphql
  query(
    $userId: Float!
  ) {
    account(userId: $userId) {
      id
      isActive
      roleId
      user {
        id
        displayName
        email
        profile {
          displayName
        }
      }
      role {
        name
      }
    }
  }
`;

export function useStartValues(): UseStartValuesResult {
  const { uid: userId } = useParams<{ uid: string }>();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  const useAccountViaAragorn = useFeatureFlagUnleash('useAccountViaAragorn', { queryString: true });

  const [{ fetching: fetchingAccount, data }] = useQuery({
    query: GetAccountQuery,
    variables: { userId: Number(userId) },
    pause: !useAccountViaAragorn || !userId
  });

  useEffect(() => {
    if (useAccountViaAragorn && data?.account) {
      setCurrentUser({
        id: data?.account?.id,
        name: data?.account?.user?.profile?.displayName || data?.account?.user?.displayName,
        role: data?.account?.roleId,
        email: data?.account?.user?.email,
        status: data?.account?.isActive ? 'active' : 'disable'
      });
    }
  }, [data, useAccountViaAragorn]);

  const getUser = useCallback(async () => {
    if (!userId) return;
    setFetching(true);
    try {
      const {
        data: { data }
      } = await axios.get<{ data: User }>(`/users/${userId}/account`);
      setCurrentUser(data);
    } catch (error) {
    } finally {
      setFetching(false);
    }
  }, [userId]);

  useEffect(() => {
    if (useAccountViaAragorn) return;
    getUser();
  }, [getUser, useAccountViaAragorn]);

  const startValues = useMemo(() => {
    if (!currentUser) return undefined;

    if (useAccountViaAragorn) {
      return currentUser;
    }

    return startValuesAdapter(currentUser);
  }, [currentUser, useAccountViaAragorn]);

  return { startValues, fetching: useAccountViaAragorn ? fetchingAccount : fetching };
}
