import { useCallback, useState } from 'react';

import { Button } from 'components/Button';

import { AddMemberDialog } from './Dialog';

export const AddMemberButton = ({
  teamId,
  disabled,
  handleRefetchMembers
}: {
  teamId: number;
  disabled: boolean;
  handleRefetchMembers: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const handleChangeDialog = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <Button onClick={handleChangeDialog} disabled={disabled} title="Add Member">
        Member
      </Button>
      <AddMemberDialog
        open={open}
        handleChangeDialog={handleChangeDialog}
        teamId={teamId}
        handleRefetchMembers={handleRefetchMembers}
      />
    </>
  );
};
