type OthersMethodsIndicesEnum = "Ping" | "TCP" | "Webhook" | "grpc";
type SearchEngineMethods = "elastic" | "solr";
type HTTPMethodsIndicesEnum = "GET" | "POST";
export type IndicesEnum = OthersMethodsIndicesEnum & SearchEngineMethods & HTTPMethodsIndicesEnum;

const othersMethods = {
    "ping": false,
    "tcp": false,
    "webhook": false,
    "grpc": false,
};
const searchEngineMethods = {
    "elastic": false,
    "solr": false,
};
const HTTPMethods = {
    "GET": false,
    "POST": false,
};

function canHiddenLatencies (type: string, method: IndicesEnum): boolean {
    if(type === "http" && typeof HTTPMethods[method] === "boolean") {
        return HTTPMethods[method];
    };

    if(type === "search" && typeof searchEngineMethods[method] === "boolean") {
        return searchEngineMethods[method];
    };

    if(type === "others" && typeof othersMethods[method] === "boolean") {
        return othersMethods[method];
    };

    return true;
};

export default canHiddenLatencies;