export const CreateItsmIntegration = `#graphql
  mutation($data: CreateItsmIntegrationInput!) {
    createItsmIntegration(data: $data) {
      success
    }
  }
`;

export type CreateItsmIntegrationResponse = {
  createItsmIntegration: {
    success: boolean;
  };
};

export type CreateItsmIntegrationVariables = {
  data: {
    name: string;
    origin: string;
    openAutomatic: boolean;
    configuration: {
      subdomain: string;
      username?: string;
      password?: string;
      clientId?: string;
      clientSecret?: string;
    };
  };
};

export const UpdateItsmIntegration = `#graphql
  mutation($newData: UpdateItsmIntegrationInput!, $integrationUid: String!) {
    updateItsmIntegration(newData: $newData, integrationUid: $integrationUid) {
      success
    }
  }
`;

export type UpdateItsmIntegrationResponse = {
  updateItsmIntegration: {
    success: boolean;
  };
};

export type UpdateItsmIntegrationVariables = {
  newData: {
    name: string;
    origin: string;
    openAutomatic: boolean;
    configuration: {
      subdomain: string;
      username?: string;
      password?: string;
      clientId?: string;
      clientSecret?: string;
    };
  };
  integrationUid: string;
};
