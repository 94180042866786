import { makeStyles } from '@material-ui/core';
import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import { Theme } from 'theme/v4';

import { StatusChipProps } from '.';

type StatusChipStyleProps = {
  status: StatusChipProps['status'];
  entity: ApplicationManagerEntityType;
};

function getChipColorByStatus(
  status: ResourceStatusEnum | MultiFlowStatusEnum,
  entity: ApplicationManagerEntityType,
  palette: Theme['palette']
) {
  const resourceStatusColorMap = {
    [ResourceStatusEnum.Off]: palette.gray[700],
    [ResourceStatusEnum.Available]: palette.green[400],
    [ResourceStatusEnum.Unavailable]: palette.red[500],
    [ResourceStatusEnum.Pending]: palette.gray[200],
    [ResourceStatusEnum.Maintenance]: '#F57D27'
  };

  const isResource = entity === ApplicationManagerEntityType.Resource;

  if (isResource) {
    return resourceStatusColorMap[status as ResourceStatusEnum];
  }

  const multiflowStatusColorMap = {
    [MultiFlowStatusEnum.Off]: palette.gray[700],
    [MultiFlowStatusEnum.Success]: palette.green[400],
    [MultiFlowStatusEnum.Error]: palette.red[500],
    [MultiFlowStatusEnum.Pending]: palette.gray[200],
    [MultiFlowStatusEnum.Maintenance]: '#F57D27'
  };

  return multiflowStatusColorMap[status as MultiFlowStatusEnum];
}

export const useStyles = makeStyles<Theme, StatusChipStyleProps>(theme => ({
  chip: {
    backgroundColor: props => getChipColorByStatus(props.status, props.entity, theme.palette),
    color: theme.palette.common.white
  }
}));
