import { useQuery } from 'urql';

type UseTeamsParams = {
  page: number;
  perPage: number;
  query: string;
  pause?: boolean;
};

type Teams = {
  id: number;
  name: string;
  members: [
    {
      id: number;
    }
  ];
};

type UseTeamsData = {
  data: Teams[];
  total: number;
};

const getTeamsQuery = `#graphql
query(
    $from: Int!,
    $size: Int!,
    $query: String!
) {
  getTeams(
    from: $from,
    size: $size,
    query: $query    
  ){
    data{
      id,
      name,
      members{
        id
      }
    }
    total
  }
}
`;
export function useTeams({ page, perPage: size, query, pause }: UseTeamsParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ getTeams: UseTeamsData }>({
    query: getTeamsQuery,
    pause: pause,
    variables: {
      from,
      size,
      query
    }
  });
}
