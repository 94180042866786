import actions from '../../actions';

const environment = (state, action) => {
  if (actions.ENVIRONMENT_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      environment_view: action.payload
    };
  }

  if (actions.ENVIRONMENT_CHANGE_TAB === action.type) {
    return {
      ...state,
      environment_view: {
        ...state.environment_view,
        tab: action.payload
      }
    };
  }

  if (actions.ENVIRONMENT_CLUSTERS_FETCH_SUCCESSFUL === action.type) {
    return {
      ...state,
      environment_clusters: {
        ...state.environment_clusters,
        data: action.payload
      }
    };
  }

  return state;
};

export default environment;
