import { useQuery } from 'urql';

const GetAppTokensQuery = `#graphql
    query(
        $from: Int,
        $size: Int,
    ) {
      tokens(from: $from, size: $size) {
        data {
          id
          name
          uid
          kongApiKeyId
          consumer {
            id
            kongConsumerId
          }
        }
        total
    }
  }
`;

type UseAppTokensParams = {
  from: number;
  size: number;
  sortKey?: string;
};

type Tokens = {
  id: number;
  name: string;
  uid: string;
  kongApiKeyId: string;
  consumer: {
    id: number;
    kongConsumerId: string;
  };
};

type UseAppTokensData = {
  data: Tokens[];
  total: number;
};

export function useAppTokens({ from, size, sortKey }: UseAppTokensParams) {
  return useQuery<{ tokens: UseAppTokensData }>({
    query: GetAppTokensQuery,
    variables: {
      from,
      size,
      sortKey
    }
  });
}
