import { Typography } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

import useStyles from './styles';

type Props = {
  label?: string;
  name: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  control: Control<any>;
  errors?: DeepMap<any, FieldError>;
};

const InputPhoneNumber = ({
  label,
  name,
  defaultValue = '',
  required = false,
  disabled = false,
  control,
  helperText,
  errors
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <Typography className={classes.label} variant="body2">
          {required ? label + ' *' : label}
        </Typography>
      )}
      <Controller
        name={name}
        control={control}
        render={({ name, onChange, value }) => (
          <MuiPhoneNumber
            required={required}
            disableAreaCodes
            defaultCountry={'br'}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="dense"
            disabled={disabled}
            onChange={onChange}
            defaultValue={defaultValue}
            error={errors?.value}
            name={name}
            autoFocus={false}
            value={value}
            helperText={errors?.value?.message || helperText}
            variant="outlined"
            FormHelperTextProps={{
              className: classes.helperTextColor
            }}
          />
        )}
      />
    </>
  );
};

export default InputPhoneNumber;
