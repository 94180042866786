import firebase from './index';

const analyticsEvent = (event, params) => {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    firebase.analytics().logEvent(event, params);
  } catch (err) {}
};

export { analyticsEvent };
