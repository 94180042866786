import { format } from 'date-fns';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Author',
    selector: row => row?.user?.displayName
  },
  {
    name: 'Type',
    selector: row => {
      if (row?.tokenType) {
        return row?.tokenType;
      }

      if (row?.type === 'api_token') {
        return 'API Token';
      }
    },
    sortable: true
  },
  {
    name: 'Created At',
    selector: row => {
      const date = new Date(row.createdAt);
      const formatedDate = format(date, 'dd/MM/yyyy, p');
      return formatedDate;
    },
    sortable: true
  }
];

export default columns;
