import React from 'react';

import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const Columns = [
  {
    name: 'Name',
    sortable: true,
    cell: row => {
      return (
        <Link component={RouterLink} to={`/teams/${row.id}`}>
          {row.name}
        </Link>
      );
    }
  }
];

export default Columns;
