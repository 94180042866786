import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/incident';

function* prepareFetchEntities(axios, action) {
  try {
    const { productId, applicationId, incidentId } = action.params;
    const {
      data: { data: incident }
    } = yield axios.get(
      `/products/${productId}/applications/${applicationId}/incidents/${incidentId}`
    );

    yield put({
      type: actions.INCIDENT_VIEW_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: incident
    });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchPrepareFetchEntities(axios) {
  yield takeLatest(actions.INCIDENT_VIEW_FETCH, prepareFetchEntities, axios);
}
