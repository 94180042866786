import { Box } from '@material-ui/core';

import { usePermission } from 'hooks/usePermission';
import CallRotationV2 from 'views/Teams/View/CallRotationV2';

const OnCallTab = () => {
  const permissions = {
    newCallRotation: usePermission('CallrotationController-post-/callrotation/stepGroups'),
    editCallRotation: usePermission('CallrotationController-put-/callrotation/schedule/:id'),
    deleteCallRotation: usePermission('CallrotationController-delete-/callrotation/stepGroups/:id'),
    editAdvancedNotify: usePermission('NotifySettingsController-post-/notify_settings/:teamId')
  };

  return (
    <Box>
      <CallRotationV2 permissions={permissions} />
    </Box>
  );
};

export default OnCallTab;
