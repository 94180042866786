import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AgentInput from 'componentsV3/AgentInput';
import { ButtonSimple } from 'componentsV3/ButtonSimple';
import DialogBox from 'componentsV3/DialogBox';
import SelectSimple from 'componentsV3/Select';
import SelectWithStatus from 'componentsV3/SelectWithStatus';
import useQueryParams from 'helpers/useQueryParams';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';
import { instructions } from 'views/Environments/components/CustomAgentInstructions/CommandTexts';

import useForm from './useForm';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(4)
  },
  text: {
    marginTop: theme.spacing(4)
  },
  text1: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  text2: {
    marginTop: 12
  },
  checked: {
    color: theme.palette.primary.main
  },
  text4: {
    marginTop: theme.spacing(4),
    marginBottom: 12
  },
  text5: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  text6: {
    marginTop: theme.spacing(1)
  },
  marginTop32: {
    marginTop: theme.spacing(4)
  }
}));

const startValues = {
  name: ''
};

function TabAgent({
  environment,
  onFinish,
  applicationType
}: {
  environment: any;
  onFinish: () => void;
  applicationType: string;
}) {
  const classes = useStyles();

  const { handleSubmit, errors, control, watch } = useForm({
    defaultValues: startValues
  });

  const submitMutation = (data: any) => {};

  const selectedAgent = watch('agent');

  const selectedMethod = watch('selectedMethod');

  const selectedSystem = watch('selectedSystem');

  const firstAccess = useQueryParams().has('firstAccess');

  return (
    <>
      <Typography className={classes.text} variant="h2">
        Agent installation in your Test Cloud cloud (Custom).
      </Typography>
      <Typography className={classes.text2}>
        Carefully follow the steps below to configure the agent.
      </Typography>
      <Typography>
        If you need help, check out this{' '}
        <a
          target="_blank"
          className={classes.checked}
          href="https://help.elven.works/support/solutions/69000282602"
          rel="noreferrer">
          FAQ article
        </a>{' '}
        or{' '}
        <a
          target="_blank"
          className={classes.checked}
          href="https://elvenworks.zendesk.com/hc/en-us/requests/new"
          rel="noreferrer">
          ask one of our implementers for help
        </a>
        .
      </Typography>

      <form onSubmit={handleSubmit(submitMutation)}>
        <Typography className={classes.text1}>
          Select how you prefer to install the agent.
        </Typography>
        <AgentInput name="agent" control={control} />
        <Grid container style={{ position: 'relative' }}>
          {selectedAgent === 'kube' && (
            <Grid className={classes.text} xs={6}>
              <SelectWithStatus
                errors={errors}
                name="selectedMethod"
                control={control}
                defaultLabel="Choose an agent update method"
                defaultValue={''}
                items={[
                  {
                    value: 'autoUpdate',
                    text: 'Update Automatic',
                    status: 'Automatically updates in your Cloud.'
                  },
                  {
                    value: 'noAutoUpdate',
                    text: 'Update Manual',
                    status: 'You will be notified when there is an update.'
                  }
                ]}
              />
            </Grid>
          )}

          {selectedAgent === 'system' && (
            <Grid className={classes.text} xs={6}>
              <Typography>
                At this point, you'll perform some configurations in your own cloud.
              </Typography>
              <Typography className={classes.text6}>
                Follow the videos in each block for reference while setting up.
              </Typography>
              <Typography className={classes.text5}>
                It is important that you have admin permission to proceed.
              </Typography>
              <SelectSimple
                errors={errors}
                name="selectedSystem"
                control={control}
                label="Which operating system have you selected in your cloud?"
                defaultValue={''}
                items={[
                  { value: 'amazonLinux', label: 'Amazon Linux' },
                  { value: 'centOS7And8', label: 'CentOS 7' },
                  { value: 'redHat', label: 'Red Hat' },
                  { value: 'ubuntu', label: 'Ubuntu' }
                ]}
              />
            </Grid>
          )}
        </Grid>

        {selectedMethod && (
          <Grid className={classes.marginTop32} item xs={7}>
            <DialogBox
              link={
                'https://help.elven.works/support/solutions/articles/69000610444-setup-agent-custom-kubernetes'
              }
              title={'Install this Manifest to your cloud.'}
              text={instructions(environment.id, environment.agentToken, selectedMethod)}
              applicationType={applicationType}
            />
            <Typography className={classes.text4} variant="h3">
              Now go to the next steps.
            </Typography>
            <Typography>
              Once this process is complete, it's time to provision the agent from this environment
              in your cloud.
              <p className={classes.text6}>
                This installation can take up to 2 minutes and you will be notified. Please feel
                free to proceed with product configuration.
              </p>
            </Typography>
            <Grid className={classes.button} xs={12}>
              <ButtonSimple
                type="submit"
                color="primary"
                onClick={() => {
                  if (selectedAgent === 'kube') {
                    const eventsMap: { [key: string]: { [key: string]: string } } = {
                      internal: {
                        autoUpdate: firstAccess
                          ? 'fa_new_iaea_kubernetes_auto'
                          : 'new_iaea_kubernetes_auto',
                        noAutoUpdate: firstAccess
                          ? 'fa_new_iaea_kubernetes_manual'
                          : 'new_iaea_kubernetes_manual'
                      },
                      externalWithAddons: {
                        autoUpdate: firstAccess
                          ? 'fa_new_eaea_kubernetes_auto'
                          : 'new_eaea_kubernetes_auto',
                        noAutoUpdate: firstAccess
                          ? 'fa_new_eaea_kubernetes_manual'
                          : 'new_eaea_kubernetes_manual'
                      }
                    };

                    if (
                      eventsMap[applicationType]
                        ? eventsMap[applicationType][selectedMethod]
                        : false
                    ) {
                      analyticsEvent(eventsMap[applicationType][selectedMethod]);
                    }
                  }

                  onFinish();
                }}
                text="Provision agent and configure product"
              />
            </Grid>
          </Grid>
        )}

        {selectedSystem && (
          <Grid className={classes.marginTop32} item xs={7}>
            <DialogBox
              link={
                'https://help.elven.works/support/solutions/articles/69000610460-setup-agent-custom-operating-system'
              }
              title={'Install this script on your cloud.'}
              text={instructions(environment.id, environment.agentToken, selectedSystem)}
              applicationType={applicationType}
            />
            <Typography className={classes.text4} variant="h3">
              Now go to the next steps.
            </Typography>
            <Typography>
              Once this process is complete, it's time to provision the agent from this environment
              in your cloud.
            </Typography>
            <Typography className={classes.text6}>
              This installation can take up to 2 minutes and you will be notified. Please feel free
              to proceed with product configuration.
            </Typography>
            <Grid className={classes.button} xs={6}>
              <ButtonSimple
                type="submit"
                color="primary"
                onClick={() => {
                  if (applicationType === 'internalMultiHttp') {
                    analyticsEvent(
                      firstAccess ? 'fa_provision_agent_multi' : 'provision_agent_multi'
                    );
                  }

                  const eventsMap: { [key: string]: { [key: string]: string } } = {
                    internal: {
                      amazonLinux: firstAccess
                        ? 'fa_new_iaea_os_amazon_linux'
                        : 'new_iaea_os_amazon_linux',
                      centOS7And8: firstAccess ? 'fa_new_iaea_os_centos7' : 'new_iaea_os_centos7',
                      ubuntu: firstAccess ? 'fa_new_iaea_os_ubuntu' : 'new_iaea_os_ubuntu'
                    },
                    externalWithAddons: {
                      amazonLinux: firstAccess
                        ? 'fa_new_eaea_os_amazon_linux'
                        : 'new_eaea_os_amazon_linux',
                      centOS7And8: firstAccess ? 'fa_new_eaea_os_centos7' : 'new_eaea_os_centos7',
                      ubuntu: firstAccess ? 'fa_new_eaea_os_ubuntu' : 'new_eaea_os_ubuntu'
                    }
                  };

                  if (eventsMap[applicationType][selectedSystem]) {
                    analyticsEvent(eventsMap[applicationType][selectedSystem]);
                  }

                  onFinish();
                }}
                text="Provision agent and configure product"
              />
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
}

export default withThemeV3Hoc(TabAgent);
