import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

const CustomLinkButton = withStyles(theme => ({
  root: {
    width: 'fit-content',
    color: theme.palette.primary.main,
    textTransform: 'none',
    '&:hover, &:hover *': {
      background: 'none',
      color: theme.palette.primary.dark
    },
    '&:active': {
      color: theme.palette.primary.light
    }
  }
}))(Button);

export default withThemeV3Hoc(CustomLinkButton);
