/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

import { useMutation, useQuery } from 'urql';

export type ConnectedApplications = {
  id: number;
  uid: string;
  name: string;
  productName: string;
}[];

type GithubIntegrationData = {
  type: 'github';
  data: {
    installationId: number;
  };
};
type BitbucketIntegrationData = {
  type: 'bitbucket';
  data: {
    clientKey: string;
    sharedSecret: string;
  };
};
type GitlabIntegrationData = {
  type: 'gitlab';
  data: {
    accessToken: string;
  };
};

export type IntegrationPlatforms = 'github' | 'bitbucket' | 'gitlab';

export type Integration = {
  id: number;
  uid?: string;
  accountName: string;
  repositoryAccess: string;
  orgUid: string;
  connectedApplications: ConnectedApplications;
  type: string;
  data: any;
} & (GithubIntegrationData | BitbucketIntegrationData | GitlabIntegrationData);

const CreateIntegration = `#graphql
  mutation(
    $createIntegrationInput: CreateIntegrationInput!
  ) {
    createIntegration(
      data: $createIntegrationInput
    )
  }
`;

const GetIntegrations = `#graphql
  query {
    integrations {
      id
      orgUid
      type
      accountName
      repositoryAccess
      connectedApplications {
        id
        name
        productName
        uid
      }
      data {
        installationId
        clientKey
      }
    }
  }
`;

const GetMetrics = `#graphql
  query (
    $applicationId: Int!
    $periods: [String!]
  ){
    leadTimeMetrics(
      applicationId: $applicationId
      periods: $periods
    ) {
      metric {
        application_id
        metrics {
          type
          hours_formated
        }
      }
    }
  }
`;

const GetMetricsByAppUid = `#graphql
  query (
    $applicationUid: String!
    $periods: [String!]
  ){
    leadTimeMetricsByAppUid(
      applicationUid: $applicationUid
      periods: $periods
    ) {
      metric {
        applicationUid
        metrics {
          type
          hoursFormated
        }
      }
    }
  }
`;

const GetGithubRepositories = `#graphql
  query ($installationId: Int!) {
    githubRepositories(
      installationId: $installationId
    ) {
      id_repo
      name
    }
  }
`;

const GetBitbucketRepositories = `#graphql
  query ($clientKey: String!) {
    bitbucketRepositories(
      clientKey: $clientKey
    ) {
      uuid
      name
    }
  }
`;

const CreateAppAssociation = `#graphql
  mutation(
    $data: CreateAppAssociationInput!
  ) {
    createAppAssociation(
      data: $data
    )
  }
`;

const DeleteIntegration = `#graphql
  mutation(
    $integrationId: Int!
  ) {
    deleteIntegration(integrationId: $integrationId)
  }
`;

const DeleteAppAssociation = `#graphql
  mutation($applicationUid: String!) {
    deleteAppAssociation(
      applicationUid: $applicationUid
    )
  }
`;

const repositoryQueryDictionary = {
  github: GetGithubRepositories,
  bitbucket: GetBitbucketRepositories,
  gitlab: ''
};

const mapGetRepositoryQueryArgs = (args: {
  type: IntegrationPlatforms;
  installationId: number | null;
  clientKey: string | null;
}) => {
  if (args.type === 'github')
    return {
      installationId: args.installationId
    };

  if (args.type === 'bitbucket')
    return {
      clientKey: args.clientKey
    };

  return {};
};

function useLeadTimeChanges() {
  const createIntegration = useMutation(CreateIntegration);
  const createAppAssociation = useMutation(CreateAppAssociation);
  const deleteIntegration = useMutation(DeleteIntegration);
  const deleteAppAssociation = useMutation(DeleteAppAssociation);
  const useIntegrations = (args?: { pause: boolean }) =>
    useQuery<{ integrations: Integration[] }>({
      query: GetIntegrations,
      pause: args?.pause ?? false
    });
  const useMetrics = (args?: { variables?: { applicationId: number; periods: string[] } }) =>
    useQuery({
      query: GetMetrics,
      variables: args?.variables
    });

  const useMetricsByAppUid = (args?: {
    variables?: { applicationUid: string; periods: string[] };
  }) => {
    return useQuery({
      query: GetMetricsByAppUid,
      variables: {
        applicationUid: args?.variables?.applicationUid,
        periods: args?.variables?.periods
      }
    });
  };

  const useRepositories = (args: {
    pause: boolean;
    clientKey: string | null;
    installationId: number | null;
    type: IntegrationPlatforms;
  }) =>
    useQuery({
      query: repositoryQueryDictionary[args.type],
      pause: args.pause,
      variables: mapGetRepositoryQueryArgs({
        type: args.type,
        clientKey: args.clientKey,
        installationId: args.installationId
      })
    });

  return useMemo(
    () => ({
      createIntegration,
      getIntegrations: useIntegrations,
      getMetrics: useMetrics,
      getMetricsByAppUid: useMetricsByAppUid,
      getRepositories: useRepositories,
      createAppAssociation,
      deleteIntegration,
      deleteAppAssociation
    }),
    []
  );
}

export default useLeadTimeChanges;
