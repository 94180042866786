import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    border: 'none'
  },
  title: {
    fontSize: theme.typography.pxToRem(14)
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    padding: theme.spacing(0, 1.25, 0, 1.25)
  },
  leadTimer: {
    fontSize: theme.typography.pxToRem(24),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  card: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    minWidth: theme.spacing(20),
    height: '100%'
  },
  textDay: {
    fontSize: theme.typography.pxToRem(12)
  },
  textToIntegration: {
    fontSize: theme.typography.pxToRem(14),
    paddingTop: theme.spacing(1.5),
    color: '#898989'
  },
  spacingButton: {
    marginTop: theme.spacing(1),
    padding: 0
  },
  disabled: {
    pointerEvents: 'none'
  }
}));
