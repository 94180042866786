import React from 'react';

import regionsAzure from 'constants/regionsAzure';

import { Grid, MenuItem, TextField } from '@material-ui/core';

import ResourceGroupSelect from './ResourceGroupSelect';
import SubnetSelect from './SubnetSelect';
import useGetVnets from './useGetVnets';
import VnetSelect from './VnetSelect';

const AzureFields = ({ formState, onChange }) => {
  const { cloudId, resourceGroup } = formState.values;

  const { data: vnets = [] } = useGetVnets({
    cloudId,
    resourceGroup
  });

  const handleChange = event => {
    event.persist();
    onChange(event.target.name, event.target.value);
  };

  const handleResourceGroupChange = resourceGroup => {
    onChange('resourceGroup', resourceGroup);
    onChange('vnetName', null);
    onChange('subnetName', null);
    onChange('location', null);
  };

  const handleVnetChange = vnetName => {
    onChange('vnetName', vnetName);
    const vnetLocation = vnets?.find(item => item.name === vnetName)?.location;
    onChange('location', vnetLocation);
  };

  return (
    <>
      <Grid item md={4} xs={12}>
        <ResourceGroupSelect formState={formState} onChange={handleResourceGroupChange} />
      </Grid>
      <Grid item md={4} xs={12}>
        <VnetSelect formState={formState} onChange={handleVnetChange} />
      </Grid>
      <Grid item md={4} xs={12}>
        <SubnetSelect formState={formState} onChange={onChange} />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          label="Location"
          margin="dense"
          name="location"
          onChange={handleChange}
          required
          select
          disabled
          value={formState.values.location || ''}
          variant="outlined"
          helperText="Your VNet location">
          {regionsAzure.map(option => (
            <MenuItem title={option.label} key={`location_${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};

export default AzureFields;
