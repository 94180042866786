import { useQuery, useMutation } from 'urql';

const communicationIntegrations = `#graphql
    query {
      communicationIntegrations {
        integrationPayload {
          ok
          team {
            id
            name
          }
        }
      }
    }
`;

const deleteCommunicationIntegrationMutation = `#graphql
  mutation {
    deleteCommunicationIntegration 
  }
`;

const useCommunicationIntegrations = () => {
  const deleteCommunicationIntegration = useMutation(deleteCommunicationIntegrationMutation);

  const useIntegrations = (args?: { pause: boolean }) =>
    useQuery<{ communicationIntegrations: any }>({
      query: communicationIntegrations,
      pause: args?.pause ?? false
    });

  return { getCommunicationIntegrations: useIntegrations, deleteCommunicationIntegration };
};

export default useCommunicationIntegrations;
