import { LambdaFormData } from 'views/ServicesHub/forms/monitoring/serveless/Lambda';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function lambdaAdapter(data: MonitoringCommomData & LambdaFormData) {
  const domain =
    data.domainType === 'url'
      ? data.domain
      : `${data.accessKey}:${data.secretAccessKey}/${data.awsRegion}@${data.functionName}`;
  // ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@functionName

  return {
    ...monitoringAdapter({ ...data, method: 'lambdaawsv2' }),
    domain,
    domainSettings: {
      searchPeriod: data.notInvoke,
      domainType: data.domainType
    }
  };
}
