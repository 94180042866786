import React from 'react';

const GetHealthCheckHelperText = param => {
  switch (param) {
    case 'sqlserver':
      return 'ex: sqlserver://USER:PASSWORD@HOST?database=DB';
    case 'mysql':
      return 'ex: USER:PASSWORD@tcp(HOST:PORT)/DATABASE';
    case 'mongodb':
      return 'ex: mongodb://HOST:PORT';
    case 'postgres':
      return 'ex: postgres://USER:PASSWORD@HOST:PORT/DATABASE';
    case 'redis':
      return 'ex: redis://HOST:PORT';
    case 'redisV2':
      return (
        <>
          ex1: HOST:PORT
          <br></br>
          ex2 (cluster): HOST1:PORT1,HOST2:PORT2,HOST3:PORT3...
          <br></br>
          ex3 (cluster): HOSTCONFIG:PORT
        </>
      );
    case 'memcached':
      return 'ex: HOST:PORT';
    case 'GET':
      return 'ex: http://example.com';
    case 'POST':
      return 'ex: http://example.com';
    case 'kafka':
      return 'ex: HOST:PORT/TOPIC';
    case 'kafka_advanced':
      return 'ex: HOST:PORT';
    case 'kafkadeprecated':
      return 'ex: HOST:PORT/TOPIC';
    case 'rabbitmq':
      return 'ex: amqp://USER:PASSWORD@HOST:PORT/VIRTUAL-HOST';
    case 'elastic':
      return (
        <>
          ex1: http://HOST:PORT/_cluster/health/staging_entities-orgid
          <br></br>
          ex2: http://USER:PASSWORD@HOST:PORT/_cluster/health/staging_entities-orgid
          <br></br>
          ex3: http://APIKEY@HOST:PORT/_cluster/health/staging_entities-orgid
        </>
      );
    case 'solr':
      return 'ex: http://HOST:PORT/solr/gettingstarted/admin/ping';
    case 'sqs':
      return 'ex: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TEST-QUEUE';
    case 'oracle':
      return 'ex: USER/PASSWORD@HOST:PORT/DATABASE/ as SYSDBA';
    case 'cassandra':
      return 'ex: HOST:PORT@USER/PASSWORD';
    case 'neo4j':
      return 'ex: bolt://HOST:PORT@USER/PASSWORD';
    case 'lambdaaws':
      return 'ex: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@functionName:aliasVersion';
    case 'lambdaawsv2':
      return 'ex: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@functionName';
    case 'ping':
      return 'ex: HOST';
    case 'tcp':
      return 'ex: HOST:PORT';
    case 'sidekiq':
      return 'ex: http://example.com/sidekiq/stats';
    case 'pubsub':
      return 'ex: TOPIC';
    case 'grpc':
      return 'ex: HOST:PORT';
    case 'functions':
      return 'ex: FUNCTION';
    case 'functionsgen2':
      return 'ex: FUNCTION';
    case 'dynamodb':
      return (
        <>
          ex1: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@tableName
          <br></br>
          ex2: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@tableName:primaryKey:valueItem
        </>
      );
    default:
      return '';
  }
};

export default GetHealthCheckHelperText;
