import Grid from '@material-ui/core/Grid';
import Toolbar from 'oldComponents/Crud/ToolbarQueryProps';
import { useQueryCache } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import useProfile from 'hooks/queriesGraphQL/useProfile';
import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';
import uploadReducer from 'redux/reducer/upload';

import { AccountDetails } from '../../components/AccountDetails/AccountDetails';
import { AccountProfile } from '../../components/AccountProfile/AccountProfile';

export const Account = () => {
  const dispatch = useDispatch();

  const queryCache = useQueryCache();

  const { data: account_edit } = useUser({
    refetchOnWindowFocus: false
  });

  const { updateProfile } = useProfile();

  const { upload, user: account } = useSelector(state => state);

  const saveHandler = (file, contentType, name) => {
    dispatch({
      type: actions.UPLOAD_AVATAR,
      meta: { reducer: uploadReducer },
      payload: { file, contentType, name }
    });
  };

  const updateProfileHandler = data => {
    return updateProfile({ updateProfileInput: data })
      .then(() => {
        queryCache.invalidateQueries('auth/logged');

        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Updated successfully'
        });
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
      });
  };

  return (
    <div>
      <Toolbar title="Profile" noSearch={true} noButton={true} />
      {account_edit && (
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} xl={4} xs={12}>
            <AccountProfile account={account} saveHandler={saveHandler} upload={upload} />
          </Grid>
          <Grid item lg={8} md={6} xl={8} xs={12}>
            <AccountDetails account_edit={account_edit} saveHandler={updateProfileHandler} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
