import { useQuery } from 'urql';

const GetFeaturePermissionQuery = `#graphql
  query($userId: Float!, $target: String!, $roleId: Float, $productId: Float) {
    getPermission(userId: $userId, target: $target, roleId: $roleId, productId: $productId)
  }
`;

type GetPermission = {
  getPermission: boolean;
};

type GetPermissionVariables = {
  userId: number;
  target: string;
  roleId?: number;
  productId?: number;
};

export function useHasPermission({
  userId,
  roleId,
  target,
  productId
}: {
  userId: number;
  roleId?: number;
  productId?: number;
  target: string;
}) {
  const [{ data, fetching }] = useQuery<GetPermission, GetPermissionVariables>({
    query: GetFeaturePermissionQuery,
    variables: { userId, roleId, target, productId },
    pause: !target || !userId || (productId === null && roleId === null)
  });

  if (fetching) return null;

  return Boolean(data?.getPermission);
}
