import { Application } from 'hooks/queriesGraphQL/useNewJourneyMatrix';

export const createEnvironmentsData = ({ data }: { data: Application[] }) => {
  return data.reduce((envs: any, app) => {
    const { origin, environment } = app;
    const id = environment.id || 0;
    const name = id ? environment.name : environmentName({ origin });

    if (envs[name]) {
      envs[name].data.push(app);

      return envs;
    }

    envs[name] = {
      name,
      id,
      data: [app]
    };

    return envs;
  }, {});
};

const environmentName = ({ origin }: { origin: string }): string => {
  switch (origin) {
    case 'api':
      return 'API';
    case 'pool':
      return 'AWS - us-east-1';
    default:
      return origin.toUpperCase();
  }
};
