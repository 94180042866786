import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';

function* ProductApplicationsdeploy(axios, action) {
  try {
    const applicationId = action.payload.id;
    yield axios.request({
      method: 'post',
      url: `/applications/${applicationId}/deploy`
    });

    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'starting deploy...'
    });

    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL
    });
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationsDeploy(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_DEPLOY, ProductApplicationsdeploy, axios);
}
