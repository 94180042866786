import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    helperTextColor: {
      color: theme.palette.error.main
    },
    label: {
      color: theme.palette.text.primary,
      display: 'flex'
    }
  };
});

export default useStyles;
