import React from 'react';

const TabPanel = props => {
  const { children, value, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      {...rest}>
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
