export enum MetabaseDashboards {
  ListByProduct = 'resources-list-by-product',
  DeploymentsListByOrg = 'deployments',
  DeploymentsByApplication = 'dora-metrics-deployments-application',
  UptimeByOrg = 'sre-metrics-uptime',
  UptimeByService = 'sre-metrics-uptime-service',
  LeadTimeForChanges = 'lead-time-for-changes',
  LeadTimeForChangesByApplication = 'lead-time-for-changes-application',
  IncidentsByOrg = 'incidents-by-org',
  IncidentsByOrgPerHour = 'incidents-by-hour',
  IncidentsByOrgPerDayOfWeek = 'incidents-by-day-of-week',
  ResourcesListByOrg = 'resources-list-by-org',
  MetricsUptime = 'system-health-monitoring',
  MetricsDora = 'dora-metrics',
  MetricsMtts = 'mtts-graphics',
  UptimeByApplication = 'uptimes-by-app',
  DeliveriesChecks = 'deliveries',
  ResourcesMonitoring = 'resource-monitoring-graphics',
  ResourcesMTTs = 'resource-mtts-graphics',
  ResourcesCheckExplorer = 'resources-check-explorer',
  ResourcesByProduct = 'resources-by-product',
  ResourcesByOrg = 'resources-by-org',
  ResourcesIncidentsByOrg = 'resources-incidents-by-org',
  InsightsIncidents = 'insights-incidents',
  InsightsResponders = 'events-responders',
  InsightsMonitoringPerformance = 'insights-monitoring-performance',
  InsightsRealTimeMonitoring = 'insights-real-time-monitoring',
  InsightsDoraMetrics = 'insights-dora-metrics',
  InsightsBusinessImpact = 'insights-business-impact',
  RealtimeLatenciesResources = 'realtime-latencies-resources',
  LatenciesPercentilesResources = 'latencies-percentiles-resources',
  LatenciesPercentiles = 'latencies-percentiles',
  RealtimeLatencies = 'realtime-latencies',
  CheckExplorerHitsOrFailures = 'check-explorer-hits-or-failures',
  OnCallList = '64-on-call-list',
  SingleSignOn = 'single-sign-on'
}
