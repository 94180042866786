import { takeLatest, put, select } from 'redux-saga/effects';

import { actions } from '../actionCreators';
import reducer from '../reducer';

function* prepareFetchEntities(axios, action) {
  try {
    const user = yield select(state => state.user);
    const {
      data: { data }
    } = yield axios.get(`/teams/withmember/${user.id}`);
    yield put({
      type: actions.PRODUCT_NEW_FETCH_TEAMS_SUCCESSFULL,
      payload: {
        teams: data
      },
      meta: { reducer }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

function* watchFetchTeams(axios) {
  yield takeLatest(actions.PRODUCT_NEW_FETCH_TEAMS, prepareFetchEntities, axios);
}

export default watchFetchTeams;
