import { useState } from 'react';

import { Typography, Box } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';
import { useProductPermission } from 'hooks/useProductPermission';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex',
    minHeight: '42px',
    marginTop: theme.spacing(1),
    flexWrap: 'wrap'
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10
  },
  deleteButton: {
    backgroundColor: red[700],
    borderColor: red[700],
    '&:hover': {
      backgroundColor: red[700],
      borderColor: 'none'
    }
  },
  backButton: {
    background: grey[200]
  },
  buttons: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

const ProductToolbar = props => {
  const { className, product, goBack, history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useHistory();

  const hasEditPermission = useProductPermission({
    productId: String(product?.id),
    target: 'ProductController-put-/products/:id'
  });
  const hasDeletePermission = useProductPermission({
    productId: String(product?.id),
    target: 'ProductController-delete-/products/:id'
  });

  const DeleteProductMutation = `#graphql
    mutation($productId: Float!){
      deleteProduct (productId: $productId){
        success
      }
    }
  `;

  const [{ fetching }, deleteProductMutation] = useMutation(DeleteProductMutation);

  const removeHandlerUsingBFF = event => {
    return deleteProductMutation({
      productId: product.id
    }).then(() => {
      router.push('/products');
    });
  };

  const goBackHandler = event => {
    event.preventDefault();
    goBack(history);
  };

  const coverUrl = product => {
    if (product.cover) {
      return product.cover;
    } else {
      return `${process.env.PUBLIC_URL}/images/not_product_2.png`;
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const canDelete = product => hasDeletePermission && product?.totalApplications === 0;

  const editHandler = () => dispatch(push(`/products/${product.id}/edit`));

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Box display="flex" alignItems="center">
          <div>
            <img alt={product.name} className={classes.cover} src={coverUrl(product)} />
          </div>
          <Typography variant="h4" name={product.name}>
            {product.name}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginLeft="auto" className={classes.buttons}>
          <ButtonSimple
            color="primary"
            variant="contained"
            onClick={() => setIsDeleteModalOpen(true)}
            text="Remove"
            className={classes.deleteButton}
            disabled={!hasDeletePermission}
            style={{
              width: 'initial'
            }}
          />
          <ButtonSimple
            title="Edit"
            color="primary"
            onClick={editHandler}
            variant="contained"
            disabled={!hasEditPermission}
            text="Edit"
          />

          <ButtonSimple
            onClick={goBackHandler}
            variant="outlined"
            color="primary"
            text="Back"
            style={{
              width: 'initial'
            }}
          />
        </Box>
      </div>

      <DeleteEntity
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleRemove={removeHandlerUsingBFF}
        entityName="Product"
        disabled={!canDelete(product)}
        productId={product.id}
        isLoading={fetching}
        customTitle={`You need to remove all applications from this product`}
      />
    </div>
  );
};

ProductToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductToolbar;
