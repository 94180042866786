export const postAdvancedSettings = `#graphql
  mutation(
    $teamId: Float!,
    $notifySettingsInput: [NotifySettingsInput!]!
  ){
    postNotificationSettings(
      teamId: $teamId, 
      notifySettingsInput: $notifySettingsInput
    ){
      teamId
      orgUid
      affected
    }   	
  }
  `;

export const queryNotificationSettingsMatrix = `#graphql
    query(
      $teamId: Float!
    ) {
      getNotificationSettingsMatrix(
        teamId: $teamId
      ){
      data{
            teamId
            resourceId
            resourceType
            resourceName
            environmentName
            environmentId
            Mail
            Telegram
            Slack 
            Teams
            SMS
            GoogleChat
            WhatsApp
            Voice
            CallRotation
            DiscordChannel
          }
      }
    } 
  `;
