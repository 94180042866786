import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentSentryGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Sentry',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/sentry/${integrationUid}?token=${token}`,
    warningSteps: [
      `There is no need for a payload to integrate the platform with Sentry, only the URL is required. 
        If you wish to classify the severity of an incident/alert, you can append the URL with &severity= followed by one of the following values:`,
      '• critical',
      '• high',
      '• moderate',
      '• low',
      '• informational',
      `If you prefer not to classify the incident, simply do not include this add-on.`
    ]
  };
};
