import { zodResolver } from '@hookform/resolvers/zod';
import { Backdrop, Box, CircularProgress, Divider } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Dialog from 'components/Dialog';
import useCreateMember from 'hooks/queriesGraphQL/useCreateMember';

import { ProfileForm } from './Form';
import schema from './schema';
import { useStyles } from './styles';

interface AddMemberDialogProps {
  open: boolean;
  handleChangeDialog: () => void;
  teamId: number;
  handleRefetchMembers: () => void;
}

export const AddMemberDialog = ({
  open,
  handleChangeDialog,
  teamId,
  handleRefetchMembers
}: AddMemberDialogProps) => {
  const classes = useStyles();

  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: '',
      roleId: '',
      userId: 0,
      userUid: ''
    },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const { createMember, isLoading } = useCreateMember();

  const saveUser = (data: any) => {
    const payload = {
      name: data?.name,
      roleId: Number(data?.roleId),
      userId: data?.userId,
      userUid: data?.userUid,
      isActive: true,
      teamId
    };

    createMember(payload).then(() => {
      handleChangeDialog();

      handleRefetchMembers();
    });
  };

  return (
    <>
      <Dialog
        title="New Member"
        open={open}
        onClose={handleChangeDialog}
        showCloseButton
        onSubmit={handleSubmit(saveUser)}
        actions={[
          {
            label: 'User',
            variant: 'contained',
            component: Link,
            className: classes.createUserButton,
            startIcon: <Add />,
            to: '/services-hub/new?type=users'
          },
          {
            label: 'Cancel',
            variant: 'outlined',
            className: classes.cancelButton,
            onClick: handleChangeDialog,
            color: 'default'
          },
          {
            label: 'Save user',
            variant: 'contained',
            className: classes.buttons,
            type: 'submit'
          }
        ]}>
        <Box>
          <form onSubmit={handleSubmit(saveUser)}>
            <ProfileForm control={control} register={register} setValue={setValue} />
          </form>

          <Backdrop open={isLoading} className={classes.backdrop}>
            <CircularProgress color="primary" size={90} thickness={5} />
          </Backdrop>

          <Divider className={classes.divider} />
        </Box>
      </Dialog>
    </>
  );
};
