export const ForceFinishMaintenance = `#graphql
  mutation($maintenanceInput: MaintenanceInput!) {
    forceFinish(
      maintenanceInput: $maintenanceInput
    ) {
      id
      serviceId
      applicationId
      status
      start
      finish
      forceFinish
    }
  }
`;
