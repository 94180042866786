import React from 'react';

import { apm } from '@elastic/apm-rum';
import { Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  DeleteAccountMutation,
  DeleteAccountWithAragornMutation
} from 'graphqlQueries/deleteAccount';
import Toolbar from 'oldComponents/Crud/ToolbarQueryProps';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery as useQueryGraphQL } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { capitalizeFirstLetter } from 'views/ServicesHub/forms/User';

import DataGrid from '../components/Crud/DataGrid';

import { GetAccountsQuery } from './graphqlQueriesAndMutations';

const styles = theme => ({
  content: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1),
    position: 'absolute',
    color: theme.palette.primary.dark
  }
});

const columns = [
  {
    grow: 0,
    center: true,
    compact: true,
    cell: row => (
      <Avatar src={row.user.photoURL}>
        {row.user.profile && row.user.profile.displayName
          ? row.user.profile.displayName
          : row.user.displayName}
      </Avatar>
    )
  },
  {
    name: 'Name',
    selector: 'displayName',
    sortable: true,
    cell: row =>
      row.user?.profile?.displayName ? row.user?.profile?.displayName : row.user?.displayName
  },
  {
    name: 'Email',
    sortable: true,
    cell: row => row.user.email
  },
  {
    name: 'Status',
    selector: 'isActive',
    sortable: true,
    cell: row => (row.isActive ? 'Active' : 'Disabled')
  },
  {
    name: 'Role',
    cell: row => capitalizeFirstLetter(row.role.name)
  }
];

const mapEntity = row => row.user;

const UserList = ({ fetchEntity, classes }) => {
  const permissions = {
    new: usePermission('UserController-post-/users'),
    update: usePermission('UserController-put-/users/:id/account'),
    remove: usePermission('UserController-delete-/users/:id')
  };

  const dispatch = useDispatch();

  const shouldUseNewUserFormCreate = useFeatureFlagUnleash('useNewUserFormCreate');

  const shouldUseAccountViaAragorn = useFeatureFlagUnleash('useAccountViaAragorn', {
    queryString: true
  });

  const [query, setQuery] = React.useState('');
  const [queryVariables, setQueryVariables] = React.useState({
    page: 1,
    per_page: 10,
    sort: 'name,ASC'
  });

  const [, deleteAccountMutation] = useMutation(DeleteAccountMutation);
  const [, deleteAccountWithAragornMutation] = useMutation(DeleteAccountWithAragornMutation);

  const [result, reexecuteQuery] = useQueryGraphQL({
    query: GetAccountsQuery,
    variables: {
      ...queryVariables,
      query
    }
  });

  const graphqlRequest = result.data?.accounts ? result.data.accounts : { data: [], total: 0 };

  React.useEffect(() => {
    fetchEntity();
  }, [fetchEntity]);

  const removeHandler = async payload => {
    const response = shouldUseAccountViaAragorn
      ? await deleteAccountWithAragornMutation({ userId: payload.id })
      : await deleteAccountMutation({ id: payload.id });

    if (response?.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on delete team' }
      });

      if (response.error) apm.captureError(response.error);
      return;
    }

    dispatch({
      payload: 'User deleted successfully',
      type: actions.GLOBAL_SUCCESS
    });

    reexecuteQuery();
  };

  const handlePerRowChange = per_page => {
    setQueryVariables({ ...queryVariables, per_page: per_page });
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  const handlePageChange = page => {
    setQueryVariables({ ...queryVariables, page: page });
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  const handleSort = ({ name }, sortDirection) => {
    setQueryVariables({
      ...queryVariables,
      sort: `${name.toLowerCase()},${sortDirection.toUpperCase()}`
    });
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  return (
    <>
      <Breadcrumb items={[{ label: 'People' }, { label: 'User Center' }]} />

      <Toolbar
        title="Users"
        buttonNewName="New"
        entity="users"
        newEntity="users"
        urlNew={shouldUseNewUserFormCreate && '/services-hub/new?type=users'}
        searchPlaceholder="Search user"
        disableNew={!permissions.new}
        setQuery={setQuery}
      />
      <div className={classes.content}>
        <DataGrid
          data={graphqlRequest?.data}
          total={graphqlRequest?.total}
          cols={columns}
          entity="users"
          mapEntity={mapEntity}
          permissions={permissions}
          handleSort={handleSort}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowChange}
          removeHandler={removeHandler}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEntity: () => {
      dispatch({
        type: actions.FETCH_ENTITY,
        payload: {},
        meta: {
          entity: 'users'
        }
      });
    }
  };
};

const ConnectedUserList = connect(() => ({}), mapDispatchToProps)(UserList);

export default withStyles(styles)(ConnectedUserList);
