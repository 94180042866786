export const CreateApplicationMutation = `#graphql
mutation(
  $applicationInput: ApplicationInput!
) {
  createApplication (
    applicationInput: $applicationInput
  ) {
    id
    productId
    sterasId
  }
}
`;
