import { Resource } from 'types/external/Resource';

import { MemcachedFormData } from 'views/ServicesHub/forms/monitoring/cache/Memcached';

import { monitoringAdapter } from '../monitoring';

export function memcachedAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & MemcachedFormData {
  const { domain, host, port } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    host,
    port
  };
}

const prepareDomain = (data: Resource) => {
  let host = '';
  let port = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    // ex: HOST:PORT
    const memcachedDomainRegex = /(.+):(.+)/;

    const match = domain.match(memcachedDomainRegex)!;

    // const host = match[1];
    // const port = match[2];
    host = match[1];
    port = match[2];
  }

  return { domain, host, port };
};
