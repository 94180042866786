import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';

function* provisionin(axios, action) {
  try {
    const environmentId = action.payload.environmentId;
    const entity = action.meta.entity;
    yield axios.request({
      method: 'post',
      url: `/${entity}/${environmentId}/clusters`
    });
    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Cluster provisioning...'
    });
    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL
    });
    yield put(push(`/${entity}`));
    yield put(push(`/${entity}/${environmentId}/`));
  } catch (err) {
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProvisioning(axios) {
  yield takeLatest(actions.PROVISION_ENTITY, provisionin, axios);
}
