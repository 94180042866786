import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export type StepNameProps = {
  step: number;
  name: string;
};

export function StepName({ step, name }: StepNameProps) {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Typography variant="body1" className={classes.name}>
        <strong>Step {step + 1}: </strong>
        {name}
      </Typography>
    </Box>
  );
}
