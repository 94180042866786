import React from 'react';

import { Card, CardHeader, CardContent, Divider, CircularProgress } from '@material-ui/core';

import initialValues from '../initialValues.js';
import NewFields from '../NotificationsForm/NewFields';

const Form = ({ formState, title, onSave, canCreate, newTeam, numberOptions, teamId }) => {
  const handleSave = data => {
    return onSave(data);
  };

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <Divider />
        <CardContent>
          {newTeam && (
            <NewFields
              initialValues={initialValues}
              canCreate={canCreate}
              handleSave={handleSave}
              nameField={true}
              newTeam={newTeam}
            />
          )}

          {!formState.values.name && !newTeam && (
            <CircularProgress
              style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            />
          )}

          {formState.values.name && (
            <NewFields
              initialValues={formState.values}
              canCreate={canCreate}
              handleSave={handleSave}
              nameField={true}
              numberOptions={numberOptions}
              teamId={teamId}
            />
          )}
        </CardContent>
        <Divider />
      </Card>
    </>
  );
};

export default Form;
