import { Fragment } from 'react';

import { Link } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

export function MarkdownHelperText({ children }: { children: string }) {
  return (
    <ReactMarkdown
      components={{
        p: Fragment,
        a: ({ href, children }) => (
          <Link href={href} target="_blank" rel="noopener noreferrer">
            {children}
          </Link>
        )
      }}>
      {children}
    </ReactMarkdown>
  );
}
