import { useQuery } from 'urql';

const getProvidersQuery = `#graphql
  query ($teamId: [Int!]!) {
    listProviders (data: { teamId: $teamId}) {
      id
      providerName
      enabled
      config {
        email
        numbers
        telegramId
        slackChannel
        slackWorkspace
        slackIntegration
        teamsWebhookUrl
        gchatWebhookUrl
        discordChannelWebhookUrl
        webhookUrl
        webhookRetry
        webhookRetryFailEmail
        apikey
      }
    }
  }`;

export enum ProvidersEnum {
  Mail = 'Mail',
  Telegram = 'Telegram',
  Slack = 'Slack',
  Teams = 'Teams',
  GoogleChat = 'GoogleChat',
  DiscordChannel = 'DiscordChannel',
  WebHook = 'WebHook'
}

export type Provider = {
  id: number;
  teamId: number;
  providerName: ProvidersEnum;
  enabled: boolean;
  config: {};
};

type GetProviders = {
  listProviders: Provider[];
};

export const useGetProviders = ({ teamId }: { teamId: number }) => {
  const [{ data: listProviders, fetching }, reexecuteQuery] = useQuery<GetProviders>({
    query: getProvidersQuery,
    variables: { teamId },
    pause: !teamId
  });

  return { data: listProviders?.listProviders, fetching, reexecuteQuery };
};

export default useGetProviders;
