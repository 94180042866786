import { AuditTrail } from 'types/external/AuditTrail';
import { useQuery } from 'urql';

type UseAuditTrailListParams = {
  page: number;
  perPage: number;
  search: string;
  customRange: string;
  period: string;
};

type SearchAuditTrailArgs = {
  from: number;
  size: number;
  query: string;
  betweenKey: string;
  betweenValues: string;
};

type AuditTrailList = {
  data: Pick<AuditTrail, 'id' | 'createdAt' | 'action' | 'title'>[];
  total: number;
};

const AuditTrailListQuery = `
  query($searchArgs: SearchAuditTrailArgs!) {
    auditTrailList(searchArgs: $searchArgs) {
      data {
        id
        createdAt
        action
        title
      }
      total
    }
  }
`;

export function useAuditTrailList({
  page,
  perPage: size,
  search: query,
  period,
  customRange
}: UseAuditTrailListParams) {
  const betweenValues = (() => {
    if (customRange) {
      return customRange;
    }

    if (period === 'all') {
      return `now-1y,now`;
    }

    return `now-${period},now`;
  })();

  const [{ data, fetching }] = useQuery<
    {
      auditTrailList: AuditTrailList;
    },
    { searchArgs: SearchAuditTrailArgs }
  >({
    query: AuditTrailListQuery,
    variables: {
      searchArgs: {
        from: (page - 1) * size,
        size,
        query: `*${query}*`,
        betweenKey: 'created_at',
        betweenValues
      }
    }
  });

  return {
    data: data?.auditTrailList.data || [],
    total: data?.auditTrailList.total || 0,
    isLoading: fetching
  };
}
