import { Dispatch, SetStateAction } from 'react';

import { Box, Button, Paper, Popper, TextField, ClickAwayListener } from '@material-ui/core';
import dayjs from 'dayjs';

import { useStyles } from './styles';

export type CustomRangeProps = {
  onApplyCustomRange: ({ betweenValue }: { betweenValue: string }) => void;
  handleClose: () => void;
  onClearCustomRange: () => void;
  anchorEl: HTMLElement | null;
  setBadgeContentCustomRange: Dispatch<SetStateAction<string | number>>;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
};

export function CustomRange({
  anchorEl,
  onApplyCustomRange,
  handleClose,
  onClearCustomRange,
  setBadgeContentCustomRange,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: CustomRangeProps) {
  const classes = useStyles();

  const handleChangeStartDate = (date: any) => {
    setStartDate(date.target.value);
  };

  const handleChangeEndDate = (date: any) => {
    setEndDate(date.target.value);
  };

  const handleApplyCustomRange = () => {
    const betweenValue = `${startDate}:00-03:00,${endDate}:59-03:00`;

    onApplyCustomRange({ betweenValue });
    setBadgeContentCustomRange('1');
    handleClose();
  };

  const handleClear = () => {
    setStartDate(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
    setEndDate(dayjs(new Date()).format('YYYY-MM-DDTHH:mm'));
    onClearCustomRange();
    setBadgeContentCustomRange(0);
    handleClose();
  };

  const startHourError = startDate > dayjs(new Date()).format('YYYY-MM-DDTHH:mm') ? true : false;
  const endHourError = endDate < startDate ? true : false;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-end"
        disablePortal={false}
        className={classes.popper}>
        <Paper className={classes.paper}>
          <Box display="flex" gridGap={8}>
            <TextField
              variant="outlined"
              size="small"
              error={startHourError}
              helperText={startHourError ? 'The date must be less than the current date' : ''}
              label="Start date"
              type="datetime-local"
              fullWidth
              required
              value={startDate}
              onChange={handleChangeStartDate}
              InputProps={{
                style: {
                  paddingRight: 0,
                  fontSize: 14
                }
              }}
              InputLabelProps={{
                shrink: true,
                color: 'secondary'
              }}
            />
            <TextField
              variant="outlined"
              size="small"
              error={endHourError}
              helperText={endHourError ? 'The date must be greater than the start date' : ''}
              label="End date"
              type="datetime-local"
              fullWidth
              required
              value={endDate}
              onChange={handleChangeEndDate}
              InputProps={{
                style: {
                  paddingRight: 0,
                  fontSize: 14
                }
              }}
              InputLabelProps={{
                shrink: true,
                color: 'secondary'
              }}
            />
          </Box>
          <Box className={classes.buttonsContainer} mt={2}>
            <Button variant="outlined" color="primary" onClick={handleClear}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyCustomRange}
              disabled={startHourError || endHourError}>
              APPLY TIME RANGE
            </Button>
          </Box>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}
