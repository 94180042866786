import { useQuery } from 'urql';

const GetUserCallRotation = `#graphql
  query(
    $userUid: String!
    $providerType: String!
  ) {
    getStepGroupsWithUser(
      userUid: $userUid
      providerType: $providerType
    ) {
      id
      name
      orgUid
      steps {
        id
        contactMethod
        userUid
      }
      team {
        id
        name
      }
      org {
        id
        name
        uid
      }
    }
  }
`;

interface Response {
  getStepGroupsWithUser: {
    id: number;
    name: string;
    orgUid: string;
    steps: {
      id: number;
      contactMethod: string;
      userUid: string;
    };
    team: {
      id: number;
      name: string;
    };
    org: {
      id: number;
      name: string;
      uid: string;
    };
  }[];
}

type ProviderType = 'WhatsApp' | 'Telegram' | 'e-mail' | 'voice' | 'SMS';

const mapping: Record<ProviderType, string> = {
  WhatsApp: 'WhatsApp',
  Telegram: 'Telegram',
  'e-mail': 'Mail',
  voice: 'Voice',
  SMS: 'SMS'
};

export const useGetUserCallRotation = ({
  type,
  userUid,
  providerType
}: {
  type: string;
  userUid: string;
  providerType?: string;
}) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery<Response>({
    query: GetUserCallRotation,
    pause: type !== 'on-call' || !userUid || !providerType,
    variables: {
      userUid,
      providerType: mapping[providerType as ProviderType]
    }
  });

  return { data: data?.getStepGroupsWithUser, fetching, reexecuteQuery };
};

export default useGetUserCallRotation;
