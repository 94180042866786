import { Box, Divider, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ theme, palette, spacing }) => ({
  divider: {
    background: palette.inputBorder.default,
    opacity: '30%'
  }
}));

function CloudCustom() {
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />

      <Box marginTop={4}>
        <Typography variant="h2" gutterBottom>
          You are about to set up a Custom cloud
        </Typography>
      </Box>

      <Typography gutterBottom>
        The 1P platform has policies configured for the most common cloud platforms among our
        customers: AWS and Azure. Custom mode is intended for customers who have cloud on other
        providers (eg GCP).
      </Typography>

      <Typography gutterBottom>
        In this scenario, you will need some code inserts into your cloud. Therefore, we recommend
        that you follow{' '}
        <Link
          target="_blank"
          href="https://help.elven.works/support/solutions/articles/69000610427-setup-cloud-custom">
          this FAQ article
        </Link>{' '}
        during installation
        <Link target="_blank" href="https://help.elven.works/support/tickets/new">
          {' '}
          or ask one of our implementers for help
        </Link>
        .
      </Typography>

      <Typography gutterBottom>
        If you want to configure a Custom cloud, just go to the creation of Environment and the
        other steps to follow.
      </Typography>
    </>
  );
}

export default withThemeV3Hoc(CloudCustom);
