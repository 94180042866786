import { ComparisonTypes } from 'constants/postBody';

const getCommonOptions = () => [
  {
    value: ComparisonTypes.IsEmpty,
    label: 'Is Empty'
  },
  {
    value: ComparisonTypes.IsNotEmpty,
    label: 'Is Not Empty'
  },
  {
    value: ComparisonTypes.Contains,
    label: 'Contains'
  },
  {
    value: ComparisonTypes.NotContains,
    label: 'Not Contains'
  }
];

const getNumberOptions = () => [
  {
    value: ComparisonTypes.EqualsNumber,
    label: 'Equals Number'
  },
  {
    value: ComparisonTypes.NotEqualNumber,
    label: 'Not Equal Number'
  },
  {
    value: ComparisonTypes.LessThan,
    label: 'Less Than'
  },
  {
    value: ComparisonTypes.LessThanOrEqual,
    label: 'Less Than Or Equal'
  },
  {
    value: ComparisonTypes.GreaterThan,
    label: 'Greater Than'
  },
  {
    value: ComparisonTypes.GreaterThanOrEqual,
    label: 'Greater Than Or Equal'
  }
];

const getAdditionalJsonOptions = () => [
  {
    value: ComparisonTypes.Equals,
    label: 'Equals'
  },
  {
    value: ComparisonTypes.NotEqual,
    label: 'Not Equal'
  },
  {
    value: ComparisonTypes.IsNumber,
    label: 'Is Number'
  },
  {
    value: ComparisonTypes.IsNull,
    label: 'Is Null'
  },
  {
    value: ComparisonTypes.IsTrue,
    label: 'Is True'
  },
  {
    value: ComparisonTypes.IsFalse,
    label: 'Is False'
  }
];

export const comparisonOptions = (source: unknown) => {
  switch (source) {
    case 'text_body':
      return getCommonOptions();
    case 'json_body':
      return [...getNumberOptions(), ...getCommonOptions(), ...getAdditionalJsonOptions()];
    case 'status_code':
      return getNumberOptions();
    default:
      return [];
  }
};
