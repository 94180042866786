export const CreateExternalServiceMutation = `#graphql
  mutation ($data: CreateExternalIntegrationRuleInput!) {
    createExternalIntegration(
      data: $data
    ){
      id
      uid
      name
    }
  }
`;

export const CreateExternalServiceV2Mutation = `#graphql
  mutation ($data: CreateExternalIntegrationRespondersInput!) {
    createExternalIntegrationV2(
      data: $data
    ){
      id
      uid
      name
    }
  }
`;
