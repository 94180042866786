import { takeEvery, put, select } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/crud';

const map = {
  teams: {
    entity: 'teams',
    url: '/teams',
    pagination: true,
    fields: ['name'],
    sort: 'name'
  },
  users: {
    entity: 'users',
    url: '/users',
    pagination: true,
    fields: ['email', 'displayName', 'profile'],
    sort: 'displayName'
  },
  services: {
    entity: 'services',
    url: '/services',
    pagination: true,
    fields: ['name', 'url'],
    sort: 'name'
  },
  environments: {
    entity: 'environments',
    url: '/environments',
    pagination: true,
    fields: ['name', 'cloudName'],
    sort: 'name'
  },
  clusters: {
    entity: 'clusters',
    url: '/clusters',
    pagination: true,
    fields: ['name', 'environmentName'],
    sort: 'name'
  },
  clouds: {
    entity: 'clouds',
    url: '/clouds',
    pagination: true,
    fields: ['accessKeyId', 'secretAccessKey', 'region'],
    sort: 'name'
  },
  products: {
    entity: 'products',
    url: '/products',
    pagination: true,
    sort: 'name'
  },
  menuItems: {
    entity: 'menuItems',
    url: '/menuItems',
    pagination: true,
    fields: null,
    sort: 'order'
  },
  roles: {
    entity: 'roles',
    url: '/roles',
    pagination: true,
    sort: 'name'
  },
  features: {
    entity: 'features',
    url: '/features',
    pagination: true,
    sort: 'name'
  },
  permissions: {
    entity: 'permissions',
    url: '/permissions',
    pagination: false
  }
};

function urlBuilder(mappedEntity, pagination, page, per_page, query, sort) {
  const filter = query.map(q => `&${q.key}=${q.value}`);
  const sorter = sort && sort.length > 0 ? sort : `&sort=${mappedEntity.sort},ASC`;
  return pagination
    ? `${mappedEntity.url}?page=${page}&per_page=${per_page}${filter}${sorter}`
    : `${mappedEntity.url}?${filter}`;
}

function* prepareFetchEntities(axios, action) {
  try {
    const {
      meta: { entity, pagination = null, query = [] }
    } = action;

    const mappedEntity = map[entity];
    const shouldPaginate = pagination !== null ? pagination : mappedEntity.pagination;
    const state = yield select(state => state);
    const resourcesTab = mappedEntity.entity === 'products';

    if (mappedEntity && !resourcesTab) {
      const q = state[mappedEntity.entity].query;
      if (q) {
        query.push({ key: 'q', value: q });
      }
      const sort = state[mappedEntity.entity].sort;

      const { page, per_page } = state[mappedEntity.entity];
      const { data: payload } = yield axios.request({
        method: 'get',
        url: urlBuilder(mappedEntity, shouldPaginate, page, per_page, query, sort)
      });

      yield put({
        type: actions.FETCH_ENTITY_SUCCESSFUL,
        meta: {
          entity: mappedEntity.entity,
          reducer,
          pagination: shouldPaginate
        },
        payload
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchNavigation(axios) {
  yield takeEvery([actions.FETCH_ENTITY, actions.PAGINATE_ENTITY], prepareFetchEntities, axios);
}
