import applicationType from 'constants/ApplicationType';

import { makeStyles, Typography } from '@material-ui/core';
import { formatDistanceStrict } from 'date-fns';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import ActiveIncidentLink from './ActiveIncidentLink';

const useStyles = makeStyles(({ spacing }) => ({
  informations: {
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    display: 'block',
    borderRadius: 20,
    backgroundColor: 'green',
    border: '1px solid #000000',
    width: 16,
    height: 16,
    marginRight: spacing()
  }
}));

function Info({
  team,
  application,
  monitoringActive,
  selectAgentType,
  lastCheck,
  productId,
  applicationId,
  previousDayDate
}) {
  const classes = useStyles();

  const isWebhook = application.applicationsSettings?.type === applicationType.Webhook.type;
  const useTeamInApp = useFeatureFlagUnleash('useTeamInApp');

  return (
    <>
      <Typography noWrap>
        {`Healthcheck URL: `}
        {application.applicationsSettings && application.applicationsSettings.healthcheckUrl
          ? application.applicationsSettings.healthcheckUrl
          : 'healthcheckUrl not registered'}
      </Typography>
      <Typography>
        {application.applicationsSettings && application.applicationsSettings.check_interval
          ? `interval ${application.applicationsSettings.check_interval}s, `
          : 'intervals not registered, '}
        {isWebhook
          ? ''
          : application.applicationsSettings && application.applicationsSettings.timeout
          ? `timeout ${application.applicationsSettings.timeout}s, `
          : 'timeout not registered, '}
        {application.applicationsSettings && application.applicationsSettings.failuresToIncident
          ? `Fails to open automatic incident ${application.applicationsSettings.failuresToIncident}`
          : 'Fails to open automatic incident not registered'}
      </Typography>
      {monitoringActive && (
        <ActiveIncidentLink productId={productId} applicationId={applicationId} />
      )}
      <Typography className={classes.gutterTop} gutterBottom>
        <strong>Environment:</strong> {application.environment?.name}
      </Typography>
      <Typography gutterBottom>
        <strong>Cloud Account:</strong> {application.environment?.cloud.name}
      </Typography>
      <Typography gutterBottom>
        <strong>Agent Status:</strong>{' '}
        {application.agent?.status_code === 16 ? 'Installed' : 'Not installed'}
      </Typography>
      <Typography gutterBottom>
        <strong>Agent:</strong> {selectAgentType(application)}
      </Typography>
      <Typography gutterBottom>
        <strong>Last Check:</strong>{' '}
        {new Date(lastCheck).getTime() > previousDayDate.getTime()
          ? formatDistanceStrict(new Date(lastCheck), new Date())
          : 'No data'}
      </Typography>
      {useTeamInApp && (
        <Typography gutterBottom>
          <strong>Team:</strong> {team ? team?.name : 'No data'}
        </Typography>
      )}
    </>
  );
}

export default Info;
