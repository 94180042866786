import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/environments';

function* prepareFetchEntities(axios, action) {
  try {
    const {
      data: { data: payload }
    } = yield axios.get('/clouds');
    yield put({
      type: actions.ENVIRONMENTS_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchEnvFetch(axios) {
  yield takeLatest(actions.ENVIRONMENTS_FETCH, prepareFetchEntities, axios);
}
