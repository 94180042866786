import { z } from 'zod';

import { makeMonitoringSchema } from '../makeMonitoringSchema';

export const kafkaSchema = makeMonitoringSchema({
  brokers: z.array(z.object({ key: z.string(), value: z.string() })).optional(),
  topic: z.string().optional(),
  username: z.string().optional(),
  password: z.string().optional(),
  mechanism: z.string().optional(),
  authentication: z.boolean().optional(),
  tls: z.boolean().optional(),
  sasl: z.boolean().optional(),
  consumer: z.string().optional(),
  lag: z.coerce.number().optional(),
  withLag: z.coerce.boolean().optional(),
  lagTolerance: z.string().transform(data => data === 'true')
}).superRefine((data, ctx) => {
  if (data.domainType !== 'url') {
    if (data.brokers) {
      const hasPortOutRange = data.brokers.findIndex(broker => Number(broker?.value) > 65535);
      if (hasPortOutRange !== -1) {
        ctx.addIssue({
          path: [`brokers[${hasPortOutRange}].value`],
          message: 'The port must not be greater than the 65535',
          code: z.ZodIssueCode.custom
        });
      }
    }

    if (data.authentication && !data.username) {
      ctx.addIssue({
        path: ['username'],
        message: 'Username is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (data.authentication && !data.password) {
      ctx.addIssue({
        path: ['password'],
        message: 'Password is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (data.authentication && !data.mechanism) {
      ctx.addIssue({
        path: ['mechanism'],
        message: 'Mechanism is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (data.withLag && !data.consumer) {
      ctx.addIssue({
        path: ['consumer'],
        message: 'Consumer is required',
        code: z.ZodIssueCode.custom
      });
    }

    if (data.withLag && !data.lag) {
      ctx.addIssue({
        path: ['lag'],
        message: 'Lag is required',
        code: z.ZodIssueCode.custom
      });
    }
  }
});
