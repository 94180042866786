import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

function DeploymentsMetrics() {
  const dashboardName = MetabaseDashboards.DeploymentsListByOrg;

  return (
    <MetabaseDashboard
      title="Organization deployments metrics"
      subtitle="List of resources with their deployments"
      dashboardName={dashboardName}
    />
  );
}

export default DeploymentsMetrics;
