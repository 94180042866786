import { ApplicationType } from 'constants/ApplicationType';
import EventType from 'constants/EventType';
import MonitoringStatusV0 from 'constants/MonitoringStatusV0';

import { Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Link } from 'react-router-dom';

import LinkButton from 'componentsV3/LinkButton';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

import getApplicationTypeTitle from './getApplicationTypeTitle';
import getColorForStatus from './getColorForStatus';
import getTextForStatus from './getTextForStatus';
import IconForStatus from './IconForStatus';
import LastUpdate from './LastUpdate';

const CardApplication = ({
  id,
  name,
  type,
  status,
  productId,
  event
}: {
  id: number;
  name: string;
  type: ApplicationType;
  status?: MonitoringStatusV0;
  productId: number;
  event?: {
    type?: EventType;
    incidentId?: number;
    applicationId?: number;
    productId?: number;
    createdAt: Date;
  };
}) => {
  const theme = useTheme<Theme>();

  const { statusText, statusTooltipText } = getTextForStatus(status);

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        {status && (
          <Box
            display="flex"
            marginBottom={0.5}
            style={{ color: getColorForStatus({ status, theme }) }}>
            <IconForStatus status={status} style={{ marginRight: theme.spacing(0.5) }} />

            <Typography
              variant="overline"
              color="inherit"
              style={{ fontSize: 10 }}
              title={statusTooltipText}>
              STATUS: {statusText}
            </Typography>
          </Box>
        )}

        <Link to={`/products/${productId}/availability/applications/${id}`}>
          <LinkButton
            style={{
              fontWeight: 500,
              marginBottom: theme.spacing(0.5),
              padding: 0,
              justifyContent: 'flex-start'
            }}>
            {name}
          </LinkButton>
        </Link>

        <Typography
          variant="caption"
          color="textSecondary"
          component="p"
          style={{ color: '#8C8C8C', marginBottom: theme.spacing(0.5) }}>
          {getApplicationTypeTitle(type)}
        </Typography>

        {event && (
          <LastUpdate
            inlineDate={true}
            type={event.type}
            incidentId={event.incidentId}
            productId={event.productId}
            createdAt={event.createdAt}
            prefix="Last update: "
            textStylePrimary={{ ...theme.typography.caption, color: '#595959' }}
          />
        )}
      </Box>

      {/* TODO: we decided that for this initial version we won't show this switch.
          We think the users might not use this feature engough to justify the effort of implementing it. */}
      {/* <Box style={{ marginRight: -9 }}>
        <Switch
          checked={isMonitoringActive}
          // onChange={handleChange}
          name="checkedA"
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </Box> */}
    </Box>
  );
};

export default withThemeV3Hoc(CardApplication);
