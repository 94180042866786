const keyValuesToObject = keyValues => {
  const filteredKeyValues = keyValues.filter(item => Boolean(item.key));
  const asObject = filteredKeyValues.reduce((final, item) => {
    return { ...final, [item.key]: item.value };
  }, {});

  return filteredKeyValues.length ? asObject : null;
};

export default keyValuesToObject;
