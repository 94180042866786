import { ChangeEvent, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Divider,
  TextField,
  useTheme
} from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Resource, ResourceStatusEnum } from 'types/external/Resource';

import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/Button';
import { Title } from 'components/Title';
import { EventsFilters } from 'componentsV4/Filters';
import { PageFilters as NewPageFilters } from 'componentsV4/Filters/Search';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { useStyles } from 'helpers/globalStyles';
import useQueryParams from 'helpers/useQueryParams';
import { useResourceList } from 'hooks/queriesGraphQL/useResourceList';
import { useResources } from 'hooks/queriesGraphQL/useResources';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';

import { Filters, PageFilters } from './components/Filters';
import { StatusChip } from './components/StatusChip';

const GetResourcesQuery = `#graphql
  query(
    $from: Int!,
    $size: Int!,
    $query: String!
  ) {
    getResourcesFromIndexer(
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        uid
        name
        status {
          status
        }                
      }
      total
    }
  }
`;

const PER_PAGE_OPTIONS = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500; // 0.5s

const COLUMNS: TableColumn<Resource>[] = [
  {
    name: 'Status',
    selector: row => row.status.status,
    cell: row => <StatusChip status={row.status.status} />
  },
  {
    name: 'Monitoring Name',
    selector: row => row.name
  }
];

export function MonitoringList() {
  const classes = useStyles();
  const theme = useTheme();
  const queryParams = useQueryParams();

  const shouldUseNewResourceFilters = useFeatureFlagUnleash('useNewResourceFilters', {
    queryString: true
  });

  const history = useHistory();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<ResourceStatusEnum | null>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0]);

  const statusURL = queryParams.get('status');

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    statusURL && setStatus(statusURL as ResourceStatusEnum);
  }, [shouldUseNewResourceFilters, statusURL, setStatus]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const handleApplyFilters = (filters: PageFilters) => {
    setStatus(filters.status);
    setPage(1);
  };

  const newHandleApplyFilters = (filters: NewPageFilters) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    setStatus(filters.status as ResourceStatusEnum);
    setPage(1);
    handleToggleFilters();
    reexecuteQuery();
  };

  const handleClearFilters = () => {
    removeQueryParams('status');
    setStatus(null);
    setPage(1);
  };

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [{ fetching, data }, refetch] = useResources({
    graphqlQuery: GetResourcesQuery,
    page,
    perPage,
    query: buildElasticQuery({ search, 'status.status': status }),
    pause: shouldUseNewResourceFilters
  });

  const {
    data: { resources, total },
    fetching: isFetchingResource,
    reexecuteQuery
  } = useResourceList({
    page,
    perPage,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'name',
          value: search,
          isRegex: true
        },
        {
          key: 'status.status',
          value: status || 'all',
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'resources',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true',
    functions: [
      { filter: { term: { 'status.status': 'unavailable' } }, weight: 10 },
      { filter: { term: { 'status.status': 'maintenance' } }, weight: 8 },
      { filter: { term: { 'status.status': 'pending' } }, weight: 6 },
      { filter: { term: { 'status.status': 'available' } }, weight: 4 },
      { filter: { term: { 'status.status': 'off' } }, weight: 2 }
    ],
    sortKey: 'status.updated_at',
    maxBoost: 10,
    scoreMode: 'sum',
    boostMode: 'replace',
    minScore: 1,
    pause: !shouldUseNewResourceFilters
  });

  const resourceData =
    (shouldUseNewResourceFilters ? resources : data?.getResourcesFromIndexer?.data) || [];

  const resourceTotal =
    (shouldUseNewResourceFilters ? total : data?.getResourcesFromIndexer?.total) || 0;

  const isFetching = shouldUseNewResourceFilters ? isFetchingResource : fetching;

  useEffect(() => {
    if (shouldUseNewResourceFilters) {
      reexecuteQuery();
      return;
    }

    refetch();
  }, [shouldUseNewResourceFilters, refetch, reexecuteQuery]);

  const hasPermissionCreateResource = usePermission('ResourceController-post-/resource');

  const appliedFilters = () => {
    return {
      status: status ? status : 'all'
    };
  };

  const resourceStatusOptions = {
    label: 'Status',
    options: [
      { value: 'all', label: 'All' },
      { value: ResourceStatusEnum.Off, label: 'Inactive' },
      { value: ResourceStatusEnum.Available, label: 'Operational' },
      { value: ResourceStatusEnum.Pending, label: 'Pending' },
      { value: ResourceStatusEnum.Maintenance, label: 'In Maintenance' },
      { value: ResourceStatusEnum.Unavailable, label: 'Not Operational' }
    ]
  };

  return (
    <>
      <Breadcrumb
        items={[{ label: 'Monitoring' }, { link: `/monitoring`, label: 'Resource Center' }]}
      />

      <Title title="Resource Center">
        <Button
          component={Link}
          to="/services-hub"
          title="Create new resource"
          disabled={!hasPermissionCreateResource}>
          Resource
        </Button>
      </Title>

      {shouldUseNewResourceFilters ? (
        <EventsFilters
          handleChangeSearch={handleChangeSearch}
          onApplyFilters={newHandleApplyFilters}
          onClearFilters={handleClearFilters}
          appliedFilters={appliedFilters}
          hideSeverity={true}
          hideOrigins={true}
          statusOptions={resourceStatusOptions}
          includePeriod={false}
          includeCustomRange={false}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={7.5}
          gridGap={theme.spacing(1.5)}
          {...{
            ref: searchContainerRef
          }}>
          <TextField
            name="search"
            label="Search"
            variant="outlined"
            onChange={handleChangeSearch}
            placeholder="Try to search by name"
            InputProps={{
              endAdornment: <Search />,
              className: classes.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            fullWidth
          />

          <MuiButton
            className={classes.filterButton}
            variant="outlined"
            color="primary"
            onClick={handleToggleFilters}>
            <FilterList />
          </MuiButton>
        </Box>
      )}

      {!shouldUseNewResourceFilters && isFiltersOpen ? (
        <Filters
          anchorEl={searchContainerRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      ) : null}

      {resourceData?.length ? <Divider /> : null}

      <DataTable
        columns={COLUMNS}
        data={resourceData as Resource[]}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE_OPTIONS}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={resourceTotal}
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={isFetching}
        highlightOnHover
        pagination
        striped
        pointerOnHover={true}
        onRowClicked={(resource, e) => {
          if (e.ctrlKey) {
            return window.open(`/monitoring/${resource.uid}`, '_blank');
          }
          history.push(`/monitoring/${resource.uid}`);
        }}
      />
    </>
  );
}
