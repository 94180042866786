import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function MicrosoftTeamsFilled(props: SvgIconProps) {
  return (
    <SvgIcon
      width="800px"
      height="800px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}>
      <path
        fill="#5059C9"
        d="M10.765 6.875h3.616c.342 0 .619.276.619.617v3.288a2.272 2.272 0 01-2.274 2.27h-.01a2.272 2.272 0 01-2.274-2.27V7.199c0-.179.145-.323.323-.323zm2.445-.65a1.464 1.464 0 10-.001-2.925c-.809 0-1.465.655-1.465 1.463 0 .807.656 1.462 1.465 1.462z"
      />
      <path
        fill="#7B83EB"
        d="M8.651 6.225a2.114 2.114 0 002.117-2.112A2.114 2.114 0 008.65 2a2.114 2.114 0 00-2.116 2.112c0 1.167.947 2.113 2.116 2.113zm2.822.65h-5.97a.611.611 0 00-.596.625v3.75A3.669 3.669 0 008.488 15a3.669 3.669 0 003.582-3.75V7.5a.611.611 0 00-.597-.625z"
      />
      <path
        fill="#000"
        d="M8.814 6.875v5.255a.598.598 0 01-.596.595H5.193a3.951 3.951 0 01-.287-1.476V7.5a.61.61 0 01.597-.624h3.31z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M8.488 6.875v5.58a.6.6 0 01-.596.595H5.347a3.22 3.22 0 01-.267-.65 3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.985z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.488 6.875v4.93a.6.6 0 01-.596.595H5.08a3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.985z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.163 6.875v4.93a.6.6 0 01-.596.595H5.079a3.951 3.951 0 01-.172-1.15V7.498a.61.61 0 01.596-.624h2.66z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.814 5.195v1.024c-.055.003-.107.006-.163.006-.055 0-.107-.003-.163-.006A2.115 2.115 0 016.593 4.6h1.625a.598.598 0 01.596.594z"
        opacity={0.1}
      />
      <path
        fill="#000"
        d="M8.488 5.52v.699a2.115 2.115 0 01-1.79-1.293h1.195a.598.598 0 01.595.594z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.488 5.52v.699a2.115 2.115 0 01-1.79-1.293h1.195a.598.598 0 01.595.594z"
        opacity={0.2}
      />
      <path
        fill="#000"
        d="M8.163 5.52v.647a2.115 2.115 0 01-1.465-1.242h.87a.598.598 0 01.595.595z"
        opacity={0.2}
      />
      <path
        fill="url(#microsoft-teams-color-16__paint0_linear_2372_494)"
        d="M1.597 4.925h5.969c.33 0 .597.267.597.596v5.958a.596.596 0 01-.597.596h-5.97A.596.596 0 011 11.479V5.521c0-.33.267-.596.597-.596z"
      />
      <path fill="#fff" d="M6.152 7.193H4.959v3.243h-.76V7.193H3.01v-.63h3.141v.63z" />
      <defs>
        <linearGradient
          id="microsoft-teams-color-16__paint0_linear_2372_494"
          x1={2.244}
          x2={6.906}
          y1={4.46}
          y2={12.548}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A62C3" />
          <stop offset={0.5} stopColor="#4D55BD" />
          <stop offset={1} stopColor="#3940AB" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
