import { cloneElement } from 'react';

import { makeStyles, FormHelperText, Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

import HelperIcon from '../HelperIcon/index';

const useStyles = makeStyles(theme => {
  return {
    root: {
      borderRadius: 8,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      color: theme.palette.text.primary,
      display: 'flex'
    },
    input: {
      backgroundColor: theme.palette.common.white,
      padding: 0,
      '& > div:first-child': {
        paddingRight: theme.spacing(4)
      },
      '& > input': {
        paddingRight: props => (props.endAdornment ? theme.spacing(3) : undefined)
      }
    },
    helperText: {
      alignSelf: 'flex-end',
      color: props => (props.error ? theme.palette.error.main : theme.palette.text.secondary)
    },
    fields: {
      margin: 0
    },
    icon: { position: 'absolute', right: 8, color: theme.palette.ligthGrey, whiteSpace: 'nowrap' }
  };
});

const SimpleInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    border: `1px solid`,
    borderColor: props =>
      props.error ? theme.palette.error.main : theme.palette.inputBorder.default,
    fontSize: 16,
    padding: '8px 12px',
    height: '22px'
  }
}))(InputBase);

const InputDefault = ({
  label,
  placeholder,
  tooltip,
  multiline,
  linesNumber,
  type,
  required,
  disabled,
  name,
  id,
  handleChangeValue,
  defaultValue,
  helperText,
  errors,
  inputRef,
  InputProps: { endAdornment, ...InputProps } = {},
  ...otherProps
}) => {
  const errorMessage = errors && errors[name]?.message;
  const theme = useTheme();

  const helperTextMessage = () => {
    if (errorMessage && !helperText) return errorMessage;
    if (helperText && !errorMessage) return helperText;
    if (errorMessage && helperText)
      return (
        <p>
          {errorMessage} <br /> {helperText}
        </p>
      );
  };

  const error = Boolean(errorMessage);

  const classes = useStyles({ error, endAdornment: Boolean(endAdornment) });

  return (
    <FormControl className={classes.root} error={error}>
      <Grid container>
        <Typography
          className={classes.label}
          component="label"
          color={theme.palette.text.primary}
          variant="body2"
          htmlFor={id}>
          {required ? label + ' *' : label}
        </Typography>
        {tooltip && <HelperIcon tooltipText={tooltip}></HelperIcon>}
      </Grid>
      <SimpleInput
        id={id}
        className={classes.input}
        placeholder={placeholder}
        multiline={multiline}
        minRows={linesNumber}
        type={type}
        disabled={disabled}
        defaultValue={defaultValue}
        autoFocus={false}
        name={name}
        inputRef={inputRef}
        error={error}
        onChange={handleChangeValue}
        fullWidth
        endAdornment={
          endAdornment
            ? cloneElement(endAdornment, {
                style: { position: 'absolute', right: 8 }
              })
            : undefined
        }
        {...otherProps}
        {...InputProps}
      />
      <FormHelperText className={classes.helperText}>{helperTextMessage()}</FormHelperText>
    </FormControl>
  );
};

export default withThemeV3Hoc(InputDefault);
