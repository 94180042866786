import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';
import { useParams } from 'react-router-dom';

function UptimeServiceMetrics() {
  const dashboardName = MetabaseDashboards.UptimeByService;

  const { serviceId } = useParams<{
    serviceId: string;
  }>();

  const params = {
    service_id: serviceId
  };

  const hideParams = ['service_id'];

  return (
    <MetabaseDashboard
      title="Service uptime metrics"
      subtitle="Uptime service metrics"
      dashboardName={dashboardName}
      dashboardParams={params}
      hideParams={hideParams}
    />
  );
}

export default UptimeServiceMetrics;
