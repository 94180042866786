import React from 'react';

import { TextField, MenuItem, CircularProgress, Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import axios from 'redux/axios';

import actions from '../../../../redux/actions';

import useSubnet from './useSubnet';

const SubnetSelectAWS = ({ classes, formState, onChange }) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const cloudId = formState.values.cloudId;
  const region = formState.values.region;
  const vpcId = formState.values.vpcId;

  const { data: rawSubnets = [], isIdle, isPending } = useSubnet({
    cloudId,
    region,
    vpcId
  });

  const subnets = rawSubnets.map(option => {
    const { subnetId, subnetName } = option;

    const name = `(${subnetName ? subnetName : 'no name'})`;
    const subnetLabel = `${subnetId} ${name}`.trim();

    return { subnetId, subnetLabel };
  });

  const waitingSelectionsText = 'Select a Cloud, Region and VPC first';
  const pendingText = 'loading...';
  const selectText = 'select a Subnet';

  const handleChange = event => {
    event.persist();
    const { subnetId, subnetLabel } =
      subnets.find(subnet => subnet.subnetId === event.target.value) || {};
    if (subnetId !== undefined) {
      setLoading(true);
      axios
        .get(`/subnetStatus?subnetId=${subnetId}&cloudId=${cloudId}&region=${region}`)
        .then(response => validateSubnet(event, response.data.data, subnetLabel))
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  };

  const validateSubnet = (event, status, subnetLabel) => {
    setLoading(false);
    if (status.subnetStatus === 'unavailable') {
      onChange(event.target.name, undefined);
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: {
          message: 'Subnet not elegible! Verify your RouteTable.'
        }
      });
    } else {
      onChange(event.target.name, event.target.value);
      onChange('subnetLabel', subnetLabel);
      onChange('associatePublicIpAddress', Boolean(status.associatePublicIpAddress));
      if (status.subnetStatus === 'unknown') {
        dispatch({
          type: actions.GLOBAL_WARNING,
          payload: 'Unknown subnet RouteTable! Use it at your own risk.'
        });
      }
    }
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="primary" size={90} thickness={5} />
      </Backdrop>
      <TextField
        fullWidth
        select
        InputLabelProps={{
          shrink: true
        }}
        label="Subnet"
        margin="dense"
        name="subnetId"
        onChange={handleChange}
        required
        value={isPending ? '1' : formState.values.subnetId || '1'}
        variant="outlined"
        error={hasError('subnetId')}
        helperText={hasError('subnetId') ? formState.errors.subnetId[0] : null}>
        {isIdle && (
          <MenuItem title={waitingSelectionsText} value="1">
            <em>{waitingSelectionsText}</em>
          </MenuItem>
        )}
        {isPending && (
          <MenuItem title={pendingText} value="1">
            <em>{pendingText}</em>
          </MenuItem>
        )}
        {!isIdle && !isPending && (
          <MenuItem title={selectText} value="1">
            <em>{selectText}</em>
          </MenuItem>
        )}
        {subnets.map(({ subnetId, subnetLabel }) => {
          return (
            <MenuItem title={subnetLabel} key={`subnet_${subnetId}`} value={subnetId}>
              {subnetLabel}
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

const styles = theme => ({
  marginButton: {
    marginLeft: 4
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  }
});

export default withStyles(styles)(SubnetSelectAWS);
