import { push } from 'connected-react-router';
import { put, takeLatest, select } from 'redux-saga/effects';

import encrypt from 'helpers/encrypt';
import { analyticsEvent } from 'services/firebase/analytics';

import actions from '../../actions';
import reducer from '../../reducer/signInValidation';

function* loginUser(axios, action) {
  try {
    const {
      signIn: { values },
      initialUrl
    } = yield select(state => state);

    const login = JSON.stringify({ ...values, email: values.email.toLowerCase() });

    const hash = encrypt(login);

    yield axios.post('/auth/login', { data: hash });

    yield put({ type: actions.LOGGED_USER });
    const mercyfulFate = initialUrl === '/reset' || initialUrl === '/sign-up' ? '/' : initialUrl;
    yield put(push(mercyfulFate));
    analyticsEvent('signin_success');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    analyticsEvent('signin_error');
    yield put({
      type: actions.LOGIN_USER_FAILED,
      payload: err,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchLogin(axios) {
  yield takeLatest(actions.LOGIN_USER_PASSWORD, loginUser, axios);
}
