export const kafkaMapToApi = keyValues => {
  const { user, password, mechanism, tls, sasl, topic, consumer, lag, brokers } = keyValues;

  const payload = {
    brokers: brokers?.filter(Boolean) || [],
    consumer,
    lag: Number(lag) || 0,
    mechanism: mechanism || null,
    user,
    password,
    sasl,
    tls,
    topic
  };

  return payload;
};
