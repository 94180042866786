import { useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { usePermission } from 'hooks/usePermission';

import { TeamDeleteDialog } from '../../components/DeleteDialog';
import { useStyles } from '../../styles';

type MenuProps = {
  id: number;
  name?: string;
  refetch: () => void;
};

export function MenuActions({ id, name, refetch }: MenuProps) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteTeam = () => {
    handleClose();
    setDeleteDialogOpen(true);
  };

  const hasPermissionToDeleteTeam = usePermission('TeamController-delete-/teams');

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeleteTeam}
          disabled={!hasPermissionToDeleteTeam}>
          Delete team
        </MenuItem>
      </Menu>

      <TeamDeleteDialog
        open={deleteDialogOpen}
        name={name}
        id={id}
        handleClose={() => setDeleteDialogOpen(false)}
        onDelete={() => refetch()}
      />
    </>
  );
}
