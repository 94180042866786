import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(6)
  },
  svgBorder: {
    display: 'inline-block',
    padding: theme.spacing(1.5),
    border: '1px solid #C2C2C2',
    borderRadius: '8px'
  },
  rightContent: {
    display: 'flex',
    justifyContent: 'center',
    gap: 40
  },
  storeImg: {
    width: 140
  }
}));
