import { makeStyles, Box, Divider, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  dividerTop: {
    backgroundColor: 'black',
    opacity: '10%',
    marginBottom: theme.spacing(3)
  },
  paragraphText: {
    maxWidth: '44ch',
    paddingBottom: theme.spacing(3)
  },
  closeButton: {
    maxWidth: '12ch',
    marginRight: theme.spacing(2)
  }
}));

function DeleteMaintenanceModal({
  isDeleteModalOpen,
  handleCloseDeleteModal,
  handleDeleteMaintenance,
  maintenance
}) {
  const classes = useStyles();

  const handleClose = () => {
    handleCloseDeleteModal(false);
  };

  const onSubmit = data => {
    handleDeleteMaintenance({
      id: maintenance?.id,
      start: maintenance?.start,
      finish: maintenance?.finish
    });
  };

  const isFutureDay =
    dayjs(new Date(maintenance?.start)).startOf('day') > dayjs(new Date()).startOf('day')
      ? true
      : false;

  const isSameDay =
    dayjs(new Date(maintenance?.finish)).startOf('day') >
    dayjs(new Date(maintenance?.start)).startOf('day')
      ? false
      : true;

  const format = isFutureDay ? 'MM/DD HH:mm' : isSameDay ? 'HH:mm' : 'MM/DD HH:mm';

  return (
    <OldDeleteDialog
      title={'Deactivate monitoring?'}
      open={isDeleteModalOpen}
      onClose={handleClose}>
      <form name="deleteMaintenance" onSubmit={onSubmit}>
        <Typography className={classes.paragraphText}>
          There is a <strong>scheduled maintenance</strong> that starts at{' '}
          <strong>{dayjs(new Date(maintenance?.start)).format(format)}</strong> and ends at{' '}
          <strong>{dayjs(new Date(maintenance?.finish)).format(format)}</strong>. When
          deactivativing <strong> monitoring the maintenance will be deleted</strong>.
        </Typography>
        <Typography className={classes.paragraphText}>
          Are you sure you want to deactivate monitoring?
        </Typography>
        <Divider className={classes.dividerTop} />

        <Box display="flex" justifyContent="flex-end">
          <ButtonSimple
            className={classes.closeButton}
            variant="outlined"
            onClick={handleClose}
            text="Cancel"
            color="primary"
          />
          <ButtonSimple
            disableRipple
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            text="Deactivate monitoring"
          />
        </Box>
      </form>
    </OldDeleteDialog>
  );
}

export default DeleteMaintenanceModal;
