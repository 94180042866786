import { call, put, take, fork, cancel } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/applications';

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

function* getStatusPage(axios, action) {
  while (true) {
    try {
      const applicationSlug = action.params.applicationSlug;
      const {
        data: { data: values }
      } = yield axios.get(`/applications/${applicationSlug}/status`);
      yield put({
        type: actions.PRODUCT_APPLICATIONS_STATUS_PAGE_VIEW_FETCH_SUCCESSFUL,
        meta: {
          reducer
        },
        payload: {
          product_applications_status_page_view: { values }
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      yield put({
        type: actions.ENTITY_ERROR,
        payload: err,
        ga: { category: 'ERROR', action: action.type }
      });
    }
    yield put({ type: 'GET_STATUS_PAGE' });
    yield call(delay, 10000);
  }
}

export default function* timer(axios) {
  while (true) {
    const action = yield take(actions.PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_START);
    const bgSyncTask = yield fork(getStatusPage, axios, action);
    yield take(actions.PRODUCT_APPLICATIONS_STATUS_PAGE_TIMER_STOP);
    yield cancel(bgSyncTask);
  }
}
