import { MonitoringBaseForm } from '../../../forms/monitoring/commom/Base';
import { KafkaFormData } from '../../../forms/monitoring/queue/Kafka';
import { monitoringAdapter } from '../monitoring';

export function kafkaAdapter(data: MonitoringBaseForm & KafkaFormData) {
  const domain = data.domainType === 'url' ? data.domain : prepairDomain(data);

  return {
    ...monitoringAdapter({
      ...data,
      method: 'kafka'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType,
      lagTolerance: data.lagTolerance
    }
  };
}

const prepareHostAndPortsList = ({ brokers }: { brokers: { key: string; value: string }[] }) => {
  const hostsAndPortsList = brokers.map(broker => {
    return `${broker.key}:${broker.value}`;
  });

  return hostsAndPortsList.join(',');
};

const prepairDomain = (data: MonitoringBaseForm & KafkaFormData) => {
  const brokers = data.brokers;

  const brokersList = prepareHostAndPortsList({ brokers });

  const hasAuth = Boolean(data.username && data.password)
    ? data.username +
      ':' +
      data.password +
      ':' +
      data.mechanism +
      ':' +
      data.tls +
      ':' +
      data.sasl +
      '@'
    : '';

  const hasConsumerGroup = Boolean(data.consumer) ? `/${data.consumer}/${data.lag}` : '';

  const hasHostPortTopic = `[${brokersList}]/${data.topic}`;

  const domain = hasAuth + hasHostPortTopic + hasConsumerGroup;
  //ex: HOST:PORT/TOPIC
  //ex: HOST:PORT/TOPIC/CONSUMER-GROUP/LAG-TOLERENCE
  //ex: USERNAME:PASSWORD@HOST:PORT/TOPIC
  //ex: USERNAME:PASSWORD@HOST:PORT/TOPIC/CONSUMER-GROUP/LAG-TOLERENCE
  //ex: USERNAME:PASSWORD:MECHANISM:TLS:SASL@[BROKER1:PORT;BROKER2:PORT]/TOPIC
  return domain;
};
