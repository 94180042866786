import { z, ZodRawShape } from 'zod';

import { baseMonitoringSchema } from './base';
import { rulesSchema } from './rules';

export function makeMonitoringSchema<T extends ZodRawShape>(schema: T) {
  return z
    .object({
      ...baseMonitoringSchema,
      ...rulesSchema,
      ...schema
    })
    .superRefine((data, ctx) => {
      if (Number(data.interval) < Number(data.timeout)) {
        ctx.addIssue({
          path: ['timeout'],
          message: 'The timeout must not be greater than the interval',
          code: z.ZodIssueCode.custom
        });
      }
      if (String(data.interval).length > 6) {
        ctx.addIssue({
          path: ['interval'],
          message: 'Interval number can only be up to six digits',
          code: z.ZodIssueCode.custom
        });
      }
      if (String(data.timeout).length > 6) {
        ctx.addIssue({
          path: ['timeout'],
          message: 'Timeout number can only be up to six digits',
          code: z.ZodIssueCode.custom
        });
      }
      if (Number(data.environmentId) === 0) {
        ctx.addIssue({
          path: ['environmentId'],
          message: 'Checkpoint is required',
          code: z.ZodIssueCode.custom
        });
      }
    })
    .superRefine((data, ctx) => {
      const {
        intervalFailures,
        intervalHits,
        failuresToOpenIncident,
        hitsToCloseIncident,
        enable
      } = data.rules!.incident;

      const isIncidentRuleIntervalFailureValid =
        intervalFailures >= data.interval! * failuresToOpenIncident;

      if (enable && !isIncidentRuleIntervalFailureValid) {
        ctx.addIssue({
          path: ['rules.incident.intervalFailures'],
          message:
            'Interval failures should be less than interval check x failures to open incident',
          code: z.ZodIssueCode.custom
        });

        ctx.addIssue({
          path: ['rules.incident.failuresToOpenIncident'],
          message:
            'Interval failures should be less than interval check x failures to open incident',
          code: z.ZodIssueCode.custom
        });
      }

      const isIncidentRuleIntervalHitsValid = intervalHits >= data.interval! * hitsToCloseIncident;

      if (enable && !isIncidentRuleIntervalHitsValid) {
        ctx.addIssue({
          path: ['rules.incident.intervalHits'],
          message: 'Interval hits should be less than interval check x hits to close incident',
          code: z.ZodIssueCode.custom
        });

        ctx.addIssue({
          path: ['rules.incident.hitsToCloseIncident'],
          message: 'Interval hits should be less than interval check x hits to close incident',
          code: z.ZodIssueCode.custom
        });
      }
    });
}
