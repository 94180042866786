import React from 'react';

import { Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import useNewAddons from 'hooks/queriesGraphQL/useNewAddons';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';
import reducer from 'redux/reducer/addons/change';

import ServicesNewForm from '../ServicesNewForm';

import SearchInput from './SearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  row: {
    paddingBottom: 2,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  or: {
    margin: theme.spacing(0, 2)
  },
  spacer: {
    flexGrow: 1
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10
  },
  paper: {
    flex: '1 0 auto',
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  dialogTitleTextColor: {
    color: 'rgb(33, 33, 33)'
  },
  dialogTextColor: {
    color: '#414141'
  },
  actionBox: {
    flex: '1 0 auto',
    justifyContent: 'space-between'
  },
  actionButton: {
    width: theme.typography.pxToRem(150),
    height: theme.typography.pxToRem(46)
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 24,
    color: '#595959'
  },
  dialogTitleBox: {
    marginTop: theme.spacing(2)
  }
}));

const ProductToolbar = ({
  className,
  title,
  productId,
  isHttpFlow,
  application,
  applicationId,
  canAddAddon,
  onAddAddon
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { reexecuteQuery: reexecuteQueryNewAddons } = useNewAddons({ applicationId });

  const { application_services_view: view } = useSelector(({ application_services_view }) => ({
    application_services_view
  }));

  const [isOpen, setDialogOpen] = React.useState(false);

  const loadView = React.useCallback(
    payload =>
      dispatch({
        type: actions.PRODUCT_APPLICATIONS_VIEW_FETCH,
        params: payload
      }),
    [dispatch]
  );

  React.useEffect(() => {
    loadView({ productId, applicationId });
  }, [loadView, productId, applicationId]);

  const handleOpenDialog = React.useCallback(() => {
    setDialogOpen(!isOpen);
  }, [setDialogOpen, isOpen]);

  const [addAddonMutation] = useMutation(
    data => axios.post(`/products/${productId}/addonsV4`, data).then(response => response.data),
    {
      onSuccess: ({ data: { id: addonId, applicationDependencyId: appDependencyId } }) => {
        reexecuteQueryNewAddons();
        dispatch({
          type: actions.APPLICATIONS_ADDONS_FETCH,
          params: { productId },
          payload: applicationId
        });

        dispatch({
          type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
          params: { productId, applicationId }
        });

        if (!appDependencyId) {
          handleOpenDialog();
        }
      }
    }
  );

  const autoCompleteHandleChange = payload => {
    dispatch({
      type: actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_CHANGE,
      params: { productId, applicationId },
      payload,
      meta: {
        reducer
      }
    });
  };

  const addHandler = payload => {
    addAddonMutation({
      applicationId,
      serviceId: payload._source.type === 'service' ? payload._source.id : null,
      applicationDependencyId: payload._source.type === 'application' ? payload._source.id : null
    });
    reexecuteQueryNewAddons();
    dispatch({
      type: actions.APPLICATIONS_ADDONS_RESET,
      payload,
      meta: {
        reducer
      }
    });
  };

  const addHandlerUniqueResource = (payload, sameEnvironment) => {
    addAddonMutation({
      applicationId,
      serviceId: payload._source.type === 'service' ? payload._source.id : null,
      applicationDependencyId: payload._source.type === 'application' ? payload._source.id : null,
      sameEnvironment: sameEnvironment
    });
    reexecuteQueryNewAddons();
    dispatch({
      type: actions.APPLICATIONS_ADDONS_RESET,
      payload,
      meta: {
        reducer
      }
    });
  };

  const handleOpen = payload =>
    dispatch({
      type: actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_OPEN,
      payload,
      meta: {
        reducer
      }
    });

  const shouldUseAddonOnMultiFlow = useFeatureFlagUnleash('addonOnMultiFlow', {
    queryString: true
  });

  const shouldDisableEdit = !shouldUseAddonOnMultiFlow && isHttpFlow;

  const theme = useTheme();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Typography
          variant="h4"
          style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
          {title}
        </Typography>
      </div>
      <div className={classes.row}>
        <SearchInput
          disabled={!canAddAddon || shouldDisableEdit}
          addHandler={addHandler}
          autoCompleteHandleChange={autoCompleteHandleChange}
          handleOpen={handleOpen}
          placeholder="Add Service to this application"
          view={view}
          currentEnvironment={application?.environmentId}
          addHandlerUniqueResource={addHandlerUniqueResource}
        />

        <ServicesNewForm
          productId={productId}
          applicationId={applicationId}
          disabled={!canAddAddon || shouldDisableEdit}
          application={application}
          onAddAddon={onAddAddon}
        />
      </div>
    </div>
  );
};

ProductToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductToolbar;
