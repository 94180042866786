import {
  Box,
  Backdrop,
  CircularProgress,
  List,
  ListItemText,
  ListItem,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ButtonSimple } from 'componentsV4/Button';
import axios from 'redux/axios';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  },
  listItem: {
    padding: 0,
    margim: 0
  },
  listItemText: {
    padding: 0,
    margim: 0
  },
  link: { paddingLeft: theme.spacing(2) },
  list: {
    paddingLeft: theme.spacing(2),
    margin: 0
  }
}));

const UninstallDialog = ({ handleClose, open, environment, onUninstallAgent }) => {
  const classes = useStyles();
  const history = useHistory();

  const { data: monitoringApplications, isLoading } = useQuery(
    ['environments', environment.id, 'applications'],
    () => {
      return axios
        .get(`/environments/${environment.id}/monitoredApplications`)
        .then(response => response.data);
    },
    {
      enabled: open,
      refetchOnWindowFocus: false
    }
  );

  const [uninstallAgent, { isLoading: isLoadingDelete }] = useMutation(
    async id => axios.delete(`/environments/${id}/agent/uninstall`),
    {
      onSuccess: () => {
        handleClose();
        onUninstallAgent();
        return;
      }
    }
  );

  return (
    <OldDeleteDialog
      open={open}
      onClose={handleClose}
      title="Uninstall agent"
      minWidth="md"
      fullWidth>
      {monitoringApplications ? (
        <Box paddingBottom={2}>
          <Typography>
            {`Can't remove this agent because there are ${monitoringApplications.length} application(s) being monitored in this
              environment:`}
          </Typography>
          <Box overflow={'auto'} maxHeight={'300px'}>
            <List className={classes.list} fullWidth>
              {monitoringApplications?.map(app => (
                <Box>
                  <ListItem
                    className={classes.listItem}
                    key={app.id}
                    button
                    onClick={event =>
                      history.push(`/products/${app.productId}/availability/applications/${app.id}`)
                    }>
                    <ListItemText className={classes.listItemText} primary={app.name} />
                  </ListItem>
                </Box>
              ))}
            </List>
          </Box>
          <Typography>
            Please stop monitoring these applications before uninstalling the agent
          </Typography>
        </Box>
      ) : (
        <Box paddingBottom={3}>
          <Typography color="textPrimary">Do you really want to uninstall this agent?</Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <ButtonSimple onClick={handleClose} variant="outlined" text="Cancel" color="primary" />
        <Box ml={2}>
          <ButtonSimple
            color="primary"
            variant="contained"
            onClick={() => uninstallAgent(environment.id)}
            disabled={monitoringApplications}
            text="Confirm"
          />
        </Box>
      </Box>
      <Backdrop open={isLoading || isLoadingDelete} className={classes.backdrop}>
        <CircularProgress color="primary" size={90} thickness={5} />
      </Backdrop>
    </OldDeleteDialog>
  );
};

export default UninstallDialog;
