import { Box, Typography, useTheme } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

export function NotVerifiedWarning() {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      gridGap={theme.spacing()}
      marginRight="auto"
      marginLeft="1rem">
      <Warning htmlColor={theme.palette.orange[400]} />
      <Typography>Not verified</Typography>
    </Box>
  );
}
