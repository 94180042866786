export const getTeamsByResourceId = `#graphql
    query($resourceId: Float!) {
      getTeamsByResourceId(resourceId: $resourceId, resourceType: "Application") {
        name
        resourceTeams {
          teamId
        }
      }
    }
  `;
