export type ServiceHub = {
  breadcrumb: {
    label: string;
    link?: string;
  }[];
  title: string;
  description: string;
  services: Service[];
};

export type Service = {
  title: string;
  hidden: boolean;
  items: ServiceItem[];
};

export type ServiceItem = {
  title: string;
  description: string;
  link: string;
  image: string;
  hidden: boolean;
  plans: {
    [planName: string]: boolean;
  };
  featureFlag: string;
};

export const DefaultValuesServiceItem = (): ServiceItem => {
  return {
    title: '',
    description: '',
    link: '',
    image: '',
    plans: {
      '': true
    },
    hidden: false,
    featureFlag: ''
  } as ServiceItem;
};

export const DefaultValues = (): ServiceHub => {
  return {
    breadcrumb: [{ label: '' }],
    title: '',
    description: '',
    services: [
      {
        title: '',
        hidden: false,
        items: [
          {
            title: '',
            description: '',
            link: '',
            image: '',
            plans: {
              '': true
            },
            hidden: false,
            featureFlag: ''
          }
        ]
      }
    ]
  } as ServiceHub;
};
