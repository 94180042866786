import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

const GetIncidentTotalResponseEffort = `#graphql
  query ($startDate: String!, $endDate: String!) {
    insights {
      incidents {
        totalResponseEffort(startDate: $startDate, endDate: $endDate){
            period
            compared
          }
      }
    }
  }
`;

export const IncidentTotalResponseEffortMetrics = () => {
  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetIncidentTotalResponseEffort,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const incidentTotalResponseEffort = data?.insights?.incidents?.totalResponseEffort;

  return (
    <Metrics
      name="Total Response Effort"
      value={incidentTotalResponseEffort?.period}
      valueFormat={MetricsValueFormatEnum.Time}
      isFetching={fetching}
      tooltipTitle="Total sum of time involved in an incident, measured from acknowledgment to resolution."
      diff={incidentTotalResponseEffort?.compared}
      diffFormat={MetricsValueFormatEnum.Percentile}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
