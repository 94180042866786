type Params = {
  platform?: string;
};

const leadTimeIntegrationDescriptionMessage = (
  platform: Params['platform']
) => `By integrating with ${platform}, you can get the Lead Time for Change metric, which is
an indicator that measures how quickly code makes it to production.`;

const messages = {
  Slack: () =>
    'Use Slack to communicate during incidents. By connecting Slack workspaces, you can create Slack channels for your incidents and take icident actions, and help your team step in faster.',
  ServiceNow: () =>
    'Connect to ServiceNow to open tickets efficiently and effectively. Try the integration and streamline your operation.',
  Bitbucket: (params: Params) => leadTimeIntegrationDescriptionMessage(params.platform),
  Github: (params: Params) => leadTimeIntegrationDescriptionMessage(params.platform),
  Gitlab: (params: Params) => leadTimeIntegrationDescriptionMessage(params.platform),
  Jira: (params: Params) =>
    'Connect to Jira to open tickets efficiently and effectively. Try the integration and streamline your operation.'
};

export default messages;
