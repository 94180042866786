export const ElvenAgentCloud = [
  {
    value: 'aws',
    label: 'AWS'
  }
];
export const ElvenAgentRegion = [
  {
    value: 'us-east-1',
    label: 'Pool - N. Virginia'
  }
];
