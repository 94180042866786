import { useQuery } from 'urql';

type ImpactedResource = {
  application: {
    name: string;

    product: {
      name: string;
    };
  };
};

const GetImpactedResourcesQuery = `#graphql
  query ($incidentId: Int!) {
    resourcesImpacted(incidentId: $incidentId) {
      data {
        application {
          name
          product {
            name
          }
        }
      }
    }
  }
`;

export function useImpactedResources(incidentId: number) {
  const [{ data, fetching }] = useQuery<{ resourcesImpacted: { data: ImpactedResource[] } }>({
    query: GetImpactedResourcesQuery,
    variables: { incidentId }
  });

  return { data: data?.resourcesImpacted?.data ?? [], isLoading: fetching };
}
