import actions from '../../actions';

const environments = (state, action) => {
  if (actions.ENVIRONMENTS_FETCH_SUCCESSFUL === action.type) {
    const environments_new = state.environments_new;
    const environments_edit = state.environments_edit;
    return {
      ...state,
      environments_new: {
        ...environments_new,
        clouds: action.payload
      },
      environments_edit: {
        ...environments_edit,
        clouds: action.payload
      }
    };
  }
  return state;
};

export default environments;
