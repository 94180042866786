import regionsAws from 'constants/regionsAws';

import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type DynamodbFormData = {
  accessKey: string;
  secretAccessKey: string;
  awsRegion: string;
  tableName: string;
  primarykey: string;
  valueItem: string;
};

export function DynamodbForm({ form }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'Access Key',
            name: 'accessKey'
          }}
          InputComponent={Input}
        />
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'password',
            label: 'Secret Access Key',
            name: 'secretAccessKey'
          }}
          InputComponent={Input}
        />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Select options={regionsAws} control={control} name="awsRegion" label="AWS Region" />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Input label="Table name" name="tableName" inputRef={register} />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Input label="Primary Key" name="primarykey" inputRef={register} required={false} />
        <Input label="Value Item" name="valueItem" inputRef={register} required={false} />
      </Box>
    </Box>
  );
}
