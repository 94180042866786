import { composeStep } from './composeStep';
import { StepGroupsType } from './StepGroupsType';
import { StepType } from './StepType';
import { TimeUnitsType } from './TimeUnitsType';

export const composePayloadUpdate = ({
  data,
  serverData,
  timeUnits
}: {
  data: StepGroupsType;
  serverData: StepGroupsType;
  timeUnits: TimeUnitsType;
}) => {
  return {
    id: serverData.id,
    teamIdFromTeams: serverData.teamIdFromTeams,
    name: data.stepsGroupName,
    steps: data.steps.map((step: StepType, index: number) => ({
      ...composeStep({ step, index, timeUnits }),
      id: step.id ? parseInt(step.id) : undefined
    }))
  };
};
