import dayjs from 'dayjs';

import { Recurrence, EndOfRecurrence } from '../../../../../constants/callRotationRecurrence';

export class Generator {
  public create = (payload: any): string[] => {
    let startDate = dayjs(payload.startDate);
    let endDate = dayjs(payload.endDate);

    const diffDates = endDate.diff(payload.startDate, 'day');
    const countDayHasPassed = this.getCountGetDay(startDate.toDate());

    let endOfRecurrence;
    switch (payload.endOfRecurrence) {
      case EndOfRecurrence.AfterAMonth:
        endOfRecurrence = startDate.add(1, 'month');
        break;
      case EndOfRecurrence.AfterTwoMonths:
        endOfRecurrence = startDate.add(2, 'month');
        break;
      case EndOfRecurrence.AfterThreeMonths:
        endOfRecurrence = startDate.add(3, 'month');
        break;
      case EndOfRecurrence.AfterSixMonths:
        endOfRecurrence = startDate.add(6, 'month');
        break;
      case EndOfRecurrence.AfterAYear:
        endOfRecurrence = startDate.add(1, 'year');
        break;
      case EndOfRecurrence.Never:
        endOfRecurrence = startDate.add(5, 'year');
        break;
      default:
        endOfRecurrence = endDate;
    }

    let currentDate;
    let dates = [];
    while (startDate <= endOfRecurrence) {
      currentDate = dayjs(startDate);
      for (let index = 0; index < diffDates; index++) {
        if (currentDate.format('YYYYMMDD') === endOfRecurrence.add(1, 'day').format('YYYYMMDD')) {
          break;
        }

        dates.push(currentDate.format('YYYY/MM/DD'));
        currentDate = currentDate.add(1, 'day');
      }

      switch (payload.recurrence) {
        case Recurrence.Weekly:
          [startDate, endDate] = this.getWeekly(startDate, endDate, diffDates, 1);
          break;
        case Recurrence.Biweekly:
          [startDate, endDate] = this.getWeekly(startDate, endDate, diffDates, 2);
          break;
        case Recurrence.Triweekly:
          [startDate, endDate] = this.getWeekly(startDate, endDate, diffDates, 3);
          break;
        case Recurrence.Quadweekly:
          [startDate, endDate] = this.getMonthly(startDate, diffDates, countDayHasPassed);
          break;
        case Recurrence.DoesNotRepeat:
          startDate = endOfRecurrence.add(1, 'day');
          break;
        default:
          // eslint-disable-next-line no-throw-literal
          throw { code: '400', message: 'Recurrence is not exists' };
      }
    }

    return dates;
  };

  private getWeekly = (
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    diffDates: number,
    recurrence: number
  ): readonly [dayjs.Dayjs, dayjs.Dayjs] => {
    let next = 1;

    while (true) {
      if (endDate.day() === startDate.day()) {
        startDate = endDate;
        endDate = startDate.add(diffDates, 'day');

        if (next < recurrence) {
          next++;
        } else {
          break;
        }
      }

      endDate = endDate.add(1, 'day');
    }

    return [startDate, endDate] as const;
  };

  private getMonthly(
    startDate: dayjs.Dayjs,
    diffDates: number,
    countDayHasPassed: number
  ): readonly [dayjs.Dayjs, dayjs.Dayjs] {
    const dayOfWeek = startDate.toDate().getDay();

    let currentDay = new Date(startDate.toDate().getFullYear(), startDate.toDate().getMonth(), 1);
    currentDay.setMonth(currentDay.getMonth() + 1);
    currentDay.setDate(1);
    while (true) {
      if (currentDay.getDay() === dayOfWeek) {
        currentDay = dayjs(currentDay)
          .add(countDayHasPassed - 1, 'week')
          .toDate();

        if (countDayHasPassed > this.getCountGetDay(currentDay)) {
          currentDay = dayjs(currentDay)
            .add(-1, 'week')
            .toDate();
        }

        return [dayjs(currentDay), dayjs(currentDay).add(diffDates, 'day')] as const;
      }

      currentDay = dayjs(currentDay)
        .add(1, 'day')
        .toDate();
    }
  }

  private getCountGetDay = (date: Date): number => {
    const dayOfWeek = date.getDay();
    let currentDay = dayjs(new Date(date.getFullYear(), date.getMonth(), 1));
    let i = 1;

    while (true) {
      if (dayjs(date).format('YYYYMMDD') === currentDay.format('YYYYMMDD')) {
        return i;
      }

      if (currentDay.toDate().getDay() === dayOfWeek) {
        ++i;
      }

      currentDay = dayjs(currentDay).add(1, 'day');
    }
  };
}
