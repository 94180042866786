import React from 'react';

import Form from '../components/Form';

class EnvironmentsNewForm extends React.Component {
  render() {
    const { formState, onChange, clouds } = this.props;

    return (
      <Form clouds={clouds} formState={formState} onChange={onChange} title="New Environment" />
    );
  }
}

export default EnvironmentsNewForm;
