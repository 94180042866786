import actions from '../../actions';

const change = (state, action) => {
  if (actions.TEAMS_AUTOCOMPLETE_CHANGE === action.type) {
    const teamsViewState = state.teams_view;
    return {
      ...state,
      teams_view: {
        ...teamsViewState,
        autoComplete: {
          ...teamsViewState.autoComplete,
          loading: true,
          searchText: action.payload
        }
      }
    };
  }

  if (actions.TEAMS_AUTOCOMPLETE_OPEN === action.type) {
    const teamsViewState = state.teams_view;

    return {
      ...state,
      teams_view: {
        ...teamsViewState,
        autoComplete: {
          searchText: '',
          open: action.payload,
          options: teamsViewState.autoComplete.options,
          loading: action.payload && teamsViewState.autoComplete.options.length === 0
        }
      }
    };
  }

  if (actions.TEAMS_CHANGE_TAB === action.type) {
    const teamsViewState = state.teams_view;

    return {
      ...state,
      teams_view: {
        ...teamsViewState,
        tab: action.payload
      }
    };
  }

  return state;
};

export default change;
