import uuid from 'uuid';
import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 500
    }
  },
  domain: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 1000
    }
  },
  check_type: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  },
  method: {
    presence: { allowEmpty: false, message: 'é obrigatório' }
  }
};

const services = (state, action) => {
  if (actions.SERVICE_NEW_SETTINGS_ADD === action.type) {
    const services_new = state.services_new;
    const envs = services_new.values.serviceSettings.envs;
    envs.push({
      uid: uuid(),
      key: '',
      value: ''
    });
    services_new.values.serviceSettings.envs = envs;
    const errors = validate(services_new.values, schema);
    return {
      ...state,
      services_new: {
        ...services_new,
        isValid: !errors
      }
    };
  }

  if (actions.SERVICE_NEW_SETTINGS_REMOVE === action.type) {
    const services_new = state.services_new;
    const envs = services_new.values.serviceSettings.envs;

    if (services_new.values.serviceSettings.envs.length > 1) {
      services_new.values.serviceSettings.envs.pop();
    }

    services_new.values.serviceSettings.envs = envs;

    const errors = validate(services_new.values, schema);

    return {
      ...state,
      services_new: {
        ...services_new,
        isValid: !errors
      }
    };
  }

  if (actions.SERVICE_NEW_POPULATE_SETTINGS_ENTITY === action.type) {
    const services_new = state.services_new;
    const { uid } = action.meta;

    const envs = services_new.values.serviceSettings.envs.map(item => {
      const selected = { values: {} };
      if (item.uid === uid) {
        selected.values = action.payload;
      }
      const result = {
        ...item,
        ...selected.values
      };
      return result;
    });

    services_new.values.serviceSettings.envs = envs;

    const values = {
      ...services_new.values,
      ...action.payload
    };

    const errors = validate(values, schema);

    return {
      ...state,
      services_new: {
        ...services_new,
        values,
        errors,
        isValid: !errors
      }
    };
  }

  if (actions.SERVICE_EDIT_SETTINGS_ADD === action.type) {
    const services_edit = state.services_edit;
    const envs = services_edit.values.serviceSettings.envs;
    envs.push({
      uid: uuid(),
      key: '',
      value: ''
    });
    services_edit.values.serviceSettings.envs = envs;
    return {
      ...state,
      services_edit: {
        ...services_edit,
        isValid: false
      }
    };
  }

  if (actions.SERVICE_EDIT_SETTINGS_REMOVE === action.type) {
    const services_edit = state.services_edit;

    if (services_edit.values.serviceSettings.envs.length > 1) {
      const envs = services_edit.values.serviceSettings.envs.filter(
        item => item.uid !== action.payload.uid
      );
      services_edit.values.serviceSettings.envs = envs;
    }

    const errors = validate(services_edit.values, schema);

    return {
      ...state,
      services_edit: {
        ...services_edit,
        isValid: !errors
      }
    };
  }

  if (actions.SERVICE_EDIT_POPULATE_SETTINGS_ENTITY === action.type) {
    const services_edit = state.services_edit;
    const { uid } = action.meta;

    const envs = services_edit.values.serviceSettings.envs.map(item => {
      const selected = { values: {} };
      if (item.uid === uid) {
        selected.values = action.payload;
      }
      const result = {
        ...item,
        ...selected.values
      };
      return result;
    });

    services_edit.values.serviceSettings.envs = envs;

    const values = {
      ...services_edit.values,
      ...action.payload
    };

    const errors = validate(values, schema);

    return {
      ...state,
      services_edit: {
        ...services_edit,
        values,
        errors,
        isValid: !errors
      }
    };
  }

  return state;
};

export default services;
