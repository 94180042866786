import React from 'react';

import { Tooltip } from '@material-ui/core';

export const MTBFTitle = () => (
  <Tooltip title="Mean Time Between Failures">
    <span>MTBF</span>
  </Tooltip>
);

export const MTTATitle = () => (
  <Tooltip title="Mean Time To Acknowledge">
    <span>MTTA</span>
  </Tooltip>
);

export const MTTRecoveryTitle = () => (
  <Tooltip title="Mean Time To Recovery">
    <span>MTTRecovery</span>
  </Tooltip>
);

export const UptimeTitle = () => (
  <Tooltip title="Service Level Agreement (last 1 hour)">
    <span>Uptime</span>
  </Tooltip>
);

export const ChangeFailureTitle = () => (
  <Tooltip title="Service Change Failure Rate (last 30 days)">
    <span>Change Failure Rate</span>
  </Tooltip>
);

export const DeployFrequencyTitle = () => (
  <Tooltip title="Service Deploy Frequency (last 30 days)">
    <span>Deploy Frequency</span>
  </Tooltip>
);
