import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  eventDate: {
    color: theme.palette.gray[500],
    fontSize: '16px',
    marginRight: theme.spacing(1)
  }
}));
