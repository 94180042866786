import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { MenuItem, TextField } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { Origin } from 'componentsV4/Filters/Search';

import { useStyles } from '../../styles';

export const SeverityFilter = ({
  severity,
  setSeverity,
  handleApplyFilters
}: {
  severity: 'all' | SeverityStatusEnum;
  setSeverity: Dispatch<SetStateAction<'all' | SeverityStatusEnum>>;
  handleApplyFilters: ({
    newStatus,
    newSeverity,
    newOrigins
  }: {
    newStatus?: ResourceStatusEnum | MultiFlowStatusEnum | StatusEnum | 'all';
    newSeverity?: SeverityStatusEnum | 'all';
    newOrigins?: Origin[] | undefined;
  }) => void;
}) => {
  const classes = useStyles();

  const handleChangeSeverity = (event: ChangeEvent<HTMLInputElement>) => {
    const severityValue = event.target.value as SeverityStatusEnum;

    setSeverity(severityValue);
    handleApplyFilters({ newSeverity: severityValue });
  };

  return (
    <TextField
      name="severityFilter"
      label="Severity Level"
      variant="outlined"
      onChange={handleChangeSeverity}
      value={severity}
      InputProps={{
        className: classes.input
      }}
      InputLabelProps={{
        shrink: true,
        color: 'secondary'
      }}
      select
      fullWidth>
      <MenuItem value={'all'}>All</MenuItem>
      <MenuItem value={SeverityStatusEnum.NotClassified}>Not Classified</MenuItem>
      <MenuItem value={SeverityStatusEnum.Critical}>SEV 1 - Critical</MenuItem>
      <MenuItem value={SeverityStatusEnum.High}>SEV 2 - High</MenuItem>
      <MenuItem value={SeverityStatusEnum.Moderate}>SEV 3 - Moderate</MenuItem>
      <MenuItem value={SeverityStatusEnum.Low}>SEV 4 - Low</MenuItem>
      <MenuItem value={SeverityStatusEnum.Informational}>SEV 5 - Informational</MenuItem>
    </TextField>
  );
};
