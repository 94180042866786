import React from 'react';

import { Grid, Input, InputLabel, Select, MenuItem, Switch } from '@material-ui/core';
import Form from 'oldComponents/Crud/Form/Form';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation as graphqlMutation } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import actions from 'redux/actions';
import reducer from 'redux/reducer/crud';

const schema = {
  name: {
    length: {
      maximum: 500
    }
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const UpdateAccountMutation = `#graphql
    mutation($updateAccountInputs: UpdateAccountInputs!) {
      updateAccount (updateAccountInputs: $updateAccountInputs){
        id
      }
    }
  `;

const UsersEditForm = ({
  users_edit: formState,
  users_new,
  onChange,
  fetchEntity,
  match: {
    params: { id }
  }
}) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const [{ error }, graphqlUpdateAccountMutation] = graphqlMutation(UpdateAccountMutation);

  React.useEffect(() => {
    fetchEntity(id);
  }, [fetchEntity, id]);

  const handleRolesChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange(name, value, formState.uid);
  };

  const handleActiveToggleChange = event => {
    event.preventDefault();
    onChange('isActive', event.target.checked, event.target.isActive);
  };

  const handleSave = event => {
    event.preventDefault();
    graphqlUpdateAccountMutation({
      updateAccountInputs: {
        userId: formState.values.userId,
        isActive: formState.values.isActive,
        roleId: formState.values.roleId
      }
    })
      .then(() => {
        router.push('/users');
      })
      .catch(err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: 'The request failed. Please try again. ' + error,
          ga: { category: 'ERROR' }
        });
      });
  };

  const displayName =
    formState.values && formState.values.user ? formState.values.user.displayName : '';

  const email = formState.values && formState.values.user ? formState.values.user.email : '';

  const title = `Editing ${displayName} - ${email}`;

  return (
    <>
      <Breadcrumb
        items={[{ label: 'People' }, { label: 'User Center', link: '/users' }, { label: 'Edit' }]}
      />
      <Form onSave={handleSave} formState={formState} title={title}>
        <Grid container spacing={3}>
          <Grid item md={1} xs={12}>
            <InputLabel id={`${formState.uid}-select-multiple-chip-label`} shrink>
              Role
            </InputLabel>
            <Select
              input={<Input id={`${formState.uid}-select-multiple-chip`} />}
              MenuProps={MenuProps}
              name="roleId"
              onChange={handleRolesChange}
              style={{ minWidth: '100%' }}
              value={formState.values && formState.values.roleId ? formState.values.roleId : ''}
              variant="outlined">
              {users_new.roles.map(role => (
                <MenuItem key={role.name} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={1} xs={12}>
            <InputLabel shrink>Active</InputLabel>
            <Switch
              checked={!!formState.values.isActive}
              inputProps={{ 'aria-label': 'isActive checkbox' }}
              name="isActive"
              onChange={handleActiveToggleChange}
              value={formState.values.isActive}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

const mapStateToProps = ({ users_edit, users_new }) => ({
  users_edit,
  users_new
});

const mapDispatchToProps = dispatch => {
  return {
    fetchEntity: id => {
      dispatch({
        type: actions.USERS_FETCH,
        payload: { id }
      });
    },
    onChange: (name, value) => {
      dispatch({
        type: actions.POPULATE_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'users_edit',
          touched: {
            [name]: true
          },
          reducer,
          schema
        }
      });
    }
  };
};

const ConnectedUsersEditForm = connect(mapStateToProps, mapDispatchToProps)(UsersEditForm);
export default ConnectedUsersEditForm;
