import { useMemo } from 'react';

import { useParams } from 'react-router';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { MultiFlow } from 'types/external/MultiFlow';
import { useQuery } from 'urql';

import { useResponders } from 'hooks/queriesGraphQL/useResponders';
import { useTeams } from 'hooks/queriesGraphQL/useTeams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { GetRulesByEntityUidQuery } from 'hooks/useRule';
import { multiFlowStartValuesAdapter } from 'views/ServicesHub/adapters/edit/multiflow';
import { incidentsRulesAdapter } from 'views/ServicesHub/adapters/edit/rules/incident';

const GetMultiFlowStartValues = `#graphql
  query GetMultiFlowStartValues(
    $multiFlowUid: String!
  ) {
    multiflow(
      multiFlowUid: $multiFlowUid
    ) {
      id
      name
      uid
      environmentId
      interval
      steps {
        id
        name
        domain
        method
        timeout
        status
        domainSettings
        assertions {
          id
          source
          order
          comparison
          targetValue
          property
        }
      }
    }
  }
`;

export function useStartValues() {
  const { uid } = useParams<{ uid: string }>();

  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const [{ data: multiFlowData, fetching: fetchingResource }] = useQuery<{ multiflow: MultiFlow }>({
    variables: { multiFlowUid: uid },
    query: GetMultiFlowStartValues,
    pause: !uid
  });

  const [{ data: rulesData, fetching: fetchingRule }] = useQuery<{ getRulesByEntityUid: Rule[] }>({
    query: GetRulesByEntityUidQuery,
    variables: { entityUid: uid, entity: RuleOriginEntityType.Multiflow },
    pause: !uid
  });

  const [{ data: responders, fetching: fetchingResponders }] = useResponders();

  const [{ data, fetching: fetchingGetTeams }] = useTeams();

  const fetching = fetchingResource || fetchingGetTeams || fetchingRule || fetchingResponders;

  const multiflow = multiFlowData?.multiflow;

  return useMemo(() => {
    const teamsFieldOptions =
      data?.getTeamsByOrgUid?.data?.map(team => ({
        value: String(team.id),
        label: team.name
      })) ?? [];

    const respondersFieldOptions =
      responders?.getRespondersByOrgUid?.data?.map(responder => ({
        value: String(responder.uid),
        label: responder.name
      })) ?? [];

    if (!multiflow) return { startValues: undefined, fetching: false };

    const incidentRule = rulesData?.getRulesByEntityUid?.find(item =>
      item.actions.some(action => action.type !== 'timeout')
    );
    const multiFlowStartValues = multiFlowStartValuesAdapter(multiflow);

    const incidentRuleStartValues = incidentsRulesAdapter(
      incidentRule,
      shouldUseRespondersInput ? respondersFieldOptions : teamsFieldOptions
    );

    const startValues = {
      ...multiFlowStartValues,
      rules: {
        incident: incidentRuleStartValues,
        incidentRule
      }
    };

    return { startValues, fetching };
  }, [
    fetching,
    rulesData?.getRulesByEntityUid,
    multiflow,
    data?.getTeamsByOrgUid?.data,
    responders?.getRespondersByOrgUid?.data,
    shouldUseRespondersInput
  ]);
}
