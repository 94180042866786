import { useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import { usePermission } from 'hooks/usePermission';
import { useStyles } from 'views/Postmortems/styles';

type MenuProps = {
  handleClickDeleteOption: () => void;
  handleClickEditOption: () => void;
};

export function MenuActions({ handleClickDeleteOption, handleClickEditOption }: MenuProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteUser = () => {
    handleClose();
    handleClickDeleteOption();
  };
  const handleEditUser = () => {
    handleClose();
    handleClickEditOption();
  };

  const permissions = {
    new: usePermission('AccountController-post-/accounts'),
    update: usePermission('AccountController-put-/accounts'),
    remove: usePermission('AccountController-delete-/accounts/:id')
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem
          className={classes.menuItem}
          onClick={handleEditUser}
          disabled={!permissions.update}>
          Edit
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleDeleteUser}
          disabled={!permissions.remove}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}
