export enum EventTypeEnum {
  Alarmed = 'alarmed',
  Acknowledged = 'acknowledged',
  Resolved = 'resolved',
  PostMortem = 'post_mortem',
  Deploy = 'deploy',
  HotFix = 'hotfix',
  Rollback = 'rollback',
  IncidentStatusUpdate = 'incident_status_update'
}
