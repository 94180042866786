const columns = [
  {
    name: 'ID',
    selector: 'id',
    targets: ['aws', 'azure', 'custom']
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    targets: ['aws', 'azure', 'custom']
  },
  {
    name: 'Cloud',
    selector: row => row.cloud.name,
    targets: ['aws', 'azure', 'custom']
  },
  {
    name: 'VPC',
    selector: data => data.providerData?.vpcLabel,
    targets: ['aws']
  },
  {
    name: 'Region',
    selector: data => data.providerData?.location || data.region,
    targets: ['aws']
  },
  {
    name: 'Location',
    selector: data => data.providerData?.location || data.region,
    targets: ['azure']
  },
  {
    name: 'Vnet',
    selector: data => data.providerData?.vnetName,
    targets: ['azure']
  },
  {
    name: 'Subnet',
    selector: data => data.providerData?.subnetName || data.providerData?.subnetId,
    targets: ['aws', 'azure']
  },
  {
    name: 'Instance Type',
    selector: data => data.instanceType,
    targets: ['aws']
  },
  {
    name: 'Resource group',
    selector: data => data.providerData?.resourceGroup,
    targets: ['azure']
  }
];

export default columns;
