import { createTheme, ThemeProvider } from '@material-ui/core';

import palette from '../palette';

import overrides from './overrides';

const theme = createTheme({
  palette,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
    modal: 800
  },
  shape: {
    borderRadius: 8
  }
});

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    fontSize: theme.typography.pxToRem(64),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1em'
  },
  h2: {
    ...theme.typography.h2,
    fontSize: theme.typography.pxToRem(40),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1em'
  },
  h3: {
    ...theme.typography.h3,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1em'
  },
  h4: {
    ...theme.typography.h4,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.2em'
  },
  h5: {
    ...theme.typography.h5,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.4em'
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.4em'
  },
  body1: {
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.2em'
  },
  body2: {
    ...theme.typography.body2,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.2em'
  },
  button: {
    ...theme.typography.button,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1em',
    textTransform: 'none'
  },
  caption: {
    ...theme.typography.caption,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.4em'
  },
  overline: {
    ...theme.typography.overline,
    fontSize: theme.typography.pxToRem(64),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1em'
  }
};

export type Theme = typeof theme;

interface WithThemeV4HocProps {}

export const withThemeV4 = <P extends WithThemeV4HocProps = WithThemeV4HocProps>(
  Component: React.ComponentType<P>
) => (props: Omit<P, keyof WithThemeV4HocProps>) => (
  <ThemeProvider theme={theme}>
    <Component {...(props as P)} />
  </ThemeProvider>
);

export default theme;
