import actions from '../../actions';

const change = (state, action) => {
  if (actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_CHANGE === action.type) {
    const viewState = state.application_services_view;
    return {
      ...state,
      application_services_view: {
        ...viewState,
        autoComplete: {
          ...viewState.autoComplete,
          loading: true,
          searchText: action.payload
        }
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_RESET === action.type) {
    const viewState = state.application_services_view;
    return {
      ...state,
      application_services_view: {
        ...viewState,
        autoComplete: {
          ...viewState.autoComplete,
          open: false,
          options: [],
          loading: false,
          searchText: ''
        }
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_ADD_ADDON === action.type) {
    const viewState = state.application_services_view;
    return {
      ...state,
      application_services_view: {
        ...viewState,
        autoComplete: {
          ...viewState.autoComplete,
          options: [],
          loading: false,
          searchText: action.payload
        }
      }
    };
  }

  if (actions.APPLICATIONS_ADDONS_AUTOCOMPLETE_OPEN === action.type) {
    const viewState = state.application_services_view;

    return {
      ...state,
      application_services_view: {
        ...viewState,
        autoComplete: {
          searchText: '',
          open: action.payload,
          options: viewState.autoComplete.options,
          loading: action.payload && viewState.autoComplete.options.length === 0
        }
      }
    };
  }

  return state;
};

export default change;
