import React from 'react';

import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import SearchInput from './SearchInput';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  }
}));

const CrudToolbar = props => {
  const {
    className,
    buttonNewName,
    searchPlaceholder,
    entity,
    newEntity,
    view,
    handleOpen,
    autoCompleteHandleChange,
    addHandler,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <SearchInput
          addHandler={addHandler}
          autoCompleteHandleChange={autoCompleteHandleChange}
          handleOpen={handleOpen}
          placeholder={searchPlaceholder}
          view={view}
        />
      </div>
    </div>
  );
};

CrudToolbar.propTypes = {
  className: PropTypes.string
};

export default CrudToolbar;
