import React from 'react';

import { useDispatch } from 'react-redux';

import actions from 'redux/actions';

import Form from './Form';

const CrudForm = props => {
  const { entity, formState, saveAction, ...rest } = props;

  const dispatch = useDispatch();
  const saveHandler = event => {
    event.preventDefault();
    dispatch({
      type: saveAction || actions.SAVE_ENTITY,
      payload: formState.values,
      meta: { entity }
    });
  };

  return <Form onSave={saveHandler} formState={formState} {...rest} />;
};

export default CrudForm;
