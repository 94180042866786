import { makeStyles, Theme } from '@material-ui/core';

import { ButtonProps } from '.';

export default makeStyles<Theme, { size: ButtonProps['size'] }>((theme: Theme) => {
  const smallSizePadding = '6px 12px';

  const mediumSizePadding = '12px 16px';

  const paddingWithBorder = ({ size }: { size: ButtonProps['size'] }) => {
    if (size === 'small') {
      return '4px 10px';
    }

    return '10px 14px';
  };

  return {
    base: {
      textTransform: 'initial'
    },
    small: {
      padding: smallSizePadding,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px'
    },
    medium: {
      padding: mediumSizePadding,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px'
    },
    buttonContained: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.primary.dark
      },
      '&:disabled': {
        color: theme.palette.grey[500],
        background: theme.palette.grey[100],
        border: `2px solid ${theme.palette.grey[500]}`,
        padding: paddingWithBorder
      }
    },
    buttonOutlined: {
      background: 'transparent',
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      padding: paddingWithBorder,
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark
      },
      '&:disabled': {
        background: 'transparent',
        color: theme.palette.grey[500],
        borderColor: theme.palette.grey[500]
      }
    },
    buttonText: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
        background: 'transparent'
      },
      '&:disabled': {
        color: theme.palette.grey[500],
        background: 'transparent'
      }
    },
    spacingLoading: {
      marginLeft: theme.spacing(1)
    }
  };
});
