import React from 'react';

const useFocusGroupName = () => {
  const [shouldFocusLastGroupName, setShouldFocusLastGroupName] = React.useState(false);
  const lastGroupTextFieldRef = React.useRef(null);

  React.useEffect(() => {
    if (shouldFocusLastGroupName === true) {
      lastGroupTextFieldRef.current.select();
      setShouldFocusLastGroupName(false);
    }
  }, [shouldFocusLastGroupName, lastGroupTextFieldRef]);

  return { setShouldFocusLastGroupName, lastGroupTextFieldRef };
};

export default useFocusGroupName;
