import React from 'react';

import { Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import useComposeIncidentLink from 'hooks/useComposeIncidentLink';
import { useInterval } from 'hooks/useInterval';
import axios from 'redux/axios';

const ActiveIncidentLink = ({ applicationId, productId }) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState(false);

  const getIncidente = React.useCallback(() => {
    axios
      .get(
        `/products/${productId}/applications/${applicationId}/incidents?page=1&per_page=1&open=true`
      )
      .then(({ data }) => {
        setData(data.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId, applicationId]);

  React.useState(() => {
    getIncidente();
  }, [getIncidente]);

  useInterval(getIncidente, 60000);

  const composeIncidentLink = useComposeIncidentLink();

  const incident = data[0];

  if (loading) {
    return <Typography>...</Typography>;
  }

  if (incident) {
    return (
      <Typography
        style={{
          color: red[500],
          display: 'block',
          textDecoration: 'underline'
        }}
        component={Link}
        to={composeIncidentLink({ productId, applicationId, incidentId: incident.id })}>
        {incident.title}
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography>
        <i>Can't check for open incident</i>
      </Typography>
    );
  }

  return null;
};

export default ActiveIncidentLink;
