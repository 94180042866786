const mapDataToApi = data => {
  return {
    environmentId: Number(data.environmentId),
    productId: data.productId,
    name: data.name,
    applicationsSettings: {
      type: 'webhook',
      checkInterval: Number(data.check_interval),
      failuresToIncident: Number(data.failuresToIncident),
      openIncidentWithSeverity: data?.openIncidentWithSeverity || 'not-classified',
      external: true
    }
  };
};

export default mapDataToApi;
