import { useFlag } from '@unleash/proxy-client-react';
import Axios from 'axios';
import { useQuery } from 'react-query';

import useQueryParams from '..//helpers/useQueryParams';

import useUser from './queriesRest/useUser';

export const composeFlagNameWithEnvironmentPrefix = (flagName: string) => {
  const name =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? flagName
      : `${process.env.REACT_APP_ENVIRONMENT}-${flagName}`;

  return name;
};

/**
 * # Get flag from Unleash
 *
 * - If `REACT_APP_USE_FLAGS_FILE_LOCAL` env `=== "true"`, this will fetch `flags.json` from public folder,
 * otherwise it will fetch flags from Unleash. It is very useful in the local environment
 *
 * Ex.:
 * @example
 * ```json
 * // public/flags.json
 * {
 *   "myFlag": {
 *     "enabled": true
 *   }
 * }
 * ```
 *
 * ```tsx
 * // using this hook in your code
 * process.env.REACT_APP_USE_FLAGS_FILE_LOCAL = "true";
 *
 * const myFlag = useFeatureFlagUnleash('myFlag');
 * // returns -> true
 * ```
 *
 * - If `queryString` is true, it will return flag from query string. Example: ?flagKeyFromUnleash=true, it will return true;
 *
 * - You can configure the `placeholder` value, it is useful when you want return true or false, while fetching session data.
 */
const useFeatureFlagUnleash = (flag: string, { queryString = false, placeholder = false } = {}) => {
  const isLocalFlagsEnabled = process.env.REACT_APP_USE_FLAGS_FILE_LOCAL === 'true';
  const isLocalEnvironment = process.env.REACT_APP_ENVIRONMENT === 'local';

  const flagNameWithEnvironmentPrefix = composeFlagNameWithEnvironmentPrefix(flag);

  const flagFromUnleash = useFlag(flagNameWithEnvironmentPrefix);

  const { data: user } = useUser();

  const queryStringFlag = useQueryParams().get(flag);

  const { data: flags } = useQuery(
    ['featureFlagsLocalDevelopment'],
    async () => {
      return Axios.get('/flags.json').then(response => {
        return response.data;
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {},
      enabled: isLocalFlagsEnabled && isLocalEnvironment
    }
  );

  if (user === undefined) return placeholder;

  if (queryString && queryStringFlag) {
    return queryStringFlag === 'true';
  }

  if (isLocalFlagsEnabled && isLocalEnvironment) {
    return flags?.[flag]?.enabled === true;
  }

  return flagFromUnleash;
};

export default useFeatureFlagUnleash;
