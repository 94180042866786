import { makeStyles } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { Theme } from 'theme/v5';

type TypeChipStyleProps = {
  event: EventType;
};

function getBackgroundColor(event: EventType, palette: Theme['palette']) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: palette.red[500],
    [EventTypeEnum.Acknowledged]: palette.secondary.main,
    [EventTypeEnum.Resolved]: palette.primary.main,
    [EventTypeEnum.PostMortem]: palette.gray[500],
    [EventTypeEnum.Deploy]: palette.secondary.main,
    [EventTypeEnum.HotFix]: palette.orange[400],
    [EventTypeEnum.Rollback]: palette.red[500],
    [EventTypeEnum.IncidentStatusUpdate]: palette.gray[500]
  };

  return labelMap[event?.type];
}

function getBorderColor(event: EventType, palette: Theme['palette']) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: palette.red[500],
    [EventTypeEnum.Acknowledged]: palette.secondary.main,
    [EventTypeEnum.Resolved]: palette.primary.main,
    [EventTypeEnum.PostMortem]: palette.gray[500],
    [EventTypeEnum.Deploy]: palette.secondary.main,
    [EventTypeEnum.HotFix]: palette.orange[400],
    [EventTypeEnum.Rollback]: palette.red[500],
    [EventTypeEnum.IncidentStatusUpdate]: palette.gray[500]
  };

  return labelMap[event?.type];
}

export const useStyles = makeStyles<Theme, TypeChipStyleProps>(theme => ({
  chip: {
    color: props => getBackgroundColor(props.event, theme.palette),
    backgroundColor: 'white',
    borderColor: props => getBorderColor(props.event, theme.palette),
    marginRight: theme.spacing(1)
  }
}));
