const initialValues = {
  name: '',

  notificationSetup: [
    {
      providerName: 'Mail',
      enabled: false,
      config: {
        email: ''
      }
    },
    {
      providerName: 'Telegram',
      enabled: false,
      config: {
        telegramId: ''
      }
    },
    {
      providerName: 'Slack',
      enabled: false,
      config: {
        slackWorkspace: '',
        slackChannel: '',
        slackIntegration: {}
      }
    },
    {
      providerName: 'Teams',
      enabled: false,
      config: {
        teamsWebhookUrl: ''
      }
    },
    {
      providerName: 'WhatsApp',
      enabled: false,
      config: {
        numbers: []
      }
    },
    {
      providerName: 'GoogleChat',
      enabled: false,
      config: {
        gchatWebhookUrl: ''
      }
    },
    {
      providerName: 'DiscordChannel',
      enabled: false,
      config: {
        discordChannelWebhookUrl: ''
      }
    },
    {
      providerName: 'WebHook',
      enabled: false,
      config: {
        webhookUrl: '',
        webhookRetry: '0',
        webhookRetryFailEmail: ''
      }
    }
  ]
};

export default initialValues;
