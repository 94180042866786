import { Assertion } from 'views/ServicesHub/adapters/new/multiflow';

export enum MultiFlowStepType {
  HTTP = 'http'
}

export type MultiFlowStep = {
  id: number;
  step: number;
  name: string;
  type: string;
  method: string;
  domain: string;
  timeout: number;
  domainSettings: Record<string, any>;
  status: string;
  lastCheck: string;
  pingTime: number;
  latency: number;
  assertions: Assertion[] | {}[];
};

export type MultiFlow = {
  id: number;
  uid: string;
  name: string;
  interval: number;
  origin: MultiFlowOrigin;
  environmentId: number;
  orgUid: string;
  activated: string;
  createdAt: string;
  updatedAt: string;
  status: MultiFlowStatusEnum;
  environment: { name: string };
  steps: MultiFlowStep[];
};

export enum MultiFlowOrigin {
  Agent = 'agent',
  Pool = 'pool',
  API = 'api'
}

export enum MultiFlowStatusEnum {
  Off = 'off',
  Success = 'success',
  Error = 'error',
  Maintenance = 'maintenance',
  Pending = 'pending'
}

export type CreateMultiFlowOutput = {
  id: number;
  uid: string;
  name: string;
  environmentId: number;
  orgUid: string;
  origin: string;
  status: string;
  steps: {
    id: number;
    step: number;
    name: string;
    type: string;
    method: string;
    domain: string;
    timeout: number;
    domainSettings: Record<string, any>;
  }[];
};

export enum StepStatusEnum {
  Off = 'off',
  Success = 'success',
  Error = 'error',
  Skipped = 'skipped'
}

export type MultiflowQuery = {
  id: number;
  uid: string;
  name: string;
  status: MultiFlowStatusEnum;
  environmentId?: number;
  environment: { name: string; statusCode: number };
  steps: Step[];
};

export type Step = {
  id: number;
  step: number;
  name: string;
  lastCheck: string;
  status: StepStatusEnum;
};

export type UpdateMultiFlowPayload = {
  id: number;
  uid: string;
  name: string;
  interval: number;
  environmentId: number;
  origin: MultiFlowOrigin;
  steps: {
    id: number;
    step: number;
    name: string;
    type: string;
    method: string;
    domain: string;
    timeout: number;
    domainSettings: Record<string, any>;
  }[];
};
