import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2)
  }
}));
