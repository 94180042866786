import { green, red, orange, grey } from '@material-ui/core/colors';

import { TooltipHeader } from './TooltipHeader';

const statusColor = (hour: string) => {
  const defaultStyle = {
    fontSize: 16,
    color: '#ffffff'
  };

  return [
    {
      when: (row: any = {}) => !row,
      style: {
        backgroundColor: grey[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any = {}) => row?.[hour] >= 99.5,
      style: {
        backgroundColor: green[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any = {}) => row?.[hour] < 99.5 && row?.[hour] >= 98,
      style: {
        backgroundColor: orange[300],
        ...defaultStyle
      }
    },
    {
      when: (row: any) => row?.[hour] < 98,
      style: {
        backgroundColor: red[300],
        ...defaultStyle
      }
    }
  ];
};

const items = [
  {
    time: '01 hour',
    description: 'online1Hour'
  },
  {
    time: '06 hours',
    description: 'online6Hours'
  },
  {
    time: '12 hours',
    description: 'online12Hours'
  },
  {
    time: '24 hours',
    description: 'online24Hours'
  },
  {
    time: '07 days',
    description: 'online7Days'
  },
  {
    time: '15 days',
    description: 'online15Days'
  },
  {
    time: '30 days',
    description: 'online30Days'
  },
  {
    time: '90 days',
    description: 'online90Days'
  },
  {
    time: '180 days',
    description: 'online180Days'
  },
  {
    time: '365 days',
    description: 'online365Days'
  }
];

export const Columns = items.map(item => {
  return {
    name: <TooltipHeader text={item.time}>{item.time}</TooltipHeader>,
    conditionalCellStyles: statusColor(item.description),
    cell: (row: any) => <div>{row?.[item.description] || 0}%</div>,
    center: true,
    width: 'auto'
  };
});
