import regionsAws from 'constants/regionsAws';

import { Box, Checkbox, FormControlLabel, FormGroup, Icon, Link, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab-old/Alert';
import { Controller } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { useStyles } from 'views/Monitoring/View/styles';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type LambdaFormData = {
  accessKey: string;
  secretAccessKey: string;
  awsRegion: string;
  functionName: string;
  notInvoke: boolean;
  domainType: string;
  domain: string;
};

export function LambdaForm({ form, domainType }: FormProps) {
  const classes = useStyles();
  const { register, control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {domainType !== 'url' && (
        <>
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Access Key',
                name: 'accessKey'
              }}
              InputComponent={Input}
            />
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'password',
                label: 'Secret Access Key',
                name: 'secretAccessKey'
              }}
              InputComponent={Input}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <Select options={regionsAws} control={control} name="awsRegion" label="AWS Region" />
            <Input label="Function name" name="functionName" inputRef={register} />
          </Box>
        </>
      )}
      <Box display="flex" gridGap="1rem">
        <Controller
          control={control}
          name="notInvoke"
          defaultValue={false}
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="Check not invoke on interval"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                        required={false}
                      />
                    }
                  />
                  <Tooltip title="If checked, we're going to generate a failure when there isn't any invocations in the configured interval">
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
      </Box>
      <Box gridGap="1rem">
        <Alert severity="warning">
          We use AWS CloudWatch API for searching by Lambda logs and metrics
          <Link href="https://aws.amazon.com/pt/cloudwatch/pricing/">
            Click here to read more about it's pricing.
          </Link>
        </Alert>
      </Box>
    </Box>
  );
}
