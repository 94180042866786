import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';
import genericErrors from '../../actions/genericErrors';
import reducer from '../../reducer/upload';

function* upload(axios, action) {
  try {
    const { file, contentType, name } = action.payload;
    yield axios.request({
      method: 'put',
      url: '/users/avatar',
      data: { file, contentType, name }
    });
    yield put({ type: actions.LOGGED_USER });
    yield put({ type: actions.UPLOAD_AVATAR_SUCCESSFUL, meta: { reducer } });
  } catch (err) {
    yield put(genericErrors(action.type, err));
  }
}

export default function* watchUpload(axios) {
  yield takeLatest(actions.UPLOAD_AVATAR, upload, axios);
}
