import { createTheme, Theme as MuiTheme } from '@material-ui/core';

import palette from '../palette';

import overrides from './overrides';
import typography from './typography';

const theme = {
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
};

export type Theme = typeof theme & MuiTheme;

export default createTheme(theme);
