import { takeEvery, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/merge';

function* prepareFetchEntities(axios, action) {
  try {
    const { entity, id } = action.meta;
    const {
      data: { data: values }
    } = yield axios.get(`/${entity}/${id}`);

    yield put({
      type: actions.MERGE_ENTITY,
      meta: {
        entity: `${entity}_edit`,
        reducer
      },
      payload: {
        values
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchNavigation(axios) {
  yield takeEvery(actions.EDIT_ENTITY, prepareFetchEntities, axios);
}
