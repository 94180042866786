import React from 'react';

import applicationType from 'constants/ApplicationType';

import MonitoringFormNew from '../Form/FullForm/MonitoringFormNew';
import MultiHttpForm from '../Form/MultiHttpForm/MultiHttpFormNew';
import WebHookFormNew from '../Form/WebHookForm/WebHookFormNew';

import ResourceDialog from './ResourceDialog';

const getFormByApplicationType = type => {
  if (type === applicationType.Internal.type) {
    return MonitoringFormNew;
  }

  if (type === applicationType.ExternalWithAddons.type) {
    return MonitoringFormNew;
  }

  if (type === applicationType.ExternalWithoutAddons.type) {
    return MonitoringFormNew;
  }

  if (type === applicationType.Webhook.type) {
    return WebHookFormNew;
  }

  if (type === applicationType.InternalMultiHttp.type) {
    return MultiHttpForm;
  }
};

const ResourceNewDialog = ({ openerComponent, productId, applicationTypeInfos, ...otherProps }) => {
  const Form = getFormByApplicationType(applicationTypeInfos.type);

  const FormComponent = React.useCallback(
    props => (
      <Form
        resourceType={applicationTypeInfos.type}
        productId={productId}
        predefinedSettings={applicationTypeInfos.predefinedSettings}
        {...props}
      />
    ),
    [applicationTypeInfos.type, productId, applicationTypeInfos.predefinedSettings]
  );

  return (
    <ResourceDialog
      openerComponent={openerComponent}
      title={applicationTypeInfos.title}
      description={applicationTypeInfos.description}
      formComponent={FormComponent}
      {...otherProps}
    />
  );
};

export default ResourceNewDialog;
