import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { SolrFormData } from 'views/ServicesHub/forms/monitoring/searchEngine/Solr';

import { monitoringAdapter } from '../monitoring';

export function solrAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & SolrFormData {
  const domain = new URI(data.domain);
  // http://HOST:PORT/solr/gettingstarted/admin/ping

  const host = domain.hostname();
  const port = domain.port();

  return {
    ...monitoringAdapter(data),
    host,
    port
  };
}
