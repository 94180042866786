import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions } from 'oldComponents/LegacyDialog';

import { ButtonSimple } from 'componentsV4/Button';

import Form from './Form';

const modes = {
  create: 'Create',
  edit: 'Edit'
};

function NewStepsGroupDialog(props) {
  const { onClose, open, formProps, mode, canCreate } = props;

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog maxWidth="md" onClose={handleCancel} aria-labelledby="create-step-group" open={open}>
      <form onSubmit={formProps.onSubmit}>
        <DialogTitle id="create-step-group" onClose={handleCancel}>
          {modes[mode]} Step Group
        </DialogTitle>

        <DialogContent>
          <Form {...formProps} />
        </DialogContent>

        <DialogActions>
          <ButtonSimple onClick={handleCancel} text="Cancel" color="primary" variant="outlined" />
          <ButtonSimple
            type="submit"
            variant="contained"
            color="primary"
            text="Save"
            disabled={!canCreate}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewStepsGroupDialog;
