import { Resource } from 'types/external/Resource';

import { MysqlFormData } from '../../../forms/monitoring/database/Mysql';
import { monitoringAdapter } from '../monitoring';

export function mysqlAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & MysqlFormData {
  const { domain, username, password, host, port, database } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    username,
    password,
    host,
    port,
    database
  };
}

const prepareDomain = (data: Resource) => {
  let username = '';
  let password = '';
  let host = '';
  let port = '';
  let database = '';
  const domain = data.domain;

  if (data.domainSettings.domainType === 'userAndPass') {
    // ex: USER:PASSWORD@tcp(HOST:PORT)/DATABASE

    const mySQLDomainRegex = /(.+):(.+)@tcp\((.+):(.+)\)\/(.+)/;
    const match = domain.match(mySQLDomainRegex)!;

    username = match[1];
    password = match[2];
    host = match[3];
    port = match[4];
    database = match[5];
  }

  return { domain, username, password, host, port, database };
};
