import { useEffect, useState } from 'react';

import { useRoles } from 'hooks/queriesGraphQL/useRoles';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import axios from 'redux/axios';

export type Role = {
  id: number;
  name: string;
};

type Response = {
  organizationRoles: Role[] | null;
  defaultRole: Role | null;
  defaultUser: {
    id: string;
    name: string;
    email: string;
    role: number;
    status: string;
  };
};

export const useDefaultUser = (): Response => {
  const [roles, setRoles] = useState<Role[] | null>(null);

  const useAccountViaAragorn = useFeatureFlagUnleash('useAccountViaAragorn', { queryString: true });

  const { data } = useRoles({ pause: !useAccountViaAragorn });

  async function getRoles() {
    const {
      data: { data: roles }
    }: any = await axios.get('/roles/org?page=1&per_page=10000&sort=name,ASC');
    setRoles(roles);
    return roles;
  }

  useEffect(() => {
    if (useAccountViaAragorn && data?.length) {
      setRoles(data);
    }
  }, [data, useAccountViaAragorn]);

  useEffect(() => {
    if (useAccountViaAragorn) return;
    getRoles();
  }, [useAccountViaAragorn]);

  const defaultRole = roles?.filter(role => role.name === 'member') || [];

  return {
    organizationRoles: roles,
    defaultRole: defaultRole?.[0],
    defaultUser: {
      id: '',
      name: '',
      email: '',
      role: defaultRole?.[0]?.id,
      status: 'active'
    }
  };
};
