import { CreateRulePayload, UpdateRulePayload } from 'types/external/AlertCenter';
import { useMutation } from 'urql';

const CreateRuleMutation = `#graphql
  mutation($rule: CreateRuleArgs!) {
    createRule(data: $rule) {
      uid
    }
  }
`;

const DeleteRuleMutation = `#graphql
  mutation($ruleUid: String!) {
    deleteRuleFromRuleManager(ruleUid: $ruleUid)
  }
`;

const UpdateRuleMutation = `#graphql
  mutation ($data: UpdateRulesInput!) {
    updateRule(data: $data)
  }
`;

export const GetRulesByEntityUidQuery = `#graphql
  query(
    $entityUid: String!
    $entity: String!
  ) {
    getRulesByEntityUid(
      entityUid: $entityUid
      entity: $entity
    ) {
      uid
      severity
      type
      actions {
        id
        action
        type
        operation
        occurrence
        interval
      }
      teams {
        teamId
      }
      responders {
        uid
        type
      }
    }
  }
`;

const useRule = () => {
  const [{ fetching: creatingRule }, createRule] = useMutation<{ data: CreateRulePayload }>(
    CreateRuleMutation
  );

  const [{ fetching: updatingRule }, updateRule] = useMutation<{ data: UpdateRulePayload }>(
    UpdateRuleMutation
  );

  const [{ fetching: deletingRule }, deleteRule] = useMutation<{ ruleUid: string }>(
    DeleteRuleMutation
  );

  const isFetchingRule = creatingRule || updatingRule || deletingRule;

  return { isFetchingRule, createRule, updateRule, deleteRule };
};

export default useRule;
