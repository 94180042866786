import { Card, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonDefault } from 'oldComponents/Button/index';
import { Link, useParams } from 'react-router-dom';

import useLeadTimeChanges from 'hooks/queriesGraphQL/useLeadTimeChanges';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { useProductPermission } from 'hooks/useProductPermission';

import { useStyles } from './styles';

export const LeadTimeIntegrationApplicationCard = ({
  applicationUid
}: {
  applicationUid: string;
}) => {
  const classes = useStyles();

  const { productId, applicationId } = useParams<{
    productId: string;
    applicationId: string;
  }>();

  const canUseDORAMetrics = useFeaturePlan('Metrics.DORA');
  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');

  const { getMetrics } = useLeadTimeChanges();

  const hasPermissionToCreateLeadTimeIntegration = useProductPermission({
    target: 'LeadTimeChangesController-post-/integration',
    productId
  });

  const availableMetricPeriods = [
    {
      type: '1 day',
      enabled: true
    },
    {
      type: '30 days',
      enabled: canFetchDataFromTheLast30Days
    }
  ].filter(period => period.enabled);

  const [{ data }] = getMetrics({
    variables: {
      applicationId: Number(applicationId),
      periods: availableMetricPeriods.map(period => period.type)
    }
  });

  const metric = data?.leadTimeMetrics?.metric?.metrics?.find(
    (metric: { type: string; hours_formated: string }) => {
      if (canFetchDataFromTheLast30Days) {
        return metric.type === '30 days';
      }

      return metric.type === '1 day';
    }
  );

  const isIntegrated = data?.leadTimeMetrics?.metric?.application_id > 0;
  const redirectURL = `/integrations/new/github?productId=${productId}&applicationUid=${applicationUid}&redirectTo=${window.location.pathname}`;

  const Content = (() => {
    if (!canUseDORAMetrics) {
      return (
        <Link to="/plans">
          <Typography variant="h5" color="primary">
            Upgrade your plan
          </Typography>
        </Link>
      );
    }

    return isIntegrated ? (
      <>
        <Typography className={classes.leadTimer} variant="h2">
          {metric?.hours_formated}
        </Typography>
        <Typography className={classes.textDay}>Last {metric?.type}</Typography>
      </>
    ) : (
      <>
        <Typography className={classes.textToIntegration}>
          To calculate, it is necessary to make an integration
        </Typography>
        <Link
          to={redirectURL}
          className={clsx(!hasPermissionToCreateLeadTimeIntegration && classes.disabled)}>
          <ButtonDefault
            className={classes.spacingButton}
            size="small"
            children="Integrate now"
            variant="text"
            disabled={!hasPermissionToCreateLeadTimeIntegration}
          />
        </Link>
      </>
    );
  })();

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        <Typography className={classes.title} variant="h3" noWrap>
          Lead time for changes
        </Typography>

        {Content}
      </CardContent>
    </Card>
  );
};
