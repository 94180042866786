import { useParams } from 'react-router';

import { LoadingOverlay } from 'componentsV4/Loading';
import { useApplication } from 'hooks/queriesGraphQL/useApplication';
import { useDependencies } from 'hooks/queriesGraphQL/useDependency';

import { ApplicationsForm } from '../Form';

export function ApplicationEdit() {
  const { applicationUid } = useParams<{ applicationUid: string }>();

  const [{ data: appData, fetching: isFetchingApp }, refetch] = useApplication(applicationUid);
  const [{ data: dependenciesData, fetching: isFetchingDependencies }] = useDependencies(
    applicationUid
  );

  const isLoading = isFetchingApp || isFetchingDependencies;

  if (isLoading) return <LoadingOverlay />;

  return (
    <>
      {appData && dependenciesData && (
        <ApplicationsForm
          isEdit={true}
          isFetchingApp={isLoading}
          refetch={refetch}
          breadcrumbItens={[
            { label: 'Monitoring Aggregator' },
            { label: 'Application Center', link: `/applications/${applicationUid}` },
            { label: 'Edit', link: '/edit' }
          ]}
          title={'Edit Application'}
          description={
            'Create applications to centralize your monitoring and expand your visualization of monitored resources in a single location'
          }
          startValues={{
            name: appData?.applicationV2?.name,
            entityUid: appData?.applicationV2?.entityUid,
            entity: appData?.applicationV2?.entity,
            dependencies: dependenciesData?.getDependenciesList,
            applicationId: appData?.applicationV2?.id,
            applicationUid: appData?.applicationV2?.uid,
            productId: appData?.applicationV2?.productId
              ? appData?.applicationV2?.productId?.toString()
              : ''
          }}
        />
      )}
    </>
  );
}
