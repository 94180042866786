import React from 'react';

import { Button } from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

export const ButtonSimple = ({
  text,
  disabled = false,
  color,
  onClick = () => {},
  type = 'button',
  ...otherProps
}) => {
  const SimpleButtonStyle = withStyles(theme => ({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '8px 16px',
      border: disabled ? '1px solid' : 'none',
      borderRadius: '4px',
      lineHeight: 1.5,
      color: theme.palette.white,
      backgroundColor: () => {
        if (disabled) {
          return theme.palette.grey[200];
        }

        if (color === 'primary') {
          return theme.palette.primary.main;
        }

        if (color === 'secondary') {
          return theme.palette.secondary.main;
        }

        return;
      },
      borderColor: disabled ? theme.palette.button.disabledMain : 'none',
      '&:hover': {
        backgroundColor: color === 'primary' ? green[400] : blue.A400,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: theme.palette.button.greenMain
      }
    }
  }))(Button);

  return (
    <SimpleButtonStyle type={type} disabled={disabled} onClick={onClick} {...otherProps}>
      {text}
    </SimpleButtonStyle>
  );
};

export default withThemeV3Hoc(ButtonSimple);
