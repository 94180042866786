import { useQuery, useMutation } from 'urql';

const itsmIntegrations = `#graphql
  query {
    itsmIntegrations {
      uid
      origin
      configuration {
        subdomain
      }
    }
  }
`;

const deleteItsmIntegrationMutation = `#graphql
  mutation(
    $integrationUid: String!
  ) {
    deleteItsmIntegration (integrationUid: $integrationUid){
      message
      success
  }
  }
`;

const useItsmIntegration = () => {
  const deleteItsmIntegration = useMutation(deleteItsmIntegrationMutation);

  const useIntegrations = (args?: { pause: boolean }) =>
    useQuery<{ itsmIntegrations: any }>({
      query: itsmIntegrations,
      pause: args?.pause ?? false
    });

  return { getItsmIntegrations: useIntegrations, deleteItsmIntegration };
};

export default useItsmIntegration;
