import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

interface StyleProps {}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  incidentIdLink: {
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  },
  incidentId: {
    fontSize: 16
  }
}));
