import { Dependency } from 'types/external/Dependency';
import { useQuery } from 'urql';

const GetDependenciesQuery = `#graphql
query ($applicationUid: String!) {
  getDependenciesList(
        applicationUid: $applicationUid
    ) {
      dependencyUid: uid
      entityUid
      entity
      dependencyId: id
    }
  }
`;

export const useDependencies = (applicationUid: string) =>
  useQuery<{ getDependenciesList: Dependency[] }>({
    query: GetDependenciesQuery,
    variables: { applicationUid }
  });
