import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/applications';

function* prepareFetchEntities(axios, action) {
  try {
    const { applicationId, productId } = action.params;
    const {
      data: { data: product }
    } = yield axios.get(`/products/${productId}`);
    const {
      data: { data: values }
    } = yield axios.get(`/products/${productId}/applications/${applicationId}`);

    yield put({
      type: actions.PRODUCT_APPLICATIONS_VIEW_FETCH_SUCCESSFUL,
      meta: {
        reducer
      },
      payload: {
        products_view: { ...product },
        product_applications_view: { values },
        application_services_view: {
          id: applicationId
        }
      }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductApplicationViewFetch(axios) {
  yield takeLatest(actions.PRODUCT_APPLICATIONS_VIEW_FETCH, prepareFetchEntities, axios);
}
