import { Box, Button, TextFieldProps } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import { Control } from 'react-hook-form';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useResponders } from 'hooks/queriesGraphQL/useResponders';
import { MultiSelect } from 'views/ServicesHub/components/MultiSelect';
import { Select } from 'views/ServicesHub/components/Select';

type RespondersSelectProps = {
  control: Control;
  name: string;
  required?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
  label?: string;
};

export function RespondersSelect({
  control,
  name,
  required = false,
  multiSelect = false,
  disabled = false,
  label = 'Responders',
  ...props
}: RespondersSelectProps & TextFieldProps) {
  const [{ data, fetching }, refetch] = useResponders();

  const options =
    data?.getRespondersByOrgUid?.data?.map(responder => ({
      value: String(responder.uid),
      label: responder.name,
      helperText: responder.type,
      type: responder.type
    })) ?? [];

  return (
    <Box position="relative" display="flex" gridGap={'1.5rem'}>
      {fetching && <LoadingOverlay size="2rem" />}
      {multiSelect ? (
        <MultiSelect
          disabled={disabled}
          options={options}
          name={name}
          label={label}
          control={control}
          required={required}
          {...props}
        />
      ) : (
        <Select
          control={control}
          name={name}
          options={options}
          label={label}
          required={required}
          disabled={disabled}
          {...props}
        />
      )}

      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        title="Reload teams list"
        onClick={() => refetch()}>
        <Loop />
      </Button>
    </Box>
  );
}
