import { Box } from '@material-ui/core';

import { Skeleton } from 'components/Skeletron';

export function DataTableLoading() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="0.5rem"
      justifyContent="space-between"
      flex={1}>
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
      <Skeleton height="1.5rem" width="100%" variant="rect" />
    </Box>
  );
}
