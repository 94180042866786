import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';

import schema from './schema';

const useCustomUseForm = ({ defaultValues }) => {
  const useFormMethods = useForm({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const { control } = useFormMethods;

  const { fields: headersFields, append: headersAppend, remove: headersRemove } = useFieldArray({
    control,
    name: 'headers'
  });

  const {
    fields: postBodyXFormUrlEncodedFields,
    append: postBodyXFormUrlEncodedAppend,
    remove: postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'post_body_urlencoded'
  });

  return {
    ...useFormMethods,
    headersFields,
    headersAppend,
    headersRemove,
    postBodyXFormUrlEncodedFields,
    postBodyXFormUrlEncodedAppend,
    postBodyXFormUrlEncodedRemove
  };
};

export default useCustomUseForm;
