import { useQuery } from 'urql';

const IntegrationsListQuery = `#graphql
  query {
    itsmIntegrations {
      uid
      origin
    }
  }`;

type IntegrationsList = {
  itsmIntegrations: { uid: string; origin: string }[];
};

export const useItsmIntegrations = () => {
  const [{ data, fetching, error }] = useQuery<IntegrationsList>({
    query: IntegrationsListQuery
  });

  if (error) {
    return { integrationUid: '', origin: '', fetching };
  }

  const integrationUid = data?.itsmIntegrations[0]?.uid;
  const origin = data?.itsmIntegrations[0]?.origin;

  return { integrationUid, origin, fetching };
};
