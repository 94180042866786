import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { objectToKeyValueList } from 'helpers/getObjectList';
import {
  ElasticFormData,
  ElasticMonitoringType
} from 'views/ServicesHub/forms/monitoring/searchEngine/Elastic';

import { monitoringAdapter } from '../monitoring';

export function elasticAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & ElasticFormData {
  const domain = new URI(data.domain);

  const splitDomain = () => {
    const splittedDomain = data.domain.split('@');
    const splittedIndex = data.domain.split('/_cluster/health/');

    const regex = /(.+?)(?::(\d+))?(?::\d+)?(\/_cluster\/health.*)/;

    if (splittedDomain.length > 1) {
      const match = splittedDomain[1].match(regex)!;
      return { host: match[1], port: match[2] ?? '', indexAndQuery: splittedIndex[1] || '' };
    }
    const match = splittedDomain[0].match(regex)!;

    return {
      host: match[1].replace(/(http:\/\/|https:\/\/)/, ''),
      port: match[2] ?? '',
      indexAndQuery: splittedIndex[1] || ''
    };
  };
  //exemples:
  //"Real" exemple:  http://master:senhaComCaracters!@#$@https://search-testing-new-jorney-abc.aos.us-east-1.on.aws/_dashboards:9200/_cluster/health/index
  // ex1: http://HOST:PORT/_cluster/health/staging_entities-orgid
  // ex2: http://USER:PASSWORD@HOST:PORT/_cluster/health/staging_entities-orgid
  // ex3: http://APIKEY@HOST:PORT/_cluster/health/staging_entities-orgid

  const getIndex = (indexAndQuery: string) => {
    const index = indexAndQuery.replace(/\/_cluster\/health\/?/, '').split('?');

    return index[0];
  };

  const { host, port, indexAndQuery } = splitDomain();

  const query = objectToKeyValueList(
    Object.fromEntries(new URLSearchParams(domain.query()).entries())
  );

  const resourceCommonData = {
    ...monitoringAdapter(data),
    host: host,
    port: port,
    protocol: data.domainSettings.ssl ? 'https' : 'http',
    monitoringType: data.domainSettings.monitoringType as ElasticMonitoringType,
    index: getIndex(indexAndQuery),
    query
  };

  if (resourceCommonData.monitoringType === ElasticMonitoringType.userAndPass) {
    return { ...resourceCommonData, user: domain.username(), password: domain.password() };
  }

  if (resourceCommonData.monitoringType === ElasticMonitoringType.apiKey) {
    return { ...resourceCommonData, apiKey: domain.username() };
  }

  return resourceCommonData;
}
