import React from 'react';

import { Box, TextField, Typography, Button, CircularProgress } from '@material-ui/core';
import { GetErrorBudgetByServiceId, CreateService } from 'oldComponents/ErrorBudget';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'urql';

import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import { useStyles } from './styles';

const getErrorBudgetMarginText = (margin: any) => {
  if (margin.budgetPerc > 0) return `${margin.budgetPerc}%`;
  return `${margin.budgetHour} h`;
};

export function ErrorBudget({
  serviceId,
  isMonitoring,
  interval
}: {
  serviceId: number;
  isMonitoring: boolean;
  interval: number;
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const useErrorBudgetQuery = ({
    serviceId,
    isMonitoring
  }: {
    serviceId: number;
    isMonitoring: boolean;
  }) => {
    return useQuery({
      query: GetErrorBudgetByServiceId,
      pause: !serviceId || !isMonitoring,
      variables: { serviceId }
    });
  };

  const [{ data: resultErrorBudget, fetching }, reexecuteQuery] = useErrorBudgetQuery({
    serviceId,
    isMonitoring
  });

  const margin =
    resultErrorBudget?.getMargin && resultErrorBudget.getMargin?.marginOutHour
      ? resultErrorBudget?.getMargin
      : null;

  const [budgetPerc, setBudgetPerc] = React.useState(margin?.budgetPerc || 0);

  const [, createService] = useMutation(CreateService);

  const hasPermissionErrorBudget = usePermission('ErrorBudgetController-post-/budget/');

  const handleSubmit = () => {
    setButtonDisabled(true);

    createService({
      serviceInput: {
        serviceId,
        interval,
        budgetPerc: budgetPerc,
        budgetHour: margin?.budgetHour || 0
      }
    }).then(result => {
      if (result.error) return;

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Changes applied successfully'
      });

      if (result.data) {
        reexecuteQuery();
      }
    });
  };

  React.useEffect(() => {
    if (buttonDisabled && !budgetPerc && margin?.budgetPerc) {
      setBudgetPerc(margin?.budgetPerc);
    }
  }, [budgetPerc, margin, buttonDisabled]);

  React.useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, 1000 * 10);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (buttonDisabled) {
      setButtonDisabled(false);
    }

    const regex = /^[0-9\b]+$/;

    if (event.target.value === '' || regex.test(event.target.value)) {
      setBudgetPerc(Number(event.target.value));
    }
  };

  if (fetching) {
    return (
      <Box display="flex" mt={4} alignItems="center" justifyContent="center">
        <CircularProgress color="primary" size={50} thickness={4} />
      </Box>
    );
  }

  if (!resultErrorBudget?.getMargin) {
    return <Box>Error fetching data</Box>;
  }

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          {!hasPermissionErrorBudget && (
            <Typography variant="h6" className={classes.inputLabel}>
              Your budget: {getErrorBudgetMarginText(margin)}
            </Typography>
          )}

          {hasPermissionErrorBudget && (
            <>
              <Typography variant="body2" className={classes.formTextBudget}>
                Define your budget:
              </Typography>
              <TextField
                onChange={handleChange}
                className={classes.textFieldBudget}
                size="small"
                variant="outlined"
                value={budgetPerc}
                type="text"
                inputProps={{ maxLength: 2 }}
                InputProps={{ classes: { input: classes.inputTextFieldBudget } }}
              />
              <Typography variant="body2" className={classes.captionTextBudget}>
                %
              </Typography>
              <Button
                color="primary"
                size="small"
                variant="contained"
                className={classes.buttonSave}
                disabled={buttonDisabled}
                onClick={handleSubmit}>
                Save
              </Button>
            </>
          )}
        </Box>

        <Box className={classes.marginBox}>
          <Box display="flex" alignItems="center" mr={1} className={classes.outMarginText}>
            <span className={classes.redEllipse}></span>
            <Typography>
              <strong>{margin?.marginOutHour}</strong> out of margin
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" className={classes.insideMarginText}>
            <span className={classes.blueEllipse}></span>
            <Typography>
              <strong>{margin?.marginInsideHour}</strong> inside margin
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={3} mb={2}>
        <Typography variant="h5" className={classes.textBudget}>
          {margin?.marginHour}
        </Typography>
        <Box display="flex" alignItems="center" className={classes.margin} ml={3}>
          {margin?.marginOutPerc > 0 && (
            <Box
              className={
                margin?.marginInsidePerc > 0 ? classes.outMargin : classes.outMarginWithRadius
              }
              width={margin?.marginOutPerc + `%`}></Box>
          )}
          {margin?.marginInsidePerc > 0 && (
            <Box
              className={
                margin?.marginOutPerc > 0 ? classes.insideMargin : classes.insideMarginWithRadius
              }
              width={margin?.marginInsidePerc + `%`}></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
