import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { Backdrop, Box, CircularProgress, Grid, makeStyles, useTheme } from '@material-ui/core';
import { GraphQLError } from 'graphql';
import ApplicationAlertParams from 'oldComponents/ApplicationAlertParams';

import ButtonSimple from 'componentsV3/ButtonSimple';
import CheckBoxDefault from 'componentsV3/CheckBox';
import InputDefault from 'componentsV3/InputDefault';
import InputWithSecrets from 'componentsV3/InputWithSecrets';
import KeyValueWithAdd from 'componentsV3/KeyValueWithAdd';
import MuiRadioGroup from 'componentsV3/RadioGroup';
import SelectSimple from 'componentsV3/Select';
import { analyticsEvent } from 'services/firebase/analytics';

import { TeamsFromCore, useMsTeamsQuery } from '../../Product/TeamsAssign';

import useCustomUseForm from './customUseForm';
import EnvironmentSelect from './EnvironmentSelect';

const useStyles = makeStyles(({ palette, zIndex, spacing }) => ({
  form: {
    maxWidth: 830
  },
  button: {
    marginTop: spacing(9)
  },
  serverError: {
    color: palette.error.main
  },
  backdrop: {
    zIndex: zIndex.drawer + 100
  }
}));

const defaultValues = {
  environmentId: '',
  healthcheckUrl: '',
  method: 'GET',
  headers: [{ key: '', value: '' }],
  post_body_default: [{ key: '', value: '' }],
  post_body_urlencoded: [{ key: '', value: '' }],
  post_body_raw: '',
  post_body_type: postBodyTypeValues.raw,
  post_body_raw_type: postBodyRawTypeValues.json,
  check_interval: 60,
  failuresToIncident: 2,
  tls_renegotiation: 0,
  timeout: 60,
  skip_ssl_validation: false
};

type ApplicationFormProps = {
  onSubmit: (values: any) => void;
  startValues: any;
  teamId: string;
  mutationErrors?: GraphQLError[];
  isLoading: boolean;
  firstAccess: boolean;
  environmentId: number | undefined;
  applicationType: string;
};

const ApplicationForm = ({
  onSubmit,
  startValues,
  teamId,
  mutationErrors,
  isLoading,
  firstAccess,
  environmentId,
  applicationType
}: ApplicationFormProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    control,
    register,
    errors,
    setValue,
    watch,
    formState,
    handleSubmit,
    headersAppend,
    headersRemove,
    headersFields,
    postBodyXFormUrlEncodedFields,
    postBodyXFormUrlEncodedAppend,
    postBodyXFormUrlEncodedRemove,
    formState: { isValid }
  } = useCustomUseForm({
    defaultValues: startValues
      ? {
          ...startValues,
          teamId: teamId || null,
          post_body_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded
              ? startValues.post_body_type
              : postBodyTypeValues.raw,
          post_body_raw_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded ||
            !startValues.post_body_type
              ? postBodyRawTypeValues.json
              : startValues.post_body_type,
          tls_renegotiation:
            startValues?.domain_settings !== '' && startValues?.domain_settings !== undefined
              ? JSON.parse(startValues?.domain_settings)?.tls_renegotiation
              : 0
        }
      : defaultValues
  });
  const selectedMethod = watch('method');
  const selectedPostBodyType = watch('post_body_type');

  const useQuery = useMsTeamsQuery;

  const [teamsQueryResult] = useQuery();

  const resultData =
    ((teamsQueryResult as unknown) as TeamsFromCore).data?.teams?.data.map(team => ({
      label: team.name,
      value: String(team.id)
    })) || [];
  return (
    <form className={classes.form} name="newApplication" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Grid container spacing={3}>
          {!environmentId ? (
            <Grid item xs={12}>
              <EnvironmentSelect
                errors={errors}
                control={control}
                name="environmentId"
                setValue={setValue}
                formState={formState}
                defaultLabel="Environment"
                required
                selectFirst
              />
            </Grid>
          ) : (
            <input type="hidden" ref={register} name="environmentId" value={environmentId} />
          )}
          <Grid item xs={6}>
            <InputDefault
              inputRef={register}
              errors={errors}
              label="Application Name"
              placeholder="Ex: My Website"
              name="name"
              required
            />
          </Grid>

          <Grid item xs={6} spacing={1}>
            <SelectSimple
              errors={errors}
              label="Team"
              name="teamId"
              required={false}
              formState={formState}
              control={control}
              items={[
                { value: '', label: 'No Team' },
                ...resultData.map(team => ({ value: team.value, label: team.label }))
              ]}
            />
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', paddingLeft: '12px' }}>
            <Box display="flex" width="100%">
              <Box flexGrow="1" mr={3}>
                <InputWithSecrets
                  iconColor={theme.palette.primary.main}
                  errors={errors}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  TextFieldProps={{
                    name: 'healthcheckUrl',
                    label: 'Healtcheck URL',
                    placeholder: 'Ex: http://mywebsite.com',
                    required: true
                  }}
                  InputComponent={InputDefault}
                />
              </Box>

              <Box mr={3} flexBasis="16%">
                <SelectSimple
                  errors={errors}
                  name="method"
                  label="Method"
                  defaultValue="GET"
                  control={control}
                  items={[
                    { value: 'GET', label: 'GET' },
                    { value: 'POST', label: 'POST' }
                  ]}
                />
              </Box>
              <Grid item xs={2} style={{ marginRight: 24 }}>
                <SelectSimple
                  errors={errors}
                  name="tls_renegotiation"
                  label="TLS Renegotiation"
                  defaultValue="0"
                  control={control}
                  items={[
                    { value: 0, label: 'Never' },
                    { value: 1, label: 'One Time' },
                    { value: 2, label: 'Ever' }
                  ]}
                />
              </Grid>
              <Box pt={3}>
                <CheckBoxDefault
                  label="Skip SSL"
                  inputRef={register}
                  name="skip_ssl_validation"
                  tooltip={
                    '(TLS) By default, every SSL connection connector makes is verified to be secure. This option allows connector to proceed and operate even for server connections otherwise considered insecure.'
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <KeyValueWithAdd
              control={control}
              errors={errors}
              fieldAppend={headersAppend}
              fieldRemove={headersRemove}
              propsKey={{ label: 'Header', placeholder: 'Ex: header_key' }}
              propsValue={{ label: 'Value', placeholder: 'Ex: header_value' }}
              fieldsList={headersFields}
              name="headers"
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </Grid>
          {selectedMethod === 'POST' && (
            <>
              <Grid>
                <Box display="flex">
                  <MuiRadioGroup
                    errors={errors}
                    name={'post_body_type'}
                    control={control}
                    label="Post-body type"
                    defaultValue="raw"
                    options={[
                      { value: postBodyTypeValues.raw, label: 'raw' },
                      { value: postBodyTypeValues.urlencoded, label: postBodyTypeValues.urlencoded }
                    ]}
                  />

                  {selectedPostBodyType === postBodyTypeValues.raw && (
                    <SelectSimple
                      errors={errors}
                      name="post_body_raw_type"
                      control={control}
                      label="Raw type"
                      defaultValue={postBodyRawTypeValues.json}
                      items={[
                        { value: postBodyRawTypeValues.json, label: 'JSON' },
                        { value: postBodyRawTypeValues.text, label: 'Text' },
                        { value: postBodyRawTypeValues.javaScript, label: 'JavaScript' },
                        { value: postBodyRawTypeValues.xml, label: 'XML' },
                        { value: postBodyRawTypeValues.html, label: 'HTML' }
                      ]}
                    />
                  )}
                </Box>
              </Grid>

              {selectedPostBodyType === postBodyTypeValues.raw && (
                <Grid item xs={12}>
                  <InputDefault
                    errors={errors}
                    inputRef={register}
                    label="Post body"
                    multiline={true}
                    linesNumber={3}
                    name="post_body_raw"
                  />
                </Grid>
              )}

              {selectedPostBodyType === postBodyTypeValues.urlencoded && (
                <Grid item xs={12}>
                  <KeyValueWithAdd
                    control={control}
                    errors={errors}
                    fieldAppend={postBodyXFormUrlEncodedAppend}
                    fieldRemove={postBodyXFormUrlEncodedRemove}
                    fieldsList={postBodyXFormUrlEncodedFields}
                    name="post_body_urlencoded"
                    propsKey={{ label: 'Post body', placeholder: 'Ex: post_body_key' }}
                    propsValue={{ label: 'Value', placeholder: 'Ex: post_body_value' }}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                  />
                </Grid>
              )}
            </>
          )}
          {/* <Grid style={{ marginTop: -9 }} item xs={12}>
            <InputDefault
              errors={errors}
              inputRef={register}
              label="Validation String"
              placeholder="Ex: my_validation_string"
              tooltip="Will check if the healthcheck response body contains this string. Leave empty to check status code only."
              name="validationString"
            />
          </Grid> */}
          <ApplicationAlertParams register={register} errors={errors} control={control} />
        </Grid>

        <Grid className={classes.button} xs={3} item>
          <ButtonSimple
            text="Finish Application"
            type="submit"
            color="primary"
            disabled={!isValid}
            onClick={() => {
              const eventsMap: { [key: string]: string } = {
                externalWithoutAddons: firstAccess
                  ? 'fa_app_finish_setup_new_epwd_button'
                  : 'app_finish_setup_new_epwd_button',
                internal: firstAccess
                  ? 'fa_app_finish_setup_new_iaea_button'
                  : 'app_finish_setup_new_iaea_button',
                externalWithAddons: firstAccess
                  ? 'fa_app_finish_setup_new_eaea_button'
                  : 'app_finish_setup_new_eaea_button'
              };

              if (eventsMap[applicationType]) {
                analyticsEvent(eventsMap[applicationType]);
              }
            }}
          />
        </Grid>
      </Box>
      {isLoading && (
        <Backdrop open={true} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>
      )}
    </form>
  );
};

export default ApplicationForm;
