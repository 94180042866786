import React from 'react';

import { Button, Dialog, DialogContentText, Link } from '@material-ui/core';
import { DialogTitle, DialogContent, DialogActions } from 'oldComponents/LegacyDialog';

const DiscordChannelIntegrationInstructionsDialog = ({ field }) => {
  const [showInstructions, setShowInstructions] = React.useState(false);

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>
      <Dialog open={showInstructions} aria-labelledby="new-service-dialog" onClose={handleClose}>
        <DialogTitle id="new-service-dialog" onClose={handleClose}>
          Discord channel integration instructions
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant="h5">Create a new Webhook</DialogContentText>
          <DialogContentText>
            1. Go to{' '}
            <Link href="https://discord.com/app" target="_blank" rel="noopener noreferrer">
              Discord
            </Link>{' '}
            via browser or application.
          </DialogContentText>

          <DialogContentText>
            {' '}
            2. Open your Server Settings on Discord, head into the Integrations tab and click in
            "Create Webhook".
          </DialogContentText>
          <DialogContentText>3. Name your Webhook.</DialogContentText>
          <DialogContentText>4 Choose what channel the Webhook posts to.</DialogContentText>
          <DialogContentText>5. Copy the URL, and paste here.</DialogContentText>
          {field}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
      <Link
        href="#"
        onClick={() => {
          setShowInstructions(true);
        }}>
        Integration instructions
      </Link>
    </>
  );
};

export default DiscordChannelIntegrationInstructionsDialog;
