import { useEffect } from 'react';

import cloudProviders from 'constants/cloudProviders';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';

import ButtonSimple from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import slugify from 'helpers/slugify';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Environment } from 'views/V3/Products/New/WithAddons';

import AwsFields from './AwsFields/AwsFields';
import { useSubnetQuery } from './AwsFields/SubnetSelectAWS';
import { useVpcQuery } from './AwsFields/VpcSelect';
import AzureFields, { useVnet } from './AzureFields/AzureFields';
import useCustomUseForm from './customUseForm';
import mapDataToApi from './mapDataToApi';

const GetCloudsQuery = `#graphql
  query cloud($id: Float!) {
    clouds(page: 1, per_page: 1000, sort: "name,ASC", id: $id) {
      data {
        provider,
        name
      }
    }
  }
`;

const useCloudsQuery = (id?: number | null) => {
  return useQuery<{
    clouds: { data: { provider: string }[] };
  }>({
    query: GetCloudsQuery,
    pause: id === undefined,
    variables: { id }
  });
};

const useStyles = makeStyles(({ palette, zIndex, spacing }) => ({
  form: {
    maxWidth: 830,
    position: 'relative'
  },
  button: {
    marginTop: spacing(5)
  },
  serverError: {
    color: palette.error.main
  },
  backdrop: {
    zIndex: zIndex.drawer + 100
  }
}));

const defaultValues = {
  associatePublicIpAddress: '',
  provider: 'aws' as const
};

const CreateEnvironmentMutation = `#graphql
  mutation(
    $environmentInput: EnvironmentInput!
  ) {
    createEnvironment (
      environmentInput: $environmentInput
    ) {
      id
      agentToken
      status
    }
  }
`;

type CreateEnvironmentMutationResult = {
  createEnvironment: Pick<Environment, 'id' | 'agentToken' | 'status'>;
};

const Form = ({
  cloudId,
  onEnvironmentCreated,
  onSubmit
}: {
  cloudId?: number | null;
  onEnvironmentCreated: (environment: Environment) => void;
  onSubmit: () => void;
}) => {
  const classes = useStyles();

  const {
    control,
    register,
    errors,
    warnings,
    clearWarnings,
    clearErrors,
    setValue,
    setError,
    setWarning,
    watch,
    formState: { isValid },
    handleSubmit
  } = useCustomUseForm({
    defaultValues: {
      ...defaultValues,
      cloudId
    }
  });

  const region = watch('region');
  const vpcId = watch('vpc');
  const resourceGroup = watch('resourceGroup');
  const selectedVnet = watch('vnet');
  const selectedSubnetId = watch('subnet');
  const selectedSubnetAzure = watch('subnetName');

  const [resultCloud] = useCloudsQuery(cloudId);
  const [resultVpc] = useVpcQuery({ cloudId, region });
  const [resultSubnet] = useSubnetQuery({
    cloudId,
    region,
    vpcId
  });
  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [resultVnet] = useVnet({ cloudId, resourceGroup });

  const cloud = resultCloud?.data?.clouds.data[0];

  const vpc = resultVpc?.data?.vpc?.data?.find(
    (vpc: { VpcId: string; IsDefault: boolean; Tags: { Key: string; Value: string }[] }) =>
      vpc?.VpcId === vpcId
  );
  const subnet = resultSubnet?.data?.subnet?.data?.find(
    (subnet: { subnetId: string; subnetName: string }) => subnet?.subnetId === selectedSubnetId
  );

  const vnet = resultVnet?.data?.vnet?.data?.find(
    (vnet: {
      name: string;
      location: string;
      id: string;
      subnets: { name: string; id: string }[];
    }) => vnet?.name === selectedVnet
  );

  useEffect(() => {
    // TODO: This is a hack, we need to find a better way to
    // work with zod union types
    // @ts-ignore
    setValue('location', vnet?.location);
  }, [vnet, selectedVnet, setValue]);

  const subnetAzure = vnet?.subnets?.find(
    (subnet: { name: string; id: string }) => subnet?.name === selectedSubnetAzure
  );

  // eslint-disable-next-line
  const [_, createEnvironment] = useMutation<CreateEnvironmentMutationResult>(
    CreateEnvironmentMutation
  );

  useEffect(() => {
    if (cloud?.provider) {
      setValue('provider', cloud.provider);
    }
  }, [cloud, setValue]);

  if (resultCloud.fetching) {
    return <CircularProgress />;
  }

  const submitMutation = (formData: FormData) => {
    const mappedData = mapDataToApi({ ...formData, cloud, vpc, subnet, vnet, subnetAzure });

    createEnvironment({
      environmentInput: {
        ...mappedData,
        cloudId,
        newApplications: shouldUseNewApplications ? true : false
      }
    }).then(result => {
      if (result.error) return;

      if (result.data) {
        onEnvironmentCreated(result.data.createEnvironment);
      }
    });
  };

  return (
    <form className={classes.form} name="newEnvironment" onSubmit={handleSubmit(submitMutation)}>
      <Grid container spacing={3}>
        <input name="provider" ref={register} id="cloud-provider" type="hidden" />
        <Grid item xs={6}>
          <Controller
            name="name"
            control={control}
            render={({ onChange, ...otherProps }) => {
              return (
                <InputDefault
                  errors={errors}
                  inputRef={register}
                  label="Name"
                  name="name"
                  id="cloud-name"
                  required
                  handleChangeValue={(event: any) => {
                    onChange(slugify(event.target.value));
                  }}
                />
              );
            }}
          />
        </Grid>

        {cloud?.provider === cloudProviders.aws && (
          <AwsFields
            watch={watch}
            control={control}
            register={register}
            selectedCloudId={cloudId}
            selectedSubnetId={selectedSubnetId}
            region={region}
            vpcId={vpcId}
            errors={errors}
            warnings={warnings}
            setWarning={setWarning}
            setValue={setValue}
            setError={setError}
            clearWarnings={clearWarnings}
            clearErrors={clearErrors}
          />
        )}

        {cloud?.provider === cloudProviders.azure && (
          <AzureFields
            watch={watch}
            errors={errors}
            control={control}
            selectedCloudId={cloudId}
            resourceGroup={resourceGroup}
            selectedVnet={selectedVnet}
          />
        )}

        {cloud && (
          <Grid item xs={6}>
            <InputDefault
              errors={errors}
              inputRef={register}
              label="Tag *"
              multiline={false}
              name="tag"
            />
          </Grid>
        )}

        <Grid className={classes.button} xs={12} item>
          <ButtonSimple
            text="Validate environment and install agent"
            type="submit"
            color="primary"
            disabled={!isValid}
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
