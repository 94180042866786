import { ChangeEvent, useState } from 'react';

import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Add, Delete, Update } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { EventsFilters as Filters } from 'componentsV4/Filters';
import { useAuditTrailList } from 'hooks/queriesGraphQL/useAuditTrailList';
import useDebounce from 'hooks/useDebounce';

dayjs.extend(LocalizedFormat);

const TYPING_DEBOUNCE_TIME = 500; // 0.5s
const PER_PAGE_SIZE = 10;

// function PageActionButton(props: ButtonProps) {
//   const pageTitleSectionEl = document.getElementById('page-title-section')!;

//   if (!pageTitleSectionEl) return null;

//   return ReactDOM.createPortal(
//     <Button variant="contained" color="primary" startIcon={<IosShare />} {...props}>
//       Export to CSV
//     </Button>,
//     pageTitleSectionEl
//   );
// }

const useStyles = makeStyles(theme => {
  return {
    list: {
      '& > [class*="MuiListItem"]': {
        borderBottom: `1px solid ${theme.palette.gray[300]}`
      },
      '& [class*="MuiListItemIcon"]': {
        minWidth: 'auto',
        marginRight: theme.spacing(2)
      },
      '& [class*="MuiListItemText-secondary"]': {
        marginTop: theme.spacing()
      }
    }
  };
});

function getActionIcon(action: string) {
  return {
    CREATE: <Add />,
    UPDATE: <Update />,
    DELETE: <Delete />
  }[action];
}

export function TabAuditTrail() {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [customRange, setCustomRange] = useState('');
  const [period, setPeriod] = useState('all');

  const handleChangeSearch = useDebounce(
    (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    TYPING_DEBOUNCE_TIME
  );

  const { data, isLoading, total } = useAuditTrailList({
    page,
    perPage: PER_PAGE_SIZE,
    search,
    customRange,
    period
  });

  const hasData = total > 0;

  return (
    <Box mt={5}>
      {/* <PageActionButton /> */}

      <Filters
        handleChangeSearch={handleChangeSearch}
        onApplyPeriod={filters => {
          setPeriod(filters.period);
          setCustomRange('');
        }}
        onApplyCustomRange={filters => setCustomRange(filters.betweenValue)}
        onClearCustomRange={() => setCustomRange('')}
        hideFilters
      />

      <List classes={{ padding: classes.list }}>
        {!hasData && isLoading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress size="5rem" />
          </Box>
        )}
        {!hasData && !isLoading && <Typography align="center">No data</Typography>}

        {data.map(item => (
          <ListItem key={item.id}>
            <ListItemIcon>{getActionIcon(item.action)}</ListItemIcon>
            <ListItemText
              primary={item.title}
              secondary={dayjs(item.createdAt).format('L [at] LT')}
              primaryTypographyProps={{
                variant: 'h6',
                color: 'textPrimary'
              }}
              secondaryTypographyProps={{
                variant: 'body2'
              }}
            />
          </ListItem>
        ))}
      </List>

      {hasData && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            page={page}
            count={Math.ceil(total / PER_PAGE_SIZE)}
            defaultPage={1}
            size="large"
            color="primary"
            onChange={(_, value) => setPage(value)}
          />
        </Box>
      )}
    </Box>
  );
}
