import React from 'react';

import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';

import Clusters from '../Clusters';

const styles = theme => ({
  root: {},
  content: {
    marginTop: theme.spacing(1),
    borderTop: '2px solid #f4f4f4'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      aria-labelledby={`full-width-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

class TabPanels extends React.Component {
  render() {
    const { classes, environment: view, changeTab } = this.props;
    const value = view.tab;

    const handleChange = (event, newValue) => {
      changeTab(newValue);
    };

    const handleChangeIndex = index => {
      changeTab(index);
    };

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Tabs
            aria-label="full width tabs example"
            indicatorColor="primary"
            onChange={handleChange}
            textColor="primary"
            value={value}>
            <Tab label="Clusters" {...a11yProps(0)} />
          </Tabs>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel index={0} value={value}>
              <Clusters environment={view} />
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TabPanels);
