import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

const defaultValues = {
  domain: '',
  cloudId: '',
  check_type: '',
  method: '',
  headers: [{ key: '', value: '' }],
  post_body_raw: '',
  post_body_urlencoded: [{ key: '', value: '' }],
  post_body_type: postBodyTypeValues.raw,
  post_body_raw_type: postBodyRawTypeValues.json,
  skip_ssl_validation: false,
  queue_size: 0,
  busy_size: 0,
  scheduled_size: 0,
  connected_clients: 0,
  json_pubsub_body_raw: '',
  failuresToIncident: 2,
  timeout: '60',
  check_interval: '60',
  tls_renegotiation: 0,
  openIncidenteWithSeverity: 'not-classified'
};

export default defaultValues;
