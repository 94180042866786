import { Box, Backdrop, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import { ButtonSimple } from 'componentsV4/Button';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  }
}));

const DeleteEntity = ({
  open,
  disassociate = false,
  handleClose,
  disabled,
  children,
  entityName,
  agentInstalled,
  handleRemove,
  customTitle = '',
  isLoading,
  dependencyName = ''
}) => {
  const classes = useStyles();

  return (
    <>
      <OldDeleteDialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        disassociate={disassociate.toString()}
        title={disassociate ? `Disassociate ${entityName}` : `Delete ${entityName}`}
        fullWidth>
        {!disabled && (
          <Box paddingBottom={3}>
            <Typography variant="h6">
              {disassociate
                ? `Do you really want to disassociate this ${entityName}?`
                : `Do you really want to delete this ${dependencyName} ${entityName}?`}
            </Typography>
          </Box>
        )}
        {entityName === 'Environment' && disabled && agentInstalled ? (
          <Box paddingBottom={3}>
            <Typography variant="h6">Cannot remove because agent is installed</Typography>
          </Box>
        ) : null}
        {customTitle && (
          <Box paddingBottom={3}>
            <Typography variant="h6">{customTitle}</Typography>
          </Box>
        )}
        <Box>{children}</Box>
        <Box paddingTop={3} display="flex" justifyContent="flex-end">
          <ButtonSimple onClick={handleClose} variant="outlined" color="primary" text="Cancel" />

          <ButtonSimple
            style={{
              marginLeft: '1rem'
            }}
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={handleRemove}
            text="Confirm"
          />

          <Backdrop open={isLoading} className={classes.backdrop}>
            <CircularProgress color="primary" size={90} thickness={5} />
          </Backdrop>
        </Box>
      </OldDeleteDialog>
    </>
  );
};

export default DeleteEntity;
