import { Color as MuiColor } from '@material-ui/core';

type Color = Omit<MuiColor, 'A100' | 'A200' | 'A400' | 'A700'>;

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    green: Color;
    blue: Color;
    gray: Color;
    red: Color;
    yellow: Color;
    orange: Color;
    purple: Color;
  }
  interface PaletteOptions {
    green: Color;
    blue: Color;
    gray: Color;
    red: Color;
    yellow: Color;
    orange: Color;
    purple: Color;
  }
}

export const palette = {
  text: {
    primary: '#515151',
    secondary: '#262626',
    title: '#262626'
  },
  primary: {
    main: '#19A888',
    light: '#00FF42'
  },
  secondary: {
    main: '#0075FF'
  },
  green: {
    50: '#f1fcf9',
    100: '#cef9eb',
    200: '#9df2d8',
    300: '#63e5c1',
    400: '#34cda9',
    500: '#19a888',
    600: '#128f76',
    700: '#137260',
    800: '#145b4e',
    900: '#293328'
  },
  blue: {
    50: '#edfaff',
    100: '#d6f1ff',
    200: '#b5e9ff',
    300: '#83dcff',
    400: '#48c7ff',
    500: '#1ea7ff',
    600: '#0689ff',
    700: '#0075ff',
    800: '#0859c5',
    900: '#0d4e9b'
  },
  gray: {
    50: '#F6F6F6',
    100: '#EBEBEB',
    200: '#D5D5D5',
    300: '#C2C2C2',
    400: '#A5A5A5',
    500: '#898989',
    600: '#717171',
    700: '#515151',
    800: '#292929',
    900: '#262626'
  },
  red: {
    50: '#FFF1F0',
    100: '#FFCCC7',
    200: '#FFA39E',
    300: '#FF7875',
    400: '#FF4D4F',
    500: '#F5222D',
    600: '#CF1322',
    700: '#A8071A',
    800: '#820014',
    900: '#5C0011'
  },
  yellow: {
    50: '#FFEFD0',
    100: '#FFE1A6',
    200: '#FFD47E',
    300: '#FDC453',
    400: '#FAAD14',
    500: '#ECA417',
    600: '#DD9A14',
    700: '#C98D17',
    800: '#AC7912',
    900: '#855C0B'
  },
  orange: {
    50: '#F9B17D',
    100: '#F8A468',
    200: '#F79752',
    300: '#F68A3D',
    400: '#F57D27',
    500: '#DD7123',
    600: '#C4641F',
    700: '#AC581B',
    800: '#934B17',
    900: '#7B3F14'
  },
  purple: {
    50: '#B18FF9',
    100: '#9262F7',
    200: '#824CF5',
    300: '#7335F4',
    400: '#F57D27',
    500: '#4F19C2',
    600: '#3B1392',
    700: '#280C61',
    800: '#140631',
    900: '#0A0318'
  }
};
