import DataTable, { TableColumn } from 'react-data-table-component';
import { MemberType } from 'types/external/Member';

import { MenuActions } from '../MenuActions';

const MemberList = ({
  members,
  refetchList
}: {
  members: MemberType[];
  refetchList: () => void;
}) => {
  const columns: TableColumn<any>[] = [
    {
      name: 'Name',
      selector: row => row.name
    },
    {
      name: 'Role',
      selector: row => row.role.name
    },
    {
      name: 'Actions',
      right: true,
      cell: row => <MenuActions row={row} refetchList={refetchList} />
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={members}
      noDataComponent="No results found"
      highlightOnHover
      pagination
    />
  );
};

export default MemberList;
