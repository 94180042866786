import { Avatar, Box, Card, Divider, Link, Typography } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { Postmortem } from 'types/external/Postmortem';

import { StatusChip } from 'views/AlertCenter/components/StatusChip';

import { useStyles } from '../styles';

type DetailsProps = {
  postmortem: Postmortem;
};

export function Details({ postmortem }: DetailsProps) {
  const classes = useStyles({});

  const dateFormattedToPrint = dayjs(new Date(postmortem.createdAt)).format(
    'DD/MM/YYYY [at] HH:mm'
  );

  return (
    <>
      <div className="print">
        <Box display="flex" gridGap="0.5rem" alignItems="center" my="1rem">
          <Typography variant="h5" color="textPrimary">
            Written by {postmortem.owner.displayName} on {dateFormattedToPrint}
          </Typography>
        </Box>
      </div>

      <Card className={clsx(classes.card, 'no-print')}>
        <Box className={classes.cardContent}>
          <Typography variant="h5" className="title">
            Details
          </Typography>
          <Divider className={classes.cardDivider} />
          <Box display="flex" flexDirection="column" mt="1rem">
            <Typography variant="h5">Owner</Typography>
            <Box display="flex" gridGap="0.5rem" alignItems="center" my="1rem">
              <Avatar alt={postmortem.owner.displayName} src={postmortem.owner.photoURL} />
              <Typography variant="h5" color="textPrimary">
                {postmortem.owner.displayName}
              </Typography>
            </Box>
            <Typography variant="h5">Linked Incident</Typography>
            <Box display="flex" alignItems="center" marginTop="1rem">
              <StatusChip
                type="Severity"
                severityStatus={postmortem.incident.severity}
                isAlertStatus={false}
              />
              <StatusChip type="Event" status={postmortem.incident.status} isAlertStatus={true} />
              <Link
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                title={postmortem.incident.title}
                component={RouterLink}
                to={`/incidents/${postmortem.incidentId}`}>
                {postmortem.incident.title}
              </Link>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
}
