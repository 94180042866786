import { push } from 'connected-react-router';
import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareRemoveEntity(axios, action) {
  try {
    const {
      payload: { id },
      meta: { entity, query }
    } = action;
    const url = `/${entity}/${id}`;
    yield axios.request({ method: 'delete', url });
    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Entity removed successfully'
    });
    yield put({
      type: actions.FETCH_ENTITY,
      meta: {
        entity,
        query
      }
    });
    if (!action.meta.stayHere) {
      yield put(push(`/${entity}`));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchRemoveEntity(axios) {
  yield takeLatest(actions.DELETE_ENTITY, prepareRemoveEntity, axios);
}
