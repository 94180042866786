import { Box, Divider, Typography, Link } from '@material-ui/core';
import { urqlClient } from 'App';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { UserProvider } from 'types/external/UserProvider';

import useUpdateProfile from 'hooks/queriesGraphQL/useProfileV2';
import useProfile from 'hooks/useProfile';
import actions from 'redux/actions';
import ContactMethod, { Contact, Contacts } from 'views/Account/components/ContactMethod';
import FormProfile, { FormInputs as ProfileFormInputs } from 'views/Account/components/FormProfile';

import { useStyles } from '../../styles';

// const mockAppMethod = [{ method: 'app', value: 'Iphone 12', verified: true }];

const GeneralTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { profile } = useProfile();

  const { updateProfile } = useUpdateProfile();

  const profileFormHandleSubmit = (inputs: ProfileFormInputs) => {
    return updateProfile({ updateProfileInput: { displayName: inputs.name } }).then(result => {
      if (result?.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error to update profile' }
        });
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Profile sucessfully updated'
      });
    });
  };

  const { data } = useQuery(['userProviders'], {
    queryFn: () =>
      urqlClient
        .query<{ userProviders: UserProvider[] }>(
          `#graphql
              query {
                userProviders {
                  id
                  provider
                  editable
                  config {
                    value
                    verified
                  }
                }
              }
            `
        )
        .toPromise()
  });

  const contacts: Contacts = data?.data?.userProviders
    ? data?.data?.userProviders.map(userProvider => ({
        id: userProvider.id,
        active: true,
        editable: userProvider.editable,
        method: userProvider.provider as Contact['method'],
        value: userProvider.config.value,
        verified: userProvider.config.verified
      }))
    : [];

  const profileForm = useForm<ProfileFormInputs>({
    defaultValues: {
      name: profile?.displayName
    }
  });

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <Box
        component="form"
        onSubmit={profileForm.handleSubmit(profileFormHandleSubmit)}
        display="flex"
        gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <FormProfile form={profileForm} profile={profile} />
          </Box>
        </Box>
        <Box flex={1}></Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      <Typography className={classes.subTitle} variant="h3">
        Contact Methods
      </Typography>

      <Box display="flex" gridGap="3rem">
        <Box flex={2}>
          <Box display="flex" gridGap="2rem" flexDirection="column">
            <ContactMethod
              contacts={contacts}
              label="E-mail"
              type="email"
              buttonProps={{ label: 'Email' }}
            />
            <ContactMethod
              contacts={contacts}
              label="Voice"
              type="voice"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="WhatsApp"
              type="whatsapp"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="SMS"
              type="sms"
              buttonProps={{ label: 'Add phone number' }}
            />
            <ContactMethod
              contacts={contacts}
              label="Telegram"
              type="telegram"
              buttonProps={{ label: 'Telegram' }}
            />
          </Box>
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Support configuration
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Add contact methods that you would like to be notified at when an incident or alert is
            assigned to you. To delete or edit an email, SMS number, Voice number, or WhatsApp
            contact, please reach out to our{' '}
            <Link href="https://elven.works/suporte/" target="_blank" rel="noopener noreferrer">
              support team
            </Link>
            .
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.sectionDivider} />

      {/* <Box display="flex" gridGap="3rem">
        <Box display="flex" flexDirection="column" flex={2} gridGap={12}>
          <Typography className={classes.subTitle} variant="h3">
            Mobile App
          </Typography>
          {mockAppMethod ? (
            <ContactMethod
              strongLabel="App"
              disableEdit={true}
              contacts={[
                { method: 'app', value: 'Iphone 12', verified: true, active: true, editable: false }
              ]}
              type="app"
            />
          ) : (
            <DownloadAppView />
          )}
        </Box>
        <Box flex={1}>
          <Typography className={classes.infoSectionTitle} variant="subtitle2">
            Lorem ipsum dolor
          </Typography>
          <Typography className={classes.infoSectionDescription} variant="subtitle1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus ipsam perspiciatis itaque
            quos obcaecati ex debitis voluptates repellendus! Fugit tempora praesentium saepe nihil
            velit laudantium rerum harum nemo impedit voluptatum.
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default GeneralTab;
