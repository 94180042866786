import { z } from 'zod';

export const baseMonitoringSchema = {
  name: z.string().min(1, 'Name is required'),
  interval: z.coerce
    .number()
    .int()
    .min(
      Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING),
      `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
    ),
  timeout: z.coerce.number().min(1, `The timeout number must be greater than zero.`),
  environmentId: z.coerce.number().optional(),
  domain: z.string().optional(),
  domainType: z.string().optional()
};
