import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ typography, palette }) => ({
  root: {
    maxWidth: 'fit-content',
    position: 'relative'
  },
  paper: {
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.1)',
    margin: 0,
    color: '#586069',
    fontSize: typography.pxToRem(16),
    position: 'relative'
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.action.hover
    }
  },
  noOptions: {
    textAlign: 'center'
  },
  popperDisablePortal: {
    position: 'relative'
  },
  listbox: {
    marginTop: '5px'
  }
}));
