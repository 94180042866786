export enum Type {
  Internal = 'internal',
  External = 'external',
  Action = 'action'
}

export type MenuToolbar = {
  id: number;
  icon: string | null;
  link: string;
  title: string;
  allowed_roles: string[];
  type: Type;
  feature_flag: string;
};

type MenuHelp = {
  id: number;
  icon: string | null;
  link: string;
  title: string;
};

export type MenuSidebarCollection = {
  id: number;
  title: string;
  parent: number;
  href: string | null;
  icon: string | null;
  feature_flag: string;
  is_static: boolean;
  allowed_roles: ('member' | 'admin' | 'owner')[];
  plan_feature: string;
};

export type MenuSidebar = {
  id: number;
  href: string | null;
  icon: string | null;
  title: string;
  featureFlag?: string | null;
  isStatic: boolean;
  planFeature: string;
  children: {
    id: number;
    href: string;
    icon: string | null;
    title: string;
    featureFlag: string | null;
    planFeature: string;
  }[];
  allowedRoles: string[];
  type?: Type;
};

type Terms = {
  terms_content_md: string;
};

type SsoForm = {
  acs_url_helper_text: string;
  entity_id_helper_text: string;
  identifier_helper_text: string;
  reply_url_helper_text: string;
  google_workspace_info: string;
  microsoft_workspace_info: string;
};

type PlansPage = {
  url: string;
};

type QRCodeMobile = {
  app_store_qr_code_url: string;
  play_store_qr_code_url: string;
  app_store_img_url: string;
  play_store_img_url: string;
};

export type Collections = {
  menu_help: MenuHelp;
  menu_sidebar: MenuSidebarCollection;
  menu_toolbar: MenuToolbar;
  terms: Terms;
  SSO_Form: SsoForm;
  plans_page: PlansPage;
  qr_code_mobile: QRCodeMobile;
};
