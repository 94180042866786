import { useRef } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormHelperText, Typography, useTheme } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { MarkdownHelperText } from 'oldComponents/MarkdownHelperText';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { Input } from 'views/ServicesHub/components/Input';

import { SAMLConfigurationSection } from './SAMLConfigurationSection';
import {
  MicrosoftWorkspaceIntegrationFormType,
  microsoftWorkspaceIntegrationFormSchema
} from './schemas';
import useStyles from './styles';
import { useSSOForm } from './useSSOForm';

import { handleSelectFile } from '.';

const CreateMicrosoftSSOIntegrationMutation = `#graphql
  mutation($data: CreateMicrosoftSSOIntegrationInput!) {
    createMicrosoftSSOIntegration(data: $data) 
  }
`;

type MicrosoftWorkspaceIntegrationFormProps = {
  onCancel: () => void;
  handleCreateSSOIntegration: Function;
};

export function MicrosoftWorkspaceIntegrationForm({
  handleCreateSSOIntegration,
  onCancel
}: MicrosoftWorkspaceIntegrationFormProps) {
  const classes = useStyles();

  const theme = useTheme();

  const form = useForm<MicrosoftWorkspaceIntegrationFormType>({
    mode: 'onBlur',
    resolver: zodResolver(microsoftWorkspaceIntegrationFormSchema),
    defaultValues: {
      tenantId: '',
      clientId: '',
      clientSecret: '',
      groupId: ''
    }
  });

  const { register, setValue, handleSubmit, errors, watch } = form;

  const inputSigningCrendentialsFileRef = useRef<HTMLInputElement>(null);

  const hasSelectedSigningCredentials = watch('signingCertificate');

  const handleSelectSigningCertificateFile = handleSelectFile('signingCertificate', setValue);

  const [{ fetching: isCreatingSSOIntegration, error }, createSSOIntegration] = useMutation(
    CreateMicrosoftSSOIntegrationMutation
  );

  const { data: ssoFormData, isLoading: isLoadingSSOFormData } = useSSOForm();

  const isLoading = isLoadingSSOFormData || (!error && isCreatingSSOIntegration);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(4)}
      onSubmit={handleSubmit(handleCreateSSOIntegration(createSSOIntegration))}>
      {isLoading && <LoadingOverlay />}
      <Input
        className={classes.input}
        inputRef={register}
        name="signInURL"
        label="Sign In URL"
        helperText="Sign In URL generated on create a Microsoft Workspace App. Used to setup SAML connection"
        errors={errors}
        required
      />
      <Box position="relative" display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <input
          key={hasSelectedSigningCredentials}
          accept=".pem"
          ref={inputSigningCrendentialsFileRef}
          onChange={handleSelectSigningCertificateFile}
          style={{ display: 'none' }}
          type="file"
        />

        <Typography variant="body1" component="label" style={{ fontWeight: 500 }}>
          X509 Signing Certificate
        </Typography>

        <Typography>
          X509 Certificate generated on create a Microsoft Workspace SAML App. Used to setup SAML
          connection
        </Typography>

        <Input
          className={classes.input}
          inputRef={register}
          name="signingCertificate"
          label="X509 Signing Certificate"
          helperText="X509 Certificate generated on create a Microsoft Workspace SAML App. Used to setup SAML connection"
          data-visible={Boolean(hasSelectedSigningCredentials)}
          errors={errors}
          minRows={5}
          maxRows={10}
          multiline
          required
          InputProps={{
            readOnly: true
          }}
        />

        <Box my={1}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<CloudUploadOutlined />}
            onClick={() => inputSigningCrendentialsFileRef.current?.click()}>
            {hasSelectedSigningCredentials ? 'Change file' : 'Select file'}
          </Button>
        </Box>
        {errors.signingCertificate && (
          <FormHelperText error>{errors.signingCertificate.message}</FormHelperText>
        )}
      </Box>

      <SAMLConfigurationSection type="microsoft" />

      <Typography variant="h5" style={{ fontWeight: 500 }}>
        Workspace
      </Typography>

      <Box>
        <Typography gutterBottom>
          <MarkdownHelperText>{ssoFormData?.microsoft_workspace_info || ''}</MarkdownHelperText>
        </Typography>
      </Box>

      <Input
        className={classes.input}
        inputRef={form.register}
        name="tenantId"
        label="Your workspace tenant ID"
        helperText="It's will be used to authenticate in Microsoft Entra API"
        errors={form.errors}
        required={false}
      />
      <Input
        className={classes.input}
        inputRef={form.register}
        name="clientId"
        label="Client ID"
        helperText="It's will be used to authenticate in Microsoft Entra API"
        errors={form.errors}
        required={false}
      />
      <Input
        className={classes.input}
        inputRef={form.register}
        name="clientSecret"
        label="Client Secret"
        helperText="It's will be used to authenticate in Microsoft Entra API"
        errors={form.errors}
        required={false}
      />
      <Input
        className={classes.input}
        inputRef={form.register}
        name="groupId"
        label="Identifier of group to sync users"
        helperText="The users in this group will be synced in Elven Platform"
        errors={form.errors}
        required={false}
      />

      <Box display="flex" gridGap="1rem" mt={5}>
        <Button variant="outlined" color="primary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isCreatingSSOIntegration}>
          Save integration
        </Button>
      </Box>
    </Box>
  );
}
