import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import ShowDependencies from 'oldComponents/DeleteEntityDialog/components/ShowDependencies';

function DeleteModal({
  isDeleteModalOpen,
  handleCloseDeleteModal,
  handleDeleteApplication,
  isLoading,
  permissions,
  dependencies
}) {
  return (
    <DeleteEntity
      open={isDeleteModalOpen}
      handleClose={handleCloseDeleteModal}
      handleRemove={handleDeleteApplication}
      entityName="Application"
      isLoading={isLoading}
      disabled={
        !permissions.remove ||
        dependencies?.applicationIsADependencyOf?.length ||
        dependencies?.applicationDependencies?.applicationAddons?.length ||
        dependencies?.applicationDependencies?.servicesAddons?.length
          ? true
          : false
      }>
      {Boolean(
        dependencies?.applicationDependencies?.applicationAddons?.length ||
          dependencies?.applicationDependencies?.servicesAddons?.length
      ) && (
        <>
          <ShowDependencies
            dependencies={[
              {
                items: dependencies?.applicationDependencies?.applicationAddons,
                type: 'Application'
              },
              {
                items: dependencies?.applicationDependencies?.servicesAddons,
                type: 'Service'
              }
            ]}
            title="Cannot remove because there are dependencies"
          />
        </>
      )}
      {Boolean(dependencies?.applicationIsADependencyOf?.length) && (
        <ShowDependencies
          dependencies={[{ items: dependencies?.applicationIsADependencyOf, type: 'Application' }]}
          title="Cannot remove because this application is a dependency "
        />
      )}
    </DeleteEntity>
  );
}

export default DeleteModal;
