import { Chip, ChipProps } from '@material-ui/core';
import { Build, CloudUploadOutlined, Description, Restore, Update } from '@material-ui/icons';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';
import { SeverityStatusEnum } from 'types/external/Severity';

import { useStyles } from './styles';

type PrimaryChipProps = ChipProps & {
  event: EventType;
};

function getLabelBySeverityStatus(status: SeverityStatusEnum) {
  const statusLabelMap = {
    [SeverityStatusEnum.NotClassified]: 'Not Classified',
    [SeverityStatusEnum.Critical]: 'SEV 1 - Critical',
    [SeverityStatusEnum.High]: 'SEV 2 - High',
    [SeverityStatusEnum.Moderate]: 'SEV 3 - Moderate',
    [SeverityStatusEnum.Low]: 'SEV 4 - Low',
    [SeverityStatusEnum.Informational]: 'SEV 5 - Informational'
  };

  return statusLabelMap[status];
}

function getLabelByIncidentStatusUpdate(isPrivate: boolean) {
  return isPrivate ? 'Private incident status update' : 'Incident status update';
}

function getLabelByDeployType(event: EventType) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: getLabelBySeverityStatus(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified
    ),
    [EventTypeEnum.Acknowledged]: getLabelBySeverityStatus(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified
    ),
    [EventTypeEnum.Resolved]: getLabelBySeverityStatus(
      event.content?.severity ? event.content?.severity : SeverityStatusEnum.NotClassified
    ),
    [EventTypeEnum.PostMortem]: 'Post Mortem',
    [EventTypeEnum.Deploy]: 'Deploy',
    [EventTypeEnum.HotFix]: 'HotFix',
    [EventTypeEnum.Rollback]: 'Rollback',
    [EventTypeEnum.IncidentStatusUpdate]: getLabelByIncidentStatusUpdate(
      event.content?.private ? event.content?.private : false
    )
  };

  return labelMap[event?.type];
}

function getIcon(event: EventType, classes: any) {
  const labelMap = {
    [EventTypeEnum.Alarmed]: <span className={classes.ellipse}></span>,
    [EventTypeEnum.Acknowledged]: <span className={classes.ellipse}></span>,
    [EventTypeEnum.Resolved]: <span className={classes.ellipse}></span>,
    [EventTypeEnum.PostMortem]: <Description className={classes.icon} />,
    [EventTypeEnum.Deploy]: <CloudUploadOutlined className={classes.icon} />,
    [EventTypeEnum.HotFix]: <Build className={classes.icon} />,
    [EventTypeEnum.Rollback]: <Restore className={classes.icon} />,
    [EventTypeEnum.IncidentStatusUpdate]: <Update className={classes.icon} />
  };

  return labelMap[event?.type];
}

export function PrimaryChip({ event, ...props }: PrimaryChipProps) {
  const classes = useStyles({ event });

  return (
    <>
      {getIcon(event, classes)}
      <Chip
        variant="outlined"
        label={getLabelByDeployType(event)}
        className={classes.chip}
        {...props}
      />
    </>
  );
}
