import { push } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareSaveEntity(axios, action) {
  try {
    const state = yield select(state => state);
    const { id: teamId } = state.teams_view;
    const data = state.members_new.data[0];
    const payload = data.userIds.map(item => ({
      roleId: data.roleId,
      userId: item._source.userId,
      teamId
    }));
    yield axios.post(`/teams/${teamId}/add_members`, payload);
    yield put(push(`/teams/${teamId}`));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSaveEntity(axios) {
  yield takeLatest(actions.MEMBERS_SAVE_ENTITY, prepareSaveEntity, axios);
}
