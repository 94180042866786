import { getEnvUrl } from 'helpers/getEnvString';

const curlText = (setValues, data, application, deployType) => {
  if (data) {
    setValues({
      ...data,
      curl: `curl --request POST \\
      --url https://${getEnvUrl()}/external/v1/deploy/${application.uid}?token=${data.key} \\
      --header 'Content-Type: application/json' \\
      --header 'User-Agent: 1PwebhookDeliveries/1.0' \\
      --data '{
        "application_id": ${application?.id},
        "application_uid": "${application?.uid}",
        "organization": "${application?.org?.uid}",
        "description": "< Description >",
        "version": "< Version >",
        "type": "${deployType}"
      }'`
    });
  }
};

export default curlText;
