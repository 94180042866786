import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    minHeight: 100
  },
  title: {
    fontWeight: 700
  },
  helperText: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}));
