import { Fragment, useEffect, useState } from 'react';

import {
  postBodyTypeValues,
  PostBodyValues,
  ComparisonTypes,
  SourceTypes
} from 'constants/postBody';

import { Box, FormControlLabel, MenuItem, Switch, Typography, Button } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { ArrayField, Controller, useFieldArray, UseFormMethods } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { MultiFlowFormData } from 'views/ServicesHub/adapters/new/multiflow';
import { assertionDefaultValue } from 'views/ServicesHub/layouts/MultiFlow/defaultValues';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';
import { KeyValueField } from '../../../components/KeyValueField';

import { comparisonOptions } from './comparisonOptions';

interface Props extends FormProps {
  assertionActions: {
    fieldsAssertions: Partial<ArrayField<Record<string, any>, 'assertionId'>>[];
    addAssertions: (
      value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
      shouldFocus?: boolean
    ) => void;
    removeAssertions: (index?: number | number[]) => void;
  };
}

export function MultiFlowStepsForm({
  form,
  field,
  index,
  assertionActions: { addAssertions, fieldsAssertions, removeAssertions }
}: Props) {
  const [showOptionalRequest, setShowOptionalRequest] = useState(
    (field?.headers && field?.headers[0]?.key) || field?.validationString
  );

  const shouldUseSyntheticAssertions = useFeatureFlagUnleash('useSyntheticAssertions', {
    queryString: true
  });

  const { register, control, watch, errors: formErrors, setValue } = form as UseFormMethods<
    MultiFlowFormData
  >;

  const methodWatch = watch(`steps.${index}.method`);
  const postBodyTypeWatch = watch(`steps.${index}.postBodyType`);
  const rawTypeWatch = watch(`steps.${index}.rawType`);

  const { fields: fieldsHeaders, append: addHeaders, remove: removeHeaders } = useFieldArray({
    control,
    name: `steps.${index}.headers`
  });

  const { fields: fieldsPostBody, append: addPostBody, remove: removePostBody } = useFieldArray({
    control,
    name: `steps.${index}.postBodyUrlEnconded`
  });

  const errors = formErrors?.steps
    ? formErrors.steps.reduce((_steps, step, index) => {
        Object.keys(step!).forEach(key => {
          _steps[`steps.${index}.${key}`] = step![key as keyof typeof step];
        });

        return _steps;
      }, {} as Record<string, any>)
    : {};

  useEffect(() => {
    if (
      postBodyTypeWatch === postBodyTypeValues.raw &&
      rawTypeWatch === postBodyTypeValues.urlencoded
    ) {
      setValue(`steps.${index}.rawType`, PostBodyValues.json);
    }
  }, [postBodyTypeWatch, rawTypeWatch, index, setValue]);

  const setComparisonDefaultValue = ({
    index,
    fieldsAssertionsIndex,
    value
  }: {
    index: number | undefined;
    fieldsAssertionsIndex: number;
    value: string;
  }) => {
    switch (value) {
      case 'json_body':
        setValue(
          `steps.${index}.assertions.${fieldsAssertionsIndex}.comparison`,
          ComparisonTypes.Equals
        );
        break;
      case 'text_body':
        setValue(
          `steps.${index}.assertions.${fieldsAssertionsIndex}.comparison`,
          ComparisonTypes.Contains
        );
        break;
      case 'status_code':
        setValue(
          `steps.${index}.assertions.${fieldsAssertionsIndex}.comparison`,
          ComparisonTypes.EqualsNumber
        );
        break;
    }
  };

  const shouldDisableTargetValue = ({ comparison }: { comparison: ComparisonTypes }) => {
    const disableValues = [
      ComparisonTypes.IsEmpty,
      ComparisonTypes.IsNotEmpty,
      ComparisonTypes.IsNull,
      ComparisonTypes.IsNumber,
      ComparisonTypes.IsTrue,
      ComparisonTypes.IsFalse
    ];

    return disableValues.includes(comparison);
  };

  return (
    <>
      <Input
        errors={errors}
        label="Display step name"
        name={`steps.${index}.name`}
        inputRef={register()}
        defaultValue={field.name}
      />

      <InputWithSecrets
        control={control}
        defaultValue={field.healthcheckUrl}
        watch={watch}
        errors={errors}
        inputRef={register()}
        setValue={setValue}
        TextFieldProps={{
          label: 'Healthcheck URL',
          name: `steps.${index}.healthcheckUrl`,
          require: false
        }}
        InputComponent={Input}
      />

      <Input
        errors={errors}
        label="Timeout"
        name={`steps.${index}.timeout`}
        inputRef={register()}
        type="number"
        defaultValue={field.timeout}
      />

      <Box display="flex" gridGap="1rem">
        <Controller
          control={control}
          name={`steps.${index}.method`}
          defaultValue={field.method}
          render={({ value, onChange }) => {
            return (
              <Input
                errors={errors}
                label="Method"
                select
                fullWidth
                value={value}
                onChange={event => onChange(event.target.value)}>
                <MenuItem value="GET">GET</MenuItem>
                <MenuItem value="POST">POST</MenuItem>
              </Input>
            );
          }}
        />
        <Controller
          control={control}
          defaultValue={field.tlsRenegotiation}
          name={`steps.${index}.tlsRenegotiation`}
          render={({ value, onChange }) => {
            return (
              <Input
                errors={errors}
                label="TLS Renegotiation"
                select
                fullWidth
                value={value}
                onChange={event => onChange(event.target.value)}>
                <MenuItem value="0">Never</MenuItem>
                <MenuItem value="1">One Time</MenuItem>
                <MenuItem value="2">Always</MenuItem>
              </Input>
            );
          }}
        />
        <Controller
          control={control}
          defaultValue={field.skipSslValidation}
          name={`steps.${index}.skipSslValidation`}
          render={({ value, onChange }) => {
            return (
              <Input
                errors={errors}
                label="Skip SSL"
                select
                fullWidth
                value={value}
                onChange={event => onChange(event.target.value)}>
                <MenuItem value="false">False</MenuItem>
                <MenuItem value="true">True</MenuItem>
              </Input>
            );
          }}
        />
      </Box>

      {methodWatch === 'POST' && (
        <>
          <Box display="flex" gridGap="1rem">
            <Controller
              control={control}
              defaultValue={field.postBodyType}
              name={`steps.${index}.postBodyType`}
              render={({ value, onChange }) => {
                return (
                  <Input
                    errors={errors}
                    label="Post-Body Type"
                    select
                    fullWidth
                    value={value}
                    onChange={event => onChange(event.target.value)}>
                    <MenuItem value="raw">raw</MenuItem>
                    <MenuItem value="application/x-www-form-urlencoded">
                      application/x-www-form-urlencoded
                    </MenuItem>
                  </Input>
                );
              }}
            />

            {postBodyTypeWatch === 'raw' && (
              <Controller
                control={control}
                defaultValue={field.rawType}
                name={`steps.${index}.rawType`}
                render={({ value, onChange }) => {
                  return (
                    <Input
                      errors={errors}
                      label="Raw Type"
                      select
                      fullWidth
                      value={value}
                      onChange={event => onChange(event.target.value)}>
                      <MenuItem value={PostBodyValues.json}>JSON</MenuItem>
                      <MenuItem value={PostBodyValues.text}>Text</MenuItem>
                      <MenuItem value={PostBodyValues.javaScript}>Javascript</MenuItem>
                      <MenuItem value={PostBodyValues.xml}>XML</MenuItem>
                      <MenuItem value={PostBodyValues.html}>HTML</MenuItem>
                    </Input>
                  );
                }}
              />
            )}
          </Box>

          <Box display="flex" gridGap="1rem">
            {postBodyTypeWatch === 'raw' && methodWatch === 'POST' && (
              <Input
                errors={errors}
                label="Post Body"
                name={`steps.${index}.postBody`}
                inputRef={register()}
                multiline
                minRows={4}
                defaultValue={field.postBody}
              />
            )}
            {postBodyTypeWatch === 'application/x-www-form-urlencoded' &&
              methodWatch === 'POST' && (
                <KeyValueField
                  form={form}
                  name={`steps.${index}.postBodyUrlEnconded`}
                  fields={fieldsPostBody}
                  handleAdd={addPostBody}
                  handleRemove={removePostBody}
                  label="Post Body"
                  required={true}
                />
              )}
          </Box>
        </>
      )}

      <FormControlLabel
        label="Show Optional request"
        control={
          <Switch
            color="primary"
            value={showOptionalRequest}
            checked={showOptionalRequest}
            onChange={(_, val) => setShowOptionalRequest(val)}
          />
        }
      />

      <Typography hidden={!showOptionalRequest} variant="h4">
        Headers
      </Typography>

      <Box hidden={!showOptionalRequest}>
        <Input
          errors={errors}
          label="Validation String"
          name={`steps.${index}.validationString`}
          inputRef={register()}
          defaultValue={field.validationString}
          required={false}
        />
      </Box>

      <Box hidden={!showOptionalRequest}>
        <KeyValueField
          form={form}
          name={`steps.${index}.headers`}
          fields={fieldsHeaders}
          handleAdd={addHeaders}
          handleRemove={removeHeaders}
          label="Headers"
        />
      </Box>

      {shouldUseSyntheticAssertions && (
        <>
          <Typography variant="h5">Assertions</Typography>

          {fieldsAssertions.map((assertion: any, fieldsAssertionsIndex: number) => {
            const sourceWatch = watch(`steps.${index}.assertions.${fieldsAssertionsIndex}.source`);
            const comparisonWatch = watch(
              `steps.${index}.assertions.${fieldsAssertionsIndex}.comparison`
            );

            const propertyError =
              errors[`steps.${index}.assertions`] &&
              errors[`steps.${index}.assertions`][fieldsAssertionsIndex]?.property;

            const comparisonError =
              errors[`steps.${index}.assertions`] &&
              errors[`steps.${index}.assertions`][fieldsAssertionsIndex]?.comparison;

            return (
              <Fragment key={assertion.assertionId}>
                <Box display="flex" gridGap="1rem">
                  <Controller
                    control={control}
                    defaultValue={assertion.source}
                    name={`steps.${index}.assertions.${fieldsAssertionsIndex}.source`}
                    render={({ value, onChange }) => {
                      return (
                        <Input
                          errors={errors}
                          label="Source"
                          select
                          required={true}
                          fullWidth
                          value={value || ''}
                          onChange={event => {
                            onChange(event.target.value);
                            setComparisonDefaultValue({
                              index,
                              fieldsAssertionsIndex,
                              value: event.target.value
                            });
                          }}>
                          <MenuItem value={SourceTypes.JSONBody}>JSON Body</MenuItem>
                          <MenuItem value={SourceTypes.StatusCode}>Status Code</MenuItem>
                          <MenuItem value={SourceTypes.TextBody}>Text Body</MenuItem>
                        </Input>
                      );
                    }}
                  />

                  <Box>
                    <Input
                      error={propertyError && sourceWatch === 'json_body'}
                      label="Property"
                      disabled={sourceWatch !== 'json_body'}
                      required={true}
                      name={`steps.${index}.assertions.${fieldsAssertionsIndex}.property`}
                      inputRef={register()}
                      defaultValue={assertion.property}
                    />
                    {propertyError && sourceWatch === 'json_body' && (
                      <Typography color="error">
                        {
                          errors[`steps.${index}.assertions`][fieldsAssertionsIndex]?.property
                            .message
                        }
                      </Typography>
                    )}
                  </Box>

                  <Controller
                    control={control}
                    defaultValue={assertion?.comparison}
                    errors={errors}
                    name={`steps.${index}.assertions.${fieldsAssertionsIndex}.comparison`}
                    render={({ value, onChange }) => {
                      return (
                        <Input
                          errors={errors}
                          error={comparisonError}
                          helperText={comparisonError ? 'Select a valid option' : ''}
                          label="Comparison"
                          select
                          required={true}
                          fullWidth
                          value={value || ''}
                          onChange={event => onChange(event.target.value)}>
                          {comparisonOptions(sourceWatch).map(option => {
                            return <MenuItem value={option.value}>{option.label}</MenuItem>;
                          })}
                        </Input>
                      );
                    }}
                  />

                  <Input
                    errors={errors}
                    label="Target Value"
                    required={true}
                    disabled={shouldDisableTargetValue({
                      comparison: comparisonWatch as ComparisonTypes
                    })}
                    inputRef={register()}
                    defaultValue={assertion.targetValue}
                    name={`steps.${index}.assertions.${fieldsAssertionsIndex}.targetValue`}
                  />

                  <Box display="flex" height={40} gridGap={16}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => addAssertions(assertionDefaultValue)}>
                      <Add />
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => removeAssertions(fieldsAssertionsIndex)}>
                      <Delete />
                    </Button>
                  </Box>
                </Box>
                <input
                  ref={register()}
                  type="hidden"
                  name={`steps.${index}.assertions.${fieldsAssertionsIndex}.id`}
                  defaultValue={assertion.assertionId}
                />
              </Fragment>
            );
          })}
        </>
      )}

      <input ref={register()} type="hidden" name={`steps.${index}.id`} defaultValue={field.id} />
    </>
  );
}
