import { useEffect, useState } from 'react';

import { Button, Grid } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import { useDispatch } from 'react-redux';

import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';

import ServiceNowIcon from '../icons/ServiceNow';

import { useCloseIssue } from './useCloseIssue';
import { useCloseTicket } from './useCloseTicket';
import { useItsmHistory } from './useItsmHistory';
import { useItsmIntegrations } from './useItsmIntegration';
import { useOpenIssue } from './useOpenIssue';
import { useOpenTicket } from './useOpenTicket';
import { useUpdateTicket } from './useUpdateTicket';

const SelectIntegration = ({
  incidentId,
  title,
  description,
  stateIncident,
  severity,
  isResolved
}: {
  incidentId?: number;
  title?: string;
  description?: string;
  stateIncident?: string;
  severity?: string;
  isResolved?: boolean;
}) => {
  const { integrationUid, origin, fetching: isFetchingIntegrationUid } = useItsmIntegrations();

  const hasPermissionOpenServiceNow = usePermission(
    'ItsmIntegrationController-post-/serviceNow/openTicket'
  );
  const hasPermissionOpenJira = usePermission('ItsmIntegrationController-post-/jira/openIssue');

  const { state, fetching: isFetchingHistory } = useItsmHistory({
    incidentId: Number(incidentId)
  });

  const [isOpened, setIsOpen] = useState(isFetchingHistory ? false : state === 'opened');
  const [isClosed, setIsClosed] = useState(isFetchingHistory ? false : state === 'closed');

  useEffect(() => {
    setIsOpen(isFetchingHistory ? false : state === 'opened');
    setIsClosed(isFetchingHistory ? false : state === 'closed');
  }, [state, isFetchingHistory]);

  const { openTicket, fetching: isOpeningTicket } = useOpenTicket();
  const { updateTicket, fetching: isUpdatingTicket } = useUpdateTicket();
  const { closeTicket, fetching: isClosingTicket } = useCloseTicket();

  const { openIssue, fetching: isOpeningIssue } = useOpenIssue();
  const { closeIssue, fetching: isClosingIssue } = useCloseIssue();

  const dispatch = useDispatch();

  if (isFetchingIntegrationUid || isFetchingHistory || !integrationUid) {
    return null;
  }

  if (
    !incidentId ||
    isOpeningTicket ||
    isUpdatingTicket ||
    isClosingTicket ||
    isOpeningIssue ||
    isClosingIssue
  ) {
    return (
      <Grid item sm={4} xl={3}>
        <Button
          fullWidth
          variant="outlined"
          title="Open Ticket"
          startIcon={<ServiceNowIcon />}
          disabled>
          loading
        </Button>
      </Grid>
    );
  }

  const handleOpenTicket = async () => {
    openTicket({
      data: {
        incidentId,
        integrationUid: integrationUid || '',
        description: description || '',
        severity: severity || ''
      }
    }).then(result => {
      if (result.error || result.data?.openTicket.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to open ticket on service now.' }
        });
      } else {
        setIsOpen(true);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Servicenow successfully integrated'
        });
      }
    });
  };

  const handleCloseTicket = async () => {
    closeTicket({
      data: {
        incidentId,
        integrationUid: integrationUid || ''
      }
    }).then(result => {
      if (result.error || result.data?.closeTicket.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to close ticket on service now.' }
        });
      } else {
        setIsClosed(true);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Servicenow successfully integrated'
        });
      }
    });
  };

  const handleUpdateTicket = async () => {
    updateTicket({
      data: {
        incidentId,
        integrationUid: integrationUid || '',
        title: title || '',
        description: description || '',
        state: stateIncident || ''
      }
    }).then(result => {
      if (result.error || result.data?.updateTicket.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to update ticket on service now.' }
        });
      } else {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Servicenow successfully integrated'
        });
      }
    });
  };

  const handleOpenIssue = async () => {
    openIssue({
      data: {
        incidentId,
        integrationUid: integrationUid || '',
        title: title || '',
        description: description || ''
      }
    }).then(result => {
      if (result.error || result.data?.openIssue.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to open issue on jira.' }
        });
      } else {
        setIsOpen(true);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Jira successfully integrated'
        });
      }
    });
  };

  const handleCloseIssue = async () => {
    closeIssue({
      data: {
        incidentId,
        integrationUid: integrationUid || ''
      }
    }).then(result => {
      if (result.error || result.data?.closeIssue.success === false) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Failed to close issue on jira.' }
        });
      } else {
        setIsClosed(true);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Jira successfully integrated'
        });
      }
    });
  };

  const handleClick = async () => {
    if (origin === 'service-now') {
      if (!isResolved && !isOpened) {
        return handleOpenTicket();
      }

      if (!isResolved) {
        return handleUpdateTicket();
      }

      return handleCloseTicket();
    }

    if (origin === 'jira') {
      if (!isResolved && !isOpened) {
        return handleOpenIssue();
      }

      if (!isResolved) return;

      return handleCloseIssue();
    }
  };

  return (
    <Grid item sm={4} xl={3}>
      <Button
        fullWidth
        variant="outlined"
        title="Open Ticket"
        onClick={handleClick}
        startIcon={isOpened ? <UpdateIcon /> : <ServiceNowIcon />}
        disabled={isClosed || !hasPermissionOpenServiceNow || !hasPermissionOpenJira}>
        {!isOpened && !isClosed ? 'Open ticket' : 'Update Ticket'}
      </Button>
    </Grid>
  );
};

export default SelectIntegration;
