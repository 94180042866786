import { Box, CircularProgress } from '@material-ui/core';

import { Provider, ProvidersEnum, useGetProviders } from 'hooks/queriesGraphQL/useGetProvider';

import NotificationCard from './NotificationCard';
import { providerCards } from './NotificationCard/ProviderCardsList';

const IntegrationTab = ({ teamId }: { teamId: number }) => {
  const { data: providers, fetching, reexecuteQuery } = useGetProviders({ teamId });

  if (fetching) {
    return (
      <CircularProgress style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
    );
  }

  const getProvider = ({ providerName }: { providerName: ProvidersEnum }) => {
    const provider = providers?.find(result => result.providerName === providerName);

    if (provider) return { ...provider, teamId };

    return {
      teamId: teamId,
      providerName: providerName,
      enabled: false,
      config: {}
    } as Provider;
  };

  return (
    <Box display="flex" flexWrap="wrap" gridGap={20}>
      {providerCards.map((providerCard, index) => (
        <NotificationCard
          title={providerCard.title}
          description={providerCard.description}
          icon={providerCard.icon}
          provider={getProvider({ providerName: providerCard.providerName })}
          reexecuteQuery={reexecuteQuery}
        />
      ))}
    </Box>
  );
};

export default IntegrationTab;
