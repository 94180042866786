import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import axios from 'redux/axios';

import mapFormDataToApi from '../mapFormDataToApi';

import MonitoringForm from './MonitoringForm';

const MonitoringFormNew = ({ onClose, predefinedSettings, resourceType, productId }) => {
  const history = useHistory();

  const [submitMutation, { error: errorUseMutation, isLoading }] = useMutation(
    formData => {
      const data = mapFormDataToApi({
        formData,
        resourceType,
        productId,
        teamId: null,
        predefinedSettings
      });

      return axios.post(`/products/${productId}/applications`, data);
    },
    {
      onSuccess: response => {
        const applicationInfo = response.data.data;

        history.push(
          `/products/${applicationInfo.productId}/availability/applications/${applicationInfo.id}`
        );
      }
    }
  );

  return (
    <MonitoringForm
      submitMutation={submitMutation}
      errorUseMutation={errorUseMutation}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default MonitoringFormNew;
