import { useEffect } from 'react';

import { apmBase } from '@elastic/apm-rum';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useMutation as useMutationUrql } from 'urql';

import useQueryParams from 'helpers/useQueryParams';
import actions from 'redux/actions';
import axios from 'redux/axios';
import { analyticsEvent } from 'services/firebase/analytics';

import { activateMonitoringMutation } from './queries/activateMonitoring';
import { deactivateMonitoringMutation } from './queries/deactivateMonitoring';

const DeleteApplicationMutation = `#graphql
  mutation(
    $applicationId: Float!
    $productId: Float!
  ) {
    deleteApplication(applicationId: $applicationId, productId: $productId) {
      message
      success
    }
  }
`;

function useAppActions({
  isDeleteModalOpen,
  monitoringActive,
  setLoading,
  applicationType,
  reexecuteQueryUniqueResourceStatus,
  maintenance,
  submitDeleteMaintenance
}) {
  const { productId, applicationId } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();

  const queryParams = useQueryParams();

  const firstAccess = queryParams.has('firstAccess');
  const redirectedBy = queryParams.get('redirectedBy');

  const [, activateMonitoring] = useMutationUrql(activateMonitoringMutation);
  const [, deactivateMonitoring] = useMutationUrql(deactivateMonitoringMutation);

  useEffect(
    () => {
      const eventsMap = {
        internalMultiHttp: firstAccess
          ? 'fa_view_pg_monitoring_app_multi'
          : 'view_pg_monitoring_app_multi',
        externalWithoutAddons: firstAccess
          ? 'fa_monitoring_new_epwd_screen'
          : 'monitoring_new_epwd_screen',
        internal: firstAccess ? 'fa_monitoring_new_iaea_screen' : 'monitoring_new_iaea_screen',
        externalWithAddons: firstAccess
          ? 'fa_monitoring_new_eaea_screen'
          : 'monitoring_new_eaea_screen'
      };

      if (redirectedBy && eventsMap[redirectedBy]) {
        analyticsEvent(eventsMap[redirectedBy]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [{ fetching: isLoadingDeleteApplicationURQL }, deleteApplicationMutation] = useMutationUrql(
    DeleteApplicationMutation
  );

  const handleDeleteApplicationGraphQL = () => {
    if (maintenance?.id > 0) {
      submitDeleteMaintenance(maintenance);
    }

    deleteApplicationMutation({
      applicationId: Number(applicationId),
      productId: Number(productId)
    }).then(result => {
      if (result.error || !result.data)
        return dispatch({
          type: actions.ENTITY_ERROR,
          payload: result.error,
          ga: { category: 'ERROR' }
        });

      router.push(`/products/${productId}`);
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application removed successfuly'
      });
    });
  };

  const handleActiveMonitoring = () => {
    const eventsMap = {
      internalMultiHttp: firstAccess
        ? 'fa_button_active_monitoring_multi'
        : 'button_active_monitoring_multi',
      externalWithoutAddons: firstAccess
        ? 'fa_app_new_epwd_monitoring_button_on'
        : 'app_new_epwd_monitoring_button_on',
      internal: firstAccess
        ? 'fa_app_new_iaea_monitoring_button_on'
        : 'app_new_iaea_monitoring_button_on',
      externalWithAddons: firstAccess
        ? 'fa_app_new_eaea_monitoring_button_on'
        : 'app_new_eaea_monitoring_button_on'
    };

    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }

    activateMonitoring({
      applicationId: Number(applicationId),
      productId: Number(productId)
    }).then(async result => {
      if (result.error) {
        apmBase.captureError(new Error(`Error on activate monitoring: ${result.error}`));

        return;
      }

      dispatch({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId }
      });

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Activating monitoring for application...'
      });

      reexecuteQueryUniqueResourceStatus();
    });
  };

  const handleDeactiveMonitoring = () => {
    const eventsMap = {
      internalMultiHttp: firstAccess
        ? 'fa_button_active_monitoring_multi'
        : 'button_active_monitoring_multi',
      externalWithoutAddons: firstAccess
        ? 'fa_app_new_epwd_monitoring_button_off'
        : 'app_new_epwd_monitoring_button_off',
      internal: firstAccess
        ? 'fa_app_new_iaea_monitoring_button_off'
        : 'app_new_iaea_monitoring_button_off',
      externalWithAddons: firstAccess
        ? 'fa_app_new_eaea_monitoring_button_off'
        : 'app_new_eaea_monitoring_button_off'
    };

    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }

    deactivateMonitoring({
      applicationId: Number(applicationId),
      productId: Number(productId)
    }).then(result => {
      if (result.error) {
        apmBase.captureError(new Error(`Error on activate monitoring: ${result.error}`));

        return;
      }

      dispatch({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId, applicationId }
      });

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Deactivating monitoring for application...'
      });

      reexecuteQueryUniqueResourceStatus();
    });
  };

  const { data: dependencies, isLoading } = useQuery(
    ['applicationDependencies', applicationId],
    () =>
      axios
        .get(`/products/${productId}/applications/${applicationId}/dependencies`)
        .then(response => response.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: applicationType && isDeleteModalOpen
    }
  );

  const handleToggleMonitoring = () => {
    setLoading(true);
    if (monitoringActive) {
      handleDeactiveMonitoring();
    } else {
      handleActiveMonitoring();
    }
  };

  const { data: product } = useQuery(
    ['products', productId],
    () => axios.get(`/products/${productId}`).then(response => response.data.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const { user } = useSelector(({ user }) => ({
    user
  }));

  return {
    dependencies,
    isLoading: isLoading || isLoadingDeleteApplicationURQL,
    handleToggleMonitoring,
    product,
    user,
    productId,
    applicationId,
    handleDeleteApplicationGraphQL
  };
}

export default useAppActions;
