import { memo } from 'react';

import { MetabaseDashboards } from 'constants/metabaseDashboards';

import dayjs from 'dayjs';
import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

interface LatencyTabsProps {
  uid: string;
}

const LatencyTabs = (props: LatencyTabsProps) => {
  const uid = props.uid;

  const date = dayjs();

  return (
    <MetabaseDashboard
      title=""
      subtitle=""
      showSubtitle={false}
      disableQueryStringState
      iframeRefresh={60}
      iframeHeight={700}
      tabs={[
        {
          label: 'Latencies Percentiles',
          dashboardName: MetabaseDashboards.LatenciesPercentilesResources,
          dashboardParams: {
            uid,
            entity: 'resource',
            single_date: date.format('YYYY-MM-DD'),
            hour: [date.subtract(1, 'hour'), date.hour()]
          }
        },
        {
          label: 'Realtime Latencies',
          dashboardName: MetabaseDashboards.RealtimeLatenciesResources,
          dashboardParams: {
            uid,
            entity: 'resource',
            single_date: date.format('YYYY-MM-DD'),
            hour: [date.subtract(1, 'hour').hour(), date.hour()]
          }
        }
      ]}
    />
  );
};

export default memo(LatencyTabs);
