import { makeStyles, FormControlLabel, FormGroup } from '@material-ui/core';
import { Checkbox, Box } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';

import HelperIcon from 'componentsV3/HelperIcon';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 2,
    width: 16,
    height: 16,
    boxShadow: `inset 0 0 0 1px ${theme.palette.button.disabledMain}`,
    backgroundColor: '#fff',
    'input:hover ~ &': {
      backgroundColor: theme.palette.button.disabledLigth,
      boxShadow: `inset 0 0 0 1px ${theme.palette.button.disabledMain}`
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: green[400],
      boxShadow: `inset 0 0 0 1px ${green[400]}`
    }
  }
}));

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const CheckBoxDefault = ({ label, name, tooltip, inputRef, defaultChecked = false }) => {
  return (
    <FormGroup>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          style={{ marginRight: 0, marginLeft: -8 }}
          name={name}
          control={
            <StyledCheckbox inputRef={inputRef} name={name} defaultChecked={defaultChecked} />
          }
          label={label}
        />
        {tooltip && <HelperIcon tooltipText={tooltip} />}
      </Box>
    </FormGroup>
  );
};

export default withThemeV3Hoc(CheckBoxDefault);
