export const getEnvUrl = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      return 'apis.dev.elven.works';
    case 'staging':
      return 'apis.st.elven.works';
    default:
      return 'apis.elven.works';
  }
};

export const getAbreviatedEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      return '-dev';
    case 'staging':
      return '-st';
    default:
      return '';
  }
};
