import { Resource } from 'types/external/Resource';

import { RedisFormData } from 'views/ServicesHub/forms/monitoring/cache/Redis';

import { monitoringAdapter } from '../monitoring';

export function redisAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & RedisFormData {
  const domain = data.domain;
  //   //   ex1: HOST:PORT
  //   //   ex2 (cluster): HOST1:PORT1,HOST2:PORT2,HOST3:PORT3...
  //   //   ex3 (cluster): HOSTCONFIG:PORT

  if (data.domainSettings.domainType === 'url') {
    return {
      ...monitoringAdapter(data),
      hosts: [],
      user: data.domainSettings.user,
      password: data.domainSettings.password,
      databaseRedis: data.domainSettings.database,
      isCluster: data.domainSettings.isCluster,
      tls: data.domainSettings.tls,
      maxRetries: data.domainSettings.maxRetries,
      domain,
      domainType: data.domainSettings.domainType
    };
  }

  const hostsList = prepareHostAndPort(domain);

  return {
    ...monitoringAdapter(data),
    hosts: hostsList,
    user: data.domainSettings.user,
    password: data.domainSettings.password,
    databaseRedis: data.domainSettings.database,
    isCluster: data.domainSettings.isCluster,
    tls: data.domainSettings.tls,
    maxRetries: data.domainSettings.maxRetries,
    domain: '',
    domainType: data.domainSettings.domainType
  };
}

const prepareHostAndPort = (domain: string) => {
  const splitDomainsList = domain.split(',');

  const hostsList = splitDomainsList.map(item => {
    const [key, value] = item.split(':');
    return { key, value };
  });

  return hostsList;
};
