import { zodResolver } from '@hookform/resolvers/zod';
import { useForm as useReactHookForm } from 'react-hook-form';

import schema from './schema';

function useForm({ defaultValues }) {
  const useFormMethods = useReactHookForm({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'all'
  });

  return useFormMethods;
}

export default useForm;
