import * as z from 'zod';

import { domainSettingsValidation } from 'helpers/domainSettingsHelper';

const schema = z
  .object({
    domain: z.string().optional(),
    validationString: z.string().optional(),
    environmentId: z.any().nullable(),
    check_interval: z
      .string()
      .refine(
        check_interval => {
          try {
            if (
              Number(check_interval) >=
              Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
            ) {
              return true;
            }
          } catch {
            return false;
          }
        },
        {
          message: `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
        }
      )
      .optional(),
    timeout: z
      .string()
      .refine(
        timeout => {
          try {
            if (Number(timeout) >= 1) {
              return true;
            }
          } catch {
            return false;
          }
        },
        {
          message: `The timeout number must be greater than zero.`
        }
      )
      .optional(),
    method: z.string().nonempty(),
    headers: z.any(),
    post_body_raw: z.any(),
    post_body_urlencoded: z.any(),
    post_body_type: z.any(),
    post_body_raw_type: z.any(),
    skip_ssl_validation: z.boolean().optional(),
    tls_renegotiation: z.any().optional(),
    openIncidentWithSeverity: z.string().optional(),
    queue_size: z
      .string()
      .refine(
        querySize => {
          try {
            if (Number(querySize) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Queue size should be a positive number' }
      )
      .optional(),
    json_pubsub_body_raw: z.any(),
    ...domainSettingsValidation()
  })
  .refine(
    values => {
      const allowedValues = [1, 5, 10, 30];

      if (
        values.method &&
        values.method.includes('lambda') &&
        Number(values.check_interval) &&
        !(
          allowedValues.includes(Number(values.check_interval)) ||
          Number(values.check_interval) % 60 === 0
        )
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval must be a value in the set [ 1, 5, 10, 30 ] or any multiple of 60',
      path: ['check_interval']
    }
  )
  .refine(
    values => {
      const allowedValues = [1, 5, 10, 30];

      if (
        values.method &&
        values.method.includes('lambda') &&
        Number(values.timeout) &&
        !(allowedValues.includes(Number(values.timeout)) || Number(values.timeout) % 60 === 0)
      ) {
        return false;
      }

      return true;
    },
    {
      message: 'Timeout must be a value in the set [ 1, 5, 10, 30 ] or any multiple of 60',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.check_interval) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval number can only be up to six digits',
      path: ['check_interval']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Timeout number can only be up to six digits',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The timeout must not be greater than the interval',
      path: ['timeout']
    }
  )
  .refine(
    values => {
      if (Number(values.timeout) > Number(values.check_interval)) {
        return false;
      }

      return true;
    },
    {
      message: 'The interval must not be lesser than the timeout',
      path: ['check_interval']
    }
  )
  .refine(
    values => {
      if (values.method !== 'webhook' && values.domain?.length === 0) {
        return false;
      }

      return true;
    },
    {
      message: 'Should be at least 1 characters',
      path: ['domain']
    }
  )
  .refine(
    values => {
      if (values.method === 'functions' && values.validationString?.length === 0) {
        return false;
      }

      return true;
    },
    {
      message: 'Should be at least 1 characters',
      path: ['validationString']
    }
  );

export default schema;
