import { useMemo } from 'react';

import regionsAzure from 'constants/regionsAzure';

import { Grid } from '@material-ui/core';
import { useQuery } from 'urql';

import SelectSimple from 'componentsV3/Select';

import ResourceGroupSelect from './ResourceGroupSelect';
import SubnetSelectAzure from './SubnetSelectAzure';
import VnetSelect from './VnetSelect';

const GetVnetQuery = `#graphql
  query(
    $cloudId: Float!
    $resourceGroup: String!
  ) {
    vnet(
      page: 1
      per_page: 1000
      sort: "name,ASC"
      cloudId: $cloudId
      resourceGroup: $resourceGroup
    ) {
      data {
        name
        location
        subnets {
          name
        }
      }
    }
  }  
`;

export const useVnet = ({ cloudId, resourceGroup }) => {
  const [result] = useQuery({
    query: GetVnetQuery,
    pause: !cloudId || !resourceGroup,
    variables: {
      cloudId,
      resourceGroup
    }
  });

  return [result];
};

const AzureFields = ({ errors, control, selectedCloudId, resourceGroup, selectedVnet, watch }) => {
  const [result] = useVnet({ cloudId: selectedCloudId, resourceGroup });

  const { data, fetching } = result;

  const Vnets = useMemo(() => {
    if (fetching)
      return [
        {
          label: 'Loading...',
          value: ''
        }
      ];

    if (!data) return [];

    return data.vnet.data.map(({ name }) => ({
      label: name,
      value: name
    }));
  }, [data, fetching]);

  const subnets = useMemo(() => {
    if (!selectedVnet) return [];

    if (!data) return [];

    const vnet = data.vnet.data.find(({ name }) => name === selectedVnet);

    if (!vnet) return [];

    return vnet?.subnets.map(({ name }) => ({
      label: name,
      value: name
    }));
  }, [data, selectedVnet]);

  const resourceGroupSelect = watch('resourceGroup');
  const vnetSelect = watch('vnet');
  const subnetSelect = watch('subnet');

  return (
    <>
      <Grid item xs={6}>
        <ResourceGroupSelect
          errors={errors}
          control={control}
          selectedCloudId={selectedCloudId}
          required={true}
        />
      </Grid>

      <Grid item xs={6}>
        <VnetSelect
          disabled={!resourceGroupSelect}
          errors={errors}
          control={control}
          vnetList={Vnets}
          isLoading={fetching}
          required={true}
        />
      </Grid>

      <Grid item xs={6}>
        <SubnetSelectAzure
          disabled={!vnetSelect}
          errors={errors}
          control={control}
          subnetList={subnets}
          required={true}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectSimple
          disabled={!subnetSelect}
          errors={errors}
          name="location"
          control={control}
          label="Location"
          defaultValue=""
          items={regionsAzure}
          required
        />
      </Grid>
    </>
  );
};

export default AzureFields;
