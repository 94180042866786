export const CreateManualIncidentWithAlerts = `#graphql
mutation ($incidentManual: CreateIncidentManualInput!) {
  createIncidentManualV2(
    incident: $incidentManual
  ) {
    id
  }
}
`;

export const UpdateManualIncidentWithAlerts = `#graphql
mutation ($incidentManual: UpdateIncidentManualInput!) {
  updateIncidentManualV2(
    incident: $incidentManual
  ) {
    id
  }
}
`;
