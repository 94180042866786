import { Box } from '@material-ui/core';

import { RespondersSelect } from 'components/RespondersSelect';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Input } from 'views/ServicesHub/components/Input';
import { TeamsSelect } from 'views/ServicesHub/components/TeamsSelect';

import { FormProps } from '../../';

export function BaseForm({ form }: FormProps) {
  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });
  const { errors, register, control } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input
        label="External service name"
        name="name"
        inputRef={register}
        required={true}
        errors={errors}
        InputLabelProps={{
          shrink: true,
          style: {
            color: '#286A6B'
          }
        }}
      />

      {shouldUseRespondersInput ? (
        <RespondersSelect
          control={control}
          name="responders"
          required={false}
          multiSelect={true}
          InputLabelProps={{
            shrink: true,
            style: {
              color: '#286A6B'
            }
          }}
        />
      ) : (
        <TeamsSelect
          control={control}
          name="responders"
          required={false}
          multiSelect={true}
          InputLabelProps={{
            shrink: true,
            style: {
              color: '#286A6B'
            }
          }}
        />
      )}
    </Box>
  );
}
