import { Box, makeStyles, Typography } from '@material-ui/core';
import DropDownTeams from 'oldComponents/DropdownTeams/index';
import { Control } from 'react-hook-form';
import { useQuery, UseQueryState } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { analyticsEvent } from 'services/firebase/analytics';

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    marginTop: spacing(1)
  },
  input: {
    marginTop: 10,
    marginBottom: spacing(2)
  },
  icon: {
    marginTop: spacing(1),
    marginLeft: spacing(1)
  }
}));

const fromTeamsQuery = `#graphql
query{
  getTeamsByOrgUid(
  page: 1, 
  per_page: 100, 
  sort: "name,ASC"
){
  data{
    name,
    id,
  }
  }
}
`;

type Team = {
  id: number;
  name: string;
};

type Teams = {
  teams: {
    data: Team[];
  };
};

export type TeamsFromCore = {
  data: Teams;
};

type TeamsFromMsTeams = {
  getTeamsByOrgUid: {
    data: Teams;
  };
};

export const useMsTeamsQuery = () => {
  const [result, reexecuteQuery] = useQuery<TeamsFromMsTeams>({
    query: fromTeamsQuery
  });
  const resultTeams = {
    ...result,
    data: {
      teams: result.data?.getTeamsByOrgUid
    }
  };
  return [resultTeams, reexecuteQuery];
};

const TeamsAssign = ({
  result,
  control,
  setShowTeamsModal,
  firstAccess,
  applicationType
}: {
  result: UseQueryState<Teams>;
  control: Control;
  setShowTeamsModal: Function;
  firstAccess: Boolean;
  applicationType: string;
}) => {
  const hasTeams = Boolean(result.data?.teams?.data?.length);

  const classes = useStyles();

  function createNewTeamAnaliticsEvent() {
    const eventsMap: { [key: string]: string } = {
      internalMultiHttp: firstAccess ? 'fa_button_new_team_multi' : 'button_new_team_multi',
      webhook: firstAccess ? 'fa_new_team_hook' : 'new_team_hook',
      externalWithoutAddons: firstAccess
        ? 'fa_new_epdw_create_new_team_button'
        : 'new_epdw_create_new_team_button',
      internal: firstAccess
        ? 'fa_new_iaea_create_new_team_button'
        : 'new_iaea_create_new_team_button',
      externalWithAddons: firstAccess
        ? 'fa_new_eaea_create_new_team_button'
        : 'new_eaea_create_new_team_button'
    };

    if (eventsMap[applicationType]) {
      analyticsEvent(eventsMap[applicationType]);
    }
  }

  return (
    <>
      {result.fetching && <LoadingOverlay />}

      <Box style={{ display: result.fetching ? 'none' : undefined }}>
        <Typography className={classes.title} variant="body2">
          Assign a team to this product
        </Typography>
        <Typography className={classes.input} variant="body2">
          When creating a product, you need to define a team to manage it.
        </Typography>

        {hasTeams && (
          <DropDownTeams
            name="teams"
            control={control}
            teams={
              result.data?.teams?.data?.map(team => ({
                label: team.name,
                value: String(team.id),
                products: [],
                members: []
              })) || []
            }
            onClick={() => {
              setShowTeamsModal(true);
              createNewTeamAnaliticsEvent();
            }}
          />
        )}

        {!hasTeams && (
          <ButtonSimple
            onClick={() => {
              createNewTeamAnaliticsEvent();
              setShowTeamsModal(true);
            }}
            color="primary"
            disabled={false}
            text={'Create your first team'}
          />
        )}
      </Box>
    </>
  );
};

export default TeamsAssign;
