import applicationType from 'constants/ApplicationType';
import { ApplicationType } from 'constants/ApplicationType';

const getApplicationTypeTitle = (type: ApplicationType) => {
  const applicationTypeInfo = applicationType[type];

  return applicationTypeInfo ? applicationTypeInfo.title : 'Unknwon application type';
};

export default getApplicationTypeTitle;
