import { useQuery } from 'react-query';

import axios from 'redux/axios';

const useGetRoles = () => {
  const { data: rolesResponse } = useQuery(
    'roles/org',
    () => axios.get('/roles/org').then(response => response.data),
    {
      refetchOnWindowFocus: false
    }
  );
  const roles = rolesResponse?.data || [];

  return { roles };
};

export default useGetRoles;
