import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'must be provided' },
    email: true,
    length: {
      maximum: 200
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'must be provided' },
    length: {
      maximum: 128
    }
  }
  // policy: {
  //   presence: { allowEmpty: false, message: 'must be provided' },
  //   inclusion: {
  //     within: [true],
  //     message: 'Necessário aceitar os termos'
  //   }
  // }
};

const signup = (state, action) => {
  if (actions.SIGN_UP_CHANGE === action.type) {
    const signUp = state.signUp;
    const values = {
      ...signUp.values,
      ...action.payload
    };
    const errors = validate(values, schema);
    return {
      ...state,
      signUp: {
        isValid: !errors,
        values,
        errors: errors || {}
      }
    };
  }

  if (actions.SIGN_UP_INVALID_EMAIL === action.type) {
    const signUp = state.signUp;
    const values = signUp.values;
    return {
      ...state,
      signUp: {
        ...state.signUp,
        values,
        errors: { ...state.signUp.errors, email: [action.payload.message] }
      }
    };
  }

  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      ...action.payload
    };
  }

  return state;
};

export default signup;
