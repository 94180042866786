const encrypt = value => {
  try {
    // random initialization vector
    const iv = Crypto.randomBytes(16);

    // random salt
    const salt = Crypto.randomBytes(64);

    // derive key: 32 byte key length - in assumption the masterkey is a cryptographic and NOT a password there is no need for
    // a large number of iterations. It may can replaced by HKDF
    const key = Crypto.pbkdf2Sync(
      Buffer.from(process.env.REACT_APP_SALT),
      salt,
      2145,
      32,
      'sha512'
    );

    // AES 256 GCM Mode
    const cipher = Crypto.createCipheriv('aes-256-gcm', key, iv);

    // encrypt the given text
    const encrypted = Buffer.concat([cipher.update(value, 'utf8'), cipher.final()]);

    // extract the auth tag
    const tag = cipher.getAuthTag();

    // generate output
    return Buffer.concat([salt, iv, tag, encrypted]).toString('base64');
  } catch (e) {
    return null;
  }
};

export default encrypt;
