import * as z from 'zod';

export const redisSchemaValidation = () => {
  return {
    user: z.string().optional(),
    password: z.string().optional(),
    db: z
      .string()
      .refine(
        db => {
          try {
            if (Number(db) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Database number should be greater than zero' }
      )
      .optional(),
    max_retries: z
      .string()
      .refine(
        max_retries => {
          try {
            if (Number(max_retries) >= 0) {
              return true;
            }
          } catch {
            return false;
          }
        },
        { message: 'Max retries number should be greater than zero' }
      )
      .optional(),
    is_cluster: z.boolean().optional(),
    tls: z.boolean().optional()
  };
};
