import { ThemeProvider, createTheme } from '@material-ui/core';

import { getOverrides } from './overrides';
import { palette } from './palette';

const theme = createTheme({
  palette,
  typography: Object.assign(
    {},
    ...['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map(variant => ({
      [variant]: { color: palette.gray[900] }
    }))
  ),
  props: {
    MuiPaper: {
      elevation: 8
    },
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      SelectProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }
      }
    }
  }
});

theme.overrides = getOverrides(theme);

export type Theme = typeof theme;

interface WithThemeV5HocProps {}

export const withThemeV5 = <P extends WithThemeV5HocProps = WithThemeV5HocProps>(
  Component: React.ComponentType<P>
) => (props: Omit<P, keyof WithThemeV5HocProps>) => (
  <ThemeProvider theme={theme}>
    <Component {...(props as P)} />
  </ThemeProvider>
);

export default theme;
