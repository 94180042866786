import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import {
  CreateExternalServiceMutation,
  CreateExternalServiceV2Mutation
} from 'graphqlQueries/createExternalService';
import { UpdateExternalServiceMutation } from 'graphqlQueries/updateExternalService';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  ExternalIntegrationMutationResult,
  ExternalIntegrationMutationResultV2,
  ExternalServiceBaseForm,
  GuideParams,
  GuideType
} from 'types/external/ExternalService';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import {
  externalServiceAdapter,
  externalServiceOriginAdapter
} from 'views/ServicesHub/adapters/new/externalServices/externalServices';
import { LayoutType } from 'views/ServicesHub/forms';

import { BaseForm } from '../../forms/externalService/common/Base';

import { defaultValues } from './defaultValues';
import { Guide } from './Guide';
import { schema } from './schema';
import { useStyles } from './styles';
import { useStartValues } from './useStartValues';

export function ExternalServiceLayout({
  GuideProps,
  isEdit
}: {
  GuideProps: ({ orgId, orgUid, orgName }: GuideParams) => GuideType;
} & Pick<LayoutType, 'isEdit' | 'startValues'>) {
  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ uid: string }>();

  const [integrationUid, setIntegrationUid] = useState<string>('');

  const { startValues, fetching: fetchingStartValues } = useStartValues(params.uid);

  const form = useForm<any>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues
  });

  const { reset } = form;

  useEffect(() => {
    reset(startValues);

    if (startValues?.uid) {
      setIntegrationUid(startValues.uid);
    }
  }, [reset, startValues]);

  const [{ fetching: isCreating }, createExternalService] = useMutation<
    ExternalIntegrationMutationResult
  >(CreateExternalServiceMutation);

  const [{ fetching: isCreatingV2 }, createExternalServiceV2] = useMutation<
    ExternalIntegrationMutationResultV2
  >(CreateExternalServiceV2Mutation);

  const [{ fetching: isUpdating }, updateExternalService] = useMutation(
    UpdateExternalServiceMutation
  );

  const fetching = isCreating || isCreatingV2 || fetchingStartValues || isUpdating;

  const queryParams = useQueryParams();

  const [type, origin] = String(queryParams.get('type')).split('-');

  const createExternalIntegrationV1 = async (data: ExternalServiceBaseForm) => {
    const externalService = externalServiceAdapter(data);

    const externalServiceResponse = await createExternalService({
      data: {
        name: externalService?.name,
        responders: externalService?.responders,
        origin: externalServiceOriginAdapter(origin),
        type
      }
    });

    if (externalServiceResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on Create a External Service' }
      });

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'External Service has been created with successfully'
    });

    const createdIntegration = externalServiceResponse?.data?.createExternalIntegration;

    setIntegrationUid(createdIntegration?.uid || '');
  };

  const createExternalIntegrationV2 = async (data: ExternalServiceBaseForm) => {
    const externalServicePayload = externalServiceAdapter(data);

    const response = await createExternalServiceV2({
      data: {
        name: externalServicePayload?.name,
        responders: externalServicePayload?.responders,
        origin: externalServiceOriginAdapter(origin),
        type
      }
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on Create a External Service' }
      });

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'External Service has been created with successfully'
    });

    const createdIntegration = response?.data?.createExternalIntegrationV2;

    setIntegrationUid(createdIntegration?.uid || '');
  };

  const goToExternalServices = () => history.push('/external-services');

  const updateExternalIntegrationHandler = async (data: ExternalServiceBaseForm) => {
    const externalServicePayload = externalServiceAdapter(data);

    const response = await updateExternalService({
      uid: params.uid,
      data: {
        name: externalServicePayload?.name,
        responders: externalServicePayload?.responders
      }
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on Update a External Service' }
      });

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'External Service has been updated with successfully'
    });

    goToExternalServices();
  };

  const handleSubmit = (data: ExternalServiceBaseForm) => {
    if (isEdit) return updateExternalIntegrationHandler(data);

    if (!shouldUseRespondersInput) return createExternalIntegrationV1(data);

    return createExternalIntegrationV2(data);
  };

  return (
    <Box
      component="form"
      display="flex"
      gridGap="2.5rem"
      flexDirection="column"
      onSubmit={form.handleSubmit(handleSubmit)}
      position="relative">
      <Box>
        {fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h4">
          Basic informations
        </Typography>

        <Box display="flex" gridGap="12rem">
          <Box flex={0.7}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <BaseForm form={form} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button variant="outlined" color="primary" onClick={() => goToExternalServices()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isEdit && integrationUid?.length > 0}>
          {isEdit ? 'Save' : 'Create'}
        </Button>
      </Box>

      {integrationUid ? (
        <>
          <Divider className={classes.sectionDivider} />
          <Guide guide={GuideProps} integrationUid={integrationUid} />
        </>
      ) : null}
    </Box>
  );
}
