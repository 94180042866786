import actions from '../../../actions';

const fetchSuccessful = (state, action) => {
  if (actions.PRODUCT_TEAMS_AUTOCOMPLETE_FETCH_ENTITY_SUCCESSFUL === action.type) {
    const viewState = state.product_teams_view;
    return {
      ...state,
      product_teams_view: {
        ...viewState,
        autoComplete: {
          ...viewState.autoComplete,
          options: action.payload,
          loading: false
        }
      }
    };
  }

  return state;
};

export default fetchSuccessful;
