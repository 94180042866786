import DayJsUtils from '@date-io/dayjs';
import { Box, Typography, IconButton, Icon } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';

import useStyles from './styles';

export const FrequencyDay = ({
  field,
  index,
  register,
  checkDay,
  watch,
  changeEnabled,
  control
}: {
  field: any;
  index: number;
  register: any;
  watch: any;
  changeEnabled: (index: number, enabled: boolean) => void;
  checkDay: (numberDay: number) => string;
  control: any;
}) => {
  const classes = useStyles();

  const value = watch(`frequencies.${index}`);

  return (
    <Box mt={4}>
      <Box display="flex" alignItems="center">
        <input
          type="hidden"
          ref={register()}
          name={`frequencies.${index}.id`}
          defaultValue={field.id}
        />
        <input
          type="hidden"
          ref={register()}
          name={`frequencies.${index}.scheduledEventId`}
          defaultValue={field.scheduledEventId}
        />
        <input
          type="checkbox"
          ref={register()}
          name={`frequencies.${index}.enabled`}
          defaultChecked={field.enabled}
          style={{ display: 'none' }}
        />
        <input
          type="hidden"
          ref={register()}
          name={`frequencies.${index}.dayOfWeek`}
          defaultValue={field.dayOfWeek}
        />
        <Typography variant="h4" className={value.enabled ? classes.day : classes.dayDisabled}>
          {checkDay(Number(field.dayOfWeek))}
        </Typography>
        <Controller
          name={`frequencies.${index}.start`}
          control={control}
          defaultValue={field.start}
          render={props => {
            return (
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardTimePicker
                  className={classes.timePicker}
                  format="HH:mm"
                  inputVariant="outlined"
                  value={props.value}
                  ampm={false}
                  disabled={!value.enabled}
                  InputProps={{
                    classes: { input: classes.inputTimePicker }
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.inputAdornment }
                  }}
                  onChange={props.onChange}
                  error={false}
                  helperText=""
                />
              </MuiPickersUtilsProvider>
            );
          }}
        />
        <Typography
          variant="h5"
          className={value.enabled ? classes.middleText : classes.middleTextDisabled}>
          to
        </Typography>
        <Controller
          name={`frequencies.${index}.finish`}
          control={control}
          defaultValue={field.finish}
          render={props => {
            return (
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <KeyboardTimePicker
                  className={classes.timePicker}
                  format="HH:mm"
                  inputVariant="outlined"
                  value={props.value}
                  ampm={false}
                  disabled={!value.enabled}
                  InputProps={{
                    classes: { input: classes.inputTimePicker }
                  }}
                  InputAdornmentProps={{
                    classes: { root: classes.inputAdornment }
                  }}
                  onChange={props.onChange}
                  error={false}
                  helperText=""
                />
              </MuiPickersUtilsProvider>
            );
          }}
        />
        <IconButton
          title="Erase day"
          type="submit"
          className={classes.eraseDay}
          disabled={!value.enabled}
          onClick={() => changeEnabled(index, value?.enabled)}>
          <Icon>delete</Icon>
        </IconButton>
      </Box>
    </Box>
  );
};
