import { Resource } from 'types/external/Resource';
import URI from 'urijs';

import { RabbitmqFormData } from 'views/ServicesHub/forms/monitoring/queue/Rabbitmq';

import { monitoringAdapter } from '../monitoring';

export function rabbitmqAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & RabbitmqFormData {
  const domain = new URI(data.domain);

  const protocol = domain.protocol();
  const user = domain.username();
  const password = domain.password();
  const host = domain.hostname();
  const port = domain.port();
  const virtualhost = domain.path().replace('/', '');

  if (data.domainSettings.domainType === 'url') {
    return {
      ...monitoringAdapter(data),
      protocol: '',
      user: '',
      password: '',
      host: '',
      port: '',
      virtualhost: '',
      domainType: data.domainSettings.domainType,
      domain: data.domain
    };
  }

  return {
    ...monitoringAdapter(data),
    protocol,
    user,
    password,
    host,
    port,
    virtualhost,
    domainType: data.domainSettings.domainType,
    domain: ''
  };
}
