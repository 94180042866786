import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { PostgresFormData } from 'views/ServicesHub/forms/monitoring/database/Postgres';

import { monitoringAdapter } from '../monitoring';

export function postgresAdapter(data: MonitoringBaseForm & PostgresFormData) {
  const domain = prepareDomain(data);
  // ex: postgres://USER:PASSWORD@HOST:PORT/DATABASE

  return {
    ...monitoringAdapter({
      ...data,
      method: 'postgres'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringBaseForm & PostgresFormData) => {
  if (data.domainType === 'userAndPass') {
    const sslMode = data.sslMode ? `?sslmode=${data.sslMode}` : '';

    return `postgres://${data.username}:${data.password}@${data.host}:${data.port}/${data.database}${sslMode}`;
  }

  return data.domain;
};
