import { Chip } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';

import { useStyles } from './styles';

export type StatusChipProps = {
  status: MultiFlowStatusEnum;
};

function getLabelByStatus(status: MultiFlowStatusEnum) {
  const statusLabelMap = {
    [MultiFlowStatusEnum.Off]: 'Inactive',
    [MultiFlowStatusEnum.Success]: 'Success',
    [MultiFlowStatusEnum.Error]: 'Error',
    [MultiFlowStatusEnum.Pending]: 'Pending',
    [MultiFlowStatusEnum.Maintenance]: 'In maintenance'
  };

  return statusLabelMap[status];
}

export function StatusChip({ status }: StatusChipProps) {
  const classes = useStyles({ status });

  return <Chip className={classes.chip} label={getLabelByStatus(status)} />;
}
