import palette from '../theme/palette';

const inputsStateColors = {
  error: palette.error.main,
  warning: palette.warning.main,
  info: palette.info.main,
  success: palette.success.main,
  default: palette.inputBorder.default
};

export default inputsStateColors;
