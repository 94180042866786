import { TextField, Icon, Grid, IconButton, FormControl, useTheme } from '@material-ui/core';
import SecretsField from 'oldComponents/SecretsField';

const KeyValueFields = ({
  defaultValues,
  register,
  control,
  watch,
  setValue,
  label,
  fields,
  fieldAppend,
  fieldRemove,
  name,
  disabled,
  defaultValue,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <FormControl fullWidth size="small" variant="outlined" {...otherProps}>
      {fields.map((item, index) => (
        <Grid container spacing={1} key={item.id}>
          <Grid item container sm={10}>
            <TextField
              style={{ flexGrow: 1, marginRight: theme.spacing() }}
              variant="outlined"
              size="small"
              label={label}
              margin="dense"
              name={`${name}[${index}].key`}
              defaultValue={item.key}
              inputRef={register()}
              InputLabelProps={{
                shrink: true
              }}
              disabled={disabled}
            />

            <SecretsField
              TextFieldProps={{
                label: 'Value',
                name: `${name}[${index}].value`,
                fullWidth: false,
                style: { flexGrow: 1 }
              }}
              control={control}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
              defaultValue={item.value}
            />
          </Grid>

          <Grid item sm={2} container alignItems="center">
            {fields.length > 1 && (
              <IconButton
                color="primary"
                onClick={() => {
                  fieldRemove(index);
                }}
                disabled={disabled}>
                <Icon>delete</Icon>
              </IconButton>
            )}

            {index === fields.length - 1 && (
              <IconButton
                color="primary"
                onClick={() => fieldAppend({ key: '', value: '' })}
                disabled={disabled}>
                <Icon>add_circle</Icon>
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </FormControl>
  );
};

export default KeyValueFields;
