import { Rule, RuleActionType } from 'types/external/AlertCenter';

import { IncidentFormData } from 'views/ServicesHub/forms/monitoring/commom/Incident';

export function incidentsRulesAdapter(
  incidentRule?: Rule,
  respondersFieldOptions?: { value: string; label: string }[]
): Partial<IncidentFormData['incident'] & { uid: string }> {
  if (!incidentRule) {
    return {
      intervalHits: 120,
      intervalFailures: 120,
      failuresToOpenIncident: 2,
      hitsToCloseIncident: 2,
      severity: 'not-classified',
      teams: [],
      responders: [],
      enable: false
    };
  }

  const getLabel = ({
    team,
    options
  }: {
    team: { teamId: number };
    options: { value: string; label: string }[];
  }) => {
    const selected = options.find(option => {
      return Number(option.value) === team.teamId;
    });
    return selected ? selected.label : '';
  };
  const getRespondersLabel = ({
    responder,
    options
  }: {
    responder: { uid: string };
    options: { value: string; label: string }[];
  }) => {
    const selected = options.find(option => {
      return option.value === responder.uid;
    });
    return selected ? selected.label : '';
  };

  const intervalHits = incidentRule.actions.find(action => action.type === RuleActionType.Hits)
    ?.interval;
  const intervalFailures = incidentRule.actions.find(
    action => action.type === RuleActionType.Failures
  )?.interval;

  const failuresToOpenIncident = incidentRule.actions.find(
    action => action.type === RuleActionType.Failures
  )?.occurrence;
  const hitsToCloseIncident = incidentRule.actions.find(
    action => action.type === RuleActionType.Hits
  )?.occurrence;

  const teams = incidentRule.teams?.map(team => {
    return {
      value: String(team.teamId),
      label: respondersFieldOptions ? getLabel({ team, options: respondersFieldOptions }) : ''
    };
  });
  const responders = incidentRule.responders?.map(responder => {
    return {
      value: responder.uid,
      type: responder.type,
      helperText: responder.type,
      label: respondersFieldOptions
        ? getRespondersLabel({ responder, options: respondersFieldOptions })
        : ''
    };
  });

  return {
    intervalHits,
    intervalFailures,
    failuresToOpenIncident,
    hitsToCloseIncident,
    teams,
    responders,
    severity: incidentRule.severity,
    enable: true,
    uid: incidentRule.uid
  };
}
