import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(4)
  },
  infoSectionDescription: {
    color: theme.palette.gray[500]
  },
  sectionDivider: {
    border: `${theme.palette.grey[300]} 1px solid`
  }
}));
