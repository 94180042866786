import { usePermission } from 'hooks/usePermission';

import Secrets from '../Secrets';

export function TabSecrets() {
  const permissionsSecrets = {
    list: usePermission('SecretsController-get-/secrets'),
    new: usePermission('SecretsController-post-/addSecret'),
    remove: usePermission('SecretsController-delete-/removeSecret')
  };

  return (
    <>
      <Secrets permissions={permissionsSecrets} />
    </>
  );
}
