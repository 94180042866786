import { MultiFlow } from 'types/external/MultiFlow';
import { useQuery } from 'urql';

type UseMultiFlowParams = {
  page: number;
  perPage: number;
  query: string;
  pause?: boolean;
};

type UseMultiFlowData = {
  data: MultiFlow[];
  total: number;
};

const GetMultiFlowQuery = `#graphql
  query(
    $from: Int!,
    $size: Int!,
    $query: String!
  ) {
    multiflows(
      from: $from,
      size: $size,
      query: $query
    ) {
      data {
        id
        uid
        name
        status 
        environment {
          name        
        }       
      }
      total
    }
  }
`;

export function useMultiFlows({ page, perPage: size, query, pause = false }: UseMultiFlowParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ multiflows: UseMultiFlowData }>({
    query: GetMultiFlowQuery,
    variables: {
      from,
      size,
      query,
      pause
    },
    pause
  });
}
