import React from 'react';

import { Grid, Box, Typography, TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import TeamsIntegrationInstructionsDialog from '../IntegrationsInstructions/TeamsIntegrationInstructionsDialog';

import CheckboxField from './CheckboxField';

const TeamsField = ({ control, errors, field, index, register, setValue, disabled }) => {
  const error = errors.notificationSetup ? errors.notificationSetup[index] : {};

  const enabledFieldName = `notificationSetup[${index}].enabled`;

  const teamsController = (
    <Controller
      id={field.id}
      name={`notificationSetup[${index}].config.teamsWebhookUrl`}
      control={control}
      defaultValue={field.config.teamsWebhookUrl}
      render={props => {
        const { onChange, ...otherProps } = props;

        return (
          <TextField
            label="Webhook url"
            disabled={disabled}
            variant="outlined"
            margin="dense"
            fullWidth
            multiline
            rows={4}
            error={Boolean(error?.config?.teamsWebhookUrl)}
            helperText={error?.config?.teamsWebhookUrl?.message}
            onChange={event => {
              const value = event.target.value;

              setValue(enabledFieldName, Boolean(value));

              onChange(event.target.value);
            }}
            {...otherProps}
          />
        );
      }}
    />
  );

  return (
    <Grid xs={12} item style={{ margin: '5px 0' }}>
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].notificationSetupId`}
        value={field.notificationSetupId}
      />
      <input
        type="hidden"
        ref={register()}
        name={`notificationSetup[${index}].providerName`}
        value="Teams"
      />
      <CheckboxField
        name={enabledFieldName}
        control={control}
        defaultValue={field.enabled}
        disabled={disabled}>
        <img alt="ms teams" src="/images/teams.svg" height={22} />
        <Box margin="0 10px" minWidth="20vw">
          <Typography>
            Send a notification to Microsoft Teams following the{' '}
            <TeamsIntegrationInstructionsDialog field={teamsController} /> via
          </Typography>
        </Box>
        <Box minWidth="30vw">{teamsController}</Box>
      </CheckboxField>
    </Grid>
  );
};

export default TeamsField;
