import { useQuery as useQueryGraphQl } from 'urql';

import useUser from 'hooks/queriesRest/useUser';

const GetPlansOrgStatus = `#graphql
    query {
      orgStatus {
        show
        status
        message
        days                
      }
    }
  `;

const usePlans = () => {
  const { data: user } = useUser({});

  const [resultOrgStatus] = useQueryGraphQl({
    query: GetPlansOrgStatus,
    pause: !user?.logged
  });

  const dataFromGraphQl = resultOrgStatus.data?.orgStatus;

  const orgStatus = dataFromGraphQl?.show ? dataFromGraphQl : null;

  const showMessage = orgStatus?.show;

  return { orgStatus, showMessage };
};

export default usePlans;
