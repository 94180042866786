import dayjs from 'dayjs';
import { useQuery } from 'urql';

import { MetricsValueFormatEnum } from 'helpers/formatMetricsValue';

import { Metrics } from '../Metrics';

const GetOutagesInsightsQuery = `#graphql
    query(
      $startDate: String!
      $endDate: String!
    ){
        insights{
            monitoringPerformance {
                outages(startDate: $startDate, endDate: $endDate){
                    period
                    compared
                }
            }
        }
    }
`;

export const OutagesMetrics = () => {
  const endDate = dayjs();
  const startDate = dayjs().subtract(7, 'day');

  const [{ fetching, data }] = useQuery({
    query: GetOutagesInsightsQuery,
    variables: {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  });

  const outages = data?.insights?.monitoringPerformance?.outages;

  return (
    <Metrics
      name="Outages"
      value={outages?.period}
      valueFormat={MetricsValueFormatEnum.Count}
      tooltipTitle="Number of interruptions in your monitoring."
      isFetching={fetching}
      diff={outages?.compared}
      diffFormat={MetricsValueFormatEnum.Percentile}
      startDate={startDate.toDate()}
      endDate={endDate.toDate()}
    />
  );
};
