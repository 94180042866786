import { format } from 'date-fns';
import { EventsHistoryType, EventType } from 'types/external/EventsHistory';

export const organizeEventsHistoryList = ({
  eventsHistoryList,
  showLastDays = true
}: {
  eventsHistoryList: EventType[];
  showLastDays?: boolean;
}) => {
  const organizedEventsByDate: { [date: string]: EventType[] } = {};

  eventsHistoryList.forEach((item: EventType) => {
    const date = format(new Date(item.createdAt), 'MMM d, u');

    if (!organizedEventsByDate[date]) {
      organizedEventsByDate[date] = [];
    }

    organizedEventsByDate[date].push(item);
  });

  const organizedEvents: EventsHistoryType[] = [];

  for (const date in organizedEventsByDate) {
    organizedEvents.push({ date, events: organizedEventsByDate[date] });
  }

  for (const entry in organizedEvents) {
    organizedEvents[entry].events.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }

  if (showLastDays) {
    const fillingEvents = fillList(organizedEvents);

    return fillingEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  const sortedEventsHistory = organizedEvents.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  return sortedEventsHistory;
};

const fillList = (eventsList: EventsHistoryType[]) => {
  for (let i = 0; i < 5; i++) {
    const date = format(new Date().setDate(new Date().getDate() - i), 'MMM d, u');

    const result = eventsList.filter(
      (event: EventsHistoryType) => format(new Date(event?.date), 'MMM d, u') === date
    );

    if (!result.length) {
      eventsList.push({ date, events: [] });
    }
  }

  return eventsList;
};
