import { FunctionComponent } from 'react';

import { useMutation } from 'urql';

import {
  CreatePostMortemData,
  CreatePostMortemVariables,
  CreatePostMortem
} from './CreatePostMortem';
import {
  UpdatedPostMortemData,
  UpdatePostMortem,
  UpdatePostMortemVariables
} from './UpdatePostMortem';

function PostMortemFormV2({ Component }: { Component: FunctionComponent<any> }) {
  const useUpdateMutation = () =>
    useMutation<UpdatedPostMortemData, UpdatePostMortemVariables>(UpdatePostMortem);

  const useCreateMutation = () =>
    useMutation<CreatePostMortemData, CreatePostMortemVariables>(CreatePostMortem);

  const props = { useUpdateMutation, useCreateMutation };

  return <Component {...props} />;
}

export default PostMortemFormV2;
