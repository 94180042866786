import { useHasPermission } from './permissions/hasPermission';
import useUser from './queriesRest/useUser';

type UseProductPermissionParams = {
  target: string;
  productId: string;
};

export function useProductPermission({ target, productId }: UseProductPermissionParams) {
  const { data: user } = useUser();

  const hasPermission = useHasPermission({
    userId: user?.id,
    target,
    productId: Number(productId)
  });

  return hasPermission;
}
