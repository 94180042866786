import { put, takeLatest, select } from 'redux-saga/effects';

import actions from '../../actions';
import crudReducer from '../../reducer/logged';
import reducer from '../../reducer/signUpValidation';

function* resetSubmit(axios, action) {
  try {
    const reset_password = yield select(state => state.reset_password);
    const {
      data: { data }
    } = yield axios.request({
      method: 'post',
      url: '/auth/reset',
      data: reset_password.values
    });

    yield put({
      type: actions.FETCH_ENTITY_SUCCESSFUL,
      meta: {
        reducer: crudReducer
      },
      payload: {
        submit_success: data,
        signUpError: null
      }
    });
  } catch (err) {
    yield put({
      type: actions.SIGN_UP_FAILED,
      payload: err,
      meta: {
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchResetSubmit(axios) {
  yield takeLatest(actions.RESET_PASSWORD, resetSubmit, axios);
}
