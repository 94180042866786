import { ChangeEvent, useState } from 'react';

import { MenuItem, TextField, Box, Typography } from '@material-ui/core';
import { FiltersPopper, FiltersPopperProps } from 'oldComponents/FiltersPopper';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';
import { ResourceStatusEnum } from 'types/external/Resource';

import { useStyles } from './styles';

export type PageFilters = {
  status: 'all' | ResourceStatusEnum;
};

export type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  handleClose: () => void;
} & Pick<FiltersPopperProps, 'anchorEl'>;

export function Filters({ anchorEl, onApplyFilters, onClearFilters, handleClose }: FiltersProps) {
  const classes = useStyles();

  const [status, setStatus] = useState<ResourceStatusEnum | 'all'>('all');

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as ResourceStatusEnum);
  };

  const handleApplyFilters = () => {
    onApplyFilters({ status });
    handleClose();
  };

  const handleClearFilters = () => {
    setStatus('all');
    onClearFilters();
    handleClose();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <Box display="flex" justifyContent="space-between" gridGap={12}>
        <TextField
          name="statusFilter"
          label="Status"
          variant="outlined"
          onChange={handleChangeStatus}
          value={status}
          InputProps={{
            className: classes.input
          }}
          InputLabelProps={{
            shrink: true,
            color: 'secondary'
          }}
          select
          fullWidth>
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={ResourceStatusEnum.Available}>
            Available &nbsp;
            <Typography variant="caption" className={classes.caption}>
              Resource
            </Typography>
          </MenuItem>
          <MenuItem value={ResourceStatusEnum.Unavailable}>
            Unavailable &nbsp;
            <Typography variant="caption" className={classes.caption}>
              Resource
            </Typography>
          </MenuItem>
          <MenuItem value={MultiFlowStatusEnum.Success}>
            Success &nbsp;
            <Typography variant="caption" className={classes.caption}>
              Synthetic
            </Typography>
          </MenuItem>
          <MenuItem value={MultiFlowStatusEnum.Error}>
            Error &nbsp;
            <Typography variant="caption" className={classes.caption}>
              Synthetic
            </Typography>
          </MenuItem>
          <MenuItem value={ResourceStatusEnum.Off}>Off</MenuItem>
          <MenuItem value={ResourceStatusEnum.Pending}>Pending</MenuItem>
          <MenuItem value={ResourceStatusEnum.Maintenance}>Maintenance</MenuItem>
        </TextField>
      </Box>
    </FiltersPopper>
  );
}
