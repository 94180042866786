import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';

import StepsGroups from '../StepsGroups';

const FormEdit = ({
  timeSelected,
  setTimeSelected,
  dataSelected,
  color,
  setColor,
  saveOption,
  setSaveOption,
  groupId,
  setGroupId,
  teamId,
  refetchSchedules,
  permissions
}) => {
  const handleChangeTime = event => {
    event.persist();
    setTimeSelected(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled={!permissions.editCallRotation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          type="date"
          label="Start date"
          margin="dense"
          value={dataSelected?.start || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled={!permissions.editCallRotation}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            readOnly: true
          }}
          type="date"
          label="End date"
          margin="dense"
          value={
            dataSelected
              ? dayjs(dataSelected.start)
                  .add(
                    new Date(`2021-01-01 ${timeSelected.end}`) <=
                      new Date(`2021-01-01 ${timeSelected.start}`)
                      ? 1
                      : 0,
                    'day'
                  )
                  .format('YYYY-MM-DD')
              : ''
          }
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled={!permissions.editCallRotation}
          InputLabelProps={{
            shrink: true
          }}
          type="time"
          label="Start time"
          margin="dense"
          name="start"
          onChange={handleChangeTime}
          value={timeSelected?.start || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          fullWidth
          disabled={!permissions.editCallRotation}
          InputLabelProps={{
            shrink: true
          }}
          type="time"
          label="End time"
          margin="dense"
          name="end"
          onChange={handleChangeTime}
          value={timeSelected?.end || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Box minWidth={360} marginRight={1}>
          <StepsGroups
            fetchSchedulesList={refetchSchedules}
            key={groupId}
            onChange={setGroupId}
            selectedStepsGroupId={groupId}
            permissions={permissions}
          />
          <Typography>
            <Link component={RouterLink} to={`/teams/${teamId}/stepGroups`}>
              View your Step Groups
            </Link>
          </Typography>
        </Box>

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          type="color"
          label="Schedule color"
          margin="dense"
          name="color"
          value={color || ''}
          onChange={event => setColor(event.target.value)}
          variant="outlined"
          disabled={!permissions.editCallRotation}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Schedule saving options</FormLabel>
          <RadioGroup
            name="save"
            value={saveOption}
            onChange={event => setSaveOption(event.target.value)}>
            <FormControlLabel
              value="this"
              control={<Radio color="primary" />}
              label="save this schedule"
            />
            <FormControlLabel
              value="next"
              control={<Radio color="primary" />}
              label="save this and following schedules"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormEdit;
