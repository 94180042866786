import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography, makeStyles, useTheme } from '@material-ui/core';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    width: '100%',
    '&:has(div)': {
      padding: '0 !important'
    },
    '& h1': {
      fontSize: '6.25rem',
      lineHeight: '100%',
      fontWeight: 'normal'
    },
    '& h2': {
      fontSize: '3rem',
      lineHeight: '100%',
      whiteSpace: 'nowrap'
    },
    '& img': {
      marginTop: theme.spacing(2)
    },
    '& div p': {
      color: 'white'
    },
    '& button': {
      marginTop: theme.spacing(3),
      textTransform: 'uppercase'
    }
  }
}));

export function LoginErrorView() {
  const classes = useStyles();
  const theme = useTheme();

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Box className={classes.root} display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h1">Ops!</Typography>
      <Typography variant="h4" align="center">
        Something went wrong
      </Typography>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyItems="center"
        flexDirection="column"
        py={5}
        mt={4}
        bgcolor={theme.palette.green[400]}>
        <Typography align="center">
          It seems like there might be an issue with your session. Please try logging in again{' '}
          <br />
          from the application. If the problem persists, please contact the administrator.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => loginWithRedirect()}
          title="Return to login">
          Return to login
        </Button>
      </Box>
      <img src="/images/undraw_faq.svg" alt="Not found" />
    </Box>
  );
}
