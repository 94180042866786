import { User } from '../../new/user';

export function editUserAdapter(data: { users: User[] }) {
  const { users } = data;

  return {
    userId: users[0]?.id,
    isActive: users[0]?.status === 'active',
    roleId: users[0]?.role
  };
}
