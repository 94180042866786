import { useQuery } from 'urql';

const useGetGenericQuery = ({
  query,
  search,
  page,
  per_page,
  sort,
  params
}: {
  query: string;
  search: string;
  page: number;
  per_page: number;
  sort: string;
  params: any;
}) => {
  const [{ fetching, data }, executeQuery] = useQuery<{ data: { item: any } }>({
    query: query,
    variables: {
      page,
      per_page,
      sort,
      query: search,
      ...params
    }
  });
  return {
    executeQuery,
    isFetching: fetching,
    data: Object.values(data || { data: [] })[0]?.data,
    total: Object.values(data || { total: 0 })[0]?.total
  };
};

export default useGetGenericQuery;
