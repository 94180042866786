import React from 'react';

import { Grid, Typography, Paper, TextField, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab-old';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import EnvironmentDialog from './ApplicationEnvironmentDialog';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  zuruca: {},
  glenio: {
    '& > fieldset': {
      border: 0
    },
    '& > input': {
      padding: '6px 0 7px'
    }
  }
}));

const SearchInput = ({
  className,
  style,
  view,
  handleOpen,
  autoCompleteHandleChange,
  placeholder,
  addHandler,
  disabled,
  currentEnvironment,
  addHandlerUniqueResource,
  ...rest
}) => {
  const { open, options, loading, searchText } = view.autoComplete;

  const classes = useStyles();

  const [isOpenDialogEnvironment, setOpenDialogEnvironment] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();

  const handleOpenEnvironmentDialog = React.useCallback(() => {
    setOpenDialogEnvironment(!isOpenDialogEnvironment);
  }, [setOpenDialogEnvironment, isOpenDialogEnvironment]);

  const handleChangeEnvironmentDialog = value => {
    if (!value?._source?.environmentId) {
      return addHandler(value);
    }

    if (value?._source?.environmentId !== currentEnvironment) {
      setSelectedValue(value);
      return handleOpenEnvironmentDialog();
    }

    return addHandler(value);
  };

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Autocomplete
        disabled={disabled}
        className={classes.input}
        disableClearable
        disableOpenOnFocus
        filterOptions={x => x}
        freeSolo
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        includeInputInList
        inputValue={searchText}
        loading={loading}
        onChange={(event, value) => {
          handleChangeEnvironmentDialog(value);
        }}
        onClose={() => {
          handleOpen(false);
        }}
        onOpen={() => {
          handleOpen(true);
        }}
        open={open}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            autoComplete="false"
            fullWidth
            InputProps={{
              ...params.InputProps,
              className: classes.glenio,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            onChange={event => {
              autoCompleteHandleChange(event.target.value);
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
        renderOption={option => {
          const env =
            option._source.type === 'application' ? ` - ${option._source.environmentName}` : '';
          return (
            <Grid alignItems="center" container>
              <Grid item xs>
                <Typography color="textSecondary" variant="body2">
                  {option._source.type.toUpperCase()}:{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: option.highlight.name[0]
                    }}
                  />
                  {env}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <EnvironmentDialog
        handleOpenDialog={handleOpenEnvironmentDialog}
        isOpen={isOpenDialogEnvironment}
        selectedValue={selectedValue}
        addHandlerUniqueResource={addHandlerUniqueResource}
      />
    </Paper>
  );
};

export default SearchInput;
