import { Box, Modal, Typography, Divider } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    display: 'block'
  },
  line: {
    width: '100%',
    backgroundColor: '#C4C4C4',
    margin: theme.spacing(2, 0, 3)
  },
  root: {
    position: 'relative',
    backgroundColor: theme.palette.white,
    borderRadius: '14px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    minWidth: 500
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main
  }
}));

function ModalCompound({ children, title, handleClose, isOpen }) {
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={isOpen}>
          <Box className={classes.root}>
            {title && (
              <>
                <Typography className={classes.title} variant="h1">
                  {title}
                </Typography>
                <Divider className={classes.line} />
              </>
            )}
            {handleClose && (
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
            {children}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
export default withThemeV3Hoc(ModalCompound);
