import { Link as MuiLink, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { IntegrationCardPlatforms } from '.';

function NoConnectedApplicationsData({
  platform,
  integrationId
}: {
  platform: IntegrationCardPlatforms;
  integrationId: number;
}) {
  const createAppAssociationLink = `/integrations/new/${platform.toLowerCase()}?integrationId=${integrationId}`;

  return (
    <Link component={MuiLink} to={createAppAssociationLink}>
      <Typography variant="caption" color="primary" style={{ fontWeight: 500 }}>
        Connect this integration with an application
      </Typography>
    </Link>
  );
}

export default NoConnectedApplicationsData;
