import React from 'react';

import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import WebAssetIcon from '@material-ui/icons/WebAsset';

import { ToolboxOutline } from 'icons';
import IntegrationInstructions from 'icons/IntegrationInstructions';

import { types } from './types';

const Icon = ({ type }) => {
  if (type === types.product) {
    return <WebAssetIcon />;
  }

  if (type === types.environment) {
    return <EmojiObjectsIcon />;
  }

  if (type === types.application) {
    return <TurnedInIcon />;
  }

  if (type === types.addon) {
    return <ToolboxOutline />;
  }

  if (type === types.external) {
    return <IntegrationInstructions />;
  }

  return null;
};

export default Icon;
