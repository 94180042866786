import { FormControl } from '@material-ui/core';
import SecretsField from 'oldComponents/SecretsField';

import InputDefault from 'componentsV3/InputDefault';

const RawJsonField = ({
  register,
  label,
  name,
  errors,
  control,
  watch,
  setValue,
  disabled,
  style,
  selectedMethod,
  withSecrets = true,
  ...otherProps
}) => {
  const errorMessage = errors && errors[name]?.message;

  return (
    <FormControl
      fullWidth
      style={{
        ...style
      }}
      size="small"
      variant="outlined"
      id="post-body-raw-form"
      {...otherProps}>
      {withSecrets ? (
        selectedMethod === 'functions' || selectedMethod === 'functionsgen2' ? (
          <InputDefault
            style={{ flexGrow: 1 }}
            inputRef={register}
            label={label}
            name={name}
            id={name}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            margin="dense"
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{
              shrink: true
            }}
            multiline
            required
            linesNumber={5}
          />
        ) : selectedMethod === 'pubsub' ? (
          <InputDefault
            style={{ flexGrow: 1 }}
            inputRef={register}
            label={label}
            name={name}
            id={name}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            margin="dense"
            variant="outlined"
            disabled={disabled}
            InputLabelProps={{
              shrink: true
            }}
            multiline
            required
            linesNumber={5}
          />
        ) : (
          <SecretsField
            TextFieldProps={{
              label,
              name,
              fullWidth: false,
              multiline: true,
              rows: 5,
              style: { flexGrow: 1 }
            }}
            errors={errors}
            control={control}
            setValue={setValue}
            watch={watch}
            disabled={disabled}
            disableOffset={true}
          />
        )
      ) : (
        <InputDefault
          style={{ flexGrow: 1 }}
          inputRef={register}
          label={label}
          name={name}
          id={name}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          margin="dense"
          variant="outlined"
          disabled={disabled}
          InputLabelProps={{
            shrink: true
          }}
          multiline
          linesNumber={5}
        />
      )}
    </FormControl>
  );
};

export default RawJsonField;
