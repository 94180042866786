import { IconButton, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router';

import { useFeaturePlan } from 'hooks/useFeaturePlan';
import QueryStats from 'icons/QueryStats';
import { analyticsEvent } from 'services/firebase/analytics';

type AddonMetricsButtonProps = {
  sterasId?: number | null;
  serviceName: string;
};

function AddonMetricsButton({ sterasId, serviceName }: AddonMetricsButtonProps) {
  const canShowMetricsButton = useFeaturePlan('Metrics.Dashboards');

  const history = useHistory();

  const isDisabled = !Boolean(sterasId);

  const redirectURL = `/metrics?tab=monitoring-performance&resource=${serviceName}`;

  if (!canShowMetricsButton) return null;

  return (
    <Tooltip
      title={
        isDisabled
          ? 'Cannot view detailed metrics without monitoring this service'
          : 'Metrics details'
      }>
      <IconButton
        disableRipple={isDisabled}
        onClick={() => {
          !isDisabled && history.push(redirectURL);

          analyticsEvent('click_addon_metrics_button');
        }}>
        <QueryStats />
      </IconButton>
    </Tooltip>
  );
}

export default AddonMetricsButton;
