import { useQuery } from 'urql';

const GetTeamsQuery = `#graphql
query{
  getTeamsByOrgUid(
    page: 1, 
    per_page: 100, 
    sort: "name,ASC"
  ){
    data{
      id,
      name
    }
  }
}
`;

export const useTeams = () => {
  return useQuery<{
    getTeamsByOrgUid: {
      data: {
        id: number;
        name: string;
      }[];
    };
  }>({
    query: GetTeamsQuery
  });
};
