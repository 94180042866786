import React from 'react';

import { Box, Grid, Icon, IconButton, makeStyles, Popover, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { formatMetricsValue, MetricsValueFormatEnum } from 'helpers/formatMetricsValue';
import formatSecondsInWords from 'helpers/formatSecondsInWords';
import { useMtbf, useMtta, useMttrecovery } from 'hooks/queriesGraphQL/useMtts';
import { useServiceStatus } from 'hooks/queriesGraphQL/useServiceStatus';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { getAppStatusUniqueResource } from 'views/Products/View/Availability/List/Datagrid/checkAppAvailability';

import UptimeLine from '../../components/UptimeLine';
import UptimeTable from '../../components/UptimeTable';
import Charts from '../../TopSection/Charts';
import HitsAndFailures from '../../TopSection/HitsAndFailures';

import { NewDataRow } from './DataRow';

const useStyles = makeStyles({
  box: {
    width: 1000,
    minHeight: 112,
    paddingRight: 1,
    paddingLeft: 1,
    paddingBottom: 1,
    position: 'relative'
  }
});

const styles = {
  display: 'block',
  borderRadius: 20,
  backgroundColor: 'green',
  border: '1px solid #000000',
  width: 16,
  height: 16,
  margin: '0 10px'
};

export const AddonMetrics = ({
  row,
  latenciesGraphData
}: {
  row: NewDataRow;
  latenciesGraphData?: any;
}) => {
  const canUseMetrics = useFeaturePlan('Metrics');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    if (!canUseMetrics) return;

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();

  const buttonTitle = canUseMetrics
    ? 'Resource Metrics'
    : 'Upgrade your plan to view addon metrics';

  return (
    <>
      <IconButton title={buttonTitle} onClick={handleClick} aria-describedby={id}>
        <Icon>assessment</Icon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Box className={classes.box}>
          <UptimeTable
            monitoringActive={row?.monitoringActive}
            sterasId={row?.sterasId}
            resourceType="Addon"
            hasLoading
          />
          {latenciesGraphData?.graphData && (
            <Grid style={{ paddingTop: '10px' }} item xs={12}>
              <Charts data={latenciesGraphData.graphData} />
            </Grid>
          )}
        </Box>
      </Popover>
    </>
  );
};

const AddHitsAndFailuresButton = (props: any) => (
  <IconButton title="Hits and failures API" {...props}>
    <Icon>input</Icon>
  </IconButton>
);

const hitsAndFailures = (props: any) => <AddHitsAndFailuresButton {...props} />;

export const AddonHitsAndFailuresAPI = ({
  sterasId,
  orgUid,
  disabled
}: {
  sterasId?: number;
  orgUid: string;
  disabled: boolean;
}) => {
  return (
    <HitsAndFailures
      openerComponent={hitsAndFailures}
      openerDisabled={disabled}
      sterasId={sterasId}
      orgUid={orgUid}
    />
  );
};

export const AppStatus = ({ row }: { row: NewDataRow }) => {
  const { dataFromGraphQL: applicationStatusGraphQL }: { dataFromGraphQL: any } = useServiceStatus({
    useResource: row?.useResource,
    resourceUid: row?.resourceUid,
    serviceId: row?.sterasId,
    addonId: row?.id,
    applicationId: row?.applicationId,
    applicationDependencyId: row?.applicationDependency?.id,
    resourceType: !row?.applicationDependency ? 'Addon' : 'Application'
  });

  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });
  const shouldUseResource = Boolean(useResourceFlag && row?.useResource);

  const getMonitoringStatus = () => {
    if (!row?.applicationParentMonitoringActive) {
      return undefined;
    }

    if (shouldUseResource) {
      return applicationStatusGraphQL?.status;
    }

    return applicationStatusGraphQL?.monitoringStatus;
  };

  const monitoringStatus = getMonitoringStatus();
  const status = getAppStatusUniqueResource(monitoringStatus);

  return (
    <>
      <span
        title={`${row.name}-${status?.label}`}
        style={{ ...styles, backgroundColor: status?.color }}
      />
    </>
  );
};

export const GetMtta = ({ row }: { row: NewDataRow }) => {
  const dataMtta = useMtta({
    serviceId: row?.sterasId,
    useInsightsForMTTs: row.useInsightsForMTTs
  });

  const canUseMetrics = useFeaturePlan('Metrics.SRE');

  if (!canUseMetrics) {
    return (
      <Link to="/plans">
        <Typography variant="h6" color="primary">
          Upgrade your plan
        </Typography>
      </Link>
    );
  }

  return row?.informations ? (
    <>
      {row?.useInsightsForMTTs
        ? formatMetricsValue({ value: dataMtta, valueFormat: MetricsValueFormatEnum.Time })
        : formatSecondsInWords(dataMtta?.period?.thirtyDays || 0)}
    </>
  ) : (
    <div>No data</div>
  );
};

export const GetMtbf = ({ row }: { row: NewDataRow }) => {
  const dataMtbf = useMtbf({
    serviceId: row?.sterasId,
    useInsightsForMTTs: row.useInsightsForMTTs
  });

  const canUseMetrics = useFeaturePlan('Metrics.SRE');

  if (!canUseMetrics) {
    return (
      <Link to="/plans">
        <Typography variant="h6" color="primary">
          Upgrade your plan
        </Typography>
      </Link>
    );
  }

  return row?.informations ? (
    <>
      {row?.useInsightsForMTTs
        ? formatMetricsValue({ value: dataMtbf, valueFormat: MetricsValueFormatEnum.Time })
        : formatSecondsInWords(dataMtbf?.period?.thirtyDays || 0)}
    </>
  ) : (
    <div>No data</div>
  );
};

export const GetMttrecovery = ({ row }: { row: NewDataRow }) => {
  const dataMttrecovery = useMttrecovery({
    serviceId: row?.sterasId,
    useInsightsForMTTs: row.useInsightsForMTTs
  });

  const canUseMetrics = useFeaturePlan('Metrics.SRE');

  if (!canUseMetrics) {
    return (
      <Link to="/plans">
        <Typography variant="h6" color="primary">
          Upgrade your plan
        </Typography>
      </Link>
    );
  }

  return row?.informations ? (
    <>
      {row?.useInsightsForMTTs
        ? formatMetricsValue({ value: dataMttrecovery, valueFormat: MetricsValueFormatEnum.Time })
        : formatSecondsInWords(dataMttrecovery?.period?.thirtyDays || 0)}
    </>
  ) : (
    <div>No data</div>
  );
};

export const AddonUptimeLine = ({ row }: { row: NewDataRow }) => {
  const canUseMetrics = useFeaturePlan('Metrics.Uptime');

  if (!canUseMetrics) {
    return (
      <Link to="/plans">
        <Typography variant="h6" color="primary">
          Upgrade your plan
        </Typography>
      </Link>
    );
  }

  return row?.informations ? (
    <UptimeLine sterasId={row.sterasId} monitoringActive={row?.monitoringActive} />
  ) : (
    <div>No data</div>
  );
};
