export const objectToKeyValueList = object => {
  return Object.keys(object).map(key => {
    return { key, value: object[key] };
  });
};

export const keyValueListToObject = keyValues => {
  const filteredKeyValues = keyValues.filter(item => Boolean(item.key));
  const asObject = filteredKeyValues.reduce((final, item) => {
    return { ...final, [item.key]: item.value };
  }, {});

  return filteredKeyValues.length ? asObject : null;
};

const getObjectList = object => {
  object = object ? JSON.parse(object) : null;

  const objectList = object ? objectToKeyValueList(object) : [{ key: '', value: '' }];

  return objectList;
};

export default getObjectList;
