import React from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Help from '@material-ui/icons/Help';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => {
  return {
    icon: { color: theme.palette.icon, marginLeft: theme.spacing(1.5), fontSize: 20 }
  };
});

const HelperIcon = ({ tooltipText }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltipText}>
        <Help className={classes.icon} />
      </Tooltip>
    </Box>
  );
};

export default withThemeV3Hoc(HelperIcon);
