import { DialogContentText } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function GoogleChatForm({ form }: FormProps) {
  const classes = useStyles();
  const { register, errors } = form;

  return (
    <>
      <DialogContentText>1. Configure incoming webhook and get webhook url</DialogContentText>

      <DialogContentText>
        Set a name (e.g. Elven Platform) and image for the connector, then copy the webhook url and
        paste below.
      </DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.gchatWebhookUrl"
          label="Webhook url"
          errors={errors}
          minRows={5}
          multiline
          required
        />
      </DialogContentText>
    </>
  );
}
