import React from 'react';

import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { EnvironmentSelect } from 'views/ServicesHub/components/EnvironmentSelect';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';

export type MonitoringBaseForm = {
  name: string;
  environmentId: number;
  interval: number;
  timeout: number;
  domainType?: string;
  domain: string;
};

export function BaseForm({ form, domainType }: FormProps) {
  const { register, control, errors, watch, setValue } = form;

  const queryParams = useQueryParams();
  const isExternal = queryParams?.get('external');
  const isAPI = queryParams?.get('type') === 'api';
  const showDomainType =
    queryParams?.get('type') === 'sqs' ||
    queryParams?.get('type') === 'mongodb' ||
    queryParams?.get('type') === 'postgres' ||
    queryParams?.get('type') === 'mysql' ||
    queryParams?.get('type') === 'kafka' ||
    queryParams?.get('type') === 'sqlserver' ||
    queryParams?.get('type') === 'redisv2' ||
    queryParams?.get('type') === 'lambdaawsv2' ||
    queryParams?.get('type') === 'rabbitmq' ||
    queryParams?.get('type') === 'tcp' ||
    queryParams?.get('type') === 'memcached';

  const hideTimeoutAndInterval = useFeatureFlagUnleash('hideTimeoutAndInterval', {
    queryString: true
  });

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input
        label="Resource Name"
        name="name"
        inputRef={register}
        required={false}
        errors={errors}
      />
      {!isAPI ? (
        <Box display="flex" gridGap="1rem">
          <Input
            label="Interval"
            type="number"
            name="interval"
            inputRef={register}
            errors={errors}
            inputProps={{
              min: Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
            }}
            required={false}
          />

          <Input
            label="Timeout"
            type="number"
            name="timeout"
            inputRef={register}
            errors={errors}
            inputProps={{
              min: 1
            }}
            required={false}
          />
        </Box>
      ) : !hideTimeoutAndInterval ? (
        <Box display="flex" gridGap="1rem">
          <Input
            label="Interval"
            type="number"
            name="interval"
            inputRef={register}
            errors={errors}
            inputProps={{
              min: Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
            }}
            required={false}
          />

          <Input
            label="Timeout"
            type="number"
            name="timeout"
            inputRef={register}
            errors={errors}
            inputProps={{
              min: 1
            }}
            required={false}
          />
        </Box>
      ) : (
        <></>
      )}

      {!isExternal && !isAPI && <EnvironmentSelect form={form} />}

      {showDomainType && (
        <Box display="flex" gridGap="1rem">
          <Select
            options={[
              { value: 'userAndPass', label: 'User and Password' },
              { value: 'url', label: 'Healthcheck URL' }
            ]}
            control={control}
            name="domainType"
            label="Form type"
            required={true}
          />
        </Box>
      )}

      {domainType === 'url' && (
        <Box display="flex" gridGap="1rem">
          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'text',
              label: 'Healthcheck URL',
              name: 'domain'
            }}
            InputComponent={Input}
          />
        </Box>
      )}
    </Box>
  );
}
