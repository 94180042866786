import cloudProviders from 'constants/cloudProviders';

import validate from 'validate.js';

validate.validators.presenceIfCloud = (value, options, key, attributes) => {
  if (attributes.cloud?.provider === options.cloudProvider) {
    return validate.validators.presence(
      value === '1' ? undefined : value,
      { allowEmpty: false },
      key,
      attributes
    );
  }
};

const schema = {
  name: {
    presence: { allowEmpty: false }
  },

  cloudId: {
    presence: { allowEmpty: false }
  },

  tag: {
    presence: { allowEmpty: false }
  },

  // AWS fields
  region: {
    presenceIfCloud: { cloudProvider: cloudProviders.aws }
  },

  vpcId: {
    presenceIfCloud: { cloudProvider: cloudProviders.aws }
  },

  subnetId: {
    presenceIfCloud: { cloudProvider: cloudProviders.aws }
  },

  instanceType: {
    presenceIfCloud: { cloudProvider: cloudProviders.aws }
  },

  // Azure fields
  location: {
    presenceIfCloud: { cloudProvider: cloudProviders.azure }
  },

  vnetName: {
    presenceIfCloud: { cloudProvider: cloudProviders.azure }
  },

  subnetName: {
    presenceIfCloud: { cloudProvider: cloudProviders.azure }
  },

  resourceGroup: {
    presenceIfCloud: { cloudProvider: cloudProviders.azure }
  }
};

export default schema;
