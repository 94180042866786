import { useState } from 'react';

import { IconButton, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

import { Menu } from 'components/Menu';
import { usePermission } from 'hooks/usePermission';

import { PostmortemDeleteDialog } from '../components/DeleteDialog';

type MenuProps = {
  postmortemUid: string;
  postmortemTitle: string;
  refetch: () => void;
};

export function MenuActions({ postmortemUid, postmortemTitle, refetch }: MenuProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePostmortem = () => {
    handleClose();
    setDeleteDialogOpen(true);
  };

  const hasDeletePostmortemPermission = usePermission('PostMortemController-delete-/post-mortem');
  const hasUpdatePostmortemPermission = usePermission('PostMortemController-put-/post-mortem');

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu anchorEl={anchorEl} onClose={handleClose} placement="bottom-end">
        <MenuItem
          onClick={handleClose}
          disabled={!hasUpdatePostmortemPermission}
          component={NavLink}
          to={`/postmortems/edit/${postmortemUid}`}>
          Edit postmortem
        </MenuItem>
        <MenuItem onClick={handleDeletePostmortem} disabled={!hasDeletePostmortemPermission}>
          Delete postmortem
        </MenuItem>
        <MenuItem component={NavLink} to={`/postmortems/${postmortemUid}?print=true`}>
          Export to PDF
        </MenuItem>
      </Menu>

      <PostmortemDeleteDialog
        open={deleteDialogOpen}
        postmortemTitle={postmortemTitle}
        postmortemUid={postmortemUid}
        handleClose={() => setDeleteDialogOpen(false)}
        onDelete={() => refetch()}
      />
    </>
  );
}
