import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type PingFormData = {
  host: string;
};

export function PingForm({ form }: FormProps) {
  const { control, watch, errors, setValue } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <InputWithSecrets
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        TextFieldProps={{
          type: 'text',
          label: 'Host',
          name: 'host'
        }}
        InputComponent={Input}
      />
    </Box>
  );
}
