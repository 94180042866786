import { SvgIconProps } from '@material-ui/core';

const ServiceNowIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0732 0.241997C9.66008 0.247358 7.30364 0.97415 5.30669 2.32899C3.30974 3.68383 1.76351 5.60482 0.866673 7.84515C-0.0301681 10.0855 -0.23665 12.5428 0.273756 14.9014C0.784162 17.2599 1.98814 19.412 3.731 21.0811C4.14284 21.4743 4.68199 21.7068 5.2506 21.7363C5.81921 21.7659 6.37958 21.5907 6.83 21.2424C8.31852 20.1383 10.1227 19.5423 11.9759 19.5423C13.8292 19.5423 15.6333 20.1383 17.1219 21.2424C17.5764 21.6021 18.1463 21.7842 18.7252 21.7545C19.3041 21.7248 19.8524 21.4855 20.2677 21.0811C22.0019 19.4208 23.203 17.2821 23.7182 14.9372C24.2333 12.5923 24.0393 10.1471 23.1607 7.91284C22.2821 5.67853 20.7587 3.75609 18.7843 2.39012C16.8099 1.02415 14.4738 0.276395 12.0732 0.241997ZM12.0057 18.3451C11.2077 18.371 10.4127 18.2335 9.6697 17.9412C8.92666 17.6488 8.25117 17.2078 7.68469 16.645C7.1182 16.0823 6.67264 15.4098 6.37536 14.6687C6.07807 13.9276 5.93533 13.1336 5.95587 12.3354C5.92884 11.5288 6.06437 10.725 6.35439 9.97182C6.64441 9.21867 7.083 8.53157 7.64404 7.95142C8.20508 7.37126 8.8771 6.90991 9.62012 6.59483C10.3631 6.27975 11.1619 6.11738 11.969 6.11738C12.7761 6.11738 13.5749 6.27975 14.3179 6.59483C15.0609 6.90991 15.7329 7.37126 16.294 7.95142C16.855 8.53157 17.2936 9.21867 17.5836 9.97182C17.8736 10.725 18.0091 11.5288 17.9821 12.3354C18.0034 13.1304 17.8625 13.9214 17.568 14.6601C17.2735 15.3989 16.8316 16.0699 16.2693 16.6323C15.7069 17.1946 15.0359 17.6365 14.2971 17.931C13.5584 18.2255 12.7674 18.3664 11.9724 18.3451"
        fill="#81B5A1"
      />
    </svg>
  );
};

export default ServiceNowIcon;
