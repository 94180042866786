import { zodResolver } from '@hookform/resolvers/zod';
import { useTheme, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import ButtonSimple from 'componentsV3/ButtonSimple';
import useQueryParams from 'helpers/useQueryParams';
import { analyticsEvent } from 'services/firebase/analytics';

import EnvironmentSelect from '../Application/ApplicationForm/EnvironmentSelect';

const schema = z.object({
  environmentId: z.string().nonempty({ message: 'You must select an environment' })
});

const ExistingCloudForm = ({
  onEnvironmentSelected,
  cloudId,
  applicationType
}: {
  onEnvironmentSelected: (environmentId: number) => void;
  cloudId?: number | null;
  applicationType: string;
}) => {
  const theme = useTheme();

  const { errors, control, handleSubmit, setValue, formState } = useForm({
    defaultValues: { environmentId: '' },
    resolver: zodResolver(schema),
    mode: 'all'
  });

  const { isValid } = formState;

  const firstAccess = useQueryParams().has('firstAccess');

  return (
    <form
      onSubmit={handleSubmit(formData => {
        onEnvironmentSelected(Number(formData.environmentId));
      })}>
      <Grid container>
        <Grid item xs={8}>
          <EnvironmentSelect
            defaultLabel={'Select an Environment'}
            control={control}
            errors={errors}
            setValue={setValue}
            formState={formState}
            name="environmentId"
            cloudId={cloudId}
            required
          />
        </Grid>
        <Grid item xs={10}>
          <ButtonSimple
            style={{ marginTop: theme.spacing(9) }}
            type="submit"
            color="primary"
            text="Install or activate agent"
            disabled={!isValid}
            onClick={() => {
              const eventsMap: { [key: string]: string } = {
                internalMultiHttp: firstAccess
                  ? 'fa_select_existing_env_multi'
                  : 'select_existing_env_multi'
              };

              if (eventsMap[applicationType]) {
                analyticsEvent(eventsMap[applicationType]);
              }
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ExistingCloudForm;
