import { useQuery } from 'urql';

type UseCmsParams = {
  page: string;
};

const Query = `#graphql
  query(
    $page: String!
  ) {
    cms(
      page: $page
    ) {
      content
    }
  }
`;

export function useCms({ page }: UseCmsParams) {
  return useQuery<{ cms: any }>({
    query: Query,
    variables: {
      page
    }
  });
}
