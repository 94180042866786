import { MetabaseDashboards } from 'constants/metabaseDashboards';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

import { useStyles } from './styles';

type SyncReportDialogProps = {
  open: boolean;
  onClose: () => void;
};

const SyncReportDialog = ({ open, onClose }: SyncReportDialogProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const hideParams = ['org_uid'] as string[];

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <Box display="flex" flexDirection="column">
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography></Typography>
          <IconButton
            style={{ marginTop: -12, marginRight: -12, marginLeft: theme.spacing(2) }}
            aria-label="close"
            onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MetabaseDashboard
            title=""
            subtitle=""
            dashboardName={MetabaseDashboards.SingleSignOn}
            hideParams={hideParams}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default SyncReportDialog;
