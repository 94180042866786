import { useMemo } from 'react';

import { apm } from '@elastic/apm-rum';
import { useParams } from 'react-router';
import { Rule, RuleOriginEntityType } from 'types/external/AlertCenter';
import { useQuery } from 'urql';

import { useResource } from 'hooks/queriesGraphQL/useResource';
import { useResponders } from 'hooks/queriesGraphQL/useResponders';
import { useTeams } from 'hooks/queriesGraphQL/useTeams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { GetRulesByEntityUidQuery } from 'hooks/useRule';
import { adapters } from 'views/ServicesHub/adapters/edit';
import { incidentsRulesAdapter } from 'views/ServicesHub/adapters/edit/rules/incident';
import { rulesAdapter } from 'views/ServicesHub/adapters/edit/rules/timeout';

export function useStartValues() {
  const { uid } = useParams<{ uid: string }>();
  const [{ data: resourceData, fetching: fetchingResource }] = useResource(uid);

  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const [{ data: rulesData, fetching: fetchingRule }] = useQuery<{ getRulesByEntityUid: Rule[] }>({
    query: GetRulesByEntityUidQuery,
    variables: { entityUid: uid, entity: RuleOriginEntityType.Resource },
    pause: !uid
  });

  const [{ data, fetching: fetchingGetTeams }] = useTeams();
  const [{ data: responders, fetching: fetchingResponders }] = useResponders();

  const fetching = fetchingResource || fetchingGetTeams || fetchingResponders || fetchingRule;

  const resource = resourceData?.resource;
  const resourceMethod = resource?.method.toLowerCase() as keyof typeof adapters;

  return useMemo(() => {
    const teamsFieldOptions =
      data?.getTeamsByOrgUid?.data?.map(team => ({
        value: String(team.id),
        label: team.name
      })) ?? [];

    const respondersFieldOptions =
      responders?.getRespondersByOrgUid?.data?.map(responder => ({
        value: String(responder.uid),
        label: responder.name
      })) ?? [];

    if (!resourceMethod || !resource) return { startValues: undefined, fetching: false };

    if (!(resourceMethod in adapters)) {
      apm.captureError(`Method ${resourceMethod} not found in adapters`);
      return { startValues: undefined, fetching: false };
    }

    const incidentRule = rulesData?.getRulesByEntityUid?.find(item =>
      item.actions.some(action => action.type !== 'timeout')
    );
    const resourceStartValues = adapters[resourceMethod](resource);

    const timeoutRule = rulesData?.getRulesByEntityUid?.find(item =>
      item.actions.some(action => action.type === 'timeout')
    );

    const incidentRuleStartValues = incidentsRulesAdapter(
      incidentRule,
      shouldUseRespondersInput ? respondersFieldOptions : teamsFieldOptions
    );

    const ruleStartValues = rulesAdapter(
      timeoutRule,
      shouldUseRespondersInput ? respondersFieldOptions : teamsFieldOptions
    );

    const startValues = {
      ...resourceStartValues,
      rules: {
        incident: incidentRuleStartValues,
        incidentRule,
        timeout: ruleStartValues,
        timeoutRule: timeoutRule
      }
    };

    return { startValues, fetching };
  }, [
    data?.getTeamsByOrgUid?.data,
    responders?.getRespondersByOrgUid?.data,
    resourceMethod,
    resource,
    rulesData?.getRulesByEntityUid,
    fetching,
    shouldUseRespondersInput
  ]);
}
