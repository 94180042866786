import { MonitoringBaseForm } from 'views/ServicesHub/forms/monitoring/commom/Base';
import { MysqlFormData } from 'views/ServicesHub/forms/monitoring/database/Mysql';

import { monitoringAdapter } from '../monitoring';

export function mysqlAdapter(data: MonitoringBaseForm & MysqlFormData) {
  const domain = prepareDomain(data);
  // ex: USER:PASSWORD@tcp(HOST:PORT)/DATABASE

  return {
    ...monitoringAdapter({
      ...data,
      method: 'mysql'
    }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringBaseForm & MysqlFormData) => {
  if (data.domainType === 'userAndPass') {
    return `${data.username}:${data.password}@tcp(${data.host}:${data.port})/${data.database}`;
  }
  return data.domain;
};
