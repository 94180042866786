import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 240
  },
  tableRow: {
    '&:last-child td': {
      border: 'none'
    }
  },
  tableCell: {
    paddingTop: theme.spacing()
  }
}));
