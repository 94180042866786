import { MultiOriginsMonitorings } from 'types/external/MultiOriginsMonitorings';
import { useQuery } from 'urql';

const MultiOriginsListQuery = `#graphql
query {
  getMultiOriginsMonitorings {
    data {
      ... on MultiFlowToMultiOrigin {
        name
        uid
        entity
      }
      ... on ResourceToMultiOrigin {
        name
        uid
        entity
      }
    }
    total
  }
}
`;

export const useMultiOrigins = () =>
  useQuery<{ getMultiOriginsMonitorings: { data: MultiOriginsMonitorings[] } }>({
    query: MultiOriginsListQuery
  });
