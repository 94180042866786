import * as z from 'zod';

export const serviceBusSchemaValidation = () => {
  return {
    connection_string: z
      .string()
      .nonempty({ message: 'Connection String must be informed' })
      .optional(),
    type: z
      .string()
      .nonempty({ message: 'Monitoring Type must be informed' })
      .optional(),
    queue: z
      .string()
      .nonempty({ message: 'Queue must be informed' })
      .optional(),
    topic: z
      .string()
      .nonempty({ message: 'Topic must be informed' })
      .optional(),
    subscription: z
      .string()
      .nonempty({ message: 'Subscription must be informed' })
      .optional()
  };
};
