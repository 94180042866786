import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  popper: {
    marginTop: theme.spacing(),
    backgroundColor: '#FFFFFF'
  },
  input: {
    fontSize: 22
  },
  paper: {
    width: 440,
    border: '1px solid #C2C2C2',
    padding: theme.spacing(2),
    boxShadow: '0px 2px 8px 0px #00000026'
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    gap: theme.spacing(0.5)
  },
  divider: {
    backgroundColor: theme.palette.gray[100]
  },
  selectContainer: {
    marginTop: theme.spacing(3)
  },
  dialogTitle: {
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    paddingTop: '20px'
  },
  closeButton: {
    right: theme.spacing(3),
    top: theme.spacing(3),
    position: 'absolute',
    color: theme.palette.grey[500]
  }
}));
