import { postBodyTypeValues } from 'constants/postBody';

import keyValuesToObject from 'helpers/keyValuesToObject';

import { functionsMapToApi } from './Functions/mapToApi';
import { kafkaMapToApi } from './Kafka/mapToApi';
import { lambdaMapToApi } from './LambdaV2/mapToApi';
import { redisMapToApi } from './RedisV2/mapToApi';
import { serviceBusMapToApi } from './ServiceBus/mapToApi';

const sidekiqToObject = keyValues => {
  const { queue_size, busy_size, scheduled_size, connected_clients } = keyValues;
  return {
    queue_size: Number(queue_size),
    busy_size: Number(busy_size),
    scheduled_size: Number(scheduled_size),
    connected_clients: Number(connected_clients)
  };
};

const mapFormDataToApi = formData => {
  const postBodyType =
    formData.post_body_type !== 'raw' ? formData.post_body_type : formData.post_body_raw_type;

  const postBodyRaw = formData.post_body_raw;

  const postBodyXFormUrlEncoded = keyValuesToObject(formData.post_body_urlencoded || []);

  const postBodyForSelectedType =
    postBodyType === postBodyTypeValues.urlencoded ? postBodyXFormUrlEncoded : postBodyRaw;

  const mapDomainSettings = {
    sidekiq: formData => sidekiqToObject(formData),
    pubsub: formData => formData.json_pubsub_body_raw,
    redisV2: formData => redisMapToApi(formData),
    lambdaawsv2: formData => lambdaMapToApi(formData),
    functions: formData => functionsMapToApi(formData),
    functionsgen2: formData => functionsMapToApi(formData),
    kafka_advanced: formData => kafkaMapToApi(formData),
    servicebus: formData => serviceBusMapToApi(formData)
  };

  return {
    name: formData.name,
    domain: formData.method === 'kafka_advanced' ? 'kafka_advanced' : formData.domain,
    cloudId: formData.cloudId,
    environmentId: formData.environmentId,
    check_type: formData.check_type,
    method: formData.method,
    headers: formData.check_type === 'http' ? keyValuesToObject(formData.headers) : null,
    post_body: postBodyForSelectedType,
    post_body_type: postBodyType,
    skip_ssl_validation: formData.skip_ssl_validation,
    failuresToIncident: formData.failuresToIncident ? Number(formData.failuresToIncident) : 2,
    domain_settings:
      formData.method in mapDomainSettings
        ? mapDomainSettings[formData.method](formData)
        : formData.check_type === 'http'
        ? { tls_renegotiation: formData.tls_renegotiation }
        : null
  };
};

export default mapFormDataToApi;
