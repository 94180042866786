import { MetabaseDashboards } from 'constants/metabaseDashboards';

import MetabaseDashboard from 'oldComponents/MetabaseDashboard';
import { MetabaseDashboardProps } from 'oldComponents/MetabaseDashboard/types';
import { useParams } from 'react-router-dom';

import useQueryParams from 'helpers/useQueryParams';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { getParamsData } from '../CheckExplorer';

function ResourcesMetrics() {
  const shouldUseInsightsWithExploreFurtherTab = useFeatureFlagUnleash(
    'useInsightsWithExploreFurtherTab',
    {
      queryString: true
    }
  );

  const { resourceUid } = useParams<{
    resourceUid: string;
  }>();

  const queryParams = useQueryParams();
  const serviceId = queryParams.get('service')!;

  const params = { uid: resourceUid, service: serviceId };

  const filters = getParamsData();

  const paramsWithFilters = {
    uid: resourceUid,
    single_date: filters['data'],
    hour: [filters['hourIni'], filters['hourEnd']]
  };

  const tabs: MetabaseDashboardProps['tabs'] = shouldUseInsightsWithExploreFurtherTab
    ? [
        {
          label: 'System health monitoring',
          dashboardName: MetabaseDashboards.ResourcesMonitoring,
          slug: 'monitoring'
        },
        {
          label: 'MTTs graphics',
          dashboardName: MetabaseDashboards.ResourcesMTTs,
          slug: 'mtts'
        },
        {
          label: 'Explore further',
          dashboardName: MetabaseDashboards.ResourcesCheckExplorer,
          slug: 'explore-further',
          dashboardParams: paramsWithFilters
        }
      ]
    : [
        {
          label: 'System health monitoring',
          dashboardName: MetabaseDashboards.ResourcesMonitoring,
          slug: 'monitoring'
        },
        {
          label: 'MTTs graphics',
          dashboardName: MetabaseDashboards.ResourcesMTTs,
          slug: 'mtts'
        }
      ];

  const hideExploreFurtherParams = ['uid'];

  return (
    <MetabaseDashboard
      tabs={tabs}
      hideParams={shouldUseInsightsWithExploreFurtherTab ? hideExploreFurtherParams : []}
      title="Organization Resources metrics"
      subtitle="List of monitoring with their Resources"
      dashboardParams={params}
    />
  );
}

export default ResourcesMetrics;
