import * as z from 'zod';

const schema = z.object({
  budget_perc: z.coerce
    .number()
    .min(0, 'Percent should be a greater than zero')
    .max(100, 'The percentage must be less than one hundred')
    .int('Percent should be an integer')
});

export type ErrorBudgetFormData = z.infer<typeof schema>;

export default schema;
