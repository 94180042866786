import { Box, Icon } from '@material-ui/core';
import { TrendingUp, TrendingDown } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab-old';
import { TableColumn } from 'react-data-table-component';
import { FullApplication } from 'types/external/Application';
import { ApplicationManagerEntityType } from 'types/external/ApplicationManager';

import formatSecondsInWords from 'helpers/formatSecondsInWords';

import { StatusChip } from './components/StatusChip';

export const COLUMNS: TableColumn<Partial<FullApplication>>[] = [
  {
    name: 'Status',
    selector: row => row?.main?.status || '',
    cell: row =>
      row?.main?.status && row?.entity ? (
        <StatusChip status={row?.main?.status} entity={row?.entity} />
      ) : (
        <Skeleton height={40} animation="wave" width={100} />
      ),
    width: '190px',
    grow: 1.5
  },
  {
    name: 'Application main',
    selector: row => row.name || '',
    cell: row => {
      return row.name ? row.name : <Skeleton height={40} animation="wave" width={100} />;
    },
    grow: 3
  },
  {
    name: 'Dependency State',
    cell: row => {
      if (!row?.dependencies) {
        return <Skeleton height={40} animation="wave" width={85} />;
      }

      if (
        !row?.dependencies?.available &&
        !row?.dependencies.unavailable &&
        !row?.dependencies.maintenance &&
        !row?.dependencies.success &&
        !row?.dependencies.error
      ) {
        return 'No data';
      }

      return (
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            {row?.dependencies?.available || row?.dependencies?.success ? (
              <>
                {Number(row?.dependencies?.available) + Number(row?.dependencies?.success)}
                <TrendingUp style={{ marginLeft: '8px' }} color="primary" />
              </>
            ) : null}
          </Box>
          <Box display="flex" alignItems="center" ml={row?.dependencies?.available ? 2 : 0}>
            {row?.dependencies?.unavailable || row?.dependencies?.error ? (
              <>
                {Number(row?.dependencies?.unavailable) + Number(row?.dependencies?.error)}
                <TrendingDown style={{ marginLeft: '8px', color: '#F57D27' }} />
              </>
            ) : null}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            ml={row?.dependencies?.available || row?.dependencies?.unavailable ? 2 : 0}>
            {row?.dependencies?.maintenance ? (
              <>
                {row?.dependencies?.maintenance}
                <Icon fontSize="small" style={{ marginLeft: '8px', color: '#F57D27' }}>
                  construction
                </Icon>
              </>
            ) : null}
          </Box>
        </Box>
      );
    }
  },
  {
    name: 'MTBF',
    selector: row => row?.main?.mtts?.mtbf || '',
    cell: row => {
      if (row.entity === ApplicationManagerEntityType.Multiflow) {
        return 'No data';
      }

      if (!row?.main?.mtts) {
        return <Skeleton height={40} animation="wave" width={70} />;
      }

      return formatSecondsInWords(row.main.mtts.mtbf || 0);
    }
  },
  {
    name: 'MTTA',
    selector: row => row?.main?.mtts?.mtta || '',
    cell: row => {
      if (row.entity === ApplicationManagerEntityType.Multiflow) {
        return 'No data';
      }

      if (!row?.main?.mtts) {
        return <Skeleton height={40} animation="wave" width={70} />;
      }

      return formatSecondsInWords(row.main.mtts.mtta || 0);
    }
  },
  {
    name: 'MTTRecovery',
    selector: row => row?.main?.mtts?.mttr || '',
    cell: row => {
      if (row.entity === ApplicationManagerEntityType.Multiflow) {
        return 'No data';
      }

      if (!row?.main?.mtts) {
        return <Skeleton height={40} animation="wave" width={70} />;
      }

      return formatSecondsInWords(row.main.mtts.mttr || 0);
    }
  },
  {
    name: 'Uptime - 1H',
    selector: row => row?.main?.uptime?.online1Hour || '',
    cell: row => {
      if (row.entity === ApplicationManagerEntityType.Multiflow) {
        return 'No data';
      }

      if (!row?.main?.uptime) {
        return <Skeleton height={40} animation="wave" width={70} />;
      }

      if (!row?.main?.uptime?.online1Hour && row?.main?.uptime?.online1Hour !== 0) {
        return 'No data';
      }

      return `${row?.main?.uptime?.online1Hour}%`;
    }
  }
];
