import { MonitoringStatus, monitoringStatusColors } from 'constants/MonitoringStatusV1';

import { Theme, Typography, useTheme } from '@material-ui/core';
import { Link as MuiLink } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import StatusColors from './StatusColors';

const DownsLineComponent = ({
  status,
  text,
  link
}: {
  status: StatusColors;
  text: string;
  link: string | null;
}) => {
  const theme = useTheme<Theme>();

  const getIconColor = ({ status }: { status: StatusColors }) => {
    if (status === StatusColors.down) {
      return theme.palette.error.main;
    }
    if (status === StatusColors.up) {
      return theme.palette.success.main;
    }
    if (status === StatusColors.maintenance) {
      return monitoringStatusColors[MonitoringStatus.Maintenance];
    }
    return theme.palette.text.primary;
  };

  return (
    <>
      <FiberManualRecordIcon
        style={{
          fontSize: 12,
          color: getIconColor({ status }),
          marginRight: 4
        }}
      />
      {link ? (
        <MuiLink
          variant="body2"
          href={link}
          style={{
            color: getIconColor({ status })
          }}>
          {text}
        </MuiLink>
      ) : (
        <Typography
          variant="body2"
          style={{
            color: getIconColor({ status })
          }}>
          {text}
        </Typography>
      )}
    </>
  );
};

export default DownsLineComponent;
