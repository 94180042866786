import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';
import reducerApplication from '../../reducer/applications';
import reducer from '../../reducer/products/new';

function* prepareFetchEntities(axios, action) {
  try {
    const step = action.payload;
    const { entity, product_applications_new } = yield select(
      ({ products_new, product_applications_new }) => ({
        entity: products_new,
        product_applications_new
      })
    );
    switch (step) {
      case 1: {
        const entityStep = entity.steps[0];
        const payload = {};
        if (entityStep.entity && entityStep.entity.id) {
          const {
            data: { data }
          } = yield axios.put(`/products/${entityStep.entity.id}`, entityStep);
          payload.data = data;
        } else {
          const {
            data: { data }
          } = yield axios.post('/products', entityStep);
          payload.data = data;
        }
        yield put({
          type: actions.PRODUCT_FETCH_SUCCESSFUL,
          payload: payload.data,
          meta: {
            reducer
          }
        });
        break;
      }
      case 2: {
        const entityStep = entity.steps[1];
        const payload = {};
        if (entityStep.entity && entityStep.entity.id) {
          const {
            data: { data }
          } = yield axios.put(`/products/${entityStep.entity.id}`, entityStep);
          payload.data = data;
        }
        yield put({
          type: actions.PRODUCT_FETCH_SUCCESSFUL,
          payload: payload.data,
          meta: {
            reducer
          }
        });
        yield put({
          type: actions.PRODUCT_APPLICATIONS_NEW_FETCH,
          payload: entity.steps[1].entity.id,
          meta: {
            reducer
          }
        });
        break;
      }
      case 3:
        {
          const dto = product_applications_new.values;
          const entityStep = entity.steps[0];
          if (entityStep.entity && entityStep.entity.id) {
            dto.productId = entityStep.entity.id;
          }
          yield axios.post('/applications', dto);
          yield put({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Entity created successfully'
          });
          yield put({
            type: actions.PRODUCT_APPLICATIONS_RESET,
            meta: { reducer: reducerApplication }
          });
          yield put({
            type: actions.PRODUCT_NEW_STEPPER_COMPLETE,
            payload: {},
            meta: {
              reducer
            }
          });
        }
        break;
      default:
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductNew(axios) {
  yield takeLatest(actions.PRODUCT_NEW_STEPPER_ACTIVE, prepareFetchEntities, axios);
}
