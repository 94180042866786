import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { AddCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationURQL } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useApiTokens from 'hooks/queriesGraphQL/useGetApiTokens';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

import { Apps } from '..';
import AppDialog from '../AppDialog';
import setResponse from '../AppDialog/setResponse';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const CreateTokenMutation = `#graphql
  mutation($type: String!, $name: String!) {
    createToken(type: $type, name: $name) {
      id
      uid
      name
      type
    }
  }`;

export const IntegrationTokens = () => {
  const dispatch = useDispatch();
  const shouldUseApiTokens = useFeatureFlagUnleash('useApiTokens');

  const { data: user, isLoading: userIsLoading } = useUser();
  const org = user?.logged?.org;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [values, setValues] = React.useState({
    name: '',
    token_type: '',
    access: '',
    secret: '',
    curl: ''
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValues({
      name: '',
      token_type: '',
      access: '',
      secret: '',
      curl: ''
    });
  };

  const [
    { fetching: isLoadingApiTokens, data: apiTokensData, error: isErrorApiTokens },
    refetchTokens
  ] = useApiTokens({
    from: 0,
    pause: !shouldUseApiTokens,
    size: rowsPerPage,
    query: ''
  });

  const apiTokens = apiTokensData?.tokens;

  const queryCache = useQueryCache();

  const [{ isCreating }, createTokenMutation] = useMutationURQL(CreateTokenMutation);

  const [handleCreateTokenOld, { isError, isSuccess, isLoadingOld }] = useMutation(
    name =>
      axios
        .post(`/apptokens`, { name: values.name, tokenType: values.token_type })
        .then(response => response.data),
    {
      onSuccess: ({ data }) => {
        queryCache.invalidateQueries('apptokens');
        queryCache.invalidateQueries('apptokens/hits');
        queryCache.invalidateQueries('apptokens/deploy');
        setResponse(setValues, data, org, values.token_type);
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Token Created'
        });
        if (values.token_type === 'api_token') {
          handleClose();
        }
      },
      onError: err => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: err,
          ga: { category: 'ERROR' }
        });
        handleClose();
      }
    }
  );

  function handleCreateToken(data) {
    if (values.token_type === 'api_token' || values.token_type === 'refresh_token') {
      return createTokenMutation({
        name: values.name,
        type: values.token_type
      })
        .then(res => {
          if (res.error) {
            throw new Error(res.error?.message.replace('[GraphQL] ', ''));
          }

          queryCache.invalidateQueries('apptokens');
          queryCache.invalidateQueries('apptokens/hits');
          queryCache.invalidateQueries('apptokens/deploy');
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Token Created'
          });

          setTimeout(refetchTokens, 3000);
        })
        .catch(err => {
          dispatch({
            type: actions.ENTITY_ERROR,
            payload: err,
            ga: { category: 'ERROR' }
          });
        })
        .finally(() => {
          handleClose();
        });
    }

    handleCreateTokenOld(data);
  }

  const permissions = {
    new: usePermission('AppTokensController-post-/apptokens'),
    update: usePermission('AppTokensController-put-/apptokens/:id'),
    remove: usePermission('AppTokensController-delete-/apptokens/:id')
  };

  if (userIsLoading) {
    return (
      <div className={classes.root}>
        <LoadingOverlay />
      </div>
    );
  }

  const isLoading = isLoadingOld || isCreating;

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Typography variant="h5">Tokens</Typography>
        </Box>

        <IconButton
          id="create-token-button"
          title="Create a new token"
          disabled={!permissions.new}
          onClick={handleOpen}
          size="small"
          color="primary">
          <AddCircle />
        </IconButton>
      </Box>

      <Apps
        permissions={permissions}
        isLoadingApiTokens={isLoadingApiTokens}
        isErrorApiTokens={isErrorApiTokens}
        apiTokens={apiTokens}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        refetchTokens={refetchTokens}
      />

      <AppDialog
        mutationLoading={isLoading}
        isError={isError}
        values={values}
        setValues={setValues}
        isSuccess={isSuccess}
        title={'New Integration Token'}
        open={open}
        handleClose={handleClose}
        permissions={permissions}
        handleOpen={handleOpen}
        handleCreateToken={handleCreateToken}
        showApiTokenOption={shouldUseApiTokens}
      />
    </>
  );
};
