import { Box } from '@material-ui/core';
import { FiltersPopper, FiltersPopperProps } from 'oldComponents/FiltersPopper';

export type PageFilters = {};

export type FiltersProps = {
  onApplyFilters: (filters: PageFilters) => void;
  onClearFilters: () => void;
  handleClose: () => void;
} & Pick<FiltersPopperProps, 'anchorEl'>;

export function Filters({ anchorEl, onApplyFilters, onClearFilters, handleClose }: FiltersProps) {
  const handleApplyFilters = () => {
    onApplyFilters({});
    handleClose();
  };

  const handleClearFilters = () => {
    onClearFilters();
    handleClose();
  };

  return (
    <FiltersPopper
      open={true}
      handleClose={handleClose}
      anchorEl={anchorEl}
      handleApplyFilters={handleApplyFilters}
      handleClearFilters={handleClearFilters}>
      <Box display="flex" justifyContent="space-between" gridGap={12}></Box>
    </FiltersPopper>
  );
}
