import { Box, Grid } from '@material-ui/core';

import { Skeleton } from 'components/Skeletron';

export function LoadingSkeleton({
  firstLineNewHome,
  secondLineNewHome
}: {
  firstLineNewHome: boolean;
  secondLineNewHome: boolean;
}) {
  return (
    <Box>
      {firstLineNewHome && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height={130} width="100%" />
          </Grid>
        </Grid>
      )}
      {secondLineNewHome && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={130} width="100%" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={130} width="100%" />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton height={450} width="100%" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton height={450} width="100%" />
        </Grid>
      </Grid>
    </Box>
  );
}
