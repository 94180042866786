import { Menu, MenuItem } from '@material-ui/core';

import { FilterTypeEnum } from '..';

import { useStyles } from './styles';

export type TypeProps = {
  onApplyType: ({ type }: { type: FilterTypeEnum }) => void;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

export function Type({ anchorEl, onApplyType, handleClose }: TypeProps) {
  const classes = useStyles();

  const handleApplyType = (type: FilterTypeEnum) => {
    onApplyType({ type });
    handleClose();
  };

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyType(FilterTypeEnum.Incident)}>
        Incidents
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => handleApplyType(FilterTypeEnum.Deploy)}>
        Deploy
      </MenuItem>
    </Menu>
  );
}
