import { severityOptions } from 'constants/severityOptions';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import { CreateApplicationMutation } from 'graphqlQueries/createApplication';
import { CombinedError, useMutation } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import SelectSimple from 'componentsV3/Select';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { Theme } from 'theme/v3';
import withThemeV3Hoc from 'theme/v3/withTheme';

import EnvironmentSelect from '../../../Application/ApplicationForm/EnvironmentSelect';

import mapDataToApi from './mapDataToApi';
import useForm from './useForm';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  text: {
    marginTop: spacing(2),
    marginBottom: spacing(4)
  },
  button: {
    marginTop: spacing(9)
  },
  serverError: {
    color: palette.error.main,
    marginTop: spacing(-2)
  },
  sectionDivider: {
    border: `${palette.grey[300]} 1px solid`,
    marginTop: spacing(6)
  }
}));

const defaultValues = {
  name: '',
  environmentId: ''
};

function TabApplicationWebhook({
  productId,
  onFinish
}: {
  productId: number | null;
  onFinish: (data: { applicationId: number; sterasId: number }) => void;
}) {
  const classes = useStyles();

  const {
    formState: { isValid },
    handleSubmit,
    errors,
    control,
    register,
    setValue,
    formState
  } = useForm({
    defaultValues: defaultValues
  });

  const useSeverityOnApplication = useFeatureFlagUnleash('chooseSeverityOnApplication', {
    queryString: true
  });

  const [createApplicationResult, createApplication] = useMutation(CreateApplicationMutation);

  const submitMutation = async (data: any) => {
    const { ...applicationData } = data;
    const mappedData = mapDataToApi({ ...applicationData, productId });

    const applicationResponse = await createApplication({
      applicationInput: { ...mappedData, productId }
    });

    onFinish({
      applicationId: applicationResponse.data.createApplication.id,
      sterasId: applicationResponse.data.createApplication.sterasId
    });
  };

  const serversErrorsMessages = (appError: CombinedError | undefined) => {
    if (appError?.graphQLErrors) return appError.graphQLErrors[0]?.message;
    return null;
  };

  const errorMessage = serversErrorsMessages(createApplicationResult.error);

  return (
    <>
      <Typography className={classes.text} variant="h2">
        Configure Application.
      </Typography>
      <form onSubmit={handleSubmit(submitMutation)}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <InputDefault
              inputRef={register}
              errors={errors}
              label="Application Name"
              placeholder="Ex: My Website"
              name="name"
              required
              control={control}
            />
          </Grid>
          <Grid item xs={6} />

          <Grid item xs={6}>
            <EnvironmentSelect
              defaultLabel={'Select an Environment'}
              errors={errors}
              required
              control={control}
              name="environmentId"
              setValue={setValue}
              formState={formState}
              selectFirst
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <InputDefault
              errors={errors}
              inputRef={register}
              label="Interval (sec)"
              name="check_interval"
              type="number"
              defaultValue={60}
            />
          </Grid>

          <Grid item xs={3}>
            <InputDefault
              errors={errors}
              inputRef={register}
              label="Fails to open automatic incident"
              name="failuresToIncident"
              type="number"
              defaultValue={2}
            />
          </Grid>

          {useSeverityOnApplication && (
            <>
              <Grid item xs={6} />

              <Grid item xs={6}>
                <SelectSimple
                  errors={errors}
                  name="openIncidentWithSeverity"
                  label="Severity"
                  defaultValue="not-classified"
                  control={control}
                  items={severityOptions}
                />
              </Grid>
            </>
          )}

          <Grid item xs={6} />
          <Grid item className={classes.button} xs={6}>
            <ButtonSimple
              disabled={!isValid}
              type="submit"
              color="primary"
              text="Perform setup on my system"
            />
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Typography className={classes.serverError}>{errorMessage}</Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
}

export default withThemeV3Hoc(TabApplicationWebhook);
