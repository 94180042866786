import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function DashboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="479"
      height="255"
      viewBox="0 0 479 255"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clip-rule="evenodd" fill-rule="evenodd">
        <path
          d="M274.54 251.877L267.751 249.021L263.094 215.025H201.074L196.026 248.882L189.953 251.916C189.662 252.061 189.429 252.3 189.291 252.594C189.154 252.888 189.119 253.22 189.194 253.536C189.269 253.851 189.448 254.133 189.703 254.334C189.957 254.536 190.273 254.645 190.598 254.645H273.981C274.314 254.645 274.636 254.53 274.894 254.32C275.152 254.109 275.329 253.816 275.395 253.49C275.46 253.164 275.411 252.825 275.256 252.531C275.1 252.237 274.847 252.006 274.54 251.877Z"
          fill="#E6E6E6"
        />
        <path
          d="M384.752 219.548H80.0955C78.4971 219.544 76.9653 218.908 75.8363 217.777C74.7073 216.647 74.0733 215.115 74.0732 213.519V170.605H390.775V213.519C390.775 215.115 390.141 216.647 389.011 217.777C387.882 218.908 386.351 219.544 384.752 219.548Z"
          fill="#CCCCCC"
        />
        <path
          d="M391.094 190.887H73.7959V7.26809C73.7981 5.34111 74.5655 3.4937 75.9295 2.13114C77.2936 0.768587 79.1431 0.00215988 81.0722 0H383.817C385.746 0.00215861 387.596 0.768582 388.96 2.13114C390.324 3.49369 391.092 5.34111 391.094 7.26809V190.887Z"
          fill="#3F3D56"
        />
        <path
          d="M372.142 177.56H92.7462C91.259 177.558 89.8332 176.968 88.7816 175.917C87.7299 174.867 87.1384 173.442 87.1367 171.957V18.9303C87.1384 17.4447 87.7299 16.0204 88.7815 14.9699C89.8331 13.9194 91.259 13.3285 92.7462 13.3268H372.142C373.629 13.3285 375.055 13.9194 376.107 14.9699C377.158 16.0204 377.75 17.4447 377.752 18.9303V171.957C377.75 173.442 377.158 174.867 376.107 175.917C375.055 176.968 373.629 177.558 372.142 177.56Z"
          fill="white"
        />
        <path
          d="M478.29 255H0.738584C0.577762 255.003 0.420327 254.954 0.290809 254.858C0.161291 254.763 0.0669646 254.628 0.0225271 254.473C-0.00521573 254.368 -0.00844482 254.258 0.0130853 254.151C0.0346155 254.045 0.0803293 253.945 0.146715 253.859C0.213101 253.772 0.298386 253.703 0.396007 253.655C0.493628 253.607 0.600984 253.581 0.709812 253.581H478.232C478.401 253.575 478.568 253.627 478.704 253.728C478.841 253.829 478.939 253.972 478.983 254.136C479.006 254.24 479.006 254.348 478.982 254.451C478.958 254.555 478.91 254.651 478.844 254.734C478.777 254.817 478.693 254.884 478.597 254.93C478.501 254.976 478.396 255 478.29 255Z"
          fill="#CCCCCC"
        />
        <path d="M148.129 71.1993H102.159V75.5117H148.129V71.1993Z" fill="#E5E5E5" />
        <path d="M148.129 81.6573H102.159V85.9697H148.129V81.6573Z" fill="#E5E5E5" />
        <path d="M148.129 92.3463H102.159V96.6587H148.129V92.3463Z" fill="#E5E5E5" />
        <path d="M148.129 103.035H102.159V107.348H148.129V103.035Z" fill="#E5E5E5" />
        <path d="M137.241 116.618H113.048V120.36H137.241V116.618Z" fill="#5FBC91" />
        <path d="M219.66 97.9454H219.221V99.2627H219.66V97.9454Z" fill="#E5E5E5" />
        <path
          d="M219.66 127.329H219.221V124.522H219.66V127.329ZM219.66 121.715H219.221V118.909H219.66V121.715ZM219.66 116.102H219.221V113.296H219.66V116.102ZM219.66 110.489H219.221V107.682H219.66V110.489ZM219.66 104.876H219.221V102.069H219.66V104.876Z"
          fill="#E5E5E5"
        />
        <path d="M219.66 130.135H219.221V131.452H219.66V130.135Z" fill="#E5E5E5" />
        <path d="M267.791 97.9454H267.352V99.2627H267.791V97.9454Z" fill="#E5E5E5" />
        <path
          d="M267.791 127.329H267.352V124.522H267.791V127.329ZM267.791 121.715H267.352V118.909H267.791V121.715ZM267.791 116.102H267.352V113.296H267.791V116.102ZM267.791 110.489H267.352V107.682H267.791V110.489ZM267.791 104.876H267.352V102.069H267.791V104.876Z"
          fill="#E5E5E5"
        />
        <path d="M267.791 130.135H267.352V131.452H267.791V130.135Z" fill="#E5E5E5" />
        <path d="M292.07 72.1769H291.631V73.4941H292.07V72.1769Z" fill="#E5E5E5" />
        <path
          d="M292.07 127.438H291.631V124.741H292.07V127.438ZM292.07 122.044H291.631V119.346H292.07V122.044ZM292.07 116.649H291.631V113.952H292.07V116.649ZM292.07 111.255H291.631V108.558H292.07V111.255ZM292.07 105.861H291.631V103.163H292.07V105.861ZM292.07 100.466H291.631V97.7689H292.07V100.466ZM292.07 95.0717H291.631V92.3745H292.07V95.0717ZM292.07 89.6773H291.631V86.9801H292.07V89.6773ZM292.07 84.2828H291.631V81.5857H292.07V84.2828ZM292.07 78.8885H291.631V76.1913H292.07V78.8885Z"
          fill="#E5E5E5"
        />
        <path d="M292.07 130.135H291.631V131.452H292.07V130.135Z" fill="#E5E5E5" />
        <path d="M316.108 61.6389H315.668V62.9561H316.108V61.6389Z" fill="#E5E5E5" />
        <path
          d="M316.108 127.448H315.668V124.761H316.108V127.448ZM316.108 122.074H315.668V119.387H316.108V122.074ZM316.108 116.699H315.668V114.012H316.108V116.699ZM316.108 111.325H315.668V108.638H316.108V111.325ZM316.108 105.951H315.668V103.264H316.108V105.951ZM316.108 100.576H315.668V97.8892H316.108V100.576ZM316.108 95.2021H315.668V92.5149H316.108V95.2021ZM316.108 89.8278H315.668V87.1406H316.108V89.8278ZM316.108 84.4535H315.668V81.7663H316.108V84.4535ZM316.108 79.0792H315.668V76.3919H316.108V79.0792ZM316.108 73.7049H315.668V71.0176H316.108V73.7049ZM316.108 68.3305H315.668V65.6433H316.108V68.3305Z"
          fill="#E5E5E5"
        />
        <path d="M316.108 130.135H315.668V131.452H316.108V130.135Z" fill="#E5E5E5" />
        <path d="M243.835 80.2998H243.396V81.617H243.835V80.2998Z" fill="#E5E5E5" />
        <path
          d="M243.835 127.582H243.396V125.028H243.835V127.582ZM243.835 122.475H243.396V119.921H243.835V122.475ZM243.835 117.367H243.396V114.814H243.835V117.367ZM243.835 112.26H243.396V109.707H243.835V112.26ZM243.835 107.153H243.396V104.599H243.835V107.153ZM243.835 102.046H243.396V99.4922H243.835V102.046ZM243.835 96.9386H243.396V94.385H243.835V96.9386ZM243.835 91.8314H243.396V89.2778H243.835V91.8314ZM243.835 86.7242H243.396V84.1707H243.835V86.7242Z"
          fill="#E5E5E5"
        />
        <path d="M243.835 130.135H243.396V131.452H243.835V130.135Z" fill="#E5E5E5" />
        <path
          d="M340.486 132.064H194.966C194.845 132.064 194.73 132.016 194.644 131.931C194.559 131.846 194.511 131.73 194.511 131.609V61.9373C194.511 61.8167 194.559 61.701 194.644 61.6157C194.73 61.5303 194.845 61.4824 194.966 61.4824C195.087 61.4824 195.203 61.5303 195.288 61.6157C195.374 61.701 195.422 61.8167 195.422 61.9373V131.154H340.486C340.607 131.154 340.723 131.202 340.808 131.287C340.894 131.373 340.942 131.489 340.942 131.609C340.942 131.73 340.894 131.846 340.808 131.931C340.723 132.016 340.607 132.064 340.486 132.064Z"
          fill="#3F3D56"
        />
        <path
          d="M267.772 98.5567L243.577 80.4546L219.713 98.3095L219.167 97.5812L243.577 79.3179L267.656 97.334L291.584 71.1771L291.681 71.138L315.817 61.4236L316.158 62.268L292.117 71.9434L267.772 98.5567Z"
          fill="#3F3D56"
        />
        <path
          d="M219.44 100.675C220.95 100.675 222.173 99.4529 222.173 97.9454C222.173 96.438 220.95 95.2159 219.44 95.2159C217.931 95.2159 216.708 96.438 216.708 97.9454C216.708 99.4529 217.931 100.675 219.44 100.675Z"
          fill="#5FBC91"
        />
        <path
          d="M243.577 82.9332C245.086 82.9332 246.31 81.7112 246.31 80.2037C246.31 78.6963 245.086 77.4742 243.577 77.4742C242.068 77.4742 240.845 78.6963 240.845 80.2037C240.845 81.7112 242.068 82.9332 243.577 82.9332Z"
          fill="#5FBC91"
        />
        <path
          d="M267.714 100.675C269.223 100.675 270.446 99.4529 270.446 97.9454C270.446 96.438 269.223 95.2159 267.714 95.2159C266.205 95.2159 264.981 96.438 264.981 97.9454C264.981 99.4529 266.205 100.675 267.714 100.675Z"
          fill="#5FBC91"
        />
        <path
          d="M291.851 74.2898C293.36 74.2898 294.583 73.0678 294.583 71.5603C294.583 70.0528 293.36 68.8308 291.851 68.8308C290.342 68.8308 289.118 70.0528 289.118 71.5603C289.118 73.0678 290.342 74.2898 291.851 74.2898Z"
          fill="#5FBC91"
        />
        <path
          d="M315.987 64.2816C317.496 64.2816 318.72 63.0596 318.72 61.5521C318.72 60.0447 317.496 58.8226 315.987 58.8226C314.478 58.8226 313.255 60.0447 313.255 61.5521C313.255 63.0596 314.478 64.2816 315.987 64.2816Z"
          fill="#5FBC91"
        />
        <path d="M162.497 13.3267H161.566V177.56H162.497V13.3267Z" fill="#E5E5E5" />
        <path
          d="M381.859 175.879C382.181 175.497 382.414 175.049 382.544 174.567C382.673 174.085 382.695 173.581 382.608 173.089C382.521 172.598 382.327 172.131 382.04 171.723C381.753 171.314 381.38 170.974 380.947 170.725L383.218 158.807L377.393 161.232L376.129 172.177C375.759 172.932 375.678 173.796 375.903 174.605C376.128 175.415 376.642 176.114 377.348 176.571C378.055 177.027 378.904 177.209 379.735 177.081C380.567 176.954 381.323 176.526 381.859 175.879Z"
          fill="#FFB7B7"
        />
        <path
          d="M410.905 249.006H405.973L403.626 230.001L410.906 230.001L410.905 249.006Z"
          fill="#FFB7B7"
        />
        <path
          d="M412.163 253.782L396.259 253.782V253.581C396.259 251.941 396.911 250.368 398.072 249.208C399.233 248.049 400.808 247.397 402.449 247.397L412.164 247.397L412.163 253.782Z"
          fill="#2F2E41"
        />
        <path
          d="M383.949 249.006H379.017L376.67 230.001L383.95 230.001L383.949 249.006Z"
          fill="#FFB7B7"
        />
        <path
          d="M384 253.782L368.096 253.782V253.581C368.096 251.941 368.748 250.368 369.909 249.208C371.07 248.049 372.644 247.397 374.286 247.397L384.001 247.397L384 253.782Z"
          fill="#2F2E41"
        />
        <path
          d="M383.545 168.739L378.717 179.849L374.734 243.711L384.793 243.309L387.54 212.866L396.014 185.751L400.886 215.98L402.898 242.505L411.221 242.865L414.537 168.739H383.545Z"
          fill="#2F2E41"
        />
        <path
          d="M407.679 113.914L393.686 114.263L385.47 118.476L385.019 149.558L379.896 171.017C379.896 171.017 411.91 173.218 416.335 170.003L411.105 145.487L418.12 118.993L407.679 113.914Z"
          fill="#5FBC91"
        />
        <path
          d="M386.965 118.962L385.47 118.476C385.47 118.476 380.729 121.516 381.18 128.865C381.18 128.865 381.002 132.668 381.172 135.199C381.366 138.102 375.539 166.948 375.539 166.948H383.988L387.609 140.825L386.965 118.962Z"
          fill="#5FBC91"
        />
        <path
          d="M417.9 176.281C417.579 175.899 417.345 175.451 417.216 174.969C417.086 174.487 417.064 173.982 417.151 173.491C417.238 173 417.432 172.533 417.719 172.125C418.006 171.716 418.379 171.375 418.812 171.126L416.541 159.209L422.366 161.634L423.63 172.579C424.001 173.334 424.081 174.198 423.856 175.007C423.632 175.817 423.117 176.516 422.411 176.973C421.705 177.429 420.855 177.61 420.024 177.483C419.193 177.356 418.437 176.928 417.9 176.281Z"
          fill="#FFB7B7"
        />
        <path
          d="M416.014 119.364L417.509 118.878C417.509 118.878 422.251 121.918 421.799 129.267C421.799 129.267 421.977 133.07 421.807 135.6C421.613 138.503 424.221 167.35 424.221 167.35H415.772L412.151 141.227L416.014 119.364Z"
          fill="#5FBC91"
        />
        <path
          d="M411.113 100.874C411.113 102.79 410.545 104.664 409.479 106.257C408.413 107.85 406.898 109.092 405.125 109.826C403.353 110.559 401.402 110.751 399.521 110.377C397.639 110.003 395.911 109.08 394.554 107.725C393.197 106.37 392.274 104.644 391.899 102.764C391.525 100.884 391.717 98.9362 392.451 97.1656C393.185 95.3951 394.429 93.8817 396.024 92.817C397.619 91.7523 399.495 91.184 401.413 91.184C402.684 91.1808 403.943 91.4277 405.118 91.9105C406.293 92.3933 407.362 93.1026 408.262 93.9979C409.163 94.8932 409.878 95.9569 410.368 97.1284C410.857 98.2999 411.11 99.5561 411.113 100.825C411.113 100.841 411.113 100.858 411.113 100.874Z"
          fill="#FFB7B7"
        />
        <path
          d="M391.745 87.8587C392.882 85.4179 395.081 85.9004 397.004 86.7278C399.439 86.1882 401.756 84.5726 404.38 85.528C406.965 89.2834 415.643 88.1785 413.797 94.2923C413.794 95.7572 416.556 94.9045 416.074 97.3038C417.537 101.921 410.793 110.666 406.921 108.825C407.878 107.071 410.066 103.09 406.748 102.695C399.611 109.328 406.012 90.063 397.044 95.6656C394.075 98.3799 390.01 90.5737 391.745 87.8587Z"
          fill="#2F2E41"
        />
      </g>
    </SvgIcon>
  );
}
