export const createAccountMutation = `#graphql
  mutation($createAccountInputs: [CreateAccountInputs!]!) {
    createAccounts(createAccountInputs: $createAccountInputs){
      message
    }
  }
`;

export const createAccountWithAragornMutation = `#graphql
  mutation($createAccountWithAragornInputs: [CreateAccountWithAragornInputs!]!) {
    createAccountsWithAragorn(createAccountWithAragornInputs: $createAccountWithAragornInputs){
      message
    }
  }
`;
