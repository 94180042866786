import { put, takeLatest } from 'redux-saga/effects';

import { actions } from '../actionCreators';

function* prepareSave(axios, action) {
  try {
    yield axios.post('/teams', action.payload);
    yield put({ type: actions.PRODUCT_NEW_FETCH_TEAMS });
  } catch (err) {
    yield put({
      type: action.type,
      payload: err
    });
  }
}

export default function* watchNewTeam(axios) {
  yield takeLatest(actions.PRODUCT_AVAILABILITY_NEW_TEAM, prepareSave, axios);
}
