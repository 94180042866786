import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>(({ palette, spacing, typography }: Theme) => ({
  container: {
    padding: spacing(6, 5),
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    '& .react-swipeable-view-container [role="tabpanel"]': {
      margin: '0 !important'
    }
  },
  body: {
    color: palette.grey[800],
    fontSize: typography.pxToRem(16)
  },
  tabs: {
    marginTop: spacing(3.5)
  },
  tabsFlexContainer: {
    borderBottom: '2px solid #d5d5d5'
  },
  iframe: {
    border: 'none'
  }
}));

export default useStyles;
