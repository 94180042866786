import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type MongoDBFormData = {
  protocol: string;
  user: string;
  password: string;
  host: string;
  port: string;
  database: string;
};

export function MongoDBForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <>
      {domainType === 'userAndPass' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'User',
                name: 'user',
                required: false
              }}
              InputComponent={Input}
            />
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'password',
                label: 'Password',
                name: 'password'
              }}
              InputComponent={Input}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <Box display="flex" alignItems="center" gridGap="1rem">
              <Select
                options={[
                  { value: 'mongodb', label: 'mongodb://' },
                  { value: 'mongodb+srv', label: 'mongodb+srv://' }
                ]}
                control={control}
                name="protocol"
                label="Protocol"
                inputRef={register}
                required={true}
              />
            </Box>

            <Box display="flex" alignItems="center" width={'100%'}>
              <InputWithSecrets
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                TextFieldProps={{
                  type: 'text',
                  label: 'Host',
                  name: 'host'
                }}
                InputComponent={Input}
              />
            </Box>

            <Box display="flex" alignItems="center" gridGap="1rem">
              <Input
                label="Port"
                name="port"
                inputRef={register}
                type="number"
                inputProps={{
                  min: 1,
                  max: 65535
                }}
                required={false}
                errors={errors}
              />
            </Box>
          </Box>

          <InputWithSecrets
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            TextFieldProps={{
              type: 'text',
              label: 'Database',
              name: 'database',
              required: false
            }}
            InputComponent={Input}
          />
        </Box>
      )}
    </>
  );
}
