import { useEffect, useState } from 'react';

import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import '@material-ui/core/styles';

import { Button } from 'components/Button';
import { useStyles } from 'helpers/globalStyles';
import useUser from 'hooks/queriesRest/useUser';
import { usePermission } from 'hooks/usePermission';

import ProductsList from '../../V3/Products/List/ProductList';

const Products = ({ showEmptyHome: boolean }) => {
  const classes = useStyles();
  const [firstAccess, setFirstAccess] = useState(false);
  const [
    shouldUpdateHighlightNewResourcesCard,
    setShouldUpdateHighlightNewResourcesCard
  ] = useState(false);

  const { data: user, isLoading } = useUser({});

  useEffect(() => {
    if (shouldUpdateHighlightNewResourcesCard) {
      setShouldUpdateHighlightNewResourcesCard(false);
    }
  }, [shouldUpdateHighlightNewResourcesCard]);

  const hasPermissionCreateResource = usePermission('ResourceController-post-/resource');

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <Typography variant="h4">Welcome, {user?.displayName}</Typography>
        <Box>
          <Button
            title="Create new resource"
            disabled={!hasPermissionCreateResource}
            component={Link}
            to="/services-hub">
            Resource
          </Button>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box marginTop={3} marginBottom={3}>
        <ProductsList
          showEmptyHome={true}
          firstAccess={firstAccess}
          setFirstAccess={setFirstAccess}
        />
      </Box>
    </>
  );
};

export default Products;
