import { useEffect, useState } from 'react';

import agentStatus from 'constants/agentStatus';

import { useQuery } from 'urql';

import useQueryParams from 'helpers/useQueryParams';

const GetAgentStatus = `#graphql
  query ($id: Float!) {
    environment(id: $id) {
      status
    }
  }
`;

const useAgentStatus = ({ id }: { id?: number }) => {
  return useQuery({
    query: GetAgentStatus,
    pause: id === undefined,
    variables: {
      id
    }
  });
};

type Environment = { id: number; agentToken: string; status: keyof typeof agentStatus };

export function useArgsApplication() {
  const [environment, setEnvironment] = useState<Environment | null>(null);
  const [productId, setProductId] = useState<number | null>(null);

  const [result, reexecuteQuery] = useAgentStatus({ id: environment?.id });

  const currentAgentStatus = result.data?.environment?.status;

  useEffect(() => {
    if (result.fetching) return;

    if (
      environment &&
      (currentAgentStatus === agentStatus.Installing || currentAgentStatus === agentStatus.Created)
    ) {
      const timerId = setTimeout(() => {
        reexecuteQuery({ requestPolicy: 'network-only' });
      }, 1000 * 2 /* 2 seconds */);

      return () => clearTimeout(timerId);
    }

    return;
  }, [result.fetching, reexecuteQuery, environment, currentAgentStatus]);

  const firstAccess = useQueryParams().get('firstAccess');

  return {
    environment,
    productId,
    result,
    reexecuteQuery,
    currentAgentStatus,
    firstAccess,
    setEnvironment,
    setProductId
  };
}
