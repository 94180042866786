import { PropsWithChildren } from 'react';

import { Box, Container, CssBaseline, useTheme } from '@material-ui/core';

import { withThemeV5 } from 'theme/v5';

import { Toolbar } from '../components/Toolbar';

function MainLayout({ children }: PropsWithChildren<{}>) {
  const theme = useTheme();

  return (
    <>
      <CssBaseline />
      <Box height="100%">
        <Toolbar />

        <Box padding={theme.spacing(3, 8.75)} width="100%">
          <Container maxWidth="xl" disableGutters>
            <>{children}</>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default withThemeV5(MainLayout);
