import { ReactNode, useEffect, useState } from 'react';

import {
  ClickAwayListener,
  Grow,
  MenuList,
  MenuListProps as MuiMenuListProps,
  Paper,
  Popper,
  PopperProps
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

export type MenuProps = {
  anchorElId?: string;
  anchorEl?: HTMLElement | null;
  placement?: PopperProps['placement'];
  menuListProps?: Omit<MuiMenuListProps, 'children'>;
  popperProps?: Omit<PopperProps, 'children' | 'open'>;
  children: ReactNode;
  onClose?: () => void;
};

export function Menu({
  anchorElId = '',
  anchorEl,
  placement = 'bottom-start',
  menuListProps = {},
  popperProps = {},
  children,
  onClose = () => {}
}: MenuProps) {
  const classes = useStyles();

  const anchorElement = anchorEl || document.getElementById(anchorElId);

  const [isOpen, setIsOpen] = useState(Boolean(anchorEl));

  useEffect(() => setIsOpen(Boolean(anchorEl)), [anchorEl]);

  if (!anchorElement) return null;

  function handleClose() {
    setIsOpen(false);
    onClose();
  }

  return (
    <Popper
      anchorEl={anchorElement}
      transition
      disablePortal
      placement={placement}
      {...popperProps}
      open={anchorElId ? true : isOpen}
      className={clsx(classes.popper, popperProps?.className)}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'top center' }}>
          <Paper>
            <ClickAwayListener onClickAway={() => handleClose()}>
              <MenuList {...menuListProps}>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
