import { MultiFlow } from './MultiFlow';
import { Resource } from './Resource';

export enum ApplicationManagerEntityType {
  Resource = 'resource',
  Multiflow = 'multiflow'
}

export type Dependency = {
  id: number;
  uid: string;
  entityUid: string;
  entity: ApplicationManagerEntityType;
};

export type ApplicationMainMonitoring =
  | {
      entity: ApplicationManagerEntityType.Resource;
      main: Resource;
    }
  | {
      entity: ApplicationManagerEntityType.Multiflow;
      main: MultiFlow;
    };

export type Application = {
  id: number;
  uid: string;
  name: string;
  orgUid: string;
  productId: number | null;
  entityUid: string;
  entity: ApplicationManagerEntityType;
  dependencies?: Dependency[];
} & ApplicationMainMonitoring;
