import React, { useEffect } from 'react';

import { Box, Button } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { UseFormMethods, useFieldArray } from 'react-hook-form';

import { LoadingOverlay } from 'componentsV4/Loading';

import { Select } from '../../../ServicesHub/components/Select';

type DependenciesSelectProps = {
  form: UseFormMethods<any>;
  options: { label: string; value: string; helperText: string }[];
  fetching: boolean;
  required: boolean;
  refetch: () => void;
};

export function DependenciesSelect({
  form: { control, register },
  options,
  required,
  fetching
}: DependenciesSelectProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dependencies'
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ entityUid: '', dependencyUid: '' });
    }
  }, [fields, append]);

  if (fetching) {
    return <LoadingOverlay />;
  }

  return (
    <Box position="relative" display="flex" gridGap="2rem" flexDirection="column">
      {fields.map((field, index) => {
        return (
          <React.Fragment key={field.id}>
            <Box display="flex" gridGap="1rem">
              <Select
                options={options}
                name={`dependencies.${index}.entityUid`}
                label="Choose dependencies"
                control={control}
                defaultValue={field.entityUid}
                required={required}
              />

              <input
                type="hidden"
                name={`dependencies.${index}.dependencyUid`}
                defaultValue={field.dependencyUid}
                ref={register()}
              />

              <Button variant="outlined" color="primary" onClick={() => remove(index)}>
                <Delete />
              </Button>
            </Box>
          </React.Fragment>
        );
      })}
      <Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => append({ entityUid: '', dependencyUid: '' })}>
          <Add /> DEPENDENCIES
        </Button>
      </Box>
    </Box>
  );
}
