import { RefObject, useCallback } from 'react';

import PostMortemForm from './PostMortemForm';
import PostMortemFormV2 from './PostMortemFormV2';

function PostMortemFormIndex(props: {
  incident: {
    postMortem?: { id: number; postMortem: string };
    incidentId: number;
    applicationId: number;
    applicationUid: string;
    addonId: number;
    serviceId: number;
  };
  postMortemRef: RefObject<HTMLDivElement>;
  onPostMortemSave: () => void;
}) {
  const PostMortemFormHoc = useCallback(
    otherProps => <PostMortemForm {...props} {...otherProps} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <PostMortemFormV2 Component={PostMortemFormHoc} />;
}

export default PostMortemFormIndex;
