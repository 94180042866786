import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';
import { service_entity_new } from 'redux/reducer';
import reducer from 'redux/reducer/services';

import Dialog from './Dialog';

class ServicesNew extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {
      productId,
      applicationId,
      services_new: formState,
      application,
      onChange,
      reset,
      addSettings,
      removeSettings,
      clouds,
      disabled,
      onAddAddon
    } = this.props;

    const formProps = {
      addSettings,
      formState,
      onChange,
      onCancel: reset,
      application,
      removeSettings,
      clouds: clouds.data
    };

    return (
      <Dialog
        productId={productId}
        applicationId={applicationId}
        application={application}
        formProps={formProps}
        disabled={disabled}
        onAddAddon={onAddAddon}
        onReset={reset}
        isAddon
      />
    );
  }
}

export default connect(
  ({ services_new, clouds }) => ({ services_new, clouds }),
  dispatch => ({
    fetch: () =>
      dispatch({
        type: actions.FETCH_SERVICE
      }),
    reset: () =>
      dispatch({
        type: actions.RESET_FORM_ENTITY,
        payload: { ...service_entity_new },
        meta: {
          entity: 'services_new'
        }
      }),
    onChange: (name, value, uid) => {
      dispatch({
        type: actions.SERVICE_NEW_POPULATE_SETTINGS_ENTITY,
        payload: {
          [name]: value
        },
        meta: {
          entity: 'services_new',
          uid,
          touched: {
            [name]: true
          },
          reducer
        }
      });
    },
    addSettings: () => {
      dispatch({
        type: actions.SERVICE_NEW_SETTINGS_ADD,
        meta: {
          reducer
        }
      });
    },
    removeSettings: () => {
      dispatch({
        type: actions.SERVICE_NEW_SETTINGS_REMOVE,
        meta: {
          reducer
        }
      });
    }
  })
)(ServicesNew);
