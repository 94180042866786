import { Box } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

export type TeamsFormInput = {
  name: string;
  description: string;
};

export function TeamsForm({ form }: FormProps) {
  const { register } = form;

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Input label="Name" name="name" inputRef={register} errors={form.errors} />
    </Box>
  );
}
