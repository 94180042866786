import React, { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import clsx from 'clsx';
import { formatDistanceStrict } from 'date-fns';
import { ForceFinishMaintenance } from 'graphqlQueries/forceFinishMaintenance';
import { DeleteEntity } from 'oldComponents/DeleteDialog';
import MaintenanceMessage from 'oldComponents/MaintenanceMessage';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { ResourceOrigin, ResourceQuery, ResourceStatusEnum } from 'types/external/Resource';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';
import { useMutation, useQuery } from 'urql';

import Breadcrumb from 'components/Breadcrumb';
import { Menu } from 'components/Menu';
import { Title } from 'components/Title';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { maintenanceMessage } from 'helpers/maintenanceMessage';
import useUser from 'hooks/queriesRest/useUser';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import {
  EventsHistory,
  eventHistoryCustomElasticQuery
} from 'views/Applications/View/components/EventsHistory';
import LatencyTabs from 'views/Metrics/TabLatencies';
import { StatusChip } from 'views/Monitoring/List/components/StatusChip';
import canHiddenLatencies, {
  IndicesEnum
} from 'views/Products/View/Availability/View/utils/canHiddenLatencies';

import { ErrorBudget } from './components/ErrorBudget';
import HitsAndFailuresModal from './components/HitsAndFailureModal';
import { MaintenanceButton } from './components/MaintenanceButton';
import { MonitoringButton } from './components/MonitoringButton';
import { MttsCard } from './components/MttsCard';
import { ScheduledEventButton } from './components/ScheduledEventButton';
import { Uptime } from './components/Uptime';
import { maintenanceDefaultValue } from './defaultValue';
import { LoadingSkeleton } from './LoadingSkeleton';
import { useStyles } from './styles';

const GetResourceQuery = `#graphql
  query(
    $resourceUid: String!
  ) {
    resource(
      resourceUid: $resourceUid
    ) {
      uid
      id
      name
      interval
      timeout
      origin
      type
      method
      serviceId
      environmentId
      status {
        status
        lastCheck
      }
      environment {
        id
        name
        statusCode
      }
    }
  }
`;

const DeleteResourceMutation = `#graphql
  mutation ($resourceUid: String!){
      deleteResource(resourceUid: $resourceUid)
  }
`;

const eventsHistoryQuery = `#graphql
    query (
        $from: Int!
        $size: Int!
        $query: String!
        $aggregationRefs: String
        $aggregationKeys: String!
        $aggregationValues: String
        $termKey: String
        $termValue: [String!]
        $betweenKey: String
        $betweenValues: String
    ) {
        resourceEvents(
          from: $from
          size: $size
          query: $query
          aggregationRefs: $aggregationRefs
          aggregationKeys: $aggregationKeys
          aggregationValues: $aggregationValues
          termKey: $termKey
          termValue: $termValue
          betweenKey: $betweenKey
          betweenValues: $betweenValues
        ) {
          data {
            id
            incidentId
            eventId
            content {
              name
              createdAt
              description
              version
              severity
              private
            }
            createdAt
            type
            event
            eventType
          }
          total
        }
    }
`;

const PER_PAGE = 10;

export function ResourceMonitoring() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [monitoringLoading, setMonitoringLoading] = React.useState(false);
  const [maintenance, setMaintenance] = React.useState(maintenanceDefaultValue);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | StatusEnum>('all');
  const [severity, setSeverity] = useState<'all' | SeverityStatusEnum>('all');
  const [betweenValues, setBetweenValues] = useState('now-1y,now');
  const [page, setPage] = useState(1);

  const { resourceUid } = useParams<{ resourceUid: string }>();

  const shouldUseInsightsWithExploreFurtherTab = useFeatureFlagUnleash(
    'useInsightsWithExploreFurtherTab',
    {
      queryString: true
    }
  );

  const { data: user } = useUser({});
  const [{ fetching: getResourceLoading, data }, reexecuteResourceQuery] = useQuery<{
    resource: ResourceQuery;
  }>({
    query: GetResourceQuery,
    variables: {
      resourceUid
    },
    pause: !user
  });

  const resource = data?.resource;

  const [resultEventsQuery, reexecuteEventsQuery] = useQuery({
    query: eventsHistoryQuery,
    pause: !resource?.uid,
    variables: {
      query: eventHistoryCustomElasticQuery({
        queryFilters: [
          {
            key: 'title',
            value: search,
            isRegex: true
          },
          {
            key: 'status',
            value: status,
            isRegex: false
          },
          {
            key: 'severity',
            value: severity,
            isRegex: false
          }
        ]
      }),
      from: Math.max(page - 1, 0) * PER_PAGE,
      size: PER_PAGE,
      aggregationRefs: 'incidents',
      aggregationKeys: 'value_count',
      aggregationValues: 'id',
      termKey: 'origins.uid',
      termValue: resource?.uid,
      betweenKey: 'created_at',
      betweenValues
    }
  });

  const { fetching: fetchingEvents } = resultEventsQuery;

  const dataFromGraphQL = resultEventsQuery.data?.resourceEvents || [];
  const events = dataFromGraphQL || [];

  React.useEffect(() => {
    if (getResourceLoading) return;

    const intervalId = setInterval(() => {
      reexecuteResourceQuery();
    }, 1000 * 10);

    return () => clearInterval(intervalId);
  }, [getResourceLoading, reexecuteResourceQuery]);

  const monitoringIsActive = React.useCallback(() => {
    if (!resource) return false;

    if (
      resource.status.status === 'available' ||
      resource.status.status === 'pending' ||
      resource.status.status === 'unavailable'
    ) {
      return true;
    }

    return false;
  }, [resource]);

  const previousDayDate = new Date();
  previousDayDate.setDate(previousDayDate.getDate() - 1);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const [{ fetching: deleteFetching }, deleteResourceMutation] = useMutation(
    DeleteResourceMutation
  );

  const deleteResource = () => {
    deleteResourceMutation(
      { resourceUid },
      injectAuditTrailMetadata({
        resourceName: resource!.name
      })
    ).then(({ error }) => {
      if (error) {
        const resultError = error.graphQLErrors[0]
          ? error.graphQLErrors[0].extensions?.serviceResponseData.error
          : '';

        const businessRule = resultError.includes('contains this resource');

        dispatch({
          type: actions.ENTITY_ERROR,
          payload: {
            message: businessRule ? resultError : 'Error on remove Entity'
          }
        });

        handleDeleteDialog();

        if (!businessRule) apm.captureError(error);
      } else {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Resource deleted successfully'
        });

        history.push('/monitoring');
      }
    });
  };

  const orgUid = user?.logged?.org?.uid;

  const hasPermissionEdit = usePermission('ResourceController-put-/resources/:uid');
  const hasPermissionDelete = usePermission('ResourceController-delete-/resource/:uid');

  const hasPermissionCreateScheduledEvent = usePermission(
    'ScheduledEventController-post-/scheduledEvent'
  );

  const hasPermissionEditScheduledEvent = usePermission(
    'ScheduledEventController-put-/scheduledEvent'
  );

  const isAllowedToUseMaintenance = usePermission('MaintenanceController-post-/maintenance');

  const inMaintenance = Boolean(
    maintenance?.status === 'Initialized' &&
      resource?.status.status === ResourceStatusEnum['Maintenance']
  );

  const isAPI = resource?.origin === ResourceOrigin['API'];
  const isPool = resource?.origin === ResourceOrigin['Pool'];
  const isStatic = resource?.origin === ResourceOrigin['Static'];

  const allowMaintenance = !isStatic;
  const allowScheduledEvent = !isStatic;

  const maintenanceButtonEnabled =
    (!monitoringIsActive() || !isAllowedToUseMaintenance) && !maintenance?.id;

  const scheduledEventButtonEnabled = !(
    hasPermissionCreateScheduledEvent && hasPermissionEditScheduledEvent
  );

  const [, forceFinishMaintenance] = useMutation(ForceFinishMaintenance);

  const onForceFinish = React.useCallback(
    (data: { id: number; start: string; finish: string }) => {
      forceFinishMaintenance({
        maintenanceInput: {
          id: data?.id,
          serviceId: 0,
          applicationId: 0,
          start: data.start,
          finish: data.finish,
          uid: resource?.uid
        }
      }).then(result => {
        if (!result.error?.graphQLErrors) {
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Finishing maintenance'
          });

          reexecuteResourceQuery();
        }
      });
    },
    [reexecuteResourceQuery, forceFinishMaintenance, dispatch, resource?.uid]
  );

  const disableMonitoringButton = () => {
    if (inMaintenance || getResourceLoading) {
      return true;
    }

    if (isPool || isAPI || isStatic) {
      return false;
    }

    if (resource?.environment?.statusCode !== 16) {
      return true;
    }

    return false;
  };

  const checkpointName = () => {
    if (isAPI) {
      return ' API';
    }

    if (isPool) {
      return ' Pool - N. Virginia';
    }

    if (isStatic) {
      return ' Static';
    }

    return ` Agent - ${resource?.environment?.name}`;
  };

  const resourceMetricsLink = `/metrics?tab=monitoring-performance&resource=${resource?.name}`;

  const [openingHitsAndFailures, setOpeningHitsAndFailures] = React.useState(false);

  const HitsAndFailuresMenuItem = ({ onClick }: { onClick: () => void }) => {
    return (
      <MenuItem
        className={clsx(openingHitsAndFailures && classes.menuItem)}
        onClick={onClick}
        disabled={!monitoringIsActive()}>
        Hits and Failures API
      </MenuItem>
    );
  };

  if (getResourceLoading) {
    return <LoadingSkeleton />;
  }

  if (!resource) {
    return <Redirect to="/not-found" />;
  }

  const hiddenLatencies = canHiddenLatencies(resource?.type, resource?.method as IndicesEnum);

  return (
    <>
      {inMaintenance && (
        <MaintenanceMessage
          maintenance={maintenance}
          message={maintenanceMessage({
            start: maintenance?.start,
            finish: maintenance?.finish,
            entity: 'resource'
          })}
          onForceFinish={onForceFinish}
          user={user}
        />
      )}
      <>
        <Backdrop open={monitoringLoading} className={classes.backdrop}>
          <CircularProgress color="primary" size={90} thickness={5} />
        </Backdrop>

        <Box mt={inMaintenance ? 5 : 0}></Box>

        <Breadcrumb
          items={[
            { label: 'Monitoring' },
            { label: 'Resource Center', link: '/monitoring' },
            { label: resource.name }
          ]}
        />

        <Title title={resource?.name}>
          <Box ml="auto">
            <Tooltip
              title={
                !monitoringIsActive()
                  ? ''
                  : shouldUseInsightsWithExploreFurtherTab
                  ? 'Insights'
                  : 'Metrics Hour by Hour'
              }
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
              }}>
              <Button
                className={classes.icons}
                variant="outlined"
                component={NavLink}
                to={resourceMetricsLink}>
                <Icon fontSize="small">query_stats</Icon>
              </Button>
            </Tooltip>

            {allowMaintenance && (
              <MaintenanceButton
                name={resource.name}
                uid={resource.uid}
                entity={'resource'}
                serviceId={0}
                maintenance={maintenance}
                setMaintenance={setMaintenance}
                reexecuteEntityQuery={reexecuteResourceQuery}
                disabled={maintenanceButtonEnabled}
              />
            )}

            {allowScheduledEvent && (
              <ScheduledEventButton
                uid={resource.uid}
                entity={'resource'}
                serviceId={Number(resource.serviceId)}
                disabled={scheduledEventButtonEnabled}
              />
            )}
            {/* <Tooltip
              title={!monitoringIsActive() ? '' : 'Response Time Degradation'}
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
              }}>
              <Button className={classes.icons} variant="outlined" disabled={true}>
                <Icon fontSize="small">stacked_line_chart</Icon>
              </Button>
            </Tooltip> */}
            <Tooltip
              title="More Options"
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow
              }}>
              <Button className={classes.icons} variant="outlined" onClick={handleToggleMenu}>
                <MoreVert />
              </Button>
            </Tooltip>
          </Box>

          <Menu anchorEl={menuAnchorEl} onClose={handleCloseMenu} placement="bottom-end">
            {/* <MenuItem  >Correlations list</MenuItem> */}
            <MenuItem
              disabled={!hasPermissionEdit}
              component={NavLink}
              to={`/services-hub/edit/${resource.uid}?type=${resource?.method?.toLowerCase()}${
                isPool ? '&external=true' : ''
              }`}>
              Edit monitoring, alerts and incidents
            </MenuItem>
            {isAPI && (
              <HitsAndFailuresModal
                resorceUid={resource.uid}
                orgUid={orgUid}
                openerComponent={HitsAndFailuresMenuItem}
                setActivated={setOpeningHitsAndFailures}
              />
            )}
            <MenuItem onClick={handleDeleteDialog} disabled={!hasPermissionDelete}>
              Delete monitoring
            </MenuItem>
          </Menu>

          <DeleteEntity
            open={openDeleteDialog}
            entityName="Resource"
            handleClose={handleDeleteDialog}
            disabled={false}
            isLoading={deleteFetching}
            handleRemove={deleteResource}
          />
        </Title>

        <Paper elevation={1} className={classes.topBox}>
          <StatusChip status={resource.status.status} />

          <Box display="flex" ml={2} alignItems="center">
            <MonitoringButton
              resourceName={resource.name}
              inMaintenance={inMaintenance}
              disabled={disableMonitoringButton()}
              resourceUid={resource.uid}
              monitoringIsActive={monitoringIsActive()}
              reexecuteResourceQuery={reexecuteResourceQuery}
              setMonitoringLoading={setMonitoringLoading}
            />
          </Box>

          <Box display="flex" alignItems="center" className={classes.topBoxText}>
            <Typography variant="body1" className={classes.textTitle}>
              <strong>Checkpoint:</strong>
              {checkpointName()}
            </Typography>
          </Box>

          {!isAPI && (
            <Box display="flex" alignItems="center" className={classes.topBoxText}>
              <Typography variant="body1" className={classes.textTitle}>
                <strong>Last Check:</strong>{' '}
                {new Date(resource.status.lastCheck).getTime() > previousDayDate.getTime()
                  ? formatDistanceStrict(new Date(resource.status.lastCheck), new Date())
                  : 'No data'}
              </Typography>
            </Box>
          )}
        </Paper>

        <Box display="flex" mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MttsCard
                name="MTBF"
                originUid={resource.uid}
                entity="resource"
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
            <Grid item xs={4}>
              <MttsCard
                name="MTTA"
                originUid={resource.uid}
                entity="resource"
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
            <Grid item xs={4}>
              <MttsCard
                name="MTTRecovery"
                originUid={resource.uid}
                entity="resource"
                monitoringIsActive={monitoringIsActive()}
              />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Paper elevation={1} className={classes.paperMiddle}>
                <Typography variant="subtitle2" className={classes.paperTitle}>
                  SRE Metrics | Uptime
                </Typography>

                {monitoringIsActive() ? (
                  <Box mt={1}>
                    <Uptime
                      serviceId={Number(resource.serviceId)}
                      isMonitoring={monitoringIsActive()}
                    />
                  </Box>
                ) : (
                  <Box mt={1}>
                    <Typography className={classes.paperTextMiddle}>Monitoring inactive</Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper elevation={1} className={classes.paperMiddle}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Typography variant="subtitle2" className={classes.paperTitle}>
                    SRE Metrics | Error Budget
                  </Typography>
                  <Tooltip title={'Metric used to display your error budget burned.'}>
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
                <Box>
                  {monitoringIsActive() ? (
                    <ErrorBudget
                      serviceId={Number(resource.serviceId)}
                      isMonitoring={monitoringIsActive()}
                      interval={resource.interval}
                    />
                  ) : (
                    <Box mt={1}>
                      <Typography className={classes.paperTextMiddle}>
                        Monitoring inactive
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {!hiddenLatencies && monitoringIsActive() && (
          <Grid item xs={12}>
            <Box>
              <LatencyTabs uid={resource.uid} />
            </Box>
          </Grid>
        )}

        <Box mt={7}>
          <EventsHistory
            page={page}
            setPage={setPage}
            setSearch={setSearch}
            setStatus={setStatus}
            setSeverity={setSeverity}
            setBetweenValues={setBetweenValues}
            fetching={fetchingEvents}
            reexecuteQuery={reexecuteEventsQuery}
            events={events}
            hideType={true}
          />
        </Box>
      </>
    </>
  );
}
