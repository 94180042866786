import { makeStyles } from '@material-ui/core';
import { MultiFlowStatusEnum } from 'types/external/MultiFlow';

import { Theme } from 'theme/v5';

import { StatusChipProps } from '.';

type StatusChipStyleProps = {
  status: StatusChipProps['status'];
};

function getChipColorByStatus(status: MultiFlowStatusEnum, palette: Theme['palette']) {
  const statusColorMap = {
    [MultiFlowStatusEnum.Off]: palette.gray[500],
    [MultiFlowStatusEnum.Success]: palette.green[500],
    [MultiFlowStatusEnum.Error]: palette.red[500],
    [MultiFlowStatusEnum.Pending]: palette.gray[200],
    [MultiFlowStatusEnum.Maintenance]: '#F57D27'
  };

  return statusColorMap[status];
}

export const useStyles = makeStyles<Theme, StatusChipStyleProps>(theme => ({
  chip: {
    backgroundColor: props => getChipColorByStatus(props.status, theme.palette),
    color: theme.palette.common.white
  }
}));
