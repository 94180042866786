import { useMemo, useState, useEffect } from 'react';

import { Box, CircularProgress, Link, IconButton, Tooltip, Typography } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import dayjs from 'dayjs';
import Fullscreen, { useFullscreenActions } from 'oldComponents/Fullscreen';
import DataTable from 'react-data-table-component';
import { Link as RouterLink } from 'react-router-dom';

import { NewJourneyMatrixType } from 'hooks/queriesGraphQL/useNewJourneyMatrix';
import Status from 'views/Products/View/Availability/List/Datagrid/checkAppAvailability/Status';

import { conditionalCellToAddonStatus } from '../helpers/conditionalCellToAddonStatus';
import { conditionalCellToApplicationStatus } from '../helpers/conditionalCellToApplicationStatus';
import { createEnvironmentsData } from '../helpers/createEnvironmentsData';
import { createFirstColumnWithDependencyName } from '../helpers/createFirstColumnWithDependencyName';
import { Timer } from '../helpers/Timer';
import { Application, validateApplicationStatus } from '../helpers/validateApplicationStatus';
import { validateDependencyStatus } from '../helpers/validateDependencyStatus';

import { useStyles } from './styles';

const customStyles = {
  table: {
    style: {
      border: '1px solid #ececec'
    }
  },
  columns: {
    style: {
      minWidth: '700px'
    }
  },
  headRow: {
    style: {
      minHeight: '32px',
      maxHeight: '32px',
      '& div': {
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }
};

function createFirstRowToApplicationsStatus({ data }: { data: Application[] }) {
  const applicationsStatus: any = {};

  data.forEach(app => (applicationsStatus[app.uid] = app));

  return applicationsStatus;
}

const Grid = ({ title, columns, rows }: { title: JSX.Element; columns: any[]; rows: any[] }) => {
  return (
    <DataTable
      customStyles={customStyles}
      columns={columns}
      data={rows}
      highlightOnHover
      noDataComponent="No data"
      striped
      title={title}
    />
  );
};

const REFRESH_INTERVAL_IN_MS = 1000 * 60; // 60 secs

export const Matrix = ({
  matrix,
  reexecuteQuery,
  fetching
}: {
  matrix: NewJourneyMatrixType;
  reexecuteQuery: () => void;
  fetching: boolean;
}) => {
  const classes = useStyles();

  const [lastUpdate, setLastUpdate] = useState(new Date());

  const productData = matrix || { applications: [] };

  useEffect(() => {
    if (fetching) return;

    const intervalId = setInterval(() => {
      reexecuteQuery();
    }, REFRESH_INTERVAL_IN_MS);

    return () => clearInterval(intervalId);
  }, [fetching, reexecuteQuery]);

  useEffect(() => {
    if (!fetching && matrix) {
      setLastUpdate(new Date());
    }

    return;
  }, [fetching, matrix]);

  const { name: productName, applications } = productData;

  const { open, handleClickOpen, handleClose } = useFullscreenActions();

  const timer = useMemo(
    () => <Timer seconds={REFRESH_INTERVAL_IN_MS / 1000} lastUpdate={lastUpdate} />,
    [lastUpdate]
  );

  if (fetching && !productData) {
    return (
      <Box pt={8} display="flex" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!fetching && !applications.length) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography>No data</Typography>
      </Box>
    );
  }

  const environments = createEnvironmentsData({ data: applications });

  const grids = Object.keys(environments).map(key => {
    const environment = environments[key];

    const columns = [createFirstColumnWithDependencyName()];

    const applicationsStatus = createFirstRowToApplicationsStatus({ data: environment.data });

    const rows = [applicationsStatus];

    environment.data.forEach(
      (app: { dependencies: { entityUid: string; entityName: string }[] }) => {
        const dependencies = app?.dependencies;

        if (dependencies?.length) {
          dependencies.forEach(dependency => {
            const item = {
              apps: [app],
              resourceUid: dependency?.entityUid,
              resourceName: dependency?.entityName
            };

            const found = rows.find(row => row.resourceUid === dependency?.entityUid);

            if (found) {
              found.apps.push(app);
              return;
            }

            rows.push(item);
          });
        }
      }
    );

    environment.data.forEach((app: Application) => {
      columns.push({
        minWidth: '200px',
        name: (
          <Link
            app-data={app.name}
            component={RouterLink}
            to={`/applications/${app.uid}`}
            className={classes.link}>
            <Box
              textAlign="center"
              title={app.name}
              width={environment?.data?.length >= 6 ? '170px' : '100%'}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden">
              {app.name}
            </Box>
          </Link>
        ),
        conditionalCellStyles: [
          conditionalCellToApplicationStatus({ app, status: Status['UNAVAILABLE'] }),
          conditionalCellToApplicationStatus({ app, status: Status['DEGRADED'] }),
          conditionalCellToApplicationStatus({ app, status: Status['AVAILABLE'] }),
          conditionalCellToApplicationStatus({ app, status: Status['MONITORING-IS-INACTIVE'] }),
          conditionalCellToApplicationStatus({ app, status: Status['MAINTENANCE'] }),

          conditionalCellToAddonStatus({ app, status: Status['UNAVAILABLE'] }),
          conditionalCellToAddonStatus({ app, status: Status['DEGRADED'] }),
          conditionalCellToAddonStatus({ app, status: Status['AVAILABLE'] }),
          conditionalCellToAddonStatus({
            app,
            status: Status['DOES-NOT-DEPEND'],
            labelColor: 'black'
          }),
          conditionalCellToAddonStatus({
            app,
            status: Status['MONITORING-IS-INACTIVE']
          }),
          conditionalCellToAddonStatus({ app, status: Status['MAINTENANCE'] })
        ],
        //@ts-ignore
        cell: (row: {
          resourceUid: string;
          apps: {
            uid: string;
            status: string;
          }[];
        }) => {
          if (row.resourceUid) {
            const response = validateDependencyStatus({ app, row });
            return renderCell(response);
          }
          const response = validateApplicationStatus({ application: app });
          return renderCell(response);
        }
      });
    });

    const renderCell = (response: {
      status: { active: boolean; label: string };
      statusService: string;
      sla: number | string;
    }) => {
      if (response.status.active) {
        return (
          <Tooltip
            title="In the last hour"
            aria-label="In the last hour"
            classes={{ tooltip: classes.tooltip }}>
            <Box display="flex" justifyContent="space-beetwen" width="100%">
              <Box width={80}>
                {(response.statusService === 'available' ||
                  response.statusService === 'success') && <Box className={classes.up}>UP</Box>}
                {(response.statusService === 'unavailable' ||
                  response.statusService === 'error') && <Box className={classes.down}>DOWN</Box>}
              </Box>
              <Box width="100%" textAlign="center" fontSize={18}>
                {response.sla || 0}
                {typeof response.sla === 'string' ? '' : '%'}
              </Box>
            </Box>
          </Tooltip>
        );
      }

      return (
        <Box display="flex" width="100%">
          <Box width="100%" textAlign="center" fontSize={14}>
            {response.status.label}
          </Box>
        </Box>
      );
    };

    const fullscreenOpen = open === key;

    const grid = (
      <Grid
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h4" style={{ marginRight: 8 }}>
              {environment.name}
            </Typography>

            {!fullscreenOpen && (
              <IconButton
                onClick={() => {
                  handleClickOpen(key);
                }}
                title="Open in fullscreen"
                aria-label="Open in fullscreen">
                <FullscreenIcon />
              </IconButton>
            )}

            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Last sync: {dayjs(lastUpdate).format('HH:mm')}• Next check in: {timer}
            </Typography>
          </Box>
        }
        columns={columns}
        rows={rows}
      />
    );

    return (
      <Box key={key} mb={8}>
        {!fullscreenOpen && grid}

        <Fullscreen title={productName} open={fullscreenOpen} handleClose={handleClose}>
          {fullscreenOpen && grid}
        </Fullscreen>
      </Box>
    );
  });

  return <Box>{grids.map(grid => grid)}</Box>;
};
