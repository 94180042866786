import { Postmortem } from 'types/external/Postmortem';
import { useQuery } from 'urql';

const GetPostmortem = `#graphql
  query GetPostmortem($uid: String!) {
    postmortem(uid: $uid) {
      id
      incidentId
      postMortem
      title
      createdAt
      owner {
        id
        displayName
        photoURL
      }
      incident {
        title
        status
        severity
      }
    }
  }
`;

type UsePostmortemParams = {
  uid: string;
  pause?: boolean;
};

export function usePostmortem({ pause = false, uid }: UsePostmortemParams) {
  const [{ fetching, data }] = useQuery<{ postmortem: Postmortem }>({
    query: GetPostmortem,
    variables: {
      uid
    },
    pause
  });

  return { fetching, postmortem: data?.postmortem };
}
