import Breadcrumb from 'components/Breadcrumb';

import ServicesNewForm from './ServicesNewForm';

function ServicesNew() {
  return (
    <>
      <Breadcrumb items={[{ label: 'Services', link: '/services' }, { label: 'New' }]} />
      <ServicesNewForm />
    </>
  );
}

export default ServicesNew;
