import { RefObject } from 'react';

import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { ButtonDefault } from 'oldComponents/Button';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import Input from 'componentsV4/Input';
import actions from 'redux/actions';

import { useStyles } from './styles';

const UpdateIncidentMutation = `#graphql
  mutation (
    $incidentId: Int!
    $title: String!
    $description: String!
  ) {
    updateIncident(
      data: {
        incidentId: $incidentId
        title: $title
        description: $description
      }
    ) {
      title
      description
    }
  }
`;

function IncidentForm({
  incident,
  descriptionRef,
  onIncidentSave
}: {
  incident: { title: string; description: string; incidentId: number };
  descriptionRef: RefObject<HTMLDivElement>;
  onIncidentSave: () => void;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset
  } = useForm({ defaultValues: { title: incident?.title, description: incident?.description } });

  const [{ fetching: isUpdatingIncident }, updateIncident] = useMutation(UpdateIncidentMutation);

  async function handleSubmitIncident(data: { title: string; description: string }) {
    const { error } = await updateIncident({
      incidentId: Number(incident.incidentId),
      title: data.title,
      description: data.description
    });

    if (error) {
      return dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error('Something went wrong while updating incident. Please try again.')
      });
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Incident updated successfully.'
    });
    onIncidentSave();

    /*
     * data argument is necessary to reset only form state, not form values.
     */
    reset(data);
  }

  const form = (
    <form onSubmit={handleSubmit(handleSubmitIncident)}>
      <Input
        inputRef={register}
        defaultLabel="Title"
        name="title"
        inputProps={{
          maxLength: 281
        }}
        style={{ marginTop: theme.spacing(3) }}
      />

      <Input
        inputRef={register}
        defaultLabel="Description"
        name="description"
        inputProps={{
          maxLength: 281
        }}
        multiline
        minRows={4}
        style={{ marginTop: theme.spacing(3) }}
      />
      <Box mt={3}>
        <ButtonDefault
          type="submit"
          size="medium"
          children="Save changes"
          variant="contained"
          disabled={!isDirty || isUpdatingIncident}
        />
      </Box>
    </form>
  );

  return (
    <Grid item xs={12}>
      <Typography className={classes.subtitle} variant="h5">
        Incident Info
      </Typography>
      <Box mt={4} position="relative">
        {isUpdatingIncident && <LoadingOverlay />}
        {form}
      </Box>
    </Grid>
  );
}

export default IncidentForm;
