import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type MemcachedFormData = {
  host: string;
  port: string;
};
export function MemcachedForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <>
      {domainType === 'userAndPass' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'host',
                name: 'host'
              }}
              InputComponent={Input}
            />
            <Input
              label="Port"
              name="port"
              inputRef={register}
              type="number"
              inputProps={{
                min: 1,
                max: 65535
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
