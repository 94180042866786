import { useEffect } from 'react';

import { FormControl, FormHelperText, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router';
import { useQuery } from 'urql';

import { ButtonSimple } from 'componentsV4/Button';
import { customElasticQuery } from 'helpers/customElasticQuery';
import useGetMembers from 'hooks/queriesGraphQL/useGetMembers';
import useStepGroupsContacts from 'hooks/queriesGraphQL/useStepGroupsContacts';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  stepGridItem: {
    flexWrap: 'noWrap',
    marginBottom: theme.spacing(),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing()
    },
    '& > p': {
      marginTop: theme.spacing()
    }
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  nameLabel: {
    marginRight: theme.spacing()
  }
}));

export default function VerticalLinearStepper({
  fields,
  control,
  register,
  remove,
  append,
  defaultValues,
  contactMethods,
  timeUnits,
  serverError,
  errors,
  watch,
  setValue
}) {
  const classes = useStyles();
  const { id: teamId } = useParams();

  const useNewProfileFlag = useFeatureFlagUnleash('useNewProfile', { queryString: true });

  const stepGroupContacts = useStepGroupsContacts({ teamId, pause: useNewProfileFlag });

  const { data: members } = useGetMembers({
    from: 0,
    size: 100,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'team_id',
          value: String(teamId)
        }
      ]
    }),
    pause: !useNewProfileFlag
  });

  const contacts = members?.length
    ? members.map(contact => {
        return {
          id: contact.memberId,
          displayName: contact.name,
          uid: contact.userUid
        };
      })
    : stepGroupContacts || [];

  return (
    <div className={classes.root}>
      <Stepper orientation="vertical">
        {fields.map((stepValues, index) => (
          <Step key={stepValues.key} active expanded>
            <StepLabel>Configure step {index + 1}</StepLabel>
            <StepContent>
              <StepContentInner
                index={index}
                shouldShowRemoveStepOnFirst={fields.length > 1}
                stepValues={stepValues}
                control={control}
                register={register}
                remove={index => {
                  remove(index);
                }}
                contactMethods={contactMethods}
                timeUnits={timeUnits}
                errors={errors}
                contacts={contacts}
                watch={watch}
                setValue={setValue}
              />
            </StepContent>
          </Step>
        ))}
        <Step expanded>
          <StepLabel>
            <ButtonSimple
              variant="contained"
              color="primary"
              onClick={() => append(defaultValues)}
              text="Add step"
            />
          </StepLabel>
        </Step>
      </Stepper>

      <Box display="flex" alignItems="center" paddingX={3}>
        <Typography className={classes.nameLabel}>Give a name for this rules set: </Typography>

        <TextField
          name="stepsGroupName"
          variant="outlined"
          label="Steps Group name"
          size="small"
          inputRef={register}
          placeholder="Default on call rules"
          InputLabelProps={{
            shrink: true
          }}
          error={Boolean(errors.stepsGroupName)}
          helperText={errors.stepsGroupName?.message}
        />
      </Box>

      {serverError && (
        <Box paddingX={3} marginTop={1}>
          <Typography color="error">There was an error: {serverError.message}</Typography>
        </Box>
      )}
    </div>
  );
}

const GetEnabledUserProviders = `#graphql
  query(
    $userUid: String!
    $type: String!
  ) {
    enabledUserProviders(
      userUid: $userUid
      type: $type
    ) {
      provider
    }
  }
`;

const StepContentInner = ({
  index,
  shouldShowRemoveStepOnFirst,
  stepValues,
  control,
  register,
  remove,
  contactMethods,
  timeUnits,
  errors,
  contacts,
  watch,
  setValue
}) => {
  const classes = useStyles();

  const useNewProfileFlag = useFeatureFlagUnleash('useNewProfile', { queryString: true });

  const errorsInStep = errors.steps ? errors.steps[index] : null;

  const selectedMember = watch(`steps[${index}].teamMemberToContact`);

  const memberData = contacts.find(contact => contact.id === selectedMember);

  const [{ data }] = useQuery({
    query: GetEnabledUserProviders,
    variables: {
      userUid: memberData?.uid,
      type: 'on-call'
    },
    pause: !selectedMember || !useNewProfileFlag || !memberData?.uid
  });

  const availableContactMethods = () => {
    if (!useNewProfileFlag) return contactMethods;

    const availableProviders = data?.enabledUserProviders.map(item => item.provider);

    if (!availableProviders?.length) return {};

    const providerMap = {
      email: 'mail',
      sms: 'sms',
      voice: 'voiceCall',
      whatsapp: 'whatsapp',
      telegram: 'telegram',
      mobile: 'mobile'
    };

    return Object.fromEntries(
      availableProviders
        .map(provider => providerMap[provider])
        .filter(key => key in contactMethods)
        .map(key => [key, contactMethods[key]])
    );
  };

  useEffect(() => {
    if (!useNewProfileFlag) return;

    if (
      stepValues?.memberFromTeams === selectedMember ||
      stepValues?.teamMemberToContact === selectedMember
    ) {
      return;
    }

    setValue(`steps[${index}].contactMethod`, '');
  }, [selectedMember, setValue, index, useNewProfileFlag, stepValues]);

  useEffect(() => {
    if (!useNewProfileFlag) return;

    if (!memberData) return;

    setValue(`steps[${index}].userUid`, memberData.uid);
  }, [memberData, index, setValue, useNewProfileFlag]);

  return (
    <>
      <Grid container className={classes.stepGridItem}>
        <input
          type="hidden"
          ref={register}
          name={`steps[${index}].id`}
          defaultValue={stepValues.id}
        />
        <Controller
          name={`steps[${index}].userUid`}
          control={control}
          defaultValue={stepValues.userUid || ''}
          value={memberData?.uid}
        />
        <Typography>Contact</Typography>
        <FormControl size="small" style={{ minWidth: 100 }}>
          <Controller
            as={
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                size="small"
                variant="outlined">
                {contacts &&
                  contacts.map(contact => (
                    <MenuItem key={contact.id} value={contact.id}>
                      {contact.displayName}
                    </MenuItem>
                  ))}
              </Select>
            }
            name={`steps[${index}].teamMemberToContact`}
            defaultValue={stepValues.memberFromTeams || ''}
            control={control}
          />
          {errorsInStep?.teamMemberToContact && (
            <FormHelperText error variant="outlined">
              {errorsInStep.teamMemberToContact.message}
            </FormHelperText>
          )}
        </FormControl>
        <Typography>via</Typography>
        <FormControl size="small" style={{ minWidth: 100 }}>
          <Controller
            as={
              <Select variant="outlined" id="contact-method">
                {Object.values(availableContactMethods()).map(contactMethod => (
                  <MenuItem key={contactMethod.value} value={contactMethod.value}>
                    {contactMethod.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name={`steps[${index}].contactMethod`}
            defaultValue={stepValues.contactMethod || ''}
            control={control}
          />
          {errorsInStep?.contactMethod && (
            <FormHelperText error variant="outlined">
              {errorsInStep.contactMethod.message}
            </FormHelperText>
          )}
        </FormControl>
        <Typography>every</Typography>

        <TextField
          defaultValue={stepValues.interval}
          inputRef={register({ valueAsNumber: true })}
          InputProps={{ inputProps: { min: 1 } }}
          name={`steps[${index}].interval`}
          size="small"
          style={{ flex: '0 0 70px' }}
          variant="outlined"
          type="number"
          error={Boolean(errorsInStep?.interval)}
          helperText={errorsInStep?.interval?.message}
        />

        <FormControl size="small" style={{ minWidth: 100 }}>
          <Controller
            as={
              <Select variant="outlined" id="interval-time-unit">
                {Object.values(timeUnits).map(timeUnit => (
                  <MenuItem key={timeUnit.value} value={timeUnit.value}>
                    {timeUnit.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name={`steps[${index}].intervalTimeUnit`}
            defaultValue={stepValues.intervalTimeUnit || ''}
            control={control}
          />
        </FormControl>
      </Grid>
      <Grid container className={classes.stepGridItem}>
        <Typography>then after</Typography>

        <TextField
          defaultValue={stepValues.timeout}
          inputRef={register({ valueAsNumber: true })}
          InputProps={{ inputProps: { min: 1 } }}
          name={`steps[${index}].timeout`}
          size="small"
          style={{ flex: '0 0 70px' }}
          variant="outlined"
          type="number"
          error={Boolean(errorsInStep?.timeout)}
          helperText={errorsInStep?.timeout?.message}
        />

        <FormControl size="small" style={{ minWidth: 100 }}>
          <Controller
            as={
              <Select variant="outlined" id="timeout-time-unit">
                {Object.values(timeUnits).map(timeUnit => (
                  <MenuItem key={timeUnit.value} value={timeUnit.value}>
                    {timeUnit.label}
                  </MenuItem>
                ))}
              </Select>
            }
            name={`steps[${index}].timeoutTimeUnit`}
            defaultValue={stepValues.timeoutTimeUnit || ''}
            control={control}
          />
        </FormControl>
        <Typography>execute next step</Typography>
      </Grid>
      {(index > 0 || shouldShowRemoveStepOnFirst) && (
        <ButtonSimple
          variant="outlined"
          className={classes.button}
          onClick={() => remove(index)}
          text="Remove step"
          color="primary"
        />
      )}
    </>
  );
};
