import React from 'react';

import {
  Grid,
  TextField,
  Input,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const Guest = ({ onChange, formState, roles, removeGuest }) => {
  const handleChange = event => {
    event.preventDefault();
    onChange(event.target.name, event.target.value, formState.uid);
  };

  const handleRolesChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    onChange(name, value, formState.uid);
  };

  const handleRemove = () => {
    removeGuest(formState.uid);
  };

  return (
    <Grid container spacing={1}>
      <Grid item md={4} xs={12}>
        <TextField
          error={formState.errors && formState.errors.email}
          fullWidth
          helperText={
            formState.errors && formState.errors.email ? formState.errors.email.join(', ') : ''
          }
          InputLabelProps={{
            shrink: true
          }}
          label="Email"
          margin="dense"
          name="email"
          onChange={handleChange}
          required
          value={formState.email || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          label="Name"
          margin="dense"
          name="displayName"
          onChange={handleChange}
          value={formState.displayName || ''}
          variant="outlined"
        />
      </Grid>
      <Grid item md={3} xs={12}>
        <InputLabel id={`${formState.uid}-select-multiple-chip-label`} shrink>
          Role
        </InputLabel>
        <Select
          input={<Input id={`${formState.uid}-select-multiple-chip`} />}
          MenuProps={MenuProps}
          name="role"
          onChange={handleRolesChange}
          style={{ minWidth: '100%' }}
          value={formState.role}>
          {roles.map(role => (
            <MenuItem key={role.name} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item md={1} xs={12}>
        <IconButton title="Remove" onClick={handleRemove}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Guest;
