import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useFrequenciesMetricsV2 } from 'hooks/queriesGraphQL/useFrequenciesMetricsV2';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { useStyles } from 'views/Monitoring/View/styles';

import { MetricCard } from '../MetricCard';

export function FrequenciesCards({ applicationUid }: { applicationUid: string }) {
  const classes = useStyles();

  const canFetchDataFromTheLast30Days = useFeaturePlan('DataRetention.30Days');
  const canUseMetrics = useFeaturePlan('Metrics.DORA');

  const availableMetricPeriods = [
    {
      type: '1 day',
      enabled: true
    },
    {
      type: '30 days',
      enabled: canFetchDataFromTheLast30Days
    }
  ].filter(period => period.enabled);

  const frequenciesMetrics = useFrequenciesMetricsV2({
    applicationUid,
    periods: availableMetricPeriods.map(period => period.type)
  });

  const metricsText = canFetchDataFromTheLast30Days ? 'Last 30 days' : 'Last 24 hours';

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MetricCard name="Change Failure Rate">
          {canUseMetrics ? (
            <>
              <Typography variant="h5" className={classes.paperTextMiddle}>
                {frequenciesMetrics
                  ? frequenciesMetrics?.changeFailure > 0
                    ? `${frequenciesMetrics?.changeFailure.toFixed(2)}%`
                    : `${frequenciesMetrics?.changeFailure}%`
                  : '0%'}
              </Typography>
              <Typography variant="body2" className={classes.paperTextBottom}>
                {metricsText}
              </Typography>
            </>
          ) : (
            <Link to="/plans">
              <Typography variant="h5" color="primary" className={classes.upgradePlanText}>
                Upgrade your plan
              </Typography>
            </Link>
          )}
        </MetricCard>
      </Grid>
      <Grid item xs={6}>
        <MetricCard name="Deploy Frequency">
          {canUseMetrics ? (
            <>
              <Typography variant="h5" className={classes.paperTextMiddle}>
                {frequenciesMetrics ? frequenciesMetrics?.deployFrequency : 0}
              </Typography>
              <Typography variant="body2" className={classes.paperTextBottom}>
                {metricsText}
              </Typography>
            </>
          ) : (
            <Link to="/plans">
              <Typography variant="h5" color="primary" className={classes.upgradePlanText}>
                Upgrade your plan
              </Typography>
            </Link>
          )}
        </MetricCard>
      </Grid>
    </Grid>
  );
}
