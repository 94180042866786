import Dialog from 'components/Dialog';

type ConfirmationDialogProps = {
  open: boolean;
  checked: boolean;
  onClose: () => void;
  onEnable: () => void;
  onDisable: () => void;
  disableMessage: string;
  activeMessage: string;
};

export default function ConfirmationDialog({
  open,
  checked,
  onClose,
  onEnable,
  onDisable,
  disableMessage,
  activeMessage
}: ConfirmationDialogProps) {
  return (
    <Dialog
      title={`${checked ? 'Disable' : 'Enable'} notifications`}
      description={checked ? disableMessage : activeMessage}
      open={open}
      onClose={onClose}
      actions={[
        {
          label: 'Cancel',
          action: onClose,
          variant: 'outlined'
        },
        {
          label: checked ? 'Disable' : 'Enable',
          action: checked ? onDisable : onEnable
        }
      ]}>
      <></>
    </Dialog>
  );
}
