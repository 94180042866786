import actions from '../actions';

const merge = (state, action) => {
  if (actions.MERGE_ENTITY === action.type) {
    const stateEntity = state[action.meta.entity];
    return {
      ...state,
      [action.meta.entity]: {
        ...stateEntity,
        ...action.payload
      }
    };
  }

  return state;
};

export default merge;
