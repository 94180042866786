import { useMemo } from 'react';

function getTooltipEventRegister({ setShouldShowTooltip, setCurrentDeployId, type }) {
  const handleMouseEnter = className => () => {
    setCurrentDeployId(className);
    setShouldShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShouldShowTooltip(false);
    setCurrentDeployId('');
  };

  const types = {
    register(classNames) {
      if (classNames.length > 0) {
        classNames.forEach(className => {
          const element = document.querySelector(`.${className}`);

          if (element) {
            element.addEventListener('mouseenter', handleMouseEnter(className));
            element.addEventListener('mouseleave', handleMouseLeave);
          }
        });
      }
    },
    unregister(classNames) {
      if (classNames.length > 0) {
        classNames.forEach(className => {
          const element = document.querySelector(`.${className}`);

          if (element) {
            element.removeEventListener('mouseenter', handleMouseEnter(className));
            element.removeEventListener('mouseleave', handleMouseLeave);
          }
        });
      }
    }
  };

  return types[type];
}

function useChartTooltip({ setShouldShowTooltip, setCurrentDeployId }) {
  const registerEvents = useMemo(
    () =>
      getTooltipEventRegister({
        setShouldShowTooltip,
        setCurrentDeployId,
        type: 'register'
      }),
    [setCurrentDeployId, setShouldShowTooltip]
  );

  const unregisterEvents = useMemo(
    () =>
      getTooltipEventRegister({
        setShouldShowTooltip,
        setCurrentDeployId,
        type: 'unregister'
      }),
    [setCurrentDeployId, setShouldShowTooltip]
  );

  return useMemo(
    () => ({
      registerEvents,
      unregisterEvents
    }),
    [registerEvents, unregisterEvents]
  );
}

export default useChartTooltip;
