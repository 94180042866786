import React from 'react';

import { useHistory } from 'react-router';

import { usePermission } from 'hooks/usePermission';

import ServicesList from './ServicesList';

const Services = () => {
  const history = useHistory();

  const permissions = {
    new: usePermission('ServiceController-post-/services'),
    update: usePermission('ServiceController-put-/services/:id'),
    remove: usePermission('ServiceController-delete-/services/:id')
  };

  return <ServicesList history={history} permissions={permissions} />;
};

export default Services;
