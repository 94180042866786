import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
