import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { Theme } from 'theme/v4';

export default withStyles((theme: Theme) => ({
  root: {
    height: '52px',
    boxShadow: 'none',
    textTransform: 'none',
    width: '100%'
  }
}))(Button);
