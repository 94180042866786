import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import StatusPageEdit from 'views/StatusPage/Edit';
import StatusPagesList from 'views/StatusPage/List';
import StatusPageNew from 'views/StatusPage/New';

export const statusPageRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={StatusPagesList}
    exact
    layout={MainLayoutV5}
    key="/statuspages"
    path="/statuspages"
    planFeature="StatusPage"
  />,
  <AuthorizedRoute
    component={StatusPageEdit}
    exact
    layout={MainLayoutV5}
    key="/statuspages/:id/edit"
    path="/statuspages/:id/edit"
    planFeature="StatusPage"
    featureTarget="StatusPageController-put-/statuspage"
  />,
  <AuthorizedRoute
    component={StatusPageNew}
    exact
    layout={MainLayoutV5}
    key="/statuspages/new"
    path="/statuspages/new"
    planFeature="StatusPage"
    featureTarget="StatusPageController-post-/statuspage"
  />
];
