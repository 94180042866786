import { zodResolver } from '@hookform/resolvers/zod';
import { useForm as useReactHookForm } from 'react-hook-form';

import schema from './schema';

function useForm({ defaultValues }) {
  const { control, handleSubmit, register, errors, watch, setValue, getValues } = useReactHookForm({
    defaultValues,
    resolver: zodResolver(schema)
  });

  return { control, handleSubmit, register, errors, watch, setValue, getValues };
}

export default useForm;
