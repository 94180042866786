export const redisStartValues = ({ method, domain_settings }) => {
  if (method !== 'redisV2') {
    return;
  }

  let domainSettingsObj = domain_settings;
  if (typeof domain_settings === 'string') {
    domainSettingsObj = JSON.parse(domain_settings);
  }

  return {
    user: domainSettingsObj?.user,
    password: domainSettingsObj?.password,
    db: domainSettingsObj?.db,
    max_retries: domainSettingsObj?.max_retries,
    is_cluster: Boolean(domainSettingsObj?.is_cluster),
    tls: Boolean(domainSettingsObj?.tls)
  };
};
