import { buildConditionalCell } from './buildConditionalCell';
import { validateApplicationStatus } from './validateApplicationStatus';

export const conditionalCellToApplicationStatus = ({
  app,
  status
}: {
  app: any;
  status: { label: string; color: string };
}) => {
  return buildConditionalCell({
    fn: () => {
      const response = validateApplicationStatus({ application: app });
      return response.status.label === status.label;
    },
    bgColor: status.color
  });
};
