import { DialogContentText, Link } from '@material-ui/core';

import { Input } from 'views/ServicesHub/components/Input';

import { FormProps } from '..';

import { useStyles } from './../styles';

export function TeamsForm({ form }: FormProps) {
  const classes = useStyles();
  const { register, errors } = form;

  return (
    <>
      <DialogContentText>1. Install the incoming webhook connector </DialogContentText>
      <DialogContentText>
        Go to
        <Link
          className={classes.link}
          href="https://teams.microsoft.com/l/app/203a1e2c-26cc-47ca-83ae-be98f960b6b2?source=store-copy-link"
          target="_blank"
          rel="noopener noreferrer">
          incoming webhook connector details
        </Link>
        , click on <strong>Add to a team</strong>, then choose a channel to receive Elven Platform
        notifications.
      </DialogContentText>
      <DialogContentText>2. Configure incoming webhook and get webhook url</DialogContentText>
      <DialogContentText>
        Set a name (e.g. Elven Platform) and image for the connector, then copy the webhook url and
        paste below.
      </DialogContentText>
      <DialogContentText>
        <Input
          className={classes.input}
          inputRef={register}
          name="config.teamsWebhookUrl"
          label="Webhook url"
          errors={errors}
          minRows={5}
          multiline
          required
        />
      </DialogContentText>
    </>
  );
}
