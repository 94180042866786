import keyValuesToObject from 'helpers/keyValuesToObject';

const mapPrePayload = formData => ({
  name: formData.pre_name,
  domain: formData.pre_healthcheckUrl,
  method: formData.pre_method,
  check_type: 'http',
  timeout: Number(formData.timeout),
  check_interval: Number(formData.check_interval),
  failuresToIncident: Number(formData.failuresToIncident),
  postBodyRaw: formData.pre_post_body_raw || null,
  postBodyUrlencoded: keyValuesToObject(formData.pre_post_body_urlencoded || []),
  postBodyType: formData.pre_post_body_type,
  headers: keyValuesToObject(formData.pre_headers),
  skip_ssl_validation: formData.pre_skip_ssl_validation
});

const mapPostPayload = formData => ({
  name: formData.pos_name,
  environmentId: formData.environmentId,
  productId: formData.productId,
  applicationsSettings: {
    type: 'internalMultiHttp',
    healthcheckUrl: formData.pos_healthcheckUrl,
    method: formData.pos_method,
    headers: keyValuesToObject(formData.pos_headers),
    validationString: formData.validationString,
    timeout: Number(formData.timeout),
    check_interval: Number(formData.check_interval),
    failuresToIncident: Number(formData.failuresToIncident),
    postBodyRaw: formData.pos_post_body_raw || null,
    postBodyUrlencoded: keyValuesToObject(formData.pos_post_body_urlencoded || []),
    postBodyType: formData.pos_post_body_type,
    skipSslValidation: formData.pos_skip_ssl_validation,
    p50xp90_anomaly_percent: null,
    p50xp95_anomaly_percent: null,
    openIncidentWithSeverity: formData.openIncidentWithSeverity
  },
  teamId: formData.teamId || null
});

const mapData = formData => ({
  pre: mapPrePayload(formData),
  pos: mapPostPayload(formData),
  addonSettings: mapPrePayload(formData)
});

export default mapData;
