import { useEffect, useState } from 'react';

import { Grid, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import getMetabaseIframeUrl from 'helpers/getMetabaseIframeUrl';
import { useMetabaseTokens } from 'hooks/queriesGraphQL/useMetabaseTokens';
import useUser from 'hooks/queriesRest/useUser';

import useStyles from './styles';
import { MetabaseDashboardProps } from './types';

type IframeProps = Pick<
  MetabaseDashboardProps,
  | 'dashboardName'
  | 'dashboardParams'
  | 'hideParams'
  | 'iframeHeight'
  | 'iframeRefresh'
  | 'iframeTitle'
  | 'title'
>;

export function Iframe({
  dashboardName,
  dashboardParams,
  hideParams,
  iframeHeight,
  iframeRefresh,
  iframeTitle,
  title
}: IframeProps) {
  const [isLoading, setLoading] = useState(true);

  const theme = useTheme();

  const classes = useStyles();

  const { data, refetch } = useMetabaseTokens();
  const { data: user } = useUser();
  const orgUid = user?.logged?.org?.uid;

  const dashboardToken = data?.find(token => token.dashboard === dashboardName)!;

  useEffect(() => {
    const expirationDate = dayjs.unix(dashboardToken?.expires!);
    const timeRemainingInMilliseconds = expirationDate.diff(dayjs());

    if (timeRemainingInMilliseconds < 0) return;

    const timeoutId = setTimeout(() => refetch(), timeRemainingInMilliseconds);

    return () => clearTimeout(timeoutId);
  }, [dashboardToken?.expires, refetch]);

  const iframeUrl = getMetabaseIframeUrl({
    orgUid,
    token: dashboardToken?.token,
    params: dashboardParams,
    hideParams,
    iframeTitle,
    iframeRefresh
  });

  const height = iframeHeight || theme.breakpoints.values.md;

  return (
    <Grid>
      {isLoading && <LoadingOverlay isBlock />}
      {iframeUrl && (
        <iframe
          className={classes.iframe}
          title={title}
          src={iframeUrl}
          width="100%"
          height={height}
          onLoad={() => setLoading(false)}
        />
      )}
    </Grid>
  );
}
