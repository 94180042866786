import { useEffect, useState } from 'react';

export const useTimerLogic = ({ seconds, lastUpdate }: { seconds: number; lastUpdate: Date }) => {
  const [remaining, setRemaining] = useState(new Date().getTime() - lastUpdate.getTime());

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const now = new Date().getTime();

      const difference = (now - lastUpdate.getTime()) / 1000;

      setRemaining(seconds - difference);
    }, 1000 /* 1sec */);

    return () => clearTimeout(timeoutId);
  }, [remaining, seconds, lastUpdate]);

  return remaining;
};
