import React from 'react';

import { Fade } from '@material-ui/core';
import * as copy from 'clipboard-copy';

const useCopy = ({ copiedSuccessfullyTimeout = 3000 } = {}) => {
  const [copiedSuccessfully, setCopiedSuccessfully] = React.useState(false);

  React.useEffect(() => {
    if (copiedSuccessfully) {
      setTimeout(() => {
        setCopiedSuccessfully(false);
      }, copiedSuccessfullyTimeout);
    }
  }, [copiedSuccessfully, copiedSuccessfullyTimeout]);

  const handleCopy = textToBeCopied => {
    copy(textToBeCopied)
      .then(() => {
        setCopiedSuccessfully(true);
      })
      .catch(() => {
        setCopiedSuccessfully(false);
      });
  };

  const copiedSuccessfullyMessage = (
    <Fade in={copiedSuccessfully} timeout={{ enter: 500, exit: 2000 }}>
      <small>copied</small>
    </Fade>
  );

  return { handleCopy, copiedSuccessfully, copiedSuccessfullyMessage };
};

export default useCopy;
