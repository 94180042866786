import { Box, Divider, Typography } from '@material-ui/core';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { adapters } from 'views/ServicesHub/adapters/new';
import { FormProps } from 'views/ServicesHub/forms';
import { IncidentForm } from 'views/ServicesHub/forms/monitoring/commom/Incident';
import { TimeoutRuleForm } from 'views/ServicesHub/forms/monitoring/commom/Timeout';

import { useStyles } from './styles';

type RuleConfigSectionProps = {
  formType: keyof typeof adapters;
  form: FormProps['form'];
};

export function RuleConfigSection({ formType, form }: RuleConfigSectionProps) {
  const classes = useStyles();

  const useTimeoutRuleForm = useFeatureFlagUnleash('useTimeoutRuleForm', { queryString: true });

  const shouldUseTimeoutRuleForm = useTimeoutRuleForm && formType === 'api';

  return (
    <Box>
      {shouldUseTimeoutRuleForm && (
        <>
          <Box mb={5}>
            <Typography className={classes.sectionTitle} variant="h4">
              Open automatic Timeout Alert
            </Typography>
            <Box display="flex" gridGap="3rem">
              <Box flex={2}>
                <TimeoutRuleForm form={form} />
              </Box>
              <Box flex={1}>
                <Typography className={classes.infoSectionTitle} variant="subtitle2">
                  Configure Timeout Alert Threshold(s)
                </Typography>
                <Typography className={classes.infoSectionDescription} variant="subtitle1">
                  Here you can set the thresholds of the resource, which will open an automatic
                  Timeout Alert, including the severity, so you can define when and how to act on
                  it. Choose a preexisting team to be notified.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider className={classes.sectionDivider} />
        </>
      )}

      <Box mt={shouldUseTimeoutRuleForm ? 5 : 0}>
        <Typography className={classes.sectionTitle} variant="h4">
          Open automatic incident
        </Typography>
        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <IncidentForm form={form} />
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Configure Incident Threshold(s)
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Here you can set the thresholds of the resource, which will open an automatic
              incident, including the severity, so you can define when and how to act on it. Choose
              a preexisting team to be notified.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
