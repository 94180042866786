import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const IncidentDatadogGuide = ({
  orgId,
  orgUid,
  orgName,
  token,
  integrationUid
}: GuideParams): GuideType => {
  return {
    name: 'Datadog',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrl: `https://${getEnvUrl()}/${orgUid}/incidents/v1/datadog/${integrationUid}?token=${token}`,
    snippets: [
      {
        key: 'Payload',
        value: ` {
      "body": "$EVENT_MSG",
      "last_updated": "$LAST_UPDATED",
      "event_type": "$EVENT_TYPE",
      "title": "$EVENT_TITLE",
      "date": "$DATE",
      "org": {
          "id": ${orgId},
          "name": "${orgName}"
      },
      "id": "$ID",    
      "aggregate_key": "$AGGREG_KEY",
      "alert_title": "$ALERT_TITLE",
      "alert_transition": "$ALERT_TRANSITION",
      "severity": "$ALERT_PRIORITY"
    }`
      }
    ],
    instructions: {
      isNumeric: true,
      steps: [
        'Open Integrations page',
        'Install webhook integration',
        'In the settings of this integration, create a new webhook',
        'Copy and paste the url and payload above',
        'Add @webhook-name to monitor notification',
        'Send a "Test alert" to verify that the incident is created in 1P'
      ]
    }
  };
};
