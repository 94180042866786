import { useEffect } from 'react';

import { makeStyles, FormHelperText } from '@material-ui/core';
import { Typography, Box, Select, MenuItem, InputBase, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller } from 'react-hook-form';

import withThemeV3Hoc from 'theme/v3/withTheme';

import inputsStateColors from '../../constants/inputsStateColors';
import HelperIcon from '../HelperIcon/index';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    },
    label: {
      color: theme.palette.text.primary,
      paddingBottom: '2px',
      display: 'flex'
    },
    input: {
      padding: 0,
      '& > div': {
        borderColor: props =>
          props.state ? inputsStateColors[props.state] : inputsStateColors.default
      },
      '& > div:first-child': {
        paddingRight: theme.spacing(4)
      }
    },
    helperText: {
      alignSelf: 'flex-end',
      color: props => (props.state ? inputsStateColors[props.state] : theme.palette.text.secondary)
    }
  };
});

const SimpleInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    border: props =>
      props.error
        ? `1px solid ${theme.palette.error.main}`
        : `1px solid ${theme.palette.inputBorder.default}`,
    fontSize: 16,
    padding: '8px 12px',
    height: '22px',
    '&:focus': {
      borderRadius: 4
    }
  }
}))(InputBase);

const SelectSimple = ({
  items,
  defaultValue,
  label,
  placeholder,
  tooltip,
  helperText,
  errors,
  warnings,
  state,
  type,
  required,
  disabled,
  control,
  name,
  selectFirst = false,
  setValue,
  analyticsEvent = () => {},
  ...otherProps
}) => {
  const errorMessage = errors && errors[name]?.message;
  const warningMessage = warnings?.find(warning => warning.name === name)?.message;

  const error = Boolean(errorMessage);

  const classes = useStyles({
    state: state || (errorMessage && 'error') || (warningMessage && 'warning'),
    error
  });

  const renderItens = item => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    );
  };

  const arrow = props => {
    return (
      <Box {...props} style={{ position: 'absolute', right: 8 }}>
        <ExpandMoreIcon color="primary" />
      </Box>
    );
  };

  useEffect(() => {
    if (selectFirst && setValue && items?.length > 0) {
      setValue(name, items[0].value, { shouldValidate: true });
    }
  }, [items, name, selectFirst, setValue]);

  return (
    <FormControl className={classes.root} {...otherProps}>
      <Typography className={classes.label} variant="body2">
        {required ? label + ' *' : label}
      </Typography>
      {tooltip && <HelperIcon tooltipText={tooltip}></HelperIcon>}

      <Controller
        name={name}
        control={control}
        placeholder={placeholder}
        type={type}
        required={required}
        defaultValue={defaultValue}
        render={({ onChange, ...props }) => {
          return (
            <Select
              IconComponent={arrow}
              className={classes.input}
              fullWidth
              input={<SimpleInput disabled={disabled} />}
              onChange={event => {
                onChange(event);
                if (typeof analyticsEvent === 'function') {
                  analyticsEvent();
                }
              }}
              {...props}>
              {items.map(renderItens)}
            </Select>
          );
        }}
      />

      {helperText && (
        <FormHelperText variant="body2" className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}

      {errorMessage && (
        <FormHelperText error className={classes.helperText}>
          {errorMessage}
        </FormHelperText>
      )}

      {warningMessage && (
        <FormHelperText className={classes.helperText}>{warningMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default withThemeV3Hoc(SelectSimple);
