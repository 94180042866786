import { GuideParams, GuideType } from 'types/external/ExternalService';

import { getEnvUrl } from 'helpers/getEnvString';

export const AlertGrafanaGuide = ({ orgUid, token, integrationUid }: GuideParams): GuideType => {
  return {
    name: 'Grafana',
    errors: ['Select an api token'],
    useToken: true,
    externalServiceUrls: [
      {
        key: 'Grafana V10',
        url: `https://${getEnvUrl()}/${orgUid}/alerts/v1/grafana/10/${integrationUid}?token=${token}`
      },
      {
        key: 'Grafana V8',
        url: `https://${getEnvUrl()}/${orgUid}/alerts/v1/grafana/8/${integrationUid}?token=${token}`
      }
    ],
    instructions: {
      isNumeric: false,
      steps: [
        'Use this url to create alerts via Grafana.',
        'PS: Check the grafana version and use the corresponding url'
      ]
    }
  };
};
