import uuid from 'uuid';
import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  userIds: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      minimum: 1
    }
  },
  roleId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    }
  }
};

const members = (state, action) => {
  if (actions.MEMBERS_AUTOCOMPLETE === action.type) {
    return {
      ...state,
      members_new: {
        ...state.members_new,
        autoComplete: {
          ...state.members_new.autoComplete,
          searchText: action.payload
        }
      }
    };
  }

  if (actions.MEMBERS_AUTOCOMPLETE_SUCCESSFUL === action.type) {
    return {
      ...state,
      members_new: {
        ...state.members_new,
        autoComplete: {
          ...state.members_new.autoComplete,
          options: action.payload
        }
      }
    };
  }

  if (actions.MEMBERS_REMOVE === action.type) {
    const members_new = state.members_new;
    const data = members_new.data;

    const newData = data.reduce((col, item) => {
      if (item.uid !== action.meta.uid) {
        item.errors = validate(item, schema);

        col.push(item);
      }
      return col;
    }, []);

    const isValid = !newData.filter(item => item.errors).length;

    if (data.length > 1) {
      return {
        ...state,
        members_new: {
          ...members_new,
          isValid,
          data: newData
        }
      };
    }
  }

  if (actions.MEMBERS_ADD === action.type) {
    const members_new = state.members_new;
    const data = members_new.data;
    data.push({
      uid: uuid(),
      email: '',
      displayName: '',
      teams: [],
      role: null,
      admin: false
    });
    return {
      ...state,
      members_new: {
        ...members_new,
        isValid: false,
        data
      }
    };
  }

  if (actions.MEMBERS_POPULATE_ENTITY === action.type) {
    const members_new = state.members_new;
    const { uid } = action.meta;

    const data = members_new.data.map(item => {
      const selected = { values: {} };

      if (item.uid === uid) {
        if (action.payload.userIds) {
          selected.values = {
            userIds: action.payload.userIds.filter(item => item)
          };
        } else {
          selected.values = action.payload;
        }
      }
      const result = {
        ...item,
        ...selected.values
      };
      result.errors = validate(result, schema);

      return result;
    });

    const isValid = !data.filter(item => item.errors).length;

    return {
      ...state,
      members_new: {
        ...members_new,
        isValid,
        data
      }
    };
  }

  if (actions.MEMBERS_FETCH_SUCCESSFUL === action.type) {
    const members_new = state.members_new;
    const members_edit = state.members_edit;
    const {
      roles,
      members_edit: { values }
    } = action.payload;
    return {
      ...state,
      members_edit: {
        ...members_edit,
        values: {
          ...members_edit.values,
          ...values
        }
      },
      members_new: {
        ...members_new,
        roles,
        data: [
          {
            uid: uuid(),
            userIds: [],
            roleId: ''
          }
        ]
      }
    };
  }
  return state;
};

export default members;
