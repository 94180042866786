import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import { MonitoringList } from 'views/Monitoring/List';
import { ResourceMonitoring } from 'views/Monitoring/View';

export const monitoringRoutes = [
  <AuthorizedRoute
    component={MonitoringList}
    exact
    layout={MainLayoutV5}
    path="/monitoring"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ResourceMonitoring}
    exact
    layout={MainLayoutV5}
    path="/monitoring/:resourceUid"
    planFeature="Monitoring"
  />
];
