import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareFetch(axios, action) {
  try {
    const { applicationId, incidentId, values } = action.params;
    yield axios.put(`/applications/${applicationId}/incidents/${incidentId}/postMortem`, {
      summary: values.postMortem.summary,
      rootCause: values.postMortem.rootCause,
      recovery: values.postMortem.recovery,
      correctiveActions: values.postMortem.correctiveActions
    });
    yield axios.put(`/applications/${applicationId}/incidents/${incidentId}`, {
      title: values.title,
      description: values.description
    });
    yield put({
      type: actions.GLOBAL_SUCCESS,
      payload: 'saved information'
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSaveIncidentInformations(axios) {
  yield takeLatest(actions.SAVE_INCIDENT_INFORMATIONS, prepareFetch, axios);
}
