import { useState } from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'redux/axios';

import DeleteEntity from '../../../../../oldComponents/DeleteEntityDialog';
import ShowDependencies from '../../../../../oldComponents/DeleteEntityDialog/components/ShowDependencies';

import SetupCloud from './SetupCloud';

const DataGrid = ({
  clouds: { data, total },
  onPageChange,
  onRowsPerPageChange,
  onSort,
  paginationDefaultPage,
  onRemove,
  cols,
  title,
  permissions,
  onCheckCloud,
  entity
}) => {
  const dispatch = useDispatch();

  const [entityToDelete, setEntityToDelete] = useState(null);

  const { data: searchResult } = useSelector(state => state[entity]);

  const editHandler = ({ id }) => dispatch(push(`/clouds/${id}/edit`));

  const deleteIcon = cloud => (
    <IconButton
      title="Remove"
      onClick={() => setEntityToDelete(cloud)}
      disabled={!permissions.remove}>
      <DeleteIcon />
    </IconButton>
  );

  const editIcon = cloud => (
    <IconButton
      data-user={cloud?.name}
      onClick={() => editHandler(cloud)}
      title="Edit"
      disabled={!permissions.update}>
      <EditIcon />
    </IconButton>
  );

  const columns = [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => (
        <SetupCloud disabled={!permissions.validate} checkCloud={onCheckCloud} {...row} />
      )
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => editIcon(row)
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: row => deleteIcon(row)
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'of',
    noRowsPerPage: false
  };

  const dataGridTheme = {
    title: {
      height: title ? 56 : 0
    }
  };

  const handleCloseDialog = () => {
    setEntityToDelete(null);
  };

  const { data: cloudDependencies, isLoading: gettingCloudDependencies } = useQuery(
    ['cloudDependencies', entityToDelete?.id],
    async () => (await axios.get(`clouds/${entityToDelete?.id}/dependencies`)).data.data,
    {
      enabled: Boolean(entityToDelete)
    }
  );

  return (
    <>
      <DataTable
        clicked
        columns={columns}
        customTheme={dataGridTheme}
        data={searchResult.length ? searchResult : data}
        highlightOnHover
        noDataComponent="No data"
        onChangePage={onPageChange}
        onChangeRowsPerPage={onRowsPerPageChange}
        onSort={onSort}
        pagination
        paginationDefaultPage={paginationDefaultPage}
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={total}
        sortServer
        striped
        title={title}
      />
      {entityToDelete ? (
        <DeleteEntity
          open={true}
          disabled={
            !permissions.remove ||
            cloudDependencies?.environments.length ||
            cloudDependencies?.services.length
              ? true
              : false
          }
          handleClose={handleCloseDialog}
          entity={entityToDelete}
          entityName="Cloud"
          handleRemove={() => {
            onRemove(entityToDelete);
            handleCloseDialog();
          }}
          isLoading={gettingCloudDependencies}>
          {cloudDependencies?.environments.length ? (
            <ShowDependencies
              dependencies={[
                { items: cloudDependencies?.environments, type: 'Environment' },
                { items: cloudDependencies?.services, type: 'Service' }
              ]}
              title="Cannot remove because there are dependencies"
            />
          ) : null}
        </DeleteEntity>
      ) : null}
    </>
  );
};

export default DataGrid;
