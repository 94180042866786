import { OverrideFn } from '.';

export const MuiOutlinedInput: OverrideFn<'MuiOutlinedInput'> = theme => ({
  root: {
    '&:hover $notchedOutline': {
      borderColor: theme.palette.gray[500]
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.gray[500],
      borderWidth: 1
    }
  },
  notchedOutline: {
    borderColor: theme.palette.gray[300]
  }
});
