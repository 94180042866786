import { MemberType } from 'types/external/Member';
import { useQuery } from 'urql';

const GetMembersQuery = `#graphql
  query (
    $query: String!
    $size: Int!
    $from: Int!
  ) {
    getTeamMembersV3(
      from: $from
      size: $size
      query: $query
    ) {
      name
      teamId
      memberId
      roleId
      userId
      userUid
      role {
        name
      }
    }
  }
`;

type useGetMembersParams = {
  from: number;
  size: number;
  query?: string;
  pause?: boolean;
};

type Variables = {
  from: number;
  size: number;
  query?: string;
};

export const useGetMembers = ({ from, size, query, pause = false }: useGetMembersParams) => {
  const [{ fetching, data }, reexecuteQuery] = useQuery<
    { getTeamMembersV3: MemberType[] },
    Variables
  >({
    query: GetMembersQuery,
    variables: {
      from,
      size,
      query
    },
    pause
  });

  return {
    fetching,
    data: data?.getTeamMembersV3 || [],
    reexecuteQuery
  };
};

export default useGetMembers;
