import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Box,
  Dialog,
  Paper,
  Link
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';
import { useMutation as useMutationUrql } from 'urql';
import * as z from 'zod';

import InputDefault from 'componentsV3/InputDefault/index';
import InputPhoneNumber from 'componentsV3/InputPhoneNumber/index';
import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { ButtonSimple } from 'componentsV4/Button';
import ErrorModal from 'componentsV4/ErrorModal';
import SuccessfulModal from 'componentsV4/SuccessfulModal';
import axios from 'redux/axios';

const ChangePassword = `#graphql
  mutation($email: String!) {
    changePassword(email: $email)
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.grey[300]
  },
  verifyButton: {
    marginTop: '30px',
    height: '40px',
    boxShadow: 'none'
  },
  helperTextRed: {
    color: 'red'
  },
  helperTextGreen: {
    color: theme.palette.primary.main
  },
  dialogResendCode: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(1)
  },
  dialogTitle: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3)
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    color: 'red'
  },
  progressBar: {
    marginTop: theme.spacing(2),
    width: '80%'
  },
  cardHeader: {
    color: theme.palette.text.primary
  },
  botConversation: {
    height: '40px',
    boxShadow: 'none',
    marginTop: '22px'
  },
  text: {
    color: '#FFF'
  }
}));

const schema = z.object({
  displayName: z.string().nonempty({ message: 'Name is required' }),
  phone: z.string(),
  verifiedPhone: z.boolean(),
  telegramId: z.string().optional()
});

export const AccountDetails = props => {
  const classes = useStyles();

  const { className, account_edit, saveHandler, ...rest } = props;
  const values = account_edit.profile;
  const [open, setOpen] = React.useState(false);
  const [sendServerError, setSendServerError] = React.useState(false);
  const [sendCheckError, setSendCheckError] = React.useState(false);
  const [resetCode, setResetCode] = React.useState(1);

  const user = useSelector(state => state.user);

  const { register, control, handleSubmit, watch, setValue, errors } = useForm({
    defaultValues: {
      displayName: values ? values.displayName : '',
      phone: values ? values.phone : '',
      verifiedPhone: values ? values.verifiedPhone : false,
      telegramId: values ? values.telegramId : ''
    },
    resolver: zodResolver(schema),
    shouldUnregister: false
  });

  const verifiedPhoneValue = watch('verifiedPhone');
  const phoneValue = watch('phone');

  const [handleSendCode] = useMutation(
    async () =>
      axios.post('/verify/phone/send/code', {
        phone: phoneValue?.replaceAll(/\D/g, '')
      }),
    {
      onError: () => {
        setSendServerError(true);
      }
    }
  );

  const [handleCheckCode, { isLoading }] = useMutation(
    async code =>
      axios
        .post('/verify/phone/check/code', {
          phone: phoneValue,
          code: code
        })
        .then(response => response.data),
    {
      onSuccess: ({ data }) => {
        if (data.valid === false) {
          setSendCheckError(true);
          setResetCode(resetCode + 1);
        }
        if (data.status === 'approved') {
          setValue('verifiedPhone', true, { shouldDirty: true });
          handleClose();
        }
      },
      onError: () => {
        setSendServerError(true);
      }
    }
  );

  const handleVerificationCode = () => {
    setOpen(true);
    setSendServerError(false);
    handleSendCode();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCodeComplete = value => {
    handleCheckCode(value);
  };

  const submitMutation = data => {
    saveHandler(data);
  };

  const [{ fetching }, changePassword] = useMutationUrql(ChangePassword);

  const [openSuccessfulModal, setOpenSuccessfulModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const handleResetPassword = () => {
    changePassword({ email: user.email }).then(result => {
      result.error?.graphQLErrors?.length ? setOpenErrorModal(true) : setOpenSuccessfulModal(true);
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)} variant="outlined">
      <form autoComplete="off" noValidate onSubmit={handleSubmit(submitMutation)}>
        <CardHeader
          subheader="Information"
          title="Profile"
          subheaderTypographyProps={{ color: 'textPrimary' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <InputDefault
                name="displayName"
                label="Name"
                required={true}
                inputRef={register()}
                errors={errors}
              />
            </Grid>
            <Grid item md={8} xs={8}>
              <InputPhoneNumber
                label="Phone"
                name="phone"
                errors={errors}
                control={control}
                disabled={verifiedPhoneValue}
                helperText={!verifiedPhoneValue && phoneValue?.length > 3 ? 'unchecked number' : ''}
              />
              {verifiedPhoneValue ? (
                <Typography className={classes.helperTextGreen}>Verified phone number ✔</Typography>
              ) : null}
            </Grid>
            <Grid item md={4} xs={4}>
              <input hidden type="checkbox" ref={register()} name="verifiedPhone" />
              <Button
                className={classes.verifyButton}
                color="primary"
                fullWidth
                disabled={verifiedPhoneValue || phoneValue?.length < 13}
                onClick={handleVerificationCode}
                variant="contained">
                Send verification code
              </Button>
              <Dialog maxWidth="xs" fullWidth={true} open={open} onClose={handleClose}>
                <Paper elevation={3}>
                  <Box display="flex" justifyContent="center">
                    <Typography noWrap={true} variant="h5" className={classes.dialogTitle}>
                      Verification Code
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" width="100%">
                    <ReactCodeInput key={resetCode} fields={4} onComplete={handleCodeComplete} />
                  </Box>
                  <Box display="flex" justifyContent="center" width="100%">
                    {isLoading ? <LinearProgress className={classes.progressBar} /> : null}
                  </Box>
                  {sendServerError ? (
                    <Box display="flex" justifyContent="center" width="100%">
                      <Typography variant="h6" className={classes.errorMessage}>
                        Couldn't send message, please try again later.
                      </Typography>
                    </Box>
                  ) : null}
                  {sendCheckError ? (
                    <Box display="flex" justifyContent="center" width="100%">
                      <Typography variant="h6" className={classes.errorMessage}>
                        Wrong code, please verify.
                      </Typography>
                    </Box>
                  ) : null}
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      className={classes.dialogResendCode}
                      color="primary"
                      onClick={handleSendCode}>
                      Re-send verification code
                    </Button>
                  </Box>
                </Paper>
              </Dialog>
            </Grid>

            <Grid item md={8} xs={8}>
              <InputDefault
                name="telegramId"
                label="Telegram ID"
                required={false}
                inputRef={register()}
                errors={errors}
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <Button
                className={classes.botConversation}
                color="primary"
                fullWidth
                variant="contained">
                <Link
                  href="https://t.me/GlorfindelBot"
                  underline="none"
                  className={classes.text}
                  target="_blank">
                  Start Bot Conversation
                </Link>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ButtonSimple color="primary" type="submit" variant="contained" text="Save" />
          </Box>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ButtonSimple
              color="primary"
              onClick={handleResetPassword}
              variant="contained"
              text="Reset Password"
            />
          </Box>
        </CardActions>
        {fetching ? <LoadingOverlay size={45} /> : null}
      </form>

      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        title="Failed to reset password"
        bodyComponent="Please try again later or contact support"
      />
      <SuccessfulModal
        open={openSuccessfulModal}
        setOpen={setOpenSuccessfulModal}
        title="We've just sent you an email to reset your password"
        bodyComponent="Check your email, if it doesn't arrive, check your spam box"
      />
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};
