import { FunctionFormData } from 'views/ServicesHub/forms/monitoring/serveless/Function';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

import { functionsCommomData } from './functionsCommomData';

export function functionAdapter(data: MonitoringCommomData & FunctionFormData) {
  const functionData = functionsCommomData(data);
  return {
    ...monitoringAdapter({ ...data, method: 'functions' }),
    ...functionData
  };
}
