import { Chip, ChipProps, Divider } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import { SeverityStatusEnum } from 'types/external/Severity';
import { StatusEnum } from 'types/external/Status';

import { useStyles } from './styles';

export type StatusChipProps = ChipProps & {
  type: string;
  status?: StatusEnum;
  severityStatus?: SeverityStatusEnum;
  isAlertStatus: boolean;
  isPrivate?: boolean;
};

function getLabelBySeverityStatus(status: SeverityStatusEnum) {
  const statusLabelMap = {
    [SeverityStatusEnum.NotClassified]: 'Not Classified',
    [SeverityStatusEnum.Critical]: 'SEV 1 - Critical',
    [SeverityStatusEnum.High]: 'SEV 2 - High',
    [SeverityStatusEnum.Moderate]: 'SEV 3 - Moderate',
    [SeverityStatusEnum.Low]: 'SEV 4 - Low',
    [SeverityStatusEnum.Informational]: 'SEV 5 - Informational'
  };

  return statusLabelMap[status];
}

function getLabelByAlertStatus(status: StatusEnum) {
  const statusLabelMap = {
    [StatusEnum.Alarmed]: 'Alarmed',
    [StatusEnum.Resolved]: 'Resolved',
    [StatusEnum.Acknowledged]: 'Acknowledged',
    [StatusEnum.PostMortem]: 'PostMortem'
  };

  return statusLabelMap[status];
}

function getLabelByIncidentStatusUpdate(isPrivate: boolean) {
  return isPrivate ? 'Private incident status update' : 'Incident status update';
}

export function StatusChip({
  type,
  status,
  severityStatus,
  isAlertStatus,
  isPrivate = false,
  ...props
}: StatusChipProps) {
  const classes = useStyles({ status, severityStatus });

  switch (type) {
    case 'IncidentStatusUpdate':
      return (
        <Chip
          className={classes.chipIncidentStatusUpdateSeverityStatus}
          label={getLabelByIncidentStatusUpdate(isPrivate)}
          icon={<Update style={{ color: 'white' }} />}
          {...props}
        />
      );
    case 'Severity':
      return (
        <Chip
          className={classes.chipSeverityStatus}
          label={getLabelBySeverityStatus(
            severityStatus ? severityStatus : SeverityStatusEnum.NotClassified
          )}
          {...props}
        />
      );
    case 'Event':
      return (
        <Chip
          variant="outlined"
          className={classes.chipAlertStatus}
          label={getLabelByAlertStatus(status!)}
          {...props}
        />
      );
  }

  return <Divider />;
}
