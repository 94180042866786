import { Icon, Typography, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

const IncidentError = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.emptyState}>
      <div>
        <Icon style={{ fontSize: 68, color: theme.palette.warning.main }}>warning</Icon>
      </div>
      <div>
        <Typography variant="h4" gutterBottom>
          Problem loading incident
        </Typography>
        <Typography variant="subtitle2">Incident not found or deleted.</Typography>
      </div>
    </div>
  );
};

export default IncidentError;
