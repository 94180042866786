import { useMutation } from 'urql';

const OpenIssueMutation = `#graphql
  mutation($data: OpenIssueInput!) {
    openIssue(data: $data) {
      message
      success
    }
  }`;

type OpenIssueResponse = {
  openIssue: { message: string; success: boolean };
};

type OpenIssueVariables = {
  data: {
    incidentId: number;
    title: string;
    description: string;
    integrationUid: string;
  };
};

export const useOpenIssue = () => {
  const [{ data, fetching }, openIssue] = useMutation<OpenIssueResponse, OpenIssueVariables>(
    OpenIssueMutation
  );

  const success = data?.openIssue.success;

  return { openIssue, fetching, success };
};
