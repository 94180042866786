import ApplicationType from 'constants/ApplicationType';

import {
  makeStyles,
  Card,
  Typography,
  CardContent,
  CardHeader,
  Grid,
  Box
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { ButtonSimple } from 'componentsV4/Button';

import ResourceNewDialog from '../ResourceNewDialog';

const NewButton = props => (
  <ButtonSimple color="primary" title="New Application" variant="contained" {...props} text="New" />
);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    textAlign: 'center'
  },
  sub: {
    marginBottom: theme.spacing(3)
  },
  cardHeader: {
    minHeight: 80
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const Item = ({
  productId,
  type,
  title,
  description,
  agentRequirement,
  addonsInfo,
  predefinedSettings,
  ...otherProps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item sm={4}>
      <Card className={classes.root}>
        <CardHeader className={classes.cardHeader} title={title} />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.sub} color={theme.palette.text.primary}>
            {description}
          </Typography>

          <Box marginTop="auto" marginBottom={1}>
            <Typography>{agentRequirement}</Typography>
            <Typography>{addonsInfo}</Typography>
          </Box>

          <ResourceNewDialog
            openerComponent={NewButton}
            productId={productId}
            applicationTypeInfos={{
              type,
              title,
              description,
              predefinedSettings
            }}
            {...otherProps}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

const CardsList = ({ productId }) => {
  return (
    <Grid container spacing={2}>
      <>
        <Item productId={productId} {...ApplicationType.Internal} />
        <Item productId={productId} {...ApplicationType.ExternalWithAddons} />
        <Item productId={productId} {...ApplicationType.ExternalWithoutAddons} />

        <Item productId={productId} {...ApplicationType.Webhook} maxWidth="sm" />
        <Item productId={productId} {...ApplicationType.InternalMultiHttp} />
      </>
    </Grid>
  );
};

export default CardsList;
