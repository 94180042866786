import { Box } from '@material-ui/core';

import NotificationsRules from 'components/NotificationRules';
import { useGetUserProvidersSettings } from 'hooks/useUserProvidersSettings';

const NotificationsRulesTab = () => {
  const {
    reexecuteQuery: refetchIncidents,
    data: incidentUserProvidersSettingsData,
    fetching: fetchingIncidentUserProvidersSettingsData
  } = useGetUserProvidersSettings({ type: 'incident' });

  const {
    reexecuteQuery: refetchAlerts,
    data: alertUserProvidersSettingsData,
    fetching: fetchingAlertUserProvidersSettingsData
  } = useGetUserProvidersSettings({ type: 'alert' });

  const {
    reexecuteQuery: refetchOnCall,
    data: onCallUserProvidersSettingsData,
    fetching: fetchingOnCallUserProvidersSettingsData
  } = useGetUserProvidersSettings({ type: 'on-call' });

  return (
    <Box display="flex" flex={1} gridGap="2.5rem" flexDirection="column" position="relative">
      <NotificationsRules
        refetchIncidents={() => refetchIncidents()}
        refetchAlerts={() => refetchAlerts()}
        refetchOnCall={() => refetchOnCall()}
        fetchingAlertRules={fetchingAlertUserProvidersSettingsData}
        fetchingIncidentRules={fetchingIncidentUserProvidersSettingsData}
        fetchingOnCallRules={fetchingOnCallUserProvidersSettingsData}
        contactMethodsAlert={alertUserProvidersSettingsData || []}
        contactMethodsIncident={incidentUserProvidersSettingsData || []}
        contactMethodsOnCall={onCallUserProvidersSettingsData || []}
      />
    </Box>
  );
};

export default NotificationsRulesTab;
