import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import { CreateApplicationV2Mutation } from 'graphqlQueries/createApplicationV2';
import { UpdateApplicationV2 } from 'graphqlQueries/updateApplicationsV2';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { FullApplication as Application } from 'types/external/Application';
import { useMutation } from 'urql';

import { LoadingOverlay } from 'componentsV4/Loading';
import { useApplication } from 'hooks/queriesGraphQL/useApplication';
import { useDependencies } from 'hooks/queriesGraphQL/useDependency';
import { useMultiOrigins } from 'hooks/queriesGraphQL/useMultiOriginsMonitorings';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { ApplicationFormData } from 'views/ServicesHub/adapters/new/application';
import { LayoutType } from 'views/ServicesHub/forms';
import { DependenciesForm } from 'views/ServicesHub/forms/dependencies';

import { schema } from './schema';
import { useStyles } from './styles';

type FormValues = {
  name: string;
  dependencies: { entityUid: string; dependencyUid: string }[];
  entityUid: string;
  productId?: string;
};

export function ApplicationLayout({ Form, isEdit }: LayoutType) {
  const classes = useStyles();

  const { uid } = useParams<{ uid: string }>();

  const [{ data: applicationData, fetching: isFetchingApp }] = useApplication(uid);
  const [{ data: dependenciesData, fetching: isFetchingDependencies }] = useDependencies(uid);
  const [{ data: origins, fetching: isFetchingOrigins }] = useMultiOrigins();

  const monitoringList = origins?.getMultiOriginsMonitorings?.data;

  const isLoading = isFetchingApp || isFetchingDependencies || isFetchingOrigins;

  const [{ fetching: isCreating }, createApplicationV2] = useMutation<{
    createApplicationV2: Application;
  }>(CreateApplicationV2Mutation);

  const [{ fetching: isUpdating }, updateApplicationV2] = useMutation<{
    updateApplicationV2: Application;
  }>(UpdateApplicationV2);

  const application = applicationData?.applicationV2;

  const dependencies = dependenciesData?.getDependenciesList;

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      name: '',
      dependencies: [{ entityUid: '', dependencyUid: '' }],
      entityUid: '',
      productId: ''
    },
    resolver: zodResolver(schema)
  });

  const { reset } = form;

  React.useEffect(() => {
    reset({
      name: application?.name,
      dependencies: dependencies,
      entityUid: application?.entityUid,
      productId: application?.productId ? application?.productId?.toString() : ''
    });
  }, [reset, application, dependencies]);

  const hasPermissionCreate = usePermission('ApplicationController-post-/applications');
  const hasPermissionEdit = usePermission('ApplicationController-put-/applications');

  const history = useHistory();
  const dispatch = useDispatch();

  const prepareDependencies = ({
    filteredDependencies
  }: {
    filteredDependencies: { entityUid: string; dependencyUid: string }[];
  }) => {
    if (!filteredDependencies) return [];
    return filteredDependencies?.map(dependency => {
      const foundEntityForDependency = monitoringList?.find(entity => {
        return entity.uid === dependency.entityUid;
      });

      const commonDependencyData = {
        entityUid: foundEntityForDependency?.uid,
        entity: foundEntityForDependency?.entity
      };

      if (dependencies) {
        const foundStartValueDependency = dependencies
          ? dependencies.find(entity => {
              return entity.dependencyUid === dependency.dependencyUid;
            })
          : undefined;

        if (!foundStartValueDependency) return commonDependencyData;

        return {
          ...commonDependencyData,
          id: foundStartValueDependency.dependencyId,
          uid: foundStartValueDependency.dependencyUid
        };
      }

      return commonDependencyData;
    });
  };

  const handleSubmit = async (data: ApplicationFormData) => {
    const foundEntityForApplication = monitoringList?.find(entity => {
      return entity.uid === data.entityUid;
    });

    const filteredDependencies = data.dependencies.filter(dependency => {
      return Boolean(dependency.entityUid);
    });

    const applicationSubmit = {
      id: application?.id,
      applicationUid: uid,
      name: data.name,
      dependencies: prepareDependencies({ filteredDependencies }),
      entityUid: foundEntityForApplication?.uid,
      entity: foundEntityForApplication?.entity,
      productId: data.productId === '' ? null : Number(data.productId)
    };

    if (isEdit) {
      const dependenciesToDelete = dependencies
        ? dependencies
            .filter(oldDependency => {
              const found = filteredDependencies.find(newDependency => {
                return newDependency.dependencyUid === oldDependency.dependencyUid;
              });
              if (found) return false;
              return true;
            })
            .map(dependendency => dependendency.dependencyUid)
        : [];

      const applicationToUpdate = {
        ...applicationSubmit,
        dependenciesToDelete
      };
      const applicationResponse = await updateApplicationV2({ data: applicationToUpdate });

      if (applicationResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on edit application' }
        });
        // eslint-disable-next-line no-console
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application edit successfully'
      });

      history.push(`/applications/${uid}`);
    }

    if (!isEdit) {
      const applicationResponse = await createApplicationV2({
        data: {
          ...applicationSubmit,
          dependencies: prepareDependencies({ filteredDependencies })
        }
      });

      if (applicationResponse.error) {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: { message: 'Error on create application' }
        });
        // eslint-disable-next-line no-console
        return;
      }

      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Application created successfully'
      });

      history.push(`/applications/${applicationResponse.data?.createApplicationV2.uid}`);
    }
  };

  const fetching = isLoading || isCreating || isUpdating;

  return (
    <Box
      component="form"
      display="flex"
      flex={1}
      gridGap="2.5rem"
      flexDirection="column"
      position="relative"
      onSubmit={form.handleSubmit(handleSubmit)}>
      <Box>
        {fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h5">
          Basic Informations
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <Form form={form} />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Basic information
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Name your application and select the main monitoring for the group. If you don't have
              any registered monitoring, click on '+ Monitoring' to add new ones. After adding,
              click 'Update' and then select the created monitoring.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider className={classes.sectionDivider} />

      <Box>
        {fetching && <LoadingOverlay />}

        <Typography className={classes.sectionTitle} variant="h5">
          Link dependencies to this application
        </Typography>

        <Box display="flex" gridGap="3rem">
          <Box flex={2}>
            <Box display="flex" gridGap="2rem" flexDirection="column">
              <DependenciesForm form={form} />
            </Box>
          </Box>
          <Box flex={1}>
            <Typography className={classes.infoSectionTitle} variant="subtitle2">
              Link monitoring
            </Typography>
            <Typography className={classes.infoSectionDescription} variant="subtitle1">
              Include the desired monitors in the application group. Upon selection, the monitoring
              will be established as a dependency within the previously configured application.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          disabled={isCreating}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!(isEdit ? hasPermissionEdit : hasPermissionCreate) || isCreating}>
          {isEdit ? 'Save' : 'Create'} application
        </Button>
      </Box>
    </Box>
  );
}
