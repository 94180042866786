import { IncidentManualData } from 'views/ServicesHub/layouts/Incident';

export function incidentManualAdapter(data: IncidentManualData) {
  return {
    name: data.incidentName,
    cause: data.cause,
    status: data.incidentStatus,
    severity: !data.severity ? 'sev-1-critical' : data.severity,
    started_at: data.startedAt,
    linked_origins: data.origins
      ? data.origins.map(origin => ({
          origin_uid: origin.value,
          origin_entity: origin.type
        }))
      : [],
    linked_teams: data.teams ? data.teams.map(team => Number(team.value)) : []
  };
}
