import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import UserList from './UserList';

import { Users } from '.';

export const SwitchUserList = () => {
  const useAccountViaAragorn = useFeatureFlagUnleash('useAccountViaAragorn', { queryString: true });

  if (useAccountViaAragorn) {
    return <Users />;
  }

  return <UserList />;
};
