import React from 'react';

import { Button, Tabs, Tab, Box } from '@material-ui/core';
import { OldDeleteDialog } from 'oldComponents/OldDeleteDialog';

import KubernetesTab from './KubernetesTab';
import OsTab from './OsTab';

const AgentInstructionsDialog = ({ environment, renderIcon: RenderIcon }) => {
  const [ShowInstructions, setShowInstructions] = React.useState(false);
  const [operationalSystem, setOperationalSystem] = React.useState(null);
  const [updateType, setUpdateType] = React.useState(null);

  const handleClose = () => {
    setShowInstructions(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = props => {
    const { index, value, children } = props;
    if (value === 0) {
      setValue('kubernetes');
    }
    return (
      <div
        hidden={value !== index}
        id={`custonAgent-tabpanel-${index}`}
        aria-labelledby={`custonAgent-tab-${index}`}>
        {value === index && children}
      </div>
    );
  };

  return (
    <>
      <OldDeleteDialog
        open={ShowInstructions}
        aria-labelledby="new-service-dialog"
        onClose={handleClose}
        title={'Agent installation instructions'}
        maxWidth="md"
        fullWidth>
        <Tabs
          id={'customAgent-tabs'}
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered>
          <Tab id={'customAgent-tab-kubernetes'} label="Kubernetes" value="kubernetes" />
          <Tab id={'customAgent-tab-os'} label="Operational System" value="os" />
        </Tabs>

        <TabPanel id={'customAgent-tabPanel-kubernetes'} value={value} index={'kubernetes'}>
          <KubernetesTab
            id={'customAgent-kubernetesTab'}
            environment={environment}
            updateType={updateType}
            setUpdateType={setUpdateType}
          />
        </TabPanel>

        <TabPanel id={'customAgent-tabPanel-kubernetes'} value={value} index={'os'}>
          <OsTab
            id={'customAgent-osTab'}
            environment={environment}
            operationalSystem={operationalSystem}
            setOperationalSystem={setOperationalSystem}
          />
        </TabPanel>
        <Box id={`custonAgentBox-buttons`} display="flex" justifyContent="flex-end" marginTop={3}>
          <Button id={`custonAgent-cancel-button`} onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Box>
      </OldDeleteDialog>
      <RenderIcon
        onOpen={() => {
          setShowInstructions(true);
        }}
      />
    </>
  );
};

export default AgentInstructionsDialog;
