import { Box, BoxProps, List, ListItem } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import useStyles from './styles';

export interface WarningBoxGuideProps extends BoxProps {
  snippets: string[];
}

export const WarningBoxGuide = ({ snippets }: WarningBoxGuideProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.content}>
      <Warning className={classes.icon} />
      <List className={classes.list}>
        {snippets.map(snippet => (
          <ListItem className={classes.item}>{snippet}</ListItem>
        ))}
      </List>
    </Box>
  );
};
