import { useEffect, useState } from 'react';

import { Box, CircularProgress, Dialog, Divider, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { QRCodeSVG } from 'qrcode.react';
import { useQuery } from 'react-query';

import { Skeleton } from 'components/Skeletron';
import { useGetQRCodeToken, useCreateQRCodeToken } from 'hooks/useQRCodeToken';
import { directusV2 } from 'services/directus';

import useStyles from './styles';

export const QRCodeModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }) => {
  const classes = useStyles();

  const [token, setToken] = useState<string | null>(null);

  const { fetching, token: fetchedToken } = useGetQRCodeToken({
    pause: !open
  });

  const {
    token: createdToken,
    fetching: creatingToken,
    reexecuteQuery: reexecuteMutation
  } = useCreateQRCodeToken({
    pause: true
  });

  useEffect(() => {
    if (!open) return;

    if (fetchedToken) {
      setToken(fetchedToken);
      return;
    }

    if (!fetching) {
      reexecuteMutation();
    }
  }, [open, fetchedToken, fetching, reexecuteMutation]);

  useEffect(() => {
    if (createdToken) {
      setToken(createdToken);
    }
  }, [createdToken]);

  useEffect(() => {
    if (!token) return;

    const decodedToken = jwtDecode<{ exp: number }>(token);

    const expirationDate = dayjs.unix(decodedToken?.exp);
    const timeRemainingInMilliseconds = expirationDate.diff(dayjs());

    if (timeRemainingInMilliseconds < 0) return;

    const timeoutId = setTimeout(() => {
      reexecuteMutation();
    }, timeRemainingInMilliseconds);

    return () => clearTimeout(timeoutId);
  }, [token, reexecuteMutation]);

  const { data: qrCodeData, isLoading } = useQuery('qr_code_mobile', async () => {
    return directusV2.singleton('qr_code_mobile').read();
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box className={classes.root}>
        <Box textAlign="center">
          <Typography variant="h6" gutterBottom>
            Sign in with QR Code
          </Typography>
          <Typography variant="body2" gutterBottom>
            Open the Elven Platform mobile app and scan this code
            <br /> to sync it with your account.
          </Typography>
          <Box mt={3} justifyContent="center">
            {token && !(fetching || creatingToken) ? (
              <Box className={classes.svgBorder}>
                <QRCodeSVG
                  value={token}
                  size={170}
                  imageSettings={{
                    src: '/images/logos/elven-platform-logo-16x16.svg',
                    height: 28,
                    width: 28,
                    excavate: true
                  }}
                />
              </Box>
            ) : (
              <Box mt={10}>
                <CircularProgress size={70} />
              </Box>
            )}
          </Box>
        </Box>

        <Box ml={4} mr={4}>
          <Divider orientation="vertical" />
        </Box>

        <Box textAlign="center">
          <Typography variant="h6" gutterBottom>
            Download our app and never miss <br /> a notification!
          </Typography>
          <Box mt={5} className={classes.rightContent}>
            <Box>
              {!isLoading && qrCodeData?.app_store_qr_code_url ? (
                <Box className={classes.svgBorder}>
                  <QRCodeSVG value={qrCodeData?.app_store_qr_code_url} size={128} />
                </Box>
              ) : (
                <Box mt={5} mb={10}>
                  <CircularProgress size={40} />
                </Box>
              )}

              <Box marginTop={1}>
                {!isLoading && qrCodeData?.app_store_qr_code_url ? (
                  <img
                    src={qrCodeData.app_store_img_url}
                    alt="Download on the App Store"
                    className={classes.storeImg}
                  />
                ) : (
                  <Skeleton animation="wave" width={150} height={35} />
                )}
              </Box>
            </Box>

            <Box>
              {!isLoading && qrCodeData?.play_store_qr_code_url ? (
                <Box className={classes.svgBorder}>
                  <QRCodeSVG value={qrCodeData.play_store_qr_code_url} size={128} />
                </Box>
              ) : (
                <Box mt={5} mb={10}>
                  <CircularProgress size={40} />
                </Box>
              )}

              <Box marginTop={1}>
                {!isLoading && qrCodeData?.play_store_qr_code_url ? (
                  <img
                    src={qrCodeData.play_store_img_url}
                    alt="Get it on Google Play"
                    className={classes.storeImg}
                  />
                ) : (
                  <Skeleton animation="wave" width={150} height={35} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
