import { useCallback, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import schema from './schema';

export type EnvironmentForm = z.infer<typeof schema>;

type UseFormParams = {
  defaultValues: Partial<EnvironmentForm> & {
    cloudId?: number | null;
  };
};

type Warning = {
  name: string;
  message: string;
};

const useCustomUseForm = ({ defaultValues }: UseFormParams) => {
  const [warnings, setWarnings] = useState<Warning[]>([]);

  /**
   * @param name The input name
   */
  const setWarning = useCallback(
    (name: string, { message }: { message: string }) => {
      const warningAlreadyExists = warnings.some(warning => warning.name === name);

      if (warningAlreadyExists) return;

      setWarnings(warnings => [...warnings, { name, message }]);
    },
    [warnings]
  );

  /**
   * @param name The input name
   */
  const clearWarnings = useCallback(
    (name: string) => {
      const warningExists = warnings.some(warning => warning.name === name);

      if (!warningExists) return;

      return setWarnings(warnings =>
        warnings.filter(({ name: warningName }) => warningName !== name)
      );
    },
    [warnings]
  );

  const useFormMethods = useForm<EnvironmentForm>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(schema)
  });

  return useMemo(
    () => ({
      ...useFormMethods,
      clearWarnings,
      setWarning,
      warnings
    }),
    [warnings, clearWarnings, setWarning, useFormMethods]
  );
};

export default useCustomUseForm;
