import { Box, MenuItem } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { FormProps } from '..';
import { Input } from '../../components/Input';

export function OperationalSystemForm({ form: { control } }: FormProps) {
  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Controller
        control={control}
        name="osType"
        render={({ value, onChange }) => {
          return (
            <Input
              select
              name="osType"
              label="Choose your Operational System"
              value={value}
              onChange={event => onChange(event.target.value)}>
              <MenuItem value="amazonLinux">Amazon Linux</MenuItem>
              <MenuItem value="centOS7And8">CentOS 7</MenuItem>
              <MenuItem value="redHat">Red Hat</MenuItem>
              <MenuItem value="ubuntu">Ubuntu</MenuItem>
            </Input>
          );
        }}
      />
    </Box>
  );
}
