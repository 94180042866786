import { useEffect, useState } from 'react';

import { useQuery as useQueryGraphQL } from 'urql';

import { formatGetServiceStatus } from 'helpers/formatGetServiceStatus';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const GetServiceStatusQueryV4 = `#graphql
  query(
    $serviceId: Int!
    $applicationId: Int
    $addonId: Int
  ){
    serviceStatusV4(
      serviceId: $serviceId
      applicationId: $applicationId
      addonId: $addonId
    ) {
      id
      name
      application_id
      addon_id
      online
      started
      monitoringStatus
      isMonitoringActive
    }
  }
`;

const GetResourceStatus = `#graphql
  query(
    $resourceUid: String!
  ){
    getResourceStatus(
      resourceUid: $resourceUid
    ) {
      status
      lastCheck
    }
  }
`;

const useServiceStatus = ({
  useResource,
  resourceUid,
  serviceId,
  applicationId,
  addonId,
  applicationDependencyId,
  resourceType
}: {
  useResource?: boolean;
  resourceUid?: string;
  serviceId?: number;
  applicationId: number;
  resourceType: string;
  applicationDependencyId?: number;
  addonId: number;
}) => {
  const [firstRequest, setFirstRequest] = useState(true);

  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });

  const shouldUseResource = Boolean(useResourceFlag && useResource);

  const [resultGetResourceStatus, reexecuteGetResourceStatus] = useQueryGraphQL({
    query: GetResourceStatus,
    pause: !shouldUseResource || !serviceId,
    variables: {
      resourceUid
    }
  });

  const [resultServiceStatusV4, reexecuteQueryV4] = useQueryGraphQL({
    query: GetServiceStatusQueryV4,
    pause: shouldUseResource || !serviceId,
    variables: formatGetServiceStatus({
      applicationId,
      sterasId: serviceId,
      addonId,
      applicationDependencyId,
      resourceType
    })
  });

  const { fetching: fetchingData } = shouldUseResource
    ? resultGetResourceStatus
    : resultServiceStatusV4;

  const reexecuteQuery = shouldUseResource ? reexecuteGetResourceStatus : reexecuteQueryV4;

  const dataFromGraphQL =
    resultServiceStatusV4.data?.serviceStatusV4 || resultGetResourceStatus.data?.getResourceStatus;

  useEffect(() => {
    if (fetchingData) return;
    const intervalId = setInterval(
      () => {
        setFirstRequest(false);
        reexecuteQuery();
      },
      firstRequest ? 1000 * 10 : 6000 * 10
    );

    return () => clearInterval(intervalId);
  }, [fetchingData, reexecuteQuery, setFirstRequest, firstRequest]);

  return { dataFromGraphQL, reexecuteQuery };
};

export { useServiceStatus };
