import React from 'react';

import {
  makeStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Box
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    justifyContent: 'flex-end'
  },
  checked: {
    color: palette.primary.main
  },
  radioGrup: {
    display: 'flex',
    flexDirection: 'row'
  },
  radio: {
    color: palette.inputBorder.default,
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  imageBox: {
    maxWidth: 150,
    minWidth: 170,
    minHeight: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(2),
    borderRadius: 8,
    border: '1px solid',
    borderColor: props => (props.checked ? palette.primary.main : palette.inputBorder.default)
  },
  image: {
    width: 100
  }
}));

function Image({ checked, agent }) {
  const classes = useStyles({ checked });

  const icons = {
    kube: '/images/kubernetes.png',
    system: '/images/system.png'
  };

  return (
    <Box className={classes.imageBox}>
      <img className={classes.image} src={icons[agent]} alt={`${agent} agent`} />
    </Box>
  );
}

const AgentInput = ({ control, name }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <Controller
        name={name}
        control={control}
        as={
          <RadioGroup className={classes.radioGrup}>
            <FormControlLabel
              key={'kube'}
              value={'kube'}
              control={
                <Radio
                  classes={{
                    root: classes.radio
                  }}
                  icon={<Image agent="kube" />}
                  className={classes.radio}
                  checkedIcon={<Image agent="kube" checked />}
                  disableRipple
                  focusRipple={false}
                />
              }
            />

            <FormControlLabel
              key={'system'}
              value={'system'}
              control={
                <Radio
                  classes={{
                    root: classes.radio
                  }}
                  icon={<Image agent="system" />}
                  className={classes.radio}
                  checkedIcon={<Image agent="system" checked />}
                  disableRipple
                  focusRipple={false}
                />
              }
            />
          </RadioGroup>
        }
      />
    </FormControl>
  );
};

export default withThemeV3Hoc(AgentInput);
