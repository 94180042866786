import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/signup';

function* validateEmail(axios, action) {
  try {
    const { email } = action.payload;
    yield axios.get(`/auth/validateEmail?email=${email}`);
  } catch (err) {
    const response = JSON.parse(err.request.responseText);
    yield put({
      type: actions.SIGN_UP_INVALID_EMAIL,
      payload: response,
      meta: {
        entity: 'user',
        reducer
      }
    });
  }
}

export default function* watchValidateEmail(axios) {
  yield takeLatest(actions.VALIDATE_EMAIL, validateEmail, axios);
}
