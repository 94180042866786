import { useState, useCallback } from 'react';

import { IconButton, Icon } from '@material-ui/core';

import EditDialog from './EditDialog';

const EditAddonButton = ({
  addonId,
  productId,
  disabled
}: {
  addonId: number;
  productId: number;
  disabled: boolean;
}) => {
  const [dialog, setDialog] = useState(false);

  const handleChange = useCallback(() => {
    setDialog(!dialog);
  }, [setDialog, dialog]);

  return (
    <>
      <IconButton
        id={`editAppIcon`}
        title="Edit Dependency"
        onClick={handleChange}
        disabled={disabled}>
        <Icon>edit</Icon>
      </IconButton>

      <EditDialog
        open={dialog}
        handleToggle={handleChange}
        addonId={addonId}
        productId={productId}
      />
    </>
  );
};

export default EditAddonButton;
