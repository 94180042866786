import { UseFormMethods } from 'react-hook-form';

import { Provider } from 'hooks/queriesGraphQL/useGetProvider';

import { ProviderLayout } from '../layouts/ProviderLayout';

import { DiscordForm } from './Discord';
import { GoogleChatForm } from './GoogleChat';
import { MailForm } from './Mail';
import { SlackForm } from './Slack';
import { TeamsForm } from './Teams';
import { TelegramForm } from './Telegram';
import { WebhookForm } from './Webhook';

export type FormProps = {
  form: UseFormMethods<any>;
  startValues?: any;
};

export type FormType = {
  Layout: (props: {
    startValues?: any;
    isEdit: boolean;
    onClose: () => void;
    reexecuteQuery: () => void;
    hasPermissionCreate: boolean | null;
    hasPermissionUpdate: boolean | null;
  }) => JSX.Element;
  title?: string;
};

export type LayoutType = {
  Form: (props: FormProps) => JSX.Element;
  title: string;
  startValues?: Provider;
  isEdit: boolean;
  onClose: () => void;
  reexecuteQuery: () => void;
  hasPermissionCreate: boolean | null;
  hasPermissionUpdate: boolean | null;
};

export const forms: Record<string, FormType> = {
  mail: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={MailForm}
        title="E-mail group"
      />
    )
  },
  telegram: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={TelegramForm}
        title="Telegram group"
      />
    )
  },
  slack: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={SlackForm}
        title="Slack"
      />
    )
  },
  teams: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={TeamsForm}
        title="Microsoft Teams"
      />
    )
  },
  googlechat: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={GoogleChatForm}
        title="Google Chat"
      />
    )
  },
  discordchannel: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={DiscordForm}
        title="Discord"
      />
    )
  },
  webhook: {
    Layout: ({
      startValues,
      isEdit,
      onClose,
      reexecuteQuery,
      hasPermissionCreate,
      hasPermissionUpdate
    }) => (
      <ProviderLayout
        startValues={startValues}
        isEdit={isEdit}
        onClose={onClose}
        reexecuteQuery={reexecuteQuery}
        hasPermissionCreate={hasPermissionCreate}
        hasPermissionUpdate={hasPermissionUpdate}
        Form={WebhookForm}
        title="Webhook"
      />
    )
  }
};
