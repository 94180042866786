import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    width: '300px'
  },
  menuItem: {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#ECFDF5'
    }
  }
}));
