import MainLayoutV5 from 'layouts/v5/Main';
import { AuthorizedRoute } from 'routes/components/AuthorizedRoute';
import ServicesEditView from 'views/Services/Edit';
import ServicesListView from 'views/Services/List';
import ServicesNewView from 'views/Services/New';

export const serviceRoutesWithLayoutV2 = [
  <AuthorizedRoute
    component={ServicesListView}
    exact
    layout={MainLayoutV5}
    key="/services"
    path="/services"
    planFeature="Monitoring"
  />,
  <AuthorizedRoute
    component={ServicesNewView}
    exact
    layout={MainLayoutV5}
    key="/services/new"
    path="/services/new"
    planFeature="Monitoring"
    featureTarget="ServiceController-post-/services"
  />,
  <AuthorizedRoute
    component={ServicesEditView}
    exact
    layout={MainLayoutV5}
    key="/services/:id/edit"
    path="/services/:id/edit"
    planFeature="Monitoring"
    featureTarget="ServiceController-put-/services/:id"
  />
];
