import { SqlserverFormData } from 'views/ServicesHub/forms/monitoring/database/SqlserverForm';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function sqlserverAdapter(data: MonitoringCommomData & SqlserverFormData) {
  const domain = prepareDomain(data);
  // ex: sqlserver://USER:PASSWORD@HOST?database=DB

  return {
    ...monitoringAdapter({ ...data, method: 'sqlserver' }),
    domain,
    domainSettings: {
      domainType: data.domainType
    }
  };
}

const prepareDomain = (data: MonitoringCommomData & SqlserverFormData) => {
  if (data.domainType === 'userAndPass') {
    return `sqlserver://${data.user}:${data.password}@${data.host}?database=${data.database}`;
  }
  return data.domain;
};
