import React from 'react';

import cloudProviders from 'constants/cloudProviders';
import cloudStatus from 'constants/cloudStatus';

import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { red, orange, grey, blue } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import CloudIcon from '@material-ui/icons/Timelapse';

const SetupCloud = ({ id, provider, status, checkCloud, disabled }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const handleCheckCloud = () => {
    checkCloud(id);
    setAnchorEl(null);
  };

  if (provider === cloudProviders.custom) {
    return (
      <div title="Custom clouds doesn't need validation">
        <CloudDoneIcon style={{ color: blue[700] }} />
      </div>
    );
  }

  if (status === cloudStatus.Valid) {
    return (
      <div title="Verified cloud">
        <CloudDoneIcon style={{ color: theme.palette.primary.main }} />
      </div>
    );
  }

  if (status === cloudStatus.Invalid) {
    return (
      <>
        <IconButton
          onClick={event => setAnchorEl(event.currentTarget)}
          title="Fail validate cloud"
          disabled={disabled}>
          <CloudOffIcon style={{ color: red[500] }} />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Paper>
            <Box padding={2}>
              <Typography>Cloud validate is failing. Please contact us for support.</Typography>
              <Box display="flex" justifyContent="flex-end" marginTop={1}>
                <Button color="primary" onClick={handleCheckCloud} disabled={disabled}>
                  Try again?
                </Button>
              </Box>
            </Box>
          </Paper>
        </Popover>
      </>
    );
  }

  if (status === cloudStatus.Pending) {
    return (
      <div title="Pending cloud scan">
        <CloudIcon style={{ color: orange[500] }} />
      </div>
    );
  }

  if (status === cloudStatus.Initial) {
    return (
      <IconButton disabled={disabled} onClick={() => checkCloud(id)} title="Check cloud">
        <CloudQueueIcon style={{ color: grey[500] }} />
      </IconButton>
    );
  }

  return null;
};

export default SetupCloud;
