type GetTimelineDescription = {
  content?: { description?: string; 'description:'?: string };
};

export default function getTimelineDescription(item: GetTimelineDescription) {
  if (item?.content?.description) {
    return item?.content?.description;
  }
  if (item?.content && item.content['description:']) {
    return item.content['description:'];
  }
  return '';
}
