import { useQuery as useQueryUrql } from 'urql';

import { getProviders } from '../query';

const UseProvidersFromMsTeam = ({ teamId }: { teamId: number }) => {
  const [resultQuery, reexecuteQuery] = useQueryUrql({
    query: getProviders,
    variables: { teamId: teamId }
  });

  return { ...resultQuery, reexecuteQuery };
};

export default UseProvidersFromMsTeam;
