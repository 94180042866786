import { Grid, useTheme } from '@material-ui/core';

import InputDefault from 'componentsV3/InputDefault';
import InputWithSecrets from 'componentsV3/InputWithSecrets';
import withThemeV3Hoc from 'theme/v3/withTheme';

function CloudAzure({ control, errors, setValue, watch }) {
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputWithSecrets
            iconColor={theme.palette.primary.main}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            TextFieldProps={{
              name: 'clientId',
              label: 'clientId',
              type: 'password',
              required: true
            }}
            InputComponent={InputDefault}
          />
        </Grid>

        <Grid item xs={6}>
          <InputWithSecrets
            iconColor={theme.palette.primary.main}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            TextFieldProps={{
              name: 'clientSecret',
              label: 'clientSecret',
              type: 'password',
              required: true
            }}
            InputComponent={InputDefault}
          />
        </Grid>

        <Grid item xs={6}>
          <InputWithSecrets
            iconColor={theme.palette.primary.main}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            TextFieldProps={{
              name: 'tenantId',
              label: 'tenantId',
              type: 'password',
              required: true
            }}
            InputComponent={InputDefault}
          />
        </Grid>

        <Grid item xs={6}>
          <InputWithSecrets
            iconColor={theme.palette.primary.main}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            TextFieldProps={{
              name: 'subscriptionId',
              label: 'subscriptionId',
              type: 'password',
              required: true
            }}
            InputComponent={InputDefault}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withThemeV3Hoc(CloudAzure);
