import React from 'react';

import { Divider, Typography, TextField, Button, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import Breadcrumb from 'oldComponents/Breadcrumb';
import { parseUrl } from 'query-string';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useProductPermission } from 'hooks/useProductPermission';
import actions from 'redux/actions';
import axios from 'redux/axios';

import IncidentError from '../Components/IncidentError';
import LoadingSkeleton from '../Components/LoadingSkeleton';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  editableContent: {
    display: 'block',
    marginBottom: 10,
    '& textarea': {
      width: '100%'
    }
  },
  dividingLine: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  disableText: {
    color: 'gray'
  },
  saveButton: {
    marginTop: theme.spacing(4)
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  acknowledgeButton: {
    marginBottom: theme.spacing(2)
  }
}));

const View = ({ location, match: { params } }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { applicationId, incidentId } = params;
  const [incidentValues, setIncidentValues] = React.useState({});
  const [postMortemValues, setPostMortemValues] = React.useState({});
  const { productId } = useParams();

  const incidentAcknowledge = React.useCallback(
    ({ incidentId, applicationId, productId }) => {
      dispatch({
        type: actions.APPLICATION_INCIDENT_ACKNOWLEDGE,
        params: { applicationId, productId, incidentId }
      });
    },
    [dispatch]
  );

  const queryKey = ['incident', applicationId, incidentId];

  const { data: incident, isLoading } = useQuery(
    queryKey,
    () => {
      return axios
        .get(`/products/${productId}/applications/${applicationId}/incidents/${incidentId}`)
        .then(response => {
          const data = response.data.data;

          const filteredData = data?.timeline?.reduce((acc, current) => {
            const itemAlreadyExists = acc.find(
              item => item.incidentId === current.incidentId && item.type === current.type
            );

            if (!itemAlreadyExists) {
              acc.push(current);
            }

            return acc;
          }, []);

          return {
            ...response.data.data,
            timeline: filteredData
          };
        });
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: incident => {
        setIncidentValues({
          title: incident.title,
          description: incident.description
        });
        setPostMortemValues({
          summary: incident.post_mortem?.summary,
          rootCause: incident.post_mortem?.root_cause,
          recovery: incident.post_mortem?.recovery,
          correctiveActions: incident.post_mortem?.corrective_actions
        });
      }
    }
  );

  React.useEffect(() => {
    const { query } = parseUrl(location.search);
    if (query.acknowledge) {
      incidentAcknowledge({
        applicationId,
        incidentId,
        productId
      });
    }
  }, [location, incidentAcknowledge, applicationId, incidentId, productId]);

  const { data: product } = useQuery(
    ['products', productId],
    () => axios.get(`/products/${productId}`).then(response => response.data.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const permissions = {
    update: useProductPermission({
      productId,
      target:
        'ApplicationController-put-/products/:productId/applications/:id/incidents/:incidentId'
    })
  };

  const [incidentHasChanged, setIncidentHasChanged] = React.useState(false);
  const [postMortemHasChanged, setPostMortemHasChanged] = React.useState(false);

  const handleOnChangeIncident = event => {
    setIncidentValues({
      ...incidentValues,
      [event.target.name]: event.target.value
    });
    if (!incidentHasChanged) {
      setIncidentHasChanged(true);
    }
  };

  const handleOnChangePostMortem = event => {
    setPostMortemValues({
      ...postMortemValues,
      [event.target.name]: event.target.value
    });
    if (!postMortemHasChanged) {
      setPostMortemHasChanged(true);
    }
  };
  const queryCache = useQueryCache();
  const onSuccess = message => {
    queryCache.invalidateQueries(queryKey);
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: message
    });
  };

  const onError = err => {
    dispatch({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR' }
    });
  };

  const [handleSaveIncident] = useMutation(
    () => {
      return axios.put(
        `/products/${productId}/applications/${applicationId}/incidents/${incidentId}`,
        {
          title: incidentValues.title,
          description: incidentValues.description
        }
      );
    },
    {
      onSuccess: () => {
        setIncidentHasChanged(false);
        onSuccess('Updated incident');
      },
      onError
    }
  );

  const [handleAcknowledge] = useMutation(
    () => {
      return axios.post(
        `/products/${productId}/applications/${applicationId}/incidents/${incidentId}/acknowledge`,
        {
          incidentId,
          applicationId
        }
      );
    },
    {
      onSuccess: () => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'Acknowledge Successful'
        });
        setTimeout(() => {
          queryCache.invalidateQueries(queryKey);
        }, 1000);
      },
      onError
    }
  );

  const [handleSavePostMortem] = useMutation(
    () => {
      return axios.put(
        `/products/${productId}/applications/${applicationId}/incidents/${incidentId}/postMortem`,
        {
          summary: postMortemValues.summary,
          rootCause: postMortemValues.rootCause,
          recovery: postMortemValues.recovery,
          correctiveActions: postMortemValues.correctiveActions
        }
      );
    },
    {
      onSuccess: () => {
        setPostMortemHasChanged(false);
        onSuccess('Updated post mortem');
      }
    },
    onError
  );

  const handleSave = () => {
    if (incidentHasChanged) {
      handleSaveIncident();
    }
    if (postMortemHasChanged) {
      handleSavePostMortem();
    }
  };

  const changeType = type => {
    if (type === 'update_incident') {
      return 'Update Incident';
    }
    if (type === 'post_mortem') {
      return 'Post Mortem';
    }
    if (type === 'change_description') {
      return 'Change Description';
    } else {
      return type;
    }
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (incident) {
    const acceptedAck = ['acknowledged', 'resolved'];

    const isAcknowledged =
      incident.timeline && incident.timeline.length
        ? acceptedAck.includes(incident.timeline[0]?.type)
        : false;

    return (
      <div className={classes.root}>
        <Breadcrumb
          items={[
            { link: `/products`, label: 'Products' },
            { link: `/products/${productId}`, label: product?.name },
            {
              link: `/products/${productId}/availability/applications/${applicationId}`,
              label: incident?.name
            },
            { label: 'Incident' }
          ]}
        />
        <Typography gutterBottom variant="h3" className={classes.title}>
          {incident.name}
        </Typography>

        <Button
          onClick={handleAcknowledge}
          variant="contained"
          disabled={isAcknowledged}
          className={classes.acknowledgeButton}>
          Acknowledge
        </Button>

        <div className={classes.editableContent}>
          <Typography component="span" variant="h5">
            {'Environment: '}
          </Typography>
          <Typography component="span">{incident.environment}</Typography>
        </div>

        <div className={classes.editableContent}>
          <Typography component="span" variant="h5">
            {'Incident id: '}
          </Typography>
          <Typography component="span">{incident.id}</Typography>
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Title: '}
          </Typography>
          <TextField
            name="title"
            onChange={handleOnChangeIncident}
            value={incidentValues.title}
            multiline
            rows={1}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Description: '}
          </Typography>
          <TextField
            name="description"
            onChange={handleOnChangeIncident}
            value={incidentValues.description}
            multiline
            rows={6}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        {incident.timeline && (
          <>
            <Divider className={classes.dividingLine} />

            <div className={classes.editableContent}>
              <Typography variant="h5">Timeline:</Typography>
              <ul
                style={{
                  marginLeft: 17,
                  overflowY: 'scroll',
                  maxHeight: 170,
                  marginTop: 10
                }}>
                {incident.timeline?.map(item => (
                  <Typography component="li" style={{ textTransform: 'capitalize' }}>
                    {`${changeType(item.type)}, ${format(
                      new Date(item.created_at),
                      'MMMM dd, HH:mm zzz'
                    )}`}
                  </Typography>
                ))}
              </ul>
            </div>
          </>
        )}
        <Divider className={classes.dividingLine} />

        <div className={classes.editableContent}>
          <Typography variant="h3">Post Mortem</Typography>
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Summary: '}
          </Typography>
          <TextField
            name="summary"
            onChange={handleOnChangePostMortem}
            value={postMortemValues.summary}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Root cause: '}
          </Typography>
          <TextField
            name="rootCause"
            onChange={handleOnChangePostMortem}
            value={postMortemValues.rootCause}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Recovery: '}
          </Typography>
          <TextField
            name="recovery"
            onChange={handleOnChangePostMortem}
            value={postMortemValues.recovery}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        <div className={classes.editableContent}>
          <Typography className={!permissions.update ? classes.disableText : null} variant="h5">
            {'Corrective actions: '}
          </Typography>
          <TextField
            name="correctiveActions"
            onChange={handleOnChangePostMortem}
            value={postMortemValues.correctiveActions}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
            margin="dense"
            disabled={!permissions.update}
          />
        </div>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!permissions.update || (!incidentHasChanged && !postMortemHasChanged)}
          className={classes.saveButton}>
          Save
        </Button>
      </div>
    );
  }

  return <IncidentError />;
};

export default View;
