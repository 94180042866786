import { useQuery } from 'urql';

const GetAccountsQuery = `#graphql
  query {
    getAccounts {
      id
      org {
        id
        name
      }
    }
  }
`;

export type Accounts = {
  id: number;
  org: {
    id: number;
    name: string;
  };
};

type GetAccounts = {
  getAccounts: Accounts[];
};

export function useGetAccounts() {
  const [{ data, fetching }, reexecuteQuery] = useQuery<GetAccounts>({
    query: GetAccountsQuery
  });

  return {
    data: data?.getAccounts,
    fetching,
    reexecuteQuery
  };
}
