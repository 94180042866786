import validate from 'validate.js';

import actions from '../../actions';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 1200
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    length: {
      maximum: 1200
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    inclusion: {
      within: [true],
      message: 'Necessário aceitar os termos'
    }
  }
};

const orgs = (state, action) => {
  if (actions.SIGN_UP_CHANGE === action.type) {
    const org = state.org;
    const values = {
      ...org.values,
      ...action.payload.values
    };
    const errors = validate(values, schema);
    return {
      ...state,
      org: {
        isValid: !errors,
        values,
        touched: {
          ...org.touched,
          ...action.payload.touched
        },
        errors: errors || {}
      }
    };
  }

  if (actions.FETCH_ENTITY_SUCCESSFUL === action.type) {
    return {
      ...state,
      ...action.payload
    };
  }

  return state;
};

export default orgs;
