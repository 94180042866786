import { Box, Button, Link, Typography, TextFieldProps, useTheme } from '@material-ui/core';
import { Add, Loop } from '@material-ui/icons';
import { Control } from 'react-hook-form';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useTeams } from 'hooks/queriesGraphQL/useTeams';

import { MultiSelect } from '../MultiSelect';
import { Select } from '../Select';

import { useStyles } from './styles';

type TeamsSelectProps = {
  control: Control;
  name: string;
  required?: boolean;
  multiSelect?: boolean;
  disabled?: boolean;
  label?: string;
};

export function TeamsSelect({
  control,
  name,
  required = false,
  multiSelect = false,
  disabled = false,
  label = 'Responders',
  ...props
}: TeamsSelectProps & TextFieldProps) {
  const classes = useStyles();

  const theme = useTheme();

  const [{ data, fetching }, refetch] = useTeams();

  const options =
    data?.getTeamsByOrgUid?.data?.map(team => ({
      value: String(team.id),
      label: team.name
    })) ?? [];

  return (
    <Box position="relative" display="flex" gridGap={'1.5rem'}>
      {fetching && <LoadingOverlay size="2rem" />}
      {multiSelect ? (
        <MultiSelect
          disabled={disabled}
          options={options}
          name={name}
          label={label}
          control={control}
          required={required}
          {...props}
        />
      ) : (
        <Select
          control={control}
          name={name}
          options={options}
          label={label}
          required={required}
          disabled={disabled}
          {...props}
        />
      )}

      <Button
        disabled={disabled}
        variant="outlined"
        color="primary"
        title="Reload teams list"
        onClick={() => refetch()}>
        <Loop />
      </Button>
      <Link target="_blank" href="/teams">
        <Button
          variant="outlined"
          color="primary"
          disabled={disabled}
          style={{ marginLeft: theme.spacing(-1) }}>
          <Box display="flex" alignItems="center">
            <Add />
            <Box ml={1}>
              <Typography className={classes.responderText} color="primary">
                RESPONDER
              </Typography>
            </Box>
          </Box>
        </Button>
      </Link>
    </Box>
  );
}
