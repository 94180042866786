import { useEffect, ChangeEvent, useRef, useState } from 'react';

import { Box, Button, CircularProgress, Divider, TextField, useTheme } from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router';
import { MultiFlow, MultiFlowStatusEnum } from 'types/external/MultiFlow';

import Breadcrumb from 'components/Breadcrumb';
import { Title } from 'components/Title';
import { EventsFilters } from 'componentsV4/Filters';
import { PageFilters } from 'componentsV4/Filters/Search';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import { customElasticQuery } from 'helpers/customElasticQuery';
import { useStyles as useGlobalStyles } from 'helpers/globalStyles';
import useQueryParams from 'helpers/useQueryParams';
import { useMultiFlows } from 'hooks/queriesGraphQL/useMultiFlows';
import { useSynthetics } from 'hooks/queriesGraphQL/useSynthetics';
import useDebounce from 'hooks/useDebounce';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { CreateSyntheticButton } from './components/CreateSyntheticButton';
import { Filters, StatusFilters } from './components/Filters';
import { StatusChip } from './components/StatusChip';
import { useStylesMaterial } from './styles';

const PER_PAGE = [10, 25, 50];
const TYPING_DEBOUNCE_TIME = 500;

const COLUMNS: TableColumn<
  MultiFlow | Pick<MultiFlow, 'status' | 'name' | 'environment' | 'uid'>
>[] = [
  {
    name: 'Status',
    selector: row => row.status,
    cell: row => <StatusChip status={row.status} />
  },
  {
    name: 'Synthetic Monitoring Name',
    selector: row => row.name
  },
  {
    name: 'Environment',
    selector: row => row.environment?.name
  }
];

export function MultiFlowList() {
  const classes = useStylesMaterial();
  const globalClasses = useGlobalStyles();
  const theme = useTheme();
  const queryParams = useQueryParams();

  const shouldUseNewSyntheticFilters = useFeatureFlagUnleash('useNewSyntheticFilters', {
    queryString: true
  });

  const statusURL = queryParams.get('status');

  const history = useHistory();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<MultiFlowStatusEnum | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE[0]);

  const addQueryParams = (key: string, value: string) => {
    queryParams.set(key, value);
    history.replace({
      search: queryParams.toString()
    });
  };

  const removeQueryParams = (key: string) => {
    queryParams.delete(key);
    history.replace({
      search: queryParams.toString()
    });
  };

  useEffect(() => {
    statusURL && setStatus(statusURL as MultiFlowStatusEnum);
  }, [shouldUseNewSyntheticFilters, statusURL, setStatus]);

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  const handleChangeSize = (newSize: number) => setPerPage(newSize);
  const handleChangePage = (newPage: number) => setCurrentPage(newPage);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);
  const handleCloseFilters = () => setIsFiltersOpen(false);

  const handleApplyFilters = (filters: StatusFilters) => {
    setStatus(filters.status);
  };

  const handleClearFilters = () => {
    removeQueryParams('status');
    setStatus(null);
    setCurrentPage(1);
  };

  const searchElementRef = useRef<HTMLDivElement>(null);

  const [{ fetching, data }] = useMultiFlows({
    page: currentPage,
    perPage,
    query: buildElasticQuery({ search, status }),
    pause: shouldUseNewSyntheticFilters
  });

  const {
    data: { synthetics, total },
    fetching: isFetchingSynthetics,
    reexecuteQuery
  } = useSynthetics({
    page: currentPage,
    perPage,
    query: customElasticQuery({
      queryFilters: [
        {
          key: 'name',
          value: search,
          isRegex: true
        },
        {
          key: 'status',
          value: status || 'all',
          isRegex: false
        }
      ]
    }),
    aggregationRefs: 'synthetics',
    aggregationKeys: 'value_count',
    aggregationValues: 'id',
    ignoreDate: 'true',
    functions: [
      { filter: { term: { status: 'error' } }, weight: 10 },
      { filter: { term: { status: 'pending' } }, weight: 8 },
      { filter: { term: { status: 'success' } }, weight: 5 },
      { filter: { term: { status: 'off' } }, weight: 2 }
    ],
    sortKey: 'updated_at',
    maxBoost: 10,
    scoreMode: 'sum',
    boostMode: 'replace',
    minScore: 1,
    pause: !shouldUseNewSyntheticFilters
  });

  const newHandleApplyFilters = (filters: PageFilters) => {
    filters.status === 'all'
      ? removeQueryParams('status')
      : addQueryParams('status', filters.status);

    setStatus(filters.status as MultiFlowStatusEnum);
    setCurrentPage(1);
    handleToggleFilters();
    reexecuteQuery();
  };

  const syntheticData = (shouldUseNewSyntheticFilters ? synthetics : data?.multiflows?.data) || [];
  const syntheticTotal = (shouldUseNewSyntheticFilters ? total : data?.multiflows?.total) || 0;
  const isFetching = shouldUseNewSyntheticFilters ? isFetchingSynthetics : fetching;

  const appliedFilters = () => {
    return {
      status: status ?? 'all'
    };
  };

  const syntheticStatusOptions = {
    label: 'Status',
    options: [
      { value: 'all', label: 'All' },
      { value: MultiFlowStatusEnum.Off, label: 'Inactive' },
      { value: MultiFlowStatusEnum.Success, label: 'Success' },
      { value: MultiFlowStatusEnum.Pending, label: 'Pending' },
      { value: MultiFlowStatusEnum.Error, label: 'Error' }
    ]
  };

  return (
    <>
      <Breadcrumb
        items={[{ label: 'Monitoring' }, { link: `/synthetic`, label: 'Synthetic Center' }]}
      />

      <Title title="Synthetic Center">
        <CreateSyntheticButton />
      </Title>

      {shouldUseNewSyntheticFilters ? (
        <EventsFilters
          handleChangeSearch={handleChangeSearch}
          onApplyFilters={newHandleApplyFilters}
          onClearFilters={handleClearFilters}
          appliedFilters={appliedFilters}
          hideSeverity={true}
          hideOrigins={true}
          statusOptions={syntheticStatusOptions}
          includePeriod={false}
          includeCustomRange={false}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="space-between"
          mb={7.5}
          gridGap={theme.spacing(1.5)}
          {...{
            ref: searchElementRef
          }}>
          <TextField
            name="search"
            label="Search"
            variant="outlined"
            onChange={handleChangeSearch}
            placeholder="Try to search by name"
            InputProps={{
              endAdornment: <Search />,
              className: globalClasses.input
            }}
            InputLabelProps={{
              shrink: true,
              color: 'secondary'
            }}
            fullWidth
          />

          <Button
            className={classes.filter}
            variant="outlined"
            color="primary"
            onClick={handleToggleFilters}>
            <FilterList />
          </Button>
        </Box>
      )}

      {!shouldUseNewSyntheticFilters && isFiltersOpen ? (
        <Filters
          anchorEl={searchElementRef?.current}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          handleClose={handleCloseFilters}
        />
      ) : null}

      {syntheticData?.length ? <Divider /> : null}

      <DataTable
        columns={COLUMNS}
        data={syntheticData}
        noDataComponent="No results found"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeSize}
        paginationComponentOptions={{
          rowsPerPageText: 'Items per page:',
          rangeSeparatorText: 'of',
          noRowsPerPage: false
        }}
        paginationRowsPerPageOptions={PER_PAGE}
        paginationPerPage={perPage}
        paginationServer
        paginationTotalRows={syntheticTotal}
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={isFetching}
        highlightOnHover
        pagination
        striped
        pointerOnHover={true}
        onRowClicked={(mf, e) => {
          if (e.ctrlKey) {
            return window.open(`/synthetic/${mf.uid}`, '_blank');
          }
          history.push(`/synthetic/${mf.uid}`);
        }}
      />
    </>
  );
}
