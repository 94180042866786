import { Box, Divider, Typography } from '@material-ui/core';

import { useRoles } from 'hooks/queriesGraphQL/useRoles';
import { Input } from 'views/ServicesHub/components/Input';
import { Select } from 'views/ServicesHub/components/Select';

import { useStyles } from './styles';

interface EditProfileFormProps {
  control: any;
  register: any;
  member: any;
}

export const EditProfileForm = ({ control, register, member }: EditProfileFormProps) => {
  const classes = useStyles();

  const roles = useRoles({});

  const roleOptions = roles.data.map(role => {
    return {
      label: role.name,
      value: String(role.id)
    };
  });

  return (
    <Box>
      <Box>
        <Input
          label="Name"
          name="name"
          disabled
          className={classes.input}
          value={member?.user?.displayName}
        />
        <Input
          label="Email"
          name="email"
          disabled
          inputRef={register}
          value={member?.user?.email}
        />
      </Box>
      <Divider className={classes.divider} />
      <Box mt={2}>
        <Typography variant="h6">Permissions</Typography>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={1}>
            <Typography variant="body1">Team</Typography>
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.roleContainer} padding={1}>
              <Typography variant="body1" className={classes.roleText}>
                Member Role
              </Typography>
            </Box>
            <Select
              options={roleOptions}
              name="roleId"
              label="Role"
              required={true}
              control={control}
              onClick={e => e.stopPropagation()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
