import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>(({ palette, spacing, typography }: Theme) => ({
  title: {
    marginBottom: spacing(1)
  },
  body: {
    color: palette.grey[800],
    fontSize: typography.pxToRem(16)
  },
  tabs: {
    marginTop: spacing(3.5)
  },
  tabsFlexContainer: {
    borderBottom: '2px solid #d5d5d5'
  },
  tab: {
    fontSize: '14px'
  }
}));

export default useStyles;
