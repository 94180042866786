import { useState } from 'react';

import dayjs from 'dayjs';
import { UseCreateDeploy } from 'graphqlQueries/useTimelineDeploy';
import { useDispatch } from 'react-redux';
import { DeployTimeline } from 'types/external/DeployTimeline';
import { useMutation } from 'urql';

import useUser from 'hooks/queriesRest/useUser';
import actions from 'redux/actions';
import Dialog from 'views/Products/View/Availability/View/TopSection/Deploy/Dialog';

import { ApplicationData } from './types';

export const RegisterDeploy = ({
  application,
  applicationUid,
  handleCloseDeployDialog,
  open
}: {
  application: ApplicationData;
  applicationUid: string;
  handleCloseDeployDialog: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();

  const { data: user } = useUser();
  const orgUid = user?.logged?.org?.uid;

  const [deployType, setDeployType] = useState('deploy');
  const [version, setVersion] = useState(`${dayjs().format('YYYYMMDDHHmmss')}`);

  const [, createDeployTimeline] = useMutation<{
    createDeploy: DeployTimeline;
  }>(UseCreateDeploy);

  const handleSaveDeploy = async (currentDate: Date, description: string) => {
    handleCloseDeployDialog();

    const createDeploy = await createDeployTimeline({
      data: {
        createdAt: currentDate,
        description: description,
        version: version,
        type: deployType,
        applicationUid: applicationUid
      }
    });

    if (createDeploy.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: 'Error on create a Deploy on timeline' }
      });
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Deploy successfully insert in application timeline'
    });
  };

  return (
    <Dialog
      handleClose={handleCloseDeployDialog}
      handleSave={handleSaveDeploy}
      open={open}
      application={application}
      version={version}
      deployType={deployType}
      setDeployType={setDeployType}
      setVersion={setVersion}
      deployV2={true}
      applicationUid={applicationUid}
      orgUid={orgUid}
    />
  );
};
