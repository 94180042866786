import { useCallback, useMemo } from 'react';

import { useQuery, useMutation } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

export type Frequency = {
  id?: number;
  scheduledEventId?: number;
  dayOfWeek: number;
  enabled: boolean;
  start: Date;
  finish: Date;
};

export type ScheduledEvent = {
  id?: number;
  type: string;
  recurrent: boolean;
  serviceId: number;
  applicationId: number;
  uid: string;
  entity: string;
  begin: string | Date;
  end: Date | null;
  frequencies: [Frequency] | [];
};

const GetScheduledEvent = `#graphql
  query(
  $type: String!
  $serviceId: Float!
  $applicationId: Float!
  $uid: String!
) {
  getScheduledEventByService(
    type: $type,
    serviceId: $serviceId, 
    applicationId: $applicationId
    uid: $uid
  ) {
    id
    orgUid
    type
    recurrent
    serviceId
    applicationId
    uid
    begin
    end
    frequencies {
      id
      scheduledEventId
      dayOfWeek
      enabled
      start
      finish
    } 
  }
}
`;

const CreateScheduledEvent = `#graphql
  mutation(
    $type: String!
    $recurrent: Boolean!
    $serviceId: Int!
    $applicationId: Int!
    $uid: String!
    $entity: String!
    $begin: String!
    $frequencies: [FrequencyScheduledEventInput!]!
    $productId: Int!
  ) {
    createScheduledEvent(
      type: $type,
      recurrent: $recurrent,
      serviceId: $serviceId,
      applicationId: $applicationId,
      uid: $uid,
      entity: $entity,
      begin: $begin,
      frequencies: $frequencies
      productId: $productId
    ) {
      id
      orgUid
      type
      recurrent
      serviceId
      applicationId
      uid
      begin
      end
      frequencies {
        id
        scheduledEventId
        dayOfWeek
        enabled
        start
        finish
      }
    }
  }
`;

const UpdateScheduledEvent = `#graphql
  mutation(
    $id: Float!
    $type: String!
    $recurrent: Boolean!
    $serviceId: Int!
    $applicationId: Int!
    $uid: String!
    $entity: String!
    $begin: String!
    $frequencies: [FrequencyScheduledEventInput!]!
    $productId: Int!
  ) {
    updateScheduledEvent(
      id: $id,
      type: $type,
      recurrent: $recurrent,
      serviceId: $serviceId,
      applicationId: $applicationId,
      uid: $uid,
      entity: $entity,
      begin: $begin,
      frequencies: $frequencies
      productId: $productId
    ) {
      id
      orgUid
      type
      recurrent
      serviceId
      applicationId
      uid
      begin
      end
      frequencies {
        id
        scheduledEventId
        dayOfWeek
        enabled
        start
        finish
      }
    }
  }
`;

const CreateScheduledEventForResource = `#graphql
  mutation(
    $type: String!
    $recurrent: Boolean!
    $serviceId: Int!
    $applicationId: Int!
    $uid: String!
    $entity: String!
    $begin: String!
    $frequencies: [FrequencyScheduledEventInput!]!
  ) {
    createScheduledEventForResource(
      type: $type,
      recurrent: $recurrent,
      serviceId: $serviceId,
      applicationId: $applicationId,
      uid: $uid,
      entity: $entity,
      begin: $begin,
      frequencies: $frequencies
    ) {
      id
      orgUid
      type
      recurrent
      serviceId
      applicationId
      uid
      begin
      end
      frequencies {
        id
        scheduledEventId
        dayOfWeek
        enabled
        start
        finish
      }
    }
  }
`;

const UpdateScheduledEventForResource = `#graphql
  mutation(
    $id: Float!
    $type: String!
    $recurrent: Boolean!
    $serviceId: Int!
    $applicationId: Int!
    $uid: String!
    $entity: String!
    $begin: String!
    $frequencies: [FrequencyScheduledEventInput!]!
  ) {
    updateScheduledEvent(
      id: $id,
      type: $type,
      recurrent: $recurrent,
      serviceId: $serviceId,
      applicationId: $applicationId,
      uid: $uid,
      entity: $entity,
      begin: $begin,
      frequencies: $frequencies
    ) {
      id
      orgUid
      type
      recurrent
      serviceId
      applicationId
      uid
      begin
      end
      frequencies {
        id
        scheduledEventId
        dayOfWeek
        enabled
        start
        finish
      }
    }
  }
`;

const useScheduledEvent = ({
  serviceId,
  applicationId,
  uid,
  type,
  productId
}: {
  serviceId: number;
  applicationId: number;
  uid: string;
  type: string;
  productId: number;
}) => {
  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const createScheduledEventQuery = shouldUseNewApplications
    ? CreateScheduledEventForResource
    : CreateScheduledEvent;

  const updateScheduledEventQuery = shouldUseNewApplications
    ? UpdateScheduledEventForResource
    : UpdateScheduledEvent;

  const [{ fetching: fetchingScheduledEvent, data }, reexecuteQuery] = useQuery({
    query: GetScheduledEvent,
    pause: shouldUseNewApplications ? uid === '' : !serviceId,
    variables: { serviceId, applicationId, uid, type }
  });

  const scheduledEvent = data?.getScheduledEventByService ?? null;

  const [{ fetching: fetchingCreate }, createScheduledEvent] = useMutation(
    createScheduledEventQuery
  );

  const [{ fetching: fetchingUpdate }, updateScheduledEvent] = useMutation(
    updateScheduledEventQuery
  );

  const onUpdate = useCallback(
    (data: ScheduledEvent, onClose: () => void) => {
      const mappedData = {
        id: data.id,
        serviceId: data.serviceId,
        applicationId: data.applicationId,
        uid: data.uid,
        entity: data.entity,
        type: data.type,
        recurrent: data.recurrent,
        begin: data.begin,
        end: data.end,
        frequencies: data.frequencies,
        productId
      };

      updateScheduledEvent({
        ...mappedData
      }).then(result => {
        if (result.error?.graphQLErrors) return;

        onClose();

        reexecuteQuery();
      });
    },
    [productId, updateScheduledEvent, reexecuteQuery]
  );

  const onCreate = useCallback(
    (data: ScheduledEvent, onClose: () => void) => {
      const mappedData = {
        serviceId: data.serviceId,
        applicationId: data.applicationId,
        uid: data.uid,
        entity: data.entity,
        type: data.type,
        recurrent: data.recurrent,
        begin: data.begin,
        end: data.end,
        frequencies: data.frequencies,
        productId
      };

      createScheduledEvent({
        ...mappedData
      }).then(result => {
        if (result.error?.graphQLErrors) return;

        onClose();

        reexecuteQuery();
      });
    },
    [createScheduledEvent, productId, reexecuteQuery]
  );

  return useMemo(
    () => ({
      fetchingScheduledEvent,
      scheduledEvent,
      fetchingCreate,
      fetchingUpdate,
      onCreate,
      onUpdate,
      reexecuteQuery
    }),
    [
      fetchingScheduledEvent,
      scheduledEvent,
      fetchingCreate,
      fetchingUpdate,
      onCreate,
      onUpdate,
      reexecuteQuery
    ]
  );
};

export default useScheduledEvent;
