import { ServiceBusFormData } from 'views/ServicesHub/forms/monitoring/queue/ServiceBus';

import { MonitoringBaseForm } from '../../../forms/monitoring/commom/Base';
import { monitoringAdapter } from '../monitoring';

export function serviceBusAdapter(data: MonitoringBaseForm & ServiceBusFormData) {
  // The domain is not used in this agent, on Resource.

  return {
    ...monitoringAdapter({
      ...data,
      method: 'servicebus'
    }),
    domain: data.connectionString,
    domainSettings: {
      connectionString: data.connectionString,
      type: data.monitoringType,
      queue: data.queueName,
      topic: data.topicName,
      subscription: data.subscriptionName
    }
  };
}
