import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    marginBottom: theme.typography.pxToRem(8)
  },
  description: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(20),
    lineHeight: '24px'
  },
  divider: {
    border: `${theme.palette.grey[300]} 1px solid`,
    margin: theme.spacing(3, 0, 7.5)
  },
  infoSectionTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  }
}));
