import { postBodyTypeValues } from 'constants/postBody';

const keyValuesToObject = keyValues => {
  const filteredKeyValues = keyValues.filter(item => Boolean(item.key));
  const asObject = filteredKeyValues.reduce((final, item) => {
    return { ...final, [item.key]: item.value };
  }, {});

  return filteredKeyValues.length ? asObject : null;
};

const mapFull = ({ formData }) => {
  const postBodyType =
    formData.post_body_type !== 'raw' ? formData.post_body_type : formData.post_body_raw_type;

  const postBodyRaw = formData.post_body_raw;

  const postBodyXFormUrlEncoded = keyValuesToObject(formData.post_body_urlencoded || []);

  const postBodyForSelectedType =
    postBodyType === postBodyTypeValues.urlencoded ? postBodyXFormUrlEncoded : postBodyRaw;

  return {
    healthcheckUrl: formData.healthcheckUrl.trim(),
    method: formData.method,
    headers: keyValuesToObject(formData.headers),
    validationString: formData.validationString,
    timeout: Number(formData.timeout),
    check_interval: Number(formData.check_interval),
    failuresToIncident: Number(formData.failuresToIncident),
    domain_settings: JSON.stringify({ tls_renegotiation: Number(formData.tls_renegotiation) }),
    post_body: postBodyForSelectedType,
    post_body_type: postBodyType,
    skip_ssl_validation: formData.skip_ssl_validation,
    openIncidentWithSeverity: formData?.openIncidentWithSeverity
  };
};

export default mapFull;
