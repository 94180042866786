import { isExternalUrl } from './isExternalUrl';

export function composeNavigationLink(url: string) {
  if (isExternalUrl(url)) {
    return {
      pathname: url
    };
  }

  return url;
}
