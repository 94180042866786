import { Chip } from '@material-ui/core';

export function LockChip() {
  return (
    <Chip
      style={{
        cursor: 'pointer',
        marginLeft: 'auto'
      }}
      variant="outlined"
      color="primary"
      label="Locked"
      size="small"
      title="See our pricing page"
      clickable
    />
  );
}
