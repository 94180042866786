export type User = {
  id: number;
  name: string;
  role: string;
  email: string;
  status: string;
};

export function newUserAdapter(data: { users: User[] }, defaultRole: number) {
  const { users } = data;

  return users.map(user => ({
    email: user.email,
    displayName: user.name,
    roleId: user.role ? user.role : defaultRole
  }));
}
