import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { getAppStatusUniqueResource } from '../../../List/Datagrid/checkAppAvailability';

import MonitoringStatusBase from './MonitoringStatusBase';

const MonitoringStatusV0 = ({
  application,
  applicationStatusUniqueResource
}: {
  application: any;
  applicationStatusUniqueResource: any;
}) => {
  const useResourceFlag = useFeatureFlagUnleash('useResource', { queryString: true });

  const shouldUseResource = Boolean(useResourceFlag && application?.useResource);

  const applicationStatus = getAppStatusUniqueResource(
    shouldUseResource
      ? applicationStatusUniqueResource?.status
      : applicationStatusUniqueResource?.monitoringStatus
  );

  return <MonitoringStatusBase color={applicationStatus?.color} label={applicationStatus?.label} />;
};

export default MonitoringStatusV0;
