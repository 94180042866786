import { Chip } from '@material-ui/core';
import { StepStatusEnum } from 'types/external/MultiFlow';

import { useStyles } from './styles';

export type StepStatusChipProps = {
  status: StepStatusEnum;
};

function getLabelByStatus(status: StepStatusEnum) {
  const statusLabelMap = {
    [StepStatusEnum.Off]: 'Inactive',
    [StepStatusEnum.Success]: 'Success',
    [StepStatusEnum.Error]: 'Error',
    [StepStatusEnum.Skipped]: 'Skipped'
  };

  return statusLabelMap[status];
}

export function StepStatusChip({ status }: StepStatusChipProps) {
  const classes = useStyles({ status });

  return <Chip className={classes.chip} label={getLabelByStatus(status)} />;
}
