import actions from '../../actions';

const reset = (state, action) => {
  if (actions.RESET_FORM_USER === action.type) {
    return {
      ...state,
      users_new: {
        teams: [],
        roles: [],
        data: [],
        isValid: false,
        errors: {}
      }
    };
  }
  return state;
};

export default reset;
