import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TableColumn } from 'react-data-table-component';

export type Row = {
  user: {
    id: number;
    name: string;
    email: string;
  };
  name: string;
  isActive: boolean;
};

type Permission = {
  new: boolean;
  update: boolean;
  remove: boolean;
};

export type GridParams = {
  fetchQuery: string;
  deleteQuery: string;
  argsDelete: any[];
  deleteParams: any;
  entity: string;
  cols: TableColumn<any>[];
  title: string;
  mapEntity: (row: any) => any;
  permissions: Permission;
  query: string;
  sortProp: string;
  params: {
    teamsId: number;
  };
  resultQueryTop: any;
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  sort: string;
  setSort: (sort: string) => void;
  resultQuery: any;
  refetchResult: () => void;
  editPath: string;
  setIsLoading: (isLoading: boolean) => void;
};

export function getParams(args: any[], payload: any) {
  let params = {};
  for (let i: number = 0; i < args.length; i++) {
    const arg = args[i];

    params = { [arg]: payload[`${arg}`], ...params };
  }

  return params;
}

export const getStyles = (title: string) => {
  return {
    paginationComponentOptions: {
      rowsPerPageText: 'Items per page:',
      rangeSeparatorText: 'de',
      noRowsPerPage: false
    },

    dataGridTheme: {
      tableWrapper: {
        style: {
          display: 'block'
        }
      },
      title: {
        height: title ? 56 : 0
      }
    }
  };
};

export const deleteIcon = (row: Row, permissions: Permission, setDeleteModalState: any) => {
  const button = (
    <IconButton disabled={!permissions?.remove}>
      <DeleteIcon />
    </IconButton>
  );

  if (!permissions?.remove) {
    return <div title="You are not allowed to remove">{button}</div>;
  }

  return (
    <IconButton
      title="Remove"
      data-user={row?.user?.email}
      data-name={row?.name}
      // @ts-ignore: Unreachable code error//ts-ignore-next-line
      onClick={() => setDeleteModalState({ isOpen: true, id: row })}>
      <DeleteIcon />
    </IconButton>
  );
};

export function getColumns({
  cols,
  mapEntity,
  permissions,
  editHandler,
  deleteIcon,
  setDeleteModalState
}: {
  cols: GridParams['cols'];
  mapEntity: GridParams['mapEntity'];
  permissions: GridParams['permissions'];
  editHandler: ({ id }: { id: number }) => void;
  deleteIcon: any;
  setDeleteModalState: any;
}) {
  return [
    ...cols,
    {
      grow: 0,
      center: true,
      compact: true,
      cell: (row: Row) => (
        // @ts-ignore: Unreachable code error//ts-ignore-next-line
        <IconButton
          title="Edit"
          data-user={row?.user?.email}
          data-name={row?.name}
          data-isactive={row?.isActive}
          onClick={event => {
            event.preventDefault();
            const dto = mapEntity ? mapEntity(row) : row;
            editHandler(dto);
          }}
          variant="outlined"
          disabled={permissions && !permissions.update}>
          <EditIcon />
        </IconButton>
      )
    },
    {
      grow: 0,
      center: true,
      compact: true,
      cell: (row: Row) => deleteIcon(row, permissions, setDeleteModalState)
    }
  ];
}
