import { getStepGroupsList } from 'graphqlQueries/getStepGroupsList';
import { useQuery as useQueryUrql } from 'urql';

const useListStepGroups = ({ teamId }: { teamId: number }) => {
  const [{ data: stepGroupsListByIdFromTeams, fetching }, reexecuteQuery] = useQueryUrql({
    query: getStepGroupsList,
    variables: { teamIdFromTeams: Number(teamId) }
  });

  const stepsGroups = stepGroupsListByIdFromTeams?.listStepGroupsByTeamIdInMSTeams;
  const isFetching = fetching;

  return { stepsGroups, isFetching, reexecuteQuery };
};

export default useListStepGroups;
