import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > div': {
      overflowX: 'hidden',
      [theme.breakpoints.only('lg')]: {
        overflowX: 'auto'
      }
    }
  },
  cellTitle: {
    fontSize: theme.typography.pxToRem(12)
  }
}));
