import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Backdrop,
  CircularProgress,
  Box,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

interface DeleteEntityProps {
  entityName: string;
  open: boolean;
  children?: React.ReactNode;
  handleClose: () => void;
  disabled: boolean;
  isLoading: boolean;
  handleRemove: () => void;
}

export const DeleteEntity = ({
  open,
  entityName,
  handleClose,
  children,
  isLoading,
  disabled,
  handleRemove
}: DeleteEntityProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.root }} onClose={handleClose} fullWidth>
      <DialogTitle className={classes.dialogTitle}>
        <Typography variant="h4">Delete {entityName}</Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box>
          <Typography variant="h6" className={classes.text}>
            Do you really want to delete this {entityName}?
          </Typography>
        </Box>
        {children}
        <Box paddingTop={3} display="flex" justifyContent="flex-end" className={classes.buttons}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            style={{
              marginLeft: '1rem',
              whiteSpace: 'nowrap'
            }}
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={handleRemove}>
            Delete {entityName}
          </Button>
        </Box>
      </DialogContent>

      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" size={90} thickness={5} />
      </Backdrop>
    </Dialog>
  );
};
