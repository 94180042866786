import { ElvenAgentCloud, ElvenAgentRegion } from 'constants/ElvenAgent';

import { Box, Checkbox, FormControlLabel, FormGroup, Icon, Tooltip } from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import useQueryParams from 'helpers/useQueryParams';
import { useStyles } from 'views/Monitoring/View/styles';
import { KeyValueField } from 'views/ServicesHub/components/KeyValueField';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type GetFormData = {
  url: string;
  skipSslValidation: boolean;
  tls?: number;
  tlsRenegotiation: number;
  headers?: { key: string; value: string }[];
  elvenAgentRegion?: string | undefined;
  elvenAgentCloud?: string | undefined;
  validationString: string;
};

export function GetForm({ form }: FormProps) {
  const classes = useStyles();
  const queryParams = useQueryParams();
  const isExternal = queryParams?.get('external');

  const { control, watch, errors, setValue, register } = form;

  const { fields: fieldsHeaders, append: addHeaders, remove: removeHeaders } = useFieldArray({
    control,
    name: 'headers'
  });

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {isExternal && (
        <Box display="flex" gridGap="1rem">
          <Select
            options={ElvenAgentCloud}
            control={control}
            name="elvenAgentCloud"
            label="Checkpoint Cloud"
            required={false}
          />
          <Select
            options={ElvenAgentRegion}
            control={control}
            name="elvenAgentRegion"
            label="Checkpoint"
            required={false}
          />
        </Box>
      )}
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'string',
            label: 'Healthcheck URL',
            name: 'url',
            require: false
          }}
          InputComponent={Input}
        />
      </Box>

      <Box display="flex" gridGap="1rem">
        <Controller
          control={control}
          name="skipSslValidation"
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="Skip SSL Validation"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                        required={false}
                      />
                    }
                  />
                  <Tooltip
                    title={
                      '(TLS) By default, every SSL connection connector makes is verified to be secure. This option allows connector to proceed and operate even for server connections otherwise considered insecure.'
                    }>
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
        <Select
          options={[
            { value: '0', label: 'Never' },
            { value: '1', label: 'One Time' },
            { value: '2', label: 'Always' }
          ]}
          control={control}
          name="tlsRenegotiation"
          label="TLS Renegotiation"
          defaultValue="0"
          required={false}
        />
      </Box>

      <KeyValueField
        form={form}
        name="headers"
        fields={fieldsHeaders}
        handleAdd={addHeaders}
        handleRemove={removeHeaders}
        label="Header"
        required={false}
      />

      <Box display="flex" gridGap="1rem">
        <Input
          label="Validation String"
          name="validationString"
          inputRef={register}
          required={false}
        />
      </Box>
    </Box>
  );
}
