import { z } from 'zod';

const ssoIntegrationSchema = z.object({
  signInURL: z.string().url('Should be a valid URL. e.g: https://acme.inc/saml'),
  signingCertificate: z
    .string()
    .min(1, 'Select the X509 Certificate file')
    .regex(/-{5}BEGIN CERTIFICATE-{5}([^-]+)-{5}END CERTIFICATE-{5}/, 'Invalid X509 Certificate')
    .transform(data => data.replaceAll('\\n', '\n'))
});

const googleCredentialsSchema = z.object({
  type: z.string(),
  project_id: z.string(),
  private_key_id: z.string(),
  private_key: z.string(),
  client_email: z.string().email(),
  client_id: z.string(),
  auth_uri: z.string(),
  token_uri: z.string(),
  auth_provider_x509_cert_url: z.string(),
  client_x509_cert_url: z.string(),
  universe_domain: z.string()
});

export const googleWorkspaceIntegrationFormSchema = ssoIntegrationSchema.and(
  z.object({
    // z.literal('') is required because email is not required in this schema
    // see: https://github.com/colinhacks/zod/issues/310#issuecomment-794533682
    adminEmail: z
      .string()
      .email('Should be valid e-mail')
      .or(z.literal('')),
    groupEmail: z
      .string()
      .email('Should be valid e-mail')
      .or(z.literal('')),
    credentials: z.literal('').or(
      z
        .string()
        .superRefine((data, ctx) => {
          try {
            const parsedData = JSON.parse(data);
            googleCredentialsSchema.parse(parsedData);
          } catch (e) {
            ctx.addIssue({ code: 'custom', message: 'Invalid Credentials file' });
            return z.NEVER;
          }
        })
        .transform(data => JSON.stringify(JSON.parse(data)).replaceAll('\n', '\\n'))
    )
  })
);

export const microsoftWorkspaceIntegrationFormSchema = ssoIntegrationSchema.and(
  z.object({
    tenantId: z
      .string()
      .uuid('Invalid Tenant ID')
      .or(z.literal('')),
    groupId: z
      .string()
      .uuid('Invalid Group ID')
      .or(z.literal('')),
    clientId: z
      .string()
      .uuid('Invalid Client ID')
      .or(z.literal('')),
    clientSecret: z.string().or(z.literal(''))
  })
);

export type GoogleWorkspaceIntegrationFormType = z.infer<
  typeof googleWorkspaceIntegrationFormSchema
>;
export type MicrosoftWorkspaceIntegrationFormType = z.infer<
  typeof microsoftWorkspaceIntegrationFormSchema
>;
