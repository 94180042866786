import { ZodType } from 'zod';

import UseFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { adapters } from 'views/ServicesHub/adapters/new';
import { apiSchema } from 'views/ServicesHub/schema/monitoring/api/api';
import { postgresSchema } from 'views/ServicesHub/schema/monitoring/database/postgres';
import { genericMonitoringSchema } from 'views/ServicesHub/schema/monitoring/genericMonitoringSchema';
import { getSchema } from 'views/ServicesHub/schema/monitoring/http/Get';
import { postSchema } from 'views/ServicesHub/schema/monitoring/http/Post';
import { kafkaSchema } from 'views/ServicesHub/schema/monitoring/queue/kafka';

export function getSchemaByType(type: keyof typeof adapters) {
  const hideTimeoutAndInterval = UseFeatureFlagUnleash('hideTimeoutAndInterval', {
    queryString: true
  });

  const schemas: Partial<{ [key in keyof typeof adapters]: ZodType }> = {
    get: getSchema,
    post: postSchema,
    postgres: postgresSchema,
    kafka: kafkaSchema,
    api: hideTimeoutAndInterval ? apiSchema : genericMonitoringSchema
  };

  return schemas?.[type] ?? genericMonitoringSchema;
}
