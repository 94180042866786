import { Features, FeatureWithSubfeatures } from './types';

type CheckFeaturePlanOptions = {
  features: Features | undefined;
  feature: string;
};

export function checkFeature({ feature: featurePath, features }: CheckFeaturePlanOptions): boolean {
  if (!featurePath || !features) return false;

  const [feature, subfeature] = featurePath.split('.');

  const subfeatures = features?.[feature] as FeatureWithSubfeatures;

  if (!features || !subfeatures) return false;

  const planHasFeature = feature in features;
  if (!planHasFeature) return false;

  if (!subfeature) return features[feature].enabled as boolean;

  const planHasSubfeature = subfeature in subfeatures;
  if (!planHasSubfeature) return false;

  return subfeatures[subfeature].enabled;
}
