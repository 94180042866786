import { useMutation } from 'urql';

const OpenTicketMutation = `#graphql
  mutation($data: OpenTicketInput!) {
    openTicket(data: $data) {
      message
      success
    }
  }`;

type OpenTicketResponse = {
  openTicket: { message: string; success: boolean };
};

type OpenTicketVariables = {
  data: {
    incidentId: number;
    description: string;
    severity: string;
    integrationUid: string;
  };
};

export const useOpenTicket = () => {
  const [{ data, fetching }, openTicket] = useMutation<OpenTicketResponse, OpenTicketVariables>(
    OpenTicketMutation
  );

  const success = data?.openTicket.success;

  return { openTicket, fetching, success };
};
