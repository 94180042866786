import { urqlClient } from 'App';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { UserProviderSettings } from 'types/external/UserProvider';

import actions from 'redux/actions';

const GetUserProvidersSettingsQuery = `#graphql
   query($type: String!) {
    getUserProvidersSettingsByType(type: $type) {
        id
        active
        type
        provider {
          id
          editable
          config {
            value
            verified
            deviceName
            modelName
          }
          provider
        }
    }
  }
`;

type GetUserProvidersSettings = {
  getUserProvidersSettingsByType: UserProviderSettings[];
};

type UseGetUserProvidersSettingsArgs = { type: 'incident' | 'alert' | 'on-call' };

export function useGetUserProvidersSettings({ type }: UseGetUserProvidersSettingsArgs) {
  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useQuery(['userProvidersSettings', type], {
    queryFn: () =>
      urqlClient
        .query<GetUserProvidersSettings>(GetUserProvidersSettingsQuery, {
          type
        })
        .toPromise()
        .then(({ data }) => data?.getUserProvidersSettingsByType),
    onError: () => {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: {
          message: 'Unexpected error when trying to fetch notification rules'
        }
      });
    }
  });

  return {
    data,
    fetching: isLoading,
    reexecuteQuery: refetch
  };
}
