import React from 'react';

import validate from 'validate.js';

const validationSchema = {
  roleId: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
    numericality: {
      onlyInteger: true,
      greaterThan: 0
    }
  }
};

const initialFormState = {
  isValid: false,
  values: {},
  errors: {}
};

const useFormState = ({ teamId, memberId }) => {
  const [formState, dispatch] = React.useReducer((state, action) => {
    if (action.type === 'initialFetch') {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }

    if (action.type === 'change') {
      const newValues = {
        ...state.values,
        ...action.payload
      };

      const errors = validate(newValues, validationSchema);

      return {
        ...state,
        values: newValues,
        errors: errors || {},
        isValid: !errors
      };
    }

    throw new Error();
  }, initialFormState);

  return [formState, dispatch];
};

export default useFormState;
