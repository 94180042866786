import { ComponentType } from 'react';

import { SvgIconProps, Typography, useTheme } from '@material-ui/core';

const MonitoringStatusBase = ({
  color,
  label,
  icon,
  iconOnly
}: {
  color?: string;
  label?: string;
  icon?: ComponentType<SvgIconProps>;
  iconOnly?: boolean;
}) => {
  const theme = useTheme();

  if (!color || !label) {
    return null;
  }

  const Icon = icon;

  const renderedIcon = Icon ? (
    <Icon titleAccess={label} fill={color} style={{ marginRight: theme.spacing() }} />
  ) : null;

  if (Icon && iconOnly) {
    return renderedIcon;
  }

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        style={{
          display: 'flex',
          alignItems: 'center',
          color
        }}
        title={label}>
        {Icon ? (
          renderedIcon
        ) : (
          <span
            style={{
              backgroundColor: color,
              display: 'block',
              borderRadius: 20,
              border: '1px solid #000000',
              width: 16,
              height: 16,
              marginRight: theme.spacing()
            }}
            title={label}
          />
        )}
        {iconOnly ? '' : ` ${label}`}
      </Typography>{' '}
    </>
  );
};

export default MonitoringStatusBase;
