import { PostBodyTypes, PostBodyValues } from 'constants/postBody';

import { MultiFlowOrigin, MultiFlowStepType } from 'types/external/MultiFlow';

import keyValuesToObject from 'helpers/keyValuesToObject';

export type Assertion = {
  id?: number;
  source: string;
  order?: number;
  comparison: string;
  targetValue: string;
  property: string;
};

export type MultiFlowFormData = {
  id: string;
  uid: string;
  name: string;
  intervalCheck: string;
  environmentId: string;
  steps: {
    id: string;
    name: string;
    healthcheckUrl: string;
    method: string;
    tlsRenegotiation: string;
    skipSslValidation: string;
    timeout: string;
    postBodyType: string;
    postBody: string;
    postBodyUrlEnconded: {
      key: string;
      value: string;
    }[];
    rawType: string;
    headers: {
      key: string;
      value: string;
    }[];
    validationString: string;
    assertions?: Assertion[] | {}[];
  }[];
};

type CreateMultiFlowPayload = {
  name: string;
  uid: string;
  interval: number;
  environmentId: number;
  origin: string;
  activated: boolean;
  steps: {
    step: number;
    name: string;
    type: string;
    method: string;
    domain: string;
    timeout: number;
    domainSettings: Record<string, any>;
    assertions?: Assertion[] | {}[];
  }[];
};

const DEFAULT_HEADER_VALUE = [
  {
    key: '',
    value: ''
  }
];

export function multiFlowAdapter(data: MultiFlowFormData): CreateMultiFlowPayload {
  const interval = Number(data.intervalCheck);
  const environmentId = Number(data.environmentId);

  return {
    name: data.name,
    uid: '',
    interval,
    environmentId,
    origin: MultiFlowOrigin.Agent,
    activated: false,
    steps: data.steps.map((step, index) => {
      const headers = keyValuesToObject(step.headers || DEFAULT_HEADER_VALUE);
      const tlsRenegotiation = Number(step.tlsRenegotiation);
      const timeout = Number(step.timeout);

      const postBodyType =
        step.postBodyType === PostBodyValues.urlencoded ? PostBodyValues.urlencoded : step.rawType;

      const postBody = (() => {
        if (step.method !== 'POST') return {};

        return step.postBodyType === PostBodyTypes.raw
          ? btoa(step.postBody)
          : JSON.stringify(keyValuesToObject(step.postBodyUrlEnconded));
      })();

      const postBodySettings =
        step.method === 'POST'
          ? {
              postBodyType,
              postBody
            }
          : {};

      const assertions: Assertion[] = [];

      step.assertions?.forEach(({ comparison, source, property, targetValue }: any, index) => {
        if (comparison && source) {
          assertions.push({
            comparison,
            source,
            order: index,
            property: property || '',
            targetValue: targetValue || ''
          });
        }
      });

      return {
        domain: step.healthcheckUrl,
        type: MultiFlowStepType.HTTP,
        step: index,
        name: step.name,
        method: step.method,
        domainSettings: {
          tlsRenegotiation,
          validationString: step.validationString,
          skipSslValidation: step.skipSslValidation === 'true',
          ...(headers ? { headers } : {}),
          ...postBodySettings
        },
        timeout,
        assertions
      };
    })
  };
}
