import { useMemo } from 'react';

import { Box, Link as MuiLink, useTheme } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { InputWithAutocomplete, mapDataToOptions } from 'oldComponents/InputWithAutocomplete';
import { Typography } from 'oldComponents/Typography';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

import Button from 'componentsV4/Button';
import Input from 'componentsV4/Input';
import { buildElasticQuery } from 'helpers/buildElasticQuery';
import useQueryParams from 'helpers/useQueryParams';
import { useApplications } from 'hooks/queriesGraphQL/useApplications';
import useLeadTimeChanges from 'hooks/queriesGraphQL/useLeadTimeChanges';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import useStyles from './styles';

import { PlatformIntegration } from '.';

const GetProducts = `#graphql
  query {
    products(page: 1, per_page: 1000, sort: "name,ASC") {
      data {
        id
        name
      }
    }
  }
`;

const GetApplications = `#graphql
  query ($productId: Float!) {
    applications(
      page: 1
      per_page: 10000
      sort: "name,ASC"
      productId: $productId
    ) {
      data {
        id
        uid
        name
      }
    }
  }
`;

type LeadTimeIntegrationFormProps = {
  platformIntegration: PlatformIntegration;
};

export function LeadTimeIntegrationForm({ platformIntegration }: LeadTimeIntegrationFormProps) {
  const classes = useStyles();

  const theme = useTheme();

  const { register, control, handleSubmit, watch } = useForm();

  const queryParams = useQueryParams();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const applicationUidQuery = queryParams.get('applicationUid');
  const integrationIdQuery = queryParams.get('integrationId');
  const productIdQuery = queryParams.get('productId');
  const redirectTo = queryParams.get('redirectTo');

  const productId = watch('productId');
  const integrationId = watch('integrationId');

  const { getIntegrations, getRepositories, createAppAssociation } = useLeadTimeChanges();

  const [
    { fetching: isCreatingAppAssociation },
    createAppAssociationMutation
  ] = createAppAssociation;
  const [{ data: integrationsResponse, fetching: isFetchingIntegrations }] = getIntegrations();
  const [{ data: productsResponse, fetching: isFetchingProducts }] = useQuery({
    query: GetProducts
  });
  const [{ data: applicationsResponse, fetching: isFetchingApplications }] = useQuery({
    query: GetApplications,
    pause: shouldUseNewApplications || !productId,
    variables: {
      productId: Number(productId)
    }
  });

  const history = useHistory();

  const [{ fetching: isFetchingApplicationsV2, data: applicationsV2Response }] = useApplications({
    page: 1,
    perPage: 1000,
    pause: !shouldUseNewApplications,
    query: productId
      ? buildElasticQuery({
          search: '',
          status: '',
          product_id: productId
        })
      : buildElasticQuery({
          search: '',
          status: ''
        })
  });

  const integrations = useMemo(() => integrationsResponse?.integrations || [], [
    integrationsResponse?.integrations
  ]);

  const installationId = integrations.find(
    (integration: { id: number }) => integration.id === Number(integrationId)
  )?.data?.installationId;

  const clientKey = integrations.find(
    (integration: { id: number }) => integration.id === Number(integrationId)
  )?.data?.clientKey;

  const hasIntegration = integrations.length > 0;

  const handleRedirect = () => {
    if (redirectTo) {
      return history.push(redirectTo);
    }
    history.push('/organization?tab=integrations');
  };

  const [{ data: repositoriesResponse, fetching: isFetchingRepositories }] = getRepositories({
    pause: !integrationId,
    installationId: Number(installationId) || null,
    clientKey,
    type: platformIntegration
  });

  async function onSubmit(data: any) {
    const application = shouldUseNewApplications
      ? null
      : applicationsResponse?.applications?.data?.find(
          (item: { id: number; uid: string; name: string }) => item.uid === data.applicationUid
        );

    const repositoryData = (() => {
      if (platformIntegration === 'github')
        return {
          githubRepository: {
            repoId: Number(data.repoId)
          }
        };

      if (platformIntegration === 'bitbucket')
        return {
          bitbucketRepository: {
            uuid: data.repoId
          }
        };

      return {};
    })();

    const { error } = await createAppAssociationMutation({
      data: {
        targetBranch: data.targetBranch,
        applicationId: shouldUseNewApplications ? 0 : Number(application?.id),
        applicationUid: data.applicationUid,
        integrationId: Number(data.integrationId),
        type: platformIntegration,
        ...repositoryData
      }
    });

    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating app association', { error });
    }

    handleRedirect();
  }

  const integrationsOptions = useMemo(
    () =>
      mapDataToOptions(integrations, ({ id, accountName }) => ({
        label: accountName,
        value: id
      })),
    [integrations]
  );

  const productsOptions = useMemo(
    () =>
      mapDataToOptions<{
        id: string;
        name: string;
      }>(productsResponse?.products?.data ?? [], ({ id, name }) => ({
        label: name,
        value: id
      })),
    [productsResponse]
  );

  const applicationsOptions = useMemo(
    () =>
      mapDataToOptions<{
        uid: string;
        name: string;
      }>(applicationsResponse?.applications?.data ?? [], ({ uid, name }) => ({
        label: name,
        value: uid
      })),
    [applicationsResponse]
  );

  const applicationsV2Options = useMemo(
    () =>
      mapDataToOptions<{
        uid: string;
        name: string;
      }>(applicationsV2Response?.applicationsV2?.data ?? [], ({ uid, name }) => ({
        label: name,
        value: uid
      })),
    [applicationsV2Response]
  );

  const repositoryOptions = useMemo(() => {
    const data = (() => {
      if (platformIntegration === 'github') return repositoriesResponse?.githubRepositories ?? [];
      if (platformIntegration === 'bitbucket')
        return repositoriesResponse?.bitbucketRepositories ?? [];
    })();

    return mapDataToOptions<{
      id_repo: string;
      uuid: string;
      name: string;
    }>(data, ({ id_repo, name, uuid }) => {
      if (platformIntegration === 'github')
        return {
          label: name,
          value: id_repo
        };

      if (platformIntegration === 'bitbucket')
        return {
          label: name,
          value: uuid
        };

      return { label: '', value: '' };
    });
  }, [
    platformIntegration,
    repositoriesResponse?.bitbucketRepositories,
    repositoriesResponse?.githubRepositories
  ]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(5)}
      marginTop={5}
      onSubmit={handleSubmit(onSubmit)}>
      <InputWithAutocomplete
        control={control}
        name="productId"
        defaultValue={productIdQuery!}
        autocompleteProps={{
          // hide input when productId is setted from query string
          style: {
            display:
              Boolean(productIdQuery || applicationUidQuery) || !hasIntegration ? 'none' : 'inherit'
          },
          disabled: isFetchingProducts,
          options: productsOptions,
          loading: isFetchingProducts,
          renderInput: params => (
            <Input
              className={classes.input}
              inputProps={params.inputProps}
              inputRef={params.InputProps.ref}
              defaultLabel="Choose the product"
              subLabel={`Choose the product that contains the application you want to integrate
                  with ${platformIntegration}`}
              endAdornment={<Search color="action" />}
              required={shouldUseNewApplications ? false : !productIdQuery}
            />
          )
        }}
      />
      <InputWithAutocomplete
        control={control}
        name="applicationUid"
        defaultValue={applicationUidQuery!}
        autocompleteProps={{
          // hide input when productId is setted from query string
          style: {
            display: Boolean(applicationUidQuery) || !hasIntegration ? 'none' : 'inherit'
          },
          disabled: shouldUseNewApplications ? isFetchingApplicationsV2 : isFetchingApplications,
          loading: shouldUseNewApplications ? isFetchingApplicationsV2 : isFetchingApplications,
          options: shouldUseNewApplications ? applicationsV2Options : applicationsOptions,
          renderInput: params => (
            <Input
              className={classes.input}
              inputProps={params.inputProps}
              inputRef={params.InputProps.ref}
              defaultLabel="Choose the application"
              subLabel="Choose the application you want to integrate with"
              endAdornment={<Search color="action" />}
              required={!applicationUidQuery}
              disabled={shouldUseNewApplications ? false : !productId}
              tooltipMessage="Choose a product first"
              tooltipOnDisabled
            />
          )
        }}
      />
      <InputWithAutocomplete
        control={control}
        name="integrationId"
        defaultValue={integrationIdQuery!}
        autocompleteProps={{
          // hide input when productId is setted from query string
          style: {
            display: Boolean(integrationIdQuery) ? 'none' : 'inherit'
          },
          disabled: isFetchingIntegrations,
          loading: isFetchingIntegrations,
          options: integrationsOptions,
          renderInput: params => (
            <Input
              className={classes.input}
              inputProps={params.inputProps}
              inputRef={params.InputProps.ref}
              defaultLabel="Choose the integration"
              subLabel={
                <>
                  Choose the integration that contains the repository to be integrated or{' '}
                  <Link to="/organization?tab=integrations">
                    <MuiLink>make a new integration</MuiLink>
                  </Link>
                </>
              }
              disabled={integrationsOptions.length === 0}
              tooltipMessage="Make a integration first"
              tooltipOnDisabled
              endAdornment={<Search color="action" />}
              required={!integrationIdQuery}
            />
          )
        }}
      />
      <InputWithAutocomplete
        control={control}
        name="repoId"
        autocompleteProps={{
          style: {
            display: !hasIntegration ? 'none' : 'inherit'
          },
          options: repositoryOptions,
          disabled: isFetchingRepositories,
          loading: isFetchingRepositories,
          renderInput: params => (
            <Input
              className={classes.input}
              inputProps={params.inputProps}
              inputRef={params.InputProps.ref}
              defaultLabel="Choose the repository"
              subLabel={
                <>
                  You can choose an already integrated repository or{' '}
                  <Link to="/organization?tab=integrations">
                    <MuiLink>make a new integration</MuiLink>
                  </Link>
                </>
              }
              helperText="Don't see your repository? Be sure to add it to the list of allowed repositories in our app settings."
              endAdornment={<Search color="action" />}
              disabled={!integrationId}
              tooltipMessage="Choose a integration first"
              tooltipOnDisabled
              required
            />
          )
        }}
      />
      <Input
        style={{
          display: hasIntegration ? 'inherit' : 'none'
        }}
        className={classes.input}
        inputRef={register}
        name="targetBranch"
        defaultLabel="Choose your target branch"
        subLabel="The metric will be calculated from the source branch changes."
        endAdornment={<Search color="action" />}
        required
      />

      <Box marginTop={2}>
        <Typography variant="h5" style={{ fontSize: theme.typography.h5.fontSize }} gutterBottom>
          Deployment tracking
        </Typography>

        <Typography className={classes.body} variant="body1" gutterBottom>
          The reference for deployment detection is the cycle of a feature, which starts with the
          earliest commit of a pull request and ends when it is merged into the source branch.
        </Typography>
      </Box>

      <Box display="flex" gridGap={theme.spacing(3)} width="30%">
        <Button
          style={{ flex: 1 }}
          isLoading={isCreatingAppAssociation}
          disabled={!hasIntegration}
          text="Finish integration"
          color="primary"
          variant="contained"
          type="submit"
        />
        <Button
          style={{ flex: 0.5 }}
          text="Cancel"
          color="primary"
          variant="text"
          border={false}
          disabled={isCreatingAppAssociation}
          onClick={() => handleRedirect()}
        />
      </Box>
    </Box>
  );
}
