import { Resource } from 'types/external/Resource';

import { SqsFormData } from 'views/ServicesHub/forms/monitoring/queue/Sqs';

import { monitoringAdapter } from '../monitoring';

export function sqsAdapter(data: Resource): ReturnType<typeof monitoringAdapter> & SqsFormData {
  const { domain, accessKey, secretAccessKey, awsRegion, testQueue } = prepareDomain(data);

  return {
    ...monitoringAdapter(data),
    domain,
    accessKey,
    secretAccessKey,
    awsRegion,
    testQueue,
    queueSize: data.domainSettings.queueSize
  };
}

const prepareDomain = (data: Resource) => {
  let accessKey = '';
  let secretAccessKey = '';
  let awsRegion = '';
  let testQueue = '';

  const domain = data.domain;
  // ex: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TEST-QUEUE

  if (data.domainSettings.domainType === 'userAndPass') {
    const sqsDomainRegex = /(.+):(.+)\/(.+)@(.+)/;

    const match = domain.match(sqsDomainRegex)!;

    accessKey = match[1];
    secretAccessKey = match[2];
    awsRegion = match[3];
    testQueue = match[4];
  }

  return { domain, accessKey, secretAccessKey, awsRegion, testQueue };
};
