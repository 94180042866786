import React from 'react';

import cloudProviders from 'constants/cloudProviders';

import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import DeleteButtonDialog from 'oldComponents/DeleteButtonDialog';
import DeleteEntity from 'oldComponents/DeleteEntityDialog';
import ShowDependencies from 'oldComponents/DeleteEntityDialog/components/ShowDependencies';
import DataTable from 'react-data-table-component';
import { useMutation, useQuery as useQueryReactQuery } from 'react-query';
import { batch, useDispatch } from 'react-redux';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';
import axios from 'redux/axios';

import SetupAgent from '../DataGrid/SetupAgent';

import columnsMap from './columns';
import Title from './Title';
import useEnvironmentDependencies from './useEnvironmentsDependencies';
import useEnvironments from './useEnvironmentsGraphQl';

const NewDataGrid = ({ entity, permissions, provider, query }: any) => {
  const dispatch = useDispatch();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [removeErrorEnvironmentId, setRemoveErrorEnvironmentId] = React.useState<
    string | number | null
  >(null);

  const [open, setOpen] = React.useState<{
    id: string | number;
    providerData: { provider: string };
    agent: { status_code: number };
  } | null>(null);

  const [fetchParams, setFetchParams] = React.useState({
    page: 1,
    sort: 'name,ASC',
    per_page: 10
  });

  const { data, fetching: isFetchingEnvs, reloadEnvironments } = useEnvironments({
    ...fetchParams,
    provider,
    query: query,
    newApplications: shouldUseNewApplications
  });

  const editHandler = ({ id }: { id: string | number }) => dispatch(push(`/${entity}/${id}/edit`));

  const checkActionDisabled = (
    environment: { providerData: { provider: string }; agent: { status_code: number } } | null
  ) => {
    if (shouldListDependencies) {
      return true;
    }

    if (environment?.providerData?.provider === cloudProviders.custom) {
      return false;
    }

    const statusCode = environment?.agent?.status_code;

    return statusCode ? [0, 16, 32, 64].includes(statusCode) : false;
  };

  const onRemoveErrorDismiss = () => {
    setRemoveErrorEnvironmentId(null);
  };

  const [removeHandler, { isLoading }] = useMutation(
    ({ id }: { id: string | number }) => axios.delete(`environments/${id}`),
    {
      onSuccess: () => {
        batch(() => {
          handleClose();
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Environment removed successfully'
          });
          dispatch({
            type: actions.FETCH_ENTITY,
            meta: {
              entity
            }
          });
        });
        reloadEnvironments();
      },
      onError: (err, { id }) => {
        setRemoveErrorEnvironmentId(id);
      }
    }
  );

  const { data: envDependencies, isLoading: gettingEnvDependencies } = useQueryReactQuery(
    ['envDependencies', open?.id],
    async () => (await axios.get(`environments/${open?.id}/dependencies`)).data.data,
    {
      enabled: Boolean(open)
    }
  );

  const {
    data: envsDependencies,
    fetching: fetchingEnvironmentDependencies
  } = useEnvironmentDependencies(
    {
      environmentId: Number(open?.id)
    },
    Boolean(open)
  );

  const handlePerRowsChange = (per_page: any) =>
    setFetchParams(prevState => ({
      ...prevState,
      per_page
    }));

  const handlePageChange = (page: any) =>
    setFetchParams(prevState => ({
      ...prevState,
      page
    }));

  const handleSort = ({ selector }: any, sortDirection: string) =>
    setFetchParams(prevState => ({
      ...prevState,
      sort: `${selector},${sortDirection.toUpperCase()}`
    }));

  const updateEnvironment = (environment: { id: any; agent: any }) => {
    reloadEnvironments();
  };

  const getAgent = (environment: { id: any }) => {
    return axios.get(`/environments/${environment.id}/agent`);
  };

  const installAgent = async (environment: { id: any }) => {
    return axios
      .post(`/environments/${environment.id}/agent/install`)
      .then(() => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'install agent...'
        });
        reloadEnvironments();
      })
      .catch(error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: error,
          ga: { category: 'ERROR', action: null }
        });
      });
  };

  const retryInstallAgent = async (environment: { id: any }) => {
    return axios
      .post(`/environments/${environment.id}/agent/retry`)
      .then(() => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'retrying agent...'
        });
      })
      .catch(error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: error,
          ga: { category: 'ERROR', action: null }
        });
      });
  };

  const upgradeAgent = async ({
    environment,
    agentTargetVersion
  }: {
    environment: { id: string | number };
    agentTargetVersion: any;
  }) => {
    return axios
      .post(`/environments/${environment.id}/agent/upgrade`, {
        agentTargetVersion
      })
      .then(() => {
        dispatch({
          type: actions.GLOBAL_SUCCESS,
          payload: 'install agent...'
        });
      })
      .catch(error => {
        dispatch({
          type: actions.ENTITY_ERROR,
          payload: error,
          ga: { category: 'ERROR', action: null }
        });
      });
  };

  const cols = columnsMap.filter(column => column.targets.includes(provider));

  const columns = [
    ...cols,
    {
      grow: 1,
      center: true,
      compact: true,
      cell: (row: any) => (
        <SetupAgent
          environment={row}
          permissions={permissions}
          getAgent={getAgent}
          installAgent={installAgent}
          upgradeAgent={upgradeAgent}
          retryInstallAgent={retryInstallAgent}
          updateEnvironment={updateEnvironment}
        />
      )
    },
    {
      grow: 1,
      center: true,
      compact: true,
      cell: (row: {
        name: any;
        id: any;
        providerData: {
          provider: string;
        };
        agent: {
          status_code: number;
        };
      }) => (
        <IconButton
          onClick={() => editHandler(row)}
          user-data={row?.name}
          title="Edit"
          disabled={!permissions.update || checkActionDisabled(row)}>
          <EditIcon />
        </IconButton>
      )
    },
    {
      grow: 1,
      center: true,
      compact: true,
      cell: (row: any) => (
        <DeleteButtonDialog
          entity={row}
          onRemove={removeHandler}
          onRemoveErrorDismiss={onRemoveErrorDismiss}
          removeErrorEntityId={removeErrorEnvironmentId}
          affectedApplications={envDependencies}
          setOpen={setOpen}
          confirmExclusionFlag={true}
        />
      )
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Items per page:',
    rangeSeparatorText: 'of',
    noRowsPerPage: false
  };

  const handleClose = () => {
    setOpen(null);
  };

  const shouldListDependencies = [
    envDependencies?.applications?.length,
    envDependencies?.services?.length,
    envsDependencies?.synthetics?.length,
    envsDependencies?.resources?.length
  ].some(length => length > 0);

  return data?.data?.length || isFetchingEnvs ? (
    <>
      <DataTable
        columns={columns as any}
        data={data?.data || []}
        highlightOnHover
        noDataComponent="No data"
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 25, 50]}
        paginationServer
        paginationTotalRows={data?.total || 0}
        sortServer
        striped
        progressComponent={<CircularProgress color="primary" size="3rem" />}
        progressPending={isFetchingEnvs}
        title={<Title provider={provider} isLoading={isFetchingEnvs} isFetching={isFetchingEnvs} />}
      />
      <DeleteEntity
        open={Boolean(open)}
        disabled={
          !permissions.remove || checkActionDisabled(open) || envDependencies?.length ? true : false
        }
        handleClose={handleClose}
        entityName="Environment"
        agentInstalled={checkActionDisabled(open)}
        handleRemove={() => removeHandler(open ?? undefined)}
        isLoading={isLoading || gettingEnvDependencies || fetchingEnvironmentDependencies}>
        {shouldListDependencies ? (
          <ShowDependencies
            dependencies={[
              { items: envDependencies?.applications || [], type: 'Application' },
              { items: envDependencies?.services || [], type: 'Service' },
              { items: envsDependencies?.synthetics || [], type: 'Synthetic' },
              { items: envsDependencies?.resources || [], type: 'Resource' }
            ]}
            title="Cannot remove because there are dependencies"
          />
        ) : null}
      </DeleteEntity>
    </>
  ) : null;
};

export default NewDataGrid;
