import { Box, Button, Link } from '@material-ui/core';
import { Add, Loop } from '@material-ui/icons';
import { UseFormMethods } from 'react-hook-form';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { useEnvironments } from 'hooks/queriesGraphQL/useEnvironments';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { Select } from '../Select';

type EnvironmentSelectProps = {
  form: UseFormMethods;
};

export function EnvironmentSelect({ form: { control } }: EnvironmentSelectProps) {
  const newApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [{ data, fetching }, refetch] = useEnvironments(null, newApplications);

  const options =
    data?.environments?.data?.map(environment => ({
      value: String(environment.id),
      label: environment.name
    })) ?? [];

  return (
    <Box position="relative" display="flex" gridGap="1rem">
      {fetching && <LoadingOverlay isRelative size="2rem" />}
      <Select options={options} name="environmentId" label="Checkpoint" control={control} />
      <Button
        variant="outlined"
        color="primary"
        title="Reload environments list"
        onClick={() => refetch()}>
        <Loop />
      </Button>
      <Link target="_blank" href="/environments">
        <Button variant="outlined" color="primary">
          <Add /> Checkpoint
        </Button>
      </Link>
    </Box>
  );
}
