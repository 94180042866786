import { Dispatch, SetStateAction, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  ExternalIntegrationFromIndexer,
  ExternalIntegrationType
} from 'types/external/ExternalService';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { usePermission } from 'hooks/usePermission';
import { useStyles } from 'views/Postmortems/styles';

import { ExternalIntegrationDeleteDialog } from './DeleteDialog';

type MenuProps = {
  externalServiceUid: string;
  externalServiceTitle: string;
  externalServices: ExternalIntegrationFromIndexer[];
  externalService: ExternalIntegrationFromIndexer;
  setExternalServices: Dispatch<SetStateAction<ExternalIntegrationFromIndexer[]>>;
  setDeletedItem: Dispatch<SetStateAction<string>>;
};

export function MenuActions({
  externalServiceUid,
  externalServiceTitle,
  externalServices,
  externalService,
  setExternalServices,
  setDeletedItem
}: MenuProps) {
  const classes = useStyles();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteExternalService = () => {
    handleClose();
    setDeleteDialogOpen(true);
  };

  const shouldUseRespondersInput = useFeatureFlagUnleash('useRespondersInput', {
    queryString: true
  });

  const hasDeleteExternalServicePermission = usePermission(
    'ExternalIntegrations-delete-/external_integrations_delete'
  );

  const hasUpdateExternalServicePermission = usePermission(
    'ExternalIntegrationsController-put-/integrations/:uid'
  );

  const servicesHubEditType = `${externalService.type}-${externalService.origin}`.toLowerCase();

  const servicesHubEditPath = `/services-hub/edit/${externalServiceUid}?type=${servicesHubEditType}`;

  const isDefaultType = externalService.type === ExternalIntegrationType.Default;

  const shouldShowUpdateButton = shouldUseRespondersInput && !isDefaultType;

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {shouldShowUpdateButton && (
          <MenuItem disabled={!hasUpdateExternalServicePermission} className={classes.menuItem}>
            <Link to={servicesHubEditPath} style={{ color: 'inherit' }}>
              Edit External Service
            </Link>
          </MenuItem>
        )}

        <MenuItem
          className={classes.menuItem}
          onClick={handleDeleteExternalService}
          disabled={!hasDeleteExternalServicePermission}>
          Delete External Service
        </MenuItem>
      </Menu>

      <ExternalIntegrationDeleteDialog
        open={deleteDialogOpen}
        externalIntegrationUid={externalServiceUid}
        externalIntegrationTitle={externalServiceTitle}
        handleClose={() => setDeleteDialogOpen(false)}
        externalServices={externalServices}
        setExternalServices={setExternalServices}
        setDeletedItem={setDeletedItem}
      />
    </>
  );
}
