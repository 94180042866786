import { Typography, makeStyles } from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller } from 'react-hook-form';

import withThemeV3Hoc from 'theme/v3/withTheme';

import HelperIcon from '../HelperIcon/index';

const useStyles = makeStyles(theme => {
  return {
    helperTextColor: {
      color: theme.palette.error.main
    },
    label: {
      color: theme.palette.text.primary,
      display: 'flex'
    }
  };
});

const InputPhoneNumber = ({
  label,
  name,
  defaultValue,
  helperText,
  required,
  disabled,
  control,
  tooltip
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.label} variant="body2">
        {required ? label + ' *' : label}
      </Typography>
      {tooltip && <HelperIcon tooltipText={tooltip}></HelperIcon>}
      <Controller
        name={name}
        control={control}
        render={({ name, onChange, value }) => (
          <MuiPhoneNumber
            disableAreaCodes
            defaultCountry={'br'}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            margin="dense"
            disabled={disabled}
            onChange={onChange}
            defaultValue={defaultValue}
            name={name}
            autoFocus={false}
            value={value}
            helperText={helperText}
            variant="outlined"
            FormHelperTextProps={{
              className: classes.helperTextColor
            }}
          />
        )}
      />
    </>
  );
};

export default withThemeV3Hoc(InputPhoneNumber);
