import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const lightGrey = colors.grey[400];

const palette = {
  black,
  white,
  lightGrey,

  primary: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[500],
    light: colors.green[100]
  },

  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400
  },

  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },

  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },

  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },

  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },

  text: {
    primary: colors.grey[900],
    secondary: colors.grey[700],
    lightGrey: colors.grey[500],

    link: colors.blue[600]
  },

  background: {
    default: '#F4F6F8',
    paper: '#fff',
    cards: colors.green[50]
  },

  inputBorder: {
    default: colors.grey[400],
    lightGrey: colors.grey[300]
  },

  button: {
    text: white,
    contrastText: colors.green[500],
    disabledMain: colors.grey[400],
    disabledLigth: colors.grey[200],
    primaryHover: colors.green[400],
    secondaryHover: colors.blue.A400
  },

  borders: {
    image: '#E1E4E8'
  },

  icon: colors.grey[500],
  divider: colors.grey[400]
};

export default palette;
