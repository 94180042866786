import { Typography } from '@material-ui/core';

import Dialog from 'components/Dialog';

import FormWithTextFieldInput from '../../GenericForms/FormWithTextFieldInput';
import { Props as GenericFormsProps } from '../../GenericForms/types';
import { PropsDelete, StaticContentsDelete } from '../types';

const staticsContents: StaticContentsDelete = {
  default: {
    title: 'Please confirm',
    Form: (props: GenericFormsProps) => <FormWithTextFieldInput {...props} />
  }
};

const contactMethodLabels: Record<'default' | 'telegram' | 'email' | 'app', string> = {
  default: 'phone number',
  telegram: 'Telegram',
  email: 'email',
  app: ''
};

export const DeleteMethodDialog = ({
  onClose,
  onSubmit,
  currentContact,
  type,
  strongLabel
}: PropsDelete) => {
  const isCreating = !currentContact.verified && !currentContact.value;

  const staticContent = staticsContents['default'];
  const label = contactMethodLabels[type] || contactMethodLabels['default'];

  return (
    <Dialog
      onSubmit={onSubmit}
      open={true}
      onClose={onClose}
      title={staticContent.title}
      description={
        <Typography>
          Would you like to delete the {!strongLabel && label}
          <strong> {strongLabel || currentContact.value}</strong>? By proceeding, you will no longer
          receive event notifications through this channel.
        </Typography>
      }
      actions={[
        {
          action: () => onClose(),
          label: 'Cancel',
          startIcon: null,
          variant: 'outlined'
        },
        {
          type: 'submit',
          label: isCreating ? 'Advance' : 'Delete',
          startIcon: null,
          variant: 'contained'
        }
      ]}>
      <></>
    </Dialog>
  );
};
