import { useState } from 'react';

import { apm } from '@elastic/apm-rum';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import getErrorMessageFromUrql from 'helpers/getErrorMessageFromUrql';
import { injectAuditTrailMetadata } from 'helpers/injectAuditTrailMetadata';
import { ProviderSettings } from 'hooks/queriesGraphQL/useGetProviderSettings';
import { useFeaturePlan } from 'hooks/useFeaturePlan';
import { usePermission } from 'hooks/usePermission';
import actions from 'redux/actions';
import { getFeaturePlanProviderName } from 'views/Teams/v2/lib/getFeaturePlanProviderName';

import NotificationSwitchBase from '../NotificationSwitchBase';

const activeProviderSettingsMutation = `#graphql
  mutation(
    $id: Int!
    $data: UpdateActiveProviderSettingsInput!
  ) {
    updateActiveProviderSettings(id: $id, data: $data)
  }
`;

type NotificationSwitchProps = {
  title: string;
  teamId: number;
  providerSettings: ProviderSettings;
  hasOnlyOneActiveProviderSettings: boolean;
  refetch: () => void;
};

const ENABLE_MESSAGE = `By disabling this channel, you will stop receiving notifications sent through it until it is reactivated. Do you wish to continue?`;
const DISABLE_MESSAGE = `By activating this channel, you'll start receiving notifications sent through it. Do you wish to continue?`;

const NotificationSwitch = ({
  title,
  teamId,
  providerSettings,
  hasOnlyOneActiveProviderSettings,
  refetch
}: NotificationSwitchProps) => {
  const dispatch = useDispatch();

  const [, activeProviderSettings] = useMutation(activeProviderSettingsMutation);

  const [checked, setChecked] = useState<boolean>(Boolean(providerSettings?.active));

  const activeProviderSettingsActionModal = async ({ active }: { active: boolean }) => {
    const providerUpdateResponse = await activeProviderSettings(
      {
        id: providerSettings.id,
        data: {
          active: active,
          providerId: providerSettings?.provider.id,
          teamId: teamId
        }
      },
      injectAuditTrailMetadata({
        providerName: providerSettings.provider.providerName
      })
    );

    if (providerUpdateResponse.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: { message: getErrorMessageFromUrql(providerUpdateResponse?.error) }
      });

      // eslint-disable-next-line no-console
      console.error(providerUpdateResponse.error);
      apm.captureError(providerUpdateResponse.error);
      refetch();
      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Update successfully'
    });

    setChecked(active);
    refetch();
    return;
  };

  const enableProviderActionModal = () => {
    activeProviderSettingsActionModal({ active: true });
  };

  const disableProviderActionModal = () => {
    activeProviderSettingsActionModal({ active: false });
  };

  const hasPermissionUpdate = usePermission('ProvidersController-put-/providers_settings');

  const isLastActiveProvider = checked && hasOnlyOneActiveProviderSettings;

  const existsProviderSettings = !Boolean(
    providerSettings.id === 0 || !providerSettings.provider.enabled
  );

  const featurePlanProviderName = getFeaturePlanProviderName(
    providerSettings.provider.providerName
  );
  const hasUseProviderFeaturePlan = useFeaturePlan(`NotifyChannels.${featurePlanProviderName}`);

  const getTooltipTitle = () => {
    if (!existsProviderSettings) return `Integration not configured for ${title} notifications`;

    return isLastActiveProvider
      ? "Can't disable because you must have at least one notification method"
      : '';
  };

  const canUpdateProvider = hasPermissionUpdate && existsProviderSettings && !isLastActiveProvider;

  return (
    <NotificationSwitchBase
      title={`Notify me at ${title}`}
      checked={checked}
      onEnable={enableProviderActionModal}
      onDisable={disableProviderActionModal}
      tooltipTitle={getTooltipTitle()}
      disabled={!canUpdateProvider}
      useProviderFeaturePlan={hasUseProviderFeaturePlan}
      activeMessage={ENABLE_MESSAGE}
      disableMessage={DISABLE_MESSAGE}
    />
  );
};

export default NotificationSwitch;
