import { Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useQuery } from 'react-query';

import { LoadingOverlay } from 'componentsV4/Loading';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { directus, directusV2 } from 'services/directus';
import { Theme } from 'theme/v5';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    '& h1': {
      ...theme.typography.h1,
      marginTop: 30,
      marginBottom: 30,
      textAlign: 'center'
    },
    '& h2': {
      ...theme.typography.h2,
      marginTop: 70,
      marginBottom: 30
    },
    '& p': {
      ...theme.typography.body1,
      lineHeight: 1.5,
      fontSize: 18,
      marginBottom: 20
    }
  },
  divider: {
    textAlign: 'center',
    marginTop: 70
  }
}));

const Terms = () => {
  const classes = useStyles();

  const shouldCmsWithPlugin = useFeatureFlagUnleash('cmsWithPlugin', { queryString: true });

  const { data, isLoading } = useQuery('termsOfUse', () => {
    return !shouldCmsWithPlugin
      ? directus.singleton('terms').read()
      : directusV2.singleton('terms').read();
  });

  if (isLoading) {
    return (
      <Container>
        <LoadingOverlay />
      </Container>
    );
  }

  return (
    <Container>
      <img
        alt="Logo"
        src="https://static.elven.works/assets/terms/logo-300x82.png"
        style={{ margin: '0 auto', display: 'block' }}
      />
      <ReactMarkdown
        className={classes.content}
        components={{
          a: ({ node, href, children, ...props }) => (
            <Link href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </Link>
          )
        }}>
        {data?.terms_content_md ?? ''}
      </ReactMarkdown>
    </Container>
  );
};

export default Terms;
