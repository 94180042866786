import { FormGroup, Box, Checkbox, FormControl, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  checkBoxDesabled: {
    color: theme.palette.grey[800]
  }
}));

const CheckboxField = ({ name, children, control, defaultValue, onChange, disabled }) => {
  const classes = useStyles();

  return (
    <FormControl>
      <FormGroup>
        <Box display="flex" alignItems="center" style={{ margin: '0 0 0 10px' }}>
          <Controller
            name={name}
            control={control}
            render={props => (
              <Checkbox
                {...props}
                defaultValue={defaultValue}
                disabled={disabled}
                checked={props.value}
                onChange={e => {
                  props.onChange(e.target.checked);
                  onChange && onChange(Boolean(e.target.checked));
                }}
                className={classes.checkBoxDesabled}
                color="primary"
              />
            )}
          />
          {children}
        </Box>
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxField;
