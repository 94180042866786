import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles((theme: Theme) => ({
  eventsHistoryTitle: {
    fontWeight: theme.typography.fontWeightRegular
  },
  card: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  ellipse: {
    width: '12px',
    height: '12px',
    backgroundColor: theme.palette.gray[700],
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  descriptionEllipse: {
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    backgroundColor: theme.palette.gray[300],
    marginRight: theme.spacing(1)
  },
  eventDescription: {
    marginRight: theme.spacing(1),
    fontSize: '16px'
  },
  timelineConnector: {
    width: '2px',
    height: '35px',
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.gray[200]
  },
  noDataText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '32px',
    color: theme.palette.gray[700]
  },
  incidentIdLink: {
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: 400,
    marginRight: theme.spacing(1)
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightBold
  }
}));
