import { default as axiosDefault } from 'axios';
import { QueryConfig, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import calculateProfileCompleteness from 'helpers/user/calculateProfileCompleteness';
import actions from 'redux/actions';
import reducer from 'redux/reducer/logged';

const entity_new = {
  isValid: false,
  values: {
    displayName: '',
    photoURL: '',
    cep: '',
    endereco: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    phone: ''
  },
  touched: {},
  errors: {}
};

const useUser = (options?: QueryConfig<any>) => {
  const dispatch = useDispatch();

  return useQuery(
    'auth/logged',
    async () => {
      return await axiosDefault({
        url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
        method: 'post',
        withCredentials: true,
        headers: {
          'X-Platform-Resolver': 'getSession'
        },
        data: {
          query: `
              query {
                getSession {
                  id
                  uid
                  displayName
                  email
                  isActive
                  superAdmin
                  photoURL
                  profile {
                    displayName
                    photoURL
                    phone
                    verifiedPhone
                    telegramId
                  }
                  accounts {
                    id
                    org {
                      id
                      name
                    }
                  }
                  logged {
                    id
                    org {
                      id
                      uid
                      name
                      signatureStatus
                      planName
                    }
                    roleId
                  }
                  features {
                    name
                    requiresDependent
                    target
                  }
                  teamFeatures {
                    teamId
                    features {
                      name
                      requiresDependent
                      target
                    }
                  }
                }
              }
            `
        }
      }).then(response => response.data.data.getSession);
    },
    {
      ...options,
      onSuccess: data => {
        const user = data || {};
        const account_edit = user.profile
          ? {
              ...entity_new,
              values: {
                ...user.profile,
                photoURL: user.photoURL
              }
            }
          : {
              ...entity_new,
              values: {
                ...entity_new.values,
                photoURL: user.photoURL
              }
            };

        dispatch({
          type: actions.FETCH_ENTITY_SUCCESSFUL,
          meta: { entity: 'user', reducer },
          payload: {
            user,
            loading: false,
            account_edit,
            profileCompleteness: calculateProfileCompleteness(account_edit.values)
          }
        });

        options?.onSuccess?.(data);
      },
      cacheTime: 5 * 60 * 1000
    }
  );
};

export default useUser;
