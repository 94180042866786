import { ReactNode } from 'react';

import { Paper, Typography } from '@material-ui/core';

import { useStyles } from 'views/Monitoring/View/styles';

export function MetricCard({ name, children }: { name: string; children: ReactNode }) {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.paperTop}>
      <Typography variant="subtitle2" className={classes.paperTitle}>
        {name}
      </Typography>
      {children}
    </Paper>
  );
}
