import { useTimerLogic } from '../../../V2/components/helpers/timerUtils';

export const Timer = ({ seconds, lastUpdate }: { seconds: number; lastUpdate: Date }) => {
  const remaining = useTimerLogic({ seconds, lastUpdate });

  if (remaining > seconds || remaining < 0) {
    return `0secs`;
  }

  return `${String(Math.round(remaining))}secs`;
};
