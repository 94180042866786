import React from 'react';

import { usePermission } from 'hooks/usePermission';

import CloudsList from './CloudsList';

const Clouds = () => {
  const permissions = {
    new: usePermission('CloudController-post-/clouds'),
    update: usePermission('CloudController-put-/clouds/:id'),
    remove: usePermission('CloudController-delete-/clouds/:id'),
    validate: usePermission('CloudController-post-/clouds/:id/validate')
  };

  return <CloudsList permissions={permissions} />;
};

export default Clouds;
