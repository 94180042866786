import { useQuery } from 'urql';

const ProductQuery = `#graphql
query($productId: Int!) {
  product(productId: $productId)
    {
      id
      name
      description
    }
  }
`;

export const useProduct = (productId: number) =>
  useQuery<{ product: { id: number; name: string; description: string } }>({
    query: ProductQuery,
    variables: { productId }
  });
