import React from 'react';

import { postBodyTypeValues } from 'constants/postBody';

import { Grid } from '@material-ui/core';
import { KeyValueFields, RawJsonField } from 'oldComponents/Inputs';

const PostBodyField = ({
  className,
  selectedPostBodyType,
  register,
  errors,
  postBodyXFormUrlEncodedFields,
  postBodyXFormUrlEncodedAppend,
  postBodyXFormUrlEncodedRemove,
  control,
  setValue,
  watch,
  disabled
}) => {
  const isUrlEncoded = selectedPostBodyType === postBodyTypeValues.urlencoded;

  return (
    <>
      <Grid item xs={10}>
        <RawJsonField
          className={className}
          style={isUrlEncoded ? { display: 'none' } : undefined}
          label="Post Body"
          register={register}
          name="post_body_raw"
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValueFields
          className={className}
          style={!isUrlEncoded ? { display: 'none' } : undefined}
          name="post_body_urlencoded"
          label="Post body"
          register={register}
          fields={postBodyXFormUrlEncodedFields}
          fieldAppend={postBodyXFormUrlEncodedAppend}
          fieldRemove={postBodyXFormUrlEncodedRemove}
          control={control}
          setValue={setValue}
          watch={watch}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default PostBodyField;
