import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import actions from 'redux/actions';
import axios from 'redux/axios';

const useSaveNewOrUpdateAddon = ({
  productId,
  applicationId,
  addonId,
  onUpdateAddon
}: {
  productId: string;
  applicationId: string;
  addonId: string;
  onUpdateAddon: () => void;
}) => {
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch({
      type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
      params: { productId, applicationId }
    });
    onUpdateAddon();
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Addon updated successfully'
    });
  };

  const onError = (error: any) => {
    dispatch({
      type: actions.ENTITY_ERROR,
      payload: error,
      ga: { category: 'ERROR' }
    });
  };

  const [saveNewAddon, { isLoading: isLoadingSaveNewAddon }] = useMutation(
    async data => axios.post(`/products/${productId}/addonsV2/services/${addonId}`, data),
    {
      onSuccess,
      onError
    }
  );

  const [updateAddon, { isLoading: isLoadingUpdateAddon }] = useMutation(
    async data => axios.put(`/products/${productId}/addonsV2/${addonId}`, data),
    {
      onSuccess,
      onError
    }
  );

  const isLoading = isLoadingSaveNewAddon || isLoadingUpdateAddon;

  return { saveNewAddon, updateAddon, isLoading };
};

export default useSaveNewOrUpdateAddon;
