export enum Recurrence {
  Weekly = 'weekly',
  Biweekly = 'biweekly',
  Triweekly = 'triweekly',
  Quadweekly = 'quadweekly',
  DoesNotRepeat = 'does_not_repeat'
}

export enum EndOfRecurrence {
  AfterAMonth = 'after_a_month',
  AfterTwoMonths = 'after_two_months',
  AfterThreeMonths = 'after_three_months',
  AfterSixMonths = 'after_six_months',
  AfterAYear = 'after_a_year',
  Never = 'never'
}
