import { push } from 'connected-react-router';
import { takeLatest, put } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareSaveEntity(axios, action) {
  try {
    const {
      payload: data,
      meta: { entity, url: destiny, query }
    } = action;
    if (data.id) {
      yield axios.put(`/${entity}/${data.id}`, data);
      yield put({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Entity updated successfully'
      });
    } else {
      yield axios.post(`/${entity}`, data);
      yield put({
        type: actions.GLOBAL_SUCCESS,
        payload: 'Entity created successfully'
      });
    }
    if (action.meta.reload) {
      yield put({
        type: actions.FETCH_ENTITY,
        meta: {
          entity,
          query
        }
      });
    }
    if (action.meta.callback) {
      action.meta.callback();
    }
    if (!action.meta.stayHere) {
      yield put(push(destiny || `/${entity}`));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSaveEntity(axios) {
  yield takeLatest(actions.SAVE_ENTITY, prepareSaveEntity, axios);
}
