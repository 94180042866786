import React from 'react';

import { makeStyles, FormHelperText } from '@material-ui/core';
import { Typography, Box, Select, MenuItem, InputBase, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Controller } from 'react-hook-form';

import withThemeV3Hoc from 'theme/v3/withTheme';

import inputsStateColors from '../../constants/inputsStateColors';
import HelperIcon from '../HelperIcon/index';

const useStyles = makeStyles(theme => {
  return {
    root: {
      borderRadius: 8,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      color: theme.palette.text.primary,
      paddingBottom: '2px',
      display: 'flex'
    },
    input: {
      borderRadius: 8,
      padding: '0px 0px'
    },
    helperText: {
      alignSelf: 'flex-end',
      color: props => (props.state ? inputsStateColors[props.state] : theme.palette.text.secondary)
    },
    status: {
      color: theme.palette.button.disabledMain,
      marginLeft: theme.spacing()
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  };
});

const SimpleInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.inputBorder.default}`,
    fontSize: 16,
    padding: '8px 12px',
    height: '22px',
    '&:focus': {
      borderRadius: 4
    }
  }
}))(InputBase);

const SelectWithStatus = ({
  items,
  defaultValue = '',
  defaultLabel,
  placeholder = '',
  tooltip = '',
  errors,
  state = '',
  required = false,
  disabled = false,
  name,
  helperText = '',
  control
}) => {
  const classes = useStyles({ state });

  const renderItens = item => {
    return (
      <MenuItem className={classes.menuItem} key={item.value} value={item.value}>
        {item.text} <small className={classes.status}>{item.status}</small>
      </MenuItem>
    );
  };

  const arrow = props => {
    return (
      <Box {...props} style={{ position: 'absolute', right: 8 }}>
        <ExpandMoreIcon color="primary" />
      </Box>
    );
  };

  const errorMessage = errors && errors[name]?.message;
  const helperTextMessage = () => {
    if (errorMessage && !helperText) return errorMessage;
    if (helperText && !errorMessage) return helperText;
    if (errorMessage && helperText)
      return (
        <p>
          {errorMessage} <br /> {helperText}
        </p>
      );
  };

  return (
    <FormControl className={classes.root} error={Boolean(errorMessage)}>
      <Typography className={classes.label} variant="body2">
        {required ? defaultLabel + ' *' : defaultLabel}
      </Typography>
      {tooltip && <HelperIcon tooltipText={tooltip}></HelperIcon>}

      <Controller
        name={name}
        control={control}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        as={
          <Select
            IconComponent={arrow}
            input={
              <SimpleInput
                style={{ borderColor: inputsStateColors[state] || inputsStateColors.default }}
              />
            }>
            {items?.map(renderItens)}
          </Select>
        }
      />

      <FormHelperText className={classes.helperText}>{helperTextMessage()}</FormHelperText>
    </FormControl>
  );
};

export default withThemeV3Hoc(SelectWithStatus);
