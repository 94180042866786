import {
  Typography,
  RadioGroup as MuiRadioGroup,
  Radio,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { Controller } from 'react-hook-form';

const RadioGroup = ({ options, defaultValue, control, name, label, classes, ...otherProps }) => {
  const renderOption = option => {
    return (
      <FormControlLabel
        key={option.value}
        value={option.value}
        disabled={option.disabled}
        classes={{ label: classes.label }}
        control={
          <Radio
            disabled={option.disabled}
            className={classes.radio}
            checkedIcon={<RadioButtonCheckedIcon className={classes.checked} />}
          />
        }
        label={option.label}
      />
    );
  };

  const renderedOptions = options.map(renderOption);

  return (
    <FormControl component="fieldset" className={classes.root}>
      <Typography>{label}</Typography>

      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          as={<MuiRadioGroup className={classes.radioGrup}>{renderedOptions}</MuiRadioGroup>}
        />
      ) : (
        <MuiRadioGroup className={classes.radioGrup} name={name} {...otherProps}>
          {renderedOptions}
        </MuiRadioGroup>
      )}
    </FormControl>
  );
};

export default RadioGroup;
