import React from 'react';

import {
  IconButton,
  Typography,
  Paper,
  Box,
  Popover,
  ListItemText,
  ListItem,
  List
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const Delete = ({
  entity,
  onRemove,
  onRemoveErrorDismiss,
  removeErrorEntityId,
  affectedApplications = [],
  affectedEnvironments = [],
  affectedServices = [],
  setOpen,
  confirmExclusionFlag
}) => {
  const { id } = entity;
  const iconRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (removeErrorEntityId === id) {
      setAnchorEl(iconRef.current);
    }
  }, [removeErrorEntityId, id, setAnchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
    onRemoveErrorDismiss();
  };

  const handleOpenDialog = () => {
    if (confirmExclusionFlag) {
      return setOpen(entity);
    }

    return onRemove(entity);
  };

  return (
    <>
      <IconButton ref={iconRef} data-user={entity?.name} onClick={handleOpenDialog} title="Remove">
        <DeleteIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box padding={2} component={Paper}>
          {affectedEnvironments.length ? (
            <>
              <Typography>Cannot remove because there are environments using this cloud</Typography>
              <List component="nav" aria-label="applications-being-monitored">
                {affectedEnvironments.map(environment => (
                  <ListItem key={environment.id}>
                    <ListItemText primary={`Environment: ${environment.name}`} />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}

          {affectedServices.length ? (
            <>
              <Typography>Cannot remove because there are services using this cloud</Typography>
              <List component="nav" aria-label="applications-being-monitored">
                {affectedServices.map(services => (
                  <ListItem key={services.id}>
                    <ListItemText primary={`Services: ${services.name}`} />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}

          {affectedApplications.length ? (
            <>
              <Typography>
                Cannot remove because there are applications using this environment
              </Typography>
              <List component="nav" aria-label="applications-being-monitored">
                {affectedApplications.map(application => (
                  <ListItem key={application.id}>
                    <ListItemText primary={`Applications: ${application.name}`} />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}
        </Box>
      </Popover>
    </>
  );
};
export default Delete;
