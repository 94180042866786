import React from 'react';

import { connect } from 'react-redux';

import actions from 'redux/actions';

import List from './List';

class Clusters extends React.Component {
  componentDidMount() {
    const { environment, fetch } = this.props;
    fetch(environment);
  }

  render() {
    const { data } = this.props.environment_clusters;
    data.environment = this.props.environment;
    return <List data={data} />;
  }
}

export default connect(
  ({ environment_clusters }) => ({ environment_clusters }),
  dispatch => ({
    fetch: payload =>
      dispatch({
        type: actions.ENVIRONMENT_CLUSTERS_FETCH,
        payload
      })
  })
)(Clusters);
