import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  title: {
    width: 600,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '32px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: {
    fontSize: 16,
    color: theme.palette.gray['700'],
    marginBottom: theme.spacing(4)
  }
}));
