import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router';

import useSelectAccount from 'hooks/queriesGraphQL/useSelectAccount';
import { planFeaturesQueryKey } from 'hooks/useFeaturePlan';

import { SelectAccountFormData } from './useForm/schema';

const useAppActions = () => {
  const queryCache = useQueryCache();
  const history = useHistory();

  const { selectUserAccount, isLoading } = useSelectAccount();

  const handleSelectAccount = async (data: SelectAccountFormData) => {
    return selectUserAccount({ orgId: data?.orgId }).then(async () => {
      await queryCache.invalidateQueries(planFeaturesQueryKey);
      queryCache.invalidateQueries('auth/logged').then(() => {
        history.push('/');
      });
    });
  };

  return {
    handleSelectAccount,
    isLoading
  };
};

export default useAppActions;
