import React from 'react';

import { Tooltip } from '@material-ui/core';

const TooltipButton = ({ title, children }) => (
  <Tooltip title={title}>
    <span>{children}</span>
  </Tooltip>
);

export default TooltipButton;
