/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from 'urql';

enum EventTypeTimeline {
  Deploy = 'Deploy',
  Hotfix = 'Hotfix',
  Rollback = 'Rollback',
  post_mortem = 'PostMortem',
  Alarmed = 'Alarmed',
  Acknowledged = 'Acknowledged',
  ChangeDescription = 'ChangeDescription',
  Resolved = 'Resolved'
}

type Content = {
  name?: string;
  createdAt?: string;
  description?: string;
  version?: string;
  severity?: string;
};

type Event = {
  id?: number;
  applicationId?: number;
  applicationName?: string;
  addonId?: number;
  incidentId?: number;
  content?: Content;
  type: EventTypeTimeline;
  createdAt: string;
};

type IncidentStatusUpdate = {
  id: number;
  incidentId: number;
  orgUid: string;
  description: string;
  private: boolean;
  lastAction: {
    id: number;
    action: string;
    createdAt: string;
    user: {
      id: number;
      name: string;
    };
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

enum IncidentUpdateType {
  IncidentStatusUpdate = 'IncidentStatusUpdate',
  Event = 'Event'
}

export type IncidentUpdate = {
  type: keyof typeof IncidentUpdateType;
  incidentStatusUpdate?: IncidentStatusUpdate;
  event?: Event;
  createdAt: string;
};

const getIncidentUpdate = `#graphql
  query( $incidentId: Int!) {
    incidentUpdates(
      incidentId: $incidentId
    ) {
      data{
        type,      
        incidentStatusUpdate{
          id,
          incidentId,
          orgUid,
          description,
          private,
          actions{
            id,
            action,
            createdAt
            user{
              id,
              name
            }
          },        
          lastAction{
            id,
            action,
            createdAt
            user{
              id,
              name
            }
          },
          createdAt,
          updatedAt,
          deletedAt
        },
        event{
          id,
          applicationId,
          applicationName,
          addonId,
          incidentId,
          content{
            name,
            createdAt,
            description,
            version,
            severity
          },
          type,
          createdAt        
        },
        createdAt
      }      
    }
  }`;

function useIncidentUpdate({ incidentId }: { incidentId: number }) {
  const [{ fetching: fetchingIncidentUpdate, data }, reexecuteQuery] = useQuery({
    query: getIncidentUpdate,
    pause: !incidentId,
    variables: { incidentId }
  });

  return [data, fetchingIncidentUpdate, reexecuteQuery];
}

export default useIncidentUpdate;
