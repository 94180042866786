import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { usePermission } from 'hooks/usePermission';

export const CreateSyntheticButton = () => {
  const permission = usePermission('ServicesHubController-post-/services');

  return (
    <Button
      component={Link}
      to={'/services-hub/new?type=synthetic'}
      title="Create new synthetic"
      disabled={!permission}>
      Synthetic
    </Button>
  );
};
