import React from 'react';

import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  spinner: {
    color: '#1C3D37'
  }
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <CircularProgress className={classes.spinner} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Loading;
