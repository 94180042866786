import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v5';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    height: theme.spacing(5.5)
  },
  autocomplete: {
    minHeight: theme.spacing(5.5),
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.gray[200],
      borderStyle: 'solid'
    }
  },
  buttons: {
    minWidth: 115
  },
  badgeContent: {
    backgroundColor: theme.palette.primary.main,
    minWidth: 16,
    height: 16
  },
  popper: {
    marginTop: theme.spacing(1)
  },
  content: {
    padding: '16px'
  },
  paper: {
    border: '1px solid #C2C2C2',
    boxShadow: '0px 2px 8px 0px #00000026'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(0.5)
  },
  divider: {
    backgroundColor: theme.palette.gray[100]
  },
  selectContainer: {
    marginTop: theme.spacing(3)
  },
  dialogTitle: {
    paddingBottom: theme.spacing(3),
    alignItems: 'center',
    paddingTop: '20px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500]
  }
}));
