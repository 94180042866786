import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/styles';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useUser from 'hooks/queriesRest/useUser';

import { General } from '../General';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export const TabGeneral = () => {
  const { data: user, isLoading: userIsLoading } = useUser();
  const org = user?.logged?.org;
  const theme = useTheme();

  const classes = useStyles();
  if (userIsLoading) {
    return (
      <div className={classes.root}>
        <LoadingOverlay />
      </div>
    );
  }

  return (
    <Box p={1} maxWidth={theme.breakpoints.values.lg}>
      <General org={org} />
    </Box>
  );
};
