import { takeEvery, put } from 'redux-saga/effects';

import actions from '../../actions';
import reducer from '../../reducer/merge';

const decodeValues = (providerCredentials, provider) => {
  if (provider === 'aws') {
    return {
      accessKeyId: atob(providerCredentials.accessKeyId),
      secretAccessKey: atob(providerCredentials.secretAccessKey)
    };
  }
  if (provider === 'azure') {
    return {
      clientId: atob(providerCredentials.clientId),
      clientSecret: atob(providerCredentials.clientSecret),
      tenantId: atob(providerCredentials.tenantId),
      subscriptionId: atob(providerCredentials.subscriptionId)
    };
  }
  return providerCredentials;
};

function* prepareFetchEntities(axios, action) {
  try {
    const { entity, id } = action.meta;
    const {
      data: { data: payload }
    } = yield axios.get(`/${entity}/${id}`);

    const { providerCredentials, ...values } = payload;

    yield put({
      type: actions.MERGE_ENTITY,
      meta: {
        entity: `${entity}_edit`,
        reducer
      },
      payload: { values: { ...values, ...decodeValues(providerCredentials, values.provider) } }
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchNavigation(axios) {
  yield takeEvery(actions.EDIT_CLOUD_ENTITY, prepareFetchEntities, axios);
}
