import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import EditDependencies from './EditDependencies';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.typography.pxToRem(500),
    borderRadius: theme.typography.pxToRem(8)
  },
  dialogTitleTextColor: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(24),
    color: '#212121'
  },
  dialogTextColor: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: '#414141'
  },
  addButton: {
    borderColor: '#65BB77',
    borderWidth: 2,
    borderRadius: theme.typography.pxToRem(4),

    color: '#65BB77',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),

    width: theme.typography.pxToRem(162),
    height: theme.typography.pxToRem(40),
    marginRight: theme.typography.pxToRem(8)
  },
  editButton: {
    background: '#37BA77',
    borderRadius: theme.typography.pxToRem(4),
    boxShadow: 'none',

    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(18),

    width: theme.typography.pxToRem(162),
    height: theme.typography.pxToRem(40)
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 22,
    color: '#595959'
  },
  dialogTitleBox: {
    marginTop: theme.spacing(2)
  }
}));

const EditDialogAddon = ({ handleOpenDialog, isOpen, dependencies, saveNewAddon, updateAddon }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleOpenDialog}
      open={isOpen}
      aria-labelledby="question-edit-dialog"
      maxWidth="sm"
      classes={{ paper: classes.paper }}>
      <DialogTitle id="question-edit-dialog" className={classes.dialogTitleBox}>
        <Typography className={classes.dialogTitleTextColor}>Edit Dependencies</Typography>
        <IconButton
          aria-label="question-edit-dialog"
          onClick={handleOpenDialog}
          className={classes.close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogTextColor}>
          Do you want to edit only this addon or all of them?
        </DialogContentText>

        <EditDependencies
          dependencies={[
            {
              items: dependencies || [],
              type: 'Application'
            }
          ]}
          title="Remember that editing all addons may have some impact on the following applications:"
        />

        <DialogActions
          style={{
            marginTop: 20,
            marginBottom: 10
          }}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={() => {
              saveNewAddon();
              handleOpenDialog();
            }}
            className={classes.editButton}>
            Only this
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="outlined"
            onClick={() => {
              updateAddon();
              handleOpenDialog();
            }}
            className={classes.addButton}>
            Edit all
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default EditDialogAddon;
