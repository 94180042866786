import { Box, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'urql';

import { ButtonSimple } from 'componentsV3/ButtonSimple';
import InputDefault from 'componentsV3/InputDefault';
import ModalCompound from 'componentsV3/ModalCompound';
import withThemeV3Hoc from 'theme/v3/withTheme';

import useForm from './useForm';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 31
  },
  input: {
    marginTop: 32
  }
}));

const CreateTeamByMSTeamsMutation = `#graphql
mutation($name: String!){
  createTeamWithMSTeams (name: $name){
    id,
    name
  }
}
`;

const startValues = {
  name: ''
};

function ModalTeam({ onTeamCreate, handleClose, isOpen }) {
  const classes = useStyles();

  const [createTeamByMSTeamsResult, createTeamByMSTeams] = useMutation(CreateTeamByMSTeamsMutation);

  const { handleSubmit, register, errors } = useForm({
    defaultValues: startValues
  });

  const submitMutation = data => {
    createTeamByMSTeams(data).then(result => {
      onTeamCreate({
        label: result.data.createTeamWithMSTeams.name,
        value: String(result.data.createTeamWithMSTeams.id)
      });
      handleClose();
    });
  };

  const { fetching } = createTeamByMSTeamsResult;

  return (
    <ModalCompound
      className={classes.root}
      title={'New Team'}
      isOpen={isOpen}
      handleClose={handleClose}>
      <form onSubmit={handleSubmit(submitMutation)}>
        <Grid item className={classes.input}>
          <InputDefault
            inputRef={register}
            errors={errors}
            name="name"
            id="team-name"
            label={'Team Name *'}
            placeholder={'Ex: Engineering team'}
          />
        </Grid>
        <Grid item xs={12} className={classes.button}>
          <ButtonSimple type="submit" color="primary" text={'Create team'} />
        </Grid>
        {fetching && (
          <Box
            style={{
              width: '97%',
              position: 'absolute',
              display: 'flex',
              top: 0,
              bottom: 0,
              right: 0,
              left: 12,
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgb(255 255 255 / 66%)',
              zIndex: 1000
            }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </ModalCompound>
  );
}

export default withThemeV3Hoc(ModalTeam);
