import React from 'react';

function requestReducer(state, action) {
  switch (action.type) {
    case 'success': {
      return {
        ...state,
        status: 'resolved',
        data: action.data,
        total: action.data.total
      };
    }
    case 'started': {
      return {
        ...state,
        status: 'pending'
      };
    }
    case 'error': {
      return {
        ...state,
        status: 'rejected',
        error: action.error
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const useStateRequest = (initialValue = undefined) => {
  const [state, dispatch] = React.useReducer(requestReducer, {
    status: 'idle',
    data: initialValue,
    total: 0,
    error: null
  });

  return [state, dispatch];
};
