export function startValuesAdapter(user: any) {
  if (!user) return;

  return {
    role: user?.roleId,
    id: user?.userId,
    email: user?.user?.email,
    name: user?.user?.displayName,
    status: user?.isActive ? 'active' : 'disable'
  };
}
