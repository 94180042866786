export const redisMapToApi = keyValues => {
  const { user, password, db, max_retries, is_cluster, tls } = keyValues;

  return {
    user: user,
    password: password,
    db: Number(db),
    max_retries: Number(max_retries),
    is_cluster: is_cluster,
    tls: tls
  };
};
