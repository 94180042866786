import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Switch,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMutation as useMutationURQL } from 'urql';
import * as z from 'zod';

import { ButtonSimple } from 'componentsV4/Button';
import { UpdateLatencyIncidentConfig } from 'hooks/queriesGraphQL/useUpdateLatencyIncidentsConfig';
import actions from 'redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(3)
  },
  dialogContainer: {
    maxWidth: 750,
    overflow: 'hidden',
    position: 'relative'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 28,
    color: '#263238',
    fontWeight: 500
  },
  noPadding: {
    padding: 0
  },
  actions: {
    padding: 0,
    marginTop: theme.spacing(3)
  },
  info: {
    margin: theme.spacing(4, 0, 3),
    color: '#565656',
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  submitButton: {
    color: 'white',
    backgroundColor: '#4CAF50',
    padding: theme.spacing(1, 2)
  },
  input: {
    maxWidth: 90,
    maxHeight: 35
  },
  inputLabel: {
    marginRight: theme.spacing(2)
  },
  inputHelperText: {
    width: '80%',
    textAlign: 'right'
  },
  spinner: {
    marginRight: 'auto',
    marginLeft: theme.spacing(1.5)
  },
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 100
  }
}));

const refine = percent => {
  if (percent === undefined) return true;
  if (Number(percent) > 0) return true;
  return false;
};

const schema = z.object({
  p50xp90_anomaly_percent: z.custom(refine, {
    message: 'use a percentage greater than 0 or disable incidents'
  }),
  p50xp95_anomaly_percent: z.custom(refine, {
    message: 'use a percentage greater than 0 or disable incidents'
  })
});

const defaultValues = {
  p50xp90_anomaly_percent: 0,
  p50xp95_anomaly_percent: 0
};

function Input({ register, errors, name, label, setValue, clearErrors, initialConfig = {} }) {
  const classes = useStyles();

  const getState = React.useCallback(() => {
    if (name in initialConfig) {
      return !Boolean(initialConfig[name]) ? true : false;
    }

    return false;
  }, [initialConfig, name]);

  const [isDisabled, setIsDisabled] = React.useState(getState());

  const errorMessage = errors && errors[name]?.message;

  const helperTextMessage = () => {
    if (errorMessage) return errorMessage;
  };

  React.useEffect(() => {
    setIsDisabled(getState());
  }, [getState, initialConfig]);

  React.useEffect(() => {
    if (isDisabled) {
      setValue(name, null, {
        shouldValidate: false
      });
      clearErrors(name);
    }
  }, [name, isDisabled, setValue, clearErrors]);

  return (
    <>
      <Typography variant="h4">{label}</Typography>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={!isDisabled}
              onChange={() => setIsDisabled(!isDisabled)}
            />
          }
        />
        <Typography>Warnings incidents</Typography>
      </Box>
      <Box display="flex" alignItems="flex-end" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Typography className={classes.inputLabel}>Tolerated Percentage</Typography>
          <OutlinedInput
            className={classes.input}
            inputRef={register}
            name={name}
            error={!isDisabled && Boolean(errorMessage)}
            disabled={isDisabled}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </Box>
        {!isDisabled && errorMessage && (
          <FormHelperText className={classes.inputHelperText}>{helperTextMessage()}</FormHelperText>
        )}
      </Box>
    </>
  );
}

function LatencyIncidentsModal({
  open,
  handleClose,
  initialConfig,
  loadingLatencyConfig,
  fetchingLatencyConfig,
  applicationId,
  productId,
  queryKey
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const { handleSubmit, reset, register, errors, setValue, clearErrors } = useForm({
    defaultValues: initialConfig || defaultValues,
    resolver: zodResolver(schema)
  });

  const [
    { fetching: fetchingUpdateLatencyIncidentConfig },
    updateLatencyIncidentConfigMutation
  ] = useMutationURQL(UpdateLatencyIncidentConfig);

  const submitMutation = async data => {
    const payload = {
      applicationId: Number(applicationId),
      p50xp90AnomalyPercent: Number(data?.p50xp90_anomaly_percent),
      p50xp95AnomalyPercent: Number(data?.p50xp95_anomaly_percent)
    };

    const response = await updateLatencyIncidentConfigMutation({
      updateLatencyIncidentConfigInput: payload
    });

    if (!response.data?.updateLatencyIncidentConfig?.success) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: 'Something went wrong try again'
      });
      return;
    }

    handleClose();
    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Latency Incidents configured successfully'
    });
    queryCache.invalidateQueries(queryKey);

    return;
  };

  const isLoading = loadingLatencyConfig || fetchingUpdateLatencyIncidentConfig;
  const isFetching = fetchingLatencyConfig && !isLoading;

  React.useEffect(() => {
    reset(initialConfig);
  }, [reset, initialConfig]);

  return (
    <Dialog
      classes={{
        paper: classes.dialogContainer
      }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      <Box className={classes.root}>
        <Box>
          <Box className={classes.top}>
            <Typography className={classes.title}>Response Time Degradation</Typography>
            {isFetching && !isLoading && (
              <CircularProgress className={classes.spinner} color="primary" size="1.5rem" />
            )}
            <IconButton className={classes.noPadding} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography className={classes.info} gutterBottom>
            Incidents will be generated when the difference is greater than the "Tolerated
            Percentage".
          </Typography>

          <form onSubmit={handleSubmit(submitMutation)} id="configureLatencyIncidents">
            <Grid container spacing={8}>
              <Grid item>
                <Input
                  register={register}
                  label="P50 x P90"
                  name="p50xp90_anomaly_percent"
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  initialConfig={initialConfig}
                />
              </Grid>
              <Grid item>
                <Input
                  register={register}
                  label="P50 x P95"
                  name="p50xp95_anomaly_percent"
                  errors={errors}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  initialConfig={initialConfig}
                />
              </Grid>
            </Grid>
          </form>
        </Box>

        <DialogActions className={classes.actions}>
          <ButtonSimple onClick={handleClose} text="Cancel" variant="outlined" color="primary" />
          <ButtonSimple
            color="primary"
            variant="contained"
            type="submit"
            form="configureLatencyIncidents"
            text="Save"
          />
        </DialogActions>

        <Backdrop open={isLoading && !isFetching} className={classes.backdrop}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress className={classes.spinner} color="primary" size="3rem" />
          </Box>
        </Backdrop>
      </Box>
    </Dialog>
  );
}

export default LatencyIncidentsModal;
