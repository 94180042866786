import { takeLatest, put, select } from 'redux-saga/effects';

import actions from '../../actions';

function* prepareAdd(axios, action) {
  try {
    const { id: applicationId } = yield select(state => state.application_services_view);
    const { application } = yield select(state => state.application_availability);

    const productId = application.productId;

    yield axios.post(`/products/${productId}/addons`, {
      applicationId,
      serviceId: action.payload._source.type === 'service' ? action.payload._source.id : null,
      applicationDependencyId:
        action.payload._source.type === 'application' ? action.payload._source.id : null
    });
    yield put({
      type: actions.APPLICATIONS_ADDONS_FETCH,
      payload: applicationId
    });
    if (application) {
      yield put({
        type: actions.APPLICATION_AVAILABILITY_VIEW_FETCH,
        params: { productId: application.productId, applicationId }
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put({
      type: actions.ENTITY_ERROR,
      payload: err,
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchProductsAddService(axios) {
  yield takeLatest(actions.APPLICATIONS_ADDONS_ADD_ADDON, prepareAdd, axios);
}
