import { DeletePostmortemMutation } from 'graphqlQueries/deletePostmortem';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import { DeleteDialog } from 'componentsV4/DeleteDialog';
import actions from 'redux/actions';

type PostmortemDeleteDialogProps = {
  open: boolean;
  handleClose: () => void;
  postmortemTitle: string;
  postmortemUid: string;
  onDelete: () => void;
};

export function PostmortemDeleteDialog({
  open,
  handleClose,
  postmortemTitle,
  postmortemUid,
  onDelete
}: PostmortemDeleteDialogProps) {
  const [{ fetching: deletingPostmortem }, deletePostmortem] = useMutation<{}, { uid: string }>(
    DeletePostmortemMutation
  );

  const dispatch = useDispatch();

  const handleDeletePostmortem = async () => {
    const response = await deletePostmortem({
      uid: postmortemUid
    });

    if (response.error) {
      dispatch({
        type: actions.ENTITY_ERROR,
        payload: new Error('Cannot delete postmortem. Please try again'),
        ga: { category: 'ERROR' }
      });

      handleClose();

      return;
    }

    dispatch({
      type: actions.GLOBAL_SUCCESS,
      payload: 'Postmortem successfully deleted'
    });

    handleClose();

    onDelete();
  };

  return (
    <DeleteDialog
      open={open}
      title={`Delete ${postmortemTitle}`}
      handleClose={() => handleClose()}
      handleRemove={handleDeletePostmortem}>
      {deletingPostmortem && <LoadingOverlay />}
      Are you sure you want to delete the <strong>{postmortemTitle}</strong> postmortem?
    </DeleteDialog>
  );
}
