import { Autocomplete, AutocompleteProps } from '@material-ui/lab-old';
import { Control, Controller } from 'react-hook-form';

import LoadingOverlay from 'componentsV3/LoadingOverlay';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import { useStyles } from './styles';

export function mapDataToOptions<T>(
  data: T[],
  fn: (item: T) => { label: string; value: string | number | boolean | null }
): { label: string; value: string | number | boolean | null }[] {
  if (data.length === 0) return [];
  return data.map(item => fn(item));
}

export function InputWithAutocomplete({
  control,
  defaultValue = null,
  name,
  autocompleteProps
}: {
  control: Control<any>;
  defaultValue?: string | null;
  name: string;
  autocompleteProps: AutocompleteProps;
}) {
  const classes = useStyles();

  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  return (
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          disableClearable={shouldUseNewApplications ? false : true}
          classes={{
            root: classes.root,
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
            noOptions: classes.noOptions,
            listbox: classes.listbox
          }}
          ListboxProps={{
            style: {
              maxHeight: '300px'
            }
          }}
          onChange={(_, data) =>
            shouldUseNewApplications ? onChange(data?.value || null) : onChange(data?.value)
          }
          getOptionLabel={option => option.label}
          noOptionsText="No options"
          {...autocompleteProps}
          renderInput={props => (
            <>
              {autocompleteProps.renderInput(props)}
              {autocompleteProps.loading && <LoadingOverlay size={20} />}
            </>
          )}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}
