import { useMemo, useState } from 'react';

import { TextFieldProps, debounce } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useQuery } from 'urql';

import { Incident } from 'hooks/queriesGraphQL/types';
import { Input } from 'views/ServicesHub/components/Input';

type SelectProps = {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
} & TextFieldProps;

const IncidentSelectQuery = `#graphql
  query GetIncidentsForPostmortem(
    $index: String!
    $query: String!
  ) {
    incidentsWithSeverity(
      index: $index
      from: 0
      size: 10
      gte: 0
      query: $query
      fields: ["title^3"]
    ) {
      data {
        incidentId
        title
      }
    }
  }
`;

function useIncidents(query: string) {
  const index = process.env.REACT_APP_ES_INCIDENTS_MANAGER_INDEX!;

  const [{ data, fetching }] = useQuery<
    {
      incidentsWithSeverity: { data: Incident[] };
    },
    {
      index: string;
      query: string;
    }
  >({
    query: IncidentSelectQuery,
    variables: { index, query }
  });

  return { data: data?.incidentsWithSeverity.data ?? [], fetching };
}

export function IncidentsSelect({ control, errors, ...textFieldProps }: SelectProps) {
  const { name, ...inputProps } = textFieldProps;

  const [query, setQuery] = useState('');

  const handleInputChange = debounce((query: string) => {
    if (query.length === 0) return setQuery('');

    if (query.length < 3) return;

    setQuery(`(*${query}*)`);
  }, 400);

  const { data, fetching } = useIncidents(query);

  const options = useMemo(() => {
    if (data.length === 0) return [];

    return data.map(incident => ({ label: incident.title, value: incident.incidentId }));
  }, [data]);

  return (
    <Controller
      control={control}
      name={name!}
      rules={{
        validate: value => {
          if (!value) return 'Incident is required';
        }
      }}
      render={({ value, onChange }) => {
        return (
          <Autocomplete
            freeSolo
            onChange={(_, value) => onChange(value?.value)}
            onInputChange={(_, value) => handleInputChange(value)}
            noOptionsText={query ? 'No incidents matched with query' : 'No data'}
            getOptionLabel={option => option.label ?? ''}
            getOptionSelected={(option, value) => option.value === value?.value}
            filterOptions={x => x}
            value={value?.value}
            options={options}
            loading={fetching}
            style={{ width: 660 }}
            loadingText="Fetching incidents..."
            size="small"
            ChipProps={{
              color: 'secondary'
            }}
            renderInput={params => (
              <>
                <Input
                  {...inputProps}
                  {...params}
                  name={name}
                  errors={errors}
                  placeholder={fetching ? 'Fetching incidents...' : inputProps.placeholder}
                  InputProps={{ ...params.InputProps, required: false }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </>
            )}
          />
        );
      }}
    />
  );
}
