import { PostBodyTypes, PostBodyValues } from 'constants/postBody';

import {
  MultiFlow,
  MultiFlowOrigin,
  MultiFlowStepType,
  UpdateMultiFlowPayload
} from 'types/external/MultiFlow';

import { objectToKeyValueList } from 'helpers/getObjectList';
import keyValuesToObject from 'helpers/keyValuesToObject';

import { Assertion, MultiFlowFormData } from '../new/multiflow';

const DEFAULT_HEADER_VALUE = [
  {
    key: '',
    value: ''
  }
];

export function multiFlowStartValuesAdapter(multiflow: MultiFlow): MultiFlowFormData {
  const id = String(multiflow.id);
  const environmentId = String(multiflow.environmentId);
  const intervalCheck = String(multiflow.interval);

  return {
    id,
    uid: multiflow.uid,
    name: multiflow.name,
    environmentId,
    intervalCheck,
    steps: multiflow.steps.map(step => {
      const id = String(step.id);
      const timeout = String(step.timeout);

      const headers = step.domainSettings?.headers
        ? objectToKeyValueList(step.domainSettings.headers)
        : DEFAULT_HEADER_VALUE;
      const tlsRenegotiation = String(step.domainSettings?.tlsRenegotiation);

      const postBodyUrlEnconded =
        step.domainSettings.postBodyType === PostBodyValues.urlencoded
          ? objectToKeyValueList(JSON.parse(step.domainSettings.postBody))
          : [];

      const postBodyType =
        step.domainSettings.postBodyType === PostBodyValues.urlencoded
          ? PostBodyValues.urlencoded
          : PostBodyTypes.raw;

      const skipSslValidation = String(step.domainSettings?.skipSslValidation);

      const validationString = step.domainSettings?.validationString ?? '';

      return {
        id,
        timeout,
        headers,
        tlsRenegotiation,
        postBodyType,
        postBodyUrlEnconded,
        name: step.name,
        healthcheckUrl: step.domain,
        method: step.method,
        postBody: step.domainSettings.postBody,
        rawType: step.domainSettings.postBodyType,
        skipSslValidation,
        validationString,
        assertions: step.assertions
      };
    })
  };
}

export function updateMultiFlowAdapter(multiflow: MultiFlowFormData): UpdateMultiFlowPayload {
  const id = Number(multiflow.id);
  const interval = Number(multiflow.intervalCheck);
  const environmentId = Number(multiflow.environmentId);

  return {
    id,
    uid: multiflow.uid,
    name: multiflow.name,
    interval,
    environmentId,
    origin: MultiFlowOrigin.Agent,
    steps: multiflow.steps.map((step, index) => {
      const id = Number(step.id);

      const timeout = Number(step.timeout);
      const headers = keyValuesToObject(step.headers || DEFAULT_HEADER_VALUE);
      const tlsRenegotiation = Number(step.tlsRenegotiation);

      const postBodyType =
        step.postBodyType === PostBodyValues.urlencoded ? PostBodyValues.urlencoded : step.rawType;

      const postBody = (() => {
        if (step.method !== 'POST') return {};

        return step.postBodyType === PostBodyTypes.raw
          ? btoa(step.postBody)
          : JSON.stringify(keyValuesToObject(step.postBodyUrlEnconded));
      })();

      const postBodySettings =
        step.method === 'POST'
          ? {
              postBodyType,
              postBody
            }
          : {};

      const assertions: Assertion[] = [];

      step.assertions?.forEach(
        ({ comparison, source, property, id, targetValue, ...other }: any, index) => {
          if (comparison && source) {
            assertions.push({
              stepId: Number(step.id),
              id: Number(id) | 0,
              comparison,
              source,
              order: index,
              property: property || '',
              targetValue: targetValue || '',
              ...other
            });
          }
        }
      );

      return {
        id,
        name: step.name,
        type: MultiFlowStepType.HTTP,
        domain: step.healthcheckUrl,
        method: step.method,
        step: index,
        domainSettings: {
          tlsRenegotiation,
          validationString: step.validationString,
          skipSslValidation: step.skipSslValidation === 'true',
          ...(headers ? { headers } : {}),
          ...postBodySettings
        },
        timeout,
        assertions
      };
    })
  };
}
