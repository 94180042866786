import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import useGetGenericQuery from 'oldComponents/Crud/DataGrid/v2/hooks/useGetGenericQuery';
import { connect } from 'react-redux';
import { useMutation } from 'urql';

import actions from 'redux/actions';
import reducer from 'redux/reducer/products/teams/change';
import { queryGetTeamsByOrgUid } from 'views/Teams/queries';
import { queryDeleteRelationshipWithTeams } from 'views/Teams/View/Products/queries';

import DataGrid from './DataGrid';
import { createQuery, getTeamsByResourceId } from './queries';
import Toolbar from './Toolbar';

const Teams = ({ success, handleOpen, productId, fetch }) => {
  const [dataFromMsTeams, setDataFromMsTeams] = useState([]);

  const [autoComplete, setAutoComplete] = useState({
    searchText: '',
    open: false
  });

  const resultQuery = useGetGenericQuery({
    query: queryGetTeamsByOrgUid,
    search: autoComplete.searchText,
    page: 1,
    per_page: 7,
    sort: 'name,ASC'
  });
  const viewFromMsTeams = {
    autoComplete: {
      loading: resultQuery.isFetching,
      options: resultQuery.data,
      searchText: autoComplete.searchText,
      open: autoComplete.open
    }
  };

  const [, addResourceAndTeam] = useMutation(createQuery);
  const [, getTeamsByResourceIdResult] = useMutation(getTeamsByResourceId);
  const [, removeHandlerFromMSTeams] = useMutation(queryDeleteRelationshipWithTeams);
  const AutoCompleteHandleChangeFromMSTeams = value => {
    setAutoComplete({ searchText: `${value}`, open: true });
  };
  const AddTeamResourceFromMSTeams = async props => {
    await addResourceAndTeam({ teamIds: props?.id, resourceId: productId });
    getTeamsResourceFromMSTeams(productId);
    setAutoComplete({ searchText: ``, open: false });
    success('Entity added successfully');
  };

  const RemoveTeamResourceFromMSTeams = async props => {
    await removeHandlerFromMSTeams({
      teamId: props.teamId,
      id: props.resourceId,
      resourceType: props.resourceType
    });
    getTeamsResourceFromMSTeams(props.resourceId);
    setAutoComplete({ searchText: ``, open: false });
    success('Entity removed successfully');
  };

  const getTeamsResourceFromMSTeams = async productId => {
    const dataMSTeams = await getTeamsByResourceIdResult({ resourceId: productId });
    const dataTeamsByResourceId = dataMSTeams?.data?.getTeamsByResourceId?.reduce((acc, data) => {
      const aux = data?.resourceTeams?.map(resourceTeams => ({
        teamId: resourceTeams?.teamId,
        id: resourceTeams?.teamId,
        name: data?.name,
        resourceId: resourceTeams?.resourceId,
        resourceType: resourceTeams?.resourceType,
        orgUid: data?.orgUid
      }));
      return [...acc, ...aux];
    }, []);
    setDataFromMsTeams(dataTeamsByResourceId);
  };

  React.useEffect(() => {
    if (productId) {
      fetch(productId);
      getTeamsResourceFromMSTeams(productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, productId]);

  return (
    <div>
      <Box mb={2}>
        <Toolbar
          addHandler={AddTeamResourceFromMSTeams}
          autoCompleteHandleChange={AutoCompleteHandleChangeFromMSTeams}
          handleOpen={handleOpen}
          searchPlaceholder="Associate team with this product"
          view={viewFromMsTeams}
        />
      </Box>

      <DataGrid data={dataFromMsTeams} removeHandler={RemoveTeamResourceFromMSTeams} />
    </div>
  );
};

export default connect(
  () => ({}),

  dispatch => ({
    fetch: payload =>
      dispatch({
        type: actions.PRODUCT_TEAMS_FETCH,
        payload
      }),
    handleOpen: payload =>
      dispatch({
        type: actions.PRODUCT_TEAMS_AUTOCOMPLETE_OPEN,
        payload,
        meta: {
          reducer
        }
      }),
    success: payload =>
      dispatch({
        type: actions.GLOBAL_SUCCESS,
        payload
      })
  })
)(Teams);
