import { Divider, Typography } from '@material-ui/core';
import { EventType } from 'types/external/EventsHistory';
import { EventTypeEnum } from 'types/external/EventType';

import { useStyles as useComponentsStyles } from '../styles';

type DescriptionProps = {
  event: EventType;
};

export function Description({ event, ...props }: DescriptionProps) {
  const componentsClasses = useComponentsStyles({ event });

  switch (event.type) {
    case EventTypeEnum.Alarmed:
    case EventTypeEnum.Acknowledged:
    case EventTypeEnum.Resolved:
    case EventTypeEnum.IncidentStatusUpdate:
      return (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Typography variant="body1" className={componentsClasses.eventDescription}>
            <span>{event.content.name}</span>
          </Typography>
        </>
      );

    case EventTypeEnum.Deploy:
    case EventTypeEnum.HotFix:
    case EventTypeEnum.Rollback:
      return (
        <>
          <span className={componentsClasses.descriptionEllipse}></span>
          <Typography variant="body1" className={componentsClasses.eventDescription}>
            <span>{event.content.description}</span>
          </Typography>
        </>
      );
  }
  return <Divider />;
}
