import { useEffect, useMemo } from 'react';

import agentStatus from 'constants/agentStatus';

import { Control } from 'react-hook-form';
import { useQuery } from 'urql';

import SelectWithStatus from 'componentsV3/SelectWithStatus';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

const EnvironmentsListQuery = `#graphql
  query($cloudId: Float, $newApplications: Boolean) {
    environments(page: 1, per_page: 1000, sort: "name,ASC", cloudId: $cloudId, newApplications: $newApplications) {
      data {
        id
        name
        status
        agentToken
      }
    }
  }
`;

export const useEnvironmentsQuery = (cloudId: number | null, newApplications: boolean) =>
  useQuery<{ environments: { data: { id: number; name: string; status: string }[] } }>({
    query: EnvironmentsListQuery,
    variables: { cloudId, newApplications }
  });

const EnvironmentSelect = ({
  control,
  errors,
  name,
  required = false,
  setValue,
  formState,
  cloudId = null,
  defaultLabel,
  selectFirst = false
}: {
  control: Control;
  errors: Object;
  name: string;
  required: boolean;
  setValue: Function;
  formState: any;
  cloudId?: number | null;
  selectFirst?: boolean;
  defaultLabel: string;
}) => {
  const newApplications = useFeatureFlagUnleash('newApplications', {
    queryString: true
  });

  const [environmentList] = useEnvironmentsQuery(cloudId, newApplications);

  const list = useMemo(() => {
    return (
      environmentList.data?.environments.data.map(env => {
        return {
          value: String(env.id),
          text: env.name,
          status: env.status !== agentStatus.Running ? 'Agent not installed' : ''
        };
      }) || []
    );
  }, [environmentList.data?.environments.data]);

  useEffect(() => {
    if (selectFirst && !formState.isDirty && list?.length > 0) {
      setValue(name, list[0].value, { shouldValidate: true });
    }
  }, [formState, setValue, name, list, selectFirst]);

  return (
    <SelectWithStatus
      required={required}
      control={control}
      defaultLabel={defaultLabel}
      items={environmentList.fetching ? [] : list}
      name={name}
      errors={errors}
    />
  );
};

export default EnvironmentSelect;
