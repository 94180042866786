export const initialState = {
  loading: true,
  isValid: false,
  values: {
    name: '',
    description: '',
    cover: ''
  },
  teams: [],
  selectedTeams: [],
  touched: {},
  errors: {}
};

const updateState = ({ product_new }) => {
  if (product_new) {
    return {
      product_new
    };
  }
  return {
    product_new: initialState
  };
};

const mapStateToProps = state => updateState(state);

export default mapStateToProps;
