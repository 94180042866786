import { MetabaseDashboards } from 'constants/metabaseDashboards';

import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import dayjs from 'dayjs';
import MetabaseDashboard from 'oldComponents/MetabaseDashboard';

import { useStyles } from './styles';

type Props = DialogProps & {
  open: boolean;
  onClose: () => void;
  teamId: number;
};

const ExportDialog = ({ open, onClose, teamId, ...props }: Props) => {
  const theme = useTheme();

  const classes = useStyles();

  const getCurrentYearMonth = () => {
    return dayjs().format('YYYY-MM');
  };

  const scheduledDate = getCurrentYearMonth();

  const hideParams = ['team_id', 'scheduled_date'] as string[];

  return (
    <Dialog open={open} onClose={onClose} {...props} maxWidth="lg" fullWidth>
      <Box display="flex" flexDirection="column">
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography></Typography>
          <IconButton
            style={{ marginTop: -12, marginRight: -12, marginLeft: theme.spacing(2) }}
            aria-label="close"
            onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <MetabaseDashboard
            title=""
            subtitle=""
            dashboardName={MetabaseDashboards.OnCallList}
            hideParams={hideParams}
            dashboardParams={{ team_id: teamId, scheduled_date: scheduledDate }}
            iframeHeight={830}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ExportDialog;
