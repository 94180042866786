import { Box, useTheme } from '@material-ui/core';

import { useStyles } from './styles';

const MAX_CHARACTERS = 50;

function ConnectedApplicationData({ connectedApplications }: { connectedApplications: string[] }) {
  const classes = useStyles();
  const theme = useTheme();

  const { filteredConnectedApplications, restApplications } = (() => {
    let charactersCount = 0;

    const filteredConnectedApplications = connectedApplications.filter(app => {
      if (charactersCount >= MAX_CHARACTERS) return false;
      if (charactersCount + app.length > MAX_CHARACTERS) return false;

      charactersCount += app.length + 2; // +2 for the comma and space

      return true;
    });

    return {
      filteredConnectedApplications,
      restApplications: connectedApplications.length - filteredConnectedApplications.length
    };
  })();

  return (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
      <span>{filteredConnectedApplications.join(', ')}</span>
      {restApplications > 0 && (
        <span className={classes.restApplicationsBadge}>{restApplications} +</span>
      )}
    </Box>
  );
}

export default ConnectedApplicationData;
