import { put, takeLatest } from 'redux-saga/effects';

import actions from '../../actions';
import genericErrors from '../../actions/genericErrors';
import reducer from '../../reducer/upload';

function* upload(axios, action) {
  try {
    const { file, contentType, name, id } = action.payload;
    yield axios.request({
      method: 'put',
      url: '/products/cover',
      data: { file, contentType, name, id }
    });
    yield put({
      type: actions.PRODUCT_EDIT_FETCH,
      payload: id
    });
    yield put({
      type: actions.PRODUCT_UPLOAD_AVATAR_SUCCESSFUL,
      meta: { reducer }
    });
  } catch (err) {
    yield put(genericErrors(action.type, err));
  }
}

export default function* watchUpload(axios) {
  yield takeLatest(actions.PRODUCT_UPLOAD_AVATAR, upload, axios);
}
