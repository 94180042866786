import * as z from 'zod';

export const functionsSchemaValidation = () => {
  return {
    search_period: z.boolean().optional(),
    locations: z
      .string()
      .nonempty({ message: 'Invalid region' })
      .optional(),
    json_functions_body_raw: z.any()
  };
};
