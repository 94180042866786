import { makeStyles, Box, Divider, Typography } from '@material-ui/core';
import { ButtonDefault } from 'oldComponents/Button';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import Info from './Info';

const useStyles = makeStyles(theme => ({
  dividerTop: {
    backgroundColor: 'black',
    opacity: '10%',
    marginBottom: theme.spacing(3)
  },
  dividerBottom: {
    backgroundColor: 'black',
    opacity: '10%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  paragraphText: {
    maxWidth: '50ch',
    paddingBottom: theme.spacing(3)
  }
}));

const MaintenanceDeleteForm = ({ onClose, startValues, submitDelete }) => {
  const classes = useStyles();
  const { handleSubmit } = useForm({});

  const { productId } = useParams();

  const onSubmit = () => {
    submitDelete({
      id: startValues.id,
      start: startValues.start,
      finish: startValues.finish,
      productId: Number(productId)
    });
    onClose();
  };

  return (
    <form name="deleteMaintenance" onSubmit={handleSubmit(onSubmit)}>
      <Typography className={classes.paragraphText}>
        You have maintenance scheduled. Do you want to delete it?
      </Typography>
      <Divider className={classes.dividerTop} />

      <Info startValues={startValues} />

      <Divider className={classes.dividerBottom} />

      <Box display="flex" justifyContent="flex-end">
        <ButtonDefault onClick={onClose} color="primary">
          Cancel
        </ButtonDefault>

        <ButtonDefault
          color="primary"
          variant="contained"
          type="submit"
          style={{ marginLeft: '24px' }}>
          Delete
        </ButtonDefault>
      </Box>
    </form>
  );
};

export default MaintenanceDeleteForm;
