import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    borderColor: theme.palette.grey[300]
  },
  details: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dashboard: {
    paddingTop: '48px',
    height: '255px',
    width: '479px'
  }
}));
