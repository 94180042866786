import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';

import V1 from '../View';

import V2 from './index';

const TeamsView = (props: any) => {
  const useNewTeamsView = useFeatureFlagUnleash('useNewTeamsView', { queryString: true });

  if (!useNewTeamsView) return <V1 {...props} />;

  return <V2 />;
};

export default TeamsView;
