import { useState } from 'react';

import { MenuItem, makeStyles } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { MenuSidebar, Type } from 'types/directus';

import { LockChip } from 'components/LockChip';
import { Menu, MenuProps } from 'components/Menu';
import { QRCodeModal } from 'components/QRCodeModal';
import { composeNavigationLink } from 'helpers/composeNavigationLink';
import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import { useFeaturePlan } from 'hooks/useFeaturePlan';

type ToolbarMenuProps = {
  menuItems: Pick<MenuSidebar, 'id' | 'href' | 'title' | 'planFeature' | 'type' | 'featureFlag'>[];
} & Pick<MenuProps, 'anchorEl' | 'anchorElId' | 'placement' | 'onClose'>;

const useStyles = makeStyles(theme => ({
  popper: {
    minWidth: 424
  },
  menuItem: {
    '&.Mui-disabled': {
      opacity: 'initial'
    }
  }
}));

const actions: Record<
  string,
  React.FC<{ open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }>
> = {
  'open-qr-code-modal': ({ open, setOpen }) => (
    <QRCodeModal open={open} handleClose={() => setOpen(false)} />
  )
};

function ActionMenuItem({ menuItem }: { menuItem: ToolbarMenuProps['menuItems'][number] }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const ActionComponent = actions[menuItem.href?.replace('#', '') ?? ''];

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <MenuItem key={menuItem.id} className={classes.menuItem} onClick={handleClick}>
        {menuItem.title}
      </MenuItem>
      {ActionComponent && <ActionComponent open={open} setOpen={setOpen} />}
    </>
  );
}

function useFeatureFlagForMenu(featureFlag: string) {
  const [, shouldNegateFlag, flag] = featureFlag.match(/^(!)?(.+)$/) ?? [];

  const isFeatureFlagEnabled = useFeatureFlagUnleash(flag);

  if (shouldNegateFlag) return !isFeatureFlagEnabled;

  return isFeatureFlagEnabled;
}

function ToolbarMenuItem({ menuItem }: { menuItem: ToolbarMenuProps['menuItems'][number] }) {
  const classes = useStyles();

  const isExternal = menuItem?.type === Type.External;

  const isFeaturePlanEnabled = useFeaturePlan(menuItem.planFeature, { placeholder: true });

  const isAccessibleByPlan = menuItem.planFeature ? isFeaturePlanEnabled : true;

  const isFeatureFlagEnabled = useFeatureFlagForMenu(menuItem?.featureFlag ?? '');

  if (menuItem?.featureFlag && !isFeatureFlagEnabled) return null;

  if (menuItem.type === Type.Action) {
    return <ActionMenuItem menuItem={menuItem} />;
  }

  return (
    <MenuItem
      key={menuItem.id}
      data-active={menuItem.href === window.location.pathname}
      className={classes.menuItem}
      {...{
        target: isExternal ? '_blank' : '_self',
        component: NavLink,
        to: composeNavigationLink(menuItem.href ?? ''),
        rel: 'noopener noreferrer'
      }}
      disabled={!isAccessibleByPlan}>
      {menuItem.title}
      {isExternal && <Launch fontSize="small" htmlColor="inherit" />}
      {!isAccessibleByPlan && <LockChip />}
    </MenuItem>
  );
}

export function ToolbarMenu({
  anchorElId = '',
  menuItems,
  anchorEl,
  placement = 'bottom-start',
  onClose
}: ToolbarMenuProps) {
  const classes = useStyles();

  return (
    <Menu
      anchorElId={anchorElId}
      anchorEl={anchorEl}
      onClose={onClose}
      popperProps={{
        className: classes.popper,
        placement
      }}>
      {menuItems?.map(item => (
        <ToolbarMenuItem key={item.id} menuItem={item} />
      ))}
    </Menu>
  );
}
