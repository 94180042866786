import { useEffect, useMemo, useRef, useState } from 'react';

import { makeStyles, Paper, Popper, Typography } from '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { format } from 'date-fns';
import ReactApexChart from 'react-apexcharts';

import useChartTooltip from './useChartTooltip';

const useStyles = makeStyles(({ spacing, palette }) => ({
  tooltip: {
    maxWidth: 400,
    maxHeight: 200,
    padding: spacing(3, 2.25),
    '& > .type': {
      textTransform: 'capitalize',
      color: palette.text.secondary
    }
  },
  icon: {
    fontSize: '1rem',
    marginRight: '0.2rem'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      marginTop: '0.5rem'
    },
    '&.description': {
      alignItems: 'flex-start',
      textOverflow: 'ellipsis',
      '& p': {
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }
    }
  }
}));

function Tooltip({ data, anchorEl }) {
  const { type, version, description, timestamp } = data || {};

  const classes = useStyles();

  return (
    <Popper open={true} anchorEl={anchorEl} placement="right-end" style={{ zIndex: 100 }}>
      <Paper className={classes.tooltip}>
        <Typography className="type" variant="h5" gutterBottom>
          {type}
        </Typography>

        <Typography className={classes.row} variant="caption">
          <CalendarToday className={classes.icon} />
          {format(new Date(timestamp), 'MMMM dd, HH:mm zzz')} <br />
        </Typography>

        <Typography className={classes.row} variant="caption">
          <LocalOfferIcon className={classes.icon} />
          {version}
        </Typography>

        {description && (
          <Typography className={`${classes.row} description`} variant="caption">
            <DescriptionIcon className={classes.icon} />
            <p title={description}>{description}</p>
          </Typography>
        )}
      </Paper>
    </Popper>
  );
}

const getDeployLineByType = ({ deploy, labelClass }) => {
  const cssClass = `deploy-${deploy.id}`;

  const props = {
    background: '',
    type: '',
    icon: ''
  };

  switch (deploy.type) {
    case 'hotfix':
      props.background = '#e57373';
      props.type = 'Hotfix';
      props.icon = 'build';
      break;
    case 'rollback':
      props.background = '#1e88e5';
      props.type = 'Rollback';
      props.icon = 'restore';
      break;
    default:
      props.background = '#4caf50';
      props.type = 'Deploy';
      props.icon = 'cloud_upload';
  }

  return {
    x: new Date(deploy.timestamp).getTime(),
    label: {
      style: {
        color: '#fff',
        background: props.background,
        fontFamily: 'Material Icons',
        cssClass: `${cssClass} ${labelClass} material-icons`
      },
      orientation: 'horizontal',
      text: props.icon
    }
  };
};

const ApexChart = props => {
  const classes = useStyles();

  const deployClassNames = useRef([]);
  const deployData = useRef([]);

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [currentDeployId, setCurrentDeployId] = useState('');

  const graphPercentiles50Coordinates = useMemo(
    () =>
      props.data.latencies.map(coordinate => {
        return {
          x: new Date(coordinate.timestamp).toString(),
          y: coordinate.latency
        };
      }),
    [props.data.latencies]
  );

  const graphPercentiles90Coordinates = useMemo(
    () =>
      props.data.latencies.map(coordinate => {
        return {
          x: new Date(coordinate.timestamp).toString(),
          y: coordinate.latency_p90
        };
      }),
    [props.data.latencies]
  );

  const graphPercentiles95Coordinates = useMemo(
    () =>
      props.data.latencies.map(coordinate => {
        return {
          x: new Date(coordinate.timestamp).toString(),
          y: coordinate.latency_p95
        };
      }),
    [props.data.latencies]
  );

  const lineDeploys = useMemo(
    () =>
      props.data.deploys?.map(deploy =>
        getDeployLineByType({
          deploy,
          labelClass: classes.icon
        })
      ),
    [classes.icon, props.data.deploys]
  );

  const { registerEvents, unregisterEvents } = useChartTooltip({
    setShouldShowTooltip,
    setCurrentDeployId
  });

  const chart = useMemo(
    () => ({
      series: [
        {
          name: 'P50 (median)',
          data: graphPercentiles50Coordinates
        },
        {
          name: 'P90 (slow)',
          data: graphPercentiles90Coordinates
        },
        {
          name: 'P95 (slowest)',
          data: graphPercentiles95Coordinates
        }
      ],
      options: {
        tooltip: {
          x: {
            show: false
          }
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          title: {
            text: 'Latencies (miliseconds)'
          },
          labels: {
            formatter: value => `${(value / 1000).toFixed(2)} ms`
          }
        },
        colors: ['#FF8500', '#003F88', '#4CAF50'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 1.5
        },
        title: {
          text: 'Latency and Deployments (Last 24 hours)',
          align: 'center'
        },
        annotations: {
          xaxis: lineDeploys
        },
        chart: {
          events: {
            updated: () => registerEvents(deployClassNames?.current)
          }
        }
      }
    }),
    [
      graphPercentiles50Coordinates,
      graphPercentiles90Coordinates,
      graphPercentiles95Coordinates,
      lineDeploys,
      registerEvents
    ]
  );

  useEffect(() => {
    deployClassNames.current = props.data.deploys?.map(deploy => `deploy-${deploy.id}`);
    deployData.current = props.data.deploys?.map(deploy => {
      const id = `deploy-${deploy.id}`;

      return {
        id,
        type: deploy.type,
        version: deploy.version,
        description: deploy.description,
        timestamp: deploy.timestamp
      };
    });

    registerEvents(deployClassNames.current);

    return () => unregisterEvents(deployClassNames.current);
  }, [props.data.deploys, registerEvents, unregisterEvents]);

  const currentDeployData = currentDeployId
    ? deployData?.current?.find(deploy => deploy.id === currentDeployId)
    : {};

  const getTooltipAnchorElement = () => document.querySelector(`.${currentDeployId}`);

  return (
    <>
      {shouldShowTooltip && <Tooltip anchorEl={getTooltipAnchorElement} data={currentDeployData} />}

      <div id="chart">
        <ReactApexChart
          style={{ marginTop: '12px' }}
          options={chart.options}
          series={chart.series}
          type="line"
          height={250}
        />
      </div>
    </>
  );
};

export default ApexChart;
