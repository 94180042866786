import { Resource } from 'types/external/Resource';
import { useQuery } from 'urql';

type UseResourceParams = {
  graphqlQuery: string;
  page: number;
  perPage: number;
  query: string;
  pause?: boolean;
};

type UseResourcesData = {
  data: Resource[];
  total: number;
};

export function useResources({
  graphqlQuery,
  page,
  perPage: size,
  query,
  pause = false
}: UseResourceParams) {
  const from = Math.max(page - 1, 0) * size;

  return useQuery<{ getResourcesFromIndexer: UseResourcesData }>({
    query: graphqlQuery,
    variables: {
      from,
      size,
      query
    },
    pause
  });
}
