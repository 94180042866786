import { Box, Fade, Grid } from '@material-ui/core';
import { WhatsApp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  alert: {
    height: 30,
    width: '100%',
    background: 'linear-gradient(90deg, #F7832F 31.81%, #F79B2F 68.54%);'
  },
  message: {
    justifyContent: 'center',
    display: 'flex',
    fontWeight: 'bold',
    lineHeight: 1.5,
    fontSize: 18
  }
}));

const PlansMessage = ({ message }) => {
  const classes = useStyles();

  return (
    <Fade in timeout={{ enter: 1000 }}>
      <Box className={classes.alert}>
        <Grid className={classes.message}>
          {message}
          <WhatsApp
            style={{
              color: 'white',
              marginLeft: '10',
              marginRight: '5'
            }}
          />
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://api.whatsapp.com/send?phone=5511940702988"
            title="Open whatsapp"
            target="_blank"
            rel="noreferrer">
            (11) 94070-2988
          </a>
        </Grid>
      </Box>
    </Fade>
  );
};

export default PlansMessage;
