export const getStepGroupsList = `
query($teamIdFromTeams: Int!) {
  listStepGroupsByTeamIdInMSTeams(teamIdFromTeams: $teamIdFromTeams) {
    id
    teamIdFromTeams
    name
    steps {
      id
      memberFromTeams
      contactMethod
      intervalInMinutes
      timeoutInMinutes
      order
    }
  }
}
`;
