import React from 'react';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: process.env.RECT_APP_SENTRY_DSN });
}
ReactDOM.render(<App />, document.getElementById('root'));
