import { Box, Checkbox, FormControlLabel, FormGroup, Icon, Tooltip } from '@material-ui/core';
import { Controller, useFieldArray } from 'react-hook-form';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { useStyles } from 'views/Monitoring/View/styles';
import { HostAndPortWithSecrets } from 'views/ServicesHub/components/HostAndPortWithSecrets';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type RedisFormData = {
  hosts: { key: string; value: string }[];
  user: string;
  password: string;
  databaseRedis: number;
  isCluster: boolean;
  tls: boolean;
  maxRetries: number;
  domainType: string;
  domain: string;
};
export function RedisForm({ form, domainType }: FormProps) {
  const classes = useStyles();
  const { register, control, watch, errors, setValue } = form;

  const { fields, append: addFields, remove: removeFields } = useFieldArray({
    control,
    name: 'hosts'
  });

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      {domainType !== 'url' && (
        <>
          <HostAndPortWithSecrets
            form={form}
            name="hosts"
            fields={fields}
            handleAdd={addFields}
            handleRemove={removeFields}
            label="Host"
            valueLabel="Port"
            required={true}
            inputProps={{
              min: 1,
              max: 65535
            }}
          />
        </>
      )}
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'text',
            label: 'User',
            name: 'user',
            required: false
          }}
          InputComponent={Input}
        />
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'password',
            label: 'Password',
            name: 'password',
            required: false
          }}
          InputComponent={Input}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Input label="Database" name="databaseRedis" inputRef={register} type="number" />
        <Input label="Max retries" name="maxRetries" type="number" inputRef={register} />
      </Box>
      <Box display="flex" gridGap="64px">
        <Controller
          control={control}
          name="isCluster"
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="Cluster"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                      />
                    }
                  />
                  <Tooltip title="Check it up if you are going to monitor a Redis cluster">
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
        <Controller
          control={control}
          name="tls"
          render={({ value, onChange }) => {
            return (
              <FormGroup>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    style={{ marginRight: 0 }}
                    label="TLS"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(_, val) => onChange(val)}
                        color="primary"
                      />
                    }
                  />
                  <Tooltip title="Check it up if you are going to monitor a Redis TLS Connection">
                    <Icon className={classes.descriptionIcon}>help</Icon>
                  </Tooltip>
                </Box>
              </FormGroup>
            );
          }}
        />
      </Box>
    </Box>
  );
}
