import { Box } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const createFirstColumnWithDependencyName = () => {
  return {
    name: '' || <></>,
    minWidth: '200px',
    conditionalCellStyles: [
      {
        when: (row: { resourceName: string }) => row.resourceName,
        style: {
          color: 'white',
          backgroundColor: blue[500]
        }
      }
    ],
    cell: (row: { resourceName: string }) => {
      return (
        <Box
          title={row?.resourceName ? row?.resourceName : 'Dependency'}
          whiteSpace="noWrap"
          maxWidth={140}
          textOverflow="ellipsis"
          overflow="hidden">
          {row?.resourceName ? row?.resourceName : 'Dependency'}
        </Box>
      );
    }
  };
};
