import { useState, ChangeEvent, useRef } from 'react';

import { Box, TextField, useTheme, Badge, Button } from '@material-ui/core';
import { Search, Tune } from '@material-ui/icons';

import useDebounce from 'hooks/useDebounce';

import { FilterOptions } from './FilterOptions';
import { useStyles } from './styles';

const TYPING_DEBOUNCE_TIME = 500;

export const FilterToolbar = ({
  setSearch,
  handleClearFilters,
  badgeContent,
  placeholder,
  additionalFilters,
  children
}: {
  setSearch: (search: string) => void;
  placeholder: string;
  handleClearFilters?: () => void;
  badgeContent?: number;
  additionalFilters?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleToggleFilters = () => setIsFiltersOpen(isFiltersOpen => !isFiltersOpen);

  const searchContainerRef = useRef<HTMLDivElement>(null);

  const handleClear = () => {
    handleClearFilters && handleClearFilters();
    setIsFiltersOpen(false);
  };

  const handleClose = () => {
    setIsFiltersOpen(false);
  };

  const handleChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;

    if (search.length === 0) return setSearch('');

    if (search.length < 3) return;

    setSearch(search);
  }, TYPING_DEBOUNCE_TIME);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      mb={5}
      gridGap={theme.spacing(2)}
      {...{
        ref: searchContainerRef
      }}>
      <TextField
        name="search"
        label="Search"
        variant="outlined"
        onChange={handleChangeSearch}
        placeholder={placeholder}
        InputProps={{
          endAdornment: <Search />,
          className: classes.input
        }}
        InputLabelProps={{
          shrink: true
        }}
        fullWidth
      />

      {children && (
        <Badge
          classes={{ badge: classes.badgeContent }}
          color="primary"
          badgeContent={badgeContent}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <Button variant="outlined" onClick={handleToggleFilters} className={classes.buttons}>
            <Box display="flex" alignItems="center" gridGap={8}>
              <Tune />
              Filters
            </Box>
          </Button>
        </Badge>
      )}

      {isFiltersOpen && (
        <FilterOptions
          anchorEl={searchContainerRef?.current}
          handleClear={handleClear}
          handleClose={handleClose}>
          {children}
        </FilterOptions>
      )}

      {additionalFilters && <>{additionalFilters}</>}
    </Box>
  );
};
