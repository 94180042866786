import React from 'react';

import { useQuery } from 'urql';

import SelectSimple from 'componentsV3/Select';

const GetVpcQuery = `#graphql
  query(
    $cloudId: Float!
    $region: String!
  ) {
    vpc(
      page: 1
      per_page: 1000
      cloudId: $cloudId
      sort: "name,ASC"
      region: $region
    ) {
      data {
        IsDefault
        VpcId
        Tags {
          Key
          Value
        }
      }
    }
  }
`;

export const useVpcQuery = ({ cloudId, region }) => {
  const [result] = useQuery({
    query: GetVpcQuery,
    pause: !cloudId || !region,
    variables: {
      cloudId,
      region
    }
  });

  return [result];
};

const VpcSelect = ({ errors, control, selectedCloudId, region, required, disabled }) => {
  const [result] = useVpcQuery({ cloudId: selectedCloudId, region });

  const { data, fetching } = result;

  const vpcList = React.useMemo(() => {
    if (fetching)
      return [
        {
          label: 'Loading...',
          value: ''
        }
      ];

    if (!data) return [];

    const vpcs = data.vpc.data;

    return vpcs.map(({ VpcId, IsDefault, Tags }) => {
      const Name = Tags.find(({ Key }) => Key === 'Name')?.Value;

      const name = `(${Name ? Name : 'no name'})`;
      const vpcDefault = IsDefault ? '(default)' : '';
      const label = `${VpcId} ${name} ${vpcDefault}`.trim();

      return {
        label,
        value: VpcId
      };
    });
  }, [data, fetching]);

  return (
    <SelectSimple
      disabled={disabled}
      errors={errors}
      name="vpc"
      control={control}
      label="VPC"
      defaultValue=""
      items={vpcList}
      required={required}
    />
  );
};

export default VpcSelect;
