import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type ServiceBusFormData = {
  connectionString: string;
  topicName?: string | undefined;
  subscriptionName?: string | undefined;
  queueName?: string | undefined;
  monitoringType: 'queue' | 'topic';
};

export function ServiceBusForm({ form }: FormProps) {
  const { control, watch, errors, setValue, register } = form;

  const monitoringType = watch('monitoringType');

  return (
    <Box display="flex" gridGap="2rem" flexDirection="column">
      <Box display="flex" gridGap="1rem">
        <InputWithSecrets
          control={control}
          watch={watch}
          errors={errors}
          setValue={setValue}
          TextFieldProps={{
            type: 'string',
            label: 'Connection String',
            name: 'connectionString'
          }}
          InputComponent={Input}
        />
      </Box>
      <Box display="flex" gridGap="1rem">
        <Select
          options={[
            { value: 'queue', label: 'Queue' },
            {
              value: 'topic',
              label: 'Topic'
            }
          ]}
          control={control}
          name="monitoringType"
          label="Monitoriong Type"
          required={true}
        />
      </Box>

      {monitoringType === 'topic' && (
        <Box display="flex" gridGap="1rem">
          <Input label="Topic Name" name="topicName" inputRef={register} />
          <Input label="Subscription Name" name="subscriptionName" inputRef={register} />
        </Box>
      )}

      {monitoringType === 'queue' && (
        <Box display="flex" gridGap="1rem">
          <Input label="Queue Name" name="queueName" inputRef={register} />
        </Box>
      )}
    </Box>
  );
}
