import { SqsFormData } from 'views/ServicesHub/forms/monitoring/queue/Sqs';

import { MonitoringBaseForm } from '../../../forms/monitoring/commom/Base';
import { monitoringAdapter } from '../monitoring';

export function sqsAdapter(data: MonitoringBaseForm & SqsFormData) {
  const domain = prepareDomain(data);
  // ex: ACCESS_KEY:SECRET_ACCESS_KEY/AWS-REGION@TEST-QUEUE

  return {
    ...monitoringAdapter({ ...data, method: 'sqs' }),
    domain,
    domainSettings: {
      domainType: data.domainType,
      queueSize: Number(data.queueSize)
    }
  };
}

const prepareDomain = (data: MonitoringBaseForm & SqsFormData) => {
  if (data.domainType === 'userAndPass') {
    return `${data.accessKey}:${data.secretAccessKey}/${data.awsRegion}@${data.testQueue}`;
  }

  return data.domain;
};
