import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    borderRadius: 8
  },
  dialogContent: {
    paddingRight: 30,
    paddingLeft: 30,
    paddingBottom: 30
  },
  dialogTitle: {
    padding: 30,
    alignItems: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500]
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100
  },
  buttons: {
    minWidth: 240,
    '& button': {
      width: 'fit-content'
    }
  },
  text: {
    fontWeight: 400
  }
}));
