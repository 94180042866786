import * as z from 'zod';

export default z
  .object({
    name: z.string().nonempty({ message: 'Application name is required' }),
    environmentId: z.string().nonempty({ message: 'Environment name is required' }),
    check_interval: z.string().refine(
      check_interval => {
        try {
          if (
            Number(check_interval) >=
            Number(process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING)
          ) {
            return true;
          }
        } catch {
          return false;
        }
      },
      {
        message: `Interval number must be equal to or greater than ${process.env.REACT_APP_MIN_INTERVAL_AND_TIMEOUT_MONITORING} seconds.`
      }
    ),
    failuresToIncident: z
      .string()
      .nonempty({ message: 'Required' })
      .refine(
        data => {
          if (Number(data) >= 1) {
            return true;
          }
          return false;
        },
        { message: 'Number should be greater than zero' }
      ),
    openIncidentWithSeverity: z.string().optional(),
    alertConnectionHealth: z.boolean().optional(),
    alert: z
      .object({
        type: z.string().optional(),
        timeout: z.coerce.number().optional(),
        counter: z.coerce.number().optional(),
        teams: z.any().optional(),
        severity: z.string().optional()
      })
      .optional()
  })
  .refine(
    values => {
      if (Number(values.check_interval) > 999999) {
        return false;
      }

      return true;
    },
    {
      message: 'Interval number can only be up to six digits',
      path: ['check_interval']
    }
  );
