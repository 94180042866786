import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
    paddingBottom: theme.spacing(12)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    padding: theme.spacing(2, 2)
  },
  cardContent: {
    width: '100%',
    padding: 0
  },
  titleCard: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  dividerSections: {
    backgroundColor: theme.palette.text.hint,
    opacity: '30%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  scrollBar: {
    scrollbarWidth: 'auto',
    overflowY: 'auto',
    scrollbarColor: `${theme.palette.grey[300]} ${theme.palette.grey[100]}`,
    '&::-webkit-scrollbar-track': {
      background: theme.palette.grey[100]
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5)
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.grey[300]
    }
  },
  noData: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  }
}));
