import React from 'react';

import { makeStyles } from '@material-ui/core';
import RadioGroupBase from 'oldComponents/RadioGroup';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(theme => {
  return {
    root: {
      justifyContent: 'flex-end'
    },
    checked: {
      color: theme.palette.primary.main
    },
    radioGrup: {
      display: 'flex',
      flexDirection: 'row'
    },
    radio: {
      color: theme.palette.inputBorder.default
    }
  };
});

const RadioGroup = ({ options, defaultValue, control, name, label, ...otherProps }) => {
  const classes = useStyles();

  return (
    <RadioGroupBase
      options={options}
      defaultValue={defaultValue}
      control={control}
      name={name}
      label={label}
      classes={classes}
      {...otherProps}
    />
  );
};

export default withThemeV3Hoc(RadioGroup);
