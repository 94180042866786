import { ScoreFunction, SearchParams } from 'types/external/Common';
import { Synthetic } from 'types/external/Synthetic';
import { useQuery } from 'urql';

const GetSyntheticsQuery = `#graphql
  query (
    $from: Int!
    $size: Int!
    $query: String!
    $aggregationRefs: String
    $aggregationKeys: String!
    $aggregationValues: String
    $termKey: String
    $termValue: [String!]
    $betweenKey: String
    $betweenValues: String
    $sortKey: String
    $ignoreDate: String
    $functions: [SearchFunction!]
    $maxBoost: Float
    $scoreMode: String
    $boostMode: String
    $minScore: Float
  ) {
    synthetics(
      from: $from
      size: $size
      query: $query
      aggregationRefs: $aggregationRefs
      aggregationKeys: $aggregationKeys
      aggregationValues: $aggregationValues
      termKey: $termKey
      termValue: $termValue
      betweenKey: $betweenKey
      betweenValues: $betweenValues
      sortKey: $sortKey
      ignoreDate: $ignoreDate
      functions: $functions
      maxBoost: $maxBoost
      scoreMode: $scoreMode
      boostMode: $boostMode
      minScore: $minScore
    ) {
      data {
        id
        uid
        name
        status
        environmentId
        environment {
            id
            name
        }
      }
      total
    }
  }
`;

export const useSynthetics = ({
  page,
  perPage,
  query,
  aggregationKeys,
  aggregationRefs,
  aggregationValues,
  termKey,
  termValue,
  betweenKey,
  betweenValues,
  sortKey,
  ignoreDate,
  functions,
  maxBoost,
  scoreMode,
  boostMode,
  minScore,
  pause = false
}: {
  page: number;
  perPage: number;
  query?: string;
  aggregationKeys: string;
  aggregationRefs?: string;
  aggregationValues?: string;
  termKey?: string;
  termValue?: string[];
  betweenKey?: string;
  betweenValues?: string;
  sortKey?: string;
  ignoreDate?: string;
  functions?: ScoreFunction[];
  maxBoost?: number;
  scoreMode?: string;
  boostMode?: string;
  minScore?: number;
  pause?: boolean;
}) => {
  const from = Math.max(page - 1, 0) * perPage;

  const [{ fetching, data }, reexecuteQuery] = useQuery<Synthetic, SearchParams>({
    query: GetSyntheticsQuery,
    pause,
    variables: {
      from,
      size: perPage,
      query,
      aggregationKeys,
      aggregationRefs,
      aggregationValues,
      termKey,
      termValue,
      betweenKey,
      betweenValues,
      sortKey,
      ignoreDate,
      functions,
      maxBoost,
      scoreMode,
      boostMode,
      minScore
    }
  });

  return {
    fetching,
    data: { synthetics: data?.synthetics?.data || [], total: data?.synthetics?.total || 0 },
    reexecuteQuery
  };
};
