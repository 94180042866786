import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  dialogRounded: {
    borderRadius: 8
  },
  dialogTitle: {
    padding: 40,
    paddingBottom: theme.spacing(3),
    paddingRight: 100
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    color: theme.palette.grey[500]
  },
  dialogContent: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40
  }
}));
