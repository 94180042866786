import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const styles = {
  inputsSkeleton: {
    height: 59,
    width: '100%'
  }
};

const FormSkeleton = () => {
  return (
    <Box display="flex" flex={1} flexDirection="column" gridGap="16px">
      <Box display="flex" gridGap="12px">
        <Skeleton height={styles.inputsSkeleton.height} width={styles.inputsSkeleton.width} />
        <Skeleton height={styles.inputsSkeleton.height} width={styles.inputsSkeleton.width} />
        <Skeleton height={styles.inputsSkeleton.height} width="30%" />
      </Box>
      <Box>
        <Skeleton height={styles.inputsSkeleton.height} width={styles.inputsSkeleton.width} />
      </Box>
    </Box>
  );
};

export default FormSkeleton;
