import { postBodyTypeValues } from 'constants/postBody';

import { keyValueListToObject } from 'helpers/getObjectList';
import { functionsMapToApi } from 'views/Services/components/Form/Functions/mapToApi';
import { kafkaMapToApi } from 'views/Services/components/Form/Kafka/mapToApi';
import { lambdaMapToApi } from 'views/Services/components/Form/LambdaV2/mapToApi';
import { redisMapToApi } from 'views/Services/components/Form/RedisV2/mapToApi';
import { serviceBusMapToApi } from 'views/Services/components/Form/ServiceBus/mapToApi';

export const mapToApi = (formData: any, startValues: any) => {
  const postBodyType =
    formData.post_body_type !== 'raw' ? formData.post_body_type : formData.post_body_raw_type;

  const postBodyRaw = formData.post_body_raw;

  const postBodyXFormUrlEncoded = keyValueListToObject(formData.post_body_urlencoded || []);

  const postBodyForSelectedType =
    postBodyType === postBodyTypeValues.urlencoded ? postBodyXFormUrlEncoded : postBodyRaw;

  const domainSettingsSidekiq = {
    queue_size: +formData.queue_size,
    busy_size: +formData.busy_size,
    scheduled_size: +formData.scheduled_size,
    connected_clients: +formData.connected_clients
  };

  const mapDomainSettings = {
    sidekiq: (formData: any) => domainSettingsSidekiq,
    pubsub: (formData: any) => formData.json_pubsub_body_raw,
    redisV2: (formData: any) => redisMapToApi(formData),
    lambdaawsv2: (formData: any) => lambdaMapToApi(formData),
    functions: (formData: any) => functionsMapToApi(formData),
    functionsgen2: (formData: any) => functionsMapToApi(formData),
    kafka_advanced: (formData: any) => kafkaMapToApi(formData),
    servicebus: (formData: any) => serviceBusMapToApi(formData)
  };

  const payload = {
    domain: formData.method !== 'webhook' ? formData.domain?.trim() : null,
    method: formData.method,
    headers: formData.headers ? keyValueListToObject(formData.headers) : undefined,
    validationString: formData.validationString,
    skip_ssl_validation: formData.skip_ssl_validation,
    timeout: Number(formData.timeout) ? Number(formData.timeout) : startValues?.timeout,
    check_interval: Number(formData.check_interval)
      ? Number(formData.check_interval)
      : startValues?.check_interval,
    failuresToIncident: Number(formData.failuresToIncident)
      ? Number(formData.failuresToIncident)
      : startValues?.failuresToIncident,
    post_body: postBodyForSelectedType,
    post_body_type: postBodyType,
    queue_size: formData.queue_size,
    environmentId: formData.environmentId,
    domain_settings:
      (formData.method as keyof typeof mapDomainSettings) in mapDomainSettings
        ? mapDomainSettings[formData.method as keyof typeof mapDomainSettings](formData)
        : formData.method === 'GET' || 'POST'
        ? JSON.stringify({
            tls_renegotiation: formData.tls_renegotiation ? Number(formData.tls_renegotiation) : 0
          })
        : null,
    openIncidentWithSeverity: formData?.openIncidentWithSeverity || 'not-classified'
  };

  if (formData.method === 'GET') {
    payload.post_body = '';
    payload.post_body_type = '';
  }

  return payload;
};
