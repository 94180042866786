import { useMemo } from 'react';

import checkTypes from 'constants/checkTypes';
import { postBodyRawTypeValues, postBodyTypeValues } from 'constants/postBody';

import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, MenuItem, makeStyles } from '@material-ui/core';
import { EnvironmentSelect } from 'oldComponents/Inputs';
import { Select, TextField } from 'oldComponents/Inputs';
import JsonFunctionsUpload from 'oldComponents/JsonFunctionsUpload';
import JsonPubsubUpload from 'oldComponents/JsonPubsubUpload';
import Locations from 'oldComponents/Locations';
import HealthcheckField from 'oldComponents/ResourceInputs/HealthcheckField';
import HTTPFields from 'oldComponents/ResourceInputs/HTTPFields';
import Methods from 'oldComponents/ResourceInputs/Methods';
import SkipSSLValidation from 'oldComponents/ResourceInputs/SkipSSLValidation';
import { useForm, useFieldArray } from 'react-hook-form';

import { FunctionsForm } from './Functions/Form';
import { functionsStartValues } from './Functions/startValues';
import { kafkaStartValues } from './Kafka/startValues';
import { LambdaForm } from './LambdaV2/Form';
import { lambdaStartValues } from './LambdaV2/startValues';
import { RedisForm } from './RedisV2/Form';
import { redisStartValues } from './RedisV2/startValues';
import schema from './schema';
import { ServiceBusForm } from './ServiceBus/Form';
import { serviceBusStartValues } from './ServiceBus/startValues';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(2)
  },
  environmentSelect: {
    marginTop: theme.spacing(1)
  }
}));

function Form({ submitMutation, startValues, isAddon, application }) {
  const classes = useStyles();

  const defaultValues = {
    name: '',
    domain: '',
    cloudId: '',
    environmentId: application?.environmentId,
    check_type: '',
    method: '',
    headers: [{ key: '', value: '' }],
    brokers: [''],
    post_body_raw: '',
    post_body_urlencoded: [{ key: '', value: '' }],
    post_body_type: postBodyTypeValues.raw,
    post_body_raw_type: postBodyRawTypeValues.json,
    skip_ssl_validation: false,
    tls_renegotiation: 0,
    queue_size: 0,
    busy_size: 0,
    scheduled_size: 0,
    connected_clients: 0,
    failuresToIncident: 2
  };

  const redisStartVals = redisStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const lambdaStartVals = lambdaStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const functionsStartVals = functionsStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const kafkaStartVals = kafkaStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const serviceBusStartVals = serviceBusStartValues({
    method: startValues?.method,
    domain_settings: startValues?.domain_settings
  });

  const { control, register, handleSubmit, watch, getValues, errors, setValue } = useForm({
    defaultValues: startValues
      ? {
          ...startValues,
          post_body_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded
              ? startValues.post_body_type
              : postBodyTypeValues.raw,
          post_body_raw_type:
            startValues.post_body_type === postBodyTypeValues.urlencoded ||
            !startValues.post_body_type
              ? postBodyRawTypeValues.json
              : startValues.post_body_type,
          post_body_raw: startValues.post_body_raw,
          json_pubsub_body_raw: startValues.json_pubsub_body_raw,
          busy_size:
            startValues.method === 'sidekiq'
              ? JSON.parse(startValues.domain_settings).busy_size
              : null,
          scheduled_size:
            startValues.method === 'sidekiq'
              ? JSON.parse(startValues.domain_settings).scheduled_size
              : null,
          connected_clients:
            startValues.method === 'sidekiq'
              ? JSON.parse(startValues.domain_settings).connected_clients
              : null,
          tls_renegotiation:
            startValues.check_type === 'http' && Boolean(startValues.domain_settings)
              ? JSON.parse(startValues.domain_settings).tls_renegotiation
              : 0,

          failuresToIncident: startValues.failuresToIncident,
          ...redisStartVals,
          ...lambdaStartVals,
          ...functionsStartVals,
          ...kafkaStartVals,
          ...serviceBusStartVals
        }
      : defaultValues,
    resolver: zodResolver(schema)
  });

  const selectedMethod = watch('method');
  const selectedPostBodyType = watch('post_body_type');
  const selectedCheckType = watch('check_type');

  const shouldShowSidekiqField = selectedCheckType === 'queue' && selectedMethod === 'sidekiq';

  const shouldShowQueueSizeField = selectedCheckType === 'queue' && selectedMethod === 'sqs';

  const shouldShowPubsubFields = selectedCheckType === 'queue' && selectedMethod === 'pubsub';

  const shouldShowWebhookFields = selectedCheckType === 'others' && selectedMethod === 'webhook';

  const shouldShowRedisV2Fields = selectedCheckType === 'cache' && selectedMethod === 'redisV2';

  const shouldShowLambdaV2Field =
    selectedCheckType === 'lambda' && selectedMethod === 'lambdaawsv2';

  const shouldShowFunctionsField =
    selectedCheckType === 'lambda' && selectedMethod.startsWith('functions');

  const shouldShowServiceBusFields =
    selectedCheckType === 'queue' && selectedMethod === 'servicebus';

  const shouldShowHealthcheckField =
    !shouldShowWebhookFields && !shouldShowServiceBusFields && selectedCheckType && selectedMethod;

  const shouldShowSkipSSLValidationField =
    (selectedCheckType === 'http' && selectedMethod) || shouldShowSidekiqField;

  const {
    fields: postBodyXFormUrlEncodedFields,
    append: postBodyXFormUrlEncodedAppend,
    remove: postBodyXFormUrlEncodedRemove
  } = useFieldArray({
    control,
    name: 'post_body_urlencoded'
  });

  const { fields: headersFields, append: headersAppend, remove: headersRemove } = useFieldArray({
    control,
    name: 'headers'
  });

  const checkTypesOptions = useMemo(() => {
    const options = [
      <MenuItem key={'check-type-blank'} value=""></MenuItem>,
      ...checkTypes.map(checkType => (
        <MenuItem key={checkType.value} value={checkType.value}>
          {checkType.label}
        </MenuItem>
      ))
    ];

    return options;
  }, []);

  return (
    <form onSubmit={handleSubmit(submitMutation)} id="serviceForm">
      <TextField
        register={register}
        className={classes.formControl}
        label="Name"
        required={true}
        name="name"
        errors={errors}
      />

      <Grid className={classes.formControl} container spacing={3}>
        <Grid
          item
          md={shouldShowQueueSizeField || shouldShowSidekiqField ? 3 : !isAddon ? 3 : 5}
          xs={12}>
          <EnvironmentSelect
            className={classes.environmentSelect}
            errors={errors}
            control={control}
            required
          />
        </Grid>
        <Grid
          item
          md={
            shouldShowQueueSizeField || shouldShowSidekiqField
              ? !isAddon
                ? 2
                : 4
              : !isAddon
              ? 3
              : 4
          }
          xs={12}>
          <Select
            control={control}
            required={true}
            label="Check Type"
            name="check_type"
            errors={errors}>
            {checkTypesOptions}
          </Select>
        </Grid>
        <Grid
          item
          md={shouldShowQueueSizeField || shouldShowSidekiqField ? (!isAddon ? 2 : 3) : 3}
          xs={12}>
          <Methods control={control} errors={errors} selectedCheckType={selectedCheckType} />
        </Grid>

        {shouldShowQueueSizeField || shouldShowSidekiqField ? (
          <Grid item md={2} xs={12}>
            <TextField
              className={classes.formControl}
              register={register}
              required={false}
              label="Queue size"
              errors={errors}
              name="queue_size"
              type="number"
            />
          </Grid>
        ) : null}
        {!isAddon && (
          <Grid item md={3} xs={12}>
            <TextField
              register={register}
              required={false}
              label="Fails to incident (Recommended (2+))"
              errors={errors}
              name="failuresToIncident"
              type="number"
            />
          </Grid>
        )}
      </Grid>

      {shouldShowHealthcheckField && (
        <Grid container spacing={3}>
          <Grid item md={shouldShowFunctionsField ? 9 : selectedCheckType === 'http' ? 8 : 12}>
            <HealthcheckField
              className={classes.formControl}
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              errors={errors}
              selectedMethod={selectedMethod}
              flex="1"
            />
          </Grid>

          <Grid
            item
            md={selectedCheckType === 'http' && shouldShowHealthcheckField ? 2 : 0}
            style={{ paddingTop: '1rem' }}>
            {shouldShowSkipSSLValidationField && (
              <SkipSSLValidation
                register={register}
                defaultChecked={startValues?.skip_ssl_validation}
              />
            )}
          </Grid>

          <Grid item md={selectedCheckType === 'http' && shouldShowHealthcheckField ? 2 : 0}>
            {selectedCheckType === 'http' && (
              <Select
                errors={errors}
                name="tls_renegotiation"
                control={control}
                label="TLS Renegotiation">
                <MenuItem value={0}>Never</MenuItem>
                <MenuItem value={1}>One Time</MenuItem>
                <MenuItem value={2}>Always</MenuItem>
              </Select>
            )}
          </Grid>

          <Grid item md={shouldShowFunctionsField ? 3 : 0} xs={12}>
            {shouldShowFunctionsField && <Locations control={control} />}
          </Grid>
        </Grid>
      )}

      {shouldShowSidekiqField && (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              className={classes.formControl}
              register={register}
              required={false}
              label="Busy Size"
              errors={errors}
              name="busy_size"
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.formControl}
              register={register}
              required={false}
              label="Scheduled Size"
              errors={errors}
              name="scheduled_size"
              type="number"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.formControl}
              register={register}
              required={false}
              label="Connected Clients"
              errors={errors}
              name="connected_clients"
              type="number"
            />
          </Grid>
        </Grid>
      )}

      {shouldShowPubsubFields && (
        <JsonPubsubUpload
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
          selectedMethod={selectedMethod}
        />
      )}

      {shouldShowRedisV2Fields && (
        <RedisForm
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          startValues={redisStartValues}
        />
      )}

      {shouldShowLambdaV2Field && (
        <LambdaForm register={register} errors={errors} startValues={lambdaStartValues} />
      )}

      {shouldShowFunctionsField && (
        <JsonFunctionsUpload
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          watch={watch}
          selectedMethod={selectedMethod}
        />
      )}

      {shouldShowFunctionsField && (
        <FunctionsForm register={register} errors={errors} startValues={functionsStartValues} />
      )}

      {shouldShowServiceBusFields && (
        <ServiceBusForm
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
          startValues={serviceBusStartValues}
        />
      )}

      <HTTPFields
        className={classes.formControl}
        selectedCheckType={selectedCheckType}
        selectedMethod={selectedMethod}
        defaultValues={defaultValues}
        register={register}
        headersFields={headersFields}
        headersAppend={headersAppend}
        headersRemove={headersRemove}
        control={control}
        setValue={setValue}
        watch={watch}
        selectedPostBodyType={selectedPostBodyType}
        errors={errors}
        postBodyXFormUrlEncodedFields={postBodyXFormUrlEncodedFields}
        postBodyXFormUrlEncodedAppend={postBodyXFormUrlEncodedAppend}
        postBodyXFormUrlEncodedRemove={postBodyXFormUrlEncodedRemove}
      />
    </form>
  );
}

export default Form;
