import { parsePhoneNumberWithError } from 'libphonenumber-js';
import * as z from 'zod';

export default z
  .object({
    value: z.string(),
    method: z.enum(['email', 'voice', 'whatsapp', 'sms', 'telegram', 'app']),
    reusePhoneNumber: z.any().optional()
  })
  .superRefine((data, ctx) => {
    if (!['voice', 'whatsapp', 'sms'].includes(data?.method)) {
      return;
    }

    try {
      const parsedNumber = parsePhoneNumberWithError(data?.value);

      const isValid = parsedNumber.isValid();

      if (isValid) return;

      ctx.addIssue({
        message: 'Invalid phone number',
        path: ['value'],
        code: z.ZodIssueCode.custom
      });
    } catch {
      ctx.addIssue({
        message: 'Invalid phone number',
        path: ['value'],
        code: z.ZodIssueCode.custom
      });
    }
  });
