import { Chip } from '@material-ui/core';

export function BetaChip() {
  return (
    <Chip
      style={{
        cursor: 'pointer',
        marginLeft: 'auto'
      }}
      variant="outlined"
      color="primary"
      label="Beta"
      size="small"
      title="This is a beta version"
    />
  );
}
