import { useMutation } from 'urql';

const CreateMemberMutation = `#graphql
  mutation(
    $name: String!
    $teamId: Float!
    $userId: Float!
    $userUid: String!
    $roleId: Float! 
    $isActive: Boolean!
  ){
    createMember(
      name: $name 
      teamId: $teamId 
      userId: $userId
      userUid: $userUid
      roleId: $roleId 
      isActive: $isActive
    ){
      id
      teamId
      userId
    }
  }
`;

export const useCreateMember = () => {
  const [{ fetching: isLoading }, createMember] = useMutation(CreateMemberMutation);

  return { createMember, isLoading };
};

export default useCreateMember;
