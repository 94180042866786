import React from 'react';

import { Tooltip } from '@material-ui/core';
import { green, red, blue, grey, orange, yellow } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { useTheme } from '@material-ui/styles';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';

import useComposeIncidentLink from '../../hooks/useComposeIncidentLink';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    minHeight: 50
  },
  description: {
    width: '100%',
    maxWidth: '37ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  descriptionContainer: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center'
  },
  descriptionIcon: {
    fontSize: 10,
    marginRight: 2,
    paddingBottom: 1
  },
  LocalOfferIcon: { fontSize: 11, marginRight: 2, paddingTop: 3 },
  separator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  version: { marginLeft: 8 },
  line: {
    height: '100%',
    width: 2,
    backgroundImage: 'linear-gradient(to top, #cecece 5px, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '2px 8px',
    backgroundRepeat: 'repeat-y'
  },
  content: {
    marginLeft: theme.spacing(1)
  },
  title: {
    fontWeight: 500
  },
  anchor: {
    transform: 'translateY(2px)',
    fontSize: 16,
    color: grey[600],
    marginLeft: theme.spacing(1)
  }
}));

const Item = ({
  description,
  version,
  icon,
  iconColor,
  title,
  incident,
  incidentId,
  applicationId,
  environment,
  productId,
  createdAt
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const incidentLink = useComposeIncidentLink()({ productId, applicationId, incidentId });

  return (
    <div className={classes.root}>
      <div className={classes.separator}>
        <div>
          <Icon style={{ color: iconColor }}>{icon}</Icon>
        </div>
        <div className={classes.line} />
      </div>
      <div className={classes.content}>
        <Typography
          variant="subtitle2"
          color={theme.palette.text.primary}
          className={classes.title}>
          {title}

          <Typography variant="caption"></Typography>
          {incidentId && (
            <Link component={RouterLink} to={incidentLink}>
              <Icon className={classes.anchor}>link</Icon>
            </Link>
          )}
        </Typography>
        {incident && (
          <Typography variant="body2" color={theme.palette.text.primary}>
            {incident}
          </Typography>
        )}
        <Typography variant="caption">
          {format(new Date(createdAt), 'MMMM dd, HH:mm zzz')} {''}
          {version && (
            <Typography className={classes.version} variant="caption">
              <LocalOfferIcon className={classes.LocalOfferIcon} />
              {version} <br />
            </Typography>
          )}
          {description && (
            <Typography className={classes.descriptionContainer} variant="caption">
              <Tooltip title={description}>
                <DescriptionIcon className={classes.descriptionIcon} />
              </Tooltip>
              <p className={classes.description} title={description}>
                {''} {description}
              </p>
            </Typography>
          )}
        </Typography>
      </div>
    </div>
  );
};

const TimeLineItem = ({ type, incidentId, applicationId, environment, productId, ...props }) => {
  const incidentLink = useComposeIncidentLink()({ productId, applicationId, incidentId });

  if (type === 'deploy') {
    return <Item icon="cloud_upload" iconColor={green[600]} {...props} />;
  }

  if (type === 'hotfix') {
    return <Item icon="build" style={{ fontSize: 40 }} iconColor={red[300]} {...props} />;
  }

  if (type === 'rollback') {
    return <Item icon="restore" iconColor={blue[600]} {...props} />;
  }

  if (type === 'post_mortem') {
    return (
      <Item
        icon="description"
        incidentId={incidentId}
        applicationId={applicationId}
        productId={productId}
        iconColor={yellow[700]}
        incident={
          <div>
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
          </div>
        }
        {...props}
      />
    );
  }

  if (type === 'alarmed') {
    return (
      <Item
        icon="trending_down"
        incidentId={incidentId}
        applicationId={applicationId}
        productId={productId}
        iconColor={red[600]}
        incident={
          <div>
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' created'}
          </div>
        }
        {...props}
      />
    );
  }

  if (type === 'acknowledged') {
    return (
      <Item
        icon="done_all"
        incidentId={incidentId}
        applicationId={applicationId}
        productId={productId}
        iconColor={blue[600]}
        incident={
          <div>
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' acknowledged'}
          </div>
        }
        {...props}
      />
    );
  }

  if (type === 'change_description') {
    return (
      <Item
        icon="compare_arrows"
        incidentId={incidentId}
        applicationId={applicationId}
        productId={productId}
        iconColor={orange[400]}
        incident={
          <div>
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' changed'}
          </div>
        }
        {...props}
      />
    );
  }

  if (type === 'resolved') {
    return (
      <Item
        icon="trending_up"
        incidentId={incidentId}
        applicationId={applicationId}
        productId={productId}
        iconColor={green[600]}
        incident={
          <div>
            {'Incident '}
            <Link component={RouterLink} to={incidentLink}>
              #{incidentId}
            </Link>
            {' resolved'}
          </div>
        }
        {...props}
      />
    );
  }

  return null;
};

export default TimeLineItem;
