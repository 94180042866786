import { useMutation, useQuery } from 'urql';

type Profile = {
  displayName: string;
  photoURL: string;
  acceptedTermsAt: string;
  muteReports: boolean;
  muteNotifications: boolean;
};

const GetProfileQuery = `#graphql
  query {
    getProfile {
      displayName
      photoURL
      acceptedTermsAt
      muteReports
      muteNotifications
    }
  }`;

const UpdateUserProfilePhotoMutation = `#graphql
  mutation ($file: Upload!) {
      updateUserProfilePhoto (file: $file){
        photoURL
      }
  }
  `;

const useProfile = () => {
  const [{ data, fetching: fetchingProfile }] = useQuery<{ getProfile: Profile }>({
    query: GetProfileQuery
  });

  const [{ fetching: updatingUserProfilePhoto }, updateUserProfilePhoto] = useMutation<{
    updateUserProfilePhoto: any;
  }>(UpdateUserProfilePhotoMutation);

  return {
    fetchingProfile,
    profile: data?.getProfile,
    updatingUserProfilePhoto,
    updateUserProfilePhoto
  };
};

export default useProfile;
