import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Button, makeStyles } from '@material-ui/core';

import ProductImage from 'componentsV3/ProductImage';
import { analyticsEvent } from 'services/firebase/analytics';
import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    root: {
      maxWidth: '100%',
      maxHeight: 68,
      height: '100%',
      display: props => (props.previewImage ? 'none' : 'flex'),
      alignItems: 'center',
      padding: spacing(3),
      border: 1,
      borderStyle: 'dashed',
      borderRadius: spacing(1),
      borderColor: palette.primary.main,
      fontSize: 14,
      color: palette.primary.main,
      backgroundColor: props => (props.isActive ? palette.primary.light : 'transparent'),
      textTransform: 'none',

      '& input[type=file]': {
        display: 'none'
      }
    },
    label: {
      pointerEvents: 'none'
    }
  };
});

function ProductImageUpload({
  forwardRef,
  image,
  setSharedState,
  setValue,
  register,
  firstAccess,
  applicationType
}) {
  const buttonRef = useRef();

  const [isActive, setIsActive] = useState(false);
  const [previewImage, setPreviewImage] = useState(image);

  const classes = useStyles({ isActive, previewImage });

  const handleDrop = useCallback(
    event => {
      event.preventDefault();

      const { dataTransfer } = event;

      const hasItems = Boolean(dataTransfer.files.length);

      if (!hasItems) {
        setIsActive(false);
        setPreviewImage(null);
        return;
      }

      const [selectedImage] = Array.from(dataTransfer.files);
      const selectedImagePreview = URL.createObjectURL(selectedImage);

      setValue('cover', dataTransfer.files, {
        shouldDirty: true
      });

      setPreviewImage(selectedImagePreview);
      setIsActive(false);
    },
    [setValue]
  );

  const handleImageChange = useCallback(event => {
    if (!event.target.files) return;

    const [selectedImage] = Array.from(event.target.files);

    const selectedImagePreview = URL.createObjectURL(selectedImage);

    setPreviewImage(selectedImagePreview);
  }, []);

  useEffect(() => setPreviewImage(image), [image]);

  useEffect(() => setSharedState && setSharedState(previewImage), [previewImage, setSharedState]);

  useImperativeHandle(
    forwardRef,
    () => ({
      changeImage: () => buttonRef?.current.click(),
      removeImage: () => {
        setPreviewImage(null);
      }
    }),
    []
  );

  return (
    <>
      {previewImage && <ProductImage imageUrl={previewImage} size={68} padding={1} />}

      <Button
        ref={buttonRef}
        classes={{
          root: classes.root,
          label: classes.label
        }}
        fullWidth
        title="Drag an image here or select on your computer"
        component="label"
        onDrop={handleDrop}
        onDragEnter={() => setIsActive(true)}
        onDragLeave={() => setIsActive(false)}
        onDragOver={event => event.preventDefault()}>
        Drag an image here or select on your computer
        <input
          ref={register}
          name="cover"
          accept="image/*"
          type="file"
          onChange={handleImageChange}
          onClick={() => {
            const eventsMap = {
              internalMultiHttp: firstAccess
                ? 'fa_button_upload_img_multi'
                : 'button_upload_img_multi',
              internal: firstAccess
                ? 'fa_new_iaea_photo_upload_button'
                : 'new_iaea_photo_upload_button',
              externalWithAddons: firstAccess
                ? 'fa_new_eaea_photo_upload_button'
                : 'new_eaea_photo_upload_button'
            };

            if (eventsMap[applicationType]) {
              analyticsEvent(eventsMap[applicationType]);
            }
          }}
        />
      </Button>
    </>
  );
}

export default forwardRef((props, ref) =>
  withThemeV3Hoc(ProductImageUpload)({ ...props, forwardRef: ref })
);
