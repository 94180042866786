import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
  makeStyles
} from '@material-ui/core';

import theme from 'theme';

const useStyles = makeStyles({
  toolbar: {
    minHeight: 'auto'
  },
  actions: {
    '& button': {
      padding: theme.spacing()
    }
  }
});

type TablePaginationProps = Omit<MuiTablePaginationProps, 'rowsPerPage' | 'rowsPerPageOptions'> & {
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
};

export function TablePagination(props: TablePaginationProps) {
  const classes = useStyles();

  return (
    <MuiTablePagination
      classes={{
        toolbar: classes.toolbar,
        actions: classes.actions
      }}
      rowsPerPage={5}
      rowsPerPageOptions={[]}
      {...props}
    />
  );
}
