const runtimes = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true
  }
];

const columns = {
  runtimes
};

export default columns;
