import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';

import useGetResourceGroups from './useGetResourceGroups';

const ResourceGroupSelect = ({ formState, onChange }) => {
  const { cloudId } = formState.values;

  const { data: resourceGroups = [], isSuccess, isLoading, isError } = useGetResourceGroups({
    cloudId
  });

  const handleChange = event => {
    event.persist();
    onChange(event.target.value);
  };

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const options = () => {
    if (isLoading) {
      const pendingText = 'loading...';
      return (
        <MenuItem title={pendingText} value="1">
          <em>{pendingText}</em>
        </MenuItem>
      );
    }

    if (isSuccess) {
      return resourceGroups.map(({ name }) => {
        return (
          <MenuItem title={name} key={`resourceGroup_${name}`} value={name}>
            {name}
          </MenuItem>
        );
      });
    }

    if (isError) {
      const errorText = "Couldn't load Resource Groups";
      return (
        <MenuItem title={errorText} value="1">
          <em>{errorText}</em>
        </MenuItem>
      );
    }

    return null;
  };

  return (
    <TextField
      fullWidth
      select
      InputLabelProps={{
        shrink: true
      }}
      label="Resource Group"
      margin="dense"
      name="resourceGroup"
      onChange={handleChange}
      required
      value={!isSuccess ? '1' : formState.values.resourceGroup || '1'}
      variant="outlined"
      error={hasError('resourceGroup')}
      helperText={hasError('resourceGroup') ? formState.errors.resourceGroup[0] : null}>
      {options()}
    </TextField>
  );
};

export default ResourceGroupSelect;
