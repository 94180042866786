import { Neo4jFormData } from 'views/ServicesHub/forms/monitoring/database/Neo4j';
import { MonitoringCommomData } from 'views/ServicesHub/layouts/Monitoring';

import { monitoringAdapter } from '../monitoring';

export function neo4jAdapter(data: MonitoringCommomData & Neo4jFormData) {
  const domain = `bolt://${data.host}:${data.port}@${data.user}/${data.password}`;
  // ex: bolt://HOST:PORT@USER/PASSWORD

  return {
    ...monitoringAdapter({ ...data, method: 'neo4j' }),
    domain
  };
}
