import { useState } from 'react';

import { Tooltip, Box, Icon, Link, Typography, CircularProgress, Grid } from '@material-ui/core';
import { RawJsonField } from 'oldComponents/Inputs';
import { useMutation } from 'react-query';

import { ButtonSimple } from 'componentsV4/Button';
import convertJsonToString from 'helpers/convertStringToJson';
import useQueryParams from 'helpers/useQueryParams';
import useCopy from 'hooks/useCopy';
import axios from 'redux/axios';
import { analyticsEvent } from 'services/firebase/analytics';

import useForm from './useForm';

const defaultValues = {
  template_response: ''
};
const KeysForm = () => {
  const [keys, setKeys] = useState();

  const { handleCopy, copiedSuccessfullyMessage } = useCopy();

  const firstAccess = useQueryParams().has('firstAccess');

  const { control, handleSubmit, register, errors, watch, setValue } = useForm({
    defaultValues
  });

  const [submitMutation, { error: errorUseMutation, isLoading }] = useMutation(
    formData => {
      const payload = convertJsonToString(formData?.template_response);
      return axios.post(`/products/multihttp/applications/keys`, payload);
    },
    {
      onSuccess: data => {
        setKeys(data.data);
        return data.data;
      }
    }
  );

  const onSubmit = data => {
    if (data.template_response === '') {
      return;
    }
    setKeys();
    submitMutation(data);
  };

  const templateResponse = (
    <>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <RawJsonField
            register={register}
            errors={errors}
            name="template_response"
            label="Response Template"
            control={control}
            watch={watch}
            withSecrets={false}
            setValue={setValue}
          />
        </Grid>
        <Grid style={{ marginBottom: -20 }} item xs={4}>
          <ButtonSimple
            fullWidth
            variant="contained"
            color="primary"
            size="medium"
            onClick={event => {
              handleSubmit(onSubmit)(event);

              analyticsEvent(
                firstAccess ? 'fa_button_generate_keys_multi' : 'button_generate_keys_multi'
              );
            }}
            text="Generate Keys"
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <form>
        {templateResponse}
        {isLoading && <CircularProgress style={{ color: '#1C3D37' }} />}
        {!errorUseMutation && typeof keys !== 'undefined' && (
          <>
            <Box display="flex" style={{ marginTop: 20 }}>
              <Typography style={{ whiteSpace: 'nowrap', marginTop: '20px' }} color="textPrimary">
                Identified variables that are used in the request
              </Typography>

              <Box style={{ marginTop: '4px' }}>
                <Tooltip title="These keys were identified, click on it and copy it to use it in the Post Request">
                  <Icon style={{ color: 'grey', margin: 12 }}>help</Icon>
                </Tooltip>
              </Box>
            </Box>
          </>
        )}

        {keys?.keys?.map(key => (
          <Link
            component="button"
            variant="inherit"
            color="primary"
            underline="hover"
            type="button"
            key={key}
            style={{ marginBottom: '16px', fontSize: '16px', marginRight: '10px' }}
            onClick={() => handleCopy(key)}>
            {key}
          </Link>
        ))}

        {copiedSuccessfullyMessage}

        {errorUseMutation && (
          <Typography variant="h5" color="primary" style={{ marginBottom: '16px' }}>
            Error on get keys
          </Typography>
        )}
      </form>
    </>
  );
};

export default KeysForm;
