import { Box } from '@material-ui/core';

import InputWithSecrets from 'componentsV3/InputWithSecrets';
import { Select } from 'views/ServicesHub/components/Select';

import { FormProps } from '../..';
import { Input } from '../../../components/Input';

export type PostgresFormData = {
  username: string;
  password: string;
  host: string;
  port: string;
  database: string;
  sslMode: string;
};

export function PostgresForm({ form, domainType }: FormProps) {
  const { register, control, watch, errors, setValue } = form;

  return (
    <>
      {domainType === 'userAndPass' && (
        <Box display="flex" gridGap="2rem" flexDirection="column">
          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Username',
                name: 'username'
              }}
              InputComponent={Input}
            />

            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'password',
                label: 'Password',
                name: 'password'
              }}
              InputComponent={Input}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <InputWithSecrets
              control={control}
              watch={watch}
              errors={errors}
              setValue={setValue}
              TextFieldProps={{
                type: 'text',
                label: 'Host',
                name: 'host'
              }}
              InputComponent={Input}
            />

            <Input
              label="Port"
              name="port"
              type="number"
              inputProps={{
                min: 1,
                max: 65535
              }}
              inputRef={register}
              errors={errors}
            />
          </Box>

          <Box display="flex" gridGap="1rem">
            <Box display="flex" alignItems="center" flexGrow={11}>
              <InputWithSecrets
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                TextFieldProps={{
                  type: 'text',
                  label: 'Database',
                  name: 'database'
                }}
                InputComponent={Input}
              />
            </Box>

            <Box display="flex" alignItems="center" flexGrow={1}>
              <Select
                options={[
                  { value: '', label: 'None' },
                  { value: 'disable', label: 'Disable' },
                  { value: 'alow', label: 'Alow' },
                  { value: 'prefer', label: 'Prefer' },
                  { value: 'required', label: 'Required' },
                  { value: 'verify-ca', label: 'Verify-ca' },
                  { value: 'verify-full', label: 'Verify-full' }
                ]}
                control={control}
                name="sslMode"
                label="SSL Mode"
                defaultValue=""
                required={false}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
