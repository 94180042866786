import React from 'react';

import {
  Box,
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  Typography,
  IconButton,
  Divider
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useStyles } from './styles';

export const FilterOptions = ({
  anchorEl,
  children,
  handleClear,
  handleClose
}: {
  anchorEl: HTMLDivElement | null;
  children: React.ReactNode;
  handleClear: () => void;
  handleClose: () => void;
}) => {
  const classes = useStyles();

  const handleClosePopper = (event: React.MouseEvent<Document>) => {
    if (event.target instanceof Element && event.target.localName === 'body') {
      event.preventDefault();
      return;
    }

    handleClose();
  };

  const handleClearFilters = () => {
    handleClear();
  };

  const handleApplyFilters = () => {
    handleClose();
  };

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom"
      className={classes.popper}
      style={{ width: anchorEl?.getBoundingClientRect().width }}>
      <ClickAwayListener onClickAway={handleClosePopper}>
        <Paper className={classes.paper}>
          <Box className={classes.content}>
            <Box className={classes.dialogTitle}>
              <Typography variant="h4">Filters</Typography>

              <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.selectContainer}>
              <Box display="flex" justifyContent="space-between" gridGap={12}>
                {children}
              </Box>
            </Box>

            <Box className={classes.buttonsContainer} mt={2}>
              <Button onClick={handleClearFilters} variant="outlined" color="primary">
                Clear
              </Button>
              <Button onClick={() => handleApplyFilters()} variant="contained" color="primary">
                Apply
              </Button>
            </Box>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
