import { useEffect, useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'urql';

import useFeatureFlagUnleash from 'hooks/useFeatureFlagUnleash';
import actions from 'redux/actions';

const GetMaintenanceByService = `#graphql
  query(
    $serviceId: Float!
    $applicationId: Float!
    $uid: String!
  ) {
    getMaintenanceByService(serviceId: $serviceId, applicationId: $applicationId, uid: $uid) {
      id
      serviceId
      applicationId
      orgUid
      status
      start
      finish
      dependencies
      forceFinish
      userUid    
    }
  }
`;

const CreateMaintenance = `#graphql
  mutation($maintenanceInput: MaintenanceInput!) {
    createMaintenance(
      maintenanceInput: $maintenanceInput
    ) {
      id
      serviceId
      applicationId
      status
      start
      finish
      forceFinish
    }
  }
`;

const DeleteMaintenance = `#graphql
  mutation($maintenanceInput: MaintenanceInput!) {
    deleteMaintenance(
      maintenanceInput: $maintenanceInput
    ) {
      id
      serviceId
      applicationId
      status
      start
      finish
      forceFinish
    }
  }
`;

const ForceFinishMaintenance = `#graphql
  mutation($maintenanceInput: MaintenanceInput!) {
    forceFinish(
      maintenanceInput: $maintenanceInput
    ) {
      id
      serviceId
      applicationId
      status
      start
      finish
      forceFinish
    }
  }
`;

const useMaintenanceWindow = ({
  serviceId,
  applicationId,
  uid,
  entity,
  reexecuteEntityQuery
}: {
  serviceId: number;
  applicationId: number;
  uid: string;
  entity: string;
  reexecuteEntityQuery: () => void;
}) => {
  const shouldUseNewApplications = useFeatureFlagUnleash('newApplications', { queryString: true });

  const [{ fetching: fetchingMaintenance, data }, reexecuteQuery] = useQuery({
    query: GetMaintenanceByService,
    pause: !useMaintenanceWindow || shouldUseNewApplications ? uid === '' : !serviceId,
    variables: { serviceId, applicationId, uid }
  });

  const maintenance = data?.getMaintenanceByService ?? null;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (fetchingMaintenance) return;

      reexecuteQuery();
    }, 1000 * 10 /* ms = 10 seconds */);

    return () => clearInterval(intervalId);
  }, [fetchingMaintenance, reexecuteQuery]);

  const [{ fetching: fetchingCreate }, createMaintenance] = useMutation(CreateMaintenance);
  const [{ fetching: fetchingDelete }, deleteMaintenance] = useMutation(DeleteMaintenance);
  const [{ fetching: fetchingForceFinish }, forceFinishMaintenance] = useMutation(
    ForceFinishMaintenance
  );

  const onSubmit = useCallback(
    (data: any) => {
      const mappedData = {
        id: maintenance?.id,
        serviceId: serviceId,
        applicationId: applicationId,
        uid: uid,
        entity: entity,
        start: data.start,
        finish: data.finish,
        dependencies: data.dependencies,
        productId: data.productId
      };

      createMaintenance({
        maintenanceInput: { ...mappedData }
      }).then(result => {
        if (result.error?.graphQLErrors) return;

        reexecuteQuery();
      });
    },
    [applicationId, createMaintenance, maintenance?.id, reexecuteQuery, serviceId, uid, entity]
  );

  const onDelete = useCallback(
    (data: any) => {
      const mappedData = {
        id: data?.id,
        serviceId: serviceId,
        applicationId: applicationId,
        uid: uid,
        start: data.start,
        finish: data.finish,
        productId: data.productId
      };

      deleteMaintenance({
        maintenanceInput: { ...mappedData }
      }).then(result => {
        if (result.error?.graphQLErrors) return;

        reexecuteQuery();
      });
    },
    [applicationId, deleteMaintenance, reexecuteQuery, serviceId, uid]
  );

  const dispatch = useDispatch();

  const onForceFinish = useCallback(
    (data: any) => {
      const mappedData = {
        id: data?.id,
        serviceId: serviceId,
        applicationId: applicationId,
        start: data.start,
        finish: data.finish,
        uid,
        productId: data.productId
      };

      forceFinishMaintenance({
        maintenanceInput: { ...mappedData }
      }).then(result => {
        if (!result.error?.graphQLErrors) {
          dispatch({
            type: actions.GLOBAL_SUCCESS,
            payload: 'Finishing maintenance'
          });

          reexecuteEntityQuery();
        }
      });
    },
    [applicationId, serviceId, reexecuteEntityQuery, forceFinishMaintenance, dispatch, uid]
  );

  const inMaintenance = !useMaintenanceWindow
    ? false
    : maintenance?.id > 0 && maintenance?.status === 'Initialized'
    ? true
    : false;

  const isFutureDay =
    dayjs(new Date(maintenance?.start)).startOf('day') > dayjs(new Date()).startOf('day')
      ? true
      : false;

  const isSameDay =
    dayjs(new Date(maintenance?.finish)).startOf('day') >
    dayjs(new Date(maintenance?.start)).startOf('day')
      ? false
      : true;

  const format = isFutureDay ? 'MM/DD HH:mm' : isSameDay ? 'HH:mm' : 'MM/DD HH:mm';

  const maintenanceMessage = inMaintenance
    ? 'This product is under scheduled maintenance (' +
      dayjs(new Date(maintenance?.start)).format(format) +
      ' - ' +
      dayjs(new Date(maintenance?.finish)).format(format) +
      ')'
    : null;

  return useMemo(
    () => ({
      maintenance,
      onSubmit,
      onDelete,
      onForceFinish,
      inMaintenance,
      maintenanceMessage,
      fetchingCreate,
      fetchingDelete,
      fetchingForceFinish
    }),
    [
      fetchingCreate,
      fetchingDelete,
      fetchingForceFinish,
      inMaintenance,
      maintenance,
      maintenanceMessage,
      onDelete,
      onForceFinish,
      onSubmit
    ]
  );
};

export default useMaintenanceWindow;
