import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

const useStyles = makeStyles<Theme>(({ palette, typography }) => ({
  body: {
    color: palette.grey[800],
    fontSize: typography.pxToRem(16)
  }
}));

export default useStyles;
