import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,

  primary: {
    contrastText: white,
    dark: '#228855',
    main: '#37BA77',
    light: '#67C897'
  },

  secondary: {
    contrastText: white,
    dark: '#286A6B',
    main: '#286A6B',
    light: '#77AAAA'
  },

  success: {
    contrastText: white,
    dark: '#228855',
    main: '#37BA77',
    light: '#67C897'
  },

  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },

  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: '#FAAD14',
    light: '#FFD666'
  },

  error: {
    contrastText: white,
    dark: '#D11114',
    main: '#F60E11',
    light: '#FF8888'
  },

  text: {
    primary: '#595959',
    secondary: '#37BA77',
    link: '#67C897'
  },

  background: {
    default: '#F6F6F6',
    paper: '#fff'
  },

  inputBorder: {
    default: colors.grey[400]
  },

  grey: {
    900: '#333333',
    800: '#595959',
    700: '#7B7B7B',
    600: '#9F9F9F',
    500: '#BFBFBF',
    400: '#CECECE',
    300: '#D9D9D9',
    200: '#EFEFEF',
    100: '#F6F6F6'
  },

  icon: colors.grey[700],

  green: {
    50: 'rgba(236, 253, 245, 1)',
    100: 'rgba(205, 244, 226, 1)',
    200: 'rgba(168, 231, 202, 1)',
    300: 'rgba(125, 208, 170, 1)',
    400: 'rgba(95, 188, 145, 1)',
    500: 'rgba(67, 165, 120, 1)',
    600: 'rgba(50, 141, 99, 1)',
    700: 'rgba(26, 107, 70, 1)',
    800: 'rgba(15, 87, 54, 1)',
    900: 'rgba(5, 54, 31, 1)'
  },
  gray: {
    50: 'rgba(246, 246, 246, 1)',
    100: 'rgba(239, 239, 239, 1)',
    200: 'rgba(213, 213, 213, 1)',
    300: 'rgba(194, 194, 194, 1)',
    400: 'rgba(165, 165, 165, 1)',
    500: 'rgba(137, 137, 137, 1)',
    600: 'rgba(113, 113, 113, 1)',
    700: 'rgba(81, 81, 81, 1)',
    800: 'rgba(50, 50, 50, 1)',
    900: 'rgba(38, 38, 38, 1)'
  },
  red: {
    50: 'rgba(255, 241, 240, 1)',
    100: 'rgba(255, 204, 199, 1)',
    200: 'rgba(255, 163, 158, 1)',
    300: 'rgba(255, 120, 117, 1)',
    400: 'rgba(255, 77, 79, 1)',
    500: 'rgba(245, 34, 45, 1)',
    600: 'rgba(207, 19, 34, 1)',
    700: 'rgba(168, 7, 26, 1)',
    800: 'rgba(130, 0, 20, 1)',
    900: 'rgba(92, 0, 17, 1)'
  },
  yellow: {
    50: 'rgba(255, 239, 208, 1)',
    100: 'rgba(255, 225, 166, 1)',
    200: 'rgba(255, 212, 126, 1)',
    300: 'rgba(253, 196, 83, 1)',
    400: 'rgba(250, 173, 20, 1)',
    500: 'rgba(236, 164, 23, 1)',
    600: 'rgba(221, 154, 20, 1)',
    700: 'rgba(201, 141, 23, 1)',
    800: 'rgba(172, 121, 18, 1)',
    900: 'rgba(133, 92, 11, 1)'
  },
  blue: {
    50: 'rgba(219, 232, 255, 1)',
    100: 'rgba(178, 207, 255, 1)',
    200: 'rgba(131, 177, 255, 1)',
    300: 'rgba(87, 150, 255, 1)',
    400: 'rgba(41, 121, 255, 1)',
    500: 'rgba(18, 95, 224, 1)',
    600: 'rgba(17, 81, 188, 1)',
    700: 'rgba(0, 61, 163, 1)',
    800: 'rgba(2, 45, 118, 1)',
    900: 'rgba(4, 34, 83, 1)'
  },
  purple: {
    50: '#B18FF9',
    100: '#9262F7',
    200: '#824CF5',
    300: '#7335F4',
    400: '#F57D27',
    500: '#4F19C2',
    600: '#3B1392',
    700: '#280C61',
    800: '#140631',
    900: '#0A0318'
  },
  orange: {
    50: '#F9B17D',
    100: '#F8A468',
    200: '#F79752',
    300: '#F68A3D',
    400: '#F57D27',
    500: '#DD7123',
    600: '#C4641F',
    700: '#AC581B',
    800: '#934B17',
    900: '#7B3F14'
  },
  button: {
    text: white,
    contrastText: colors.green[500],
    disabledMain: colors.grey[400],
    disabledLigth: colors.grey[200],
    primaryHover: colors.green[400],
    secondaryHover: colors.blue.A400
  },
  borders: {
    image: '#E1E4E8'
  }
};

export default palette;
