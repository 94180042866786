import React from 'react';

import { makeStyles, Box } from '@material-ui/core';

import withThemeV3Hoc from 'theme/v3/withTheme';

const useStyles = makeStyles(({ palette, spacing }) => {
  return {
    root: {
      maxWidth: props => props.size,
      maxHeight: props => props.size,
      width: '100%',
      height: '100%',
      padding: props => spacing(props.padding),
      border: '1px solid',
      borderColor: palette.borders.image,
      borderRadius: spacing(1),
      boxSizing: 'border-box'
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: '100%'
    }
  };
});

function ProductImage({ imageUrl, size = 146, padding = 2 }) {
  const classes = useStyles({ size, padding });

  return (
    <Box className={classes.root}>
      <img className={classes.image} alt="product-logo" src={imageUrl} />
    </Box>
  );
}

export default withThemeV3Hoc(ProductImage);
