import { Resource } from 'types/external/Resource';

import { FunctionFormData } from 'views/ServicesHub/forms/monitoring/serveless/Function';

import { monitoringAdapter } from '../monitoring';

export function functionsAdapter(
  data: Resource
): ReturnType<typeof monitoringAdapter> & FunctionFormData {
  return {
    ...monitoringAdapter(data),
    function: data.domainSettings.function,
    notInvoke: data.domainSettings.searchPeriod,
    credentials: data.domainSettings.credentials,
    locations: data.domainSettings.locations
  };
}
