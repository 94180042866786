import React from 'react';

import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex'
  },
  cover: {
    maxHeight: 45,
    maxWidth: 100,
    margin: 10,
    display: 'inline-block'
  },
  avatar: {
    display: 'flex'
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  progress: {
    margin: 10
  }
}));

const cover_url = () => {
  return `${process.env.PUBLIC_URL}/images/not_product_2.png`;
};

const UploadImage = ({ cover }) => {
  const [picture, setPicture] = React.useState(cover_url());

  React.useEffect(() => {
    if (cover) {
      setPicture(cover);
    }
  }, [cover]);

  const classes = useStyles();
  const upload = useSelector(state => state.upload);

  const onFileLoad = (e, file) => {
    localStorage.setItem('file', e.target.result);
    localStorage.setItem('contentType', file.type);
    localStorage.setItem('name', file.name);
  };

  const onInputChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => onFileLoad(e, file);
    reader.addEventListener('load', () => {
      setPicture(reader.result);
    });
  };

  const handleUpload = () => {
    const input = document.querySelector('#avatarAccount');
    input.click();
  };

  const progress = upload.status ? <CircularProgress className={classes.progress} /> : null;

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.avatar}>
          <img alt="Status Page" id={picture} className={classes.cover} src={picture} />
          <input
            accept="image/*"
            id="avatarAccount"
            onChange={onInputChange}
            style={{ display: 'none' }}
            type="file"
          />
          <Button
            className={classes.uploadButton}
            color="primary"
            disabled={upload.status}
            onClick={handleUpload}
            size="small"
            variant="text">
            Upload
          </Button>
          {progress}
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
