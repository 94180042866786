import { makeStyles } from '@material-ui/core';

import { Theme } from 'theme/v4';

export default makeStyles((theme: Theme) => ({
  root: {},
  paper: {
    minWidth: '1000px'
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    marginTop: theme.spacing(4)
  },
  day: {
    fontSize: '18px',
    width: 60,
    marginRight: 110
  },
  dayDisabled: {
    fontSize: '18px',
    width: 60,
    marginRight: 110,
    color: theme.palette.grey[300]
  },
  middleTextDefaultValue: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  middleText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  middleTextDisabled: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.grey[300]
  },
  eraseDay: {
    marginLeft: 8
  },
  customDialogTitle: {
    paddingBottom: '12px !important'
  },
  avatarDisabled: {
    backgroundColor: 'white',
    border: `2px solid #5FBC91`,
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '14px',
    color: '#5FBC91',
    width: 36,
    height: 36
  },
  avatarEnabled: {
    backgroundColor: '#5FBC91',
    borderColor: '#5FBC91',
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '14px',
    color: 'white',
    width: 36,
    height: 36
  },
  inputlabel: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  },
  input: {
    width: 130
  },
  saveHourButton: {
    width: 200,
    marginLeft: theme.spacing(2),
    fontWeight: theme.typography.fontWeightRegular,
    whiteSpace: 'nowrap',
    color: '#5FBC91'
  },
  timePicker: {
    width: 100,
    '& div > div': {
      padding: 0,
      marginRight: 0
    },
    '& input': {
      height: 10
    },
    '& button': {
      padding: 0
    }
  },
  inputTimePicker: {
    fontSize: 16
  },
  inputAdornment: {
    marginRight: -10
  },
  copyButtonText: {
    fontSize: 16
  },
  subtitle: {
    fontSize: 16,
    paddingBottom: theme.spacing(4),
    marginTop: '-10px'
  },
  dialogContent: {
    marginLeft: '-3px'
  },
  customButton: {
    backgroundColor: '#5FBC91'
  },
  customButtonDisabled: {
    backgroundColor: '#A8E7CA !important',
    color: '#FFFFFF !important',
    border: 'none !important',
    padding: '12px 16px !important',
    lineHeight: '24px !important'
  }
}));
