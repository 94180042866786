import { useMutation } from 'urql';

const UpdateProfileMutation = `#graphql
  mutation ($updateProfileInput: UpdateProfileV2Inputs!) {
    updateProfileV2(updateProfileInput: $updateProfileInput) {
      displayName
    }
  }
`;

const useUpdateProfile = () => {
  const [{ fetching: isLoading }, updateProfile] = useMutation(UpdateProfileMutation);

  return { updateProfile, isLoading };
};

export default useUpdateProfile;
